import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { Link, generatePath, useLocation, useHistory } from 'react-router-dom'
import LinkButton from '@mui/material/Link'
import queryString from 'query-string'
import Box from '@mui/material/Box'
import Card from '../../components/Common/Card'
import Grid from '@mui/material/Grid'
import { Form } from 'react-final-form'
import cn from 'classnames'
import InfiniteScroll from 'react-infinite-scroll-component'
import moment from 'moment'
import styles from './CollectionsDashboardPage.module.scss'
import genericSs from '@styles/generic.module.scss'
import MultiselectRow from '../../components/MultiselectRow'
import { IAggregationData, COLLECTIONS_TABS } from '@common/interfaces/collection'
import Table from '../../components/Common/Table'
import TableHead from '../../components/Common/TableHead'
import TableBody from '../../components/Common/TableBody'
import TableFooter from '../../components/Common/TableFooter'
import TableRow from '../../components/Common/TableRow'
import TableCell from '../../components/Common/TableCell'
import TableContainer from '../../components/Common/TableContainer'
import Tabs from '../../components/Common/Tabs'
import { debounceEventHandler, formatNumber, formatPrice, formatter } from '../../helpers/helpers'
import { IClientInfo, ClientInfoStatus } from '@common/interfaces/client'
import CollectionAggregationRow from '../../components/CollectionAggregationRow'
import TableFiltersRow from '../../components/Common/TableFiltersRow'
import { ROUTES } from '../../constants/routes'
import DeletedCheckTable from '../../components/DeletedCheckTable'
import { COLLECTIONS_LIST_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import {
  buildFiltersDefaults,
  buildFiltersValidateSchema,
  updateDateFilters,
} from '../../helpers/filters'
import { IEntityInfo } from '@common/interfaces/entityInfo'
import FilterContainer from '../../components/Filters/FilterContainer'
import TableLoader from '../../components/Common/TableLoader'
import { ILoadingData } from '../../redux/types'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'
import { DATE_DATABASE_FORMAT } from '../../constants/common'
import useTable from '../../hooks/useTable'
import { useLoadingData } from '../../hooks/useLoadingData'
import { ExpandDetailIcon } from '../../components/Common/Icons'
import useSummaryRow from '../../hooks/useSummaryRow'

const TABS_ROUTES = {
  [COLLECTIONS_TABS.OVERALL_COLLECTIONS]: ROUTES.COLLECTIONS_DASHBOARD_OVERALL,
  [COLLECTIONS_TABS.NON_CLIENT_RELATED_CASH]: ROUTES.COLLECTIONS_DASHBOARD_NON_CLIENT_RELATED_CASH,
}

const today = moment()

const filtersValidate = buildFiltersValidateSchema(COLLECTIONS_LIST_FILTERS_CONFIG)
const filtersDefaults = buildFiltersDefaults(COLLECTIONS_LIST_FILTERS_CONFIG, {
  recordDateFrom: today.format(DATE_DATABASE_FORMAT),
  recordDateTo: today.format(DATE_DATABASE_FORMAT),
})

interface IProps {
  isLoadingCollections: boolean
  aggregationData: ILoadingData<IAggregationData>
  clients: ILoadingData<{ data: IClientInfo[] }>
  listAggregation: (params?: {
    page?: number
    filters?: object
    orderBy?: string
    orderDirection?: string
  }) => void
  exportAggregation: (params?: {
    filters?: object
    orderBy?: string
    orderDirection?: string
  }) => void
  listClients: (params?: object) => void
  listEntityInfo: (data: object) => Promise<{ data: IEntityInfo[] }>
}

const CollectionsDashboardPage = ({
  isLoadingCollections,
  aggregationData,
  clients,
  listAggregation,
  exportAggregation,
  listClients,
  listEntityInfo,
}: IProps) => {
  const wrapperRef = useRef(null)

  const [isLoadMore, setIsLoadMore] = useState(false)

  useSetPageTitle('Collections')

  const {
    filters,
    handleFiltersChange,
    handleOrderChange,
    orderBy,
    quickFilter,
    handleQuickFilterChange,
    activeItems,
    activeItem,
    handleSelectRow,
  } = useTable({
    tableId: 'collection',
    filtersDefaults,
    sortDefault: {
      field: 'client_name',
      direction: 'ASC',
    },
    quickFilterDefault: 'Today',
  })

  const { clientsData } = useMemo(
    () => ({
      clientsData: clients?.data?.data,
    }),
    [clients],
  )

  const { data: aggregation } = useLoadingData(aggregationData)

  const history = useHistory()
  const [currentTab, setCurrentTab] = useState<string>(COLLECTIONS_TABS.OVERALL_COLLECTIONS)
  const onTabChange = useCallback(
    (tab) => {
      history.push(generatePath(TABS_ROUTES[tab]))
      setCurrentTab(tab)
    },
    [history],
  )

  const [expanded, setExpanded] = useState([])
  const { search }: { search: string } = useLocation()

  const fetchAggregationList = useCallback(
    async (data: any) => {
      const filters = updateDateFilters(data.filters, COLLECTIONS_LIST_FILTERS_CONFIG)
      const params = {
        ...data,
        filters,
      }
      await listAggregation(params)
    },
    [listAggregation],
  )

  const debounceListAggregation = useMemo(
    () => debounceEventHandler(fetchAggregationList, 500),
    [fetchAggregationList],
  )

  useEffect(() => {
    debounceListAggregation({
      page: 0,
      perPage: PER_PAGE,
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [orderBy, filters, debounceListAggregation])

  useEffect(() => {
    if (search) {
      const parsed = queryString.parse(search)
      const { type, activityId } = parsed
      if (type && activityId) {
        handleFiltersChange((data: any) => ({
          ...data,
          type,
          activityId,
        }))
      }
    }
  }, [search, handleFiltersChange])

  useEffect(() => {
    listClients({ withBankAccounts: true })
  }, [listClients])

  const handleExpand = useCallback((index: number) => {
    setExpanded((values) =>
      values.includes(index) ? values.filter((item) => item !== index) : [...values, index],
    )
  }, [])

  const refetchAggregationList = useCallback(async () => {
    setIsLoadMore(true)
    await fetchAggregationList({
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
    setIsLoadMore(false)
  }, [orderBy, fetchAggregationList, filters])

  const loadDebtors = useCallback(
    async (inputValue: string) => {
      const res = await listEntityInfo({
        name: inputValue,
      })
      return res.data.map(({ name }) => ({
        value: name,
        label: name,
      }))
    },
    [listEntityInfo],
  )

  const filtersConfig = useMemo(
    () =>
      COLLECTIONS_LIST_FILTERS_CONFIG.map((item) => ({
        ...item,
        options:
          item.field === 'clients'
            ? clientsData?.map(({ clientName }) => ({
                value: clientName,
                label: clientName,
              }))
            : item.options,
        loadOptions: item.field === 'debtor' ? loadDebtors : undefined,
      })),
    [clientsData, loadDebtors],
  )

  const handleExportAggregation = useCallback(async () => {
    const updatedFilters = updateDateFilters(filters, COLLECTIONS_LIST_FILTERS_CONFIG)
    const params = {
      filters: {
        ...updatedFilters,
      },
      collectionsExport: true,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    }
    await exportAggregation(params)
  }, [filters, orderBy, exportAggregation])

  const loadMore = useCallback(async () => {
    setIsLoadMore(true)
    await fetchAggregationList({
      loadMore: true,
      page: Math.ceil(aggregation?.data?.length / PER_PAGE),
      perPage: PER_PAGE,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
    setIsLoadMore(false)
  }, [aggregation, orderBy, filters, fetchAggregationList])

  const totalRow = useSummaryRow(aggregation?.data, activeItems, {
    sumFields: [
      'checksCount',
      'wiresCount',
      'checksPaymentAmount',
      'wiresPaymentAmount',
      'totalPaymentAmount',
    ],
  })

  return (
    <Box py={1} pr={2}>
      <Card
        withBorder={false}
        title={
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Tabs
              tabs={[
                COLLECTIONS_TABS.OVERALL_COLLECTIONS,
                COLLECTIONS_TABS.NON_CLIENT_RELATED_CASH,
              ]}
              selected={currentTab}
              handleChange={onTabChange}
            />
          </Box>
        }
      >
        <Grid container spacing={3} className={styles.aggregationPage}>
          {currentTab === COLLECTIONS_TABS.OVERALL_COLLECTIONS && (
            <Grid item xs={12} ref={wrapperRef}>
              <TableContainer className={styles.aggregationTable} hasFooter>
                <Form
                  validate={filtersValidate}
                  onSubmit={handleFiltersChange}
                  initialValues={filters}
                  mutators={{
                    setFieldData: ([field, value], state, { changeValue }) => {
                      changeValue(state, field, () => value)
                    },
                  }}
                  render={({ values, handleSubmit, form: { mutators } }) => (
                    <FilterContainer
                      filters={filtersConfig}
                      handleSubmit={handleSubmit}
                      mutators={mutators}
                      values={values}
                      withFullSearch={false}
                      appliedFilters={filters}
                      appliedQuickFilter={quickFilter}
                      handleAppliedQuickFilterChange={handleQuickFilterChange}
                      handleExportAggregation={handleExportAggregation}
                    />
                  )}
                />
                <Table>
                  <TableHead>
                    <TableFiltersRow
                      filters={filtersConfig}
                      orderBy={orderBy}
                      handleOrderChange={handleOrderChange}
                      isChildrenAtStart
                    >
                      <TableCell />
                    </TableFiltersRow>
                  </TableHead>
                  <TableBody id="scrollableTableCollections">
                    {isLoadingCollections && !isLoadMore ? (
                      <TableLoader columnsCount={6} height={24} />
                    ) : (
                      aggregation?.data &&
                      aggregation.data?.length > 0 && (
                        <InfiniteScroll
                          dataLength={aggregation?.data?.length}
                          next={loadMore}
                          hasMore={aggregation?.data?.length < aggregation?.totals.totalItems}
                          loader={<TableLoader columnsCount={6} height={24} rowsCount={1} />}
                          scrollableTarget="scrollableTableCollections"
                        >
                          {aggregation.data?.map((item, index) => {
                            const isExpanded = expanded.includes(index)

                            return (
                              <React.Fragment key={item.clientName}>
                                <TableRow
                                  data-index={index}
                                  className={cn({
                                    [styles.expandedRow]: isExpanded,
                                    activeRow: activeItems.includes(index),
                                    currentActiveRow: activeItem === index,
                                  })}
                                  onClick={(event) => handleSelectRow(event, index)}
                                >
                                  <TableCell>
                                    <ExpandDetailIcon
                                      onClick={() => handleExpand(index)}
                                      isExpanded={isExpanded}
                                    />
                                  </TableCell>

                                  <TableCell className={genericSs.tableTextLeft}>
                                    <LinkButton
                                      component={Link}
                                      to={generatePath(ROUTES.CLIENT_PAGE, {
                                        id: item.clientId,
                                      })}
                                    >
                                      {item.clientName}
                                    </LinkButton>
                                    {item.clientStatus === ClientInfoStatus.Past && (
                                      <span className={genericSs.grayCard}>Past</span>
                                    )}
                                  </TableCell>

                                  <TableCell className={genericSs.tableTextRight}>
                                    <span>{+item.checksCount + +item.wiresCount || '-'}</span>
                                  </TableCell>

                                  <TableCell className={genericSs.tableTextRight}>
                                    <span className={genericSs.pricePrefix}>$</span>
                                    {formatPrice(item.checksPaymentAmount)}
                                  </TableCell>

                                  <TableCell className={genericSs.tableTextRight}>
                                    <span className={genericSs.pricePrefix}>$</span>
                                    {formatPrice(item.wiresPaymentAmount)}
                                  </TableCell>

                                  <TableCell className={genericSs.tableTextRight}>
                                    <span className={genericSs.pricePrefix}>$</span>
                                    {formatPrice(+item.totalPaymentAmount)}
                                  </TableCell>
                                </TableRow>

                                {isExpanded && (
                                  <TableRow>
                                    <TableCell colSpan={6} className={genericSs.nestedRowColumn}>
                                      <CollectionAggregationRow
                                        clientName={item.clientName}
                                        data={item.rows}
                                        filters={filters}
                                        clientsData={clientsData}
                                        refetchAggregationList={refetchAggregationList}
                                        loadDebtors={loadDebtors}
                                      />
                                    </TableCell>
                                  </TableRow>
                                )}
                              </React.Fragment>
                            )
                          })}
                        </InfiniteScroll>
                      )
                    )}
                    <MultiselectRow activeItems={activeItems} addColumn>
                      <TableCell className={genericSs.tableTextRight}>
                        {formatNumber(totalRow?.checksCount + totalRow?.wiresCount, 0)}
                      </TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        {formatter.format(totalRow?.checksPaymentAmount)}
                      </TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        {formatter.format(totalRow?.wiresPaymentAmount)}
                      </TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        {formatter.format(totalRow?.totalPaymentAmount)}
                      </TableCell>
                    </MultiselectRow>
                  </TableBody>
                  <TableFooter>
                    <TableRow className={styles.aggregationTableTotals}>
                      <TableCell />
                      <TableCell className={genericSs.tableTextLeft}>Total:</TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        {formatNumber(
                          aggregation?.totals?.totalChecksCount +
                            aggregation?.totals?.totalWiresCount,
                          0,
                        )}
                      </TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        <span className={genericSs.pricePrefix}>$</span>
                        {formatPrice(aggregation?.totals?.checksPaymentSum)}
                      </TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        <span className={genericSs.pricePrefix}>$</span>
                        {formatPrice(aggregation?.totals?.wiresPaymentSum)}
                      </TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        <span className={genericSs.pricePrefix}>$</span>
                        {formatPrice(
                          +aggregation?.totals?.checksPaymentSum +
                            +aggregation?.totals?.wiresPaymentSum,
                        )}
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Grid>
          )}

          {currentTab === COLLECTIONS_TABS.NON_CLIENT_RELATED_CASH && (
            <Grid item xs={12}>
              <DeletedCheckTable />
            </Grid>
          )}
        </Grid>
      </Card>
    </Box>
  )
}

export default CollectionsDashboardPage
