import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import { Paper, IconButton, Typography, Box, Fab, Zoom, Tooltip } from '@mui/material'
import {
  ChatBubble as ChatIcon,
  Close as CloseIcon,
  Menu as MenuIcon,
  Add as AddIcon,
  Fullscreen as FullscreenIcon,
  FullscreenExit as FullscreenExitIcon,
} from '@mui/icons-material'
import { IChatHistory, IChatSession, Models } from '@common/interfaces/atlasai'
import styles from './AtlasAIChat.module.scss'
import ChatMessage from './ChatMessage'
import ChatInput from './ChatInput'
import ChatSidebar from './ChatSidebar'
import { usePermissions } from '../../helpers/permissionContext'
import { PER_PAGE } from '@common/constants/filters'

const CHAT_MESSAGES_CONTAINER_ID = 'chatMessagesContainer'

interface IProps {
  chatHistory: IChatHistory | null
  isLoading: boolean
  isLoadingMore: boolean
  isChatVisible: boolean
  sessionId: string | null
  askAtlasAI: (params: { question: string; sessionId: string; modelProvider: Models }) => void
  toggleChatVisibility: () => void
  sessions: IChatSession[]
  listSessions: (params?: { limit: number; offset: number }) => void
  selectSession: (sessionId: string) => void
  createSession?: () => Promise<{ data: { session: { id: string } } }>
  loadMoreHistory?: (sessionId: string, params: { limit: number; offset: number }) => void
  modelProvider: Models
}

const AtlasAIChat: React.FC<IProps> = ({
  chatHistory,
  isLoading,
  isLoadingMore,
  isChatVisible,
  sessionId,
  askAtlasAI,
  toggleChatVisibility,
  sessions,
  listSessions,
  selectSession,
  createSession,
  loadMoreHistory,
  modelProvider,
}) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)
  const [isFullscreen, setIsFullscreen] = useState(false)
  const messagesEndRef = useRef<HTMLDivElement>(null)
  const { isAdminRightsRole, isPortfolio } = usePermissions()
  const [sentMessageCount, setSentMessageCount] = useState(1)

  useEffect(() => {
    if (isSidebarOpen) {
      listSessions({ limit: PER_PAGE, offset: 0 })
    }
  }, [isSidebarOpen, listSessions])

  const handleSendMessage = useCallback(
    async (message: string) => {
      setSentMessageCount((prev) => prev + 1)
      let sessionIdToUse = sessionId
      if (!sessionIdToUse) {
        const newSession = await createSession()
        sessionIdToUse = newSession.data.session.id
        listSessions({ limit: PER_PAGE, offset: 0 })
      }
      await askAtlasAI({
        question: message,
        sessionId: sessionIdToUse,
        modelProvider,
      })
    },
    [askAtlasAI, sessionId, createSession, listSessions, modelProvider],
  )

  const toggleFullscreen = useCallback(() => {
    setIsFullscreen((prev) => !prev)
  }, [])

  const toggleSidebar = useCallback(() => {
    setIsSidebarOpen((prev) => !prev)
  }, [])

  useEffect(() => {
    if ((sessionId && sentMessageCount > 0) || chatHistory?.thinking) {
      const chatContainer = document.getElementById(CHAT_MESSAGES_CONTAINER_ID)
      if (chatContainer) {
        chatContainer.scrollTo({
          top: chatContainer.scrollHeight,
          behavior: 'smooth',
        })
      }
    }
  }, [sessionId, sentMessageCount, chatHistory?.thinking])

  const handleLoadMore = useCallback(() => {
    if (sessionId && loadMoreHistory && !isLoadingMore) {
      const offset = Math.floor(chatHistory?.messages.length / 2)
      loadMoreHistory(sessionId, {
        limit: PER_PAGE / 2,
        offset,
      })
    }
  }, [sessionId, loadMoreHistory, chatHistory, isLoadingMore])

  const handleShowChat = useCallback(async () => {
    if (!sessionId && sessions.length > 0) {
      toggleChatVisibility()
      await selectSession(sessions[0].id)
    } else if (sessionId) {
      toggleChatVisibility()
    } else {
      toggleChatVisibility()
      await createSession()
    }
  }, [selectSession, toggleChatVisibility, sessionId, sessions, createSession])

  const currentSessionName = useMemo(() => {
    return sessions?.find((session) => session.id === sessionId)?.name || 'New Chat'
  }, [sessions, sessionId])

  const handleScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement>) => {
      const target = e.target as HTMLElement
      if (target.scrollTop < 50 && !isLoadingMore && sessionId && chatHistory?.hasMore) {
        handleLoadMore()
      }
    },
    [handleLoadMore, isLoadingMore, sessionId, chatHistory],
  )

  if (!isAdminRightsRole && !isPortfolio) {
    return null
  }

  return (
    <>
      <Zoom in={!isChatVisible}>
        <Tooltip title="Open Atlas AI" placement="left">
          <Fab color="primary" className={styles.chatButton} onClick={handleShowChat}>
            <ChatIcon />
          </Fab>
        </Tooltip>
      </Zoom>
      {isChatVisible && (
        <Paper
          className={`${styles.chatPanel} ${isFullscreen ? styles.fullscreen : ''}`}
          elevation={3}
        >
          <ChatSidebar open={isSidebarOpen} />

          <Box className={styles.chatContent}>
            <Box className={styles.chatHeader}>
              <Box className={styles.headerLeft}>
                <IconButton size="small" onClick={toggleSidebar}>
                  <MenuIcon fontSize="small" />
                </IconButton>
                <Typography variant="h6">Ask Atlas - {currentSessionName}</Typography>
              </Box>
              <Box>
                <IconButton size="small" onClick={createSession}>
                  <Tooltip title="Start new chat">
                    <AddIcon fontSize="small" />
                  </Tooltip>
                </IconButton>
                <IconButton size="small" onClick={toggleFullscreen}>
                  {isFullscreen ? (
                    <FullscreenExitIcon fontSize="small" />
                  ) : (
                    <FullscreenIcon fontSize="small" />
                  )}
                </IconButton>
                <IconButton size="small" onClick={toggleChatVisibility}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            </Box>

            <Box
              className={styles.chatMessages}
              id={CHAT_MESSAGES_CONTAINER_ID}
              sx={{
                height: 400,
                overflowY: 'auto',
                display: 'flex',
                flexDirection: 'column',
              }}
              onScroll={handleScroll}
            >
              {chatHistory?.messages.length === 0 ? (
                <Box className={styles.emptyChat}>
                  <Typography color="textSecondary">
                    Hi, I'm Atlas AI. Ask me anything Dwight related from fundings, collections,
                    financials. I am your go to for all things Dwight.
                  </Typography>
                </Box>
              ) : (
                <>
                  {isLoadingMore && (
                    <Box className={styles.loadingIndicator} my={1}>
                      <Box className={styles.typingIndicator}>
                        <span></span>
                        <span></span>
                        <span></span>
                      </Box>
                    </Box>
                  )}
                  {(chatHistory?.messages || []).map((message) => (
                    <ChatMessage key={message.id} message={message} />
                  ))}
                </>
              )}
              {isLoading && !isLoadingMore ? (
                chatHistory?.thinking ? (
                  <Box className={styles.thinking}>
                    <Typography>{chatHistory?.thinking}</Typography>
                  </Box>
                ) : (
                  <Box className={styles.loadingIndicator}>
                    <Box className={styles.typingIndicator}>
                      <span></span>
                      <span></span>
                      <span></span>
                    </Box>
                  </Box>
                )
              ) : (
                <div ref={messagesEndRef} />
              )}
            </Box>

            <ChatInput
              isLoading={isLoading}
              isFullscreen={isFullscreen}
              onSendMessage={handleSendMessage}
            />
          </Box>
        </Paper>
      )}
    </>
  )
}

export default AtlasAIChat
