import React, { useCallback, useEffect, useMemo, useState, Dispatch, SetStateAction } from 'react'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'
import Box from '@mui/material/Box'
import moment from 'moment'
import styles from './ClientTermLoans.module.scss'
import Button from '../Common/Button'
import { ReactComponent as DeleteIcon } from '../../assets/images/delete-icon.svg'
import Card from '../Common/Card'
import { dateToString, formatPrice } from '../../helpers/helpers'
import { ClientInfoStatus, IClientInfo } from '@common/interfaces/client'
import { ClientTermLoanField, IClientTermLoanField } from '../Client/ClientHelpers'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import IconButton from '@mui/material/IconButton'
import { Grid } from '@mui/material'
import RouteLeavingGuard from '../Common/RouteLeavingGuard'
import { useHistory } from 'react-router'
import { ReactComponent as EmptyIcon } from '../../assets/images/empty-page-icon.svg'
import { ITermLoan, TermLoanDealType } from '@common/interfaces/termLoan'
import WarningModal from '../WarningModal'
import ClientTermLoanAmortization from './ClientTermLoanAmortization'
import AddButton from '../Client/AddButton'
import { Skeleton } from '@mui/material'

const BASE_SCHEMA = {
  boxLink: Yup.string().nullable(true).required('Required'),
  dealType: Yup.string().required('Required'),
  collateralName: Yup.string().nullable(true).required('Required'),
  loanAmount: Yup.number()
    .typeError('Invalid number')
    .min(0, 'Invalid number')
    .required('Required'),
  amortization: Yup.number()
    .typeError('Invalid number')
    .integer('Please, enter valid integer number')
    .min(0, 'Must be positive number')
    .required('Required'),
  term: Yup.number()
    .typeError('Invalid number')
    .integer('Please, enter valid integer number')
    .min(0, 'Must be positive number')
    .required('Required')
    .max(500, 'Term should be less than 500')
    .test({
      name: 'lestThan',
      exclusive: false,
      message: 'Term should always be equal or less than amortization',
      test(value) {
        return +parseInt(this.parent.amortization) >= value || this.parent.amortization === 0
      },
    }),
  baseRate: Yup.number()
    .typeError('Invalid number')
    .min(0, 'Must be positive number')
    .required('Required'),
  abovePrime: Yup.number()
    .typeError('Invalid number')
    .min(0, 'Must be positive number')
    .required('Required'),
  closingFeePercent: Yup.number()
    .typeError('Invalid number')
    .min(0, 'Must be positive number')
    .required('Required'),
  miscFees: Yup.number()
    .typeError('Invalid number')
    .min(0, 'Must be positive number')
    .required('Required'),
  wireFee: Yup.number()
    .typeError('Invalid number')
    .min(0, 'Must be positive number')
    .required('Required'),
}

export const termLoanFieldConfig: IClientTermLoanField[] = [
  {
    label: 'Contract link',
    name: 'boxLink',
    type: 'text',
  },
  {
    label: 'Deal type',
    name: 'dealType',
    type: 'select',
    options: [
      { label: 'Equipment Loan', value: TermLoanDealType.Equipment },
      { label: 'Term Loan', value: TermLoanDealType.TermLoan },
    ],
  },
  {
    label: 'Term Loan Name',
    name: 'collateralName',
    type: 'text',
  },
  {
    label: 'Loan Amount',
    name: 'loanAmount',
    type: 'currency',
  },
  { label: 'Amortization (months)', name: 'amortization', type: 'number' },
  {
    label: 'Term (months)',
    name: 'term',
    type: 'number',
  },
  {
    label: 'Rate',
    name: 'rate',
    type: 'percent',
    disabled: true,
  },
  {
    label: 'Floor',
    name: 'baseRate',
    type: 'percent',
  },
  {
    label: 'Margin Above Prime',
    name: 'abovePrime',
    type: 'percent',
  },
  {
    label: 'Closing Date',
    name: 'closingDate',
    type: 'date',
  },
  {
    label: 'Termination Date',
    name: 'terminationDate',
    type: 'date',
    disabled: true,
  },
  {
    label: 'Closing Fee Percent',
    name: 'closingFeePercent',
    type: 'percent',
  },
  {
    label: 'Closing Fee Amount',
    name: 'closingFeeAmount',
    type: 'currency',
    disabled: true,
  },
  {
    label: 'Misc. Fees',
    name: 'miscFees',
    type: 'currency',
  },
  {
    label: 'Wire Fee',
    name: 'wireFee',
    type: 'currency',
  },
]

const mutators = {
  ...arrayMutators,
}

const getTermLoanTitle = (termLoan: ITermLoan) => {
  const { collateralName, closingDate, loanAmount } = termLoan
  if (collateralName && loanAmount) {
    return `${collateralName} - $${formatPrice(loanAmount)} (${closingDate})`
  }
  return 'Default'
}
interface ITermLoansProps {
  clientId: string
  clientInfo: IClientInfo
  isAdminRightsRole: boolean
  termLoans: ITermLoan[]
  listTermLoans: (id: string) => void
  createTermLoan: (id: string, data: object) => Promise<any>
  updateTermLoan: (id: string, termLoanId: string, data: object) => Promise<any>
  deleteTermLoan: (id: string, termLoanId: string) => Promise<any>
  listAmortizationSchedule: (id: string, termLoanId: string) => Promise<any>
  isLoading: boolean
  loanBalanceStartDate: string
}

const ClientTermLoans = ({
  clientId,
  clientInfo,
  isAdminRightsRole,
  termLoans,
  listTermLoans,
  createTermLoan,
  updateTermLoan,
  deleteTermLoan,
  listAmortizationSchedule,
  loanBalanceStartDate,
  isLoading,
}: ITermLoansProps) => {
  const [termLoanList, setTermLoanList] = useState<ITermLoan[]>([])
  const [refreshCounter, setRefreshCounter] = useState(1)
  const [expandedTermLoan, setExpandedTermLoan] = useState<string | null>(null)
  const [amortizationIdsLoading, setAmortizationIdsLoading] = useState<string[]>([])

  useEffect(() => {
    setTermLoanList(termLoans)
  }, [termLoans])

  const handleLoadAmortization = useCallback(
    async (termLoanId) => {
      setAmortizationIdsLoading((prev) => [...prev, termLoanId])
      await listAmortizationSchedule(clientId, termLoanId)
      setAmortizationIdsLoading((prev) => prev.filter((id) => id !== termLoanId))
    },
    [clientId, listAmortizationSchedule],
  )

  useEffect(() => {
    if (expandedTermLoan && expandedTermLoan !== 'newTermLoan' && refreshCounter) {
      handleLoadAmortization(expandedTermLoan)
    }
  }, [clientId, expandedTermLoan, handleLoadAmortization, refreshCounter])

  const debounceListTermLoans = useCallback(() => {
    if (clientId) {
      listTermLoans(clientId)
    }
  }, [clientId, listTermLoans])

  useEffect(() => {
    debounceListTermLoans()
  }, [debounceListTermLoans])

  const isActiveClient = useMemo(
    () =>
      [ClientInfoStatus.Current, ClientInfoStatus.DueDiligence].includes(clientInfo?.clientStatus),
    [clientInfo],
  )

  const termLoanTemplate = useMemo(
    () => ({
      boxLink: null,
      dealType: TermLoanDealType.Equipment,
      collateralName: null,
      loanAmount: null,
      amortization: null,
      term: null,
      baseRate: clientInfo?.interestRateBase,
      abovePrime: clientInfo?.interestRateAbovePrime,
      closingDate: null,
      closingFeePercent: null,
      miscFees: 0,
      wireFee: clientInfo?.wireFee,
      status: null,
      terminationDate: null,
      closingFeeAmount: null,
      clientName: clientInfo?.clientName,
      id: null,
    }),
    [clientInfo],
  )

  const handleAddTermLoan = useCallback(() => {
    setTermLoanList((prev) => [termLoanTemplate, ...prev])
    setExpandedTermLoan('newTermLoan')
  }, [termLoanTemplate, setTermLoanList, setExpandedTermLoan])

  const handleRemoveTermLoan = useCallback(
    (index: number) => {
      setTermLoanList((prev) => {
        const newTermLoan = [...prev]
        newTermLoan.splice(index, 1)
        return newTermLoan
      })
    },
    [setTermLoanList],
  )

  if (!isLoading && termLoanList.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="70vh">
        <Grid container spacing={2} justifyContent={'center'}>
          <Grid container item xs={12} justifyContent={'center'}>
            <EmptyIcon />
          </Grid>
          <Grid container item xs={12} justifyContent={'center'}>
            <h3>No term loans exist for {clientInfo?.clientName}</h3>
          </Grid>
          <Grid container item xs={12} justifyContent={'center'}>
            <Button
              className={styles.addButton}
              type="button"
              color="primary"
              variant="contained"
              small={false}
              disabled={!isActiveClient}
              onClick={handleAddTermLoan}
            >
              Setup term loan
            </Button>
          </Grid>
        </Grid>
      </Box>
    )
  }

  if (isLoading) {
    return (
      <Box gap={10}>
        {Array.from({ length: 5 }).map((_, index) => (
          <Skeleton
            key={index}
            variant="rectangular"
            height={150}
            style={{
              marginBottom: 10,
            }}
          />
        ))}
      </Box>
    )
  }

  return (
    <Card
      withBorder={false}
      noHeaderMargin
      title={
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <span>Term loan</span>
          <Box display="flex" alignItems="center" justifyContent={'space-between'}>
            <AddButton
              disabled={!isActiveClient}
              onClick={handleAddTermLoan}
              variant="outlined"
            ></AddButton>
          </Box>
        </Box>
      }
    >
      <Grid container spacing={2} mt={2}>
        {termLoanList.map((termLoan: ITermLoan, index: number) => (
          <Grid item xs={12} key={termLoan.id || 'newTermLoan'}>
            <ClientTermLoan
              key={termLoan.id || 'newTermLoan'}
              clientId={clientId}
              isAdminRightsRole={isAdminRightsRole}
              termLoan={termLoan}
              createTermLoan={createTermLoan}
              updateTermLoan={updateTermLoan}
              deleteTermLoan={deleteTermLoan}
              removeTermLoan={() => handleRemoveTermLoan(index)}
              loanBalanceStartDate={loanBalanceStartDate}
              setRefreshCounter={setRefreshCounter}
              setExpandedTermLoan={setExpandedTermLoan}
              expandedTermLoan={expandedTermLoan}
              amortizationIdsLoading={amortizationIdsLoading}
            />
          </Grid>
        ))}
      </Grid>
    </Card>
  )
}

interface ITermLoanProps {
  clientId: string
  isAdminRightsRole: boolean
  termLoan: ITermLoan
  createTermLoan: (id: string, data: object) => Promise<any>
  updateTermLoan: (id: string, termLoanId: string, data: object) => Promise<any>
  deleteTermLoan: (id: string, termLoanId: string) => Promise<any>
  removeTermLoan: () => void
  loanBalanceStartDate: string
  setRefreshCounter: Dispatch<SetStateAction<number>>
  setExpandedTermLoan: Dispatch<SetStateAction<string | null>>
  expandedTermLoan: string | null
  amortizationIdsLoading: string[]
}

const ClientTermLoan = ({
  clientId,
  isAdminRightsRole,
  termLoan,
  createTermLoan,
  updateTermLoan,
  deleteTermLoan,
  removeTermLoan,
  loanBalanceStartDate,
  setRefreshCounter,
  setExpandedTermLoan,
  expandedTermLoan,
  amortizationIdsLoading,
}: ITermLoanProps) => {
  const history = useHistory()

  const handleNavigate = useCallback(
    (path) => {
      history.push(path)
    },
    [history],
  )
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false)
  const [deleteCallback, setDeleteCallback] = useState(null)

  const isNew = useMemo(() => !termLoan?.id, [termLoan])

  const isEdit = useMemo(() => moment(termLoan.closingDate) > moment().startOf('month'), [termLoan])

  const isDisabled = useMemo(
    () => !isAdminRightsRole || (!isNew && !isEdit),
    [isAdminRightsRole, isNew, isEdit],
  )

  const isAmortizationLoading = useMemo(
    () => amortizationIdsLoading.includes(termLoan.id || ''),
    [amortizationIdsLoading, termLoan.id],
  )

  const handleSaveTermLoan = useCallback(
    async (value) => {
      const { id, closingDate, terminationDate } = value
      const data = {
        ...value,
        id,
        closingDate: typeof closingDate === 'string' ? closingDate : dateToString(closingDate),
        endDate:
          !terminationDate || typeof terminationDate === 'string'
            ? terminationDate
            : dateToString(terminationDate),
      }
      //remove id from data
      delete data.id

      if (isNew) {
        await createTermLoan(clientId, {
          ...data,
          id: clientId,
        })
      } else {
        await updateTermLoan(clientId, termLoan.id, data)
        setRefreshCounter((prev) => prev + 1)
      }
    },
    [createTermLoan, clientId, isNew, termLoan, updateTermLoan, setRefreshCounter],
  )

  const handleDeleteTermLoan = useCallback(async () => {
    if (isNew) {
      removeTermLoan()
    } else {
      setIsDeleteModalShown(true)
      setDeleteCallback(() => async () => {
        await deleteTermLoan(clientId, termLoan.id)
      })
    }
  }, [clientId, deleteTermLoan, isNew, termLoan, removeTermLoan])

  const handleDeleteTermLoanConfirm = useCallback(async () => {
    deleteCallback && (await deleteCallback())
    setDeleteCallback(null)
    setIsDeleteModalShown(false)
  }, [deleteCallback])

  const initialValues = useMemo(
    () => ({
      ...termLoan,
      closingDate: termLoan.closingDate ? moment(termLoan.closingDate).toDate() : null,
      terminationDate: termLoan.terminationDate ? moment(termLoan.terminationDate).toDate() : null,
    }),
    [termLoan],
  )

  const termLoanTitle = useMemo(() => getTermLoanTitle(termLoan), [termLoan])

  const handleExpandClick = useCallback(() => {
    const termLoanId = termLoan.id || 'newTermLoan'
    setExpandedTermLoan((prev) => (prev === termLoanId ? null : termLoanId))
  }, [termLoan.id, setExpandedTermLoan])

  const isExpanded = useMemo(
    () => expandedTermLoan === termLoan.id || isNew,
    [expandedTermLoan, termLoan.id, isNew],
  )

  const isPaidOff = useMemo(
    () =>
      termLoan.loanBalance === 0 &&
      moment(termLoan.closingDate).isBefore(moment(loanBalanceStartDate), 'day'),
    [termLoan, loanBalanceStartDate],
  )

  const validate = useMemo(
    () =>
      makeValidate(
        Yup.object().shape({
          ...BASE_SCHEMA,
          closingDate: Yup.date()
            .typeError('Please type date in MM/DD/YY format')
            .required('Required')
            .min(
              moment(loanBalanceStartDate || undefined).startOf('month'),
              'Closing date cannot be for a locked month',
            ),
        }),
      ),
    [loanBalanceStartDate],
  )

  return (
    <>
      <Form
        initialValues={initialValues}
        onSubmit={handleSaveTermLoan}
        validate={validate}
        mutators={mutators}
        render={({ invalid, handleSubmit, dirty }) => {
          return (
            <form id={termLoan.id || 'newTermLoan'}>
              <Card
                expandable
                setIsExpanded={handleExpandClick}
                isExpanded={isExpanded}
                className={styles.card}
                title={
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <span>
                      {termLoanTitle}
                      <IconButton onClick={handleExpandClick} disableFocusRipple disableRipple>
                        {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </IconButton>
                      {isPaidOff && <span className={styles.paidOff}>Paid</span>}
                    </span>

                    <Box display="flex" alignItems="center">
                      {(isNew || isEdit) && (
                        <Button
                          className={styles.deleteButton}
                          color="secondary"
                          variant="contained"
                          disabled={invalid || !dirty}
                          onClick={handleSubmit}
                        >
                          {isNew ? 'Create' : 'Save'}
                        </Button>
                      )}
                      {isAdminRightsRole && !isDisabled && (
                        <IconButton disableFocusRipple disableRipple>
                          <DeleteIcon onClick={() => handleDeleteTermLoan()} />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                }
              >
                <Grid container spacing={2} justifyContent={'start'}>
                  {termLoanFieldConfig.map((field) => (
                    <Grid item xs={3} xl={2} key={field.label}>
                      <ClientTermLoanField
                        key={field.name}
                        name={field.name}
                        label={field.label}
                        type={field.type}
                        options={field.options}
                        disabled={isDisabled || field.disabled}
                        minDate={
                          field.name === 'closingDate'
                            ? moment(loanBalanceStartDate || undefined).toDate()
                            : null
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
                <br />
                {!isNew && (
                  <ClientTermLoanAmortization
                    isLoading={isAmortizationLoading}
                    termLoan={termLoan}
                    setRefreshCounter={setRefreshCounter}
                  />
                )}
              </Card>
              <RouteLeavingGuard
                when={dirty}
                navigate={handleNavigate}
                shouldBlockNavigation={() => dirty}
                helperText={`You have unsaved changes for ${termLoanTitle}. If you leave before saving, your changes will be lost.`}
                buttonText="Save changes"
                alternateSubmit={handleSubmit}
                isAlternateSubmitInvalid={invalid}
              />
            </form>
          )
        }}
      />
      {isDeleteModalShown && (
        <WarningModal
          warningMessage="Term Loan will be deleted."
          onConfirm={handleDeleteTermLoanConfirm}
          onCancel={() => setIsDeleteModalShown(false)}
          confirmText="Delete"
          cancelText="Cancel"
        />
      )}
    </>
  )
}

export default ClientTermLoans
