import React from 'react'
import TableCell from '../TableCell'

interface IProps {
  count: number
}

export const MultipleTableCells = ({ count }: IProps) => {
  return (
    <>
      {Array.from({ length: count }, (_, index) => (
        <TableCell key={index} />
      ))}
    </>
  )
}

export default MultipleTableCells
