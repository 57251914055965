import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import MainLayout from './MainLayout'

import { isBlankLayout } from '../../redux/router/selectors'
import {
  clientInfo,
  isAdminLoggedAsClient,
  isInitialized,
  isSidebarOpen,
  role,
  isProspectUser,
  user,
} from '../../redux/profile/selectors'
import { loadProfile, logoutAsClient } from '../../redux/profile/actions'
import { toggleNotesOn, toggleNotesOff } from '../../redux/notes/actions'
import { pathname } from '../../redux/router/selectors'
import { isNotesShown } from '../../redux/notes/selectors'
import { listSessions } from '../../redux/atlasai/actions'
const selector = createStructuredSelector({
  isInitialized,
  isBlankLayout,
  role,
  pathname,
  isSidebarOpen,
  isNotesShown,
  isAdminLoggedAsClient,
  clientInfo,
  isProspectUser,
  user,
})

const actions = {
  onInit: loadProfile,
  toggleNotesOff,
  toggleNotesOn,
  logoutAsClient,
  listSessions,
}

export { MainLayout }

export default connect(selector, actions)(MainLayout)
