import React, { useState, ReactNode, useCallback, useMemo, useRef } from 'react'
import styles from './CopyToClipboard.module.scss'
import cn from 'classnames'
import { ReactComponent as CopyIcon } from '@assets/images/copy-icon.svg'
import Tooltip from '@mui/material/Tooltip'
import CheckIcon from '@mui/icons-material/Check'

interface CopyButtonProps {
  tooltipText: string
  copied: boolean
  handleCopy: () => void
}

const CopyButton = ({ tooltipText, copied, handleCopy }: CopyButtonProps) => {
  return (
    <Tooltip title={tooltipText} placement="top">
      <div className={styles.iconWrapper} onClick={handleCopy}>
        {copied ? (
          <CheckIcon fontSize="small" color="success" className={styles.icon} />
        ) : (
          <CopyIcon className={styles.icon} />
        )}
      </div>
    </Tooltip>
  )
}

interface IProps {
  children: ReactNode
  textToCopy?: string | number
  iconPosition?: 'left' | 'right'
  className?: string
}


const CopyToClipboard = ({ children, textToCopy, iconPosition = 'left', className }: IProps) => {
  const [copied, setCopied] = useState(false)
  const contentRef = useRef<HTMLSpanElement>(null)

  const handleCopy = useCallback(() => {
    // Get text to copy - either from prop or from children's text content
    const textToUse =
      textToCopy ||
      (typeof children === 'string' ? children : contentRef.current?.textContent || '')

    navigator.clipboard.writeText(textToUse.toString()).then(() => {
      setCopied(true)
      setTimeout(() => setCopied(false), 3000)
    })
  }, [children, textToCopy])

  const tooltipText = useMemo(() => {
    return copied ? 'Copied!' : 'Copy'
  }, [copied])

  return (
    <div className={cn(styles.container, className, styles[iconPosition])}>
      {iconPosition === 'left' && (
        <CopyButton
          tooltipText={tooltipText}
          copied={copied}
          handleCopy={handleCopy}
        />
      )}

      <span ref={contentRef} className={styles.content}>
        {children}
      </span>

      {iconPosition === 'right' && (
        <CopyButton
          tooltipText={tooltipText}
          copied={copied}
          handleCopy={handleCopy}
        />
      )}
    </div>
  )
}

export default CopyToClipboard
