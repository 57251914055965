import { connect } from 'react-redux'
import { loadMoreSessions, deleteSession, selectSession } from '../../../redux/atlasai/actions'
import ChatSidebar from './ChatSidebar'
import { createStructuredSelector } from 'reselect'
import {
  sessions,
  isSessionsLoading,
  isSessionsLoadingMore,
  hasMoreSessions,
  sessionId,
} from '../../../redux/atlasai/selectors'

const actions = {
  loadMoreSessions,
  deleteSession,
  selectSession,
}

const selector = createStructuredSelector({
  sessions,
  sessionId,
  isSessionsLoading,
  isSessionsLoadingMore,
  hasMoreSessions,
})

export default connect(selector, actions)(ChatSidebar)
