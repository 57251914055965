import React, { useMemo } from 'react'

import genericSs from '@styles/generic.module.scss'

import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import { ISalesBySKUVisualizationTotals } from '@common/interfaces/bbc'

import { IFilter } from '@common/constants/filters'
import FormattedTableCell from '../Common/FormattedTableCell'

interface IProps {
  title: string
  totals: ISalesBySKUVisualizationTotals
  filtersConfig: IFilter[]
  isMonthlySalesBySKU: boolean
  missedColumns: number[]
}

const SalesBySKUVisualizationTableFooter = ({
  title,
  totals,
  filtersConfig,
  isMonthlySalesBySKU,
  missedColumns,
}: IProps) => {
  const titleField = useMemo(
    () => (title === 'Top 5' ? 'top5' : title === 'Top 10' ? 'top10' : 'all'),
    [title],
  )

  const item = useMemo(() => totals?.[titleField], [totals, titleField])
  if (!totals) return null

  return (
    <TableRow>
      <TableCell colSpan={1} className={genericSs.tableTextLeft}>
        {title}
      </TableCell>
      {filtersConfig
        .filter(({ type }) => type !== 'text')
        .map(({ field, type }) => {
          const value =
            isMonthlySalesBySKU &&
            (field.startsWith('totalSalesPerMonth') || field.startsWith('unitSalesPerMonth'))
              ? item[field.split('.')[0]][field.split('.')[1]]
              : item[field]

          return (
            <FormattedTableCell
              key={field}
              value={value}
              fieldType={type}
              nullCondition={value === null || !value}
            />
          )
        })}
      {isMonthlySalesBySKU && missedColumns.map((index) => <TableCell key={index} />)}
    </TableRow>
  )
}

export default SalesBySKUVisualizationTableFooter
