import React from 'react'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'

const UWDefaultNotesLoader = () => {
  return (
    <Box display="flex" flexDirection="column" mt={1}>
      <Box flex={12}>
        <Skeleton height={150} />
      </Box>
      <Box flex={12}>
        <Skeleton height={150} />
      </Box>
      <Box flex={12}>
        <Skeleton height={150} />
      </Box>
    </Box>
  )
}

export default UWDefaultNotesLoader
