import React, { useCallback, useEffect, useState } from 'react'
import { Box, Grid, MenuItem } from '@mui/material'
import Card from '../../Common/Card'
import Table from '../../Common/Table'
import TableContainer from '../../Common/TableContainer'
import TableHead from '../../Common/TableHead'
import TableRow from '../../Common/TableRow'
import TableCell from '../../Common/TableCell'
import TableBody from '../../Common/TableBody'
import genericSs from '@styles/generic.module.scss'
import styles from './CheckAliases.module.scss'
import TableLoader from '../../Common/TableLoader'
import SaveState from '../../Common/SaveState'
import Menu from '../../Common/Menu'
import AddEditAlias from './components/AddEditAlias'
import { ICheckAliasData } from '@common/interfaces/collection'
import { ILoadingData } from '../../../redux/types'
import { useLoadingData } from '../../../hooks/useLoadingData'
import { useParams } from 'react-router-dom'
import AddButton from './../AddButton'
interface IProps {
  checkAliases: ILoadingData<ICheckAliasData>
  listCheckAliases: (id: string) => void
  updateCheckAliases: (
    id: string,
    data: {
      aliases: string[]
    },
  ) => void
}

const CheckAliases = ({ checkAliases, listCheckAliases, updateCheckAliases }: IProps) => {
  const { id } = useParams<{ id: string }>()
  const { data, isLoading, isSaving, isSaved } = useLoadingData(checkAliases)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [selectedAlias, setSelectedAlias] = useState<string | null>(null)

  useEffect(() => {
    listCheckAliases(id)
  }, [id, listCheckAliases])

  const handleAdd = () => {
    setSelectedAlias(null)
    setDialogOpen(true)
  }

  const handleEdit = (alias: string) => {
    setSelectedAlias(alias)
    setDialogOpen(true)
  }

  const handleSubmit = useCallback(
    (newAlias: string) => {
      const currentAliases = data?.data?.aliases || []
      let updatedAliases: string[]

      if (selectedAlias) {
        // Edit mode: replace the old alias with the new one
        updatedAliases = currentAliases.map((a) => (a === selectedAlias ? newAlias : a))
      } else {
        // Add mode: append the new alias
        updatedAliases = [...currentAliases, newAlias]
      }

      updateCheckAliases(id, { aliases: updatedAliases })
    },
    [data?.data?.aliases, id, selectedAlias, updateCheckAliases],
  )

  const handleDelete = useCallback(
    (aliasToDelete: string) => {
      const updatedAliases = data?.data?.aliases.filter((a) => a !== aliasToDelete) || []
      updateCheckAliases(id, { aliases: updatedAliases })
    },
    [data?.data?.aliases, id, updateCheckAliases],
  )
  return (
    <Grid item xs={12} container>
      <Grid item xs={6}>
        <Card
          title="Check Aliases"
          withHeaderBorder={false}
          renderHeaderRightColumn={() => <AddButton onClick={handleAdd} />}
        >
          <TableContainer className={styles.table}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={genericSs.tableTextLeft}>Name</TableCell>
                  <TableCell className={genericSs.tableTextLeft}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableLoader columnsCount={2} rowsCount={5} />
                ) : (
                  data?.data?.aliases?.map((alias, index) => (
                    <TableRow key={index}>
                      <TableCell className={genericSs.tableTextLeft}>{alias}</TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        <Box display="flex" justifyContent="flex-end">
                          <Menu>
                            <MenuItem onClick={() => handleEdit(alias)}>Edit</MenuItem>
                            <MenuItem onClick={() => handleDelete(alias)}>Delete</MenuItem>
                          </Menu>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
            <Box display="flex" alignItems="center" justifyContent="right">
              <SaveState isSaving={isSaving} isSaved={isSaved} />
            </Box>
          </TableContainer>
        </Card>
      </Grid>

      <AddEditAlias
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onSubmit={handleSubmit}
        initialValue={selectedAlias || ''}
        isEdit={!!selectedAlias}
      />
    </Grid>
  )
}

export default CheckAliases
