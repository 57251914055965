import { IClientInfo, TermLoanStatus } from './client'
import { TermLoanActivityType } from './loanServicing'

export enum TermLoanDealType {
  Equipment = 'Equipment',
  TermLoan = 'Term Loan',
}

export interface ITermLoan {
  id: string
  clientName: string
  clientInfo?: IClientInfo
  status: TermLoanStatus
  boxLink?: string
  dealType: TermLoanDealType
  collateralName: string
  loanAmount: number
  amortization: number
  term: number
  baseRate: number
  abovePrime: number
  rate?: number
  closingDate: string
  terminationDate: string
  closingFeePercent: number
  closingFeeAmount: number
  miscFees: number
  wireFee: number
  loanBalance?: number
  termLoanBalances?: ITermLoanBalance[]
  amortizationSchedule?: IAmortizationSchedule[]
}

export interface ITermLoanBalance {
  termLoanId?: string
  termLoan?: ITermLoan
  clientName: string
  clientInfo?: IClientInfo
  recordDate: string
  collateralName: string
  originalLoanAmount: number
  payments: number
  fundings: number
  fees: number
  interest: number
  loanBalance: number
}

interface IAmortizationSchedule extends ITermLoanBalance {
  activity: ITermLoanActivity
}

export interface ITermLoanActivity {
  id: string
  recordDate: string
  amount: number
  type: TermLoanActivityType
  isEditable: boolean
}
