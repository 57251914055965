import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import { Link, generatePath } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import cn from 'classnames'
import LinkButton from '@mui/material/Link'
import InfiniteScroll from 'react-infinite-scroll-component'
import { ROUTES } from '../../constants/routes'
import styles from './CollectionAggregationRow.module.scss'
import genericSs from '@styles/generic.module.scss'
import { CollectionType, IAggregationDataRow } from '@common/interfaces/collection'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TableFiltersRow from '../Common/TableFiltersRow'
import TableLoader from '../Common/TableLoader'
import { MenuIcon } from '../Common/Icons'
import {
  debounceEventHandler,
  formatDateTime,
  formatPrice,
  formatDate,
} from '../../helpers/helpers'
import { PER_PAGE, COLLECTION_ROW_FILTERS_CONFIG } from '@common/constants/filters'
import useTable from '../../hooks/useTable'
import EditForm from './components/EditForm'
import { Menu, MenuItem } from '@mui/material'
import WarningModal from '../WarningModal'
import MultiSelectToolbar from '../MultiSelectToolbar'
import { ILoadingData } from 'src/redux/types'
import { IBankAccount } from '@common/interfaces/bankAccount'
import Button from '../Common/Button'
import { IClientInfo } from '@common/interfaces/client'
import useSummaryRow from '../../hooks/useSummaryRow'

interface IProps {
  clientName: string
  data: IAggregationDataRow[]
  filters: any
  banksAccountData: ILoadingData<{ data: IBankAccount[] }>
  loanBalanceStartDate: string
  clientsData: IClientInfo[]
  loadDebtors: (inputValue: string) => Promise<any>
  listAggregationRow: (params: object) => void
  addEntityInfo: (data: object) => Promise<any>
  listBankAccounts: (id: string, data?: object) => void
  updateAggregationRow: (id: string, data: object) => void
  deleteAggregationRow: (data: object) => void
  refetchAggregationList: () => void
}

const CollectionAggregationRow = ({
  clientName,
  banksAccountData,
  loanBalanceStartDate,
  data,
  filters,
  clientsData,
  loadDebtors,
  listAggregationRow,
  addEntityInfo,
  listBankAccounts,
  updateAggregationRow,
  deleteAggregationRow,
  refetchAggregationList,
}: IProps) => {
  const wrapperRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isButtonLoading, setIsButtonLoading] = useState({ type: null, value: false })
  const [selectedItem, setSelectedItem] = useState<IAggregationDataRow | null>(null)
  const [isEditModalShown, setIsEditModalShown] = useState(false)
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false)
  const [isPermanent, setIsPermanent] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const actionsMenuOpen = useMemo(() => Boolean(anchorEl), [anchorEl])

  const {
    handleSelectRow,
    setActiveItems,
    setActiveItem,
    handleOrderChange,
    orderBy,
    activeItem,
    activeItems,
    resetActiveItems,
  } = useTable({
    tableId: `${clientName}-aggregation-table`,
    sortDefault: {
      field: 'recordDate',
      direction: 'DESC',
    },
  })

  const debounceListAggregation = useMemo(
    () =>
      debounceEventHandler(async (data: any) => {
        !data.loadMore && setIsLoading(true)
        await listAggregationRow({
          ...data,
          clientName,
          filters,
          nestedRows: {
            keys: ['clientName'],
          },
        })
        setIsLoading(false)
      }, 500),
    [clientName, listAggregationRow, filters],
  )

  const loadMore = useCallback(() => {
    debounceListAggregation({
      loadMore: true,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
      page: Math.ceil(data?.length / PER_PAGE),
    })
  }, [orderBy, filters, debounceListAggregation, data])

  useEffect(() => {
    if (orderBy) {
      debounceListAggregation({
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
        filters,
        page: 0,
      })
    }
  }, [orderBy, filters, debounceListAggregation])

  useEffect(() => {
    if (!data) {
      debounceListAggregation({
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
        filters,
        page: 0,
      })
    }
  }, [data, debounceListAggregation, orderBy, filters])

  const tableId = useMemo(() => `${clientName}-aggregation`, [clientName])
  const totalCount = useMemo(() => +data?.[0]?.totalCount, [data])

  const handleClickMenu = useCallback((row, event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setSelectedItem(row)
  }, [])
  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
    setSelectedItem(null)
  }, [])

  const handleEdit = useCallback(() => {
    setIsEditModalShown(true)
  }, [])

  const handleArchive = useCallback((item) => {
    setIsPermanent(false)
    setSelectedItem(item)
    setIsDeleteModalShown(true)
  }, [])

  const handleDelete = useCallback((item) => {
    setIsPermanent(true)
    setSelectedItem(item)
    setIsDeleteModalShown(true)
  }, [])

  const handleDeleteCancel = useCallback(() => {
    setSelectedItem(null)
    setIsDeleteModalShown(false)
  }, [])

  const handleDeleteConfirm = useCallback(async () => {
    setIsButtonLoading({ type: null, value: true })
    await deleteAggregationRow({ ids: [selectedItem.id], isPermanent })
    await refetchAggregationList()
    setIsButtonLoading({ type: null, value: null })
    handleCloseMenu()
    setIsDeleteModalShown(false)
    setActiveItems([])
  }, [
    deleteAggregationRow,
    selectedItem,
    refetchAggregationList,
    isPermanent,
    handleCloseMenu,
    setIsButtonLoading,
    setActiveItems,
  ])

  const handleArchiveActiveItems = useCallback(() => {
    setIsPermanent(false)
    setIsDeleteModalShown(true)
  }, [])

  const handleDeleteActiveItems = useCallback(() => {
    setIsPermanent(true)
    setIsDeleteModalShown(true)
  }, [])

  const handleDeleteActiveItemsCancel = useCallback(() => {
    setIsDeleteModalShown(false)
  }, [])

  const handleDeleteActiveItemsConfirm = useCallback(async () => {
    setIsButtonLoading({ type: 'delete', value: true })
    await deleteAggregationRow({
      ids: data?.filter((item, index) => activeItems.includes(index))?.map(({ id }) => id),
      isPermanent,
    })
    await refetchAggregationList()
    setIsButtonLoading({ type: null, value: null })
    setIsDeleteModalShown(false)
    setActiveItems([])
  }, [
    data,
    deleteAggregationRow,
    isPermanent,
    setIsButtonLoading,
    refetchAggregationList,
    setActiveItems,
    activeItems,
  ])

  useEffect(() => {
    if (selectedItem?.clientId) {
      listBankAccounts(selectedItem?.clientId, {
        isExternal: false,
      })
    }
  }, [listBankAccounts, selectedItem])

  const areAnyActiveItemsEditable = useMemo(() => {
    return data
      ?.filter((item, index) => activeItems.includes(index))
      ?.some((item) => item.isEditable)
  }, [activeItems, data])

  const totalRow = useSummaryRow(data, activeItems, {
    sumFields: ['amount'],
  })

  if (!data && !isLoading) {
    return null
  }

  return (
    <TableContainer
      className={styles.table}
      onActiveRowsChange={setActiveItems}
      onActiveRowChange={setActiveItem}
      size="small"
    >
      <Table ref={wrapperRef}>
        <TableHead>
          <TableFiltersRow
            filters={COLLECTION_ROW_FILTERS_CONFIG}
            orderBy={orderBy}
            handleOrderChange={handleOrderChange}
          />
        </TableHead>
        <TableBody id={tableId} className={styles.scrollableDiv}>
          {isLoading ? (
            <TableLoader columnsCount={COLLECTION_ROW_FILTERS_CONFIG.length} />
          ) : (
            <InfiniteScroll
              dataLength={data?.length || 0}
              next={loadMore}
              hasMore={Boolean(data?.length && totalCount && data.length < totalCount)}
              loader={
                <TableLoader columnsCount={COLLECTION_ROW_FILTERS_CONFIG.length} rowsCount={1} />
              }
              scrollableTarget={tableId}
            >
              {data.map((item, index) => {
                return (
                  <TableRow
                    key={item.id}
                    data-index={index}
                    className={cn(styles.collectionAggregationRow, 'activableRow', {
                      activeRow: activeItems.includes(index),
                      currentActiveRow: activeItem === index,
                    })}
                    onClick={(event) => handleSelectRow(event, index)}
                  >
                    <TableCell className={genericSs.tableTextLeft}>
                      {item?.entityId ? (
                        <LinkButton
                          component={Link}
                          to={generatePath(ROUTES.ENTITY_PAGE, {
                            id: item.entityId,
                          })}
                        >
                          {item.debtor}
                        </LinkButton>
                      ) : (
                        <span>{item.debtor}</span>
                      )}
                    </TableCell>

                    <TableCell className={genericSs.tableTextLeft}>{item.paymentType}</TableCell>

                    <TableCell className={genericSs.tableTextRight}>
                      <Tooltip title={formatDateTime(item.processedAt)} placement="top">
                        <span>{formatDate(item.recordDate)}</span>
                      </Tooltip>
                    </TableCell>

                    <TableCell className={genericSs.tableTextLeft}>
                      <span className={genericSs.colorSecondary}>
                        {item.paymentType === CollectionType.Check ? (
                          <LinkButton
                            href={item.boxLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => window.open(item.boxLink, '_blank')}
                          >
                            {item.description}
                          </LinkButton>
                        ) : (
                          <Tooltip
                            title={item.description || ''}
                            placement="top"
                            disableHoverListener={
                              item.description?.length < 10 || !item.description
                            }
                            disableTouchListener
                          >
                            <span>{item.description}</span>
                          </Tooltip>
                        )}
                      </span>
                    </TableCell>

                    <TableCell className={genericSs.tableTextRight}>
                      <span className={genericSs.pricePrefix}>$</span>
                      {formatPrice(item.amount)}
                    </TableCell>

                    <TableCell className={genericSs.tableTextRight}>
                      {item.isEditable && (
                        <Box display="inline-box" ml={1}>
                          <MenuIcon
                            onClick={(event: React.MouseEvent<HTMLElement>) =>
                              handleClickMenu(item, event)
                            }
                            size="small"
                          />
                        </Box>
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
            </InfiniteScroll>
          )}
        </TableBody>
      </Table>
      <Menu
        open={actionsMenuOpen}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        className={styles.actionsMenu}
      >
        <MenuItem
          onClick={() => {
            handleArchive(selectedItem)
          }}
        >
          Mark as non-client related cash
        </MenuItem>
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        <MenuItem
          onClick={() => {
            handleDelete(selectedItem)
          }}
        >
          Delete
        </MenuItem>
      </Menu>
      {isDeleteModalShown && selectedItem && (
        <WarningModal
          warningMessage={
            isPermanent
              ? `This ${
                  selectedItem.paymentType === CollectionType.Wire ? 'wire' : 'check'
                } will be deleted.`
              : `This ${
                  selectedItem.paymentType === CollectionType.Wire ? 'wire' : 'check'
                } will be marked as non-client related cash.`
          }
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
          confirmText="Yes, confirm"
          cancelText="Cancel"
          isLoading={isButtonLoading?.value}
        />
      )}

      {isDeleteModalShown && !selectedItem && activeItems.length > 1 && (
        <WarningModal
          warningMessage={
            isPermanent
              ? 'These checks and wires will be deleted.'
              : 'These checks and wires will be marked as non-client related cash.'
          }
          onConfirm={handleDeleteActiveItemsConfirm}
          onCancel={handleDeleteActiveItemsCancel}
          confirmText="Yes, confirm"
          cancelText="Cancel"
          isLoading={isButtonLoading?.value}
        />
      )}
      {isEditModalShown && selectedItem && (
        <EditForm
          isEditModalShown={isEditModalShown}
          selectedItem={selectedItem}
          loadDebtors={loadDebtors}
          addEntityInfo={addEntityInfo}
          clientsData={clientsData}
          banksAccountData={banksAccountData?.data?.data || []}
          loanBalanceStartDate={loanBalanceStartDate}
          handleCloseMenu={handleCloseMenu}
          updateAggregationRow={updateAggregationRow}
          setSelectedItem={setSelectedItem}
          setIsEditModalShown={setIsEditModalShown}
          refetchAggregationList={refetchAggregationList}
        />
      )}
      <MultiSelectToolbar
        activeItems={activeItems}
        resetActiveItems={resetActiveItems}
        totalSum={`$${formatPrice(totalRow?.amount)}`}
      >
        {activeItems.length > 1 && areAnyActiveItemsEditable && (
          <Box display="flex" gap={1}>
            <Button color="primary" variant="outlined" onClick={handleArchiveActiveItems}>
              Non Client Cash
            </Button>
            <Button color="primary" variant="outlined" onClick={handleDeleteActiveItems}>
              Delete
            </Button>
          </Box>
        )}
      </MultiSelectToolbar>
    </TableContainer>
  )
}

export default CollectionAggregationRow
