import React, { ChangeEvent, useCallback, useState, useEffect, useMemo, useRef } from 'react'
import Drawer from '@mui/material/Drawer'
import { useLocation, useParams } from 'react-router'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import Box from '@mui/material/Box'
import queryString from 'query-string'
import cn from 'classnames'

import styles from './RightSidebar.module.scss'

import NotesContainer from '../Notes'
import FlagContainer from '../Flags'
import NewsContainer from '../NewsContainer'
import BBCLogsContainer from '../BBCLogsContainer'
import { WorkflowTypes } from '@common/interfaces/notes'
import { IBorrowingBase, IOngoingReporting } from '@common/interfaces/bbc'
import { IOPSReporting } from '@common/interfaces/prospects'
import { IEntityInfo } from '@common/interfaces/entityInfo'
import { IClientInfo } from '@common/interfaces/client'

enum Tabs {
  flags = 'Flags',
  notes = 'Notes',
  news = 'News',
  logs = 'Logs',
}

interface IProps {
  isNotesShown: boolean
  workflow: WorkflowTypes
  bbc: IBorrowingBase
  entityInfo: IEntityInfo
  reporting: IOPSReporting
  clientInfo: IClientInfo
  ongoingReporting: IOngoingReporting
  newFlagsCount: number
  getNewFlagsCount: ({ id, workflow }: { id: string; workflow: WorkflowTypes }) => void
}

const RightSidebar = ({
  isNotesShown,
  workflow,
  bbc,
  entityInfo,
  reporting,
  clientInfo,
  ongoingReporting,
  getNewFlagsCount,
  newFlagsCount,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const [tabValue, setTabValue] = useState('')
  const { search }: { search: string; pathname: string } = useLocation()
  const { noteId } = useMemo(
    () =>
      queryString.parse(search, {
        parseBooleans: true,
      }) as { noteId: string },
    [search],
  )
  const noteIdRef = useRef(noteId)

  useEffect(() => {
    noteIdRef.current = noteId
  }, [noteId])

  const workflowConditions = useMemo(
    () => ({
      isBBC: workflow === WorkflowTypes.bbc && bbc?.id && bbc.id === id,
      isEntity: workflow === WorkflowTypes.entityPage && entityInfo?.id,
      isOps: workflow === WorkflowTypes.opsFlow && reporting?.id,
      isClientPage: workflow === WorkflowTypes.clientPage && clientInfo?.id,
      isOngoingReporting: workflow === WorkflowTypes.ongoingReporting && ongoingReporting?.id,
      isDueDiligence: workflow === WorkflowTypes.dueDiligencePage && clientInfo?.id,
      isHomePage: workflow === WorkflowTypes.homePage,
      isClientManagement: workflow === WorkflowTypes.clientManagement,
    }),
    [workflow, bbc?.id, id, entityInfo?.id, reporting?.id, clientInfo?.id, ongoingReporting?.id],
  )

  useEffect(() => {
    if (
      workflow &&
      ![
        WorkflowTypes.clientPage,
        WorkflowTypes.entityPage,
        WorkflowTypes.homePage,
        WorkflowTypes.clientManagement,
      ].includes(workflow) &&
      tabValue === Tabs.news
    ) {
      setTabValue(Tabs.notes)
    }
  }, [workflow, tabValue])

  const handleChangeTab = useCallback((event: ChangeEvent<{}>, newValue: Tabs) => {
    setTabValue(newValue)
  }, [])

  useEffect(() => {
    if (id && workflowConditions?.isBBC && !noteIdRef.current) {
      setTabValue(Tabs.flags)
    } else if (workflowConditions?.isHomePage || workflowConditions?.isClientManagement) {
      setTabValue(Tabs.news)
    } else {
      setTabValue(Tabs.notes)
    }
  }, [
    id,
    workflowConditions?.isBBC,
    workflowConditions?.isHomePage,
    workflowConditions?.isClientManagement,
    workflowConditions?.isClientPage,
    workflowConditions?.isEntity,
  ])

  useEffect(() => {
    if (id && workflow) {
      getNewFlagsCount({ id, workflow })
    }
  }, [id, workflow, getNewFlagsCount])

  if (!tabValue) {
    return null
  }

  return (
    <Drawer
      className={cn(styles.drawer, {
        [styles.expanded]: isNotesShown,
      })}
      classes={{
        paper: styles.drawerPaper,
      }}
      variant="persistent"
      anchor="right"
      open={isNotesShown}
    >
      <TabContext value={tabValue}>
        <Box>
          <TabList
            onChange={handleChangeTab}
            TabIndicatorProps={{
              style: {
                backgroundColor: '#0066F5',
                height: '3px',
              },
            }}
          >
            {!workflowConditions?.isHomePage && !workflowConditions?.isClientManagement && (
              <Tab
                label={<div className={styles.tabName}>Notes</div>}
                value={Tabs.notes}
                className={styles.tabNameContainer}
              />
            )}

            {!workflowConditions?.isHomePage && !workflowConditions?.isClientManagement && (
              <Tab
                label={
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <div className={styles.tabName}>Flags</div>
                    {newFlagsCount > 0 ? (
                      <div className={styles.flagCount}>{newFlagsCount}</div>
                    ) : (
                      ''
                    )}
                  </Box>
                }
                value={Tabs.flags}
                className={styles.tabNameContainer}
              />
            )}

            {[WorkflowTypes.clientPage, WorkflowTypes.bbc].includes(workflow) && (
              <Tab
                label={<div className={styles.tabName}>Logs</div>}
                value={Tabs.logs}
                className={styles.tabNameContainer}
              />
            )}

            {[
              WorkflowTypes.clientPage,
              WorkflowTypes.entityPage,
              WorkflowTypes.homePage,
              WorkflowTypes.clientManagement,
            ].includes(workflow) && (
              <Tab
                label={<div className={styles.tabName}>News</div>}
                value={Tabs.news}
                className={styles.tabNameContainer}
              />
            )}
          </TabList>
        </Box>
        <TabPanel value={Tabs.notes} className={styles.tabPanel}>
          <NotesContainer workflow={workflow} />
        </TabPanel>
        <TabPanel value={Tabs.flags} className={styles.tabPanel}>
          <FlagContainer workflow={workflow} />
        </TabPanel>
        <TabPanel value={Tabs.news} className={styles.tabPanel}>
          <NewsContainer workflow={workflow} />
        </TabPanel>
        <TabPanel value={Tabs.logs} className={styles.tabPanel}>
          <BBCLogsContainer workflow={workflow} />
        </TabPanel>
      </TabContext>
    </Drawer>
  )
}

export default RightSidebar
