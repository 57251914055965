import {
  IOngoingReportingSummaryData,
  IOngoingReportingSummaryHeaders,
  OngoingReportingType,
} from '@common/interfaces/bbc'

export const getReconciliation = (
  statementType: OngoingReportingType,
  dataSummary: Partial<Record<OngoingReportingType, IOngoingReportingSummaryData>>,
  dataSummaryHeaders: IOngoingReportingSummaryHeaders,
): { field: string; amounts: number[]; instructions: string }[] => {
  if (!dataSummary || !dataSummaryHeaders || !statementType) {
    return []
  }
  if (
    statementType === OngoingReportingType.IncomeStatement ||
    statementType === OngoingReportingType.IncomeStatementProjections
  ) {
    return [
      {
        instructions: 'Net Revenue - (Gross Revenue - Trade Discounts - Returns) = 0',
        field: 'net_revenue',
        amounts: dataSummaryHeaders?.[statementType]?.map(
          (header, index) =>
            Math.round(
              ((+dataSummary[statementType]?.net_revenue?.[index] || 0) -
                (+dataSummary[statementType]?.gross_revenue?.[index] || 0) +
                Math.abs(+dataSummary[statementType]?.trade_discounts?.[index] || 0) +
                Math.abs(+dataSummary[statementType]?.returns_refunds?.[index] || 0)) *
                100,
            ) / 100,
        ),
      },
      {
        instructions: 'Total Opex - (Payroll + Sales & Marketing + R&D + Other Opex) = 0',
        field: 'total_operating_expenses',
        amounts: dataSummaryHeaders?.[statementType]?.map(
          (header, index) =>
            Math.round(
              ((+dataSummary[statementType]?.total_operating_expenses?.[index] || 0) -
                (+dataSummary[statementType]?.payroll?.[index] || 0) -
                (+dataSummary[statementType]?.sales_and_marketing?.[index] || 0) -
                (+dataSummary[statementType]?.research_and_development?.[index] || 0) -
                (+dataSummary[statementType]?.other_operating_expenses?.[index] || 0)) *
                100,
            ) / 100,
        ),
      },
      {
        instructions:
          'Net Income - (Operating Income - Interest - Depreciation - Taxes - Other Expenses + Other Income) = 0',
        field: 'net_income',
        amounts: dataSummaryHeaders?.[statementType]?.map(
          (header, index) =>
            Math.round(
              ((+dataSummary[statementType]?.net_income?.[index] || 0) -
                (+dataSummary[statementType]?.operating_income?.[index] || 0) +
                (+dataSummary[statementType]?.interest_expense?.[index] || 0) +
                (+dataSummary[statementType]?.depreciation_and_amortization?.[index] || 0) +
                (+dataSummary[statementType]?.taxes?.[index] || 0) +
                (+dataSummary[statementType]?.other_expenses?.[index] || 0) -
                (+dataSummary[statementType]?.other_income?.[index] || 0)) *
                100,
            ) / 100,
        ),
      },
    ]
  } else if (
    statementType === OngoingReportingType.BalanceSheet ||
    statementType === OngoingReportingType.BalanceSheetProjections
  ) {
    return [
      {
        instructions: 'Total Assets - Total Liabilities - Total Equity = 0',
        field: 'assets',
        amounts: dataSummaryHeaders?.[statementType]?.map(
          (header, index) =>
            Math.round(
              ((+dataSummary[statementType]?.total_assets?.[index] || 0) -
                (+dataSummary[statementType]?.total_liabilities?.[index] || 0) -
                (+dataSummary[statementType]?.total_shareholders_equity?.[index] || 0)) *
                100,
            ) / 100,
        ),
      },
    ]
  }
  return []
}
