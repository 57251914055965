import React, { useEffect, useCallback, useMemo, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { Form } from 'react-final-form'
import cn from 'classnames'
import InfiniteScroll from 'react-infinite-scroll-component'
import styles from './OutstandingParticipationWires.module.scss'
import genericSs from '@styles/generic.module.scss'
import { debounceEventHandler, formatDate, formatPercent, formatPrice } from '../../helpers/helpers'
import Card from '../Common/Card'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TableBody from '../Common/TableBody'
import { IFilter } from '@common/constants/filters'
import TableFiltersRow from '../Common/TableFiltersRow'
import {
  PARTICIPATION_OUTSTANDING_WIRES_FILTERS_CONFIG,
  PARTICIPANT_PARTICIPATION_OUTSTANDING_WIRES_FILTERS_CONFIG,
  PER_PAGE,
} from '@common/constants/filters'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import FilterContainer from '../Filters/FilterContainer'
import { ILoadingData } from '../../redux/types'
import {
  IOutstandingParticipationWiresData,
  IOutstandingParticipationWire,
  ParticipationWireDirections,
} from '@common/interfaces/participant'
import useTable from '../../hooks/useTable'
import FormField from '../Common/FormField'
import moment from 'moment'
import { Link as RouterLink, generatePath } from 'react-router-dom'
import { ROUTES } from '../../constants/routes'
import Link from '@mui/material/Link'
import { DATE_DATABASE_FORMAT } from '../../constants/common'
import WarningModal from '../WarningModal'
import TableLoader from '../Common/TableLoader'
import { usePermissions } from '../../helpers/permissionContext'
import { ReceiveIcon, SendIcon } from '../Common/Icons'
import ParticipationActivityMenu from '../Client/ClientLoanLedger/ParticipationActivityMenu'
import MultiSelectToolbar from '../MultiSelectToolbar'

const today = moment().format(DATE_DATABASE_FORMAT)
const monthAgo = moment().subtract(1, 'month').format(DATE_DATABASE_FORMAT)

const OutstandingParticipationWire = ({
  wire,
  createParticipationWire,
  loanBalanceStartDate,
  handleSelectRow,
  activeItems,
  activeItem,
  index,
  refetchParticipationWires,
  isParticipant,
  isParticipantPage,
}: {
  wire: IOutstandingParticipationWire
  createParticipationWire: (id: string, participationId: string, data: object) => void
  loanBalanceStartDate: string
  handleSelectRow: (event: any, index: number) => void
  activeItems: number[]
  activeItem: number
  index: number
  refetchParticipationWires: () => void
  isParticipant?: boolean
  isParticipantPage?: boolean
}) => {
  const [isSending, setIsSending] = useState(false)
  const [currentValues, setCurrentValues] = useState({})
  const [isConfirmModalShown, setIsConfirmModalShown] = useState(false)
  const [refreshCounter, setRefreshCounter] = useState(0)

  const initialValues = useMemo(
    () => ({
      amount: wire.amount?.toFixed(2),
      type: wire.type,
      direction: wire.direction,
      recordDate: wire.recordDate,
    }),
    [wire],
  )

  const label = useMemo(() => {
    if (wire.confirmed) {
      return wire.direction === ParticipationWireDirections.Outgoing ? 'Sent' : 'Received'
    }
    return wire.direction === ParticipationWireDirections.Outgoing ? 'Send' : 'Receive'
  }, [wire])

  useEffect(() => {
    refreshCounter && refetchParticipationWires()
  }, [refreshCounter, refetchParticipationWires])

  const handleSubmitWire = useCallback(
    async (values, { isConfirmed = false }) => {
      if (!moment(values.recordDate).isSame(moment(today), 'day') && !isConfirmed) {
        setIsConfirmModalShown(true)
        setCurrentValues(values)
        return
      }
      setIsSending(true)
      await createParticipationWire(wire.clientId, wire.participationId, values)
      setIsConfirmModalShown(false)
      setCurrentValues({})
      setTimeout(async () => {
        await refetchParticipationWires()
        setIsSending(false)
      }, 500)
    },
    [createParticipationWire, wire, refetchParticipationWires],
  )

  const handleSentCancel = useCallback(() => {
    setIsConfirmModalShown(false)
    setCurrentValues({})
  }, [])

  const handleSentConfirm = useCallback(async () => {
    await handleSubmitWire(currentValues, {
      isConfirmed: true,
    })
  }, [currentValues, handleSubmitWire])

  return (
    <>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmitWire}
        render={({ handleSubmit, form }) => (
          <TableRow
            key={`${wire.id}-${index}-${wire.type}-${wire.confirmed}`}
            data-index={index}
            className={cn('activableRow', {
              activeRow: activeItems.includes(index),
              currentActiveRow: activeItem === index,
            })}
            onClick={(event) => handleSelectRow(event, index)}
          >
            {!isParticipant && !isParticipantPage && (
              <TableCell className={genericSs.tableTextLeft}>
                <Link
                  component={RouterLink}
                  to={generatePath(ROUTES.PARTICIPANTS_PAGE, {
                    id: wire.participantId,
                  })}
                >
                  {wire.participant}
                </Link>
              </TableCell>
            )}
            <TableCell className={genericSs.tableTextLeft}>
              <Link
                component={RouterLink}
                to={generatePath(ROUTES.CLIENT_PAGE, {
                  id: wire.clientId,
                })}
              >
                {wire.clientName}
              </Link>
            </TableCell>

            {isParticipant && (
              <TableCell className={genericSs.tableTextLeft}>
                {wire.direction} {wire.type}
              </TableCell>
            )}

            <TableCell className={genericSs.tableTextRight}>
              ${formatPrice(wire.participantExposureAmount)}
            </TableCell>
            <TableCell className={genericSs.tableTextRight}>
              {formatPercent(wire.participantExposurePercentage)}
            </TableCell>

            <TableCell className={genericSs.tableTextRight}>
              {!wire.confirmed && !isParticipant ? (
                <FormField name="amount" disabled={wire.confirmed} type="currency" size="small" />
              ) : (
                `$${formatPrice(wire.amount)}`
              )}
            </TableCell>
            <TableCell className={genericSs.tableTextRight}>
              {!wire.confirmed && !isParticipant ? (
                <FormField
                  name="recordDate"
                  disabled={wire.confirmed}
                  size="small"
                  type="date"
                  minDate={moment(loanBalanceStartDate || undefined)
                    .startOf('day')
                    .toDate()}
                />
              ) : (
                formatDate(wire.recordDate)
              )}
            </TableCell>

            {!isParticipant && (
              <TableCell className={genericSs.tableTextLeft}>
                {wire.direction} {wire.type}
              </TableCell>
            )}
            {!isParticipant && (
              <TableCell className={genericSs.tableTextRight}>
                {wire.confirmed ? (
                  <>
                    <span className={genericSs.greenTag}>{label}</span>
                    <ParticipationActivityMenu
                      clientId={wire.clientId}
                      participationWire={wire}
                      effectiveParticipations={[]}
                      setRefreshCounter={setRefreshCounter}
                      withEdit={false}
                    />
                  </>
                ) : wire.direction === ParticipationWireDirections.Outgoing ? (
                  <SendIcon
                    isLoading={isSending}
                    disabled={isSending}
                    onClick={handleSubmit}
                    size="small"
                  />
                ) : (
                  <ReceiveIcon
                    isLoading={isSending}
                    disabled={isSending}
                    onClick={handleSubmit}
                    size="small"
                  />
                )}
              </TableCell>
            )}
          </TableRow>
        )}
      />
      {isConfirmModalShown && (
        <WarningModal
          warningMessage="Are you sure you want to apply this? It is not set for today."
          onConfirm={handleSentConfirm}
          onCancel={handleSentCancel}
          confirmText="Apply"
          cancelText="Cancel"
        />
      )}
    </>
  )
}

interface IProps {
  outstandingParticipationWires: ILoadingData<IOutstandingParticipationWiresData>
  listOutstandingWires: (params?: {
    filters?: object
    page?: number
    perPage?: number
    orderBy?: string
    orderDirection?: string
  }) => void
  createParticipationWire: (id: string, participationId: string, data: object) => void
  loanBalanceStartDate: string
  participantId?: string
}

const OutstandingParticipationWires = ({
  outstandingParticipationWires,
  listOutstandingWires,
  createParticipationWire,
  loanBalanceStartDate,
  participantId,
}: IProps) => {
  const wrapperRef = useRef(null)

  const { isParticipant } = usePermissions()

  const filtersConfig = useMemo(() => {
    return isParticipant
      ? PARTICIPANT_PARTICIPATION_OUTSTANDING_WIRES_FILTERS_CONFIG
      : PARTICIPATION_OUTSTANDING_WIRES_FILTERS_CONFIG.filter(
          (filter) => filter.field !== 'participant' || !participantId,
        )
  }, [isParticipant, participantId])

  const filtersValidate = buildFiltersValidateSchema(filtersConfig)
  const filtersDefaults = buildFiltersDefaults(
    filtersConfig,
    participantId
      ? {}
      : {
          recordDateFrom: isParticipant ? monthAgo : today,
          recordDateTo: today,
        },
  )

  const { outstandingWires, totalCount, isLoading } = useMemo(
    () => ({
      outstandingWires: outstandingParticipationWires?.data?.data,
      totalCount: outstandingParticipationWires?.data?.totalCount,
      isLoading: outstandingParticipationWires?.isLoading,
    }),
    [outstandingParticipationWires],
  )

  const {
    filters,
    handleFiltersChange,
    handleOrderChange,
    orderBy,
    activeItem,
    activeItems,
    handleSelectRow,
    setActiveItem,
    setActiveItems,
    resetActiveItems,
    quickFilter,
    handleQuickFilterChange,
  } = useTable({
    tableId: 'participationWires',
    sortDefault: {
      field: 'record_date',
      direction: 'DESC',
    },
    filtersDefaults,
    quickFilterDefault: isParticipant ? 'Last 30 Days' : 'Today',
  })

  const fetchOutgoingWire = useCallback(
    (values) => {
      const params = {
        ...values,
        participantId,
        filters: {
          ...values.filters,
        },
      }
      listOutstandingWires(params)
    },
    [listOutstandingWires, participantId],
  )

  const debounceListOutgoingWire = useMemo(
    () => debounceEventHandler(fetchOutgoingWire, 500),
    [fetchOutgoingWire],
  )

  const fetchParticipationWires = useCallback(() => {
    debounceListOutgoingWire({
      page: 0,
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [debounceListOutgoingWire, filters, orderBy])

  useEffect(() => {
    fetchParticipationWires()
  }, [fetchParticipationWires])

  const loadMore = useCallback(() => {
    fetchOutgoingWire({
      loadMore: true,
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      page: Math.ceil(totalCount / PER_PAGE),
    })
  }, [orderBy, filters, fetchOutgoingWire, totalCount])

  const totalAmount = useMemo(() => {
    const total = (outstandingWires || [])
      .filter((_, index) => activeItems.includes(index))
      .reduce(
        (result, row) => {
          result.totalAmount += +row.amount

          return result
        },
        {
          totalAmount: 0,
        },
      )
    return `$${formatPrice(total?.totalAmount)}`
  }, [outstandingWires, activeItems])

  const title = useMemo(() => {
    if (isParticipant) {
      return 'Transfers'
    } else if (participantId) {
      return 'Activity'
    }
    return 'Participations'
  }, [isParticipant, participantId])

  const isParticipantPage = useMemo(() => !!participantId, [participantId])

  return (
    <Box py={1}>
      <Grid container spacing={3} ref={wrapperRef}>
        <Grid item xs={12}>
          <Card noHeaderMargin withBorder={isParticipantPage} noPadding={!isParticipantPage}>
            <TableContainer
              className={cn(styles.outgoingWireTable, {
                [styles.outgoingWireTableParticipantPage]: participantId,
              })}
              onActiveRowsChange={setActiveItems}
              onActiveRowChange={setActiveItem}
            >
              <Form
                validate={filtersValidate}
                onSubmit={handleFiltersChange}
                initialValues={filters}
                mutators={{
                  setFieldData: ([field, value], state, { changeValue }) => {
                    changeValue(state, field, () => value)
                  },
                }}
                render={({
                  values,
                  handleSubmit: handleSubmitFilters,
                  form: { mutators: filterMutators },
                }) => (
                  <FilterContainer
                    filters={filtersConfig as IFilter[]}
                    handleSubmit={handleSubmitFilters}
                    mutators={filterMutators}
                    values={values}
                    appliedFilters={filters}
                    title={title}
                    appliedQuickFilter={quickFilter}
                    handleAppliedQuickFilterChange={handleQuickFilterChange}
                  />
                )}
              />
              <Table>
                <TableHead>
                  <TableFiltersRow
                    filters={filtersConfig}
                    orderBy={orderBy}
                    handleOrderChange={handleOrderChange}
                  />
                </TableHead>

                <TableBody id="scrollableTable">
                  {!outstandingWires || isLoading ? (
                    <TableLoader columnsCount={filtersConfig.length} rowsCount={40} />
                  ) : (
                    <InfiniteScroll
                      dataLength={outstandingWires?.length}
                      next={loadMore}
                      hasMore={outstandingWires?.length < totalCount}
                      loader={<TableLoader columnsCount={filtersConfig.length} rowsCount={1} />}
                      scrollableTarget="scrollableTable"
                    >
                      {outstandingWires?.map((wire, index) => (
                        <OutstandingParticipationWire
                          key={`${wire.id}-${index}-${wire.type}-${wire.confirmed}`}
                          wire={wire}
                          createParticipationWire={createParticipationWire}
                          loanBalanceStartDate={loanBalanceStartDate}
                          handleSelectRow={handleSelectRow}
                          activeItems={activeItems}
                          activeItem={activeItem}
                          index={index}
                          isParticipant={isParticipant}
                          isParticipantPage={isParticipantPage}
                          refetchParticipationWires={fetchParticipationWires}
                        />
                      ))}
                    </InfiniteScroll>
                  )}
                </TableBody>
                <MultiSelectToolbar
                  activeItems={activeItems}
                  totalSum={totalAmount}
                  resetActiveItems={resetActiveItems}
                />
              </Table>
              {totalCount > 0 && (
                <div className={styles.itemsCount}>
                  {outstandingWires?.length} / {totalCount}
                </div>
              )}
            </TableContainer>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

export default OutstandingParticipationWires
