import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Link from '@mui/material/Link'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'
import cn from 'classnames'

import styles from './SelectedFiles.module.scss'
import genericSs from '@assets/styles/generic.module.scss'

import { IFile, IFileSheet } from '@common/interfaces/box'
import { ReactComponent as ExcelIcon } from '@assets/images/excel-icon.svg'
import { RegenerateIcon, MenuIcon } from '../Common/Icons'
import { excelOnlineLink } from '../../constants/common'
import SelectField from '../Common/SelectField'

interface IProps {
  file: IFile
  fileSheet: IFileSheet
  isPrimary: boolean
  handleAddFileSheet?: (id: string) => Promise<void>
  handleSelectFileSheet?: (id: string, sheetName: string, fileSheetId: string) => Promise<void>
  handleReselectFileSheet: (id: string, sheetName: string) => Promise<void>
  handleClearFileSheet: (id: string, sheetName: string, fileSheetId: string) => Promise<void>
  handleDeleteFile: (id: string) => void
  disabled?: boolean
  isMultiple?: boolean
  fileLoading?: { id: string; sheetName: string }
}

const SelectedFilesRow = ({
  file: { id, fileName, name, link, fileId, sheetNames },
  fileSheet: { id: fileSheetId, sheetName, data },
  isPrimary,
  handleAddFileSheet,
  handleSelectFileSheet,
  handleReselectFileSheet,
  handleClearFileSheet,
  handleDeleteFile,
  disabled,
  isMultiple,
  fileLoading,
}: IProps) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const isMenuOpen = useMemo(() => Boolean(anchorEl), [anchorEl])

  const [sheetNameValue, setSheetNameValue] = useState(sheetName)

  const isLoading = useMemo(
    () => fileLoading.id === id && fileLoading.sheetName === sheetNameValue,
    [fileLoading, id, sheetNameValue],
  )

  useEffect(() => {
    setSheetNameValue(sheetName)
  }, [sheetName])

  const handleClickMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleAdd = useCallback(() => {
    handleCloseMenu()
    return handleAddFileSheet && handleAddFileSheet(id)
  }, [handleAddFileSheet, id, handleCloseMenu])

  const handleEdit = useCallback(() => {
    handleCloseMenu()
    window.open(excelOnlineLink(fileId), '_blank')
  }, [fileId, handleCloseMenu])

  const handleChangeSheetName = useCallback(
    ({ target: { value } }) => {
      if (handleSelectFileSheet) {
        setSheetNameValue(value)
        handleSelectFileSheet(id, value, fileSheetId)
      }
    },
    [handleSelectFileSheet, id, fileSheetId],
  )

  const handleReselect = useCallback(() => {
    handleCloseMenu()
    return handleReselectFileSheet(id, sheetName)
  }, [handleReselectFileSheet, id, sheetName, handleCloseMenu])

  const handleClear = useCallback(() => {
    handleCloseMenu()
    return handleClearFileSheet(id, sheetName, fileSheetId)
  }, [handleClearFileSheet, id, sheetName, fileSheetId, handleCloseMenu])

  const handleDelete = useCallback(() => {
    handleCloseMenu()
    if (!fileSheetId && !isPrimary) {
      return handleClearFileSheet(id, sheetName, fileSheetId)
    }
    return handleDeleteFile(id)
  }, [
    handleDeleteFile,
    handleClearFileSheet,
    id,
    sheetName,
    fileSheetId,
    isPrimary,
    handleCloseMenu,
  ])

  const sheetOptions = useMemo(
    () =>
      (sheetNames || []).map((sheetName) => ({
        value: sheetName,
        label: sheetName,
      })),
    [sheetNames],
  )

  const newError = data?.error ? (
    <div>
      <h3 className={styles.errorTitle}>Unable to read file</h3>
      <span>{data.error}</span>
    </div>
  ) : (
    ''
  )

  return (
    <Tooltip
      title={newError}
      placement="left"
      arrow
      classes={{ tooltip: styles.tooltipError, arrow: styles.tooltipArrow }}
    >
      <div
        className={cn(styles.row, {
          [styles.selected]: !!fileSheetId,
          [styles.error]: !!data?.error,
        })}
      >
        {isPrimary && (
          <Link href={link} target="_blank" className={styles.link}>
            <ExcelIcon />
          </Link>
        )}

        <div
          className={cn(styles.nameContainer, {
            [styles.nameContainerPrimary]: isPrimary,
          })}
        >
          {isPrimary ? (
            <Tooltip title={fileName || name} placement="top">
              <div className={styles.fileName}>{fileName || name}</div>
            </Tooltip>
          ) : (
            <div className={styles.childBorders} />
          )}
        </div>

        <div className={styles.sheetContainer}>
          <SelectField
            placeholder="Tab"
            fullWidth
            className={styles.sheetNameSelect}
            name="sheetName"
            useFinalForm={false}
            options={sheetOptions}
            onChange={handleChangeSheetName}
            value={sheetNameValue || ''}
            disabled={disabled || !handleSelectFileSheet}
            selectSize="medium"
          />
        </div>

        <div
          className={cn(styles.icons, {
            [genericSs.disabled]: disabled,
          })}
        >
          <RegenerateIcon
            title="Reselect File"
            action={handleReselect}
            isLoading={isLoading}
            disabled={!fileSheetId || disabled}
          />

          <MenuIcon onClick={handleClickMenu} isActive={isMenuOpen} />

          <Menu open={isMenuOpen} onClose={handleCloseMenu} anchorEl={anchorEl}>
            {handleAddFileSheet &&
              isPrimary &&
              fileSheetId &&
              sheetNames.length > 1 &&
              isMultiple && (
                <MenuItem onClick={handleAdd} disabled={isLoading || disabled}>
                  Add Tab
                </MenuItem>
              )}
            <MenuItem onClick={handleEdit} disabled={isLoading || disabled}>
              Edit (Excel Online)
            </MenuItem>
            {fileSheetId && (
              <MenuItem onClick={handleClear} disabled={isLoading || disabled}>
                Clear File
              </MenuItem>
            )}
            {(!fileSheetId || isPrimary) && (
              <MenuItem
                className={styles.menuItemDelete}
                onClick={handleDelete}
                disabled={isLoading || disabled}
              >
                Delete
              </MenuItem>
            )}
          </Menu>
        </div>
      </div>
    </Tooltip>
  )
}

export default SelectedFilesRow
