import React, { useCallback, useMemo } from 'react'

import cn from 'classnames'
import { NotesFilterOptions } from '@common/interfaces/notes'
import { NOTES_FILTER_ICONS } from '../../../constants/common'
import { ReactComponent as DocumentIcon } from '@assets/images/document-icon.svg'
import { TAG_COLOR_MAPPING } from '@common/interfaces/notes'
import styles from './NotesFullScreenModal.module.scss'

interface IProps {
  filter: NotesFilterOptions | string
  filters: any
  setFilters?: (filters: any) => void
  key: string
  isDiligence?: boolean
  setOrderBy?: (order: { field: string; direction: string }) => void
}

const QuickFilters = ({
  filter,
  filters,
  setFilters,
  key,
  isDiligence = false,
  setOrderBy,
}: IProps) => {
  const Icon = useMemo(() => NOTES_FILTER_ICONS[filter], [filter])
  const isCallPrep = useMemo(
    () => isDiligence && filter === NotesFilterOptions.Open,
    [isDiligence, filter],
  )
  const handleFilters = useCallback(() => {
    if (Icon) {
      setFilters({
        dueDiligenceFilters: [isCallPrep ? NotesFilterOptions.CallPrep : filter],
      })
    } else {
      setFilters({
        dueDiligenceFilters: [NotesFilterOptions.Open, NotesFilterOptions.Resolved],
        tags: [filter],
      })
    }

    if (isDiligence && filter !== NotesFilterOptions.Archived) {
      setOrderBy({ field: 'drag_order', direction: 'ASC' })
    } else {
      setOrderBy({ field: 'created_at', direction: 'DESC' })
    }
  }, [filter, setFilters, Icon, setOrderBy, isCallPrep, isDiligence])

  const isActive = useMemo(() => {
    return (
      ((filters?.dueDiligenceFilters?.[0] === filter ||
        (filters?.dueDiligenceFilters?.[0] === NotesFilterOptions.CallPrep &&
          filter === NotesFilterOptions.Open)) &&
        !filters?.tags?.length) ||
      (filters?.tags?.length === 1 && filters?.tags?.[0] === filter)
    )
  }, [filters, filter])

  const quickFilterColor = useMemo(() => {
    return TAG_COLOR_MAPPING?.[filter]?.color
  }, [filter])

  const activeTagDiligenceTag = useMemo(() => {
    return isDiligence && isActive && quickFilterColor
  }, [isDiligence, isActive, quickFilterColor])

  return (
    <div
      key={key}
      className={cn(styles.quickFilter, {
        [styles.activeFilter]: isActive,
        [styles.activeFilterFill]:
          filters?.dueDiligenceFilters?.[0] === filter &&
          (filter === NotesFilterOptions.Pinned || filter === NotesFilterOptions.CallAgenda),
        [styles.quickFilterDiligence]: isDiligence,
      })}
      onClick={handleFilters}
    >
      <div className={styles.quickFilterIcon}>
        {Icon ? (
          <Icon />
        ) : (
          <DocumentIcon
            className={styles.documentIcon}
            style={
              activeTagDiligenceTag
                ? ({ '--stroke-color': quickFilterColor } as React.CSSProperties)
                : {}
            }
          />
        )}
      </div>

      <div
        className={styles.quickFilterText}
        style={activeTagDiligenceTag ? { color: quickFilterColor } : {}}
      >
        {isCallPrep ? 'Call Prep' : filter}
      </div>
    </div>
  )
}

export default QuickFilters
