import React, { useState, useEffect, useCallback } from 'react'
import Modal from '../../../Common/Modal'
import Button from '../../../Common/Button'
import TextField from '../../../Common/TextField'
import { Box, InputLabel } from '@mui/material'
import genericSs from '@styles/generic.module.scss'
interface IProps {
  open: boolean
  onClose: () => void
  onSubmit: (alias: string) => void
  initialValue?: string
  isEdit?: boolean
}

const AddEditAlias = ({ open, onClose, onSubmit, initialValue = '', isEdit = false }: IProps) => {
  const [alias, setAlias] = useState('')

  useEffect(() => {
    if (open) {
      setAlias(initialValue)
    }
  }, [open, initialValue])

  const handleSubmit = useCallback(() => {
    onSubmit(alias)
    onClose()
  }, [alias, onSubmit, onClose])

  return (
    <Modal
      open={open}
      onCancel={onClose}
      title={isEdit ? 'Edit Alias' : 'Add Alias'}
      footer={[
        <Button onClick={onClose} variant="outlined" fullWidth>
          Cancel
        </Button>,
        <Button onClick={handleSubmit} color="primary" disabled={!alias.trim()} fullWidth>
          {isEdit ? 'Update' : 'Add'}
        </Button>,
      ]}
      size="small"
    >
      <Box>
        <InputLabel htmlFor="alias" className={genericSs.textLeft}>
          Alias
        </InputLabel>
        <TextField
          placeholder="Alias"
          useFinalForm={false}
          fullWidth
          value={alias}
          onChange={(e) => setAlias(e.target.value)}
        />
      </Box>
    </Modal>
  )
}

export default AddEditAlias
