import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import Tooltip from '@mui/material/Tooltip'
import InfiniteScroll from 'react-infinite-scroll-component'
import cn from 'classnames'
import styles from './ProspectReportDetailRow.module.scss'
import genericSs from '@styles/generic.module.scss'
import MultiselectRow from '../MultiselectRow'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TableBody from '../Common/TableBody'
import { debounceEventHandler, formatter } from '../../helpers/helpers'
import TableFiltersRow from '../Common/TableFiltersRow'
import { buildFiltersDefaults } from '../../helpers/filters'
import { PER_PAGE, PROSPECTS_REPORT_DETAIL_LIST_FILTERS_CONFIG } from '@common/constants/filters'
import TableLoader from '../Common/TableLoader'
import { IProspectAP, IProspectAR } from '@common/interfaces/prospects'
import useTable from '../../hooks/useTable'
import useSummaryRow from '../../hooks/useSummaryRow'

const filtersDefaults = buildFiltersDefaults(PROSPECTS_REPORT_DETAIL_LIST_FILTERS_CONFIG)

interface IProps {
  listARDetail: (id: string, params: object) => void
  listAPDetail: (id: string, params: object) => void
  detailRows: IProspectAR[] | IProspectAP[]
  entityName: string
  entityType: 'creditor' | 'debtor'
}

const ProspectReportDetailRow = ({
  listARDetail,
  listAPDetail,
  detailRows,
  entityName,
  entityType,
}: IProps) => {
  const { id } = useParams<{ id: string }>()

  const isAR = useMemo(() => entityType === 'debtor', [entityType])

  const listDetail = useCallback(
    (id: string, params: object) => {
      if (isAR) {
        listARDetail(id, params)
      } else {
        listAPDetail(id, params)
      }
    },
    [isAR, listARDetail, listAPDetail],
  )

  const filtersConfig = useMemo(
    () =>
      PROSPECTS_REPORT_DETAIL_LIST_FILTERS_CONFIG.map((filter) => {
        if (isAR) {
          if (filter.field === 'creditor') {
            return { ...filter, field: 'debtor', title: 'Customer' }
          }
          if (filter.field === 'total') {
            return { ...filter, title: 'Total AR' }
          }
        }
        return filter
      }),
    [isAR],
  )

  const {
    filters,
    handleOrderChange,
    orderBy,
    activeItem,
    activeItems,
    setActiveItem,
    setActiveItems,
    handleSelectRow,
  } = useTable({
    tableId: 'prospectARAPDetail',
    filtersDefaults,
    sortDefault: { field: 'total', direction: 'DESC' },
  })

  const [isLoading, setIsLoading] = useState(false)

  const debounceListSummary = useMemo(
    () =>
      debounceEventHandler(async (data: any) => {
        !data.loadMore && setIsLoading(true)
        await listDetail(id, {
          ...data,
          [entityType]: entityName,
          nestedRows: {
            keys: [entityType],
          },
        })
        setIsLoading(false)
      }, 500),

    [id, listDetail, entityType, entityName],
  )

  useEffect(() => {
    debounceListSummary({
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [filters, orderBy, debounceListSummary])

  const loadMore = useCallback(() => {
    listARDetail(id, {
      loadMore: true,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
      page: Math.ceil(detailRows.length / PER_PAGE),
    })
  }, [id, listARDetail, orderBy, filters, detailRows])

  const totalRow = useSummaryRow(detailRows as IProspectAR[], activeItems, {
    sumFields: [
      '_0_to_30_days',
      '_31_to_60_days',
      '_61_to_90_days',
      '_91_plus_days',
      'total',
      'total_percent',
    ],
  })

  const totalCount = useMemo(() => detailRows?.[0]?.totalCount, [detailRows])

  return (
    <TableContainer
      className={cn(styles.table)}
      onActiveRowsChange={setActiveItems}
      onActiveRowChange={setActiveItem}
      hasFooter
    >
      <Table>
        <TableHead>
          <TableFiltersRow
            filters={filtersConfig}
            orderBy={orderBy}
            handleOrderChange={handleOrderChange}
          />
        </TableHead>
        <TableBody id={'prospectReportDetail'}>
          {isLoading ? (
            <TableLoader columnsCount={filtersConfig.length} rowsCount={PER_PAGE} />
          ) : (
            detailRows?.length > 0 && (
              <InfiniteScroll
                dataLength={detailRows.length}
                next={loadMore}
                hasMore={detailRows.length < totalCount}
                loader={<TableLoader columnsCount={8} rowsCount={1} />}
                scrollableTarget={'prospectReportDetail'}
              >
                {detailRows.map((item, index) => {
                  const memo = 'debtor' in item ? item.debtor : item.creditor

                  return (
                    <TableRow
                      key={memo}
                      data-index={index}
                      className={cn('activableRow', {
                        activeRow: activeItems.includes(index),
                        currentActiveRow: activeItem === index,
                      })}
                      onClick={(event) => handleSelectRow(event, index)}
                    >
                      <TableCell className={genericSs.tableTextLeft}>
                        <Tooltip title={memo} placement="top" disableTouchListener>
                          <span>{memo}</span>
                        </Tooltip>
                      </TableCell>

                      <TableCell className={genericSs.tableTextRight}>
                        {item._0_to_30_days ? formatter.format(item._0_to_30_days) : '-'}
                      </TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        {item._31_to_60_days ? formatter.format(item._31_to_60_days) : '-'}
                      </TableCell>

                      <TableCell className={genericSs.tableTextRight}>
                        {item._61_to_90_days ? formatter.format(item._61_to_90_days) : '-'}
                      </TableCell>

                      <TableCell className={genericSs.tableTextRight}>
                        {item._91_plus_days ? formatter.format(item._91_plus_days) : '-'}
                      </TableCell>

                      <TableCell className={cn(styles.totals, genericSs.tableTextRight)}>
                        <p>{formatter.format(item.total)}</p>
                      </TableCell>
                      <TableCell className={cn(styles.totals, genericSs.tableTextRight)}>
                        <p>{(+item.total_percent * 100 || 0).toFixed(2)}%</p>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </InfiniteScroll>
            )
          )}
          <MultiselectRow activeItems={activeItems}>
            <TableCell className={genericSs.tableTextRight}>
              {formatter.format(totalRow?._0_to_30_days)}
            </TableCell>
            <TableCell className={genericSs.tableTextRight}>
              {formatter.format(totalRow?._31_to_60_days)}
            </TableCell>
            <TableCell className={genericSs.tableTextRight}>
              {formatter.format(totalRow?._61_to_90_days)}
            </TableCell>
            <TableCell className={genericSs.tableTextRight}>
              {formatter.format(totalRow?._91_plus_days)}
            </TableCell>

            <TableCell className={genericSs.tableTextRight}>
              {formatter.format(totalRow?.total)}
            </TableCell>
            <TableCell className={genericSs.tableTextRight}>
              {(totalRow?.total_percent * 100 || 0).toFixed(2)}%
            </TableCell>
          </MultiselectRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ProspectReportDetailRow
