import React, { useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { generatePath } from 'react-router-dom'
import { IOPSReporting, OPSReportingStatus } from '@common/interfaces/prospects'
import Grid from '@mui/material/Grid'

import { ROUTES } from '../../constants/routes'
import ProspectHeader from '../../components/ProspectHeader'

interface IProps {
  reporting: IOPSReporting
  isBDO: boolean
  isAdmin: boolean
}

const ProspectPage = ({ reporting, isBDO, isAdmin }: IProps) => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const { search } = useLocation()

  useEffect(() => {
    if (!reporting || reporting.id !== id) {
      return
    }

    if (isBDO) {
      history.replace({ pathname: generatePath(ROUTES.PROSPECT_ANALYSIS_QUEUE, { id }), search })
    } else if (isAdmin) {
      if (reporting?.status === OPSReportingStatus.Complete) {
        history.replace({ pathname: generatePath(ROUTES.PROSPECT_SUMMARY_PAGE, { id }), search })
      } else {
        history.replace({ pathname: generatePath(ROUTES.PROSPECT_ANALYSIS_QUEUE, { id }), search })
      }
    } else {
      history.replace({ pathname: generatePath(ROUTES.PROSPECT_APPLICATION_PAGE, { id }), search })
    }
  }, [reporting, isBDO, isAdmin, history, id, search])

  return (
    <Grid container py={3} pr={2} alignItems={'flex-start'} justifyContent={'center'}>
      <Grid item container xs={12}>
        <ProspectHeader />
      </Grid>
    </Grid>
  )
}

export default ProspectPage
