import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import { useLocation, useParams } from 'react-router'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import InfiniteScroll from 'react-infinite-scroll-component'
import Popover from '@mui/material/Popover'
import MenuItem from '@mui/material/MenuItem'
import { Form } from 'react-final-form'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import cn from 'classnames'
import { useDragDropManager } from 'react-dnd'
import withScrolling from 'react-dnd-scrolling'

import styles from './NotesFullScreenModal.module.scss'
import { ReactComponent as CheveronUp } from '@assets/images/chevron-up.svg'
import { ReactComponent as CheveronDown } from '@assets/images/down-chevron.svg'
import { ReactComponent as SortIcon } from '@assets/images/sort-icon.svg'
import FullscreenModal from '../../Common/FullscreenModal'
import {
  INotesData,
  WorkflowTypes,
  Pages,
  DD_NOTES_FILTER_OPTIONS,
  GENERAL_NOTES_FILTER_OPTIONS,
  INote,
  NotesFilterOptions,
  NotesTags,
} from '@common/interfaces/notes'
import Card from '../../Common/Card/Card'
import { buildFiltersDefaults } from '../../../helpers/filters'
import {
  NOTES_LIST_FILTERS_CONFIG,
  NOTES_LIST_PER_PAGE,
  NOTES_LIST_SORT_OPTIONS,
} from '@common/constants/filters'
import { currentWorkFlow, debounceEventHandler } from '../../../helpers/helpers'
import Note from './../Note'
import AddButton from '../../../components/Client/AddButton'
import QuickFilters from './QuickFilters'
import { useAutoScroll } from '../../../hooks/useAutoScroll'
import FilterContainer from '../../Filters/FilterContainer'
import ExportButton from '../../Common/ExportButton'
import CustomDragLayer from './CustomDragLayer'
import Skeleton from '@mui/material/Skeleton'
import Tooltip from '@mui/material/Tooltip'
import useTable from '../../../hooks/useTable'
const ScrollingComponent = withScrolling('div')

const SectionLoader = () => {
  return (
    <Box display="flex" flexDirection={'column'} mt={1}>
      <Box flex={12}>
        <Skeleton height={150} />
      </Box>
      <Box flex={12}>
        <Skeleton height={150} />
      </Box>
      <Box flex={12}>
        <Skeleton height={150} />
      </Box>
    </Box>
  )
}

interface IProps {
  handleClose: (value: boolean) => void
  listNotes: (id: string, data: object) => void
  notesData: INotesData
  addNote: (params: object) => void
  exportNotes: (id: string, data: object) => void
  updateNote: (id: string, data: object) => void
  isLoading: boolean
}

const NotesFullScreenModal = ({
  handleClose,
  notesData,
  listNotes,
  addNote,
  exportNotes,
  updateNote,
  isLoading,
}: IProps) => {
  const { pathname }: { search: string; pathname: string } = useLocation()
  const workflow = useMemo(() => currentWorkFlow(pathname), [pathname])

  const isDueDiligence = useMemo(() => workflow === WorkflowTypes.dueDiligencePage, [workflow])
  const isBDO = useMemo(() => workflow === WorkflowTypes.opsFlow, [workflow])
  const scrollContainerRef = useRef<HTMLDivElement>(null)

  const { updatePosition } = useAutoScroll(scrollContainerRef)
  const dragDropManager = useDragDropManager()
  const monitor = dragDropManager.getMonitor()

  const isDraggingRef = useRef(monitor.isDragging())

  useEffect(() => {
    const unsubscribe = monitor.subscribeToOffsetChange(() => {
      const offset = monitor.getSourceClientOffset()?.y as number
      updatePosition({ position: offset, isScrollAllowed: true })
      isDraggingRef.current = monitor.isDragging()
    })
    return unsubscribe
  }, [monitor, updatePosition])

  const filtersDefaults = useMemo(() => {
    return buildFiltersDefaults(
      NOTES_LIST_FILTERS_CONFIG,
      isDueDiligence
        ? {
            dueDiligenceFilters: [NotesFilterOptions.CallPrep],
          }
        : {
            pages: isBDO
              ? [Pages.ops]
              : [
                  Pages.bbc,
                  Pages.financials,
                  Pages.salesBySKU,
                  Pages.projections,
                  Pages.arGeneralLedger,
                  Pages.clientPage,
                  Pages.ops,
                  Pages.entity,
                ],
          },
    )
  }, [isDueDiligence, isBDO])

  const { filters, handleFiltersChange, handleOrderChange, orderBy } = useTable({
    tableId: 'notesFullScreenModal',
    filtersDefaults,
    sortDefault: isDueDiligence
      ? {
          field: 'drag_order',
          direction: 'ASC',
        }
      : {
          field: 'created_at',
          direction: 'DESC',
        },
  })
  const isArchived = useMemo(() => {
    return (
      filters?.dueDiligenceFilters?.length === 1 &&
      filters?.dueDiligenceFilters?.includes(NotesFilterOptions.Archived)
    )
  }, [filters?.dueDiligenceFilters])
  const totalCount = useMemo(() => notesData?.total, [notesData])
  const itemsCount = useMemo(() => notesData?.data?.length, [notesData])
  const noteTags = useMemo(() => notesData?.distinctTags, [notesData])

  const [tagSectionsOpen, setTagSectionsOpen] = useState<Record<NotesTags, boolean>>(() => {
    return Object.keys(notesData?.dataByTags || {}).reduce((acc, tag) => {
      acc[tag as NotesTags] = false
      return acc
    }, {} as Record<NotesTags, boolean>)
  })

  const [isExportLoading, setIsExportLoading] = useState(false)
  const [sortAnchorEl, setSortAnchorEl] = useState<SVGSVGElement | null>(null)
  const isSortOpen = useMemo(() => Boolean(sortAnchorEl), [sortAnchorEl])

  const [isAutoRefresh, setIsAutoRefresh] = useState(true)
  const [notesList, setNotesList] = useState<INote[]>([])
  const [notesByTags, setNotesByTag] = useState<Record<NotesTags, INote[]>>(
    {} as Record<NotesTags, INote[]>,
  )

  useEffect(() => {
    if (!isAutoRefresh) {
      setIsAutoRefresh(true)
    } else {
      setNotesList(notesData?.data || [])
      setNotesByTag(notesData?.dataByTags || ({} as Record<NotesTags, INote[]>))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notesData])

  useEffect(() => {
    if (isDueDiligence) {
      setTagSectionsOpen((prevState) => {
        const newState = { ...prevState }
        Object.keys(notesByTags || {}).forEach((tag) => {
          if (!(tag in newState)) {
            newState[tag as NotesTags] = false
          }
        })
        return newState
      })
    }
  }, [notesByTags, isDueDiligence])

  const toggleTagSection = useCallback(
    (tag: NotesTags) => {
      setTagSectionsOpen((prevState) => ({
        ...prevState,
        [tag]: !prevState[tag],
      }))
    },
    [setTagSectionsOpen],
  )

  const { id } = useParams<{ id: string }>()

  const statusFilterOptions = useMemo(() => {
    return isDueDiligence ? DD_NOTES_FILTER_OPTIONS : GENERAL_NOTES_FILTER_OPTIONS
  }, [isDueDiligence])

  const quickFilters = useMemo(() => {
    if (isDueDiligence) {
      return [...DD_NOTES_FILTER_OPTIONS, ...noteTags]
    } else {
      return GENERAL_NOTES_FILTER_OPTIONS
    }
  }, [isDueDiligence, noteTags])

  const isDiligenceWithSections = useMemo(
    () =>
      isDueDiligence &&
      ((filters.dueDiligenceFilters?.length === 1 &&
        [...DD_NOTES_FILTER_OPTIONS, NotesFilterOptions.CallPrep].includes(
          filters.dueDiligenceFilters[0],
        ) &&
        !filters.tags?.length) ||
        (!filters.dueDiligenceFilters?.length && !filters.tags?.length)),
    [isDueDiligence, filters],
  )

  const filtersConfig = useMemo(
    () => [
      isDueDiligence
        ? {
            field: 'tags',
            type: 'list' as const,
            title: 'Tags',
            isMultiple: true,
            options: noteTags.map((value) => ({
              value,
              label: value,
            })),
          }
        : {
            field: 'pages',
            type: 'list' as const,
            title: 'Deal stage',
            isMultiple: true,
            options: (isBDO
              ? [Pages.ops]
              : [
                  Pages.bbc,
                  Pages.financials,
                  Pages.salesBySKU,
                  Pages.projections,
                  Pages.arGeneralLedger,
                  Pages.clientPage,
                  Pages.dueDiligence,
                  Pages.entity,
                  Pages.ops,
                ]
            ).map((value) => ({
              value,
              label: value,
            })),
          },
      {
        field: 'dueDiligenceFilters',
        type: 'list' as const,
        title: 'Status',
        isMultiple: true,
        options: statusFilterOptions
          .filter((filter) => filter !== NotesFilterOptions.All)
          .map((value) => ({
            value,
            label: value,
          })),
      },
    ],
    [isDueDiligence, isBDO, noteTags, statusFilterOptions],
  )

  const debounceListNotes = useMemo(
    () =>
      debounceEventHandler((data: any) => {
        if (isDueDiligence) {
          listNotes(id, {
            filters,
            orderBy: orderBy.field,
            orderDirection: orderBy.direction,
            workflow,
            page: 0,
            perPage: NOTES_LIST_PER_PAGE,
            skipLoader: true,
          })
        } else {
          listNotes(id, data)
        }
      }, 500),
    [id, listNotes, filters, orderBy, workflow, isDueDiligence],
  )

  useEffect(() => {
    if (id && workflow) {
      debounceListNotes({
        filters,
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
        workflow,
        page: 0,
        perPage: NOTES_LIST_PER_PAGE,
      })
    }
  }, [filters, debounceListNotes, id, orderBy, workflow])

  const isNoteDragReversed = useMemo(
    () => orderBy.field === 'drag_order' && orderBy.direction === 'DESC',
    [orderBy.field, orderBy.direction],
  )

  const onNoteDrop = useCallback(
    async (note, dragOrder) => {
      await updateNote(note?.id, {
        newDragOrder: isNoteDragReversed ? totalCount - dragOrder : dragOrder,
        sortDirection: orderBy.direction,
        dueDiligenceFilters: filters?.dueDiligenceFilters,
        skipLoader: true,
      })
    },
    [updateNote, isNoteDragReversed, totalCount, orderBy.direction, filters?.dueDiligenceFilters],
  )

  const moveNote = useCallback((dragIndex: number, hoverIndex: number) => {
    setNotesList((notesList) => {
      const updatedNotes = [...notesList]
      const [draggedNote] = updatedNotes.splice(dragIndex, 1)
      updatedNotes.splice(hoverIndex, 0, draggedNote)

      return updatedNotes
    })
  }, [])

  const moveNoteByTag = useCallback(
    (tag: NotesTags) => (dragIndex: number, hoverIndex: number) => {
      setNotesByTag((notesByTags) => {
        const updatedNotesByTag = [...notesByTags[tag]]
        const [draggedNote] = updatedNotesByTag.splice(dragIndex, 1)
        updatedNotesByTag.splice(hoverIndex, 0, draggedNote)

        return {
          ...notesByTags,
          [tag]: updatedNotesByTag,
        }
      })
    },
    [],
  )

  const loadMore = useCallback(() => {
    const data = {
      loadMore: true,
      skipLoader: true,
      workflow,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      page: Math.ceil(itemsCount / NOTES_LIST_PER_PAGE),
      perPage: NOTES_LIST_PER_PAGE,
      filters,
    }
    workflow && listNotes(id, data)
  }, [id, listNotes, filters, orderBy, workflow, itemsCount])

  const handleExport = useCallback(async () => {
    setIsExportLoading(true)
    await exportNotes(id, {
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      workflow,
      page: 0,
      perPage: NOTES_LIST_PER_PAGE,
      skipLoader: true,
      isExport: true,
    })
    setIsExportLoading(false)
  }, [exportNotes, id, filters, orderBy, workflow])

  const openSort = useCallback((event: React.MouseEvent<SVGSVGElement>) => {
    setSortAnchorEl((anchorEl: SVGSVGElement) => (anchorEl ? null : event.currentTarget))
  }, [])

  const closeSort = useCallback(() => {
    setSortAnchorEl(null)
  }, [])

  const handleChangeOrderBy = useCallback(
    (value) => {
      closeSort()

      const [field, direction] = value.split(' ')
      handleOrderChange(field, direction)
      setSortAnchorEl(null)
    },
    [handleOrderChange, closeSort],
  )

  const createNewNote = useCallback(async () => {
    const draftJSData = EditorState.createWithContent(ContentState.createFromText(''))
    const draftJSDataJSON = convertToRaw(draftJSData.getCurrentContent())
    await addNote({
      workflow,
      note: draftJSDataJSON,
      id,
      filters: filters,
      sortDirection: orderBy.direction,
      sortValue: orderBy.field,
    })
  }, [addNote, id, workflow, filters, orderBy])

  const fullScreenModalSortOptions = useMemo(() => {
    return [
      ...NOTES_LIST_SORT_OPTIONS,
      ...(isDueDiligence && !isArchived
        ? [
            {
              value: 'drag_order ASC',
              label: 'Custom Order (Standard)',
            },
            {
              value: 'drag_order DESC',
              label: 'Custom Order (Reverse)',
            },
          ]
        : []),
    ]
  }, [isArchived, isDueDiligence])

  return (
    <FullscreenModal
      isOpen
      setIsOpen={handleClose}
      disableEnforceFocus
      classes={{ body: styles.fullScreenModal }}
      showCloseIcon
    >
      <Card
        className={styles.notesContainer}
        key="notes"
        classes={{
          content: styles.notesCardContainer,
        }}
        withBorder={false}
        noHeaderMargin
      >
        <CustomDragLayer />

        <div
          className={cn(styles.quickFiltersContainer, {
            [styles.quickFiltersContainerDiligence]: isDueDiligence,
          })}
        >
          <div className={styles.modalTitle}>Notes</div>
          {quickFilters.map((filter) => (
            <QuickFilters
              filter={filter}
              filters={filters}
              setFilters={handleFiltersChange}
              key={filter}
              isDiligence={isDueDiligence}
              setOrderBy={({ field, direction }) => handleOrderChange(field, direction)}
            />
          ))}
        </div>
        <div className={styles.modal}>
          <div className={cn(styles.modalBody)}>
            <Box
              display="flex"
              alignContent="center"
              flexDirection="column"
              gap={2}
              className={styles.actionsContainer}
            >
              <Box display="flex" justifyContent="space-between" alignContent="center" gap={2}>
                <Box display="flex" flex={1} alignContent="center" gap={2}>
                  <div
                    className={cn(
                      styles.actionButtonContainer,
                      styles.actionButtonContainerFilterContainer,
                    )}
                  >
                    <Form
                      onSubmit={handleFiltersChange}
                      initialValues={filters}
                      mutators={{
                        setFieldData: ([field, value], state, { changeValue }) => {
                          changeValue(state, field, () => value)
                        },
                      }}
                      render={({ values, handleSubmit, form: { mutators } }) => (
                        <FilterContainer
                          mutators={mutators}
                          filters={filtersConfig}
                          handleSubmit={handleSubmit}
                          values={values}
                          appliedFilters={filters}
                          withFilterItems={false}
                          filtersSize={6}
                          actionsSize={0}
                          actionsInsideFilters
                          actions={
                            <div className={styles.actionButtonContainer}>
                              <Popover
                                open={isSortOpen}
                                anchorEl={sortAnchorEl}
                                onClose={closeSort}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                }}
                                classes={{ paper: styles.sortMenuPopoverPaper }}
                              >
                                <div className={styles.sortMenu}>
                                  {fullScreenModalSortOptions.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      classes={{
                                        root: styles.sortMenuItem,
                                        selected: styles.sortMenuItemSelected,
                                      }}
                                      onClick={() => handleChangeOrderBy(option.value)}
                                      selected={
                                        `${orderBy.field} ${orderBy.direction}` === option.value
                                      }
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </div>
                              </Popover>

                              <SortIcon
                                className={cn(styles.button, {
                                  [styles.buttonActive]: isSortOpen,
                                })}
                                onClick={openSort}
                              />
                            </div>
                          }
                        />
                      )}
                      s
                    />
                  </div>
                </Box>
                <div className={styles.exportAddContainer}>
                  <div className={styles.actionButtonContainer}>
                    <Tooltip
                      title={
                        isDiligenceWithSections
                          ? 'Please select a section on the left to add a note'
                          : ''
                      }
                    >
                      <span>
                        <AddButton
                          disabled={isDiligenceWithSections}
                          variant="outlined"
                          onClick={createNewNote}
                        ></AddButton>
                      </span>
                    </Tooltip>
                  </div>
                  <div className={styles.actionButtonContainer}>
                    <ExportButton isLoading={isExportLoading} handleExport={handleExport} />
                  </div>
                </div>
              </Box>
            </Box>

            {isDiligenceWithSections ? (
              <ScrollingComponent className={styles.dueDiligenceNotesList}>
                {!isLoading ? (
                  notesByTags &&
                  Object.entries(notesByTags)?.map(([tag, notes]) => (
                    <div className={styles.tagSection} key={tag}>
                      <div
                        className={styles.tagSectionHeader}
                        onClick={() => toggleTagSection(tag as NotesTags)}
                      >
                        {tagSectionsOpen[tag as NotesTags] ? <CheveronUp /> : <CheveronDown />}
                        <div className={styles.tagSectionTitle}>{tag}</div>
                      </div>
                      {tagSectionsOpen[tag as NotesTags] && (
                        <div className={styles.tagSectionNotes}>
                          {notesByTags[tag as NotesTags].map((note, index) => (
                            <Note
                              index={index}
                              key={note?.id}
                              note={note}
                              listNotes={debounceListNotes}
                              isFullScreen
                              setIsAutoRefresh={setIsAutoRefresh}
                              noteTags={noteTags}
                              setQuickFilters={handleFiltersChange}
                              moveNote={moveNoteByTag(tag as NotesTags)}
                              onNoteDrop={onNoteDrop}
                              isDraggable={
                                isDueDiligence && !isArchived && orderBy.field === 'drag_order'
                              }
                              orderBy={orderBy}
                              filters={filters}
                              isNoteBeingDragged={isDraggingRef.current}
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <SectionLoader />
                )}
              </ScrollingComponent>
            ) : (
              <ScrollingComponent
                id="scrollableDivModal"
                className={styles.notesList}
                ref={scrollContainerRef}
              >
                <InfiniteScroll
                  className={styles.infiniteScroll}
                  dataLength={itemsCount || 0}
                  next={loadMore}
                  hasMore={itemsCount < totalCount}
                  loader={
                    <Box className={styles.circularLoader}>
                      <CircularProgress color="primary" />
                    </Box>
                  }
                  scrollableTarget="scrollableDivModal"
                >
                  {notesList?.map((note, index) => (
                    <Note
                      index={index}
                      key={note.id}
                      note={note}
                      listNotes={debounceListNotes}
                      isFullScreen
                      setIsAutoRefresh={setIsAutoRefresh}
                      noteTags={noteTags}
                      setQuickFilters={handleFiltersChange}
                      moveNote={moveNote}
                      onNoteDrop={onNoteDrop}
                      isDraggable={isDueDiligence && !isArchived && orderBy.field === 'drag_order'}
                      orderBy={orderBy}
                      filters={filters}
                      isNoteBeingDragged={isDraggingRef.current}
                    />
                  ))}
                </InfiniteScroll>
              </ScrollingComponent>
            )}
          </div>
        </div>
      </Card>
    </FullscreenModal>
  )
}

export default NotesFullScreenModal
