import React, { useCallback, useMemo, useState } from 'react'
import { Box, IconButton, Typography, Popover, TextField, Tooltip } from '@mui/material'
import { ThumbUp as ThumbUpIcon, ThumbDown as ThumbDownIcon } from '@mui/icons-material'
import { Feedback } from '@common/interfaces/prospects'
import styles from './../AtlasAIChat.module.scss'
import Button from '../../Common/Button'
import cn from 'classnames'

interface IProps {
  messageId: string
  feedbackType?: Feedback
  savedFeedbackComment?: string
  submitFeedback?: (params: {
    messageId: string
    feedbackType: Feedback
    comment?: string
  }) => Promise<any>
}

const ChatFeedback: React.FC<IProps> = ({
  messageId,
  feedbackType,
  savedFeedbackComment,
  submitFeedback,
}) => {
  const [feedbackComment, setFeedbackComment] = useState(savedFeedbackComment || '')
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleSubmitFeedback = useCallback(async () => {
    await submitFeedback({ messageId, feedbackType: Feedback.DISLIKED, comment: feedbackComment })
    setAnchorEl(null)
  }, [messageId, feedbackComment, submitFeedback])

  const handleClearFeedback = useCallback(async () => {
    await submitFeedback({ messageId, feedbackType: Feedback.CLEAR })
  }, [messageId, submitFeedback])

  const handleClickHelpful = useCallback(() => {
    if (feedbackType === Feedback.LIKED) {
      handleClearFeedback()
    } else {
      submitFeedback({ messageId, feedbackType: Feedback.LIKED })
    }
  }, [feedbackType, messageId, submitFeedback, handleClearFeedback])

  const handleClickNotHelpful = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (feedbackType === Feedback.DISLIKED) {
        handleClearFeedback()
      } else {
        setAnchorEl(event?.currentTarget || null)
      }
    },
    [feedbackType, handleClearFeedback],
  )

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const open = useMemo(() => Boolean(anchorEl), [anchorEl])

  return (
    <Box className={styles.feedbackContainer}>
      <>
        <IconButton
          size="small"
          disableFocusRipple
          disableRipple
          onClick={handleClickHelpful}
          className={cn(styles.feedbackButton, styles.likeButton, {
            [styles.liked]: feedbackType === Feedback.LIKED,
          })}
        >
          <ThumbUpIcon fontSize="small" />
        </IconButton>
        <Tooltip title={feedbackType === Feedback.DISLIKED ? feedbackComment : ''}>
          <IconButton
            size="small"
            disableFocusRipple
            disableRipple
            onClick={handleClickNotHelpful}
            className={cn(styles.feedbackButton, styles.dislikeButton, {
              [styles.disliked]: feedbackType === Feedback.DISLIKED,
            })}
          >
            <ThumbDownIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{ p: 2, width: 300 }}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            What went wrong?
          </Typography>
          <TextField
            autoFocus
            fullWidth
            multiline
            rows={3}
            size="small"
            value={feedbackComment}
            onChange={(e) => setFeedbackComment(e.target.value)}
            placeholder="Your feedback helps us improve Ask Atlas"
          />
          <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
            <Button size="small" variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button size="small" variant="contained" onClick={handleSubmitFeedback}>
              Submit
            </Button>
          </Box>
        </Box>
      </Popover>
    </Box>
  )
}

export default ChatFeedback
