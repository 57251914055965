import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ExportsDashboard from './ExportsDashboard'

import { clients } from '../../redux/clientInfo/selectors'
import { loanBalanceStartDate, endOfMonthFlow } from '../../redux/loanBalance/selectors'
import {
  exportTransactions,
  recalculateLoanBalance,
  getAverageLoanBalance,
  getLatestEndOfMonthFlow,
  exportEndOfMonthFlow,
  applyEndOfMonthFlow,
  exportIneligibles,
} from '../../redux/loanBalance/actions'
import { list, exportClientFeeStatement } from '../../redux/clientInfo/actions'

const selector = createStructuredSelector({
  loanBalanceStartDate,
  endOfMonthFlow,
  clients,
})
const actions = {
  exportTransactions,
  exportClientFeeStatement,
  getLatestEndOfMonthFlow,
  exportEndOfMonthFlow,
  applyEndOfMonthFlow,
  recalculateLoanBalance,
  listClients: list,
  getAverageLoanBalance,
  exportIneligibles,
}

export { ExportsDashboard }

export default connect(selector, actions)(ExportsDashboard)
