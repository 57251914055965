import React, { useEffect, useMemo, useCallback } from 'react'
import { useParams, useHistory, generatePath, matchPath } from 'react-router'
import { IOngoingReporting } from '@common/interfaces/bbc'
import { ROUTES } from '../../constants/routes'
import Tabs from '../Common/Tabs/Tabs'

interface IProps {
  data: IOngoingReporting
  pathname: string
  showOngoingReporting: (id: string) => void
}

const STEPS = [
  {
    title: 'Income Statement',
    path: ROUTES.ONGOING_REPORTING_INCOME_STATEMENT,
  },
  {
    title: 'Balance Sheet',
    path: ROUTES.ONGOING_REPORTING_BALANCE_SHEET,
  },
  {
    title: 'Summary',
    path: ROUTES.ONGOING_REPORTING_SUMMARY,
  },
]

const OngoingReportingStepper = ({
  data,
  pathname,
  showOngoingReporting,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()

  useEffect(() => {
    if (!data || data.id !== id) {
      showOngoingReporting(id)
    }
  }, [showOngoingReporting, data, id])

  const currentStep = useMemo(
    () => STEPS.find((step) => matchPath(pathname, { path: step.path }))?.title,
    [pathname],
  )

  useEffect(() => {
    if (data && pathname.startsWith('/ongoing-reporting') && !currentStep) {
      history.push(generatePath(ROUTES.ONGOING_REPORTING_INCOME_STATEMENT, { id }))
    }
  }, [data, currentStep, id, history, pathname])

  const onClick = useCallback(
    async (tab: string) => {
      const step = STEPS.find((step) => step.title === tab)
      history.push(generatePath(step.path, { id }))
    },
    [history, id],
  )

  const tabs = useMemo(() => STEPS.map((step) => step.title), [])

  return <Tabs tabs={tabs} handleChange={onClick} selected={currentStep} />
}

export default OngoingReportingStepper
