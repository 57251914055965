import { IActivity } from '@common/interfaces/activity'
import { FundingRequestAmountType, FundingRequestStatus } from '@common/interfaces/bbc'

export const getAmountFromActivity = (activity: IActivity): number => {
  if (!activity?.borrowingBase) {
    return activity?.totalCollections || 0
  }

  if (activity?.borrowingBase?.status === FundingRequestStatus.Sent) {
    return activity?.borrowingBase?.fundings?.reduce((sum, { amount }) => sum + amount, 0) || 0
  }

  if (activity?.borrowingBase?.requestedAmountType === FundingRequestAmountType.Specific) {
    return activity?.borrowingBase?.requestedAmount || 0
  }

  return 0
}
