import React from 'react'
import Tooltip from '@mui/material/Tooltip'

import styles from './FilesTemplates.module.scss'

import Button from '../../components/Common/Button'

interface IProps {
  isDownloading?: boolean
  templates: { name: string; type: string | null; fileId?: string }[]
  handleDownloadTemplate: (fileType?: string, fileId?: string) => Promise<void>
}

const FilesTemplates = ({ isDownloading, templates, handleDownloadTemplate }: IProps) => {
  return (
    <div className={styles.filesListItem}>
      <div className={styles.filesListItemDocuments}>
        {templates?.length > 0 &&
          templates.map((item, index: number) => (
            <React.Fragment key={item.type}>
              <Tooltip placement="top" title="Download Template">
                <Button
                  isLoading={isDownloading}
                  className={styles.downloadButton}
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    handleDownloadTemplate(item.type, item.fileId)
                  }}
                >
                  {item.name}
                </Button>
              </Tooltip>
              {index !== templates?.length - 1 && <div className={styles.templatesDivider} />}
            </React.Fragment>
          ))}
      </div>
    </div>
  )
}

export default FilesTemplates
