import React, { ReactNode } from 'react'
import { TableCell, TableRow } from '@mui/material'
import cn from 'classnames'
import styles from './MultiselectRow.module.scss'
import genericSs from '@styles/generic.module.scss'

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  activeItems: any[]
  addColumn?: boolean
  children?: ReactNode
  className?: string
}

const MultiselectRow = ({
  activeItems,
  children,
  className,
  addColumn = false,
  ...props
}: IProps) => {
  if (activeItems.length <= 1) {
    return null
  }

  return (
    <TableRow className={cn(styles.stickyFooter, className)} {...props}>
      {addColumn && <TableCell />}
      <TableCell className={cn(styles.activeItemsCount, genericSs.tableTextLeft)}>
        {activeItems.length} rows
      </TableCell>
      {children}
    </TableRow>
  )
}

export default MultiselectRow
