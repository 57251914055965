import { IClientInfo } from './client'
import { IEntityInfo } from './entityInfo'

export enum CheckDataStatus {
  New = 'NEW',
  Processed = 'PROCESSED',
  Deleted = 'DELETED',
  ProcessedDeleted = 'PROCESSED_DELETED',
  ProcessedDeletedSent = 'DELETED_SENT',
}

export enum CollectionType {
  Check = 'Check',
  Wire = 'Wire',
}

export interface ICheckDataPages {
  start: number
  end: number
  image: string
}

export interface ICheckData {
  data: ICheck[]
  totalCount: number
}

export interface ICheck {
  id: string
  checkNumber: string
  clientInfo?: IClientInfo
  checkAccount?: ICheckAccount
  accountNumber: string
  paymentAmount: number
  recordDate: string
  routingNumber: string
  lockbox?: number
  boxLink?: string
  pages?: ICheckDataPages
  status: string
  originalEntity?: string
  clientName: string
}

export interface ICheckAccount {
  id: string
  accountNumber: string
  linkedName: string
  lastMappedDate?: string
  lastSeenDate?: string
  entityInfo?: IEntityInfo
}

export interface ILockboxReconciliation {
  lockbox: number
  total: number
  adjustments: number
}

export interface IChecksSummaryInfo {
  checksReconciliation: ILockboxReconciliation[]
  nonClientCashChecks: ICheck[]
  mappingProgress: number
}

export enum WireDataStatus {
  New = 'NEW',
  Processed = 'PROCESSED',
  Deleted = 'DELETED',
  Negative = 'NEGATIVE',
  ProcessedDeleted = 'PROCESSED_DELETED',
  ProcessedDeletedSent = 'DELETED_SENT',
  Duplicate = 'DUPLICATE',
}

export interface IWireData {
  id?: string
  clientName?: string
  recordDate: string
  paymentAmount: number
  accountNumber?: string
  debtor?: string
  memo?: string
  originalMemo?: string
  prediction?: string
  predictionEntityId?: string
  status?: string
  isPossibleMultipleClients?: boolean
  isNegativePerClient?: boolean
  isNegative?: boolean
  isChecked?: boolean
  fileId?: string
  activityId?: string
  totalViewCount?: number
  recType: string
  isReconciliationValue: boolean
  isPositive: boolean
  isPotentialDuplicate?: boolean
}

export interface IWireDataAggregation {
  totalCount: number
  totalAmount: number
  mappedCount: number
  data: IWireData[]
}

export interface IWireTotals {
  mapping: number
  totalAmount: number
  totalWires?: number
  wiresPostingToday?: number
  wiresPostedYesterday?: number
  negativeWiresPostedYesterday?: number
  checksPostedYesterday?: number
  negativeWiresUploadedYesterday?: number
  reconciliationAmount: number
}

export interface IWiresDataReconciliationAccounts {
  bank: number
  operating: number
  concentration: number
  discrepancy: number
}

export interface IAggregationDataRow {
  id: string
  clientName: string
  debtor: string
  recordDate: string
  processedAt: string
  boxLink?: string
  accountNumber?: string
  description: string
  amount: number
  paymentType: CollectionType
  isEditable: boolean
  entityId: string
  clientId: string
  routingNumber?: string
  totalCount?: number
}

interface IAggregationDataTopRow {
  clientId: string
  clientName: string
  clientStatus: string
  checksPaymentAmount: number
  checksCount: number
  wiresPaymentAmount: number
  wiresCount: number
  totalPaymentAmount: number
  rows?: IAggregationDataRow[]
}
export interface IAggregationData {
  data: IAggregationDataTopRow[]
  totals: {
    checksPaymentSum: number
    wiresPaymentSum: number
    totalChecksCount: number
    totalWiresCount: number
    totalItems: number
  }
}

interface INegativeBalanceWire {
  id?: string
  clientName?: string
  recordDate: string
  paymentAmount: number
  accountNumber?: string
  debtor?: string
  memo?: string
  prediction?: string
  status?: string
}

export enum COLLECTIONS_TABS {
  OVERALL_COLLECTIONS = 'Overall collections',
  NON_CLIENT_RELATED_CASH = 'Non-client related cash',
}

export interface INegativeBalanceWiresData {
  data: Required<INegativeBalanceWire & { clientId: string; clientStatus: string }>[]
  totals: {
    totalItems: number
  }
}

export interface IMasterCollection {
  id: string
  clientName: string
  clientInfo?: IClientInfo
  clientId?: string
  clientIconUrl?: string
  recordDate: string
  paymentAmount: number
  debtor: string
  entityId: string
  paymentType: string
  boxLink: string
}

export const IDB_LOAN_ACCOUNT_NUMBER = '1000003294'

export enum RefreshType {
  SkipLoader = 'skipLoader',
  Refresh = 'refresh',
}

export interface ICheckAliasData {
  data: ICheckAlias
}

export interface ICheckAlias {
  id: string
  clientName: string
  aliases: string[]
}
