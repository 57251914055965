import React, { useCallback, useMemo, useState } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import styles from './Participations.module.scss'

import WarningModal from '../../WarningModal'
import { IParticipation } from '@common/interfaces/participant'
import KeyboardDatePicker from '../../Common/KeyboardDatePicker'
import moment from 'moment'
import { MenuIcon } from '../../Common/Icons'

interface IProps {
  id: string
  terminateParticipation: (id: string, participationId: string, data: object) => void
  editParticipation: (participation: IParticipation) => void
  participation: IParticipation
}

const ParticipantsActionMenu = ({
  participation,
  id,
  terminateParticipation,
  editParticipation,
}: IProps) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const [isTerminateWarningOpen, setIsTerminateWarningOpen] = useState(false)
  const [terminationDate, setTerminationDate] = useState(moment().toDate())

  const handleClickMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setMenuOpen(true)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
    setMenuOpen(false)
  }, [])

  const handleTerminateConfirm = useCallback(() => {
    terminateParticipation(id, participation.id, {
      terminationDate: terminationDate?.toISOString(),
    })
    handleCloseMenu()
  }, [handleCloseMenu, id, terminateParticipation, participation, terminationDate])

  const handleterminateCancel = useCallback(() => {
    setIsTerminateWarningOpen(false)
  }, [])

  const handleTerminate = useCallback(() => {
    setIsTerminateWarningOpen(true)
    handleCloseMenu()
  }, [handleCloseMenu])

  const handleEdit = useCallback(() => {
    editParticipation(participation)
    handleCloseMenu()
  }, [handleCloseMenu, editParticipation, participation])

  const handleChangeTerminationDate = useCallback((date: Date | null) => {
    if (date) {
      setTerminationDate(date)
    }
  }, [])

  const isTerminated = useMemo(
    () => moment(participation.terminationDate).isSameOrBefore(moment()),
    [participation.terminationDate],
  )

  const editText = useMemo(() => {
    if (isTerminated) {
      return 'View'
    }
    return 'Edit'
  }, [isTerminated])

  return (
    <div className={styles.wrapper}>
      <MenuIcon isActive={menuOpen} onClick={handleClickMenu} size="small" />
      <Menu open={menuOpen} onClose={handleCloseMenu} anchorEl={anchorEl}>
        <MenuItem onClick={handleEdit}>{editText}</MenuItem>
        {!isTerminated && <MenuItem onClick={handleTerminate}>Terminate</MenuItem>}
      </Menu>
      {isTerminateWarningOpen && (
        <WarningModal
          warningMessage="Are you sure you want to delete these terms?"
          onConfirm={handleTerminateConfirm}
          onCancel={handleterminateCancel}
          confirmText="Terminate"
          cancelText="Cancel"
          inputComponent={
            <div className={styles.terminationDate}>
              <h3>Termination date</h3>
              <KeyboardDatePicker
                size="large"
                useFinalForm={false}
                name="terminationDate"
                label="Termination Date"
                value={terminationDate}
                onChange={handleChangeTerminationDate}
              />
            </div>
          }
        />
      )}
    </div>
  )
}

export default ParticipantsActionMenu
