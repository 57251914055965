import React, { useMemo } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'

import styles from './OngoingReportingSummary.module.scss'

import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import {
  CLIENT_FINANCIALS_FIELDS,
  FinancialsType,
  ONGOING_REPORTING_TYPE_LABEL,
  OngoingReportingType,
} from '@common/interfaces/bbc'
import Card from '../Common/Card'
import TableLoader from '../Common/TableLoader'

interface IProps {
  statementType: OngoingReportingType
  cardWithBorder?: boolean
}

const OngoingReportingSummaryLoader = ({ statementType, cardWithBorder = true }: IProps) => {
  const financialStatementType = useMemo(
    () => statementType?.replace(FinancialsType.Projections, ''),
    [statementType],
  )

  const columns = useMemo(() => Array.from(Array(6).keys()), [])

  return (
    <Card
      title={
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center" gap={2}>
            Consolidated {ONGOING_REPORTING_TYPE_LABEL[statementType]}{' '}
          </Box>
          <Grid item xs={9} container justifyContent="flex-end" columnSpacing={2} spacing={2}>
            <Grid item>
              <Skeleton width={36} height={60} />
            </Grid>
            <Grid item>
              <Skeleton width={36} height={60} />
            </Grid>
            <Grid item>
              <Skeleton width={36} height={60} />
            </Grid>
            <Grid item>
              <Skeleton width={100} height={60} />
            </Grid>
            <Grid item>
              <Skeleton width={36} height={60} />
            </Grid>
          </Grid>
        </Box>
      }
      withBorder={cardWithBorder}
      withHeaderBorder={!cardWithBorder}
    >
      <TableContainer className={styles.table} scrollLeft>
        <Table>
          <TableHead className={styles.tableHead}>
            <TableRow>
              <TableCell />
              <TableCell>
                <div className={styles.accountHeader}>Account</div>
              </TableCell>
              {columns.map((index) => (
                <TableCell key={index} />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableLoader
              columnsCount={columns.length + 2}
              rowsCount={CLIENT_FINANCIALS_FIELDS[financialStatementType]?.length}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  )
}

export default OngoingReportingSummaryLoader
