import * as Yup from 'yup'
import moment from 'moment'
import { IClientInfo } from '@common/interfaces/client'
import { ILoadingData } from '../../redux/types'
import { IEndOfMonthFlow } from '@common/interfaces/loanServicing'
import { makeValidate } from 'mui-rff'

const schema = Yup.object().shape({
  recordDateFrom: Yup.date().typeError('Please type date in MM/DD/YY format').nullable(true),
  recordDateTo: Yup.date()
    .typeError('Please type date in MM/DD/YY format')
    .nullable(true)
    .when('recordDateFrom', (recordDateFrom: any, validation: any) =>
      recordDateFrom && recordDateFrom instanceof Date && !isNaN(+recordDateFrom)
        ? validation.min(recordDateFrom, 'To date should be prior to the from date')
        : validation,
    ),
})

const ineligiblesSchema = Yup.object().shape({
  recordDate: Yup.date()
    .typeError('Please type date in MM/DD/YY format')
    .required('Start date is required')
    .max(moment().toDate(), 'Date cannot be in the future'),
})
const recalculationSchema = Yup.object().shape({
  recordDate: Yup.date()
    .typeError('Please type date in MM/DD/YY format')
    .required('Start date is required'),
})

const validateQuickbook = makeValidate(schema)
const validateClientFeeStatement = makeValidate(schema)
const validateBankOutput = makeValidate(schema)
const validateIneligibles = makeValidate(ineligiblesSchema)
const validateRecalculation = makeValidate(recalculationSchema)

const DEFAULT_FILTERS_QUICKBOOK = {
  recordDateFrom: moment().subtract(1, 'month').startOf('month').startOf('day').toDate(),
  recordDateTo: moment().subtract(1, 'month').endOf('month').endOf('day').toDate(),
}

const DEFAULT_FILTERS_AVG_LOAN_BALANCE = {
  recordDateFrom: moment().subtract(1, 'month').startOf('month').startOf('day').toDate(),
  recordDateTo: moment().subtract(1, 'month').endOf('month').endOf('day').toDate(),
}

const DEFAULT_FILTERS_BANK_OUTPUT: {
  recordDateFrom: Date | string
  recordDateTo: Date | string
  clients: string[]
} = {
  recordDateFrom: moment().subtract(1, 'day').endOf('day').toDate(),
  recordDateTo: moment().subtract(1, 'day').endOf('day').toDate(),
  clients: [],
}

const DEFAULT_FILTERS_CLIENT_FEE_STATEMENT: {
  recordDateFrom: Date | string
  recordDateTo: Date | string
  clients: string[]
} = {
  recordDateFrom: moment().startOf('month').startOf('day').toDate(),
  recordDateTo: moment().endOf('month').endOf('day').toDate(),
  clients: [],
}

const DEFAULT_FILTERS_INELIGIBLES = {
  recordDate: moment().subtract(1, 'day').toDate(),
}

const DEFAULT_FILTERS_RECALCULATION: {
  clients: string[]
  recordDate: Date | string
} = {
  clients: [],
  recordDate: null,
}

export interface IProps {
  loanBalanceStartDate: string
  endOfMonthFlow: IEndOfMonthFlow
  clients: ILoadingData<{ data: IClientInfo[] }>
  exportTransactions: (params?: { filters?: object; format: string }) => void
  exportClientFeeStatement: (params: object) => void
  getLatestEndOfMonthFlow: () => void
  exportEndOfMonthFlow: () => void
  applyEndOfMonthFlow: (params: object) => void
  recalculateLoanBalance: (params?: { filters?: object }) => void
  listClients: () => void
  getAverageLoanBalance: (params: object) => void
  exportIneligibles: (params: object) => void
}

export {
  DEFAULT_FILTERS_QUICKBOOK,
  DEFAULT_FILTERS_AVG_LOAN_BALANCE,
  DEFAULT_FILTERS_BANK_OUTPUT,
  DEFAULT_FILTERS_CLIENT_FEE_STATEMENT,
  DEFAULT_FILTERS_INELIGIBLES,
  DEFAULT_FILTERS_RECALCULATION,
  validateQuickbook,
  validateClientFeeStatement,
  validateBankOutput,
  validateIneligibles,
  validateRecalculation,
}
