import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import { generatePath } from 'react-router-dom'
import cn from 'classnames'

import genericSs from '@styles/generic.module.scss'
import styles from './ProspectManageTermsPage.module.scss'
import { usePermissions } from '../../helpers/permissionContext'

import { ReactComponent as EmptyIcon } from '@assets/images/empty-page-icon.svg'
import { ReactComponent as NextIcon } from '@assets/images/next-icon.svg'
import { IOPSReporting, IProspectTerms, OPSReportingStatus } from '@common/interfaces/prospects'
import { ROUTES } from '../../constants/routes'
import TableContainer from '../../components/Common/TableContainer/TableContainer'
import Card from '../../components/Common/Card/Card'
import Table from '../../components/Common/Table'
import TableHead from '../../components/Common/TableHead'
import TableRow from '../../components/Common/TableRow'
import TableCell from '../../components/Common/TableCell'
import TableBody from '../../components/Common/TableBody'
import { dateToString, formatDate, handleStopPropagation } from '../../helpers/helpers'
import Button from '../../components/Common/Button/Button'
import ManageTermsActionMenu from './ManageTermsActionMenu'
import ManageTermsStatusSelect from './ManageTermsStatusSelect'
import { ILoadingData } from '../../redux/types'
import TableLoader from '../../components/Common/TableLoader'
import SaveState from '../../components/Common/SaveState'
import { BoxLink, ExpandDetailIcon } from '../../components/Common/Icons'
import { ClientInfoStatus } from '@common/interfaces/client'
import ProspectHeader from '../../components/ProspectHeader'
import ProspectReportingSummaryTermStructureData from '../../components/ProspectReportingSummaryTermStructure/ProspectReportingSummaryTermStructureData'
import { ProspectTermStatus } from '@common/constants/prospects'

interface IProps {
  reporting: IOPSReporting
  prospectTerms: ILoadingData<{ data: IProspectTerms[] }>
  listProspectTerms: (id: string) => void
  createProspectTerms: (
    id: string,
    term: IProspectTerms & { submittedProspectTermId?: string },
  ) => void
  generateTermSheet: (id: string, prospectTermsId: string) => Promise<void>
  isAdmin: boolean
}

const ProspectTermBoxLink = ({
  term,
  generateTermSheet,
}: {
  term: IProspectTerms
  generateTermSheet: (termId: string) => Promise<void>
}) => {
  const [isGenerateTermSheetLoading, setIsGenerateTermSheetLoading] = useState(false)

  const handleClick = useCallback(
    async (event) => {
      handleStopPropagation(event)

      if (term.boxLink) {
        return
      }

      setIsGenerateTermSheetLoading(true)
      await generateTermSheet(term.id)
      setIsGenerateTermSheetLoading(false)
    },
    [term, generateTermSheet],
  )

  return (
    <BoxLink onClick={handleClick} link={term.boxLink} isLoading={isGenerateTermSheetLoading} />
  )
}

const ProspectPage = ({
  reporting,
  listProspectTerms,
  createProspectTerms,
  generateTermSheet,
  prospectTerms,
  isAdmin,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const [isInitialized, setIsInitialized] = useState(false)
  const [isCloneLoading, setIsCloneLoading] = useState(false)
  const [isApproveLoading, setIsApproveLoading] = useState(false)
  const history = useHistory()

  const {
    data: prospectTermsData,
    isLoading,
    isSaving,
    isSaved,
  } = useMemo(
    () => ({
      data: prospectTerms?.data?.data || [],
      isLoading: prospectTerms.isLoading,
      isSaving: prospectTerms.isSaving,
      isSaved: prospectTerms.isSaved,
    }),
    [prospectTerms],
  )

  useEffect(() => {
    if (reporting?.id) {
      listProspectTerms(id)
    }
  }, [id, listProspectTerms, reporting])

  useEffect(() => {
    if (!isInitialized && prospectTermsData.length) {
      setExpanded([prospectTermsData[0].id])
      setIsInitialized(true)
    }
  }, [prospectTermsData, isInitialized])

  const { isUW } = usePermissions()

  const readOnly = useMemo(
    () =>
      ![ClientInfoStatus.Prospect, ClientInfoStatus.Archived].includes(
        reporting?.clientInfo?.clientStatus,
      ) ||
      reporting?.status === OPSReportingStatus.Archived ||
      isUW,
    [reporting, isUW],
  )

  const handleClick = useCallback(() => {
    history.push(generatePath(ROUTES.PROSPECT_ADD_TERMS_PAGE, { id }))
  }, [id, history])

  const [expanded, setExpanded] = useState([])

  const handleExpand = useCallback((label: string) => {
    setExpanded((values) =>
      values.includes(label) ? values.filter((item) => item !== label) : [...values, label],
    )
  }, [])

  const handleReview = useCallback(() => {
    history.push(generatePath(ROUTES.PROSPECT_SUMMARY_PAGE, { id }))
  }, [id, history])

  const handleClone = useCallback(async () => {
    if (!prospectTermsData.length) {
      return
    }
    setIsCloneLoading(true)
    await createProspectTerms(reporting.id, {
      ...prospectTermsData[0],
      recordDate: dateToString(new Date()),
      status: ProspectTermStatus.proposed,
    })
    setIsCloneLoading(false)
  }, [reporting?.id, createProspectTerms, prospectTermsData])

  const handleApproveTerm = useCallback(async () => {
    if (!prospectTermsData.length) {
      return
    }
    setIsApproveLoading(true)
    await createProspectTerms(reporting.id, {
      ...prospectTermsData[0],
      recordDate: dateToString(new Date()),
      status: ProspectTermStatus.approved,
      submittedProspectTermId: prospectTermsData[0].id,
    })
    setIsApproveLoading(false)
  }, [reporting?.id, createProspectTerms, prospectTermsData])

  const currentProspectTermStatus = useMemo(
    () => prospectTermsData[0]?.status || null,
    [prospectTermsData],
  )

  const handleGenerateTermSheet = useCallback(
    async (termId: string) => {
      await generateTermSheet(id, termId)
      await listProspectTerms(id)
    },
    [id, generateTermSheet, listProspectTerms],
  )

  return (
    <Grid container py={3} pr={2} alignItems={'flex-start'} justifyContent={'start'}>
      <Grid item xs={12}>
        <ProspectHeader />
      </Grid>
      <Grid item xs={12}>
        <Card
          withBorder={false}
          title={
            !!prospectTermsData.length ? (
              <Box display="flex" justifyContent="space-between">
                Term Sheets
                <Box display="flex" gap={1} alignItems="center">
                  <span className={cn(genericSs.statusCard, styles.statusChip)}>
                    {!currentProspectTermStatus
                      ? 'Original Terms'
                      : currentProspectTermStatus === ProspectTermStatus.submitted
                      ? 'Submit OPS'
                      : 'Approve Terms'}
                  </span>
                  <NextIcon className={styles.nextIcon} />

                  {!currentProspectTermStatus ? (
                    <Button onClick={handleReview}>Review</Button>
                  ) : currentProspectTermStatus === ProspectTermStatus.submitted ? (
                    isAdmin ? (
                      <Button onClick={handleApproveTerm} isLoading={isApproveLoading}>
                        Approve Terms
                      </Button>
                    ) : (
                      <span className={cn(genericSs.statusCard, styles.statusChip)}>
                        Get Approval
                      </span>
                    )
                  ) : (
                    <Button onClick={handleClone} isLoading={isCloneLoading}>
                      Term Sheet
                    </Button>
                  )}
                </Box>
              </Box>
            ) : null
          }
        >
          {!isLoading && !prospectTermsData.length ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <Grid container spacing={2} justifyContent={'center'}>
                <Grid container item xs={12} justifyContent={'center'}>
                  <EmptyIcon />
                </Grid>
                {!readOnly && (
                  <Grid container item xs={12} justifyContent={'center'}>
                    <Button type="submit" small={false} onClick={handleClick} isLoading={isLoading}>
                      Create Term Structure
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Box>
          ) : (
            <TableContainer className={styles.table} size="large">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={genericSs.tableTextCenter}> </TableCell>
                    <TableCell className={genericSs.tableTextRight}>Date</TableCell>
                    <TableCell className={genericSs.tableTextLeft}>Status</TableCell>
                    <TableCell className={genericSs.tableTextLeft}>Summary of changes</TableCell>
                    <TableCell className={genericSs.tableTextLeft}>Created by</TableCell>
                    <TableCell className={genericSs.tableTextLeft}>Box</TableCell>
                    {!readOnly && (
                      <TableCell className={genericSs.tableTextRight}>Actions</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody id="scrollableTable">
                  {isLoading ? (
                    <TableLoader columnsCount={readOnly ? 5 : 6} />
                  ) : (
                    prospectTermsData.map((term) => {
                      const isExpanded = expanded.includes(term.id)

                      return (
                        <React.Fragment key={term.id}>
                          <TableRow
                            key={term.id}
                            className={cn('activableRow', {
                              activeRow: isExpanded,
                              currentActiveRow: isExpanded,
                            })}
                          >
                            <TableCell className={genericSs.tableTextCenter}>
                              <ExpandDetailIcon
                                onClick={() => handleExpand(term.id)}
                                isExpanded={isExpanded}
                              />
                            </TableCell>
                            <TableCell className={genericSs.tableTextRight}>
                              {formatDate(term.recordDate)}
                            </TableCell>
                            <TableCell className={genericSs.tableTextLeft}>
                              <ManageTermsStatusSelect term={term} opsReportingId={reporting?.id} />
                            </TableCell>
                            <TableCell className={genericSs.tableTextLeft}>
                              <Tooltip title={term.summaryOfChanges} placement="top">
                                <div>{term.summaryOfChanges}</div>
                              </Tooltip>
                            </TableCell>
                            <TableCell className={genericSs.tableTextLeft}>
                              {term.user?.firstName || ''}
                            </TableCell>
                            <TableCell className={genericSs.tableTextLeft}>
                              <ProspectTermBoxLink
                                term={term}
                                generateTermSheet={handleGenerateTermSheet}
                              />
                            </TableCell>
                            {!readOnly && (
                              <TableCell className={genericSs.tableTextRight}>
                                <ManageTermsActionMenu term={term} id={id} />
                              </TableCell>
                            )}
                          </TableRow>
                          {isExpanded && (
                            <TableRow>
                              <TableCell className={styles.nestedRowColumn} colSpan={6}>
                                <ProspectReportingSummaryTermStructureData
                                  data={term}
                                  percentWithScale
                                />
                              </TableCell>
                            </TableRow>
                          )}
                        </React.Fragment>
                      )
                    })
                  )}
                </TableBody>
              </Table>
              <Box display="flex" alignItems="center" justifyContent="end">
                <SaveState isSaving={isSaving} isSaved={isSaved} />
              </Box>
            </TableContainer>
          )}
        </Card>
      </Grid>
    </Grid>
  )
}

export default ProspectPage
