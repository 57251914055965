import React from 'react'

import styles from './ProspectReportingSummaryTermStructure.module.scss'

import { IProspectTerms } from '@common/interfaces/prospects'
import { TERM_STRUCTURE_FIELDS } from '@common/constants/prospects'
import ProspectReportingSummaryTermStructureDataRow from './ProspectReportingSummaryTermStructureDataRow'
import { formatDate } from '../../helpers/helpers'
import { textToTitleCase } from '@common/helpers/helpers'

interface IProps {
  data: IProspectTerms
  previousData?: IProspectTerms | null
  percentWithScale?: boolean
}

const ProspectReportingSummaryTermStructureData = ({
  data: termData,
  previousData,
  percentWithScale,
}: IProps) => {
  return (
    <>
      <div className={styles.data}>
        {TERM_STRUCTURE_FIELDS.map((data, index) => (
          <div key={index} className={styles.column}>
            {data.map(({ section, data }, index) => (
              <div key={section} className={styles.section}>
                <div className={styles.sectionTitleWrapper}>
                  <div className={styles.sectionTitle}>{section}</div>
                  {index === 0 && previousData && (
                    <>
                      <div className={styles.previousValue}>
                        {previousData.status || ''}{' '}
                        {previousData.recordDate && formatDate(previousData.recordDate)}
                      </div>
                      <div className={styles.value}>
                        {textToTitleCase(termData.status || '')}{' '}
                        {termData.recordDate && formatDate(termData.recordDate)}
                      </div>
                    </>
                  )}
                </div>

                <div className={styles.sectionData}>
                  {data.map((field) => (
                    <ProspectReportingSummaryTermStructureDataRow
                      key={field}
                      field={field}
                      data={termData}
                      previousData={previousData}
                      percentWithScale={percentWithScale}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>

      <ProspectReportingSummaryTermStructureDataRow
        field="commentsForCommittee"
        data={termData}
        percentWithScale={percentWithScale}
        isFullRow
      />
    </>
  )
}

export default ProspectReportingSummaryTermStructureData
