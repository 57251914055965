import React, { useMemo } from 'react'
import styles from './InventoryDistributionGraph.module.scss'
import { Bar } from 'react-chartjs-2'
import {
  IInventoryDistributionAnalysis,
  InventoryFieldKeys,
  NOT_MAPPED,
} from '@common/interfaces/bbc'
import { formatAmount, formatDate } from '../../helpers/helpers'
import Skeleton from '@mui/material/Skeleton'
import { DAYS_LABELS } from '../BBCInventoryDistributionTable/BBCInventoryDistributionTable'
import { Grid } from '@mui/material'
import { BORDER_RADIUS, COLORS, FONT_SIZE, TOOLTIP } from '../../constants/graphs'

const DAYS_SINCE_LANDED_ORDER = [
  '0-90 Days',
  '91-180 Days',
  '181-270 Days',
  '271-360 Days',
  '361+ Days',
]

const DAYS_UNTIL_EXPIRATION_ORDER = [
  '361+ Days',
  '271-360 Days',
  '181-270 Days',
  '91-180 Days',
  '0-90 Days',
]

const DAYS_SINCE_LANDED_COLORS = {
  '0': '#54936D',
  '91': '#66B485',
  '181': '#F5B396',
  '271': '#E48C65',
  '361': '#CC5858',
}

const DAYS_UNTIL_EXPIRATION_COLORS = {
  '361': '#54936D',
  '271': '#66B485',
  '181': '#F5B396',
  '91': '#E48C65',
  '0': '#CC5858',
}

interface IProps {
  borrowingBases: { id: string; recordDate: string }[]
  inventoryDistributionAnaysis: IInventoryDistributionAnalysis[]
  isPercent: boolean
  field: InventoryFieldKeys
  isLoading: boolean
  xAxis: 'date' | 'distribution'
  date: string
}

const InventoryDistributionGraph = ({
  borrowingBases,
  inventoryDistributionAnaysis,
  isLoading,
  isPercent,
  field,
  xAxis,
  date,
}: IProps) => {
  const isByDistribution = useMemo(() => {
    return xAxis === 'distribution'
  }, [xAxis])

  const currentBBCId = useMemo(() => {
    return borrowingBases?.find((item) => item.recordDate === date)?.id
  }, [borrowingBases, date])

  const top5Categories = useMemo(() => {
    const sumByDistribution: {
      [key: string]: number
    } = {}
    inventoryDistributionAnaysis?.forEach((item) => {
      const sum = Object.keys(item?.values)
        ?.filter((key) => (isByDistribution ? key === currentBBCId : true))
        ?.map((key) => item?.values[key])
        .reduce((acc, value) => {
          return (acc || 0) + (value?.value || 0)
        }, 0)
      sumByDistribution[item.distribution] = sum
    })

    const sortedSumByDistribution = Object.entries(sumByDistribution).sort((a, b) => b[1] - a[1])
    const top5 = sortedSumByDistribution.slice(0, 5).map((item) => item[0])
    return top5
  }, [inventoryDistributionAnaysis, isByDistribution, currentBBCId])

  const orderList = useMemo(
    () =>
      field === 'daysSinceLanded'
        ? DAYS_SINCE_LANDED_ORDER
        : field === 'daysUntilExpiration'
        ? DAYS_UNTIL_EXPIRATION_ORDER
        : top5Categories?.length > 5
        ? [...top5Categories, 'Other']
        : top5Categories,
    [field, top5Categories],
  )

  const cleanedUpData = useMemo(() => {
    return inventoryDistributionAnaysis?.map((item, index) => {
      const data = borrowingBases
        ?.filter((item) => (isByDistribution ? item.recordDate === date : true))
        .map(({ id }) => {
          const value = item?.values[id]?.value
          const percent = item?.values?.[id]?.percent
          return { value, percent }
        })
      const distribution = item.distribution?.toString() || ''
      const isTop5 = top5Categories?.includes(distribution)
      const top5Index = top5Categories?.findIndex((category) => category === distribution)
      let label
      if (isTop5) {
        label = DAYS_LABELS[distribution as keyof typeof DAYS_LABELS] || distribution || NOT_MAPPED
      } else {
        label = 'Other'
      }

      let backgroundColor = COLORS[top5Index]
      if (field === 'daysSinceLanded') {
        backgroundColor =
          DAYS_SINCE_LANDED_COLORS[distribution as keyof typeof DAYS_SINCE_LANDED_COLORS]
      } else if (field === 'daysUntilExpiration') {
        backgroundColor =
          DAYS_UNTIL_EXPIRATION_COLORS[distribution as keyof typeof DAYS_UNTIL_EXPIRATION_COLORS]
      }

      return {
        label,
        data,
        backgroundColor,
        borderRadius: BORDER_RADIUS,
      }
    })
  }, [inventoryDistributionAnaysis, top5Categories, borrowingBases, field, date, isByDistribution])

  const sumDatasets: {
    label: string
    data: {
      value: number
      percent: number
    }[]
    backgroundColor: string
    borderRadius: number
  }[] = useMemo(() => {
    const sumDatasets = cleanedUpData?.reduce((acc, dataset) => {
      const index = acc?.findIndex((item) => item.label === dataset.label)
      if (index !== -1) {
        acc[index].data = acc[index].data.map(
          (
            value: {
              value: number
              percent: number
            },
            index: number,
          ) => {
            const sum = (value.value || 0) + (dataset.data[index].value || 0)
            const percent = (value.percent || 0) + (dataset.data[index].percent || 0)
            return { value: sum, percent }
          },
        )
      } else {
        acc.push(dataset)
      }
      return acc
    }, [])

    return sumDatasets
  }, [cleanedUpData])

  const sortedSum = useMemo(() => {
    const sorted = sumDatasets?.sort((a, b) => {
      const aIndex = orderList.findIndex((item) => item === a.label)
      const bIndex = orderList.findIndex((item) => item === b.label)
      return aIndex - bIndex
    })
    return sorted
  }, [sumDatasets, orderList])

  const datasets = useMemo(() => {
    const key = isPercent ? 'percent' : 'value'
    const dataset = sortedSum?.map((item) => {
      return {
        ...item,
        data: item.data.map((value) => value[key]),
      }
    })
    return dataset
  }, [sortedSum, isPercent])

  const labels = useMemo(() => {
    return borrowingBases?.map(({ recordDate }) => formatDate(recordDate))
  }, [borrowingBases])

  const labels2 = useMemo(() => {
    return sortedSum?.map((item) => item.label)
  }, [sortedSum])

  const { byDistributionDatasets, byDistributionTooltipInfo } = useMemo(() => {
    const currentDateData = inventoryDistributionAnaysis?.map((item) => {
      const label = top5Categories?.includes(item.distribution)
        ? DAYS_LABELS[item.distribution as keyof typeof DAYS_LABELS] ||
          item.distribution ||
          NOT_MAPPED
        : 'Other'

      const currentValue = item?.values[currentBBCId]?.value
      const currentPercent = item?.values[currentBBCId]?.percent
      return {
        value: currentValue || 0,
        percent: currentPercent || 0,
        distribution: item.distribution,
        label,
      }
    })

    const currentDateDataByLabel = currentDateData?.reduce((acc, item) => {
      acc[item.label] = {
        value: (acc[item.label]?.value || 0) + (item.value || 0),
        percent: (acc[item.label]?.percent || 0) + (item.percent || 0),
        distribution: item.distribution,
        label: item.label,
      }
      return acc
    }, {} as Record<string, { value: number; percent: number; distribution: string; label: string }>)

    // sort based on orderList using distribution values
    const sortedCurrentDateData = Object.values(currentDateDataByLabel)?.sort((a, b) => {
      const aIndex = labels2.findIndex((item) => item === a.label)
      const bIndex = labels2.findIndex((item) => item === b.label)
      if (aIndex === -1) return 1
      if (bIndex === -1) return -1
      return aIndex - bIndex
    })

    const byDistributionLabels = sortedCurrentDateData?.map((item) => item.distribution)
    let colors = COLORS.slice(0, sortedCurrentDateData?.length + 1)
    if (field === 'daysSinceLanded') {
      colors = byDistributionLabels
        .map((label) => DAYS_SINCE_LANDED_COLORS[label as keyof typeof DAYS_SINCE_LANDED_COLORS])
        .filter(Boolean)
    } else if (field === 'daysUntilExpiration') {
      colors = byDistributionLabels
        .map(
          (label) =>
            DAYS_UNTIL_EXPIRATION_COLORS[label as keyof typeof DAYS_UNTIL_EXPIRATION_COLORS],
        )
        .filter(Boolean)
    }

    const byDistributionDataset = {
      label: formatDate(date),
      data: sortedCurrentDateData?.map((item) => item.value),
      backgroundColor: colors,
      borderRadius: BORDER_RADIUS,
    }

    return {
      byDistributionDatasets: [byDistributionDataset],
      byDistributionLabels,
      byDistributionTooltipInfo: sortedCurrentDateData,
    }
  }, [inventoryDistributionAnaysis, date, currentBBCId, field, labels2, top5Categories])

  const chartOptions = useMemo(
    () => ({
      maintainAspectRatio: false,
      scales: {
        x: {
          stacked: true,
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: FONT_SIZE,
            },
          },
        },
        y: {
          stacked: true,
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: FONT_SIZE,
            },
            callback: (value: any) => {
              if (isPercent) {
                return value ? `${value}%` : `0%`
              } else {
                return value ? formatAmount(value) : `$0`
              }
            },
          },
        },
      },
      plugins: {
        legend: {
          display: !isByDistribution,
          position: 'top' as const,
          labels: {
            font: {
              size: FONT_SIZE,
              weight: '500',
            },
            color: 'black',
            boxWidth: 20,
            boxHeight: 20,
          },
        },
        tooltip: {
          ...TOOLTIP,
          callbacks: {
            title: (tooltipItems: any) => {
              // Assuming 'recordDate' is the x-axis label
              return `${tooltipItems[0].label}`
            },
            label: (tooltipItem: any) => {
              const datasetIndex = tooltipItem.datasetIndex
              const dataIndex = tooltipItem.dataIndex
              let value = 0
              let percent = 0
              if (isByDistribution) {
                value = byDistributionTooltipInfo[dataIndex].value || 0
                percent = byDistributionTooltipInfo[dataIndex].percent || 0
              } else {
                value = sumDatasets[datasetIndex].data?.[dataIndex]?.value || 0
                percent = sumDatasets[datasetIndex].data?.[dataIndex]?.percent || 0
              }

              // Format the value and percent
              const formattedValue = formatAmount(value) || '0'
              const formattedPercent = percent.toFixed(2) + '%'

              return [`$${formattedValue}`, formattedPercent]
            },
            footer: (tooltipItem: any) => {
              if (isPercent || isByDistribution) {
                return
              }
              return '$' + formatAmount(tooltipItem.reduce((a: any, b: any) => a + b.parsed.y, 0))
            },
          },
        },
      },
    }),
    [isPercent, sumDatasets, isByDistribution, byDistributionTooltipInfo],
  )

  const chartData = useMemo(() => {
    if (datasets) {
      return {
        labels: isByDistribution ? labels2 : labels,
        datasets: isByDistribution ? byDistributionDatasets : datasets,
      }
    } else {
      return null
    }
  }, [labels, datasets, isByDistribution, labels2, byDistributionDatasets])

  return (
    <div className={styles.chartContainer}>
      {isLoading ? (
        <Grid
          container
          spacing={5}
          className={styles.skeletonContainer}
          justifyContent={'start'}
          height={'100%'}
        >
          {Array.from({ length: 6 }, (_, index) => (
            <Grid item xs={2} key={index} height={'100%'}>
              <Skeleton variant="rectangular" width="100%" height="100%" />
            </Grid>
          ))}
        </Grid>
      ) : (
        chartData && <Bar className={styles.chart} data={chartData} options={chartOptions} />
      )}
    </div>
  )
}

export default InventoryDistributionGraph
