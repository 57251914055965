import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { Link, generatePath } from 'react-router-dom'
import LinkButton from '@mui/material/Link'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import cn from 'classnames'
import styles from './CollectionsPassThroughsTable.module.scss'
import genericSs from '@styles/generic.module.scss'
import { ClientInfoStatus, IClientInfo } from '@common/interfaces/client'
import { IPassThroughData, PassThroughStatus, IPassThrough } from '@common/interfaces/passThrough'
import { debounceEventHandler, formatDate, formatPrice } from '../../helpers/helpers'
import Card from '../Common/Card'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TableBody from '../Common/TableBody'
import CurrencyField from '../Common/CurrencyField'
import TableFiltersRow from '../Common/TableFiltersRow'
import { ROUTES } from '../../constants/routes'
import InfoPreview from '../InfoPreview'
import { PASSTHROUGHS_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import {
  buildFiltersDefaults,
  buildFiltersValidateSchema,
  updateDateFilters,
} from '../../helpers/filters'
import FilterContainer from '../Filters/FilterContainer'
import { ILoadingData } from '../../redux/types'
import useTable from '../../hooks/useTable'
import FormField from '../Common/FormField'
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroll-component'
import TableLoader from '../Common/TableLoader'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import WarningModal from '../WarningModal'
import { MenuIcon, SendIcon } from '../Common/Icons'
import { sleep } from '@common/helpers/helpers'
import MultiSelectToolbar from '../MultiSelectToolbar'

const mutators = {
  ...arrayMutators,
}

const filtersValidate = buildFiltersValidateSchema(PASSTHROUGHS_FILTERS_CONFIG)

const DeleteMenu = ({ handleDeleteWire }: { handleDeleteWire: () => void }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const handleClickMenu = useCallback((event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget)
    setIsMenuOpen(true)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
    setIsMenuOpen(false)
  }, [])

  const handleDelete = useCallback(async () => {
    handleCloseMenu()
    await handleDeleteWire()
  }, [handleCloseMenu, handleDeleteWire])

  return (
    <>
      <MenuIcon isActive={isMenuOpen} onClick={handleClickMenu} size="small" />
      <Menu
        classes={{ paper: styles.iconMenu }}
        open={isMenuOpen}
        onClose={handleCloseMenu}
        anchorEl={anchorEl}
      >
        <MenuItem classes={{ root: styles.iconMenuItem }} onClick={handleDelete}>
          Delete
        </MenuItem>
      </Menu>
    </>
  )
}

interface IProps {
  passThroughData: ILoadingData<IPassThroughData>
  clients: ILoadingData<{ data: IClientInfo[] }>
  listClients: () => void
  listPassThroughs: (params?: {
    page?: number
    perPage?: number
    filters?: object
    orderBy?: string
    orderDirection?: string
  }) => void
  submitPassThroughs: (data: object) => Promise<void>
  loanBalanceStartDate: string
  deletePassthrough: (id: string) => void
}

const CollectionsPassThroughsPage = ({
  passThroughData,
  clients,
  listClients,
  listPassThroughs,
  submitPassThroughs,
  loanBalanceStartDate,
  deletePassthrough,
}: IProps) => {
  const wrapperRef = useRef(null)

  const [selectedPassthroughId, setSelectedPassthroughId] = useState(null)
  const [isConfirmModalShown, setIsConfirmModalShown] = useState(false)
  const [passThroughsSending, setPassThroughsSending] = useState([])
  const [passthroughDeleting, setPassthroughDeleting] = useState(false)

  const handleDeletePassthrough = useCallback((id: string) => {
    setSelectedPassthroughId(id)
  }, [])

  const handleCancelDeletePassthrough = useCallback(() => {
    setSelectedPassthroughId(null)
  }, [])

  const [selectedPassThroughs, setSelectedPassThroughs] = useState([])
  const { passThroughs, isLoading, itemsCount, dataLength } = useMemo(
    () => ({
      passThroughs: passThroughData?.data?.data,
      isLoading: passThroughData?.isLoading,
      itemsCount: passThroughData?.data?.totals?.totalItems,
      dataLength: passThroughData?.data?.data?.length,
    }),
    [passThroughData],
  )

  const {
    filters,
    handleFiltersChange,
    handleOrderChange,
    orderBy,
    activeItem,
    activeItems,
    setActiveItem,
    setActiveItems,
    handleSelectRow,
    resetActiveItems,
    quickFilter,
    handleQuickFilterChange,
  } = useTable({
    tableId: 'passThroughs',
    filtersDefaults: buildFiltersDefaults(PASSTHROUGHS_FILTERS_CONFIG, {
      sentDateFrom: moment().format('YYYY-MM-DD'),
      sentDateTo: moment().format('YYYY-MM-DD'),
    }),
    sortDefault: {
      field: 'collectionDays',
      direction: 'DESC',
    },
    quickFilterDefault: 'Today',
  })

  const { clientsData } = useMemo(
    () => ({
      clientsData: clients?.data?.data,
    }),
    [clients],
  )
  useEffect(() => {
    listClients()
  }, [listClients])

  const debounceListPassThroughs = useMemo(
    () =>
      debounceEventHandler((values: any) => {
        const filters = updateDateFilters(values.filters, PASSTHROUGHS_FILTERS_CONFIG)
        const params = {
          ...values,
          filters,
        }

        listPassThroughs(params)
      }, 500),
    [listPassThroughs],
  )

  const refetchListPassThroughs = useCallback(
    () =>
      listPassThroughs({
        filters: updateDateFilters(filters, PASSTHROUGHS_FILTERS_CONFIG),
        page: 0,
        perPage: PER_PAGE,
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
      }),
    [filters, orderBy, listPassThroughs],
  )

  useEffect(() => {
    refetchListPassThroughs()
  }, [refetchListPassThroughs])

  const loadMore = useCallback(() => {
    debounceListPassThroughs({
      loadMore: true,
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      page: Math.ceil(dataLength / PER_PAGE),
      perPage: PER_PAGE,
    })
  }, [orderBy, filters, debounceListPassThroughs, dataLength])

  const handleSubmitPassThroughs = useCallback(
    (values: { passThroughs: any[] }) => {
      const submittedPassThroughs = values.passThroughs
        .filter((_, index) => activeItems.includes(index))
        .filter(({ status }) => status !== PassThroughStatus.Sent)
        .filter(
          ({ id, amount, status }) =>
            status === PassThroughStatus.Unsent ||
            +amount !== +passThroughs?.find((item) => item.id === id)?.amount,
        )
      setSelectedPassThroughs(submittedPassThroughs)
    },
    [passThroughs, activeItems],
  )

  const handleSubmitPassThrough = useCallback((passThrough: IPassThrough) => {
    setSelectedPassThroughs([passThrough])
  }, [])

  const submitPassThroughsFinal = useCallback(async () => {
    setPassThroughsSending(selectedPassThroughs.map(({ id }) => id))
    await submitPassThroughs({
      data: selectedPassThroughs,
    }).then(async () => {
      await sleep(300)
      await refetchListPassThroughs()
      setPassThroughsSending([])
      setSelectedPassThroughs([])
      setActiveItems([])
      setIsConfirmModalShown(false)
    })
  }, [submitPassThroughs, selectedPassThroughs, refetchListPassThroughs, setActiveItems])

  useEffect(() => {
    if (selectedPassThroughs.length > 0) {
      if (selectedPassThroughs.some(({ sentDate }) => !moment(sentDate).isSame(moment(), 'day'))) {
        setIsConfirmModalShown(true)
      } else {
        submitPassThroughsFinal()
      }
    }
  }, [selectedPassThroughs, submitPassThroughsFinal])

  const initialValues = useMemo(
    () => ({
      passThroughs: passThroughs?.map(
        ({ id, clientName, amount, collectionDays, status, sentDate }) => ({
          id,
          clientName,
          amount,
          collectionDays,
          status,
          sentDate: sentDate ? sentDate : moment().format('YYYY-MM-DD'),
        }),
      ),
    }),
    [passThroughs],
  )

  const filtersConfig = useMemo(
    () =>
      PASSTHROUGHS_FILTERS_CONFIG.map((item) => ({
        ...item,
        options:
          item.field === 'clients'
            ? clientsData?.map(({ clientName }) => ({
                value: clientName,
                label: clientName,
              }))
            : item.options,
      })),
    [clientsData],
  )

  const unsentPassThroughs = useMemo(
    () =>
      initialValues.passThroughs
        ? initialValues?.passThroughs
            .filter((_, index) => activeItems.includes(index))
            .filter(({ status }) => status === PassThroughStatus.Unsent).length
        : 0,
    [activeItems, initialValues],
  )

  const totalAmount = useMemo(() => {
    const total = (initialValues?.passThroughs || [])
      .filter((_, index) => activeItems.includes(index))
      .reduce(
        (result, row) => {
          result.totalAmount += +row.amount

          return result
        },
        {
          totalAmount: 0,
        },
      )
    return `$${formatPrice(total?.totalAmount)}`
  }, [initialValues, activeItems])

  const handleDeletePassthroughConfirm = useCallback(async () => {
    setPassthroughDeleting(true)
    await deletePassthrough(selectedPassthroughId)
    setPassthroughDeleting(false)
    handleCancelDeletePassthrough()
    await sleep(300)
    await refetchListPassThroughs()
  }, [
    deletePassthrough,
    selectedPassthroughId,
    handleCancelDeletePassthrough,
    refetchListPassThroughs,
  ])

  const handleSentCancel = useCallback(() => {
    setIsConfirmModalShown(false)
  }, [])

  const handleSentConfirm = useCallback(async () => {
    await submitPassThroughsFinal()
  }, [submitPassThroughsFinal])

  return (
    <Box py={1}>
      <Grid container spacing={3} ref={wrapperRef}>
        <Grid item xs={12}>
          <Card noHeaderMargin withBorder={false} noPadding>
            <TableContainer
              className={styles.passThroughsTable}
              onActiveRowsChange={setActiveItems}
              onActiveRowChange={setActiveItem}
            >
              <Form
                onSubmit={handleSubmitPassThroughs}
                initialValues={initialValues}
                mutators={mutators}
                render={({
                  handleSubmit: handleSubmitPassThroughs,
                  values,
                }: {
                  handleSubmit: any
                  values: any
                }) => (
                  <>
                    <Form
                      validate={filtersValidate}
                      onSubmit={handleFiltersChange}
                      initialValues={filters}
                      mutators={{
                        setFieldData: ([field, value], state, { changeValue }) => {
                          changeValue(state, field, () => value)
                        },
                      }}
                      render={({
                        values,
                        handleSubmit: handleSubmitFilters,
                        form: { mutators: filterMutators },
                      }) => (
                        <FilterContainer
                          filters={filtersConfig}
                          handleSubmit={handleSubmitFilters}
                          mutators={filterMutators}
                          values={values}
                          appliedFilters={filters}
                          appliedQuickFilter={quickFilter}
                          handleAppliedQuickFilterChange={handleQuickFilterChange}
                        />
                      )}
                    />
                    <Table>
                      <TableHead>
                        <TableFiltersRow
                          filters={filtersConfig}
                          orderBy={orderBy}
                          handleOrderChange={handleOrderChange}
                        />
                      </TableHead>
                      <TableBody id="passThroughTable">
                        {!passThroughData?.data || isLoading ? (
                          <TableLoader columnsCount={5} rowsCount={10} />
                        ) : (
                          <InfiniteScroll
                            dataLength={dataLength}
                            next={loadMore}
                            hasMore={dataLength < itemsCount}
                            loader={<TableLoader columnsCount={5} rowsCount={1} />}
                            scrollableTarget="passThroughTable"
                          >
                            <FieldArray name="passThroughs">
                              {({ fields }) =>
                                fields.map((name, index) => {
                                  const item = passThroughs[index]
                                  if (!item) {
                                    return null
                                  }

                                  const isDeletable = moment(item.sentDate).isAfter(
                                    loanBalanceStartDate,
                                  )
                                  const isSent = item.status === PassThroughStatus.Sent

                                  return (
                                    <TableRow
                                      key={item.id}
                                      data-index={index}
                                      className={cn('activableRow', {
                                        activeRow: activeItems.includes(index),
                                        currentActiveRow: activeItem === index,
                                      })}
                                      onClick={(event) => handleSelectRow(event, index)}
                                    >
                                      <TableCell className={genericSs.tableTextLeft}>
                                        <LinkButton
                                          component={Link}
                                          to={generatePath(ROUTES.CLIENT_PAGE, {
                                            id: item.clientId,
                                          })}
                                        >
                                          {item.clientName}
                                        </LinkButton>
                                        {item.clientStatus === ClientInfoStatus.Past && (
                                          <span className={genericSs.grayCard}>Past</span>
                                        )}
                                      </TableCell>
                                      <TableCell className={genericSs.tableTextRight}>
                                        {isSent ? (
                                          formatPrice(item.amount)
                                        ) : (
                                          <CurrencyField name={`${name}.amount`} size="small" />
                                        )}
                                      </TableCell>
                                      <TableCell className={genericSs.tableTextRight}>
                                        {isSent ? (
                                          formatDate(item.sentDate)
                                        ) : (
                                          <FormField
                                            name={`${name}.sentDate`}
                                            type="date"
                                            size="small"
                                            minDate={moment(
                                              loanBalanceStartDate || undefined,
                                            ).toDate()}
                                            maxDate={moment().toDate()}
                                          />
                                        )}
                                      </TableCell>
                                      <TableCell className={genericSs.tableTextRight}>
                                        {!isSent ? `${item.collectionDays}` : ''}
                                      </TableCell>
                                      <TableCell className={genericSs.tableTextRight}>
                                        {isSent ? (
                                          <>
                                            <span className={genericSs.greenTag}>
                                              {item.status}
                                            </span>
                                            {isDeletable && (
                                              <DeleteMenu
                                                handleDeleteWire={() =>
                                                  handleDeletePassthrough(item.id)
                                                }
                                              />
                                            )}
                                          </>
                                        ) : (
                                          unsentPassThroughs <= 1 && (
                                            <>
                                              {item.noWireFeeApplied && (
                                                <InfoPreview
                                                  title="No Fee Applied"
                                                  isText
                                                ></InfoPreview>
                                              )}
                                              <SendIcon
                                                isLoading={passThroughsSending.includes(item.id)}
                                                disabled={
                                                  isLoading || passThroughsSending.includes(item.id)
                                                }
                                                onClick={() =>
                                                  handleSubmitPassThrough(
                                                    values.passThroughs[index],
                                                  )
                                                }
                                                size="small"
                                              />
                                            </>
                                          )
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  )
                                })
                              }
                            </FieldArray>
                          </InfiniteScroll>
                        )}
                      </TableBody>
                      <MultiSelectToolbar
                        activeItems={activeItems}
                        totalSum={totalAmount}
                        resetActiveItems={resetActiveItems}
                      >
                        {unsentPassThroughs > 1 && (
                          <SendIcon
                            disabled={passThroughsSending.length > 0}
                            isLoading={passThroughsSending.length > 0}
                            onClick={handleSubmitPassThroughs}
                            title="Send pass-through"
                          />
                        )}
                      </MultiSelectToolbar>
                    </Table>
                  </>
                )}
              />
            </TableContainer>
          </Card>
        </Grid>
      </Grid>

      {isConfirmModalShown && (
        <WarningModal
          warningMessage="Are you sure you want to send these pass-throughs? Some are not dated for today."
          onConfirm={handleSentConfirm}
          onCancel={handleSentCancel}
          confirmText="Send"
          cancelText="Cancel"
          disabled={isLoading}
          isLoading={passThroughsSending.length > 0}
        />
      )}
      {selectedPassthroughId && (
        <WarningModal
          warningMessage="Are you sure you want to delete this pass-through?"
          onConfirm={handleDeletePassthroughConfirm}
          onCancel={handleCancelDeletePassthrough}
          confirmText="Delete"
          cancelText="Cancel"
          disabled={passthroughDeleting}
          isLoading={passthroughDeleting}
        />
      )}
    </Box>
  )
}

export default CollectionsPassThroughsPage
