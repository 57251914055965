import React from 'react'

import styles from './ClientBanners.module.scss'

import { IClientBanner, IClientInfo } from '@common/interfaces/client'
import ClientBannerItem from './ClientBannerItem'

interface IProps {
  clientInfo: IClientInfo
  banners: IClientBanner[]
  closeBanner: (id: string) => Promise<void>
}

const ClientBanners = ({ clientInfo, banners, closeBanner }: IProps) => {
  return (
    <div className={styles.clientBannersContainer}>
      {banners.map((banner) => (
        <ClientBannerItem
          key={banner.id}
          clientInfo={clientInfo}
          banner={banner}
          closeBanner={closeBanner}
        />
      ))}
    </div>
  )
}

export default ClientBanners
