import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link, generatePath, useParams } from 'react-router-dom'
import { Form } from 'react-final-form'
import InfiniteScroll from 'react-infinite-scroll-component'
import Box from '@mui/material/Box'
import LinkButton from '@mui/material/Link'
import moment from 'moment'
import cn from 'classnames'

import styles from './DilutionByCustomer.module.scss'
import genericSs from '@styles/generic.module.scss'

import TableFooter from '../Common/TableFooter'
import { debounceEventHandler, formatPercent, formatPrice } from '../../helpers/helpers'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TableFiltersRow from '../Common/TableFiltersRow'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import { DILUTION_BY_CUSTOMER_LIST_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import FilterContainer from '../Filters/FilterContainer'
import { ILoadingData } from '../../redux/types'
import TableLoader from '../Common/TableLoader/TableLoader'
import {
  IClientGeneralLedgerDilutionByCustomerData,
  IDilutionDropwdowns,
} from '@common/interfaces/clientGeneralLedger'
import { ExpandAndMinimize, ExternalLink } from '../Common/Icons'
import FullscreenModal from '../Common/FullscreenModal'
import DatePicker from '../Common/DatePicker'
import {
  IDebtorCategories,
  Category,
  ReportingFlow,
  ReportingPeriods,
} from '@common/interfaces/bbc'
import { WorkflowTypes } from '@common/interfaces/notes'
import useTable from '../../hooks/useTable'
import MultiselectRow from '../MultiselectRow'
import useTrackVisualizationsTable from '../../hooks/useTrackVisualizationsTable'
import { CATEGORIES } from '@common/constants/tracking'
import Empty from '../Common/Empty/Empty'
import { ROUTES } from '../../constants/routes'
import useSummaryRow from '../../hooks/useSummaryRow'

const filtersValidate = buildFiltersValidateSchema(DILUTION_BY_CUSTOMER_LIST_FILTERS_CONFIG)
const filtersDefaults = buildFiltersDefaults(DILUTION_BY_CUSTOMER_LIST_FILTERS_CONFIG)

interface IProps {
  workflow: string
  dilutionByCustomer: ILoadingData<IClientGeneralLedgerDilutionByCustomerData>
  listDilutionByCustomer: (
    id: string,
    params?: {
      page?: number
      perPage?: number
      filters?: object
      orderBy?: string
      orderDirection?: string
    },
  ) => void
  hideDilutionByCustomer: () => void
  refreshCounter?: number
  debtorCategories: ILoadingData<IDebtorCategories>
  dilutionDropdowns: ILoadingData<IDilutionDropwdowns>
  reportingFlow?: ReportingFlow
}

const DilutionByCustomer = ({
  workflow,
  dilutionByCustomer,
  listDilutionByCustomer,
  hideDilutionByCustomer,
  refreshCounter,
  debtorCategories,
  dilutionDropdowns,
  reportingFlow = ReportingFlow.ClientPage,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const wrapperRef = useRef(null)
  const [isModalShown, setIsModalShown] = useState(false)
  const [currentDateRange, setCurrentDateRange] = useState<{ startDate: string; endDate: string }>({
    startDate: '',
    endDate: '',
  })
  const [isInitialized, setIsInitialized] = useState(false)

  const {
    filters,
    handleFiltersChange,
    handleOrderChange,
    orderBy,
    activeItem,
    activeItems,
    setActiveItem,
    setActiveItems,
    handleSelectRow,
    quickFilter,
    handleQuickFilterChange,
  } = useTable({
    tableId: 'dilutionByCustomer',
    filtersDefaults,
    sortDefault: {
      field: 'invoices',
      direction: 'DESC',
    },
  })

  const {
    isLoading,
    dilutionByCustomerData,
    dilutionByCustomerDataLength,
    dilutionByCustomerDataTotals,
    dilutionByCustomerDataTotalItems,
  } = useMemo(
    () => ({
      isLoading: dilutionByCustomer.isLoading,
      dilutionByCustomerData: dilutionByCustomer?.data?.data || [],
      dilutionByCustomerDataLength: dilutionByCustomer?.data?.data?.length || 0,
      dilutionByCustomerDataTotals: dilutionByCustomer?.data?.totals,
      dilutionByCustomerDataTotalItems: dilutionByCustomer?.data?.totals?.totalItems || 0,
    }),
    [dilutionByCustomer],
  )

  const { maxDate, minDate, debtors, boxLink, isDropdownsLoading } = useMemo(
    () => ({
      maxDate: dilutionDropdowns?.data?.maxDate || '',
      minDate: dilutionDropdowns?.data?.minDate || '',
      debtors: dilutionDropdowns?.data?.debtors || [],
      boxLink: dilutionDropdowns?.data?.boxLink || null,
      isDropdownsLoading: dilutionDropdowns?.isLoading,
    }),
    [dilutionDropdowns],
  )

  const isClientPage = useMemo(() => workflow === WorkflowTypes.clientPage, [workflow])

  const categories = useMemo(() => debtorCategories.data?.categories || [], [debtorCategories])

  useEffect(() => {
    if (!isInitialized && minDate && maxDate) {
      if (isClientPage) {
        if (maxDate) {
          setCurrentDateRange({
            startDate: moment
              .max([moment(minDate), moment(maxDate).subtract(1, 'year')])
              .format('YYYY-MM-DD'),
            endDate: maxDate,
          })
        }
      } else {
        setCurrentDateRange({
          startDate: minDate || '',
          endDate: maxDate || '',
        })
      }
      setIsInitialized(true)
    }
  }, [isInitialized, dilutionByCustomer, isClientPage, minDate, maxDate])

  useEffect(() => {
    if (workflow === WorkflowTypes.ongoingReporting) {
      setCurrentDateRange({
        startDate: minDate || '',
        endDate: maxDate || '',
      })
    }
  }, [workflow, minDate, maxDate])

  useEffect(() => {
    return () => {
      hideDilutionByCustomer()
    }
  }, [hideDilutionByCustomer])

  const datesBoundary = useMemo(
    () => ({
      minDate: minDate || null,
      maxDate: maxDate || null,
    }),
    [minDate, maxDate],
  )

  const fetchDilutionByCustomer = useCallback(
    (data: any) => {
      const params = {
        ...data,
        filters: {
          ...data.filters,
        },
        startDate: currentDateRange.startDate,
        endDate: currentDateRange.endDate,
        reportingFlow,
      }
      currentDateRange.startDate && listDilutionByCustomer(id, params)
    },
    [listDilutionByCustomer, id, currentDateRange, reportingFlow],
  )

  const debounceListDilutionByCustomer = useMemo(
    () => debounceEventHandler(fetchDilutionByCustomer, 500),
    [fetchDilutionByCustomer],
  )

  useEffect(() => {
    debounceListDilutionByCustomer({
      page: 0,
      perPage: PER_PAGE,
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [filters, orderBy, debounceListDilutionByCustomer])

  const loadMore = useCallback(() => {
    fetchDilutionByCustomer({
      loadMore: true,
      page: Math.ceil(dilutionByCustomerDataLength / PER_PAGE),
      perPage: PER_PAGE,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
  }, [dilutionByCustomerDataLength, orderBy, filters, fetchDilutionByCustomer])

  const refetchDilutionByCustomer = useCallback(() => {
    fetchDilutionByCustomer({
      page: 0,
      perPage: dilutionByCustomerDataLength || PER_PAGE,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
  }, [dilutionByCustomerDataLength, orderBy, filters, fetchDilutionByCustomer])

  useEffect(() => {
    if (refreshCounter) {
      refetchDilutionByCustomer()
    }
  }, [refreshCounter, refetchDilutionByCustomer])

  const handleModalChange = useCallback(() => {
    setIsModalShown((prev) => !prev)
  }, [])

  const handleDateChange = useCallback(
    (values: { startDate: string; endDate: string }) => {
      setCurrentDateRange(values)
    },
    [setCurrentDateRange],
  )

  const filtersConfig = useMemo(
    () =>
      DILUTION_BY_CUSTOMER_LIST_FILTERS_CONFIG.map((item) => ({
        ...item,
        options:
          item.field === 'debtor'
            ? debtors.map((item) => ({
                label: item,
                value: item,
              }))
            : item.options,
      })).map((filter) =>
        filter.type === 'quickFilter' && reportingFlow !== ReportingFlow.LoanCommitteeReport
          ? {
              ...filter,
              quickFilters: [
                {
                  title: 'Eligible Customers',
                  isHidden: true,
                  filters: {
                    category: [
                      Category.Foreign,
                      Category.Government,
                      Category.Intercompany,
                      Category.Other,
                      Category.Distressed,
                      Category.Affiliate,
                    ].filter((category) => categories.includes(category)),
                  },
                },
              ],
            }
          : filter,
      ),
    [debtors, categories, reportingFlow],
  )

  const totalRow = useSummaryRow(dilutionByCustomerData, activeItems, {
    sumFields: ['invoices', 'payments', 'dilutiveCredits', 'other'],
  })
  const visualizationsParams = useMemo(
    () => ({
      [isClientPage ? 'clientId' : 'ongoingReportingId']: id,
    }),
    [isClientPage, id],
  )

  const visualizationsFilters = useMemo(
    () => ({
      ...filters,
      startDate: currentDateRange.startDate,
      endDate: currentDateRange.endDate,
    }),
    [filters, currentDateRange],
  )

  useTrackVisualizationsTable({
    isInitialized,
    category: CATEGORIES.dilutionSummary,
    params: visualizationsParams,
    filtersConfig,
    filters: visualizationsFilters,
    orderBy,
  })

  const noDataAvailable = useMemo(
    () => !maxDate && !minDate && !isDropdownsLoading,
    [maxDate, minDate, isDropdownsLoading],
  )

  return (
    <FullscreenModal
      isOpen={isModalShown}
      setIsOpen={setIsModalShown}
      classes={{ body: styles.fullScreenModal }}
    >
      <TableContainer
        className={styles.table}
        onActiveRowsChange={setActiveItems}
        onActiveRowChange={setActiveItem}
      >
        <Form
          validate={filtersValidate}
          onSubmit={handleFiltersChange}
          initialValues={filters}
          mutators={{
            setFieldData: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value)
            },
          }}
          render={({ values, handleSubmit, form: { mutators } }) => (
            <FilterContainer
              filters={filtersConfig}
              handleSubmit={handleSubmit}
              mutators={mutators}
              values={values}
              appliedFilters={filters}
              appliedQuickFilter={quickFilter}
              handleAppliedQuickFilterChange={handleQuickFilterChange}
              title={
                <Box mr={2}>
                  <h2>
                    {reportingFlow === ReportingFlow.LoanCommitteeReport
                      ? 'Dilution by Customer'
                      : isClientPage
                      ? 'Dilution Summary'
                      : ''}
                  </h2>
                </Box>
              }
              actions={
                <Box display="flex" alignItems="center" gap={1}>
                  <DatePicker
                    reportingPeriod={ReportingPeriods.Monthly}
                    currentDateRange={currentDateRange}
                    datesBoundary={datesBoundary}
                    onChange={handleDateChange}
                  />

                  {reportingFlow === ReportingFlow.LoanCommitteeReport && boxLink && (
                    <ExternalLink link={boxLink} />
                  )}

                  <ExpandAndMinimize action={handleModalChange} isExpanded={isModalShown} />
                </Box>
              }
            />
          )}
        />

        {noDataAvailable ? (
          <Empty message="No dilution data is available" />
        ) : (
          <Table ref={wrapperRef}>
            <TableHead>
              <TableFiltersRow
                filters={filtersConfig}
                orderBy={orderBy}
                handleOrderChange={handleOrderChange}
              />
            </TableHead>
            <TableBody id="scrollableTable">
              {isLoading ? (
                <TableLoader columnsCount={filtersConfig.length} />
              ) : (
                dilutionByCustomerDataLength > 0 && (
                  <InfiniteScroll
                    dataLength={dilutionByCustomerDataLength}
                    next={loadMore}
                    hasMore={dilutionByCustomerDataLength < dilutionByCustomerDataTotalItems}
                    loader={<TableLoader columnsCount={filtersConfig.length} rowsCount={1} />}
                    scrollableTarget="scrollableTable"
                  >
                    {dilutionByCustomerData.map((item, index) => (
                      <TableRow
                        key={item.debtor}
                        data-index={index}
                        className={cn('activableRow', {
                          activeRow: activeItems.includes(index),
                          currentActiveRow: activeItem === index,
                        })}
                        onClick={(event) => handleSelectRow(event, index)}
                      >
                        <TableCell className={genericSs.tableTextLeft}>
                          {item.entityId ? (
                            <LinkButton
                              component={Link}
                              to={generatePath(ROUTES.ENTITY_PAGE, {
                                id: item.entityId,
                              })}
                            >
                              {item.debtor}
                            </LinkButton>
                          ) : (
                            <span>{item.debtor}</span>
                          )}
                        </TableCell>
                        <TableCell className={genericSs.tableTextRight}>
                          <span className={genericSs.pricePrefix}>$</span>
                          {formatPrice(item.invoices)}
                        </TableCell>
                        <TableCell className={genericSs.tableTextRight}>
                          <span className={genericSs.pricePrefix}>$</span>
                          {formatPrice(item.payments)}
                        </TableCell>
                        <TableCell className={genericSs.tableTextRight}>
                          <span className={genericSs.pricePrefix}>$</span>
                          {formatPrice(item.dilutiveCredits)}
                        </TableCell>
                        <TableCell className={genericSs.tableTextRight}>
                          <span className={genericSs.pricePrefix}>$</span>
                          {formatPrice(item.other)}
                        </TableCell>
                        <TableCell className={genericSs.tableTextRight}>
                          {formatPercent(item.dilution)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </InfiniteScroll>
                )
              )}
              <MultiselectRow activeItems={activeItems}>
                <TableCell className={genericSs.tableTextRight}>
                  <span className={genericSs.pricePrefix}>$</span>
                  {formatPrice(totalRow?.invoices)}
                </TableCell>
                <TableCell className={genericSs.tableTextRight}>
                  <span className={genericSs.pricePrefix}>$</span>
                  {formatPrice(totalRow?.payments)}
                </TableCell>
                <TableCell className={genericSs.tableTextRight}>
                  <span className={genericSs.pricePrefix}>$</span>
                  {formatPrice(totalRow?.dilutiveCredits)}
                </TableCell>
                <TableCell className={genericSs.tableTextRight}>
                  <span className={genericSs.pricePrefix}>$</span>
                  {formatPrice(totalRow?.other)}
                </TableCell>
                <TableCell className={genericSs.tableTextRight}>
                  {formatPercent(
                    totalRow?.invoices && totalRow?.dilutiveCredits
                      ? Math.abs(totalRow?.dilutiveCredits / totalRow?.invoices)
                      : 0,
                  )}
                </TableCell>
              </MultiselectRow>
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell className={genericSs.tableTextLeft}>Total</TableCell>
                <TableCell className={genericSs.tableTextRight}>
                  <span className={genericSs.pricePrefix}>$</span>
                  {formatPrice(dilutionByCustomerDataTotals?.invoices)}
                </TableCell>
                <TableCell className={genericSs.tableTextRight}>
                  <span className={genericSs.pricePrefix}>$</span>
                  {formatPrice(dilutionByCustomerDataTotals?.payments)}
                </TableCell>
                <TableCell className={genericSs.tableTextRight}>
                  <span className={genericSs.pricePrefix}>$</span>
                  {formatPrice(dilutionByCustomerDataTotals?.dilutiveCredits)}
                </TableCell>
                <TableCell className={genericSs.tableTextRight}>
                  <span className={genericSs.pricePrefix}>$</span>
                  {formatPrice(dilutionByCustomerDataTotals?.other)}
                </TableCell>
                <TableCell className={genericSs.tableTextRight}>
                  {formatPercent(dilutionByCustomerDataTotals?.dilution)}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        )}
      </TableContainer>
    </FullscreenModal>
  )
}

export default DilutionByCustomer
