import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useParams } from 'react-router'
import InputAdornment from '@mui/material/InputAdornment'
import { Form, FormSpy } from 'react-final-form'
import InfiniteScroll from 'react-infinite-scroll-component'
import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'
import cn from 'classnames'
import { usePermissions } from '../../helpers/permissionContext'

import styles from './ProspectInventoryAnalysis.module.scss'
import genericSs from '@styles/generic.module.scss'
import { IFile } from '@common/interfaces/box'
import FullscreenModal from '../Common/FullscreenModal'
import MultiselectRow from '../MultiselectRow'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableContainer from '../Common/TableContainer'
import TableBody from '../Common/TableBody'
import TableFooter from '../Common/TableFooter'
import {
  IInventoryIneligibilityAnalisysSKUData,
  OngoingReportingType,
} from '@common/interfaces/bbc'
import { debounceEventHandler, formatPrice } from '../../helpers/helpers'
import TableFiltersRow from '../Common/TableFiltersRow'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import {
  PROSPECTS_INVENTORY_LIST_FILTERS_CONFIG,
  PER_PAGE,
  FULLSCREEN_PER_PAGE,
} from '@common/constants/filters'
import FilterContainer from '../Filters/FilterContainer'
import { ExternalLink, ExpandAndMinimize, EditSourceData } from '../Common/Icons'
import Grid from '@mui/material/Grid'
import Card from '../Common/Card'
import TableLoader from '../Common/TableLoader'
import { ClientInfoStatus } from '@common/interfaces/client'
import { IOPSReporting, OPSReportingStatus } from '@common/interfaces/prospects'
import TextField from '../Common/TextField'
import Box from '@mui/material/Box'
import SaveState from '../Common/SaveState'
import useSummaryRow from '../../hooks/useSummaryRow'
import useTable from '../../hooks/useTable'

const filtersValidate = buildFiltersValidateSchema(PROSPECTS_INVENTORY_LIST_FILTERS_CONFIG)
const filtersDefaults = buildFiltersDefaults(PROSPECTS_INVENTORY_LIST_FILTERS_CONFIG)

const schema = Yup.object().shape({
  advanceRate: Yup.number()
    .required('Advance Rate is required')
    .typeError('Invalid number')
    .min(0, 'Advance Rate must be greater than or equal 0')
    .max(100, 'Advance Rate be less than or equal 100'),
})

const validate = makeValidate(schema)

interface IProps {
  reporting: IOPSReporting
  listInventory: (
    id: string,
    data: object,
  ) => Promise<{ data: IInventoryIneligibilityAnalisysSKUData }>
  refreshCounter?: number
  selectedFile: IFile
  handleOpenModal: () => void
  updateReportingFlowAdditionalData: (id: string, flowId: string, data: object) => Promise<any>
  isFilesSaving: boolean
}

const ProspectInventoryAnalysis = ({
  reporting,
  listInventory,
  refreshCounter,
  selectedFile,
  handleOpenModal,
  updateReportingFlowAdditionalData,
  isFilesSaving,
}: IProps) => {
  const { id } = useParams<{ id: string }>()

  const { isUW } = usePermissions()

  const readOnly = useMemo(
    () =>
      ![ClientInfoStatus.Prospect, ClientInfoStatus.Archived].includes(
        reporting?.clientInfo?.clientStatus,
      ) ||
      reporting?.status === OPSReportingStatus.Archived ||
      isUW,
    [reporting, isUW],
  )

  const [isLoading, setIsLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [result, setResult] = useState(null as IInventoryIneligibilityAnalisysSKUData)
  const [refreshCounterWithoutLoader, setRefreshCounterWithoutLoader] = useState(0)
  const [isModalShown, setIsModalShown] = useState(false)

  const {
    filters,
    handleFiltersChange,
    orderBy,
    handleOrderChange,
    activeItem,
    activeItems,
    handleSelectRow,
    setActiveItem,
    setActiveItems,
  } = useTable({
    tableId: 'prospectInventory',
    sortDefault: {
      field: 'totalValue',
      direction: 'DESC',
    },
    filtersDefaults,
  })

  const fetchInventoryList = useCallback(
    async (data: any) => {
      const params = {
        ...data,
        filters: {
          ...data.filters,
        },
        perPage: isModalShown ? FULLSCREEN_PER_PAGE : PER_PAGE,
      }
      !data.loadMore && !data.skipLoader && setIsLoading(true)
      const res = await listInventory(id, params)
      if (!data.loadMore) {
        setResult(res.data)
      } else {
        setResult((previous) => ({
          ...previous,
          data: [...previous.data, ...res.data.data],
        }))
      }
      setIsLoading(false)
    },
    [id, listInventory, isModalShown],
  )

  const debounceListInventory = useMemo(
    () => debounceEventHandler(fetchInventoryList, 500),
    [fetchInventoryList],
  )

  useEffect(() => {
    debounceListInventory({
      page: 0,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
  }, [orderBy, filters, debounceListInventory, refreshCounter])

  useEffect(() => {
    debounceListInventory({
      page: 0,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
      skipLoader: true,
    })
  }, [orderBy, filters, debounceListInventory, refreshCounterWithoutLoader])

  const loadMore = useCallback(() => {
    fetchInventoryList({
      loadMore: true,
      page: Math.ceil(result?.data.length / (isModalShown ? FULLSCREEN_PER_PAGE : PER_PAGE)),
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
  }, [result, orderBy, filters, fetchInventoryList, isModalShown])

  const totalRow = useSummaryRow(result?.data, activeItems, {
    sumFields: ['totalValue', 'totalAvailability', 'percent'],
  })

  const handleModalChange = useCallback(() => {
    setIsModalShown((prev) => !prev)
  }, [])

  const opsReportingFlow = useMemo(
    () =>
      reporting?.opsReportingFlows?.filter(
        ({ type }) => type === OngoingReportingType.Inventory,
      )[0],
    [reporting],
  )

  const additionalDataInitialValues = useMemo(
    () => ({
      advanceRate: opsReportingFlow?.advanceRate * 100 || 55,
    }),
    [opsReportingFlow],
  )

  const handleChangeAdditionalData = useMemo(
    () =>
      debounceEventHandler(async (data: any) => {
        setIsSaving(true)
        await updateReportingFlowAdditionalData(id, opsReportingFlow.id, {
          advanceRate: (+data.advanceRate || 0) / 100,
        })
        setIsSaving(false)
        setRefreshCounterWithoutLoader((counter) => counter + 1)
      }, 500),
    [id, opsReportingFlow, updateReportingFlowAdditionalData],
  )

  return (
    <FullscreenModal
      isOpen={isModalShown}
      setIsOpen={setIsModalShown}
      classes={{ body: styles.fullScreenModal }}
    >
      <Card noHeaderMargin withBorder={false}>
        <TableContainer
          className={styles.table}
          onActiveRowsChange={setActiveItems}
          onActiveRowChange={setActiveItem}
          hasFooter
        >
          <Form
            validate={filtersValidate}
            onSubmit={handleFiltersChange}
            initialValues={filters}
            mutators={{
              setFieldData: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value)
              },
            }}
            render={({ values, handleSubmit, form: { mutators } }) => (
              <FilterContainer
                filters={PROSPECTS_INVENTORY_LIST_FILTERS_CONFIG}
                handleSubmit={handleSubmit}
                mutators={mutators}
                values={values}
                appliedFilters={filters}
                title={'Inventory Summary'}
                actions={
                  <Grid container columnSpacing={2} justifyContent="right" alignItems="center">
                    <Grid item>
                      <Form
                        onSubmit={handleChangeAdditionalData}
                        initialValues={additionalDataInitialValues}
                        validate={validate}
                        render={({ handleSubmit }) => (
                          <>
                            <TextField
                              className={styles.textField}
                              name="advanceRate"
                              label="Advance Rate"
                              disabled={readOnly}
                              InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                type: 'number',
                              }}
                            />
                            <FormSpy
                              subscription={{ valid: true, dirty: true, values: true }}
                              onChange={(props) => {
                                props.valid && props.dirty && handleSubmit(props.values)
                              }}
                            />
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item>
                      {selectedFile?.link && (
                        <ExternalLink link={selectedFile?.link} title={'Inventory detail'} />
                      )}
                    </Grid>
                    {!readOnly && (
                      <Grid item>
                        <EditSourceData action={handleOpenModal} error={!!selectedFile?.error} />
                      </Grid>
                    )}
                    <Grid item>
                      <ExpandAndMinimize action={handleModalChange} isExpanded={isModalShown} />
                    </Grid>
                  </Grid>
                }
              />
            )}
          />
          <Table>
            <TableHead>
              <TableFiltersRow
                filters={PROSPECTS_INVENTORY_LIST_FILTERS_CONFIG}
                orderBy={orderBy}
                handleOrderChange={handleOrderChange}
              />
            </TableHead>
            <TableBody id="scrollableTableModal">
              {isFilesSaving || isLoading ? (
                <TableLoader
                  columnsCount={PROSPECTS_INVENTORY_LIST_FILTERS_CONFIG.length}
                  height={24}
                />
              ) : (
                result?.data && (
                  <InfiniteScroll
                    dataLength={result?.data.length}
                    next={loadMore}
                    hasMore={result?.data.length < result?.totals.totalItems}
                    loader={
                      <TableLoader
                        columnsCount={PROSPECTS_INVENTORY_LIST_FILTERS_CONFIG.length}
                        rowsCount={1}
                        height={24}
                      />
                    }
                    scrollableTarget="scrollableTableModal"
                  >
                    {result?.data.map((item, index) => (
                      <TableRow
                        key={`${item.sku}-${item.type}-${item.location}-${item.description}`}
                        data-index={index}
                        className={cn('activableRow', {
                          activeRow: activeItems.includes(index),
                          currentActiveRow: activeItem === index,
                        })}
                        onClick={(event) => handleSelectRow(event, index)}
                      >
                        <TableCell className={genericSs.tableTextLeft}>
                          {item.description}
                        </TableCell>
                        <TableCell className={genericSs.tableTextLeft}>{item.sku}</TableCell>
                        <TableCell className={genericSs.tableTextLeft}>{item.type}</TableCell>
                        <TableCell className={genericSs.tableTextLeft}>
                          {item.location || '-'}
                        </TableCell>
                        <TableCell className={cn(styles.totals, genericSs.tableTextRight)}>
                          <p>${formatPrice(item.totalValue)}</p>
                        </TableCell>
                        <TableCell className={cn(styles.totals, genericSs.tableTextRight)}>
                          <p> {(item.percent * 100 || 0).toFixed(2)} %</p>
                        </TableCell>
                        <TableCell className={cn(styles.totals, genericSs.tableTextRight)}>
                          <p>${formatPrice(item.totalAvailability)}</p>
                        </TableCell>
                      </TableRow>
                    ))}
                  </InfiniteScroll>
                )
              )}
              <MultiselectRow activeItems={activeItems}>
                <TableCell className={genericSs.tableTextRight}>
                  <span className={genericSs.pricePrefix}>$</span>
                  {formatPrice(totalRow?.totalValue)}
                </TableCell>
                <TableCell className={genericSs.tableTextRight}>
                  {(totalRow?.percent * 100 || 0).toFixed(2)} %
                </TableCell>
                <TableCell className={genericSs.tableTextRight}>
                  <span className={genericSs.pricePrefix}>$</span>
                  {formatPrice(totalRow?.totalAvailability)}
                </TableCell>
              </MultiselectRow>
            </TableBody>
            {result?.totals && (
              <TableFooter>
                <TableRow>
                  <TableCell className={genericSs.tableTextLeft}>
                    <p className={genericSs.tableTextLeft}>Top 5 Largest SKUs</p>
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell className={genericSs.tableTextRight}>
                    <p>${formatPrice(result.totals.totalValueTop5)}</p>
                  </TableCell>
                  <TableCell className={genericSs.tableTextRight}>
                    <p>{(result.totals.totalValueTop5Percent * 100 || 0).toFixed(2)}%</p>
                  </TableCell>
                  <TableCell className={genericSs.tableTextRight}>
                    <p>${formatPrice(result.totals.totalAvailabilityTop5)}</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={genericSs.tableTextLeft}>
                    <p className={genericSs.tableTextLeft}>Top 10 Largest SKUs</p>
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell className={genericSs.tableTextRight}>
                    <p>${formatPrice(result.totals.totalValueTop10)}</p>
                  </TableCell>
                  <TableCell className={genericSs.tableTextRight}>
                    <p>{(result.totals.totalValueTop10Percent * 100 || 0).toFixed(2)}%</p>
                  </TableCell>
                  <TableCell className={genericSs.tableTextRight}>
                    <p>${formatPrice(result.totals.totalAvailabilityTop10)}</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={genericSs.tableTextLeft}>
                    <p className={genericSs.tableTextLeft}>Total Inventory</p>
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell className={genericSs.tableTextRight}>
                    <p>$ {formatPrice(result.totals.totalValueAll)}</p>
                  </TableCell>
                  <TableCell className={genericSs.tableTextRight}>
                    <p>100 %</p>
                  </TableCell>
                  <TableCell className={genericSs.tableTextRight}>
                    <p>$ {formatPrice(result.totals.totalAvailabilityAll)}</p>
                  </TableCell>
                </TableRow>
              </TableFooter>
            )}
          </Table>

          <Box display="flex" alignItems="center" justifyContent="space-between">
            {result?.totals.totalItems > 0 ? (
              <div className={genericSs.itemsCount}>
                {result.data.length} / {result.totals.totalItems}
              </div>
            ) : (
              <div />
            )}
            <SaveState isSaving={isSaving} isSaved />
          </Box>
        </TableContainer>
      </Card>
    </FullscreenModal>
  )
}

export default ProspectInventoryAnalysis
