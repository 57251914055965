import React, { useEffect, ReactNode, useMemo, useCallback } from 'react'
import classnames from 'classnames'

import styles from './MainLayout.module.scss'
import genericSs from '@styles/generic.module.scss'

import { ReactComponent as WarningIcon } from '@assets/images/warning-polygon-icon.svg'
import { IUser, UserRole } from '@common/interfaces/user'
import SideMenu from '../../components/SideMenu'
import Loader from '../../components/Loader'
import Header from '../../components/Header'
import { currentWorkFlow } from '../../helpers/helpers'
import SecondaryMenu from '../../components/SecondaryMenu/SecondaryMenu'
import RightSidebar from '../../components/RightSidebar'
import { IClientInfo } from '@common/interfaces/client'
import BoxViewer from '../../components/BoxViewer'
import { usePermissions } from '../../helpers/permissionContext'
import { clarity } from 'react-microsoft-clarity'
import AtlasAIChat from '../../components/AtlasAIChat'
interface IProps {
  isInitialized: boolean
  onInit: () => void
  children: ReactNode
  isBlankLayout: boolean
  role: string
  toggleNotesOn: () => void
  toggleNotesOff: () => void
  pathname: string
  isSidebarOpen: boolean
  isNotesShown: boolean
  isAdminLoggedAsClient: boolean
  logoutAsClient: () => void
  clientInfo?: IClientInfo
  isProspectUser: boolean
  user: IUser
  listSessions: () => void
}

const MainLayout = ({
  isInitialized,
  onInit,
  children,
  isBlankLayout,
  role,
  toggleNotesOn,
  toggleNotesOff,
  pathname,
  isSidebarOpen,
  isNotesShown,
  isAdminLoggedAsClient,
  logoutAsClient,
  clientInfo,
  isProspectUser,
  user,
  listSessions,
}: IProps) => {
  useEffect(() => {
    onInit()
  }, [onInit])

  const { isParticipant, isAdmin, isPortfolio, isBDO, isExtendedRightsRole } = usePermissions()

  useEffect(() => {
    if (isExtendedRightsRole) {
      listSessions()
    }
  }, [listSessions, isExtendedRightsRole])

  const workflow = useMemo(
    () => currentWorkFlow(pathname, isPortfolio, isAdmin, isBDO),
    [pathname, isPortfolio, isAdmin, isBDO],
  )

  const handleToggleNotes = useCallback(() => {
    if (!workflow || isParticipant) {
      toggleNotesOff()
    } else if (workflow) {
      toggleNotesOn()
    }
  }, [toggleNotesOn, toggleNotesOff, workflow, isParticipant])

  useEffect(() => {
    handleToggleNotes()
  }, [handleToggleNotes])

  const handleLogoutAsClient = useCallback(() => {
    if (isAdminLoggedAsClient) {
      logoutAsClient()
    }
  }, [isAdminLoggedAsClient, logoutAsClient])

  useEffect(() => {
    if (user?.id && clarity.hasStarted()) {
      clarity.identify(user.email, {
        'friendly-name': `${user.firstName} ${user.lastName}`,
        role: user.role,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        clientName: user.clientName,
      })
    }
  }, [user])

  if (!isInitialized) {
    return <Loader />
  }

  return (
    <div className={styles.container}>
      {!isBlankLayout && <SideMenu />}
      <SecondaryMenu />
      <div
        className={classnames(styles.content, {
          [styles.blankLayoutContent]: isBlankLayout,
          [styles.loggedAsClientContent]: isAdminLoggedAsClient,
        })}
      >
        <div
          className={classnames(styles.childrenContent, {
            [styles.childrenContentClient]: role === UserRole.CLIENT_USER,
          })}
        >
          {children}
        </div>

        <div
          id="multiSelectToolbar"
          className={classnames(genericSs.multiSelectToolbar, {
            [genericSs.multiSelectToolbarWithSidebar]: isSidebarOpen,
            [genericSs.multiSelectToolbarWithNotes]: isNotesShown,
          })}
        />
        <div id="multiSelectToolbarModal" className={genericSs.multiSelectToolbarModal} />
      </div>
      <Header workflow={workflow} />
      {isAdminLoggedAsClient && (
        <div
          className={classnames(styles.loginAsClientContainer, {
            [styles.loginAsClientContainerExpanded]: isSidebarOpen,
            [styles.loginAsClientContainerProspect]: isProspectUser,
          })}
        >
          <WarningIcon />
          <span>
            You are viewing Atlas as <b>{clientInfo?.clientName}</b>. Click&nbsp;
            <span className={styles.loginAsClientContainerLink} onClick={handleLogoutAsClient}>
              here
            </span>
            &nbsp;to go back to your account.
          </span>
        </div>
      )}
      {isExtendedRightsRole && <AtlasAIChat />}

      <BoxViewer />

      {!isBlankLayout && workflow && !isParticipant && <RightSidebar workflow={workflow} />}
    </div>
  )
}

export default MainLayout
