import React, { useState, useCallback, useEffect } from 'react'
import MuiMenu from '@mui/material/Menu'

import { MenuIcon } from '../Icons/Icons'

interface IProps {
  closeMenuCount?: number
  children: React.ReactNode
}

const Menu = ({ children, closeMenuCount }: IProps) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClickMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
  }, [])

  useEffect(() => {
    closeMenuCount && handleCloseMenu()
  }, [closeMenuCount, handleCloseMenu])

  return (
    <>
      <MenuIcon onClick={handleClickMenu} />
      <MuiMenu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {children}
      </MuiMenu>
    </>
  )
}

export default Menu
