import moment from 'moment'
import { OPSReportingFlowStatus, OPSReportingStatus } from '../interfaces/prospects'
import { USER_ROLE_LABEL, UserRole, UserStatus } from '../interfaces/user'
import {
  ClientInfoStatus,
  WatchListRiskRatingTier,
  ClientAmendmentType,
  SalesforceStage,
  ClientIndustries,
} from '../interfaces/client'
import {
  AGGREGATION_TYPES,
  LOAN_TYPES,
  OVERPAYABLE_INTERVAL_VALUES,
  VENDOR_TYPE_VALUES,
} from './client'
import {
  FLAG_TYPE_LABELS,
  FlagColors,
  Category,
  OngoingReportingStatus,
  SkuTypes,
  FundingRequestStatus,
} from '../interfaces/bbc'
import { PROSPECT_REPORTING_TYPES } from '../interfaces/prospects'

import { ActivityViews, ActivityStatus, ActivityType } from '../interfaces/activity'
import { PassThroughStatus } from '../interfaces/passThrough'
import {
  DUE_DILIGENCE_DOCUMENT_REQUEST_STATUS_OPTIONS,
  DueDiligenceDocumentRequestStatus,
  DueDiligenceReportingStatus,
} from '../interfaces/dueDiligence'
import { PROSPECT_REPORTING_MAP } from '../interfaces/prospects'
import { BBCLogType } from '../interfaces/bbcLog'
import { ParticipationStatus } from '../interfaces/participant'
import {
  NewsTags,
  EMAIL_NEWSLETTER_MAPPING,
  NewsletterSections,
  BrandIndustries,
} from '../interfaces/newsfeed'
import { CollectionType } from '../interfaces/collection'

export type FieldType =
  | 'autocomplete'
  | 'list'
  | 'amount'
  | 'percent'
  | 'number'
  | 'text'
  | 'date'
  | 'datetime'
  | 'empty'
  | 'fullSearch'
  | 'quickFilter'

export interface IQuickFilter {
  title: string
  filters: any
  isHidden?: boolean
}

export interface IFilter {
  field: string
  dbField?: string
  type: FieldType
  loadOptions?: (value: string) => Promise<any[]>
  alias?: string
  isCustom?: boolean
  isMultiple?: boolean
  isSortable?: boolean
  isFullWidth?: boolean
  isRawText?: boolean
  placeholder?: string
  title?: string
  filterTitle?: string
  align?: string
  options?: any[]
  isHighlighted?: boolean
  highlightColor?: string
  excludeFilter?: boolean
  excludeFullSearchFilter?: boolean
  excludeColumn?: boolean
  aggColumn?: boolean
  fields?: {
    field: string
    type: FieldType
  }[]
  quickFilters?: IQuickFilter[]
}

export const PER_PAGE = 40

export const FULLSCREEN_PER_PAGE = 60

export const SHORT_FILTERS_COUNT = 3

export const SHORT_FILTERS_SORTING_TYPES = ['amount', 'percent', 'number']

export const getApplicableFilters = (filters: IFilter[]): IFilter[] =>
  filters?.filter((filter) => !filter.excludeColumn && filter.type !== 'quickFilter') || []

export const ACTIVITIES_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    title: 'View',
    field: 'view',
    alias: 'activity',
    type: 'list',
    excludeColumn: true,
    isCustom: true,
    options: Object.keys(ActivityViews).map((value) => ({
      value,
      label: value,
    })),
  },
  {
    field: 'type',
    type: 'list',
    isMultiple: true,
    isSortable: true,
    options: [
      {
        value: 'all',
        label: 'All',
      },
      ...Object.values(ActivityType).map((value) => ({
        value,
        label: value,
      })),
    ],
    title: 'Type',
    alias: 'activity',
  },
  {
    field: 'clients',
    dbField: 'client_name',
    type: 'autocomplete',
    isSortable: true,
    placeholder: 'Client',
    title: 'Client',
    alias: 'activity',
  },
  {
    field: 'users',
    dbField: 'user',
    type: 'autocomplete',
    isCustom: true,
    isSortable: true,
    placeholder: 'User',
    title: 'User',
  },
  {
    field: 'createdAt',
    dbField: 'created_at',
    type: 'date',
    isCustom: true,
    isSortable: true,
    title: 'Date range',
    alias: 'activity',
  },
  {
    title: 'Status',
    field: 'status',
    alias: 'activity',
    type: 'list',
    isMultiple: true,
    isSortable: true,
    options: Object.values(ActivityStatus).map((value) => ({
      value,
      label: value,
    })),
  },
  {
    type: 'quickFilter',
    field: 'quickFilter',
    fields: [
      {
        field: 'isCompleted',
        type: 'text',
      },
      {
        field: 'isActive',
        type: 'text',
      },
    ],

    quickFilters: [
      {
        title: 'Active',
        filters: {
          isActive: true,
        },
      },
      {
        title: 'Completed Today',
        filters: {
          isCompleted: true,
          createdAtFrom: moment().format('YYYY-MM-DD'),
          createdAtTo: moment().format('YYYY-MM-DD'),
        },
      },
    ],
  },
]
export const USERS_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'firstName',
    dbField: 'first_name',
    type: 'text',
    isSortable: true,
    placeholder: 'First Name',
    title: 'First name',
  },
  {
    field: 'lastName',
    dbField: 'last_name',
    type: 'text',
    isSortable: true,
    placeholder: 'Last Name',
    title: 'Last name',
  },
  {
    field: 'email',
    type: 'text',
    isSortable: true,
    placeholder: 'Email',
    title: 'Email',
  },
  {
    field: 'role',
    type: 'list',
    options: [
      {
        value: 'all',
        label: 'All',
      },
      {
        value: UserRole.ADMIN,
        label: USER_ROLE_LABEL[UserRole.ADMIN],
      },
      {
        value: UserRole.PORTFOLIO_ADMIN,
        label: USER_ROLE_LABEL[UserRole.PORTFOLIO_ADMIN],
      },
      {
        value: UserRole.PORTFOLIO_USER,
        label: USER_ROLE_LABEL[UserRole.PORTFOLIO_USER],
      },
      {
        value: UserRole.BDO,
        label: USER_ROLE_LABEL[UserRole.BDO],
      },
      {
        value: UserRole.UW_USER,
        label: USER_ROLE_LABEL[UserRole.UW_USER],
      },
    ],
    title: 'Role',
  },
  {
    field: 'status',
    type: 'list',
    isSortable: true,
    options: [
      {
        value: 'all',
        label: 'All',
      },
      {
        value: 'active',
        label: 'New',
      },
      {
        value: 'sent',
        label: 'Active',
      },
    ],
    title: 'User Status',
  },
  {
    type: 'list',
    field: 'client',
    isCustom: true,
    options: [
      {
        value: 'all',
        label: 'All',
      },
      {
        value: 'active',
        label: 'New',
      },
      {
        value: 'sent',
        label: 'Active',
      },
    ],
    title: 'Client',
  },
  {
    field: 'action',
    type: 'empty',
    title: 'Action',
  },
]
export const AUDIT_LOG_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'id',
    type: 'empty',
    isSortable: true,
    title: 'ID',
    align: 'left',
  },
  {
    field: 'clients',
    dbField: 'client_name',
    type: 'autocomplete',
    isSortable: true,
    placeholder: 'Client',
    title: 'Client',
  },
  {
    field: 'activities',
    dbField: 'activity',
    type: 'text',
    isMultiple: true,
    isSortable: true,
    title: 'Activity',
  },
  {
    field: 'users',
    dbField: 'user',
    type: 'autocomplete',
    isCustom: true,
    isSortable: true,
    placeholder: 'User',
    title: 'User',
  },
  {
    field: 'tables',
    dbField: '"table"',
    type: 'list',
    isMultiple: true,
    isSortable: true,
    title: 'Table',
  },
  {
    field: 'columns',
    dbField: '"column"',
    type: 'text',
    isMultiple: true,
    isSortable: true,
    title: 'Column',
  },
  {
    field: 'oldValue',
    dbField: 'old_value',
    type: 'text',
    isSortable: true,
    placeholder: 'Old Value',
    title: 'Old Value',
  },
  {
    field: 'newValue',
    dbField: 'new_value',
    type: 'text',
    isSortable: true,
    placeholder: 'New Value',
    title: 'New Value',
  },
  {
    field: 'createdAt',
    dbField: 'created_at',
    alias: 'audit',
    type: 'datetime',
    isSortable: true,
    title: 'Date',
  },
]
export const FUNDING_WIRES_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'clientName',
    type: 'autocomplete',
    isSortable: true,
    placeholder: 'Client',
    title: 'Client',
    align: 'left',
  },
  {
    field: 'amount',
    type: 'amount',
    isSortable: true,
    title: 'Wire Amount',
    align: 'right',
  },
  {
    field: 'sentDate',
    type: 'date',
    isSortable: true,
    title: 'Sent date',
  },
  {
    field: 'boxLink',
    type: 'empty',
    title: 'Certificate',
    align: 'left',
  },
  {
    field: 'isSent',
    type: 'list',
    isMultiple: true,
    isSortable: true,
    title: 'Action/Status',
    align: 'right',
    options: [
      {
        value: 'all',
        label: 'All',
      },
      {
        value: 'true',
        label: 'Sent',
      },
      {
        value: 'false',
        label: 'Not Sent',
      },
    ],
  },
  {
    type: 'quickFilter',
    field: 'quickFilter',
    quickFilters: [
      {
        title: 'Today',
        filters: {
          sentDateFrom: moment().format('YYYY-MM-DD'),
          sentDateTo: moment().format('YYYY-MM-DD'),
        },
      },
    ],
  },
]

export const FUNDING_WIRES_PARTICIPANTS_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'clientName',
    type: 'autocomplete',
    isSortable: true,
    placeholder: 'Funding',
    title: 'Funding',
    align: 'left',
  },
  {
    field: 'currentLoanAmount',
    type: 'amount',
    isSortable: true,
    title: 'Current Loan Balance',
    align: 'right',
    dbField: '"currentLoanAmount"',
  },
  {
    field: 'amount',
    type: 'amount',
    isSortable: true,
    title: 'Wire Amount',
    align: 'right',
  },
  {
    field: 'newLoanAmount',
    type: 'amount',
    isSortable: true,
    title: 'New Loan Balance',
    align: 'right',
    dbField: '"newLoanAmount"',
  },
  {
    field: 'excessAvailibility',
    type: 'amount',
    isSortable: true,
    title: 'Excess Availability',
    align: 'right',
    dbField: '"excessAvailability"',
  },
  {
    field: 'sentDate',
    type: 'date',
    isSortable: true,
    title: 'Sent date',
  },
  {
    type: 'quickFilter',
    field: 'quickFilter',
    quickFilters: [
      {
        title: 'Last 30 Days',
        filters: {
          sentDateFrom: moment().subtract(1, 'month').format('YYYY-MM-DD'),
          sentDateTo: moment().format('YYYY-MM-DD'),
        },
      },
    ],
  },
]
export const PASSTHROUGHS_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'clientName',
    type: 'autocomplete',
    isSortable: true,
    placeholder: 'Client',
    title: 'Client',
  },
  {
    field: 'amount',
    type: 'amount',
    isSortable: true,
    title: 'Pass-through amount',
    align: 'right',
  },
  {
    field: 'sentDate',
    type: 'date',
    isSortable: true,
    title: 'Sent date',
    align: 'right',
  },
  {
    field: 'collectionDays',
    type: 'number',
    isSortable: true,
    title: 'Days negative',
    align: 'right',
  },
  {
    field: 'status',
    type: 'list',
    isMultiple: true,
    isSortable: true,
    align: 'right',
    options: [
      {
        value: 'all',
        label: 'All',
      },
      ...Object.values(PassThroughStatus).map((value) => ({
        value,
        label: value,
      })),
    ],
    title: 'Action',
  },
  {
    type: 'quickFilter',
    field: 'quickFilter',
    quickFilters: [
      {
        title: 'Today',
        filters: {
          sentDateFrom: moment().format('YYYY-MM-DD'),
          sentDateTo: moment().format('YYYY-MM-DD'),
        },
      },
    ],
  },
]
export const DELETED_COLLECTIONS_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'description',
    type: 'text',
    isSortable: true,
    placeholder: 'Description',
    title: 'Description',
  },
  {
    field: 'credit',
    type: 'amount',
    isSortable: true,
    title: 'Credit',
  },
  {
    field: 'debit',
    type: 'amount',
    isSortable: true,
    title: 'Debit',
  },
  {
    field: 'originalRecordDate',
    dbField: 'original_record_date',
    type: 'date',
    isSortable: true,
    title: 'Received date',
  },
  {
    field: 'recordDate',
    dbField: 'record_date',
    type: 'date',
    isSortable: true,
    title: 'Returned date',
  },
  {
    field: 'status',
    type: 'list',
    isMultiple: true,
    options: [
      {
        value: 'all',
        label: 'All',
      },
      {
        value: 'sent',
        label: 'Returned',
      },
      {
        value: 'notsent',
        label: 'Unreturned',
      },
    ],
    title: 'Action',
  },
  {
    type: 'quickFilter',
    field: 'quickFilter',
    quickFilters: [
      {
        title: 'Unreturned',
        filters: {
          status: ['notsent'],
        },
      },
      {
        title: 'Returned Today',
        filters: {
          recordDateFrom: moment().format('YYYY-MM-DD'),
          recordDateTo: moment().format('YYYY-MM-DD'),
        },
      },
      {
        title: 'Received Today',
        filters: {
          originalRecordDateFrom: moment().format('YYYY-MM-DD'),
          originalRecordDateTo: moment().format('YYYY-MM-DD'),
        },
      },
    ],
  },
]
export const NEGATIVE_WIRES_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'clients',
    dbField: 'client_name',
    type: 'autocomplete',
    isSortable: true,
    placeholder: 'Client',
    title: 'Client',
    alias: 'negative_balance_wires',
  },
  {
    field: 'debtors',
    dbField: 'debtor',
    type: 'autocomplete',
    placeholder: 'Customer',
    title: 'Customer',
    alias: 'debtor_info',
  },
  {
    field: 'paymentAmount',
    dbField: 'payment_amount',
    type: 'amount',
    isSortable: true,
    title: 'Amount',
  },
  {
    field: 'recordDate',
    dbField: 'record_date',
    type: 'date',
    isSortable: true,
    title: 'Date',
  },
]
export const ONGOING_REPORTING_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'clients',
    dbField: 'client_name',
    type: 'autocomplete',
    alias: 'reporting',
    isSortable: true,
    placeholder: 'Client',
    title: 'Client',
  },
  {
    field: 'type',
    type: 'list',
    isMultiple: true,
    isSortable: true,
    title: 'Analysis Type',
  },
  {
    field: 'daysSinceDue',
    type: 'empty',
    title: 'Days Since Due',
    align: 'right',
    isSortable: false,
  },
  {
    field: 'asOfDate',
    dbField: 'as_of_date',
    type: 'date',
    align: 'left',
    isSortable: true,
    title: 'Reporting Through',
  },
  {
    field: 'createdAt',
    dbField: 'created_at',
    type: 'date',
    alias: 'reporting',
    align: 'left',
    isSortable: true,
    title: 'Submitted Date',
  },
  {
    field: 'statuses',
    dbField: 'status',
    type: 'list',
    isMultiple: true,
    isSortable: true,
    title: 'Status',
  },
  {
    field: 'action',
    type: 'empty',
    title: 'Action',
    align: 'right',
  },
  {
    field: 'clientStatus',
    title: 'Client Status',
    dbField: 'client_status',
    type: 'list',
    isMultiple: true,
    excludeColumn: true,
    alias: 'clientInfo',
    options: [
      {
        value: ClientInfoStatus.Current,
        label: ClientInfoStatus.Current,
      },
      {
        value: ClientInfoStatus.Past,
        label: ClientInfoStatus.Past,
      },
      {
        value: ClientInfoStatus.PastArchived,
        label: ClientInfoStatus.PastArchived,
      },
      {
        value: ClientInfoStatus.DueDiligence,
        label: ClientInfoStatus.DueDiligence,
      },
    ],
  },
  {
    type: 'quickFilter',
    field: 'quickFilter',
    quickFilters: [
      {
        title: 'Action Required',
        filters: {
          statuses: [
            OngoingReportingStatus.NotStarted,
            OngoingReportingStatus.Uploaded,
            OngoingReportingStatus.Process,
            OngoingReportingStatus.InReview,
            OngoingReportingStatus.Verified,
          ],
        },
      },
      {
        title: 'Submitted This Week',
        filters: {
          createdAtFrom: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        },
      },
      {
        title: 'Archived Reports',
        filters: {
          statuses: [OngoingReportingStatus.Archived],
        },
      },
    ],
  },
]

export const PROSPECT_REPORTING_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'prospect',
    dbField: 'client_name',
    type: 'autocomplete',
    alias: 'opsReportingFlows',
    isSortable: true,
    placeholder: 'Prospect',
    title: 'Prospect',
  },
  {
    field: 'type',
    type: 'list',
    isMultiple: true,
    isSortable: true,
    alias: 'opsReportingFlows',
    title: 'Report type',
    options: Object.values(PROSPECT_REPORTING_TYPES).map((type) => ({
      value: type,
      label: PROSPECT_REPORTING_MAP[type],
    })),
  },
  {
    field: 'boxLink',
    type: 'empty',
    title: 'Folder',
    align: 'left',
    isSortable: true,
    isCustom: true,
  },
  {
    field: 'createdAt',
    dbField: `"filesSubQuery"."createdAt"`,
    type: 'date',
    align: 'right',
    isSortable: true,
    title: 'Submitted date',
    excludeFullSearchFilter: true,
    aggColumn: true,
  },
  {
    field: 'completedAt',
    dbField: 'completed_at',
    type: 'date',
    align: 'right',
    isSortable: true,
    title: 'Completed date',
    excludeFullSearchFilter: true,
  },
  {
    field: 'monthsProcessed',
    type: 'empty',
    align: 'right',
    isSortable: false,
    excludeFullSearchFilter: true,
    title: 'Processed as of',
    excludeFilter: true,
  },
  {
    field: 'statuses',
    dbField: 'status',
    alias: 'opsReportingFlows',
    type: 'list',
    isMultiple: true,
    isSortable: true,
    title: 'Status',
    options: [
      OPSReportingFlowStatus.FileSelect,
      OPSReportingFlowStatus.Mapping,
      OPSReportingFlowStatus.Complete,
    ],
  },
  {
    field: 'action',
    type: 'empty',
    title: 'Action',
    align: 'right',
  },
  {
    type: 'quickFilter',
    field: 'quickFilter',
    fields: [
      {
        field: 'salesforceOwner',
        type: 'list',
      },
    ],
    quickFilters: [],
  },
]

export const DUE_DILIGENCE_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'type',
    type: 'text',
    isSortable: true,
    title: 'Report type',
  },
  {
    field: 'boxLink',
    type: 'empty',
    title: 'Folder',
    align: 'left',
    isSortable: false,
    isCustom: true,
  },
  {
    field: 'createdAt',
    dbField: 'created_at',
    alias: 'ddReporting',
    type: 'date',
    title: 'Submitted date',
    align: 'left',
    isSortable: true,
  },
  {
    field: 'monthsProcessed',
    type: 'empty',
    align: 'left',
    isSortable: false,
    title: 'Months processed',
  },
  {
    field: 'status',
    type: 'list',
    isMultiple: false,
    isSortable: true,
    title: 'Status',
    options: [
      {
        value: DueDiligenceReportingStatus.NotStarted,
        label: 'Not started',
      },
      {
        value: DueDiligenceReportingStatus.InProgress,
        label: 'In progress',
      },
      {
        value: DueDiligenceReportingStatus.Completed,
        label: 'Completed',
      },
    ],
  },
  {
    field: 'status',
    dbField: 'status',
    type: 'list',
    title: 'Status',
    isSortable: true,
    options: [
      {
        value: UserStatus.New,
        label: 'Invitation Sent',
      },
      {
        value: UserStatus.Active,
        label: 'Active',
      },
    ],
  },
]

export const BANK_TRANSACTIONS_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'cleanedDescription',
    type: 'text',
    isSortable: true,
    placeholder: 'Description',
    title: 'Description',
  },
  {
    field: 'transactionAmount',
    type: 'amount',
    isSortable: true,
    title: 'Total Amount',
  },
  {
    field: 'category',
    type: 'autocomplete',
    isSortable: true,
    title: 'Category',
  },
  {
    field: 'categoryPredictions',
    dbField: 'category_predictions',
    type: 'autocomplete',
    isCustom: true,
    title: 'Category Prediction',
  },
  {
    field: 'entityName',
    type: 'autocomplete',
    isSortable: true,
    title: 'Entity',
  },
  {
    field: 'entityPrediction',
    type: 'text',
    isSortable: true,
    title: 'Entity Prediction',
  },
  {
    field: 'transactionType',
    type: 'list',
    isSortable: true,
    title: 'Transaction Type',
  },
  {
    field: 'recordDate',
    type: 'date',
    isSortable: false,
    title: 'Transaction Date',
    excludeColumn: true,
  },
]

export const BANK_TRANSACTIONS_DETAIL_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'descriptionOriginal',
    type: 'text',
    isSortable: false,
    placeholder: 'Description',
    title: 'Description',
  },
  {
    field: 'recordDate',
    type: 'date',
    isSortable: false,
    isFullWidth: false,
    title: 'Date',
  },
  {
    field: 'amount',
    type: 'amount',
    isSortable: false,
    isFullWidth: false,
    title: 'Amount',
  },
]

export const BANK_TRANSACTIONS_RAW_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'record_date',
    alias: 'bankTransactions',
    type: 'empty',
    align: 'right',
    isSortable: true,
    title: 'Date',
  },
  {
    field: 'description',
    alias: 'bankTransactions',
    type: 'text',
    isSortable: true,
    title: 'Description',
  },
  {
    field: 'category',
    type: 'autocomplete',
    isSortable: true,
    title: 'Category',
  },
  {
    field: 'entity_name',
    type: 'autocomplete',
    isSortable: true,
    title: 'Entity',
  },
  {
    field: 'intermediary_name',
    type: 'autocomplete',
    isSortable: true,
    title: 'Intermediary',
  },
  {
    field: 'amount',
    type: 'amount',
    isSortable: true,
    title: 'Amount',
  },

  {
    type: 'quickFilter',
    field: 'quickFilter',
    quickFilters: [
      {
        title: 'Debits',
        filters: {
          amountMax: 0,
        },
        isHidden: true,
      },
      {
        title: 'Credits',
        filters: {
          amountMin: 0,
        },
        isHidden: true,
      },
    ],
  },
]

export const CAP_TABLE_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'name',
    type: 'text',
    isSortable: true,
    placeholder: 'Investor',
    title: 'Investor',
  },
  {
    field: 'mapping',
    type: 'empty',
    title: 'Mapping',
    align: 'left',
  },
  {
    field: 'prediction',
    type: 'text',
    isSortable: true,
    placeholder: 'Prediction',
    title: 'Prediction',
  },
  {
    field: 'currentOutstandingOwnership',
    type: 'percent',
    isSortable: true,
    title: 'Current Outstanding Ownership',
  },
  {
    field: 'percentOfRound',
    type: 'percent',
    isSortable: true,
    title: '% of Round',
  },
  {
    field: 'percentAfterRound',
    type: 'percent',
    isSortable: true,
    title: '% After Round',
  },
  {
    field: 'amountInvested',
    type: 'amount',
    isSortable: true,
    title: 'Amount',
  },
  {
    field: 'roundInvested',
    type: 'text',
    isSortable: true,
    placeholder: 'Round',
    title: 'Round',
  },
  {
    field: 'description',
    type: 'text',
    isSortable: true,
    placeholder: 'Description',
    title: 'Description',
  },
  {
    field: 'action',
    type: 'empty',
    title: 'Ignore',
  },
]

export const CAP_TABLE_SUMMARY_TITLE_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'action',
    title: 'Action',
    type: 'empty',
  },
  {
    field: 'linked_name',
    alias: 'alias',
    type: 'text',
    title: 'Investor',
    isSortable: true,
    align: 'left',
  },
]

export const PARTICIPANTS_TABLE_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'action',
    title: '',
    type: 'empty',
  },
  {
    field: 'participant',
    alias: 'participantInfo',
    type: 'text',
    title: 'Participant',
    isSortable: true,
    align: 'left',
  },
  {
    field: 'totalNFE',
    type: 'amount',
    title: 'Total NFE',
    isSortable: true,
    align: 'right',
    aggColumn: true,
    dbField: `SUM(DISTINCT participationLoanExposure.participant_exposure_amount)`,
    excludeFullSearchFilter: true,
  },
  {
    field: 'totalClients',
    type: 'number',
    title: 'Total Clients',
    isSortable: true,
    align: 'right',
    aggColumn: true,
    dbField: `COUNT(DISTINCT participations.id)`,
    excludeFullSearchFilter: true,
  },
]

export const PARTICIPANTS_DETAIL_TABLE_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'client_name',
    type: 'text',
    title: 'Client',
    isSortable: true,
    align: 'left',
    alias: 'participation',
  },
  {
    field: 'NFE',
    type: 'amount',
    title: 'NFE',
    isSortable: true,
    align: 'right',
    aggColumn: true,
    alias: 'participationLoanExposure',
    dbField: `participant_exposure_amount`,
    excludeFullSearchFilter: true,
  },
  {
    field: 'loanBalance',
    type: 'amount',
    title: 'ABL Loan Balance',
    isSortable: true,
    align: 'right',
    aggColumn: true,
    alias: 'participationLoanExposure',
    dbField: `loan_balance`,
    excludeFullSearchFilter: true,
  },
  {
    field: 'loanExposure',
    type: 'percent',
    title: 'Target Exposure',
    isSortable: true,
    align: 'right',
    aggColumn: true,
    dbField: 'loan_exposure',
    excludeFullSearchFilter: true,
  },
]

export const INVESTOR_SUMMARY_TITLE_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'action',
    title: 'Action',
    type: 'empty',
  },
  {
    field: 'linked_name',
    alias: 'alias',
    type: 'text',
    title: 'Investor',
    isSortable: true,
    align: 'left',
  },
]

export const FULLY_DILUTED_OWNERSHIP_QUERY = `CASE WHEN COALESCE(MAX("capTableDetails"."fully_diluted_ownership"), 0) = 0 THEN CAST(SUM("capTableDetails"."diluted_shares") AS numeric) / NULLIF(MAX("capTableSummary".total_fully_diluted_shares), 0) ELSE MAX("capTableDetails"."fully_diluted_ownership") END`
export const OUTSTANDING_OWNERSHIP_QUERY = `CAST(SUM("capTableDetails"."outstanding_shares") AS numeric) / NULLIF(MAX("capTableSummary".total_outstanding_shares), 0)`

export const CAP_TABLE_SUMMARY_DATA_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'totalInvestment',
    type: 'text',
    title: 'Total Investment',
    isSortable: true,
    align: 'right',
    aggColumn: true,
    dbField: 'SUM("capTableDetails"."outstanding_shares" * "capTableDetails"."price_per_share")',
  },
  {
    field: 'outstandingShares',
    type: 'text',
    title: 'Outstanding Ownership',
    isSortable: true,
    align: 'right',
    aggColumn: true,
    dbField: OUTSTANDING_OWNERSHIP_QUERY,
  },
  {
    field: 'fullyDilutedOwnership',
    type: 'percent',
    title: 'Full Diluted Ownership',
    align: 'left',
    aggColumn: true,
    isSortable: true,
    dbField: FULLY_DILUTED_OWNERSHIP_QUERY,
  },
]

export const CAP_TABLE_MAPPING_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'description',
    type: 'text',
    isSortable: true,
    title: 'Description',
    align: 'left',
    alias: 'capTableDetails',
  },
  {
    field: 'fullyDilutedOwnership',
    type: 'percent',
    title: 'Full Diluted Ownership',
    align: 'left',
    aggColumn: true,
    isSortable: true,
    dbField: FULLY_DILUTED_OWNERSHIP_QUERY,
  },
  {
    field: 'linked_name',
    type: 'autocomplete',
    isSortable: true,
    title: 'Investor',
    alias: 'alias',
    excludeFilter: true,
  },
  {
    field: 'investorPrediction',
    type: 'text',
    isSortable: true,
    title: 'Prediction',
    alias: 'capTableDetails',
    dbField: 'investor_prediction',
  },
  {
    field: 'boardSeats',
    alias: 'board_seats',
    excludeFullSearchFilter: true,
    dbField: 'name',
    type: 'list',
    title: 'Board Seat Holder(s)',
    isSortable: false,
  },
]

export const COLLECTIONS_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'clients',
    dbField: 'client_name',
    type: 'autocomplete',
    isSortable: true,
    placeholder: 'Client',
    title: 'Client',
  },
  {
    field: 'recordDate',
    dbField: 'record_date',
    type: 'date',
    isSortable: true,
    title: 'Date',
    excludeColumn: true,
  },
  {
    field: 'debtor',
    type: 'autocomplete',
    placeholder: 'Customer',
    title: 'Customer',
    excludeColumn: true,
  },
  {
    field: 'paymentType',
    type: 'list',
    options: Object.values(CollectionType).map((type) => ({
      value: type,
      label: type,
    })),
    title: 'Type',
    excludeColumn: true,
  },
  {
    field: 'count',
    type: 'number',
    title: 'Count',
    aggColumn: true,
    isSortable: true,
    dbField: 'COUNT("paymentType")',
  },
  {
    field: 'checksPaymentAmount',
    type: 'amount',
    title: 'Check amount',
    align: 'right',
    isSortable: true,
    aggColumn: true,
    dbField: 'ROUND(SUM(checks_payment_amount)::numeric, 2)',
  },
  {
    field: 'wiresPaymentAmount',
    type: 'amount',
    title: 'Wire amount',
    align: 'right',
    isSortable: true,
    aggColumn: true,
    dbField: 'ROUND(SUM(wires_payment_amount)::numeric, 2)',
  },
  {
    field: 'totalPaymentAmount',
    type: 'amount',
    isSortable: true,
    title: 'Total amount',
    align: 'right',
    aggColumn: true,
    dbField: 'ROUND(SUM(checks_payment_amount + wires_payment_amount)::numeric, 2)',
  },
  {
    type: 'quickFilter',
    field: 'quickFilter',
    fields: [
      {
        field: 'clientStatus',
        type: 'list',
      },
    ],
    quickFilters: [
      {
        title: 'Today',
        filters: {
          recordDateFrom: moment().format('YYYY-MM-DD'),
          recordDateTo: moment().format('YYYY-MM-DD'),
        },
      },
      {
        title: 'Current Clients',
        isHidden: true,
        filters: {
          clientStatus: [ClientInfoStatus.Current],
        },
      },
    ],
  },
]

export const COLLECTION_ROW_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'debtor',
    title: 'Debtor',
    type: 'text',
    isSortable: true,
  },
  {
    field: 'paymentType',
    title: 'Type',
    type: 'text',
    isSortable: true,
  },
  {
    field: 'recordDate',
    title: 'Record Date',
    type: 'date',
    isSortable: true,
    alias: 'data',
  },
  {
    field: 'description',
    title: 'Description',
    type: 'text',
    isSortable: true,
  },
  {
    field: 'amount',
    title: 'Amount',
    type: 'amount',
    isSortable: true,
  },
  {
    field: 'action',
    title: 'Action',
    type: 'empty',
  },
]

export const PROSPECTS_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'clients',
    dbField: 'client_name',
    type: 'autocomplete',
    isSortable: true,
    placeholder: 'Prospect',
    title: 'Prospect',
    alias: 'clientInfo',
  },
  {
    field: 'salesforceStage',
    dbField: 'salesforce_stage',
    type: 'list',
    isSortable: true,
    isMultiple: true,
    placeholder: 'Stage',
    title: 'Stage',
    alias: 'salesforceProspectInfo',
  },
  {
    field: 'salesforceOwner',
    dbField: 'user_id',
    type: 'list',
    isSortable: true,
    isMultiple: true,
    placeholder: 'BDO',
    title: 'BDO',
    alias: 'salesforceProspectInfo',
  },
  {
    field: 'salesforceLink',
    type: 'empty',
    title: 'Salesforce',
    align: 'left',
    isCustom: true,
    isSortable: true,
    alias: 'salesforceProspectInfo',
  },
  {
    field: 'opsBoxLink',
    type: 'empty',
    title: 'Folder',
    align: 'left',
    isSortable: true,
    isCustom: true,
    alias: 'salesforceProspectInfo',
  },
  {
    field: 'commitmentAmount',
    dbField: 'commitment_amount',
    alias: 'salesforceProspectInfo',
    type: 'amount',
    isSortable: true,
    title: 'Commitment Amount',
  },
  {
    field: 'riskRating',
    dbField: 'risk_rating',
    alias: 'riskRatingRawScores',
    type: 'number',
    isSortable: true,
    title: 'Risk',
  },
  {
    field: 'status',
    dbField: 'status',
    alias: 'opsReporting',
    title: 'Status',
    type: 'list',
    isMultiple: true,
    isSortable: true,
    options: [
      {
        value: '',
        label: 'All',
      },
    ].concat(
      Object.values(OPSReportingStatus).map((status) => ({
        value: status,
        label: status,
      })),
    ),
  },
  {
    field: 'lastDealActivityDate',
    dbField: 'last_deal_activity_date',
    alias: 'salesforceProspectInfo',
    type: 'date',
    isSortable: true,
    title: 'Last Activity',
  },
  {
    type: 'quickFilter',
    field: 'quickFilter',
    quickFilters: [
      {
        title: 'Active Deals',
        filters: {
          salesforceStage: [
            null,
            SalesforceStage.TermSheetNegotiation,
            SalesforceStage.OpsCall,
            SalesforceStage.GatheringInfo,
            SalesforceStage.IntroOrMention,
            SalesforceStage.Submission,
            SalesforceStage.QualifyingCall,
          ],
        },
      },
      {
        title: 'Low Risk',
        filters: {
          riskRatingMin: 5,
        },
      },
    ],
  },
]

export const DD_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'clients',
    dbField: 'client_name',
    type: 'autocomplete',
    isSortable: true,
    placeholder: 'Prospect',
    title: 'Prospect',
    alias: 'clientInfo',
    align: 'left',
  },
  {
    field: 'clientStatus',
    dbField: 'client_status',
    alias: 'clientInfo',
    isMultiple: true,
    type: 'list',
    title: 'Status',
    options: [
      { value: ClientInfoStatus.DueDiligence, label: 'Due Diligence' },
      { value: ClientInfoStatus.Archived, label: 'Archived' },
      { value: ClientInfoStatus.Current, label: 'Current' },
    ],
    align: 'left',
    excludeColumn: true,
  },
  {
    field: 'closeDate',
    dbField: 'close_date',
    type: 'date',
    isSortable: true,
    placeholder: 'Target funding date',
    title: 'Target Funding',
  },
  {
    field: 'age',
    dbField: 'age',
    type: 'number',
    isSortable: true,
    isMultiple: true,
    placeholder: 'Age',
    title: 'Age (days)',
  },
  {
    field: 'progress',
    dbField:
      'ROUND(((ddClientInfo.companyBackgroundProgress + ddClientInfo.teamProgress + ddClientInfo.financialCollateralProgress)/300)::numeric,2)',
    type: 'percent',
    isSortable: true,
    title: 'Progress',
  },
  {
    field: 'riskRating',
    dbField: 'risk_rating',
    alias: 'risk',
    type: 'number',
    isSortable: true,
    title: 'Risk',
  },
  {
    field: 'ebitda',
    alias: 'financials',
    type: 'amount',
    isSortable: true,
    title: 'EBITDA (YTD)',
  },
  {
    field: 'netRevenue',
    dbField: 'net_revenue',
    alias: 'financials',
    type: 'amount',
    isSortable: true,
    title: 'Net Revenue (YTD)',
  },
  {
    field: 'commitmentAmount',
    dbField: 'commitment_amount',
    type: 'amount',
    isSortable: true,
    title: 'Commitment Amount',
  },
  {
    field: 'links',
    type: 'empty',
    title: 'Links',
    align: 'right',
    isSortable: true,
  },
  {
    type: 'quickFilter',
    field: 'quickFilter',
    quickFilters: [
      {
        title: 'Due Diligence',
        filters: {
          clientStatus: [ClientInfoStatus.DueDiligence],
        },
      },
    ],
  },
]

export const PROSPECTS_INVENTORY_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'description',
    type: 'text',
    isSortable: true,
    placeholder: 'Description',
    title: 'Description',
  },
  {
    field: 'sku',
    type: 'text',
    isSortable: true,
    placeholder: 'SKU',
    title: 'SKU',
  },
  {
    field: 'type',
    type: 'text',
    isSortable: true,
    placeholder: 'Type',
    title: 'Type',
  },
  {
    field: 'location',
    type: 'text',
    isSortable: true,
    placeholder: 'Location',
    title: 'Location',
  },
  {
    field: 'totalValue',
    type: 'amount',
    isSortable: true,
    title: 'Total Value',
  },
  {
    field: 'percent',
    type: 'percent',
    isSortable: true,
    title: '% of Total',
  },
  {
    field: 'totalAvailability',
    type: 'amount',
    isSortable: true,
    title: 'Total Availability',
  },
]
export const PROSPECTS_RECEIVABLES_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'debtor',
    dbField: 'memo',
    type: 'text',
    isSortable: true,
    placeholder: 'Description',
    title: 'Description',
  },
  {
    field: 'mapping',
    type: 'empty',
    title: 'Customer',
    align: 'left',
  },
  {
    field: 'prediction',
    type: 'empty',
    title: 'Prediction',
    align: 'left',
  },
  {
    field: 'balance',
    type: 'amount',
    isSortable: true,
    title: 'Balance',
  },
]
export const PROSPECTS_RECEIVABLES_SUMMARY_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'debtor',
    type: 'text',
    isSortable: true,
    placeholder: 'Customer',
    title: 'Customer',
  },
  {
    field: '_0_to_30_days',
    type: 'amount',
    isSortable: true,
    title: '1-30 Days',
  },
  {
    field: '_31_to_60_days',
    type: 'amount',
    isSortable: true,
    title: '31-60 Days',
  },
  {
    field: '_61_to_90_days',
    type: 'amount',
    isSortable: true,
    title: '61-90 Days',
  },
  {
    field: '_91_plus_days',
    type: 'amount',
    isSortable: true,
    title: '91+ Days',
  },
  {
    field: 'cross_aged',
    type: 'amount',
    isSortable: true,
    title: 'Cross Aged',
  },
  {
    field: 'concentration_limit',
    type: 'amount',
    isSortable: true,
    title: 'Conc. Limit',
  },
  {
    field: 'ineligible_ar',
    type: 'amount',
    isSortable: true,
    title: 'Ineligible AR',
  },
  {
    field: 'total',
    type: 'amount',
    isSortable: true,
    title: 'Total AR',
  },
  {
    field: 'total_percent',
    type: 'percent',
    isSortable: true,
    title: '% of Total',
    excludeFullSearchFilter: true,
  },
  {
    field: 'eligible_ar',
    type: 'amount',
    isSortable: true,
    title: 'Eligible AR',
  },
  {
    field: 'available_ar',
    type: 'amount',
    isSortable: true,
    title: 'Available AR',
  },
  {
    field: 'eligible',
    type: 'list',
    options: [
      {
        value: 'all',
        label: 'All',
      },
      {
        value: true,
        label: 'Yes',
      },
      {
        value: false,
        label: 'No',
      },
    ],
    isSortable: true,
    title: 'Eligible',
  },
]

export const PROSPECTS_PAYABLES_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'creditor',
    dbField: 'memo',
    type: 'text',
    isSortable: true,
    placeholder: 'Description',
    title: 'Description',
  },
  {
    field: 'mapping',
    type: 'empty',
    title: 'Vendor',
    align: 'left',
  },
  {
    field: 'prediction',
    type: 'empty',
    title: 'Prediction',
    align: 'left',
  },
  {
    field: 'balance',
    type: 'amount',
    isSortable: true,
    title: 'Balance',
  },
]
export const PROSPECTS_PAYABLES_SUMMARY_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'creditor',
    type: 'text',
    isSortable: true,
    placeholder: 'Vendor',
    title: 'Vendor',
  },
  {
    field: '_0_to_30_days',
    type: 'amount',
    isSortable: true,
    title: '1-30 Days',
  },
  {
    field: '_31_to_60_days',
    type: 'amount',
    isSortable: true,
    title: '31-60 Days',
  },
  {
    field: '_61_to_90_days',
    type: 'amount',
    isSortable: true,
    title: '61-90 Days',
  },
  {
    field: '_91_plus_days',
    type: 'amount',
    isSortable: true,
    title: '91+ Days',
  },
  {
    field: 'total',
    type: 'amount',
    isSortable: true,
    title: 'Total AP',
  },
  {
    field: 'total_percent',
    type: 'percent',
    isSortable: true,
    title: '% of Total',
    excludeFullSearchFilter: true,
  },
]

export const PROSPECTS_REPORT_DETAIL_LIST_FILTERS_CONFIG =
  PROSPECTS_PAYABLES_SUMMARY_LIST_FILTERS_CONFIG

export const ONGOING_REPORTING_MAPPING_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'income',
    type: 'text',
    isSortable: true,
    placeholder: 'Account',
    title: 'Account',
  },
  {
    field: 'mapping',
    type: 'empty',
    title: 'Mapping',
    align: 'left',
  },
  {
    field: 'prediction',
    type: 'empty',
    title: 'Prediction',
    align: 'left',
  },
  {
    field: 'tag',
    type: 'list',
    title: 'Tags',
    align: 'left',
  },

  {
    field: 'amount1',
    type: 'amount',
    isSortable: true,
    isCustom: true,
  },
  {
    field: 'amount2',
    type: 'amount',
    isSortable: true,
    isCustom: true,
  },
  {
    field: 'amount3',
    type: 'amount',
    isSortable: true,
    isCustom: true,
  },
  {
    field: 'amount4',
    type: 'amount',
    isSortable: true,
    isCustom: true,
  },
  {
    field: 'amount5',
    type: 'amount',
    isSortable: true,
    isCustom: true,
  },
  {
    field: 'amount6',
    type: 'amount',
    isSortable: true,
    isCustom: true,
  },
]
export const PORTFOLIO_REVIEW_METRICS_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'lineTurn',
    type: 'number',
    isSortable: true,
    title: 'Line Turn',
    aggColumn: true,
    dbField: 'MAX(collections.collections_t3) / 3 / NULLIF(MAX(balance_avg.balance_avg), 0)',
  },
  {
    field: 'arAdvance',
    type: 'percent',
    isSortable: true,
    title: 'AR Adv.',
    alias: 'clientInfo',
    dbField: 'ar_advance',
  },
  {
    field: 'inventoryAdvanceRateCost',
    type: 'percent',
    isSortable: true,
    title: 'Inventory Adv.',
    alias: 'clientInfo',
    dbField: 'inventory_advance_rate_cost',
  },
  {
    field: 'nolv',
    type: 'percent',
    isSortable: true,
    title: 'NOLV',
    alias: 'clientInfo',
    dbField: 'nolv',
  },
  {
    field: 'minimumLineAmount',
    type: 'amount',
    isSortable: true,
    title: 'Minimum',
    alias: 'clientInfo',
    dbField: 'minimum_line_amount',
  },
  {
    field: 'maxLineAmount',
    type: 'amount',
    isSortable: true,
    title: 'Max Line Amount',
    alias: 'clientInfo',
    dbField: 'max_line_amount',
  },
  {
    field: 'clientStatus',
    dbField: 'client_status',
    type: 'list',
    isMultiple: true,
    isSortable: true,
    options: [
      { value: 'all', label: 'All' },
      { value: ClientInfoStatus.Active, label: 'Active' },
      { value: ClientInfoStatus.Current, label: 'Current' },
      { value: ClientInfoStatus.DueDiligence, label: 'Due Diligence' },
      { value: ClientInfoStatus.Past, label: 'Past' },
      { value: ClientInfoStatus.Archived, label: 'Past Archived' },
    ],
    placeholder: 'Status',
    title: 'Status',
    excludeColumn: true,
  },
]

export const PORTFOLIO_REVIEW_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'clients',
    dbField: 'client_name',
    type: 'autocomplete',
    isSortable: true,
    placeholder: 'Client',
    title: 'Client',
    alias: 'clientInfo',
  },
  {
    field: 'currentAmount',
    type: 'amount',
    isSortable: true,
    title: 'Loan Balance',
    aggColumn: true,
    dbField:
      '(COALESCE(MAX(loanBalances.loan_balance),0)+ COALESCE(MAX(term_loan_balances.term_loan_balance),0))',
  },
  {
    field: 'utilization',
    type: 'percent',
    isSortable: true,
    title: 'Utilization',
    aggColumn: true,
    dbField: `MAX(CASE WHEN "clientInfo".client_status = '${ClientInfoStatus.Current}' THEN loanBalances.loan_balance ELSE 0 END)/NULLIF(MAX(CASE WHEN "clientInfo".client_status = '${ClientInfoStatus.Current}' THEN clientCollaterals.final_net_availability ELSE 0 END), 0)`,
  },
  {
    field: 'risk',
    type: 'number',
    isSortable: true,
    title: 'Risk Rating',
    aggColumn: true,
    dbField: 'MAX(riskRatingRawScores.risk_rating)',
  },
  {
    type: 'quickFilter',
    field: 'quickFilter',
    fields: [
      {
        field: 'userId',
        type: 'list',
      },
    ],
    quickFilters: [],
  },
]

export const PARTICIPANT_PORTFOLIO_REVIEW_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'clients',
    dbField: 'client_name',
    type: 'autocomplete',
    isSortable: true,
    placeholder: 'Client',
    title: 'Client',
    alias: 'clientInfo',
  },
  {
    field: 'currentAmount',
    type: 'amount',
    isSortable: true,
    title: 'Exposure',
    aggColumn: true,
    dbField: 'MAX(participation_loan_exposure."participantExposureAmount")',
  },
  {
    field: 'loanBalance',
    type: 'amount',
    isSortable: true,
    title: 'Loan Balance',
    aggColumn: true,
    dbField:
      '(COALESCE(MAX(loanBalances.loan_balance),0)+ COALESCE(MAX(term_loan_balances.term_loan_balance),0))',
  },
  {
    field: 'utilization',
    type: 'percent',
    isSortable: true,
    title: 'Utilization',
    aggColumn: true,
    dbField: `MAX(CASE WHEN "clientInfo".client_status = '${ClientInfoStatus.Current}' THEN loanBalances.loan_balance ELSE 0 END)/NULLIF(MAX(CASE WHEN "clientInfo".client_status = '${ClientInfoStatus.Current}' THEN clientCollaterals.final_net_availability ELSE 0 END), 0)`,
  },
  {
    field: 'clientParticipantStatus',
    dbField: 'termination_date',
    type: 'list',
    isMultiple: true,
    isSortable: true,
    options: [
      { value: 'all', label: 'All' },
      { value: ParticipationStatus.Active, label: ParticipationStatus.Active },
      { value: ParticipationStatus.Terminated, label: 'Past' },
    ],
    placeholder: 'Status',
    title: 'Status',
    excludeColumn: true,
    excludeFullSearchFilter: true,
  },
]

export const COMPLIANCE_BY_CLIENT_LIST_FILTERS_EDITABLE_CONFIG: IFilter[] = [
  {
    field: 'capTable',
    type: 'date',
    isSortable: true,
    title: 'Cap Table',
  },
  {
    field: 'warehouseWaiverPercentage',
    type: 'percent',
    isSortable: true,
    title: 'Warehouse Waivers',
  },
  {
    field: 'totalOverdueReports',
    type: 'empty',
    title: '# Overdue',
    align: 'right',
    isSortable: true,
  },
  {
    field: 'action',
    type: 'empty',
    title: 'Action',
    align: 'right',
  },
  {
    type: 'quickFilter',
    field: 'quickFiler',
    fields: [
      {
        field: 'isFinancialsOverdue',
        type: 'text',
      },
      {
        field: 'userId',
        type: 'list',
      },
    ],
    quickFilters: [
      {
        title: 'My Clients',
        isHidden: true,
        filters: {
          userId: null,
        },
      },
      {
        title: 'Codat Connected',
        filters: {
          isErpConnected: ['true'],
        },
      },
      {
        title: 'Overdue Financials',
        isHidden: true,
        filters: {
          isFinancialsOverdue: 'true',
        },
      },
    ],
  },
]

export const COMPLIANCE_BY_CLIENT_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'clientName',
    dbField: 'clientNameSort',
    type: 'text',
    isSortable: true,
    placeholder: 'Client',
    title: 'Client',
  },
  {
    field: 'loanType',
    type: 'list',
    isMultiple: true,
    isSortable: true,
    options: [
      {
        value: 'all',
        label: 'All',
      },
      ...Object.values(LOAN_TYPES).map((value) => ({
        value,
        label: value,
      })),
    ],
    title: 'Deal Type',
  },
  {
    field: 'isErpConnected',
    type: 'list',
    isMultiple: true,
    isSortable: true,
    options: [
      {
        value: 'all',
        label: 'All',
      },
      {
        value: 'true',
        label: 'Yes',
      },
      {
        value: 'false',
        label: 'No',
      },
    ],
    title: 'Sync',
  },
  {
    field: 'clientIncomeStatement',
    type: 'date',
    isSortable: true,
    title: 'Financials',
  },
  {
    field: 'projections',
    type: 'date',
    isSortable: true,
    title: 'Projections',
  },
  {
    field: 'salesBySKU',
    type: 'date',
    isSortable: true,
    title: 'Sales by SKU',
  },
  {
    field: 'arLedger',
    type: 'date',
    isSortable: true,
    title: 'A/R Ledger',
  },
  {
    field: 'bankTransactions',
    type: 'date',
    isSortable: true,
    title: 'Bank Transactions',
  },
  {
    field: 'eopExpiration',
    type: 'date',
    isSortable: true,
    title: 'EOP Expiration',
  },
  {
    field: 'coiExpiration',
    type: 'date',
    isSortable: true,
    title: 'COI Expiration',
  },
  {
    field: 'borrowingBase',
    type: 'date',
    isSortable: true,
    title: 'BBC',
  },
]
export const CLIENT_ACCOUNT_ACTIVITY_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'recordDate',
    dbField: 'record_date',
    type: 'date',
    isSortable: true,
    title: 'Date',
    alias: 'activity',
  },
  {
    field: 'type',
    type: 'list',
    isMultiple: true,
    isSortable: true,
    options: [
      {
        value: 'all',
        label: 'All',
      },
      ...Object.values(AGGREGATION_TYPES).map((value) => ({
        value,
        label: value,
      })),
    ],
    title: 'Type',
    alias: 'activity',
  },
  {
    field: 'description',
    type: 'autocomplete',
    isSortable: true,
    placeholder: 'Description',
    title: 'Description',
    alias: 'activity',
    isMultiple: true,
  },
  {
    field: 'amount',
    type: 'amount',
    isSortable: true,
    title: 'Amount',
    alias: 'activity',
  },
  {
    field: 'loanBalanceEnding',
    dbField: 'loan_balance_ending',
    type: 'amount',
    isSortable: true,
    title: 'ABL Loan Balance',
    alias: 'activity',
  },
  {
    field: 'originalMemo',
    dbField: 'original_memo',
    type: 'text',
    excludeColumn: true,
    alias: 'activity',
  },
  {
    field: 'action',
    type: 'empty',
  },
  {
    type: 'quickFilter',
    field: 'quickFilter',
    quickFilters: [
      {
        title: 'Fundings',
        filters: {
          type: [AGGREGATION_TYPES.funding],
        },
      },
      {
        title: 'Collections',
        filters: {
          type: [AGGREGATION_TYPES.check, AGGREGATION_TYPES.wire, AGGREGATION_TYPES.adjustment],
        },
      },
      {
        title: 'Interest & Fees',
        filters: {
          type: [
            AGGREGATION_TYPES.fee,
            AGGREGATION_TYPES.wireFee,
            AGGREGATION_TYPES.facilityFee,
            AGGREGATION_TYPES.lockboxFee,
            AGGREGATION_TYPES.terminationFee,
            AGGREGATION_TYPES.overadvancesFee,
            AGGREGATION_TYPES.interestAmountMonthly,
          ],
        },
      },
    ],
  },
]
export const CLIENT_TERM_LOANS_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'boxLink',
    dbField: 'box_link',
    type: 'text',
    isSortable: true,
    placeholder: 'Link to contract',
    title: 'Box Link',
  },
  {
    field: 'collateralName',
    dbField: 'collateral_name',
    type: 'text',
    isSortable: true,
    placeholder: 'Collateral Name',
    title: 'Collateral Name',
  },
  {
    field: 'loanAmount',
    dbField: 'loan_amount',
    type: 'amount',
    isSortable: true,
    title: 'Loan Amount',
  },
  {
    field: 'amortization',
    dbField: 'amortization',
    type: 'amount',
    isSortable: true,
    title: 'Amortization',
  },
  {
    field: 'term',
    dbField: 'term',
    type: 'amount',
    isSortable: true,
    title: 'Term',
  },
  {
    field: 'rate',
    dbField: 'rate',
    type: 'percent',
    isCustom: true,
    title: 'Rate',
  },
  {
    field: 'baseRate',
    dbField: 'base_rate',
    type: 'percent',
    isSortable: true,
    title: 'Base Rate',
  },
  {
    field: 'abovePrime',
    dbField: 'above_prime',
    type: 'percent',
    isSortable: true,
    title: 'Above Prime',
  },
  {
    field: 'closingDate',
    dbField: 'closing_date',
    type: 'date',
    isSortable: true,
    title: 'Closing Date',
  },
  {
    field: 'terminationDate',
    dbField: 'termination_date',
    type: 'date',
    isSortable: true,
    title: 'Termination Date',
  },
  {
    field: 'closingFeePercent',
    dbField: 'closing_fee_percent',
    type: 'percent',
    isSortable: true,
    title: 'Closing Fee Percent',
  },
  {
    field: 'closingFeeAmount',
    dbField: 'closing_fee_amount',
    type: 'amount',
    isSortable: true,
    title: 'Closing Fee Amount',
  },
  {
    field: 'miscFees',
    dbField: 'misc_fees',
    type: 'amount',
    isSortable: true,
    title: 'Misc Fees',
  },
  {
    field: 'wireFee',
    dbField: 'wire_fee',
    type: 'amount',
    isSortable: true,
    title: 'Wire Fee',
  },
  {
    field: 'action',
    type: 'empty',
  },
]

export const CLIENT_TERM_LOAN_AMORTIZATION_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'recordDate',
    dbField: 'record_date',
    type: 'date',
    isSortable: true,
    title: 'Date',
  },
  {
    field: 'interest',
    dbField: 'interest',
    type: 'amount',
    isSortable: true,
    title: 'Interest',
  },
  {
    field: 'principal',
    dbField: 'principal',
    type: 'amount',
    isSortable: true,
    title: 'Principal',
  },
  {
    field: 'loanBalance',
    dbField: 'loan_balance',
    type: 'amount',
    isSortable: true,
    title: 'Balance',
  },
  {
    field: 'action',
    type: 'empty',
  },
]

export const CLIENT_AR_ELIGIBILITY_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'debtor',
    type: 'autocomplete',
    isSortable: true,
    placeholder: 'Customer',
    title: 'Customer',
    alias: 'debtorClientEligibility',
  },
  {
    field: 'eligible',
    dbField: 'eligible',
    title: 'Eligible',
    alias: 'debtorClientEligibility',
    type: 'list',
    isSortable: true,
    options: [
      {
        value: 'true',
        label: 'Yes',
      },
      {
        value: 'false',
        label: 'No',
      },
    ],
  },
  {
    field: 'category',
    dbField: 'category',
    type: 'list',
    isSortable: true,
    options: [
      {
        value: 'all',
        label: 'All',
      },
      ...Object.keys(Category).map((category) => ({
        value: category,
        label: category,
      })),
    ],
    title: 'Category',
    alias: 'debtorClientEligibility',
  },
  {
    field: 'lastAmount',
    dbField: '"lastAmount"',
    type: 'amount',
    isSortable: true,
    title: 'Current amount',
    alias: 'clientArSummarySubQueryTotal',
    align: 'left',
  },

  {
    field: 'creditLimit',
    dbField: 'credit_limits',
    type: 'amount',
    isSortable: true,
    title: 'Credit limit',
    align: 'left',
  },

  {
    field: 'concentrationLimit',
    dbField: 'concentration_limit',
    type: 'percent',
    isSortable: true,
    title: 'Conc. limit',
    align: 'left',
  },
  {
    field: 'lastActive',
    dbField: '"lastActive"',
    type: 'date',
    isSortable: true,
    title: 'Last active',
    alias: 'clientArSummarySubQuery',
    align: 'left',
  },

  {
    field: 'notesReason',
    dbField: 'notes_reason',
    type: 'text',
    isSortable: true,
    placeholder: 'Notes',
    title: 'Notes',
  },
  {
    type: 'quickFilter',
    field: 'quickFilter',
    quickFilters: [
      {
        title: 'New Customers',
        filters: {
          eligible: [null],
        },
      },
    ],
  },
]
export const CLIENT_AP_ELIGIBILITY_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'creditor',
    type: 'autocomplete',
    isSortable: true,
    placeholder: 'Vendor',
    title: 'Vendor',
    alias: 'creditorClient',
  },
  {
    field: 'type',
    type: 'list',
    title: 'Type',
    isSortable: true,
    placeholder: 'Type',
    options: VENDOR_TYPE_VALUES,
    alias: 'creditorClient',
  },
  {
    field: 'priority',
    type: 'empty',
    isSortable: false,
    placeholder: 'Priority',
    title: 'Priority',
  },

  {
    field: 'lastAmount',
    dbField: '"lastAmount"',
    type: 'amount',
    isSortable: true,
    title: 'Current amount',
    alias: 'clientApSummarySubQueryTotal',
    align: 'left',
  },

  {
    field: 'priorityPayable',
    dbField: 'priority_payable',
    type: 'list',
    isSortable: true,
    options: OVERPAYABLE_INTERVAL_VALUES,
    title: 'Aging threshold',
  },

  {
    field: 'lastActive',
    dbField: '"lastActive"',
    type: 'date',
    isSortable: true,
    title: 'Last active',
    align: 'left',
    alias: 'clientApSummarySubQuery',
  },

  {
    field: 'notesReason',
    dbField: 'notes',
    type: 'text',
    isSortable: true,
    placeholder: 'Notes',
    title: 'Notes',
  },
  {
    type: 'quickFilter',
    field: 'quickFilter',
    fields: [
      {
        field: 'isPriorityPayable',
        type: 'text',
      },
    ],
    quickFilters: [
      {
        title: 'Priority Vendors',
        isHidden: true,
        filters: {
          isPriorityPayable: 'true',
        },
      },
    ],
  },
]

export const ELIGIBILITY_NOTES_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'eligible',
    type: 'list',
    isSortable: true,
    options: [
      {
        value: 'all',
        label: 'All',
      },
      {
        value: true,
        label: 'Eligible',
      },
      {
        value: false,
        label: 'Ineligible',
      },
    ],
    title: 'Eligible',
    alias: 'masterInventory',
  },
  {
    field: 'notes',
    type: 'text',
    isSortable: true,
    placeholder: 'Notes',
    title: 'Notes',
    alias: 'masterInventory',
  },
]

export const CLIENT_MASTER_INVENTORY_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'recordDate',
    dbField: 'record_date',
    type: 'date',
    isSortable: true,
    placeholder: 'First Appeared',
    title: 'First Appeared',
    alias: 'borrowingBase',
  },
  {
    field: 'description',
    type: 'text',
    isSortable: true,
    placeholder: 'Description',
    title: 'Description',
    alias: 'masterInventory',
  },
  {
    field: 'sku',
    type: 'text',
    isSortable: true,
    placeholder: 'SKU',
    title: 'SKU',
    alias: 'masterInventory',
  },
  {
    field: 'type',
    type: 'list',
    dbField: 'type',
    isSortable: true,
    options: [
      {
        value: 'all',
        label: 'All',
      },
      {
        value: null,
        label: 'New',
      },
      {
        value: SkuTypes.Finished,
        label: SkuTypes.Finished,
      },
      {
        value: SkuTypes.InProcess,
        label: SkuTypes.InProcess,
      },
      {
        value: SkuTypes.Raw,
        label: SkuTypes.Raw,
      },
      {
        value: SkuTypes.Packaging,
        label: SkuTypes.Packaging,
      },
      {
        value: SkuTypes.Other,
        label: SkuTypes.Other,
      },
    ],
    title: 'Type',
    alias: 'masterInventory',
    isMultiple: true,
  },
  {
    field: 'brandCategory',
    type: 'text',
    isSortable: true,
    title: 'Category',
    align: 'left',
    dbField: 'brand_category',
    alias: 'masterInventory',
  },
  {
    field: 'lastAmount',
    dbField: '"lastAmount"',
    type: 'amount',
    isSortable: true,
    title: 'Current amount',
    align: 'left',
    alias: 'clientInventorySubQueryTotal',
  },
  {
    field: 'lastActive',
    dbField: '"lastActive"',
    type: 'date',
    isSortable: true,
    title: 'Last active',
    align: 'left',
    alias: 'clientInventorySubQuery',
  },
  ...ELIGIBILITY_NOTES_FILTERS_CONFIG,

  {
    type: 'quickFilter',
    field: 'quickFilter',
    quickFilters: [
      {
        title: 'New',
        filters: {
          type: [null],
        },
      },
      {
        title: 'Finished Goods',
        filters: {
          type: [SkuTypes.Finished],
        },
      },
      {
        title: 'Not Finished Goods',
        filters: {
          type: [null, SkuTypes.InProcess, SkuTypes.Raw, SkuTypes.Packaging, SkuTypes.Other],
        },
      },
    ],
  },
]

export const CLIENT_INVENTORY_ELIGIBILITY_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'label',
    dbField: 'label',
    type: 'text',
    isSortable: true,
    title: 'Label',
    align: 'left',
    alias: 'rules',
  },
  {
    field: 'field',
    type: 'text',
    isSortable: true,
    title: 'Column Name',
    align: 'left',
    alias: 'rules',
  },
  {
    field: 'ineligibleReason',
    type: 'text',
    isSortable: true,
    title: 'Reason',
    align: 'left',
    alias: 'rules',
  },
  {
    field: 'operator',
    type: 'text',
    isSortable: true,
    title: 'Operator',
    alias: 'rules',
  },
  {
    field: 'value',
    type: 'text',
    isSortable: true,
    title: 'Value',
    align: 'left',
    alias: 'rules',
  },
  {
    field: 'eligible',
    type: 'list',
    isSortable: true,
    align: 'left',
    options: [
      {
        value: 'all',
        label: 'All',
      },
      {
        value: true,
        label: 'Eligible',
      },
      {
        value: false,
        label: 'Ineligible',
      },
    ],
    title: 'Eligible',
    alias: 'rules',
  },
  {
    field: 'overrides',
    type: 'text',
    isSortable: true,
    title: 'Override',
    alias: 'rules',
  },
  {
    field: 'action',
    type: 'empty',
    title: 'Action',
    align: 'right',
  },
]

export const ENTITY_SETTINGS_AR_TABLE_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'client',
    dbField: 'client_name',
    type: 'text',
    isSortable: true,
    placeholder: 'Client',
    title: 'Client',
    alias: 'debtorClientEligibility',
  },
  {
    field: 'eligible',
    dbField: 'eligible',
    title: 'Eligible',
    type: 'list',
    isSortable: true,
    options: [
      {
        value: true,
        label: 'Eligible',
      },
      {
        value: false,
        label: 'Ineligible',
      },
    ],
  },
  {
    field: 'category',
    dbField: 'category',
    type: 'list',
    isSortable: true,
    options: [
      {
        value: 'all',
        label: 'All',
      },
      ...Object.keys(Category).map((category) => ({
        value: category,
        label: category,
      })),
    ],
    title: 'Status',
    alias: 'debtorClientEligibility',
  },
  {
    field: 'lastAmount',
    dbField: '"lastAmount"',
    type: 'amount',
    isSortable: true,
    title: 'Current amount',
    alias: 'clientArSummarySubQueryTotal',
    align: 'left',
  },

  {
    field: 'creditLimit',
    dbField: 'credit_limits',
    type: 'amount',
    isSortable: true,
    title: 'Credit limit',
    align: 'left',
  },

  {
    field: 'concentrationLimit',
    dbField: 'concentration_limit',
    type: 'percent',
    isSortable: true,
    title: 'Conc. limit',
    align: 'left',
  },
  {
    field: 'lastActive',
    dbField: '"lastActive"',
    type: 'date',
    isSortable: true,
    title: 'Last active',
    alias: 'clientArSummarySubQuery',
    align: 'left',
  },
]
export const ENTITY_SETTINGS_AP_TABLE_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'client',
    dbField: 'client_name',
    type: 'text',
    isSortable: true,
    placeholder: 'Client',
    title: 'Client',
    alias: 'creditorClient',
  },
  {
    field: 'type',
    type: 'list',
    title: 'Type',
    isSortable: true,
    placeholder: 'Type',
    options: VENDOR_TYPE_VALUES,
    alias: 'creditorClient',
  },
  {
    field: 'priority',
    type: 'empty',
    isSortable: false,
    placeholder: 'Priority',
    title: 'Priority',
  },

  {
    field: 'lastAmount',
    dbField: '"lastAmount"',
    type: 'amount',
    isSortable: true,
    title: 'Current amount',
    align: 'left',
  },

  {
    field: 'priorityPayable',
    dbField: 'priority_payable',
    type: 'list',
    isSortable: true,
    options: OVERPAYABLE_INTERVAL_VALUES,
    title: 'Aging threshold',
  },

  {
    field: 'lastActive',
    dbField: '"lastActive"',
    type: 'date',
    isSortable: true,
    title: 'Last active',
    align: 'left',
    alias: 'clientApSummarySubQuery',
  },
]

export const BBC_AR_INELIGIBILITY_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'debtor',
    type: 'autocomplete',
    isSortable: true,
    placeholder: 'Customer',
    title: 'Customer',
    alias: 'debtorClientEligibility',
  },
  {
    field: 'eligible',
    dbField: 'eligible',
    alias: 'debtorClientEligibility',
    type: 'list',
    isSortable: true,
    title: 'Eligible',
    options: [
      {
        value: 'true',
        label: 'Yes',
      },
      {
        value: 'false',
        label: 'No',
      },
    ],
  },
  {
    field: 'category',
    dbField: 'category',
    type: 'list',
    isSortable: true,
    options: [
      {
        value: 'all',
        label: 'All',
      },
      ...Object.keys(Category).map((category) => ({
        value: category,
        label: category,
      })),
    ],
    title: 'Category',
    alias: 'debtorClientEligibility',
  },
  {
    field: 'creditLimit',
    dbField: 'credit_limits',
    type: 'amount',
    isSortable: true,
    title: 'Credit Limit',
  },
  {
    field: 'concentrationLimit',
    dbField: 'concentration_limit',
    type: 'percent',
    isSortable: true,
    title: 'Concentr.Limit',
  },
  {
    field: 'totalIneligible',
    dbField: 'ineligible_amount',
    type: 'amount',
    isSortable: true,
    title: 'Total Ineligible',
    alias: 'arIneligibility',
  },
  {
    field: 'summaryTotal',
    dbField: 'total',
    type: 'amount',
    isSortable: true,
    title: 'Total AR',
    alias: 'summary',
  },
  {
    field: 'notesReason',
    dbField: 'notes_reason',
    alias: 'debtorClientEligibility',
    type: 'text',
    isSortable: true,
    placeholder: 'Notes',
    title: 'Notes',
  },

  {
    type: 'quickFilter',
    field: 'quickFilter',
    quickFilters: [
      {
        title: 'New Customers',
        filters: {
          eligible: [null],
        },
      },
    ],
  },
]
export const BBC_MASTER_INVENTORY_INELIGIBILITY_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'recordDate',
    dbField: 'record_date',
    type: 'date',
    isSortable: true,
    placeholder: 'First Appeared',
    title: 'First Appeared',
    alias: 'borrowingBase',
  },
  {
    field: 'description',
    type: 'text',
    isSortable: true,
    placeholder: 'Description',
    title: 'Description',
    alias: 'masterInventory',
  },
  {
    field: 'sku',
    type: 'text',
    isSortable: true,
    placeholder: 'SKU',
    title: 'SKU',
    alias: 'masterInventory',
  },
  {
    field: 'location',
    type: 'text',
    isSortable: true,
    placeholder: 'Location',
    title: 'Location',
  },
  {
    field: 'totalValue',
    type: 'amount',
    isSortable: true,
    isCustom: true,
    placeholder: 'SKU',
    title: 'Total Amount',
    excludeFullSearchFilter: true,
  },
  {
    field: 'type',
    type: 'list',
    dbField: 'type',
    isSortable: true,
    options: [
      {
        value: 'all',
        label: 'All',
      },
      {
        value: null,
        label: 'New',
      },
      {
        value: SkuTypes.Finished,
        label: SkuTypes.Finished,
      },
      {
        value: SkuTypes.InProcess,
        label: SkuTypes.InProcess,
      },
      {
        value: SkuTypes.Raw,
        label: SkuTypes.Raw,
      },
      {
        value: SkuTypes.Packaging,
        label: SkuTypes.Packaging,
      },
      {
        value: SkuTypes.Other,
        label: SkuTypes.Other,
      },
    ],
    title: 'Type',
    alias: 'masterInventory',
    isMultiple: true,
  },
  {
    field: 'brandCategory',
    type: 'text',
    isSortable: true,
    title: 'Category',
    align: 'left',
    dbField: 'brand_category',
    alias: 'masterInventory',
  },
  {
    field: 'eligible',
    type: 'list',
    isSortable: true,
    options: [
      {
        value: 'all',
        label: 'All',
      },
      {
        value: true,
        label: 'Eligible',
      },
      {
        value: false,
        label: 'Ineligible',
      },
    ],
    title: 'Eligible',
    alias: 'masterInventory',
  },
  {
    field: 'notes',
    type: 'text',
    isSortable: true,
    placeholder: 'Notes',
    title: 'Notes',
    alias: 'masterInventory',
  },
  {
    type: 'quickFilter',
    field: 'quickFilter',
    quickFilters: [
      {
        title: 'New',
        filters: {
          type: [null],
        },
      },
      {
        title: 'Finished Goods',
        filters: {
          type: [SkuTypes.Finished],
        },
      },
      {
        title: 'Not Finished Goods',
        filters: {
          type: [null, SkuTypes.InProcess, SkuTypes.Raw, SkuTypes.Packaging, SkuTypes.Other],
        },
      },
    ],
  },
]

export const LIST_INVENTORY_MAPPING_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'sku',
    type: 'text',
    isSortable: true,
    align: 'left',
    title: 'SKU',
  },
  {
    field: 'description',
    type: 'text',
    isSortable: true,
    align: 'left',
    title: 'Description',
  },
  {
    field: 'type',
    type: 'text',
    isSortable: true,
    title: 'Type',
    align: 'left',
  },
  {
    field: 'brandCategory',
    dbField: 'brand_category',
    type: 'text',
    isSortable: true,
    align: 'left',
    title: 'Category',
  },
  {
    field: 'industrySubcategory',
    dbField: 'industry_subcategory',
    type: 'text',
    isSortable: true,
    align: 'left',
    title: 'Sub category',
  },
  {
    field: 'season',
    type: 'text',
    isSortable: true,
    title: 'Season',
    align: 'left',
  },
  {
    field: 'size',
    type: 'text',
    isSortable: true,
    title: 'Size',
    align: 'left',
  },
  {
    field: 'flavor',
    type: 'text',
    isSortable: true,
    title: 'Flavor',
    align: 'left',
  },
  {
    field: 'style',
    type: 'text',
    isSortable: true,
    title: 'Style',
    align: 'left',
  },
  {
    field: 'color',
    type: 'text',
    isSortable: true,
    title: 'Color',
    align: 'left',
  },
]

const INVENTORY_DISTRIBUTION_FILTERS: IFilter[] = [
  {
    field: 'brandCategory',
    dbField: 'brand_category',
    type: 'autocomplete',
    title: 'Category',
    placeholder: 'Category',
    excludeColumn: true,
    isMultiple: true,
  },
  {
    field: 'industrySubcategory',
    dbField: 'industry_subcategory',
    type: 'autocomplete',
    title: 'Sub category',
    excludeColumn: true,
    isMultiple: true,
  },
  {
    field: 'color',
    type: 'autocomplete',
    title: 'Color',
    excludeColumn: true,
    isMultiple: true,
  },
  {
    field: 'flavor',
    type: 'autocomplete',
    title: 'Flavor',
    excludeColumn: true,
    isMultiple: true,
  },
  {
    field: 'size',
    type: 'autocomplete',
    title: 'Size',
    excludeColumn: true,
    isMultiple: true,
  },
  {
    field: 'style',
    type: 'autocomplete',
    title: 'Style',
    excludeColumn: true,
    isMultiple: true,
  },
  {
    field: 'season',
    type: 'autocomplete',
    title: 'Season',
    excludeColumn: true,
    isMultiple: true,
  },
  {
    field: 'type',
    type: 'autocomplete',
    title: 'Type',
    excludeColumn: true,
    isMultiple: true,
  },
  ...(['custom_1', 'custom_2', 'custom_3', 'custom_4', 'custom_5'].map((customField) => ({
    field: customField,
    type: 'autocomplete',
    title: customField.replace('_', ' ').replace('custom', 'Custom'),
    excludeColumn: true,
    isMultiple: true,
  })) as IFilter[]),
  {
    field: 'dwightCategory',
    dbField: 'dwight_category',
    type: 'autocomplete',
    title: 'Standard category',
    excludeColumn: true,
    isMultiple: true,
  },
  {
    field: 'dwightSubcategory',
    dbField: 'dwight_subcategory',
    type: 'autocomplete',
    title: 'Standard sub category',
    excludeColumn: true,
    isMultiple: true,
  },
  {
    field: 'dwightSize',
    dbField: 'dwight_size',
    type: 'autocomplete',
    title: 'Standard size',
    excludeColumn: true,
    isMultiple: true,
  },
  {
    field: 'dwightDepartment',
    dbField: 'dwight_department',
    type: 'autocomplete',
    title: 'Standard department',
    excludeColumn: true,
    isMultiple: true,
  },
  {
    field: 'department',
    dbField: 'department',
    type: 'autocomplete',
    title: 'Department',
    excludeColumn: true,
    isMultiple: true,
  },
]
export const SALES_DISTRIBUTION_BY_GRAPH_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'distribution',
    type: 'text',
    align: 'left',
    title: 'Distribution',
    isSortable: true,
    dbField: 'distribution',
  },
  ...INVENTORY_DISTRIBUTION_FILTERS,
]
export const SALES_BY_SKU_VISUALIZATION_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'distribution',
    type: 'text',
    align: 'left',
    title: 'Distribution',
    isSortable: true,
    dbField: 'distribution',
  },
  ...INVENTORY_DISTRIBUTION_FILTERS,
  {
    field: 'onHand',
    type: 'number',
    isSortable: true,
    align: 'right',
    title: 'Units On Hand',
    excludeFilter: true,
    isHighlighted: true,
  },
  {
    field: 'previousOnHandDifference',
    type: 'number',
    isSortable: true,
    align: 'right',
    title: 'Unit Change',
    excludeFilter: true,
    isHighlighted: true,
  },
  {
    field: 'totalValue',
    type: 'amount',
    isSortable: true,
    align: 'right',
    title: 'Total Inventory',
    excludeFilter: true,
    isHighlighted: true,
  },
  {
    field: 'previousTotalValueDifference',
    type: 'amount',
    isSortable: true,
    align: 'right',
    title: 'Value Change',
    excludeFilter: true,
    isHighlighted: true,
  },
  {
    field: 'totalInventoryPercent',
    type: 'percent',
    isSortable: true,
    align: 'right',
    title: '% of Inventory',
    excludeFilter: true,
    isHighlighted: true,
  },
  {
    field: 'unitSales',
    type: 'number',
    isSortable: true,
    title: 'Sales Units',
    align: 'right',
    excludeFilter: true,
    isHighlighted: true,
  },
  {
    field: 'totalSales',
    type: 'amount',
    isSortable: true,
    title: 'Sales',
    align: 'right',
    excludeFilter: true,
    isHighlighted: true,
  },
  {
    field: 'totalSalesPercent',
    type: 'percent',
    isSortable: true,
    title: '% of Sales',
    align: 'right',
    excludeFilter: true,
    isHighlighted: true,
  },
  {
    field: 'totalCOGS',
    type: 'amount',
    isSortable: true,
    title: 'COGS',
    align: 'right',
    excludeFilter: true,
    isHighlighted: true,
  },
  {
    field: 'weeklyCOGS',
    type: 'amount',
    isSortable: true,
    title: 'Weekly COGS',
    align: 'right',
    excludeFilter: true,
    isHighlighted: true,
  },
  {
    field: 'weeksOfSupply',
    type: 'number',
    title: 'WOS',
    isSortable: true,
    align: 'right',
    excludeFilter: true,
    isHighlighted: true,
  },
  {
    field: 'totalIneligible',
    type: 'amount',
    isSortable: true,
    title: 'Total Ineligible',
    align: 'right',
    excludeFilter: true,
    isHighlighted: true,
  },
  {
    field: 'monthsOfSales',
    type: 'number',
    title: 'Months of Sales',
    isSortable: true,
    align: 'right',
    excludeColumn: true,
    excludeFilter: true,
  },
  {
    field: 'monthsOfSalesCount',
    type: 'number',
    title: 'Months of Sales Count',
    isSortable: true,
    align: 'right',
    excludeColumn: true,
    excludeFilter: true,
  },

  {
    type: 'quickFilter',
    field: 'quickFilter',
    quickFilters: [
      {
        title: 'Finished Goods',
        filters: {
          type: [SkuTypes.Finished],
        },
      },
    ],
  },
]

export const BBC_INVENTORY_INELIGIBILITY_SHORT_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'value',
    type: 'text',
    isSortable: true,
    placeholder: 'Value',
    title: 'Value',
  },
  {
    field: 'totalValue',
    type: 'number',
    isSortable: true,
    placeholder: 'Total Value',
    title: 'Total Value',
    dbField: 'COALESCE(total_values.total_value, 0)',
  },
  {
    field: 'eligible',
    type: 'list',
    isSortable: true,
    options: [
      {
        value: 'all',
        label: 'All',
      },
      {
        value: true,
        label: 'Eligible',
      },
      {
        value: false,
        label: 'Ineligible',
      },
    ],
    title: 'Eligible',
  },
]

export const USER_MANAGEMENT_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'firstName',
    type: 'text',
    dbField: 'first_name',
    isSortable: true,
    title: 'First Name',
  },
  {
    field: 'lastName',
    type: 'text',
    dbField: 'last_name',
    isSortable: true,
    title: 'Last Name',
  },
  {
    field: 'email',
    dbField: 'email',
    type: 'text',
    isSortable: true,
    title: 'Email',
  },
  {
    field: 'status',
    dbField: 'status',
    type: 'list',
    title: 'Status',
    isSortable: true,
    options: [
      {
        value: UserStatus.New,
        label: 'Invitation Sent',
      },
      {
        value: UserStatus.Active,
        label: 'Active',
      },
    ],
  },
]

export const USER_MANAGEMENT_CLIENT_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'isAuthorizedSignature',
    dbField: 'is_authorized_signature',
    alias: 'clientInfoUsers',
    type: 'list',
    title: 'Authorized Signature',
    options: [
      {
        value: 'true',
        label: 'Authorized',
      },
      {
        value: 'false',
        label: 'Not authorized',
      },
    ],
  },
  {
    field: 'isReportingReminderReceiver',
    dbField: 'is_reporting_reminder_receiver',
    alias: 'clientInfoUsers',
    type: 'list',
    title: 'Reporting Reminders',
    options: [
      {
        value: 'true',
        label: 'Reporting Reminders receiver',
      },
      {
        value: 'false',
        label: 'Not Reporting Reminders receiver',
      },
    ],
  },
  {
    field: 'isViewer',
    dbField: 'is_viewer',
    alias: 'clientInfoUsers',
    type: 'list',
    title: 'BBC Viewer',
    options: [
      {
        value: 'true',
        label: 'BBC viewer',
      },
      {
        value: 'false',
        label: 'Not BBC viewer',
      },
    ],
  },
  {
    field: 'action',
    type: 'empty',
    title: 'Action',
  },
]

export const USER_MANAGEMENT_PARTICIPANT_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'companies',
    alias: 'clientInfoUsers',
    type: 'text',
    title: 'Clients',
    isSortable: false,
  },
  {
    field: 'action',
    type: 'empty',
    title: 'Action',
  },
]

export const BANK_ACCOUNT_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'bankName',
    type: 'text',
    dbField: 'bank_name',
    isSortable: true,
    title: 'Bank',
  },
  {
    field: 'accountHolder',
    type: 'text',
    dbField: 'account_holder_name',
    isSortable: true,
    title: 'Account Holder',
  },
  {
    field: 'bankAccount',
    dbField: 'bank_account_number',
    type: 'text',
    isSortable: true,
    title: 'Account Number',
    align: 'right',
  },
  {
    field: 'ABArouting',
    dbField: 'aba_routing_number',
    type: 'text',
    isSortable: true,
    title: 'ABA Routing',
    align: 'right',
  },
  {
    field: 'purpose',
    dbField: 'purpose',
    type: 'text',
    isSortable: true,
    title: 'Purpose',
  },
  {
    field: 'isExternal',
    dbField: 'is_external',
    type: 'list',
    title: 'Type',
    options: [
      { value: 'true', label: 'External' },
      { value: 'false', label: 'Internal' },
    ],
    isSortable: true,
  },
  {
    field: 'action',
    type: 'empty',
    title: 'Action',
  },
]

export const RELATIONSHIP_MANAGERS_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'firstName',
    type: 'text',
    dbField: 'first_name',
    isSortable: true,
    title: 'First Name',
  },
  {
    field: 'lastName',
    type: 'text',
    dbField: 'last_name',
    isSortable: true,
    title: 'Last Name',
  },
  {
    field: 'email',
    dbField: 'email',
    type: 'text',
    isSortable: true,
    title: 'Email',
  },
  {
    field: 'is_notified',
    dbField: 'is_notified',
    type: 'list',
    title: 'Status',
    isSortable: true,
    options: [
      {
        value: 'Notification Sent',
        label: 'Notification Sent',
      },
      {
        value: 'Send Notification',
        label: 'Send Notification',
      },
    ],
  },

  {
    field: 'action',
    type: 'empty',
    title: 'Action',
  },
]

export const CHECKS_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'checkNumber',
    type: 'text',
    dbField: 'check_number',
    isSortable: true,
    title: 'Check #',
  },
  {
    field: 'clientName',
    type: 'text',
    dbField: 'client_name',
    isSortable: true,
    title: 'Client',
    alias: 'clientInfo',
  },
  {
    field: 'debtor',
    dbField: 'name',
    type: 'text',
    isSortable: true,
    title: 'Customer',
    alias: 'entityInfo',
  },
  {
    field: 'originalEntity',
    dbField: 'original_entity',
    type: 'text',
    title: 'Primary Customer',
  },
  {
    field: 'paymentAmount',
    dbField: 'payment_amount',
    type: 'amount',
    title: 'Amount',
    align: 'right',
    isSortable: true,
  },
  {
    field: 'action',
    type: 'empty',
    title: 'Action',
  },
]

export const BBC_INVENTORY_INELIGIBILITY_LOCATION_SHORT_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'value',
    type: 'text',
    isSortable: true,
    placeholder: 'Value',
    title: 'Value',
  },
  {
    field: 'totalValue',
    type: 'number',
    isSortable: true,
    placeholder: 'Total Value',
    title: 'Total Value',
    dbField: 'COALESCE(total_values.total_value, 0)',
  },
  {
    field: 'eligible',
    type: 'list',
    isSortable: true,
    options: [
      {
        value: 'all',
        label: 'All',
      },
      {
        value: true,
        label: 'Eligible',
      },
      {
        value: false,
        label: 'Ineligible',
      },
    ],
    title: 'Eligible',
  },
  {
    field: 'mappedLocation',
    dbField: 'mapped_location',
    alias: 'inventoryLocation',
    type: 'text',
    isSortable: true,
    title: 'Alias',
  },
  {
    field: 'entityName',
    dbField: 'entity_name',
    alias: 'inventoryLocation',
    type: 'text',
    isSortable: true,
    title: 'Vendor',
  },
  {
    field: 'isCanadaMexicoLocation',
    dbField: 'is_canada_mexico_location',
    title: 'Cad/Mex',
    type: 'list',
    isSortable: true,
    options: [
      {
        value: 'all',
        label: 'All',
      },
      {
        value: true,
        label: 'Canada & Mexico',
      },
      {
        value: false,
        label: 'Domestic',
      },
    ],
  },
  {
    field: 'warehouseWaiverLink',
    dbField: 'warehouse_waiver_link',
    type: 'empty',
    title: 'Waiver',
    isSortable: true,
    alias: 'inventoryLocation',
  },
]
export const BBC_RECEIVABLES_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'debtor',
    dbField: 'memo',
    type: 'text',
    isSortable: true,
    placeholder: 'Description',
    title: 'Description',
  },
  {
    field: 'mapping',
    type: 'empty',
    title: 'Customer',
    align: 'left',
  },
  {
    field: 'prediction',
    type: 'empty',
    title: 'Prediction',
    align: 'left',
  },
  {
    field: 'invoice',
    type: 'text',
    isSortable: true,
    placeholder: 'Invoice #',
    title: 'Invoice #',
  },
  {
    field: 'balance',
    type: 'amount',
    isSortable: true,
    title: 'Balance',
  },
  {
    field: 'invoiceDate',
    type: 'date',
    isSortable: true,
    title: 'Invoice Date',
  },
  {
    field: 'daysPastInvoice',
    type: 'number',
    isSortable: true,
    title: 'Days Past Invoice',
  },
]
export const BBC_PAYABLES_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'creditor',
    dbField: 'memo',
    type: 'text',
    isSortable: true,
    placeholder: 'Description',
    title: 'Description',
  },
  {
    field: 'mapping',
    type: 'empty',
    title: 'Vendor',
    align: 'left',
  },
  {
    field: 'prediction',
    type: 'empty',
    title: 'Prediction',
    align: 'left',
  },
  {
    field: 'invoice',
    type: 'text',
    isSortable: true,
    placeholder: 'Invoice #',
    title: 'Invoice #',
  },
  {
    field: 'balance',
    type: 'amount',
    isSortable: true,
    title: 'Balance',
  },
  {
    field: 'invoiceDate',
    type: 'date',
    isSortable: true,
    title: 'Invoice Date',
  },
  {
    field: 'daysPastInvoice',
    type: 'number',
    isSortable: true,
    title: 'Days Past Invoice',
  },
]
export const BBC_INVENTORY_INELIGIBLE_ANALYSIS_SKU_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'description',
    type: 'text',
    isSortable: true,
    placeholder: 'Description',
    title: 'Description',
  },
  {
    field: 'sku',
    type: 'text',
    isSortable: true,
    placeholder: 'SKU',
    title: 'SKU',
  },
  {
    field: 'type',
    type: 'text',
    isSortable: true,
    placeholder: 'Type',
    title: 'Type',
  },
  {
    field: 'location',
    type: 'text',
    isSortable: true,
    placeholder: 'Location',
    title: 'Location',
  },
  {
    field: 'eligible',
    title: 'Eligibility',
    type: 'list',
    isSortable: true,
    options: [
      {
        value: 'all',
        label: 'All',
      },
      {
        value: true,
        label: 'Eligible',
      },
      {
        value: false,
        label: 'Ineligible',
      },
    ],
  },
  {
    field: 'weeksOfSupply',
    type: 'number',
    isSortable: true,
    title: 'Weeks of Supply',
  },
  {
    field: 'totalValue',
    type: 'amount',
    isSortable: true,
    title: 'Total Value',
  },
  {
    field: 'percent',
    type: 'percent',
    isSortable: true,
    title: '% of Total',
  },
]
export const BBC_INVENTORY_INELIGIBLE_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'label',
    type: 'empty',
    title: 'Category',
    placeholder: 'Category',
    isSortable: true,
    align: 'left',
  },
  {
    field: 'value_0',
    type: 'empty',
    isHighlighted: true,
    isSortable: true,
    align: 'right',
  },
  {
    field: 'value_1',
    type: 'empty',
    isHighlighted: true,
    isSortable: true,
    align: 'right',
  },
  {
    field: 'value_2',
    type: 'empty',
    isHighlighted: true,
    isSortable: true,
    align: 'right',
  },
  {
    field: 'value_3',
    type: 'empty',
    isHighlighted: true,
    isSortable: true,
    align: 'right',
  },
  {
    field: 'value_4',
    type: 'empty',
    isHighlighted: true,
    isSortable: true,
    align: 'right',
  },
]
export const BBC_INVENTORY_DISTRIBUTION_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'distribution',
    type: 'empty',
    title: 'Category',
    placeholder: 'Category',
    isSortable: true,
    align: 'left',
  },
  ...INVENTORY_DISTRIBUTION_FILTERS,
  {
    field: 'location',
    dbField: 'location',
    type: 'autocomplete',
    title: 'Location',
    excludeColumn: true,
    isMultiple: true,
  },
  {
    field: 'value_0',
    title: 'Total',
    type: 'amount',
    isHighlighted: true,
    isSortable: true,
    align: 'right',
    excludeFilter: true,
  },
  {
    field: 'percent_0',
    title: '% of Total',
    type: 'percent',
    isHighlighted: true,
    isSortable: true,
    align: 'right',
    excludeFilter: true,
  },

  {
    field: 'value_1',
    title: 'Total',
    type: 'amount',
    isHighlighted: true,
    isSortable: true,
    align: 'right',
    excludeFilter: true,
  },
  {
    field: 'percent_1',
    title: '% of Total',
    type: 'percent',
    isHighlighted: true,
    isSortable: true,
    align: 'right',
    excludeFilter: true,
  },
  {
    field: 'value_2',
    title: 'Total',
    type: 'amount',
    isHighlighted: true,
    isSortable: true,
    align: 'right',
    excludeFilter: true,
  },
  {
    field: 'percent_2',
    title: '% of Total',
    type: 'percent',
    isHighlighted: true,
    isSortable: true,
    align: 'right',
    excludeFilter: true,
  },
  {
    field: 'value_3',
    title: 'Total',
    type: 'amount',
    isHighlighted: true,
    isSortable: true,
    align: 'right',
    excludeFilter: true,
  },
  {
    field: 'percent_3',
    title: '% of Total',
    type: 'percent',
    isHighlighted: true,
    isSortable: true,
    align: 'right',
    excludeFilter: true,
  },
  {
    field: 'value_4',
    title: 'Total',
    type: 'amount',
    isHighlighted: true,
    isSortable: true,
    align: 'right',
    excludeFilter: true,
  },
  {
    field: 'percent_4',
    title: '% of Total',
    type: 'percent',
    isHighlighted: true,
    isSortable: true,
    align: 'right',
    excludeFilter: true,
  },
]
export const BBC_INVENTORY_INELIGIBLE_REASONS_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'reason',
    type: 'text',
    title: 'Reason',
    isSortable: true,
  },
  {
    field: 'value_0',
    type: 'empty',
    isHighlighted: true,
    isSortable: true,
    align: 'right',
  },
  {
    field: 'value_1',
    type: 'empty',
    isHighlighted: true,
    isSortable: true,
    align: 'right',
  },
  {
    field: 'value_2',
    type: 'empty',
    isHighlighted: true,
    isSortable: true,
    align: 'right',
  },
  {
    field: 'value_3',
    type: 'empty',
    isHighlighted: true,
    isSortable: true,
    align: 'right',
  },
  {
    field: 'value_4',
    type: 'empty',
    isHighlighted: true,
    isSortable: true,
    align: 'right',
  },
]

export const BBC_INVENTORY_INELIGIBLE_DETAILS_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'sku',
    type: 'text',
    title: 'SKU',
    isSortable: true,
  },

  {
    field: 'description',
    type: 'text',
    title: 'Description',
    placeholder: 'Description',
    isSortable: true,
  },

  {
    field: 'value_0',
    type: 'empty',
    isHighlighted: true,
    isSortable: true,
    align: 'right',
  },
  {
    field: 'value_1',
    type: 'empty',
    isHighlighted: true,
    isSortable: true,
    align: 'right',
  },
  {
    field: 'value_2',
    type: 'empty',
    isHighlighted: true,
    isSortable: true,
    align: 'right',
  },
  {
    field: 'value_3',
    type: 'empty',
    isHighlighted: true,
    isSortable: true,
    align: 'right',
  },
  {
    field: 'value_4',
    type: 'empty',
    isHighlighted: true,
    isSortable: true,
    align: 'right',
  },
]

export const BBC_INELIGIBLE_AR_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'label',
    type: 'autocomplete',
  },
  {
    field: 'debtor',
    type: 'text',
  },
  {
    field: 'invoice',
    type: 'text',
  },
  {
    field: 'value_0',
    type: 'amount',
  },
  {
    field: 'value_1',
    type: 'amount',
  },
  {
    field: 'value_2',
    type: 'amount',
  },
  {
    field: 'value_3',
    type: 'amount',
  },
  {
    field: 'value_4',
    type: 'amount',
  },
]
export const BBC_AR_SUMMARY_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'debtor',
    type: 'autocomplete',
    placeholder: 'Customer',
    title: 'Customer',
    isSortable: true,
    alias: 'current',
  },
  {
    field: 'invoice',
    type: 'text',
    title: 'Invoice',
    placeholder: 'Invoice',
    isSortable: true,
    alias: 'current',
    excludeFullSearchFilter: true,
  },
  {
    field: 'daysPastInvoice',
    type: 'number',
    title: 'Age',
    placeholder: 'Age',
    isSortable: true,
    alias: 'current',
  },
  {
    field: 'ar_1To_30Days',
    type: 'amount',
    isSortable: true,
    title: '1-30 Days',
    alias: 'current',
  },
  {
    field: 'ar_31To_60Days',
    type: 'amount',
    isSortable: true,
    title: '31-60 Days',
    alias: 'current',
  },
  {
    field: 'ar_61To_90Days',
    type: 'amount',
    isSortable: true,
    title: '61-90 Days',
    alias: 'current',
  },
  {
    field: 'ar_91PlusDays',
    type: 'amount',
    isSortable: true,
    title: '91+ Days',
    alias: 'current',
  },
  {
    field: 'ineligible',
    type: 'amount',
    isSortable: true,
    title: 'Total Ineligible',
    alias: 'current',
  },
  {
    field: 'eligible',
    type: 'amount',
    isSortable: true,
    title: 'Total Eligible',
    alias: 'current',
  },
  {
    field: 'total',
    type: 'amount',
    isSortable: true,
    title: 'Total AR',
    alias: 'current',
  },
  {
    field: 'percent',
    type: 'percent',
    isSortable: true,
    title: '% of Total',
    excludeFullSearchFilter: true,
    isCustom: true,
  },
  {
    title: 'AR Status',
    field: 'category',
    type: 'list',
    isMultiple: true,
    excludeColumn: true,
    alias: 'debtor_client_eligibility',
    options: [],
  },
  {
    type: 'quickFilter',
    field: 'quickFilter',
    quickFilters: [],
  },
]
export const BBC_AR_SUMMARY_DETAILS_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'debtor',
    type: 'text',
    title: 'Customer',
    placeholder: 'Customer',
    isSortable: true,
    alias: 'current',
    excludeFullSearchFilter: true,
  },
  {
    field: 'memo',
    type: 'text',
    title: 'Customer',
    placeholder: 'Customer',
    isSortable: true,
    alias: 'current',
    excludeFullSearchFilter: true,
  },
  {
    field: 'invoice',
    type: 'text',
    title: 'Invoice',
    placeholder: 'Invoice',
    isSortable: true,
    alias: 'current',
    excludeFullSearchFilter: true,
  },
  {
    field: 'daysPastInvoice',
    type: 'number',
    title: 'Age',
    placeholder: 'Age',
    isSortable: true,
    alias: 'current',
  },
  {
    field: 'ar_1To_30Days',
    type: 'amount',
    isSortable: true,
    title: '1-30 Days',
    alias: 'current',
  },
  {
    field: 'ar_31To_60Days',
    type: 'amount',
    isSortable: true,
    title: '31-60 Days',
    alias: 'current',
  },
  {
    field: 'ar_61To_90Days',
    type: 'amount',
    isSortable: true,
    title: '61-90 Days',
    alias: 'current',
  },
  {
    field: 'ar_91PlusDays',
    type: 'amount',
    isSortable: true,
    title: '91+ Days',
    alias: 'current',
  },
  {
    field: 'ineligible',
    type: 'amount',
    isSortable: true,
    title: 'Total Ineligible',
    alias: 'current',
  },
  {
    field: 'eligible',
    type: 'amount',
    isSortable: true,
    title: 'Total Eligible',
    alias: 'current',
  },
  {
    field: 'total',
    type: 'amount',
    isSortable: true,
    title: 'Total AR',
    alias: 'current',
  },
  {
    field: 'percent',
    type: 'percent',
    isSortable: true,
    title: '% of Total',
    excludeFullSearchFilter: true,
    isCustom: true,
  },
]
export const BBC_AP_SUMMARY_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'creditor',
    type: 'autocomplete',
    placeholder: 'Vendor',
    title: 'Vendor',
    isSortable: true,
    alias: 'current',
  },
  {
    field: 'invoice',
    type: 'text',
    title: 'Invoice',
    placeholder: 'Invoice',
    isSortable: true,
    alias: 'current',
    excludeFullSearchFilter: true,
  },
  {
    field: 'daysPastInvoice',
    type: 'number',
    title: 'Age',
    placeholder: 'Age',
    isSortable: true,
    alias: 'current',
  },
  {
    field: 'ap_1To_30Days',
    type: 'amount',
    isSortable: true,
    title: '1-30 Days',
    alias: 'current',
  },
  {
    field: 'ap_31To_60Days',
    type: 'amount',
    isSortable: true,
    title: '31-60 Days',
    alias: 'current',
  },
  {
    field: 'ap_61To_90Days',
    type: 'amount',
    isSortable: true,
    title: '61-90 Days',
    alias: 'current',
  },
  {
    field: 'ap_91PlusDays',
    type: 'amount',
    isSortable: true,
    title: '91+ Days',
    alias: 'current',
  },
  {
    field: 'total',
    type: 'amount',
    isSortable: true,
    title: 'Total AP',
    alias: 'current',
  },
  {
    field: 'percent',
    type: 'percent',
    isSortable: true,
    title: '% of Total',
    excludeFullSearchFilter: true,
    isCustom: true,
  },
  {
    type: 'quickFilter',
    field: 'quickFilter',
    fields: [
      {
        field: 'isPriorityPayable',
        type: 'text',
      },
    ],
    quickFilters: [
      {
        title: 'Priority Vendors',
        isHidden: true,
        filters: {
          isPriorityPayable: 'true',
        },
      },
    ],
  },
]
export const BBC_AP_SUMMARY_DETAILS_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'memo',
    type: 'text',
    title: 'Vendor',
    placeholder: 'Vendor',
    isSortable: true,
    alias: 'current',
    excludeFullSearchFilter: true,
  },

  {
    field: 'invoice',
    type: 'text',
    title: 'Invoice',
    isSortable: true,
    alias: 'current',
    excludeFullSearchFilter: true,
  },
  {
    field: 'daysPastInvoice',
    type: 'number',
    title: 'Age',
    isSortable: true,
    alias: 'current',
  },
  {
    field: 'ap_1To_30Days',
    type: 'amount',
    isSortable: true,
    title: '1-30 Days',
    alias: 'current',
  },
  {
    field: 'ap_31To_60Days',
    type: 'amount',
    isSortable: true,
    title: '31-60 Days',
    alias: 'current',
  },
  {
    field: 'ap_61To_90Days',
    type: 'amount',
    isSortable: true,
    title: '61-90 Days',
    alias: 'current',
  },
  {
    field: 'ap_91PlusDays',
    type: 'amount',
    isSortable: true,
    title: '91+ Days',
    alias: 'current',
  },
  {
    field: 'total',
    type: 'amount',
    isSortable: true,
    title: 'Total AP',
    alias: 'current',
  },
  {
    field: 'percent',
    type: 'percent',
    isSortable: true,
    title: '% of Total',
    excludeFullSearchFilter: true,
    isCustom: true,
  },
]

export const BBC_AP_ROLLFORWARD_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'label',
    type: 'text',
    title: 'Vendor',
    align: 'left',
  },
  {
    field: 'newBalance',
    type: 'amount',
    isSortable: true,
    title: 'Total AP',
    isHighlighted: true,
    highlightColor: 'primary',
  },
  {
    field: 'percent',
    type: 'percent',
    isSortable: true,
    title: '% of Total',
    isHighlighted: true,
    highlightColor: 'primary',
  },
  {
    field: 'newBills',
    type: 'amount',
    isSortable: true,
    title: 'New Bills',
    isHighlighted: true,
  },
  {
    field: 'impliedPayments',
    type: 'amount',
    isSortable: true,
    title: 'Implied Payments',
    isHighlighted: true,
  },
  {
    field: 'creditsApplied',
    type: 'amount',
    isSortable: true,
    title: 'Credits Applied',
    isHighlighted: true,
  },
  {
    field: 'newCredits',
    type: 'amount',
    isSortable: true,
    title: 'New Credits',
    isHighlighted: true,
  },
  {
    type: 'quickFilter',
    field: 'quickFilter',
    fields: [
      {
        field: 'isPriorityPayable',
        type: 'text',
      },
    ],
    quickFilters: [
      {
        title: 'Priority Vendors',
        isHidden: true,
        filters: {
          isPriorityPayable: 'true',
        },
      },
    ],
  },
]

export const BBC_AP_ROLLFORWARD_DETAIL_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'invoice',
    type: 'text',
    title: 'Invoice',
    align: 'left',
    isSortable: true,
    excludeFullSearchFilter: true,
  },
  {
    field: 'newBills',
    type: 'amount',
    isSortable: true,
    title: 'New Bills',
  },
  {
    field: 'invoiceDate',
    type: 'date',
    isSortable: true,
    title: 'Invoice Date',
  },
  {
    field: 'impliedPayments',
    type: 'amount',
    isSortable: true,
    title: 'Implied Payments',
  },
  {
    field: 'paidDate',
    type: 'date',
    isSortable: true,
    title: 'Paid Date',
  },
  {
    field: 'creditsApplied',
    type: 'amount',
    isSortable: true,
    title: 'Credits Applied',
  },
  {
    field: 'newCredits',
    type: 'amount',
    isSortable: true,
    title: 'New Credits',
  },
]

export const BBC_AR_ROLLFORWARD_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'label',
    type: 'text',
    title: 'Customer',
    align: 'left',
  },
  {
    field: 'newBalance',
    type: 'amount',
    isSortable: true,
    title: 'Total AR',
    isHighlighted: true,
    highlightColor: 'primary',
  },
  {
    field: 'percent',
    type: 'percent',
    isSortable: true,
    title: '% of Total',
    isHighlighted: true,
    highlightColor: 'primary',
  },
  {
    field: 'newInvoices',
    type: 'amount',
    isSortable: true,
    title: 'New Invoices',
    isHighlighted: true,
  },
  {
    field: 'impliedCollections',
    type: 'amount',
    isSortable: true,
    title: 'Implied Collections',
    isHighlighted: true,
  },
  {
    field: 'actualCollections',
    type: 'amount',
    isSortable: true,
    title: 'Actual Collections',
    isHighlighted: true,
  },
  {
    field: 'impliedReal',
    type: 'amount',
    isSortable: true,
    title: 'Implied - Real',
    isHighlighted: true,
  },
  {
    field: 'impliedDilution',
    type: 'percent',
    isSortable: true,
    title: 'Implied Dilution',
    isHighlighted: true,
  },
  {
    field: 'creditsApplied',
    type: 'amount',
    isSortable: true,
    title: 'Credits Applied',
    isHighlighted: true,
  },
  {
    field: 'newCredits',
    type: 'amount',
    isSortable: true,
    title: 'New Credits',
    isHighlighted: true,
  },
  {
    title: 'AR Status',
    field: 'category',
    type: 'list',
    isMultiple: true,
    excludeColumn: true,
    alias: 'debtor_client_eligibility',
    options: [],
  },
  {
    type: 'quickFilter',
    field: 'quickFilter',
    quickFilters: [],
  },
]

export const BBC_AR_ROLLFORWARD_DETAIL_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'debtor',
    type: 'text',
    title: 'Customer',
    align: 'left',
    isSortable: true,
    excludeFullSearchFilter: true,
  },
  {
    field: 'invoice',
    type: 'text',
    title: 'Invoice',
    align: 'left',
    isSortable: true,
    excludeFullSearchFilter: true,
  },
  {
    field: 'newInvoices',
    type: 'amount',
    isSortable: true,
    title: 'New Invoices',
  },
  {
    field: 'invoiceDate',
    type: 'date',
    isSortable: true,
    title: 'Invoice Date',
  },
  {
    field: 'impliedCollections',
    type: 'amount',
    isSortable: true,
    title: 'Implied Collections',
  },
  {
    field: 'paidDate',
    type: 'date',
    isSortable: true,
    title: 'Paid Date',
  },
  {
    field: 'actualCollections',
    type: 'amount',
    isSortable: true,
    title: 'Actual Collections',
  },
  {
    field: 'impliedReal',
    type: 'amount',
    isSortable: true,
    title: 'Implied - Real',
  },
  {
    field: 'impliedDilution',
    type: 'percent',
    isSortable: true,
    title: 'Implied Dilution',
  },
  {
    field: 'creditsApplied',
    type: 'amount',
    isSortable: true,
    title: 'Credits Applied',
  },
  {
    field: 'newCredits',
    type: 'amount',
    isSortable: true,
    title: 'New Credits',
  },
]

export const ALIAS_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'aka',
    dbField: 'aka',
    type: 'text',
    isSortable: true,
    placeholder: 'Description',
    title: 'Description',
  },
  {
    field: 'linkedName',
    type: 'empty',
    title: 'Customer',
    align: 'left',
  },
  {
    field: 'prediction',
    type: 'empty',
    title: 'Prediction',
    align: 'left',
  },
  {
    field: 'confidence',
    dbField: 'confidence',
    isSortable: true,
    type: 'empty',
    title: 'Confidence',
    align: 'left',
  },
  {
    field: 'totalAmount',
    dbField: 'totalAmount',
    type: 'amount',
    isSortable: true,
    title: 'Total Amount',
    excludeFullSearchFilter: true,
    excludeFilter: true,
  },
  {
    field: 'clientName',
    dbField: 'clientName',
    type: 'text',
    isSortable: true,
    placeholder: '',
    title: 'Client Name',
    excludeFilter: true,
  },

  {
    field: 'lastMappedDate',
    dbField: 'lastMappedDate',
    isSortable: true,
    type: 'date',
    title: 'Mapped Date',
    excludeFilter: true,
  },
]

export const ENTITY_REPORT_SUMMARY_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'clientName',
    type: 'autocomplete',
    placeholder: 'Client',
    title: 'Client',
    isSortable: true,
  },
  {
    field: '_1To_30Days',
    type: 'amount',
    isSortable: true,
    title: '1-30 Days',
  },
  {
    field: '_31To_60Days',
    type: 'amount',
    isSortable: true,
    title: '31-60 Days',
  },
  {
    field: '_61To_90Days',
    type: 'amount',
    isSortable: true,
    title: '61-90 Days',
  },
  {
    field: '_91PlusDays',
    type: 'amount',
    isSortable: true,
    title: '91+ Days',
  },
  {
    field: '_91PlusDaysPercent',
    dbField: '_91PlusDays',
    type: 'percent',
    isSortable: true,
    title: '% 91+ Days',
  },
  {
    field: 'ineligible',
    type: 'amount',
    isSortable: true,
    title: 'Total Ineligible',
  },
  {
    field: 'averageTerms',
    type: 'number',
    isSortable: true,
    title: 'Average Terms',
  },
  {
    field: 'dso',
    type: 'number',
    isSortable: true,
    title: 'Days Sales Outstanding',
  },
  {
    field: 'total',
    type: 'amount',
    isSortable: true,
    title: 'Total',
  },
  {
    field: 'percent',
    type: 'percent',
    isSortable: true,
    title: '% of Total',
    excludeFullSearchFilter: true,
    isCustom: true,
  },
  {
    field: 'totalExposure',
    type: 'amount',
    isSortable: true,
    title: 'Total Exposure',
  },
  {
    title: 'AR Status',
    field: 'category',
    type: 'list',
    isMultiple: true,
    excludeColumn: true,
    alias: 'debtor_client_eligibility',
    options: [],
  },
]

export const ENTITY_REPORT_DETAILS_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'invoice',
    type: 'autocomplete',
    title: 'Invoice',
    placeholder: 'Invoice',
    isSortable: true,
  },
  {
    field: 'daysPastInvoice',
    type: 'number',
    title: 'Age',
    placeholder: 'Age',
    isSortable: true,
  },
  {
    field: '_1To_30Days',
    type: 'amount',
    isSortable: true,
    title: '1-30 Days',
  },
  {
    field: '_31To_60Days',
    type: 'amount',
    isSortable: true,
    title: '31-60 Days',
  },
  {
    field: '_61To_90Days',
    type: 'amount',
    isSortable: true,
    title: '61-90 Days',
  },
  {
    field: '_91PlusDays',
    type: 'amount',
    isSortable: true,
    title: '91+ Days',
  },
  {
    field: 'total',
    type: 'amount',
    isSortable: true,
    title: 'Total',
  },
  {
    field: 'percent',
    type: 'percent',
    isSortable: true,
    title: '% of Total',
    excludeFullSearchFilter: true,
    isCustom: true,
  },
]

export const ENTITY_DILUTION_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'clientName',
    type: 'autocomplete',
    placeholder: 'Client',
    title: 'Client',
    isSortable: true,
  },

  {
    field: 'priorAr',
    type: 'amount',
    isSortable: true,
    title: 'Prior AR',
  },
  {
    field: 'totalAr',
    type: 'amount',
    isSortable: true,
    title: 'Current AR',
  },
  {
    field: 'newInvoices',
    type: 'amount',
    isSortable: true,
    title: 'New Invoices',
  },
  {
    field: 'impliedCollections',
    type: 'amount',
    isSortable: true,
    title: 'Implied Collections',
  },
  {
    field: 'totalCollections',
    type: 'number',
    isSortable: true,
    title: 'Actual Collections',
  },
  {
    field: 'dilution',
    dbField: 'dilution',
    type: 'number',
    isSortable: true,
    title: 'Dilution',
  },
  {
    title: 'AR Status',
    field: 'category',
    type: 'list',
    isMultiple: true,
    excludeColumn: true,
    alias: 'debtor_client_eligibility',
    options: [],
  },
]

export const CUSTOMER_SUMMARY_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'name',
    dbField: 'name',
    type: 'autocomplete',
    placeholder: 'Customer',
    title: 'Customer',
    isSortable: true,
  },
  {
    field: 'totalExposure',
    type: 'amount',
    isSortable: true,
    title: 'Total Exposure',
  },
  {
    field: 'totalAr',
    type: 'amount',
    isSortable: true,
    title: 'Total AR',
  },
  {
    field: 'totalCollections',
    type: 'amount',
    isSortable: true,
    title: 'Collections (L3M)',
  },
  {
    field: 'dilution',
    dbField: 'dilution',
    type: 'percent',
    isSortable: true,
    title: 'Dilution (L3M)',
  },
  {
    field: 'dso',
    dbField: 'dso',
    type: 'number',
    isSortable: true,
    title: 'Avg DSO (L3M)',
  },
  {
    field: 'totalClients',
    dbField: 'totalClientsAr',
    type: 'number',
    isSortable: true,
    title: 'Total clients',
  },
]

export const VENDOR_SUMMARY_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'name',
    dbField: 'name',
    type: 'autocomplete',
    placeholder: 'Vendor',
    title: 'Vendor',
    isSortable: true,
  },
  {
    field: 'type',
    dbField: 'entityType',
    type: 'empty',
    placeholder: 'Type',
    title: 'Type',
    isSortable: false,
  },
  {
    field: 'industries',
    dbField: 'industry',
    type: 'text',
    placeholder: 'Industries',
    title: 'Industries',
    isSortable: false,
  },
  {
    field: 'totalAp',
    type: 'amount',
    isSortable: true,
    title: 'Total AP',
  },
  {
    field: 'totalClients',
    dbField: 'totalClientsAp',
    type: 'number',
    isSortable: true,
    title: 'Total clients',
  },
]

export const INVESTOR_SUMMARY_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'linked_name',
    alias: 'alias',
    type: 'text',
    placeholder: 'Investor',
    title: 'Investor',
    isSortable: true,
  },
  {
    field: 'totalInvestment',
    dbField: 'SUM(investorDetails.outstanding_shares * investorDetails.price_per_share)',
    aggColumn: true,
    type: 'amount',
    placeholder: 'Total Investment',
    title: 'Total Investment ($)',
    isSortable: true,
  },
  {
    field: 'averageInvestment',
    dbField:
      'SUM(investorDetails.outstanding_shares * investorDetails.price_per_share) / COUNT(DISTINCT investorDetails.cap_table_summary_id)',
    aggColumn: true,
    type: 'amount',
    placeholder: 'Avg. Investment',
    title: 'Avg. Investment ($)',
    isSortable: true,
  },
  {
    field: 'avgOutstandingOwnership',
    dbField: 'AVG(sub."outstandingOwnership")',
    aggColumn: true,
    type: 'percent',
    placeholder: 'Avg. Outstanding Ownership (%)',
    title: 'Avg. Outstanding Ownership (%)',
    isSortable: true,
  },
  {
    field: 'avgFullyDilutedOwnership',
    dbField: 'AVG(sub."fullyDilutedOwnership")',
    aggColumn: true,
    type: 'percent',
    placeholder: 'Avg. Diluted Ownership (%)',
    title: 'Avg. Diluted Ownership (%)',
    isSortable: true,
  },
  {
    field: 'totalClients',
    dbField: 'COUNT(DISTINCT investorDetails.cap_table_summary_id)',
    aggColumn: true,
    type: 'number',
    placeholder: 'Total Clients',
    title: 'Total Clients',
    isSortable: true,
  },
  {
    field: 'totalBoardSeats',
    dbField: 'COALESCE(MAX("boardSeats"."totalBoardSeats"), 0)',
    aggColumn: true,
    type: 'number',
    placeholder: 'Total Board Seats',
    title: 'Total Board Seats',
    isSortable: true,
  },
]

export const INVESTOR_ENTITY_SUMMARY_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'clientName',
    alias: 'capTableSummary',
    dbField: 'client_name',
    type: 'text',
    placeholder: 'Client',
    title: 'Client',
    isSortable: true,
  },
  {
    field: 'totalInvestment',
    dbField: 'SUM(capTableDetails.outstandingShares * capTableDetails.pricePerShare)',
    aggColumn: true,
    type: 'amount',
    placeholder: 'Total Investment',
    title: 'Total Investment',
    isSortable: true,
  },
  {
    field: 'outstandingOwnership',
    type: 'percent',
    title: 'Outstanding Ownership (%)',
    isSortable: true,
    align: 'right',
    aggColumn: true,
    dbField: OUTSTANDING_OWNERSHIP_QUERY,
  },
  {
    field: 'dilutedSharesPercent',
    type: 'percent',
    title: 'Diluted Ownership (%)',
    isSortable: true,
    align: 'right',
    aggColumn: true,
    dbField: FULLY_DILUTED_OWNERSHIP_QUERY,
  },
  {
    field: 'boardSeats',
    type: 'number',
    title: 'Board Seats',
    isSortable: true,
    align: 'right',
    aggColumn: true,
    dbField: 'COALESCE(MAX("boardSeats"."totalBoardSeats"), 0)',
  },
  {
    field: 'boardSeatsHolders',
    type: 'text',
    title: 'Board Seats Holder(s)',
    isSortable: false,
    excludeFilter: true,
    excludeFullSearchFilter: true,
    align: 'left',
  },
  {
    type: 'quickFilter',
    field: 'quickFilter',
    quickFilters: [
      {
        title: '> 5% Ownership',
        filters: {
          dilutedSharesPercentMin: 5,
        },
      },
    ],
  },
]

export const NEWS_FEED_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'headline',
    alias: 'newsfeedHeadlines',
    type: 'text',
    isSortable: false,
    excludeColumn: true,
    title: 'Headline',
  },
  {
    field: 'headlineSummary',
    dbField: 'headline_summary',
    alias: 'newsfeedHeadlines',
    type: 'text',
    isSortable: false,
    excludeColumn: true,
    title: 'Headline Summary',
  },
  {
    field: 'brandIndustry',
    dbField: 'industry',
    alias: 'brandFromSalesforce',
    type: 'list',
    isSortable: false,
    isMultiple: true,
    title: 'Brand Industry',
    options: Object.values(BrandIndustries).map((industry) => ({
      value: industry,
      label: industry,
    })),
  },
  {
    field: 'industry',
    dbField: 'industry',
    alias: 'newsfeedHeadlines',
    type: 'list',
    isSortable: false,
    isMultiple: true,
    excludeColumn: true,
    title: 'Industry',
    options: Object.values(BrandIndustries).map((industry) => ({
      value: industry,
      label: industry,
    })),
  },
  {
    field: 'type',
    alias: 'newsfeedHeadlines',
    type: 'list',
    placeholder: 'Type',
    title: 'Type',
    isMultiple: true,
    options: Object.values(NewsTags).map((tag) => ({
      value: tag,
      label: tag,
    })),
  },
  {
    field: 'newsletterSection',
    dbField: 'newsletter_section',
    alias: 'newsfeedHeadlines',
    type: 'list',
    placeholder: 'Section',
    title: 'Section',
    isMultiple: true,
    options: Object.values(NewsletterSections).map((section) => ({
      value: section,
      label: section,
    })),
  },
  {
    field: 'processedAt',
    dbField: 'processed_at',
    type: 'date',
    placeholder: 'Date Range',
    title: 'Date Range',
    alias: 'newsfeedHeadlines',
  },
  {
    field: 'email',
    alias: 'newsfeedEmail',
    type: 'list',
    placeholder: 'Source',
    title: 'Source',
    isMultiple: true,
    options: Object.entries(EMAIL_NEWSLETTER_MAPPING).map(([key, value]) => ({
      value: key,
      label: value,
    })),
  },
  {
    field: 'brand',
    dbField: 'brand_name',
    alias: 'brandFromSalesforce',
    type: 'autocomplete',
    title: 'Brand',
  },
  {
    field: 'referral_source',
    dbField: 'brand_name',
    alias: 'linkedAccountBrandInfo',
    type: 'autocomplete',
    title: 'Other Accounts',
  },
  {
    field: 'sharedWithClients',
    placeholder: 'Shared with Clients',
    title: 'Shared with Clients',
    dbField: `CASE 
              WHEN clientNewsfeedHeadlines.id IS NOT NULL 
      AND clientNewsfeedHeadlines.display_until >= CURRENT_DATE
    THEN true 
              ELSE false 
              END`,
    filterTitle: 'Shared with Clients',
    type: 'list',
    options: [
      {
        value: 'true',
        label: 'Yes',
        title: 'Shared with Clients',
      },
      {
        value: 'false',
        label: 'No',
        title: 'Not Shared with Clients',
      },
    ],
  },
  {
    type: 'quickFilter',
    field: 'quickFilter',
    quickFilters: [
      {
        title: 'Last 3 days',
        filters: {
          processedAtFrom: moment().subtract(3, 'days').format('YYYY-MM-DD'),
        },
      },
      {
        title: 'Shared with Clients',
        filters: {
          sharedWithClients: true,
        },
      },
      {
        title: 'Newsletter',
        filters: {
          newsletterSection: [
            NewsletterSections.RecentlyInDistributionNews,
            NewsletterSections.RecentlyInFundraiseNews,
          ],
        },
      },
    ],
  },
  {
    field: 'amount',
    type: 'amount',
    title: 'Amount',
    excludeFilter: true,
  },
  {
    field: 'round',
    type: 'text',
    title: 'Rmount',
    excludeFilter: true,
  },
]

export const ENTITY_ACTIVITY_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'recordDate',
    type: 'date',
    isSortable: true,
    title: 'Date',
  },
  {
    field: 'clientName',
    type: 'autocomplete',
    isSortable: true,
    placeholder: 'Client',
    title: 'Client',
  },
  {
    field: 'type',
    type: 'list',
    options: [
      {
        value: '',
        label: 'All',
      },
      {
        value: 'New Invoice',
        label: 'New Invoice',
      },
      {
        value: 'Implied Collection',
        label: 'Implied Collection',
      },
      {
        value: 'Collection - Check',
        label: 'Collection - Check',
      },
      {
        value: 'Collection - Wire',
        label: 'Collection - Wire',
      },
    ],
    title: 'Type',
  },
  {
    field: 'invoice',
    type: 'text',
    isSortable: true,
    title: 'Invoice',
  },
  {
    field: 'amount',
    type: 'amount',
    title: 'Amount',
    isSortable: true,
    align: 'right',
  },
  {
    type: 'quickFilter',
    field: 'quickFilter',
    quickFilters: [
      {
        title: 'Today',
        filters: {
          recordDateFrom: moment().format('YYYY-MM-DD'),
          recordDateTo: moment().format('YYYY-MM-DD'),
        },
      },
    ],
  },
]

export const BBC_DETAIL_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'label',
    dbField: 'label',
    type: 'text',
    isSortable: true,
    placeholder: 'Description',
    title: 'Description',
  },
  {
    field: 'reference',
    dbField: 'reference',
    type: 'text',
    isSortable: true,
    placeholder: 'Reference',
    title: 'Reference',
  },
  {
    field: 'originalDate',
    dbField: 'originalDate',
    type: 'date',
    align: 'right',
    title: 'Original BBC date',
    isSortable: true,
  },
  {
    field: 'oldValue',
    dbField: 'oldValue',
    type: 'text',
    title: 'Old value',
    isSortable: true,
    align: 'left',
    excludeFilter: true,
  },
  {
    field: 'newValue',
    dbField: 'newValue',
    type: 'text',
    title: 'New value',
    isSortable: true,
    align: 'left',
    excludeFilter: true,
  },
  {
    field: 'impactToAvailability',
    dbField: 'impact_to_availability',
    type: 'amount',
    title: 'Availability impact',
    isSortable: true,
    align: 'right',
  },
]

export const BBC_DETAIL_DUPLICATE_INVOICES_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'invoice',
    type: 'text',
    isSortable: true,
    placeholder: 'Invoice',
    title: 'Invoice',
    align: 'left',
  },
  {
    field: 'count',
    type: 'number',
    dbField: 'COUNT(*)',
    isSortable: true,
    title: 'Count',
    align: 'right',
    excludeFullSearchFilter: true,
    aggColumn: true,
  },
  {
    field: 'customer',
    dbField: `STRING_AGG(distinct "alias"."linked_name", ', ')`,
    type: 'text',
    align: 'left',
    title: 'Customer',
    isSortable: false,
    aggColumn: true,
  },
]

export const BBC_DETAIL_CONFIRMED_PRIORITY_INVOICE_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'invoice',
    type: 'text',
    isSortable: true,
    placeholder: 'Invoice',
    title: 'Invoice',
    align: 'left',
  },
  {
    field: 'balance',
    type: 'amount',
    isSortable: true,
    title: 'Amount',
    align: 'right',
  },
  {
    field: 'creditor',
    dbField: `STRING_AGG(distinct "alias"."linked_name", ', ')`,
    type: 'text',
    isSortable: true,
    align: 'left',
    title: '3PL',
    aggColumn: true,
  },
]

export const BANK_TRANSACTIONS_PENDING_CASH_DOMINION_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'description',
    type: 'text',
    isSortable: true,
    title: 'Description',
  },
  {
    field: 'amount',
    type: 'amount',
    isSortable: true,
    title: 'Amount',
  },
  {
    field: 'recordDate',
    dbField: 'record_date',
    type: 'date',
    isSortable: true,
    title: 'Date',
  },
]

export const BBC_DETAIL_ALTERED_DATE_FILTERS_CONFIG: IFilter[] = [
  ...BBC_DETAIL_LIST_FILTERS_CONFIG,
  {
    field: 'message',
    type: 'text',
    isSortable: true,
    align: 'left',
    title: 'Message',
  },
]

export const NOTES_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'noteText',
    dbField: 'note_text',
    type: 'text',
    isSortable: true,
    placeholder: 'Note',
    title: 'Note',
  },
  {
    field: 'resolved',
    dbField: 'resolved',
    type: 'text',
    isSortable: true,
    title: 'Resolved',
  },
  {
    field: 'createdAt',
    dbField: 'created_at',
    type: 'date',
    isSortable: true,
    title: 'Created At',
  },
  {
    field: 'updatedAt',
    dbField: 'updated_at',
    type: 'date',
    isSortable: true,
    title: 'Updated At',
  },
  {
    field: 'id',
    type: 'list',
    excludeColumn: true,
  },
]

export const DEFAULT_NOTES_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'noteText',
    dbField: 'note_text',
    type: 'text',
    title: 'Note',
  },
  {
    field: 'tag',
    alias: 'tags',
    type: 'list',
    title: 'Tag',
    isMultiple: true,
  },
]

export const FLAGS_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'flag',
    dbField: 'flag',
    type: 'text',
    isSortable: true,
    placeholder: 'Flag',
    title: 'Flag',
  },
  {
    field: 'clientName',
    dbField: 'client_name',
    type: 'autocomplete',
    title: 'Client',
    alias: 'flags',
  },
  {
    field: 'name',
    dbField: 'name',
    type: 'autocomplete',
    title: 'Entity',
    alias: 'entityInfo',
  },
  {
    field: 'recordDate',
    dbField: 'record_date',
    type: 'date',
    isSortable: true,
    title: 'Date Range',
    alias: 'flags',
  },
  {
    field: 'flagType',
    dbField: 'flag_type',
    type: 'list',
    isSortable: true,
    options: Object.keys(FLAG_TYPE_LABELS).map((key) => ({
      value: key,
      label: FLAG_TYPE_LABELS[key],
    })),
    title: 'Type',
    isMultiple: true,
  },
  {
    field: 'resolved',
    dbField: 'resolved',
    type: 'list',
    options: [
      {
        value: 'false',
        label: 'Open',
      },
      {
        value: 'true',
        label: 'Resolved',
      },
    ],
    isSortable: true,
    title: 'Status',
    alias: 'flags',
  },
  {
    field: 'flagName',
    dbField: 'flag_name',
    type: 'autocomplete',
    title: 'Flag Name',
    alias: 'flags',
  },
  {
    field: 'flagColor',
    dbField: 'flag_color',
    type: 'list',
    isSortable: true,
    options: [
      {
        value: FlagColors.Red,
        label: 'Red',
      },
      {
        value: FlagColors.Yellow,
        label: 'Yellow',
      },
    ],
    title: 'Color',
  },
  {
    field: 'hasReplies',
    dbField: 'has_replies',
    type: 'list',
    options: [
      {
        value: 'true',
        label: 'Yes',
      },
      {
        value: 'false',
        label: 'No',
      },
    ],
    isSortable: true,
    title: 'Replies',
    alias: 'flags',
  },
  {
    type: 'quickFilter',
    field: 'quickFilter',
    quickFilters: [
      {
        title: 'Red Flags',
        filters: {
          flagColor: [FlagColors.Red],
          resolved: ['false'],
        },
      },
      {
        title: 'New Flags',
        filters: {
          recordDateFrom: moment().subtract(14, 'days').format('YYYY-MM-DD'),
        },
      },
    ],
  },
]

export const WIRE_MAPPING_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'recordDate',
    dbField: 'record_date',
    type: 'date',
    isSortable: true,
    title: 'Date',
    align: 'left',
  },
  {
    field: 'originalMemo',
    dbField: 'original_memo',
    type: 'text',
    isSortable: true,
    title: 'Memo',
    alias: 'wires',
  },
  {
    field: 'clientName',
    dbField: 'client_name',
    type: 'autocomplete',
    isSortable: true,
    title: 'Client',
    alias: 'wires',
  },
  {
    field: 'debtor',
    dbField: 'name',
    type: 'autocomplete',
    isSortable: true,
    title: 'Customer',
    alias: 'entityInfo',
  },
  {
    field: 'prediction',
    type: 'text',
    isSortable: true,
    title: 'Prediction',
  },
  {
    field: 'accountNumber',
    dbField: 'account_number',
    type: 'text',
    isSortable: true,
    title: 'Account number',
    alias: 'wires',
  },
  {
    field: 'amount',
    dbField: 'payment_amount',
    type: 'amount',
    isSortable: true,
    title: 'Amount',
    alias: 'wires',
  },
  {
    field: 'action',
    type: 'empty',
    title: 'Action',
  },
]

export const AR_INELIGIBLE_STATS_LABELS = {
  over_90DaysAndCreditsPastDue: 'Past Due & Credits',
  crossAge: 'Cross Age',
  ineligibleDebtors: 'Ineligible Customers',
  contras: 'Contras',
  concentration: 'Concentration',
  creditLimits: 'Credit Limits',
  arOtherMiscReserves: 'Other',
}

export const AR_INELIGIBLE_STATS_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'label',
    type: 'list',
    isMultiple: true,
    options: Object.keys(AR_INELIGIBLE_STATS_LABELS).map((label) => ({
      value: label,
      label: AR_INELIGIBLE_STATS_LABELS[label] || label,
    })),
    placeholder: 'Reason',
    title: 'Reason',
    isSortable: true,
  },
  {
    field: 'value_0',
    type: 'empty',
    isSortable: true,
    title: 'Current BBC',
    align: 'right',
  },
  {
    field: 'value_1',
    type: 'empty',
    isSortable: true,
    title: '',
    align: 'right',
  },
  {
    field: 'value_2',
    type: 'empty',
    isSortable: true,
    title: '',
    align: 'right',
  },
  {
    field: 'value_3',
    type: 'empty',
    isSortable: true,
    title: '',
    align: 'right',
  },
  {
    field: 'value_4',
    type: 'empty',
    isSortable: true,
    title: '',
    align: 'right',
  },
]

export const AR_INELIGIBLE_STATS_DETAILS_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'debtor',
    type: 'text',
    title: 'Customer',
    isSortable: true,
  },
  {
    field: 'value_0',
    type: 'empty',
    isSortable: true,
    title: 'Current BBC',
    align: 'right',
  },
  {
    field: 'value_1',
    type: 'empty',
    isSortable: true,
    title: '',
    align: 'right',
  },
  {
    field: 'value_2',
    type: 'empty',
    isSortable: true,
    title: '',
    align: 'right',
  },
  {
    field: 'value_3',
    type: 'empty',
    isSortable: true,
    title: '',
    align: 'right',
  },
  {
    field: 'value_4',
    type: 'empty',
    isSortable: true,
    title: '',
    align: 'right',
  },
]

export const AR_INELIGIBLE_STATS_DETAILS_INVOICES_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'invoice',
    type: 'text',
    title: 'Invoice',
    isSortable: true,
    excludeFullSearchFilter: true,
  },
  {
    field: 'value_0',
    type: 'empty',
    isSortable: true,
    title: 'Current BBC',
  },
  {
    field: 'value_1',
    type: 'empty',
    isSortable: true,
    title: '',
  },
  {
    field: 'value_2',
    type: 'empty',
    isSortable: true,
    title: '',
  },
  {
    field: 'value_3',
    type: 'empty',
    isSortable: true,
    title: '',
  },
  {
    field: 'value_4',
    type: 'empty',
    isSortable: true,
    title: '',
  },
]

export const NOTIFICATIONS_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'user',
    type: 'autocomplete',
    dbField: 'user_id',
    alias: 'notification',
    title: 'User',
    placeholder: 'User',
    isCustom: true,
  },
  {
    field: 'first_name',
    type: 'text',
    dbField: 'first_name',
    alias: 'user',
  },
  {
    field: 'last_name',
    type: 'text',
    dbField: 'last_name',
    alias: 'user',
  },
  {
    field: 'clientName',
    type: 'autocomplete',
    dbField: 'client_name',
    alias: 'notification',
    title: 'Client',
    placeholder: 'Client',
  },
  {
    field: 'createdAt',
    type: 'datetime',
    dbField: 'created_at',
    alias: 'notification',
    title: 'Date',
  },
  {
    field: 'notificationText',
    type: 'text',
    dbField: 'notification_text',
    alias: 'notification',
    title: 'Text',
    excludeColumn: true,
    isRawText: true,
  },
  {
    field: 'activityType',
    type: 'list',
    dbField: 'type',
    isMultiple: true,
    alias: 'activity',
    options: Object.values(ActivityType).map((value) => ({
      value,
      label: value,
    })),
    title: 'Activity',
  },
  {
    field: 'isRead',
    dbField: 'is_read',
    type: 'list',
    excludeColumn: true,
    options: [
      {
        value: 'true',
        label: 'Read',
      },
      {
        value: 'false',
        label: 'Unread',
      },
    ],
    title: 'Read',
  },
  {
    type: 'quickFilter',
    field: 'quickFilter',
    fields: [
      {
        field: 'clientStatus',
        type: 'list',
      },
    ],
    quickFilters: [
      {
        title: 'Fundings',
        filters: {
          activityType: [ActivityType.Funding],
        },
      },
      {
        title: 'Prospects',
        isHidden: true,
        filters: {
          clientStatus: [ClientInfoStatus.Prospect],
        },
      },
    ],
  },
]

export const DUE_DILIGENCE_TEAM_CONTACTS_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'fullName',
    type: 'text',
    isSortable: true,
    title: 'Full name',
  },
  {
    field: 'phone',
    type: 'text',
    isSortable: true,
    title: 'Phone number',
  },
  {
    field: 'email',
    type: 'text',
    isSortable: true,
    title: 'Email',
  },
  {
    field: 'action',
    type: 'empty',
    title: 'Action',
    align: 'right',
  },
]

export const DUE_DILIGENCE_TEAM_EXECUTIVES_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'title',
    type: 'text',
    isSortable: true,
    title: 'Title',
  },
  {
    field: 'fullName',
    type: 'text',
    isSortable: true,
    title: 'Full name',
  },
  {
    field: 'email',
    type: 'text',
    isSortable: true,
    title: 'Email',
  },
  {
    field: 'birthday',
    type: 'date',
    isSortable: true,
    title: 'Date of birth',
  },
  {
    field: 'action',
    type: 'empty',
    title: 'Action',
    align: 'right',
  },
]

export const DUE_DILIGENCE_TEAM_BOARD_MEMBERS_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'fullName',
    type: 'text',
    isSortable: true,
    title: 'Full name',
  },
  {
    field: 'email',
    type: 'text',
    isSortable: true,
    title: 'Email',
  },
  {
    field: 'company',
    type: 'text',
    isSortable: true,
    title: 'Company',
  },
  {
    field: 'relationshipToCompany',
    dbField: 'relationship_to_company',
    type: 'text',
    isSortable: true,
    title: 'Relationship to company',
  },
  {
    field: 'action',
    type: 'empty',
    title: 'Action',
    align: 'right',
  },
]

export const DUE_DILIGENCE_TEAM_REFERENCES_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'fullName',
    type: 'text',
    isSortable: true,
    title: 'Full name',
  },
  {
    field: 'businessName',
    dbField: 'business_name',
    type: 'text',
    isSortable: true,
    title: 'Business name',
  },
  {
    field: 'email',
    type: 'text',
    isSortable: true,
    title: 'Email',
  },
  {
    field: 'phone',
    type: 'text',
    isSortable: true,
    title: 'Phone number',
  },
  {
    field: 'relationshipToCompany',
    dbField: 'relationship_to_company',
    type: 'text',
    isSortable: true,
    title: 'Relationship to company',
  },
  {
    field: 'action',
    type: 'empty',
    title: 'Action',
    align: 'right',
  },
]

export const DUE_DILIGENCE_FINANCIALS_INVENTORY_LOCATIONS_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'name',
    type: 'text',
    isSortable: true,
    title: 'Name',
  },
  {
    field: 'type',
    type: 'text',
    isSortable: true,
    title: 'Location Type',
  },
  {
    field: 'landlord',
    type: 'text',
    isSortable: true,
    title: 'Landlord',
  },
  {
    field: 'address',
    type: 'text',
    isSortable: true,
    title: 'Address',
  },
  {
    field: 'action',
    type: 'empty',
    title: 'Action',
    align: 'right',
  },
]

export const DUE_DILIGENCE_FINANCIALS_BANK_ACCOUNTS_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'bankName',
    dbField: 'bank_name',
    type: 'text',
    isSortable: true,
    title: 'Bank Name',
  },
  {
    field: 'bankAccountNumber',
    dbField: 'bank_account_number',
    type: 'text',
    isSortable: true,
    title: 'Bank Account #',
  },
  {
    field: 'abaRoutingNumber',
    dbField: 'aba_routing_number',
    type: 'text',
    isSortable: true,
    title: 'Wire Routing #',
  },
  {
    field: 'purpose',
    type: 'text',
    isSortable: true,
    title: 'Purpose',
  },
  {
    field: 'action',
    type: 'empty',
    title: 'Action',
    align: 'right',
  },
]

export const DUE_DILIGENCE_TEAM_EXECUTIVES_APPLICATION_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'fullName',
    type: 'text',
    isSortable: true,
    title: 'Full name',
  },
  {
    field: 'title',
    type: 'text',
    isSortable: true,
    title: 'Title',
  },
  {
    field: 'phone',
    type: 'text',
    isSortable: true,
    title: 'Phone number',
  },
  {
    field: 'email',
    type: 'text',
    isSortable: true,
    title: 'Email',
  },
]

export const DUE_DILIGENCE_FINANCIALS_INVENTORY_LOCATIONS_APPLICATION_LIST_FILTERS_CONFIG: IFilter[] =
  [
    {
      field: 'name',
      type: 'text',
      isSortable: true,
      title: 'Name',
    },
    {
      field: 'type',
      type: 'text',
      isSortable: true,
      title: 'Location Type',
    },
    {
      field: 'totalInventoryAmount',
      type: 'text',
      isSortable: true,
      title: 'Total inventory value',
      dbField: 'total_inventory_amount',
    },
    {
      field: 'finishedGoodsAmount',
      type: 'text',
      isSortable: true,
      title: 'Finished goods value',
      dbField: 'finished_goods_amount',
    },
  ]

export const DUE_DILIGENCE_DOCUMENT_REQUEST_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'status',
    alias: 'documentRequest',
    type: 'list',
    isMultiple: true,
    options: DUE_DILIGENCE_DOCUMENT_REQUEST_STATUS_OPTIONS,
  },
  {
    type: 'quickFilter',
    field: 'quickFilter',
    quickFilters: [
      {
        title: 'Open Requests',
        filters: {
          status: [
            DueDiligenceDocumentRequestStatus.NotStarted,
            DueDiligenceDocumentRequestStatus.InReview,
            DueDiligenceDocumentRequestStatus.ActionRequired,
          ],
        },
      },
      {
        title: 'Complete Requests',
        filters: { status: [DueDiligenceDocumentRequestStatus.Completed] },
      },
    ],
  },
]

export const WATCHLIST_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'clientName',
    dbField: 'client_name',
    type: 'text',
    isSortable: true,
    title: 'Client',
    alias: 'clientInfo',
  },
  {
    field: 'riskRating',
    dbField: 'risk_rating',
    type: 'number',
    isSortable: true,
    title: 'Risk Rating (Live)',
    alias: 'riskRatingRawScore',
  },
  {
    field: 'inventoryRating',
    dbField: 'inventory_rating',
    type: 'number',
    isSortable: true,
    title: 'Inventory Rating',
    alias: 'inventoryHealth',
  },
  {
    field: 'riskRatingTier',
    type: 'list',
    isMultiple: true,
    isSortable: true,
    excludeFullSearchFilter: true,
    title: 'Risk Tier',
    options: Object.values(WatchListRiskRatingTier).map((value) => ({
      value,
      label: value,
    })),
  },
  {
    field: 'lineTurn',
    dbField: 'line_turn',
    type: 'number',
    isSortable: true,
    title: 'Line Turn',
    alias: 'riskRatingRawData',
  },
  {
    field: 'loanBalance',
    dbField: 'loan_balance',
    type: 'amount',
    isSortable: true,
    title: 'Loan Balance',
    alias: 'loanBalance',
  },
  {
    field: 'maturityDate',
    dbField: 'maturity_date',
    type: 'date',
    isSortable: true,
    title: 'Maturity Date',
    alias: 'clientInfo',
  },
  {
    type: 'quickFilter',
    field: 'quickFilter',
    quickFilters: [
      {
        title: 'Watchlist & Workout Tiers',
        filters: {
          riskRatingTier: [WatchListRiskRatingTier.Watchlist, WatchListRiskRatingTier.Workout],
        },
      },
    ],
  },
]

export const RISK_RATING_TABLE_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'clientName',
    dbField: 'client_name',
    type: 'text',
    isSortable: true,
    title: 'Client',
    alias: 'clientInfo',
  },
  {
    field: 'riskRatingTier',
    type: 'list',
    excludeFullSearchFilter: true,
    excludeColumn: true,
    title: 'Risk Tier',
    isMultiple: true,
    options: Object.values(WatchListRiskRatingTier).map((value) => ({
      value,
      label: value,
    })),
  },
  {
    field: 'industry',
    type: 'list',
    excludeFullSearchFilter: true,
    excludeColumn: true,
    title: 'Industry',
    alias: 'clientInfo',
    isMultiple: true,
    options: Object.values(ClientIndustries).map((value) => ({
      value,
      label: value,
    })),
  },

  {
    field: 'riskRating',
    dbField: 'risk_rating',
    type: 'number',
    isSortable: true,
    title: 'Risk Rating',
    alias: 'riskRatingRawScore',
    placeholder: 'Risk Rating Score',
  },
  {
    field: 'riskRatingChange',
    dbField:
      'COALESCE( "riskRatingRawScore".risk_rating - "previousRiskRatingRawScore".risk_rating , 0)',
    type: 'number',
    isSortable: true,
    title: 'Change',
  },
  {
    field: 'lineTurn',
    dbField: 'line_turn',
    type: 'number',
    isSortable: true,
    title: 'Line Turn',
    alias: 'riskRatingRawScore',
  },
  {
    field: 'cashBurn',
    dbField: 'cash_burn',
    type: 'number',
    isSortable: true,
    title: 'Cash Burn',
    alias: 'riskRatingRawScore',
  },
  {
    field: 'grossMarginRollingAvg',
    dbField: 'gross_margin_rolling_avg',
    type: 'number',
    isSortable: true,
    title: '3M Gross Margin',
    alias: 'riskRatingRawScore',
  },
  {
    field: 'percentApLess_60',
    dbField: 'percent_ap_less_60',
    type: 'number',
    isSortable: true,
    title: '% AP Under 60',
    alias: 'riskRatingRawScore',
  },
  {
    field: 'ebitda_6MonthRollingAvg',
    dbField: 'ebitda_6_month_rolling_avg',
    type: 'number',
    isSortable: true,
    title: '6M EBITDA',
    alias: 'riskRatingRawScore',
  },
  {
    field: 'currentRatio',
    dbField: 'current_ratio',
    type: 'number',
    isSortable: true,
    title: 'Current Ratio',
    alias: 'riskRatingRawScore',
  },
  {
    field: 'percentArLess_60',
    dbField: 'percent_ar_less_60',
    type: 'number',
    isSortable: true,
    title: '% AR Under 60',
    alias: 'riskRatingRawScore',
  },
  {
    field: 'dilution',
    dbField: 'dilution',
    type: 'number',
    isSortable: true,
    title: 'Dilution',
    alias: 'riskRatingRawScore',
  },
  {
    field: 'arTop_5Conc',
    dbField: 'ar_top_5_conc',
    type: 'number',
    isSortable: true,
    title: 'AR Top 5 Conc.',
    alias: 'riskRatingRawScore',
  },
  {
    field: 'weeksOfSupply',
    dbField: 'weeks_of_supply',
    type: 'number',
    isSortable: true,
    title: 'WoS',
    alias: 'riskRatingRawScore',
  },
  {
    field: 'yoyNetRevGrowth',
    dbField: 'yoy_net_rev_growth',
    type: 'number',
    isSortable: true,
    title: 'YoY Net Revenue',
    alias: 'riskRatingRawScore',
  },
  {
    field: 'inventoryHealthRating',
    dbField: 'inventory_rating',
    type: 'number',
    isSortable: true,
    title: 'Inventory Rating',
    alias: 'inventoryHealth',
  },
  {
    field: 'loanBalance',
    dbField: 'loan_balance',
    type: 'amount',
    isSortable: true,
    title: 'Loan Balance',
    alias: 'loanBalance',
  },
  {
    field: 'maturityDate',
    dbField: 'maturity_date',
    type: 'date',
    isSortable: true,
    title: 'Maturity Date',
    alias: 'clientInfo',
  },
  {
    type: 'quickFilter',
    field: 'quickFilter',
    quickFilters: [
      {
        title: 'Watchlist & Workout Tiers',
        filters: {
          riskRatingTier: [WatchListRiskRatingTier.Watchlist, WatchListRiskRatingTier.Workout],
        },
      },
    ],
  },
]

export const RISK_RATING_TABLE_VALUE_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'clientName',
    dbField: 'client_name',
    type: 'text',
    isSortable: true,
    title: 'Client',
    alias: 'clientInfo',
  },
  {
    field: 'riskRatingTier',
    type: 'list',
    excludeFullSearchFilter: true,
    excludeColumn: true,
    title: 'Risk Tier',
    options: Object.values(WatchListRiskRatingTier).map((value) => ({
      value,
      label: value,
    })),
  },
  {
    field: 'industry',
    type: 'list',
    alias: 'clientInfo',
    excludeFullSearchFilter: true,
    excludeColumn: true,
    title: 'Industry',
    isMultiple: true,
    options: Object.values(ClientIndustries).map((value) => ({
      value,
      label: value,
    })),
  },
  {
    field: 'riskRating',
    dbField: 'risk_rating',
    type: 'number',
    isSortable: true,
    title: 'Risk Rating',
    alias: 'riskRatingRawScore',
    placeholder: 'Risk Rating Score',
  },
  {
    field: 'lineTurn',
    dbField: 'line_turn',
    type: 'number',
    isSortable: true,
    title: 'Line Turn',
    alias: 'riskRatingRawScore',
    isMultiple: true,
  },
  {
    field: 'cashBurn',
    dbField: 'cash_burn',
    type: 'number',
    isSortable: true,
    title: 'Cash Burn',
    alias: 'riskRatingRawScore',
  },
  {
    field: 'grossMarginRollingAvg',
    dbField: 'gross_margin_rolling_avg',
    type: 'percent',
    isSortable: true,
    title: '3M Gross Margin',
    alias: 'riskRatingRawScore',
  },
  {
    field: 'percentApLess_60',
    dbField: 'percent_ap_less_60',
    type: 'percent',
    isSortable: true,
    title: '% AP Under 60',
    alias: 'riskRatingRawScore',
  },
  {
    field: 'ebitda_6MonthRollingAvg',
    dbField: 'ebitda_6_month_rolling_avg',
    type: 'amount',
    isSortable: true,
    title: '6M EBITDA',
    alias: 'riskRatingRawScore',
  },
  {
    field: 'currentRatio',
    dbField: 'current_ratio',
    type: 'number',
    isSortable: true,
    title: 'Current Ratio',
    alias: 'riskRatingRawScore',
  },
  {
    field: 'percentArLess_60',
    dbField: 'percent_ar_less_60',
    type: 'percent',
    isSortable: true,
    title: '% AR Under 60',
    alias: 'riskRatingRawScore',
  },
  {
    field: 'dilution',
    dbField: 'dilution',
    type: 'percent',
    isSortable: true,
    title: 'Dilution',
    alias: 'riskRatingRawScore',
  },
  {
    field: 'arTop_5Conc',
    dbField: 'ar_top_5_conc',
    type: 'percent',
    isSortable: true,
    title: 'AR Top 5 Conc.',
    alias: 'riskRatingRawScore',
  },
  {
    field: 'weeksOfSupply',
    dbField: 'weeks_of_supply',
    type: 'number',
    isSortable: true,
    title: 'WoS',
    alias: 'riskRatingRawScore',
  },
  {
    field: 'yoyNetRevGrowth',
    dbField: 'yoy_net_rev_growth',
    type: 'percent',
    isSortable: true,
    title: 'YoY Net Revenue',
    alias: 'riskRatingRawScore',
  },
  {
    field: 'loanBalance',
    dbField: 'loan_balance',
    type: 'amount',
    isSortable: true,
    title: 'Loan Balance',
    alias: 'loanBalance',
  },
  {
    field: 'maturityDate',
    dbField: 'maturity_date',
    type: 'date',
    isSortable: true,
    title: 'Maturity Date',
    alias: 'clientInfo',
  },
  {
    type: 'quickFilter',
    field: 'quickFilter',
    quickFilters: [
      {
        title: 'Watchlist & Workout Tiers',
        filters: {
          riskRatingTier: [WatchListRiskRatingTier.Watchlist, WatchListRiskRatingTier.Workout],
        },
      },
    ],
  },
]

export const TREASURY_FUNDING_WIRES_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'description',
    dbField: 'client_name',
    alias: 'clientInfo',
    type: 'text',
    isSortable: true,
    title: 'Description',
    align: 'left',
  },
  {
    field: 'status',
    dbField: `(
           CASE
               WHEN "treasuryDetails"."borrowing_base_id" IS NULL AND "treasuryDetails".is_prediction = TRUE
                   THEN 'Atlas Predicted'
               WHEN "treasuryDetails"."borrowing_base_id" IS NULL AND "treasuryDetails".is_prediction = FALSE
                   THEN 'User Predicted'
               WHEN "treasuryDetails"."borrowing_base_id" IS NOT NULL AND "borrowingBase".status = '${FundingRequestStatus.Sent}'
                   THEN 'Completed'
               ELSE "borrowingBase".requested_amount_type
               END)`,
    type: 'text',
    isSortable: true,
    title: 'Status',
  },
  {
    field: 'amount',
    dbField: 'COALESCE(fundings.amount, treasuryDetails.amount)',
    type: 'number',
    isSortable: true,
    title: 'Amount',
  },
  { field: 'action', type: 'empty', title: 'Action', align: 'left' },
]

export const TREASURY_OTHER_WIRES_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'description',
    type: 'text',
    isSortable: true,
    title: 'Description',
    align: 'left',
  },
  {
    field: 'amount',
    type: 'number',
    isSortable: true,
    title: 'Amount',
  },
  { field: 'action', type: 'empty', title: 'Action', align: 'left' },
]

export const TREASURY_PARTICIPATION_WIRES_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'description',
    dbField: `CONCAT_WS(' ', "treasuryDetails".client_name, "treasuryDetails".participant, "treasuryDetails".participation_wire_type)`,
    type: 'text',
    isSortable: true,
    title: 'Description',
    align: 'left',
  },
  {
    field: 'amount',
    dbField: 'COALESCE("participationWires".amount, "treasuryDetails".amount)',
    type: 'number',
    isSortable: true,
    title: 'Amount',
  },
  { field: 'action', type: 'empty', title: 'Action', align: 'left' },
]

export const TREASURY_PASSTHROUGH_WIRES_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'description',
    dbField: 'client_name',
    alias: 'treasuryDetails',
    type: 'text',
    isSortable: true,
    title: 'Description',
    align: 'left',
  },
  {
    field: 'amount',
    dbField: 'COALESCE("passThrough".amount, "treasuryDetails".amount)',
    type: 'number',
    isSortable: true,
    title: 'Amount',
  },
  { field: 'action', type: 'empty', title: 'Action', align: 'left' },
]

export const CLIENT_INVENTORY_LOCATIONS_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'location',
    type: 'text',
    isSortable: true,
    title: 'Location',
  },
  {
    field: 'mappedLocation',
    dbField: 'mapped_location',
    type: 'text',
    isSortable: true,
    title: 'Location Alias',
  },
  {
    field: 'entityName',
    dbField: 'entity_name',
    type: 'text',
    isSortable: true,
    title: 'Vendor',
  },
  {
    field: 'warehouseWaiverLink',
    dbField: 'warehouse_waiver_link',
    type: 'text',
    isSortable: true,
    title: 'Warehouse Waiver',
  },
]

export const CLIENT_PARTICIPATION_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'participant',
    type: 'text',
    isSortable: true,
    title: 'Participant',
    dbField: 'participant',
    alias: 'participation',
  },
  {
    field: 'loanExposure',
    type: 'percent',
    isSortable: true,
    title: 'Loan Exposure',
    dbField: 'loan_exposure',
    alias: 'participation',
  },
  {
    field: 'interestOverride',
    type: 'percent',
    isSortable: true,
    title: 'Interest Override',
    dbField: 'interest_override',
    alias: 'participation',
  },
  {
    field: 'contractDate',
    type: 'date',
    isSortable: true,
    title: 'Contract Date',
    dbField: 'contract_date',
    alias: 'participation',
  },
  { field: 'action', type: 'empty', title: 'Action', align: 'right' },
]

export const PARTICIPANT_PARTICIPATION_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'clientName',
    type: 'text',
    isSortable: true,
    title: 'Client',
    alias: 'participation',
    dbField: 'client_name',
  },
  {
    field: 'loanBalance',
    type: 'percent',
    isSortable: true,
    title: 'Loan Balance',
    dbField: 'loan_balance',
    alias: 'participationLoanExposure',
  },
  {
    field: 'participantExposureAmount',
    type: 'percent',
    isSortable: true,
    title: 'Exposure',
    dbField: 'participant_exposure_amount',
    alias: 'participationLoanExposure',
  },

  {
    field: 'participantExposurePercentage',
    type: 'percent',
    isSortable: true,
    title: 'Exposure %',
    dbField: 'participant_exposure_percentage',
    alias: 'participationLoanExposure',
  },
  {
    field: 'loanExposure',
    type: 'percent',
    isSortable: true,
    title: 'Contractual Exposure',
    dbField: 'loan_exposure',
  },
  {
    field: 'interestOverride',
    type: 'percent',
    isSortable: true,
    title: 'Interest Override',
    dbField: 'interest_override',
    alias: 'participation',
  },
  {
    field: 'contractDate',
    type: 'date',
    isSortable: true,
    title: 'Contract Date',
    dbField: 'contract_date',
    alias: 'participation',
  },
]

export const BBC_LOGS_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'type',
    alias: 'bbcLog',
    type: 'list',
    isMultiple: true,
    options: [
      {
        value: 'all',
      },
      ...Object.values(BBCLogType).map((value) => ({
        value,
        label: value,
      })),
    ],
    title: 'Type',
  },
  {
    field: 'userId',
    dbField: 'user_id',
    alias: 'users',
    type: 'autocomplete',
    isMultiple: true,
    placeholder: 'User',
    title: 'User',
  },
  {
    field: 'createdAt',
    dbField: 'created_at',
    alias: 'bbcLog',
    type: 'date',
    isSortable: true,
    title: 'Created At',
  },
]

export const CLIENT_LOAN_LEDGER_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'recordDate',
    type: 'date',
    isSortable: true,
    title: 'Date',
    dbField: 'record_date',
    alias: 'loan_balances',
  },
  {
    field: 'loanBalancePrev',
    dbField: 'loan_balance_prev',
    type: 'amount',
    isSortable: true,
    title: 'Starting Balance',
    alias: 'loan_balances',
  },
  {
    field: 'fundsOut',
    type: 'amount',
    isSortable: true,
    title: 'Fundings & Pass-throughs',
  },
  {
    field: 'fundsIn',
    type: 'amount',
    isSortable: true,
    title: 'Collections & Adjustments',
  },
  {
    field: 'totalFees',
    type: 'amount',
    isSortable: true,
    title: 'Interest & Fees',
    dbField: 'fees',
    alias: 'loan_balances',
  },
  {
    field: 'loanBalance',
    dbField: 'loan_balance',
    type: 'amount',
    isSortable: true,
    title: 'Ending Balance',
    alias: 'loan_balances',
  },
  {
    field: 'dwightExposure',
    type: 'amount',
    title: 'Dwight NFE',
    align: 'right',
  },
]

export const PARTICIPATION_OUTSTANDING_WIRES_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'participant',
    type: 'text',
    isSortable: true,
    title: 'Participant',
    dbField: 'participant',
    alias: 'outstanding_wires',
  },
  {
    field: 'clientName',
    type: 'text',
    isSortable: true,
    title: 'Client',
    dbField: 'client_name',
    alias: 'outstanding_wires',
  },
  {
    field: 'participantExposureAmount',
    type: 'amount',
    isSortable: true,
    title: 'Participant Exposure',
    dbField: 'participant_exposure_amount',
  },
  {
    field: 'participantExposurePercentage',
    type: 'percent',
    isSortable: true,
    title: 'Participant Exposure %',
    dbField: 'participant_exposure_percentage',
  },

  {
    field: 'wireAmount',
    type: 'amount',
    isSortable: true,
    title: 'Wire Amount',
    dbField: 'wire_amount',
  },
  {
    field: 'recordDate',
    type: 'date',
    isSortable: true,
    title: 'Date',
    dbField: 'record_date',
  },
  {
    field: 'direction',
    type: 'text',
    isSortable: true,
    title: 'Direction',
    excludeColumn: true,
  },
  {
    field: 'type',
    type: 'text',
    isSortable: true,
    title: 'Type',
  },
  { field: 'action', type: 'empty', title: 'Action', align: 'right' },
  {
    type: 'quickFilter',
    field: 'quickFilter',
    quickFilters: [
      {
        title: 'Today',
        filters: {
          recordDateFrom: moment().format('YYYY-MM-DD'),
          recordDateTo: moment().format('YYYY-MM-DD'),
        },
      },
    ],
  },
]

export const PARTICIPANT_PARTICIPATION_OUTSTANDING_WIRES_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'clientName',
    type: 'text',
    isSortable: true,
    title: 'Client',
    dbField: 'client_name',
    alias: 'outstanding_wires',
  },
  {
    field: 'type',
    type: 'text',
    isSortable: true,
    title: 'Type',
  },
  {
    field: 'participantExposureAmount',
    type: 'amount',
    isSortable: true,
    title: 'Exposure',
    dbField: 'participant_exposure_amount',
  },
  {
    field: 'participantExposurePercentage',
    type: 'percent',
    isSortable: true,
    title: 'Exposure %',
    dbField: 'participant_exposure_percentage',
  },
  {
    field: 'wireAmount',
    type: 'amount',
    isSortable: true,
    title: 'Wire Amount',
    dbField: 'wire_amount',
  },
  {
    field: 'recordDate',
    type: 'date',
    isSortable: true,
    title: 'Date',
    dbField: 'record_date',
  },
  {
    field: 'direction',
    type: 'text',
    isSortable: true,
    title: 'Direction',
    excludeColumn: true,
  },
  {
    type: 'quickFilter',
    field: 'quickFilter',
    quickFilters: [
      {
        title: 'Last 30 Days',
        filters: {
          recordDateFrom: moment().subtract(1, 'month').format('YYYY-MM-DD'),
          recordDateTo: moment().format('YYYY-MM-DD'),
        },
      },
    ],
  },
]

export const DILUTION_BY_CUSTOMER_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'debtor',
    type: 'list',
    isMultiple: true,
    title: 'Customer',
    align: 'left',
    isSortable: true,
  },
  {
    field: 'invoices',
    type: 'amount',
    title: 'Invoices',
    isSortable: true,
  },
  {
    field: 'payments',
    type: 'amount',
    title: 'Payments',
    isSortable: true,
  },
  {
    field: 'dilutiveCredits',
    type: 'amount',
    title: 'Dilutive Credits',
    isSortable: true,
  },
  {
    field: 'other',
    type: 'amount',
    title: 'Other',
    isSortable: true,
  },
  {
    field: 'dilution',
    type: 'percent',
    isSortable: true,
    title: 'Dilution',
  },
  {
    type: 'quickFilter',
    field: 'quickFilter',
    fields: [
      {
        field: 'category',
        type: 'list',
      },
    ],
    quickFilters: [],
  },
]

export const DILUTION_OVER_TIME_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'debtor',
    type: 'list',
    isMultiple: true,
    title: 'Customer',
    align: 'left',
    isSortable: true,
  },
  {
    type: 'quickFilter',
    field: 'quickFilter',
    fields: [
      {
        field: 'category',
        type: 'list',
      },
    ],
    quickFilters: [],
  },
]

export const ENTITY_ACCOUNTS_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'accountNumber',
    type: 'text',
    isSortable: true,
    title: 'Account',
  },
  {
    field: 'lastSeenDate',
    isSortable: true,
    type: 'date',
    title: 'Last seen',
    excludeFilter: true,
  },
  {
    field: 'lastMappedDate',
    isSortable: true,
    type: 'date',
    title: 'Mapped date',
    excludeFilter: true,
  },
  {
    field: 'linkedName',
    type: 'empty',
    title: 'Primary entity record',
    align: 'left',
  },
]

export const AMENDMENTS_QUEUE_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'expand',
    title: '',
    type: 'empty',
  },
  {
    field: 'amendmentType',
    dbField: 'amendment_type',
    type: 'list',
    alias: 'amendment',
    options: Object.values(ClientAmendmentType).map((type) => ({
      value: type,
      label: type,
    })),
    isSortable: true,
    title: 'Amendment Type',
  },
  {
    field: 'createdAt',
    dbField: 'created_at',
    alias: 'amendment',
    type: 'date',
    isSortable: true,
    title: 'Created Date',
    align: 'left',
  },
  {
    field: 'effectedDate',
    dbField: 'effective_date',
    alias: 'amendment',
    type: 'date',
    isSortable: true,
    title: 'Effective Date',
    align: 'left',
  },
  {
    field: 'summaryOfChanges',
    type: 'text',
    isSortable: false,
    title: 'Summary of Changes',
    align: 'left',
  },
  {
    field: 'action',
    title: 'Action',
    type: 'empty',
  },
]

export const NOTES_LIST_PER_PAGE = 20

export const NOTES_LIST_SORT_OPTIONS = [
  {
    value: 'created_at DESC',
    label: 'Created (Newest)',
  },
  {
    value: 'created_at ASC',
    label: 'Created (Oldest)',
  },
  {
    value: 'resolved DESC',
    label: 'Resolved (Top)',
  },
  {
    value: 'resolved ASC',
    label: 'Resolved (Bottom)',
  },
  {
    value: 'updated_at DESC',
    label: 'Updated (Newest)',
  },
  {
    value: 'updated_at ASC',
    label: 'Updated (Oldest)',
  },
]

export const FLAGS_SORT_OPTIONS = [
  {
    value: 'record_date DESC',
    label: 'Most Recent',
  },
  {
    value: 'record_date ASC',
    label: 'Oldest',
  },
  {
    value: 'resolved DESC',
    label: 'Resolved (Top)',
  },
  {
    value: 'resolved ASC',
    label: 'Resolved (Bottom)',
  },
]

export const EMPTY_ORDER_BY: any = {
  field: null,
  direction: 'ASC',
}

export const COLLECTIONS_RECONCILIATION_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'label',
    type: 'text',
    title: 'Customer',
    align: 'left',
    isSortable: true,
  },
  {
    field: 'implied_collections',
    type: 'amount',
    isSortable: true,
    title: 'Implied Collections',
  },
  {
    field: 'ar_gl_payments',
    type: 'amount',
    isSortable: true,
    title: 'AR GL Payments',
  },
  {
    field: 'actual_collections',
    type: 'amount',
    isSortable: true,
    title: 'Actual Collections',
  },
  {
    field: 'ar_gl_invoices',
    type: 'amount',
    isSortable: true,
    title: 'AR GL Invoices',
  },
  {
    field: 'ar_gl_dilutive_credits',
    type: 'amount',
    isSortable: true,
    title: 'AR GL Dilutive Credits',
  },
  {
    field: 'ar_gl_other',
    type: 'amount',
    isSortable: true,
    title: 'AR GL Other',
  },
  {
    field: 'ar_gl_dilution',
    type: 'percent',
    isSortable: true,
    title: 'AR GL Dilution',
  },
  {
    field: 'actual_dilution',
    type: 'percent',
    isSortable: true,
    title: 'Actual Dilution',
  },
  {
    field: 'dilution_change',
    type: 'percent',
    isSortable: true,
    title: '% Change',
  },
]

export const SALES_CHANNEL_DISTRIBUTION_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'label',
    type: 'text',
    title: 'Month',
    align: 'left',
    isSortable: true,
  },
  {
    field: 'customer_deposits',
    type: 'amount',
    isSortable: true,
    title: 'Customer Deposits',
  },
  {
    field: 'gross_revenue',
    type: 'amount',
    isSortable: true,
    title: 'Gross Revenue',
  },
  {
    field: 'net_revenue',
    type: 'amount',
    isSortable: true,
    title: 'Net Revenue',
  },
  {
    field: 'ar_gl_invoices',
    type: 'amount',
    isSortable: true,
    title: 'AR GL Invoices',
  },
  {
    field: 'ar_gl_payments',
    type: 'amount',
    isSortable: true,
    title: 'AR GL Collections',
  },
  {
    field: 'ar_gl_dilutive_credits',
    type: 'amount',
    isSortable: true,
    title: 'AR GL Credits',
  },
  {
    field: 'dilution',
    type: 'percent',
    isSortable: true,
    title: 'Dilution',
  },
]

export const CLIENT_PENDING_CASH_DOMINION_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'entity_name',
    type: 'text',
    isSortable: true,
    title: 'Customer',
  },
  {
    field: 'record_date',
    type: 'date',
    isSortable: true,
    title: 'Last Transaction',
  },
  {
    field: 'category',
    type: 'empty',
    isSortable: false,
    title: 'Category',
  },
  {
    field: 'amount',
    type: 'amount',
    isSortable: true,
    title: 'Total Amount (Last 90 days)',
  },
]

export const FINANCIALS_COMPARISON_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'clientName',
    dbField: 'client_name',
    type: 'text',
    title: 'Client Name',
    isSortable: true,
    alias: 'ci',
  },
  {
    field: 'industry',
    type: 'list',
    title: 'Industry',
    isSortable: true,
    alias: 'ci',
    isMultiple: true,
  },
  {
    field: 'riskRating',
    type: 'number',
    dbField: 'risk_rating',
    title: 'Risk Rating',
    isSortable: true,
    alias: 'rr',
  },
  {
    field: 'metric1',
    type: 'number',
    title: 'Metric 1',
    isSortable: true,
    aggColumn: true,
  },
  {
    field: 'metric1Projection',
    type: 'number',
    title: 'Metric 1 Projections',
    isSortable: true,
    aggColumn: true,
  },
  {
    field: 'metric1Difference',
    type: 'number',
    title: 'Metric 1 Difference',
    isSortable: true,
    aggColumn: true,
  },
  {
    field: 'metric1DifferencePercentage',
    type: 'percent',
    title: 'Metric 1 Difference %',
    isSortable: true,
    aggColumn: true,
  },
  {
    field: 'metric2',
    type: 'number',
    title: 'Metric 2',
    isSortable: true,
    aggColumn: true,
  },
  {
    field: 'metric2Projection',
    type: 'number',
    title: 'Metric 2 Projections',
    isSortable: true,
    aggColumn: true,
  },
  {
    field: 'metric2Difference',
    type: 'number',
    title: 'Metric 2 Difference',
    isSortable: true,
    aggColumn: true,
  },
  {
    field: 'metric2DifferencePercentage',
    type: 'percent',
    title: 'Metric 2 Difference %',
    isSortable: true,
    aggColumn: true,
  },
]
