import React, { ReactNode, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import cn from 'classnames'

import styles from './MultiSelectToolbar.module.scss'
import { CloseIcon } from '../Common/Icons'

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  activeItems: any[]
  children?: ReactNode
  className?: string
  totalSum?: string
  minLength?: number
  resetActiveItems: () => void
  inModal?: boolean
}

const MultiSelectToolbar = ({
  activeItems,
  children,
  className,
  minLength = 2,
  resetActiveItems,
  totalSum,
  inModal = false,
  ...props
}: IProps) => {
  const [toolbarId, setToolbarId] = useState('multiSelectToolbar')
  const wrapper = document.getElementById(toolbarId)

  useEffect(() => {
    setToolbarId(inModal ? 'multiSelectToolbarModal' : 'multiSelectToolbar')
  }, [inModal])

  if (!wrapper || activeItems.length < minLength) {
    return null
  }

  return ReactDOM.createPortal(
    <div className={cn(styles.multiSelectToolbar, className)} {...props}>
      <div className={styles.multiSelectToolbarLeft}>
        <div className={styles.activeToolbarItemsCount}>{activeItems.length} rows selected</div>
        <div className={styles.activeToolbarSum}>{totalSum}</div>
      </div>
      <div className={styles.multiSelectToolbarRight}>
        <div className={styles.activeToolbarButtons}>{children}</div>
        <div className={styles.activeToolbarClear}>
          <CloseIcon action={resetActiveItems} />
        </div>
      </div>
    </div>,
    wrapper,
  )
}

export default MultiSelectToolbar
