import { connect } from 'react-redux'
import { setModelProvider } from '../../../redux/atlasai/actions'
import ChatInput from './ChatInput'
import { createStructuredSelector } from 'reselect'
import { modelProvider } from '../../../redux/atlasai/selectors'

const actions = {
  setModelProvider,
}

const selector = createStructuredSelector({
  modelProvider,
})

export default connect(selector, actions)(ChatInput)
