import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'
import { dateToString, dateTimeToString } from './helpers'
import { IFilter } from '@common/constants/filters'

const FILTERS_STORAGE_KEY = 'filters'

export const buildFiltersDefaults = (filterConfig: IFilter[], baseDefaults: any = {}) => {
  const defaults: any = {}

  filterConfig.forEach(({ field, type }) => {
    if (type === 'quickFilter') {
      return
    }

    switch (type) {
      case 'autocomplete':
      case 'list':
        defaults[field] = []
        break
      case 'text':
        defaults[field] = null
        break
      case 'amount':
      case 'percent':
      case 'number':
        defaults[`${field}Min`] = null
        defaults[`${field}Max`] = null
        break
      case 'date':
      case 'datetime':
        defaults[`${field}From`] = null
        defaults[`${field}To`] = null
        break
      default:
        break
    }
  })

  return {
    ...defaults,
    ...baseDefaults,
  }
}

export const buildFiltersValidateSchema = (filterConfig: IFilter[]) => {
  const shape = {}

  filterConfig.forEach(({ field, type, isMultiple }) => {
    if (type === 'quickFilter') {
      return
    }

    switch (type) {
      case 'amount':
      case 'percent':
      case 'number':
        const fieldMinLabel = `${field}Min`
        const fieldMaxLabel = `${field}Max`
        shape[fieldMinLabel] = Yup.number()
          .typeError('Please, enter valid number')
          .nullable(true)
          .when(fieldMaxLabel, (max: any, validation: any) =>
            max
              ? validation.max(Yup.ref(fieldMaxLabel), 'Min should be less than or equal to max')
              : validation,
          )
        shape[fieldMaxLabel] = Yup.number()
          .typeError('Please, enter valid number')
          .nullable(true)
          .test({
            name: 'min',
            exclusive: false,
            message: 'Max should be greater than or equal to min',
            test(value) {
              return !value || !this.parent[fieldMinLabel] || this.parent[fieldMinLabel] <= value
            },
          })
        break
      case 'date':
      case 'datetime':
        const fieldFromLabel = `${field}From`
        const fieldToLabel = `${field}To`
        shape[fieldFromLabel] = Yup.date()
          .typeError('Please type date in MM/DD/YY format')
          .nullable(true)
        shape[fieldToLabel] = Yup.date()
          .typeError('Please type date in MM/DD/YY format')
          .nullable(true)
          .when(fieldFromLabel, (dateFrom: any, validation: any) =>
            dateFrom && dateFrom instanceof Date && !isNaN(+dateFrom)
              ? validation.min(dateFrom, 'To date should be prior to the from date')
              : validation,
          )
        break
      default:
        break
    }
  })

  return makeValidate(Yup.object().shape(shape))
}

export const updateDateFilter = (fieldNames: string[], params: any) => {
  fieldNames.forEach((fieldName) => {
    if (params[fieldName] && typeof params[fieldName] !== 'string') {
      params[fieldName] = dateToString(params[fieldName])
    }
  })
  return params
}

export const updateDateFilters = (filters: any, filterConfig: IFilter[]) => {
  const updatedFilters = { ...filters }

  filterConfig.forEach(({ field, type }) => {
    const fieldFromLabel = `${field}From`
    const fieldToLabel = `${field}To`
    switch (type) {
      case 'date':
      case 'datetime':
        const dateFunc = type === 'date' ? dateToString : dateTimeToString
        if (filters[fieldFromLabel] && typeof filters[fieldFromLabel] !== 'string') {
          updatedFilters[fieldFromLabel] = dateFunc(filters[fieldFromLabel])
        }
        if (filters[fieldToLabel] && typeof filters[fieldToLabel] !== 'string') {
          updatedFilters[fieldToLabel] = dateFunc(filters[fieldToLabel])
        }
        break
      default:
        break
    }
  })

  return updatedFilters
}

export const getFilters = (tableAlias: string, filtersDefaults: any) => {
  let preparedFilters = {}
  const savedFilters = localStorage.getItem(FILTERS_STORAGE_KEY)
  if (savedFilters) {
    try {
      preparedFilters = JSON.parse(savedFilters)
    } catch (err) {
      preparedFilters = {}
    }
  }

  return preparedFilters[tableAlias] || filtersDefaults
}

export const saveFilters = (tableAlias: string, filters: any) => {
  let preparedFilters = {}
  const savedFilters = localStorage.getItem(FILTERS_STORAGE_KEY)
  if (savedFilters) {
    try {
      preparedFilters = JSON.parse(savedFilters)
    } catch (err) {
      preparedFilters = {}
    }
  }
  preparedFilters[tableAlias] = filters
  localStorage.setItem(FILTERS_STORAGE_KEY, JSON.stringify(preparedFilters))
}

export const clearFilters = () => {
  localStorage.removeItem(FILTERS_STORAGE_KEY)
}
