import { Feedback } from './prospects'

export interface IChatMessage {
  id: string
  role: 'user' | 'assistant'
  content: string
  sqlQuery?: string
  queryResults?: string
  sessionId?: string
  feedbackType?: Feedback
  feedbackComment?: string
  timeToRespondInMs?: number
  createdAt?: string
}

export interface ICurrentResponse {
  response: string
  sqlQuery: string
  queryResults?: string
  sessionId: string
  question: string
  processingTimeMs: number
}

export interface IChatHistory {
  hasMore: boolean
  messages: IChatMessage[]
  total: number
  limit: number
  offset: number
  thinking?: string
  result?: any
}

export interface IChatSession {
  id: string
  name: string
  lastActive: string
  lastMessage: string
  history?: IChatHistory
}

export interface IChatSessionsResponse {
  data: IChatSession[]
  total: number
  limit: number
  offset: number
}

export enum Models {
  GPT_4O = 'gpt-4o',
  O3_MINI = 'o3-mini',
  CLAUDE_3_5_SONNET_LATEST = 'claude-3-5-sonnet-latest',
  CLAUDE_3_7_SONNET_LATEST = 'claude-3-7-sonnet-latest',
}

export enum ModelProvider {
  OPENAI = 'openai',
  ANTHROPIC = 'anthropic',
  GOOGLE = 'google',
}

export const MODEL_TO_PROVIDER: Record<Models, ModelProvider> = {
  [Models.GPT_4O]: ModelProvider.OPENAI,
  [Models.O3_MINI]: ModelProvider.OPENAI,
  [Models.CLAUDE_3_5_SONNET_LATEST]: ModelProvider.ANTHROPIC,
  [Models.CLAUDE_3_7_SONNET_LATEST]: ModelProvider.ANTHROPIC,
}

export const MODEL_PROVIDERS: { name: string; value: Models }[] = [
  {
    name: 'Claude 3.7',
    value: Models.CLAUDE_3_7_SONNET_LATEST,
  },
  {
    name: 'Claude 3.5',
    value: Models.CLAUDE_3_5_SONNET_LATEST,
  },
  {
    name: 'ChatGPT-4o',
    value: Models.GPT_4O,
  },
  {
    name: 'o3-mini',
    value: Models.O3_MINI,
  },
]
