import React from 'react'

import { ISearchResultItem, SearchType } from '@common/interfaces/search'
import { IClientInfo } from '@common/interfaces/client'
import { IBorrowingBaseIndex, IOngoingReporting } from '@common/interfaces/bbc'
import { IMasterCollection } from '@common/interfaces/collection'
import { IFee } from '@common/interfaces/loanServicing'
import { IUser } from '@common/interfaces/user'
import { IEntityInfo } from '@common/interfaces/entityInfo'
import TableRow from '../../components/Common/TableRow'
import GlobalSearchPageTableRowClient from './GlobalSearchPageTableRowClient'
import GlobalSearchPageTableRowCollection from './GlobalSearchPageTableRowCollection'
import GlobalSearchPageTableRowEntity from './GlobalSearchPageTableRowEntity'
import GlobalSearchPageTableRowFee from './GlobalSearchPageTableRowFee'
import GlobalSearchPageTableRowFunding from './GlobalSearchPageTableRowFunding'
import GlobalSearchPageTableRowOngoingReporting from './GlobalSearchPageTableRowOngoingReporting'
import GlobalSearchPageTableRowUser from './GlobalSearchPageTableRowUser'
import GlobalSearchPageTableRowParticipant from './GlobalSearchPageTableRowParticipant'
import { IParticipant } from '@common/interfaces/participant'
import { generateSearchResultItemLink } from '../../components/GlobalSearch/GlobalSearch.helpers'

const GlobalSearchPageTableRow = ({
  item,
  searchString,
}: {
  item: ISearchResultItem
  searchString: string
}) => {
  const link = generateSearchResultItemLink(item)

  if ([SearchType.Client, SearchType.DueDiligence, SearchType.Prospect].includes(item.type)) {
    return (
      <GlobalSearchPageTableRowClient
        clientInfo={item.item as IClientInfo}
        searchString={searchString}
        searchType={item.type}
      />
    )
  }
  if (item.type === SearchType.Funding) {
    return (
      <GlobalSearchPageTableRowFunding
        bbc={item.item as IBorrowingBaseIndex}
        searchString={searchString}
      />
    )
  }
  if (item.type === SearchType.Collection) {
    return (
      <GlobalSearchPageTableRowCollection
        collection={item.item as IMasterCollection}
        searchString={searchString}
        link={link}
      />
    )
  }
  if (item.type === SearchType.Fee) {
    return (
      <GlobalSearchPageTableRowFee
        fee={item.item as IFee}
        searchString={searchString}
        link={link}
      />
    )
  }
  if (item.type === SearchType.OngoingReporting) {
    return (
      <GlobalSearchPageTableRowOngoingReporting
        ongoingReporting={item.item as IOngoingReporting}
        searchString={searchString}
      />
    )
  }
  if (item.type === SearchType.User) {
    return <GlobalSearchPageTableRowUser user={item.item as IUser} searchString={searchString} />
  }
  if (item.type === SearchType.Entity) {
    return (
      <GlobalSearchPageTableRowEntity
        entityInfo={item.item as IEntityInfo}
        searchString={searchString}
      />
    )
  }
  if (item.type === SearchType.Participant) {
    return (
      <GlobalSearchPageTableRowParticipant
        participant={item.item as IParticipant}
        searchString={searchString}
      />
    )
  }

  return <TableRow />
}

export default GlobalSearchPageTableRow
