import { MutableRefObject, useCallback, useEffect } from 'react'

interface IProps {
  tableRef: MutableRefObject<HTMLTableElement>
  bodyId: string
  coupledTables: string[]
  headerId?: string
  footerId?: string
}

const useCoupledTablesScroll = ({
  tableRef,
  bodyId,
  coupledTables,
  headerId,
  footerId,
}: IProps) => {
  useEffect(() => {
    const tableBody = document.getElementById(bodyId)
    if (tableBody) {
      const scrollLeft = tableBody.scrollWidth
      tableBody.scrollLeft = scrollLeft
      coupledTables.forEach((tableId) => {
        const coupledTable = document.getElementById(tableId)
        if (coupledTable) {
          coupledTable.scrollLeft = scrollLeft
        }
      })
    }
  }, [tableRef?.current?.children, bodyId, coupledTables])

  const handleBodyScroll = useCallback(() => {
    const tableBody = document.getElementById(bodyId)
    if (tableBody && tableRef) {
      const scrollLeft = tableBody.scrollLeft
      coupledTables.forEach((tableId) => {
        const coupledTable = document.getElementById(tableId)
        if (coupledTable) {
          coupledTable.scrollLeft = scrollLeft
        }
      })
    }
  }, [bodyId, tableRef, coupledTables])

  const handleHeaderScroll = useCallback(() => {
    if (!headerId) {
      return
    }

    const tableHeader = document.getElementById(headerId)
    const tableBody = document.getElementById(bodyId)
    if (tableHeader && tableBody) {
      tableBody.scrollLeft = tableHeader.scrollLeft
    }
  }, [bodyId, headerId])

  const handleFooterScroll = useCallback(() => {
    if (!footerId) {
      return
    }

    const tableFooter = document.getElementById(footerId)
    const tableBody = document.getElementById(bodyId)
    if (tableFooter && tableBody) {
      tableBody.scrollLeft = tableFooter.scrollLeft
    }
  }, [bodyId, footerId])

  return {
    handleBodyScroll,
    handleHeaderScroll,
    handleFooterScroll,
  }
}

export default useCoupledTablesScroll
