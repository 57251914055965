import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { useParams } from 'react-router'
import InfiniteScroll from 'react-infinite-scroll-component'
import Tooltip from '@mui/material/Tooltip'
import cn from 'classnames'
import styles from './EntityReportSummaryDetailsTable.module.scss'
import genericSs from '@styles/generic.module.scss'
import MultiselectRow from '../MultiselectRow'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableContainer from '../Common/TableContainer'
import TableBody from '../Common/TableBody'
import { IReportSummaryStats } from '@common/interfaces/entityInfo'
import { debounceEventHandler, formatNumber, formatPrice } from '../../helpers/helpers'
import TableFiltersRow from '../Common/TableFiltersRow'
import { ENTITY_REPORT_DETAILS_LIST_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import TableLoader from '../Common/TableLoader'
import useSummaryRow from '../../hooks/useSummaryRow'
import useTable from '../../hooks/useTable'

interface IProps {
  rows: IReportSummaryStats[]
  reportType: 'AR' | 'AP'
  clientName: string
  filters: any
  relatedEntities?: string
  getEntityArDetails: (id: string, params: object) => void
  getEntityApDetails: (id: string, params: object) => void
}

const EntitySummaryDetailTable = ({
  rows,
  reportType,
  clientName,
  filters,
  relatedEntities,
  getEntityArDetails,
  getEntityApDetails,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const [isLoading, setIsLoading] = useState(true)

  const {
    orderBy,
    activeItem,
    activeItems,
    setActiveItem,
    setActiveItems,
    handleOrderChange,
    handleSelectRow,
  } = useTable({
    tableId: 'entitySummaryDetailTable',
    sortDefault: {
      field: 'total',
      direction: 'DESC',
    },
  })

  const filtersConfig = useMemo(
    () =>
      ENTITY_REPORT_DETAILS_LIST_FILTERS_CONFIG.map((item) => ({
        ...item,
        title: item.field === 'total' ? `Total ${reportType}` : item.title,
      })),
    [reportType],
  )

  const debounceListEntityReportDatails = useMemo(
    () =>
      debounceEventHandler(async (data: any) => {
        !data.loadMore && setIsLoading(true)
        await (reportType === 'AR' ? getEntityArDetails : getEntityApDetails)(id, {
          ...data,
          clientName,
          relatedEntities,
          nestedRows: {
            keys: ['clientName'],
          },
        })
        setIsLoading(false)
      }, 500),
    [id, reportType, clientName, getEntityArDetails, getEntityApDetails, relatedEntities],
  )

  const loadMore = useCallback(() => {
    debounceListEntityReportDatails({
      loadMore: true,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
      page: Math.ceil(rows?.length / PER_PAGE),
      perPage: PER_PAGE,
    })
  }, [orderBy, filters, debounceListEntityReportDatails, rows])

  useEffect(() => {
    !rows &&
      debounceListEntityReportDatails({
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
        filters,
        page: 0,
        perPage: PER_PAGE,
      })
  }, [orderBy, filters, debounceListEntityReportDatails, rows])

  useEffect(() => {
    debounceListEntityReportDatails({
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
      page: 0,
    })
  }, [orderBy, filters, debounceListEntityReportDatails])

  const totalRow = useSummaryRow(rows, activeItems, {
    sumFields: ['_1To_30Days', '_31To_60Days', '_61To_90Days', '_91PlusDays', 'total'],
    averageFields: ['daysPastInvoice'],
  })

  return (
    <TableContainer
      className={cn(styles.table, reportType === 'AR' ? styles.arTable : styles.apTable)}
      onActiveRowsChange={setActiveItems}
      onActiveRowChange={setActiveItem}
      hasFooter
      size="small"
    >
      <Table>
        <TableHead>
          <TableFiltersRow
            filters={filtersConfig}
            orderBy={orderBy}
            handleOrderChange={handleOrderChange}
          />
        </TableHead>
        <TableBody id={`scrollableEntitySummaryDetailTable-${clientName}`}>
          {isLoading ? (
            <TableLoader columnsCount={filtersConfig.length} />
          ) : (
            <InfiniteScroll
              dataLength={rows?.length || 0}
              next={loadMore}
              hasMore={rows?.length < rows?.[0]?.totalCount}
              loader={<TableLoader columnsCount={filtersConfig.length} rowsCount={1} />}
              scrollableTarget={`scrollableEntitySummaryDetailTable-${clientName}`}
            >
              {rows?.map((item, index) => (
                <TableRow
                  key={item.invoice}
                  data-index={index}
                  className={cn('activableRow', {
                    activeRow: activeItems.includes(index),
                    currentActiveRow: activeItem === index,
                  })}
                  onClick={(event) => handleSelectRow(event, index)}
                >
                  <TableCell className={genericSs.tableTextLeft}>
                    <Tooltip
                      title={item.invoice || ''}
                      placement="top"
                      disableHoverListener={item.invoice?.length < 10 || !item.invoice}
                      disableTouchListener
                    >
                      <span>{item.invoice}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell className={genericSs.tableTextRight}>{item.daysPastInvoice}</TableCell>
                  <TableCell className={genericSs.tableTextRight}>
                    <span className={genericSs.pricePrefix}>$</span>
                    {formatPrice(item._1To_30Days)}
                  </TableCell>
                  <TableCell className={genericSs.tableTextRight}>
                    <span className={genericSs.pricePrefix}>$</span>
                    {formatPrice(item._31To_60Days)}
                  </TableCell>
                  <TableCell className={genericSs.tableTextRight}>
                    <span className={genericSs.pricePrefix}>$</span>
                    {formatPrice(item._61To_90Days)}
                  </TableCell>
                  <TableCell className={genericSs.tableTextRight}>
                    <span className={genericSs.pricePrefix}>$</span>
                    {formatPrice(item._91PlusDays)}
                  </TableCell>
                  <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                    <span className={genericSs.pricePrefix}>$</span>
                    {formatPrice(item.total)}
                  </TableCell>
                  <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                    {(item.percent * 100 || 0).toFixed(2)}%
                  </TableCell>
                </TableRow>
              ))}
            </InfiniteScroll>
          )}
          <MultiselectRow activeItems={activeItems}>
            <TableCell className={genericSs.tableTextRight}>
              {formatNumber(totalRow?.daysPastInvoice)}
            </TableCell>
            <TableCell className={genericSs.tableTextRight}>
              <span className={genericSs.pricePrefix}>$</span>
              {formatPrice(totalRow?._1To_30Days)}
            </TableCell>
            <TableCell className={genericSs.tableTextRight}>
              <span className={genericSs.pricePrefix}>$</span>
              {formatPrice(totalRow?._31To_60Days)}
            </TableCell>
            <TableCell className={genericSs.tableTextRight}>
              <span className={genericSs.pricePrefix}>$</span>
              {formatPrice(totalRow?._61To_90Days)}
            </TableCell>
            <TableCell className={genericSs.tableTextRight}>
              <span className={genericSs.pricePrefix}>$</span>
              {formatPrice(totalRow?._91PlusDays)}
            </TableCell>
            <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
              <span className={genericSs.pricePrefix}>$</span>
              {formatPrice(totalRow?.total)}
            </TableCell>
            <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
              {(totalRow?.percent * 100 || 0).toFixed(2)}%
            </TableCell>
          </MultiselectRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default EntitySummaryDetailTable
