import React, { useCallback, useState, useMemo, useEffect, useRef } from 'react'
import { useParams } from 'react-router'
import Tooltip from '@mui/material/Tooltip'
import cn from 'classnames'
import InfiniteScroll from 'react-infinite-scroll-component'

import styles from './BBCRollforwardDetailsTable.module.scss'
import genericSs from '@styles/generic.module.scss'

import MultiselectRow from '../MultiselectRow'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TableLoader from '../Common/TableLoader'
import {
  IARRollforwardDetail,
  IAPRollforwardDetail,
  BBC_TABS,
  IARRollforwardSummary,
  IAPRollforwardSummary,
  BBCReceivablesGroupBy,
  ReportingFlow,
} from '@common/interfaces/bbc'
import { debounceEventHandler, formatValue } from '../../helpers/helpers'
import {
  BBC_AR_ROLLFORWARD_DETAIL_LIST_FILTERS_CONFIG,
  BBC_AP_ROLLFORWARD_DETAIL_LIST_FILTERS_CONFIG,
  PER_PAGE,
} from '@common/constants/filters'
import TableFiltersRow from '../Common/TableFiltersRow'
import FormattedTableCell from '../Common/FormattedTableCell'
import useTable from '../../hooks/useTable'

interface IProps {
  rollforwardDetail: IARRollforwardDetail[] | IAPRollforwardDetail[]
  getBBCARRollforwardDetails: (id: string, data: object) => void
  getBBCAPRollforwardDetails: (id: string, data: object) => void
  filters: any
  entity: string
  recordDate: string
  hasPreviousBBC: boolean
  type: BBC_TABS
  groupBy?: BBCReceivablesGroupBy
  reportingFlow: ReportingFlow
  currentDate?: string
}

const BBCRollforwardDetailsTable = ({
  rollforwardDetail,
  getBBCARRollforwardDetails,
  getBBCAPRollforwardDetails,
  filters,
  entity,
  recordDate,
  hasPreviousBBC,
  type,
  groupBy,
  reportingFlow,
  currentDate,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const wrapperRef = useRef(null)

  const [isLoading, setIsLoading] = useState(true)

  const {
    handleOrderChange,
    orderBy,
    activeItem,
    activeItems,
    setActiveItem,
    setActiveItems,
    handleSelectRow,
  } = useTable({
    tableId: 'rollforwardDetails',
    sortDefault: {
      field: 'invoice',
      direction: 'ASC',
    },
  })

  const getDetail = useMemo(
    () => (type === BBC_TABS.RECEIVABLES ? getBBCARRollforwardDetails : getBBCAPRollforwardDetails),
    [type, getBBCARRollforwardDetails, getBBCAPRollforwardDetails],
  )

  const key = useMemo(() => (type === BBC_TABS.RECEIVABLES ? 'debtor' : 'creditor'), [type])

  const filtersConfig = useMemo(
    () =>
      (type === BBC_TABS.RECEIVABLES
        ? BBC_AR_ROLLFORWARD_DETAIL_LIST_FILTERS_CONFIG
        : BBC_AP_ROLLFORWARD_DETAIL_LIST_FILTERS_CONFIG
      ).filter((config) => {
        return groupBy === BBCReceivablesGroupBy.Parent || config.field !== 'debtor'
      }),
    [type, groupBy],
  )

  const listFieldsConfig = useMemo(
    () =>
      filtersConfig
        .filter((filter) => ['amount', 'percent', 'date'].includes(filter.type))
        .map((filter) => ({
          field: filter.field,
          fieldType: filter.type,
        })),

    [filtersConfig],
  )

  const debounceFilterList = useMemo(
    () =>
      debounceEventHandler(async (data: any) => {
        !data.loadMore && setIsLoading(true)
        await getDetail(id, {
          ...data,
          [key]: entity,
          recordDate,
          groupBy,
          nestedRows: {
            keys: [key],
          },
          reportingFlow,
          currentDate,
        })
        setIsLoading(false)
      }, 500),
    [id, getDetail, entity, recordDate, groupBy, key, reportingFlow, currentDate],
  )

  useEffect(() => {
    !rollforwardDetail &&
      debounceFilterList({
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
        filters,
        page: 0,
      })
  }, [orderBy, filters, debounceFilterList, rollforwardDetail])

  useEffect(() => {
    debounceFilterList({
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
      page: 0,
    })
  }, [orderBy, filters, debounceFilterList])

  const loadMore = useCallback(() => {
    debounceFilterList({
      loadMore: true,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
      page: Math.ceil(rollforwardDetail?.length / PER_PAGE),
    })
  }, [orderBy, filters, debounceFilterList, rollforwardDetail])

  const totalRow = useMemo(
    () =>
      rollforwardDetail
        ?.map((element, index) => ({ element, index }))
        .filter(({ index }) => activeItems.includes(index))
        .map(({ element }) => element)
        .reduce(
          (totalRowResult, row) => {
            listFieldsConfig.forEach(({ field }) => {
              totalRowResult[field] += +row[field] || 0
            })
            return totalRowResult
          },
          {
            ...listFieldsConfig
              .map(({ field }) => field)
              .reduce(
                (
                  totalRowResult: Partial<IARRollforwardSummary> | Partial<IAPRollforwardSummary>,
                  field: string,
                ) => {
                  totalRowResult[field] = 0

                  return totalRowResult
                },
                {},
              ),
          },
        ),
    [activeItems, rollforwardDetail, listFieldsConfig],
  )

  const totalCount = useMemo(() => rollforwardDetail?.[0]?.totalCount, [rollforwardDetail])

  return (
    <TableContainer
      className={styles.table}
      onActiveRowsChange={setActiveItems}
      onActiveRowChange={setActiveItem}
      hasFooter
      size="small"
    >
      <Table ref={wrapperRef}>
        <TableHead>
          <TableFiltersRow
            filters={filtersConfig}
            orderBy={orderBy}
            handleOrderChange={handleOrderChange}
          />
        </TableHead>
        <TableBody
          id={`scrollable${type}Rollforward-${entity}`}
          className={styles.scrollableTableBody}
        >
          {isLoading ? (
            <TableLoader columnsCount={filtersConfig?.length} />
          ) : (
            <InfiniteScroll
              dataLength={rollforwardDetail?.length || 0}
              next={loadMore}
              hasMore={rollforwardDetail?.length < totalCount}
              loader={<TableLoader columnsCount={filtersConfig?.length} rowsCount={1} />}
              scrollableTarget={`scrollable${type}Rollforward-${entity}`}
            >
              {rollforwardDetail?.map((row, index) => (
                <TableRow
                  key={row.invoice}
                  data-index={index}
                  className={cn('activableRow', {
                    activeRow: activeItems.includes(index),
                    currentActiveRow: activeItem === index,
                  })}
                  onClick={(event) => handleSelectRow(event, index)}
                >
                  {groupBy === BBCReceivablesGroupBy.Parent && (
                    <TableCell className={genericSs.tableTextLeft}>
                      <Tooltip
                        title={(row as IARRollforwardDetail).debtor || ''}
                        placement="top"
                        disableHoverListener={
                          (row as IARRollforwardDetail).debtor?.length < 10 ||
                          !(row as IARRollforwardDetail).debtor
                        }
                        disableTouchListener
                      >
                        <span>{(row as IARRollforwardDetail).debtor}</span>
                      </Tooltip>
                    </TableCell>
                  )}

                  <TableCell className={genericSs.tableTextLeft}>
                    <span>{row.invoice}</span>
                  </TableCell>
                  {listFieldsConfig.map(({ field, fieldType }) => {
                    const value = row[field]
                    const nullCondition = !hasPreviousBBC || !value
                    return (
                      <FormattedTableCell
                        key={field}
                        value={value}
                        fieldType={fieldType}
                        nullCondition={nullCondition}
                      />
                    )
                  })}
                </TableRow>
              ))}
            </InfiniteScroll>
          )}
          <MultiselectRow activeItems={activeItems}>
            {listFieldsConfig.map(({ field, fieldType }) => {
              const value = totalRow?.[field]
              const formattedValue = formatValue({
                value,
                type: fieldType,
                nullCondition: !hasPreviousBBC || !value,
              })
              return (
                <TableCell key={field} className={genericSs.tableTextRight}>
                  {formattedValue}
                </TableCell>
              )
            })}
          </MultiselectRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default BBCRollforwardDetailsTable
