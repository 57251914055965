import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Tooltip from '@mui/material/Tooltip'
import { ErrorCode, FileRejection, useDropzone } from 'react-dropzone'
import { Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import cn from 'classnames'

import styles from './ProspectAnalysisQueuePage.module.scss'

import { ReactComponent as DeleteIcon } from '@assets/images/delete-icon.svg'
import { ReactComponent as WarningIcon } from '@assets/images/warning-icon-outline.svg'
import ProspectHeader from '../../components/ProspectHeader'
import ProspectAnalysisQueueTable from '../../components/ProspectAnalysisQueueTable'
import { ClientInfoStatus } from '@common/interfaces/client'
import {
  IOPSReporting,
  OPSReportingStatus,
  PROSPECT_REPORTING_MAP,
} from '@common/interfaces/prospects'
import { usePrevious } from '../../hooks/usePrevious'
import LinearProgressBar from '../../components/Common/LinearProgressBar'
import { DEFAULT_MAX_FILE_SIZE } from '../../constants/common'
import { humanReadableFileSize } from '@common/helpers/helpers'
import { OngoingReportingType } from '@common/interfaces/bbc'
import Modal from '../../components/Common/Modal'
import Button from '../../components/Common/Button'
import UploadFileManagement from '../../components/Common/UploadFileManagement'
import { voidHandler } from '../../helpers/helpers'
import SelectField from '../../components/Common/SelectField'
import { usePermissions } from '../../helpers/permissionContext'
import { useParams } from 'react-router'

const EMPTY_FILES: any[] = []
const mutators = {
  ...arrayMutators,
}

export const PROSPECT_REPORTING_TYPE_OPTIONS = Object.keys(PROSPECT_REPORTING_MAP)
  .filter((key) => key !== OngoingReportingType.Uncategorized)
  .map((key) => ({
    value: key,
    label: PROSPECT_REPORTING_MAP[key],
  }))

interface IProps {
  reporting: IOPSReporting
  startReporting: (id: string) => void
  updateReporting: (id: string, data: object | FormData) => Promise<any>
  putNotification: (notification: object) => void
  deleteFile: (id: string) => Promise<any>
  refreshCount: number
}

const ProspectAnalysisQueuePage = ({
  reporting,
  startReporting,
  updateReporting,
  putNotification,
  deleteFile,
  refreshCount,
}: IProps) => {
  const [isUploading, setIsUploading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isManageFilesModalOpen, setIsManageFilesModalOpen] = useState(false)
  const [refreshCounter, setRefreshCounter] = useState(1)
  const opsReportingStatus = useMemo(() => reporting?.status, [reporting])
  const previousOPSReportingStatus = usePrevious<OPSReportingStatus>(opsReportingStatus, null)
  const { id } = useParams<{ id: string }>()

  const { isUW } = usePermissions()

  const readOnly = useMemo(
    () =>
      ![ClientInfoStatus.Prospect, ClientInfoStatus.Archived].includes(
        reporting?.clientInfo?.clientStatus,
      ) ||
      reporting?.status === OPSReportingStatus.Archived ||
      isUW,
    [reporting, isUW],
  )

  useEffect(() => {
    if (reporting?.id && !readOnly && reporting.id === id) {
      startReporting(reporting.id)
    }
  }, [reporting?.id, readOnly, startReporting, id])

  useEffect(() => {
    if (
      previousOPSReportingStatus &&
      opsReportingStatus &&
      previousOPSReportingStatus !== opsReportingStatus
    ) {
      setRefreshCounter((counter) => counter + 1)
    }
  }, [previousOPSReportingStatus, opsReportingStatus])

  const handleFileDrop = useCallback(
    async (loadedFiles: File[]) => {
      if (!reporting?.id) {
        return
      }

      const formData = new FormData()

      for (const file of loadedFiles) {
        formData.append('files[]', file, file.name)
      }
      formData.append('type', OngoingReportingType.Uncategorized)

      setIsUploading(true)
      await updateReporting(reporting.id, formData)
      setRefreshCounter((counter) => counter + 1)
      setIsUploading(false)
      setIsManageFilesModalOpen(true)
    },
    [reporting?.id, updateReporting],
  )

  const handleFileRejection = useCallback(
    (fileRejection: FileRejection[]) => {
      const firstError = fileRejection?.[0]?.errors?.[0]
      if (firstError) {
        putNotification({
          code: firstError.code,
          message:
            firstError.code === ErrorCode.FileTooLarge
              ? `File is too large, the maximum file size is ${humanReadableFileSize(
                  DEFAULT_MAX_FILE_SIZE,
                ).join(' ')}`
              : firstError.message,
          type: 'error',
        })
      }
    },
    [putNotification],
  )

  const { getRootProps, getInputProps, open, isDragAccept, isDragReject } = useDropzone({
    noClick: true,
    maxSize: DEFAULT_MAX_FILE_SIZE,
    maxFiles: 99,
    multiple: true,
    noDragEventsBubbling: true,
    onDropAccepted: handleFileDrop,
    onDropRejected: handleFileRejection,
  })

  const {
    getRootProps: getContainerRootProps,
    getInputProps: getContainerInputProps,
    isDragActive,
  } = useDropzone({
    noClick: true,
    maxSize: DEFAULT_MAX_FILE_SIZE,
    maxFiles: 99,
    multiple: true,
    noDragEventsBubbling: true,
    onDropAccepted: handleFileDrop,
    onDropRejected: handleFileRejection,
  })

  const handleOpenManageFilesModal = useCallback(() => {
    setIsManageFilesModalOpen(true)
  }, [])

  const handleToggleManageFiles = useCallback(() => {
    if (!isLoading && !isUploading) {
      setIsManageFilesModalOpen((isOpen) => !isOpen)
    }
  }, [isLoading, isUploading])

  const handleManageFiles = useCallback(
    async (data: { files: { id: string; types: string[] }[] }) => {
      if (!reporting?.id) {
        return
      }

      const mappedFiles = data.files.filter(({ types }) => !!types.length)
      if (!mappedFiles.length) {
        return
      }
      setIsLoading(true)
      await updateReporting(reporting.id, {
        type: OngoingReportingType.Uncategorized,
        uncategorizedFilesMapping: mappedFiles,
      })
      setIsLoading(false)
      setIsManageFilesModalOpen(false)
    },
    [reporting?.id, updateReporting],
  )

  const handleFileDelete = useCallback(
    async (id: string) => {
      if (!reporting?.id) {
        return
      }

      setIsLoading(true)
      await deleteFile(id)
      await startReporting(reporting.id)
      setRefreshCounter((counter) => counter + 1)
      setIsLoading(false)
    },
    [reporting?.id, startReporting, deleteFile],
  )

  const uncategorizedFiles = useMemo(
    () => reporting?.files.filter(({ type }) => type === OngoingReportingType.Uncategorized) || [],
    [reporting],
  )

  const initialValues = useMemo(
    () => ({
      files:
        uncategorizedFiles?.map(({ id }) => ({
          id,
          types: [],
        })) || [],
    }),
    [uncategorizedFiles],
  )

  return (
    <Grid container py={3} pr={2} alignItems={'flex-start'} justifyContent={'center'}>
      <Grid item xs={12}>
        <ProspectHeader />
      </Grid>
      {reporting && (
        <Grid item xs={12} px={3.75} mt={1.5}>
          <div className={styles.dropWrapper} {...getContainerRootProps()}>
            {!readOnly && (
              <div className={styles.manageFilesButtonWrapper}>
                <Button
                  className={cn(styles.manageFilesButton, {
                    [styles.manageFilesButtonWarning]: uncategorizedFiles.length > 0,
                  })}
                  small
                  color="primary"
                  variant="outlined"
                  onClick={handleOpenManageFilesModal}
                  startIcon={uncategorizedFiles.length > 0 && <WarningIcon />}
                >
                  Manage Files
                </Button>
              </div>
            )}
            <ProspectAnalysisQueueTable
              opsReportingId={id}
              refreshCounter={refreshCounter}
              readOnly={readOnly}
            />

            <input {...getContainerInputProps()} />

            {isDragActive && <div className={styles.dropOverlay}>Drop files here</div>}
            {isUploading && !isManageFilesModalOpen && (
              <div className={cn(styles.dropOverlay, styles.uploadOverlay)}>
                <LinearProgressBar />
              </div>
            )}
            {isManageFilesModalOpen && (
              <Modal open title="Manage Files" onCancel={handleToggleManageFiles}>
                <UploadFileManagement
                  isLoading={isUploading}
                  className={styles.uncategorizedFilesUpload}
                  files={EMPTY_FILES}
                  onDelete={voidHandler}
                  setIsModalOpen={handleToggleManageFiles}
                  isDragAccept={isDragAccept}
                  isDragReject={isDragReject}
                  getRootProps={getRootProps}
                  getInputProps={getInputProps}
                  open={open}
                  handleDelete={voidHandler}
                  dropzoneText="Drop files here or "
                />

                <Form
                  onSubmit={handleManageFiles}
                  initialValues={initialValues}
                  mutators={mutators}
                  render={({
                    pristine,
                    handleSubmit,
                  }: {
                    pristine: boolean
                    handleSubmit: any
                  }) => (
                    <>
                      <div className={styles.uncategorizedFilesList}>
                        <FieldArray name="files">
                          {({ fields }) => (
                            <>
                              {fields.map((name, index) => {
                                const file = uncategorizedFiles[index]
                                if (!file) {
                                  return null
                                }

                                return (
                                  <div className={styles.uncategorizedFileItem} key={file.name}>
                                    <div className={styles.uncategorizedFileItemNameContainer}>
                                      <Tooltip title={file.name} placement="top">
                                        <Link
                                          href={file.link}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className={cn(
                                            styles.uncategorizedFileItemName,
                                            styles.link,
                                          )}
                                        >
                                          {file.name}
                                        </Link>
                                      </Tooltip>
                                    </div>
                                    <div className={styles.uncategorizedFileItemTypeContainer}>
                                      <SelectField
                                        name={`${name}.types`}
                                        label="Report type"
                                        variant="outlined"
                                        options={PROSPECT_REPORTING_TYPE_OPTIONS}
                                        multiple
                                        className={styles.uncategorizedFileItemTypeSelectField}
                                        defaultValue=""
                                      />
                                    </div>
                                    <div className={styles.uncategorizedFileItemDeleteContainer}>
                                      <DeleteIcon onClick={() => handleFileDelete(file.id)} />
                                    </div>
                                  </div>
                                )
                              })}
                            </>
                          )}
                        </FieldArray>
                      </div>

                      <div className={styles.uncategorizedFilesButtonContainer}>
                        <Button
                          key="submit"
                          color="primary"
                          variant="contained"
                          fullWidth
                          small={false}
                          isLoading={isLoading}
                          disabled={pristine || isUploading}
                          onClick={handleSubmit}
                        >
                          Confirm
                        </Button>
                      </div>
                    </>
                  )}
                />
              </Modal>
            )}
          </div>
        </Grid>
      )}
    </Grid>
  )
}

export default ProspectAnalysisQueuePage
