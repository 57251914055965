import React, { useCallback, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { Box, InputLabel } from '@mui/material'
import cn from 'classnames'
import Modal from '../../Common/Modal'
import Button from '../../Common/Button'
import CreatableSelectField from '../../Common/CreatableSelectField'
import SelectField, { IOption } from '../../Common/SelectField'
import TextField from '../../Common/TextField'
import KeyboardDatePicker from '../../Common/KeyboardDatePicker'
import CurrencyField from '../../Common/CurrencyField'
import { CollectionType } from '@common/interfaces/collection'
import styles from '../CollectionAggregationRow.module.scss'
import genericSs from '@styles/generic.module.scss'
import { IBankAccount } from '@common/interfaces/bankAccount'
import { IClientInfo } from '@common/interfaces/client'
import * as Yup from 'yup'
import { makeValidate } from 'mui-rff'
import { DATE_FORMAT } from '../../../constants/common'
import moment from 'moment'

interface IEditFormProps {
  isEditModalShown: boolean
  selectedItem: any
  loadDebtors: (inputValue: string) => Promise<any>
  addEntityInfo: (data: object) => Promise<any>
  updateAggregationRow: (id: string, data: object) => void
  clientsData: IClientInfo[]
  banksAccountData: IBankAccount[]
  loanBalanceStartDate: string
  handleCloseMenu: () => void
  setSelectedItem: (item: any) => void
  setIsEditModalShown: (isShown: boolean) => void
  refetchAggregationList: () => void
}

const EditForm: React.FC<IEditFormProps> = ({
  isEditModalShown,
  selectedItem,
  loadDebtors,
  addEntityInfo,
  clientsData,
  banksAccountData,
  loanBalanceStartDate,
  updateAggregationRow,
  handleCloseMenu,
  setSelectedItem,
  setIsEditModalShown,
  refetchAggregationList,
}) => {
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const itemValidate = useMemo(() => {
    const itemSchema = Yup.object().shape({
      [selectedItem?.paymentType === CollectionType.Wire ? 'debtor' : 'checkAccount']: Yup.object()
        .typeError('Customer is required')
        .required('Customer is required'),
      clientName: Yup.string().typeError('Client is required').required('Client is required'),
    })

    return makeValidate(itemSchema)
  }, [selectedItem])

  const selectedItemBankAccountId = useMemo(() => {
    return banksAccountData?.find(
      ({ bankAccountNumber }) => bankAccountNumber === selectedItem?.accountNumber,
    )?.id
  }, [banksAccountData, selectedItem])

  const itemInitialValues = useMemo(
    () =>
      selectedItem
        ? {
            ...selectedItem,
            debtor: selectedItem.debtor
              ? {
                  value: selectedItem.debtor || '',
                  label: selectedItem.debtor || '',
                }
              : null,
            checkAccount: selectedItem.debtor
              ? {
                  value: selectedItem.debtor || '',
                  label: selectedItem.debtor || '',
                }
              : null,
            amount: selectedItem.amount || 0,
            recordDate: new Date(selectedItem.recordDate),
            bankAccountId: selectedItemBankAccountId,
          }
        : {},
    [selectedItem, selectedItemBankAccountId],
  )

  const clientNamesOptions: IOption[] = useMemo(
    () =>
      clientsData?.map(({ clientName }) => ({
        value: clientName,
        label: clientName,
      })),
    [clientsData],
  )

  const clientBanksOptions: IOption[] = useMemo(
    () =>
      banksAccountData?.map(({ id, bankName, bankAccountNumber }) => ({
        value: id,
        label: `${bankName} - ${bankAccountNumber.trim().slice(-4)}`,
      })),
    [banksAccountData],
  )

  const handleAddDebtor = useCallback(
    async (debtor: string) =>
      addEntityInfo({ name: debtor, type: 'debtor', clientName: selectedItem.clientName }),
    [addEntityInfo, selectedItem],
  )

  const handleEditCancel = useCallback(() => {
    setSelectedItem(null)
    handleCloseMenu()
    setIsEditModalShown(false)
  }, [handleCloseMenu, setSelectedItem, setIsEditModalShown])

  const handleEditConfirm = useCallback(
    async (data) => {
      setIsButtonLoading(true)
      await updateAggregationRow(
        selectedItem.id,
        selectedItem.paymentType === CollectionType.Check
          ? {
              newCheckInfo: {
                clientName: data.clientName,
                debtor: data.debtor?.value,
                recordDate: data.recordDate,
                checkNumber: data.checkNumber,
                accountNumber: data.accountNumber,
                routingNumber: data.routingNumber,
                boxLink: data.boxLink,
                amount: data.amount,
              },
            }
          : {
              newWireInfo: {
                debtor: data.debtor?.value,
                bankAccountId: data.bankAccountId,
                recordDate: data.recordDate,
                amount: data.amount,
              },
            },
      )
      await refetchAggregationList()
      setIsButtonLoading(false)
      handleCloseMenu()
      setIsEditModalShown(false)
    },
    [
      updateAggregationRow,
      selectedItem,
      refetchAggregationList,
      handleCloseMenu,
      setIsEditModalShown,
    ],
  )

  return (
    <Form
      onSubmit={handleEditConfirm}
      validate={itemValidate}
      initialValues={itemInitialValues}
      render={({
        pristine,
        invalid,
        handleSubmit,
      }: {
        pristine: boolean
        invalid: boolean
        handleSubmit: any
      }) => (
        <Modal
          open={isEditModalShown}
          onCancel={handleEditCancel}
          title={`Edit ${selectedItem.paymentType} Mapping`}
          footer={null}
          size="small"
        >
          <div>
            {selectedItem.paymentType === CollectionType.Wire ? (
              <>
                <Box flex={1}>
                  <InputLabel htmlFor="debtor" className={genericSs.textLeft}>
                    Debtor
                  </InputLabel>
                  <CreatableSelectField
                    className={styles.creatableSelectFormField}
                    name="debtor"
                    placeholder="Debtor"
                    onAddValue={handleAddDebtor}
                    options={[]}
                    isAsync
                    loadOptions={loadDebtors}
                    height="large"
                    withBorder
                  />
                </Box>
                <Box flex={1}>
                  <InputLabel
                    htmlFor="bankAccountId"
                    className={cn(genericSs.textLeft, styles.label)}
                  >
                    Bank Account
                  </InputLabel>
                  <SelectField
                    className={styles.selectFormField}
                    id="bankAccountId"
                    name="bankAccountId"
                    options={clientBanksOptions || []}
                    placeholder="Bank Account"
                    selectSize="large"
                  />
                </Box>
              </>
            ) : (
              <>
                <Box flex={1}>
                  <InputLabel htmlFor="clientName" className={genericSs.textLeft}>
                    Client name
                  </InputLabel>
                  <SelectField
                    className={styles.selectFormField}
                    id="clientName"
                    name="clientName"
                    options={clientNamesOptions || []}
                    placeholder="Client name"
                    selectSize="large"
                  />
                </Box>
                <Box display="flex" flexDirection="row" flexWrap="wrap" rowGap={1}>
                  <Box flex={1} marginRight={2}>
                    <InputLabel htmlFor="debtor" className={cn(genericSs.textLeft, styles.label)}>
                      Debtor
                    </InputLabel>
                    <CreatableSelectField
                      className={styles.creatableSelectFormField}
                      name="debtor"
                      placeholder="Debtor"
                      onAddValue={handleAddDebtor}
                      options={[]}
                      isAsync
                      loadOptions={loadDebtors}
                      height="large"
                      withBorder
                    />
                    <InputLabel
                      htmlFor="accountNumber"
                      className={cn(genericSs.textLeft, styles.label)}
                    >
                      Account number
                    </InputLabel>
                    <TextField
                      className={styles.textFormField}
                      id="accountNumber"
                      name="accountNumber"
                      placeholder="Enter account number"
                      InputProps={{
                        type: 'number',
                      }}
                    />
                    <InputLabel
                      htmlFor="routingNumber"
                      className={cn(genericSs.textLeft, styles.label)}
                    >
                      Routing number
                    </InputLabel>
                    <TextField
                      className={styles.textFormField}
                      id="routingNumber"
                      name="routingNumber"
                      placeholder="Enter routing number"
                      InputProps={{
                        type: 'number',
                      }}
                    />
                  </Box>
                  <Box flex={1}>
                    <InputLabel
                      htmlFor="checkNumber"
                      className={cn(genericSs.textLeft, styles.label)}
                    >
                      Check number
                    </InputLabel>
                    <TextField
                      className={styles.textFormField}
                      id="checkNumber"
                      name="checkNumber"
                      placeholder="Enter check number"
                      InputProps={{
                        type: 'number',
                      }}
                    />
                    <InputLabel htmlFor="boxLink" className={cn(genericSs.textLeft, styles.label)}>
                      Box link
                    </InputLabel>
                    <TextField
                      className={styles.textFormField}
                      id="boxLink"
                      name="boxLink"
                      placeholder="Enter box link"
                      InputProps={{
                        type: 'text',
                      }}
                    />
                  </Box>
                </Box>
              </>
            )}
            <>
              <Box flex={1}>
                <InputLabel htmlFor="recordDate" className={cn(genericSs.textLeft, styles.label)}>
                  Date
                </InputLabel>
                <KeyboardDatePicker
                  className={styles.dateFormField}
                  name="recordDate"
                  inputFormat={DATE_FORMAT}
                  minDate={moment(loanBalanceStartDate || undefined).toDate()}
                  placeholder="Select date"
                />
              </Box>

              <Box flex={1}>
                <InputLabel htmlFor="amount" className={cn(genericSs.textLeft, styles.label)}>
                  Amount
                </InputLabel>
                <CurrencyField
                  id="amount"
                  name="amount"
                  placeholder="Enter Amount"
                  className={styles.textFormField}
                />
              </Box>
            </>
          </div>
          <Box
            mt={2}
            className={styles.editRowModalFooter}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button
              key="submit"
              color="primary"
              variant="contained"
              onClick={handleSubmit}
              disabled={invalid || pristine}
              className={styles.submitModalButton}
              small={false}
              isLoading={isButtonLoading}
            >
              Submit
            </Button>
          </Box>
        </Modal>
      )}
    />
  )
}

export default EditForm
