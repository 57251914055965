import React, { useCallback, useEffect, useMemo, useState } from 'react'
import genericSs from '@styles/generic.module.scss'
import { debounceEventHandler, formatPercent, formatPrice } from '../../helpers/helpers'
import { IFinancialsComparisonData } from '@common/interfaces/client'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TableContainer from '../Common/TableContainer'
import TableBody from '../Common/TableBody'
import cn from 'classnames'
import Box from '@mui/material/Box'
import { ILoadingData } from '../../redux/types'
import { FINANCIALS_COMPARISON_LIST_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import styles from './FinancialsComparison.module.scss'
import { Form } from 'react-final-form'
import FilterContainer from '../Filters/FilterContainer'
import TableFiltersRow from '../Common/TableFiltersRow'
import TableLoader from '../Common/TableLoader'
import InfiniteScroll from 'react-infinite-scroll-component'
import LinkButton from '@mui/material/Link'
import { Link, generatePath } from 'react-router-dom'
import useTable from '../../hooks/useTable'
import { ROUTES } from '../../constants/routes'
import moment from 'moment'
import TableFooter from '../Common/TableFooter'
import {
  CLIENT_FINANCIALS_FIELDS,
  CLIENT_FINANCIALS_FIELDS_MAPPING,
  CLIENT_FINANCIALS_PERCENT_FIELDS,
  CLIENT_FINANCIALS_RATIO_FIELDS,
  FinancialKeys,
} from '@common/interfaces/bbc'
import DatePicker from '../Common/DatePicker'
import { DATE_DATABASE_FORMAT } from '../../constants/common'
import Autocomplete from '../Common/Autocomplete'
import useSummaryRow from '../../hooks/useSummaryRow'
import MultiselectRow from '../MultiselectRow'
import MultipleTableCells from '../Common/MultipleTableCells'

const filtersValidate = buildFiltersValidateSchema(FINANCIALS_COMPARISON_LIST_FILTERS_CONFIG)
const filtersDefaults = buildFiltersDefaults(FINANCIALS_COMPARISON_LIST_FILTERS_CONFIG)

const lastFinancialMonth =
  moment().date() > 25 ? moment().subtract(1, 'month') : moment().subtract(2, 'month')

const METRIC_2_FIELDS = [
  'metric2',
  'metric2Projection',
  'metric2Difference',
  'metric2DifferencePercentage',
]
const metricOptions = [
  ...CLIENT_FINANCIALS_FIELDS.IncomeStatement,
  ...CLIENT_FINANCIALS_FIELDS.BalanceSheet,
].map((key) => ({
  value: key,
  label: CLIENT_FINANCIALS_FIELDS_MAPPING[key],
}))

const formatMetric = (metric: FinancialKeys, value: number) => {
  if (CLIENT_FINANCIALS_PERCENT_FIELDS.includes(metric)) {
    return value ? formatPercent(value) : '-'
  } else if (CLIENT_FINANCIALS_RATIO_FIELDS.includes(metric)) {
    return value ? `${value?.toFixed(2)}x` : '-'
  }
  return value ? `$${formatPrice(value, 0)}` : '-'
}

const DIFFERENCE_COLOR_MAPPING = [
  { min: 0, max: null, colors: { backgroundColor: '#66B48540', color: '#66B485' } },
  { min: -0.2, max: 0, colors: { backgroundColor: '#EEFFEB', color: '#8DB43C' } },
  { min: -0.4, max: -0.2, colors: { backgroundColor: '#FFCB001A', color: '#765E00' } },
  { min: -0.6, max: -0.4, colors: { backgroundColor: '#FBF0E0', color: '#E78B0A' } },
  { min: -0.8, max: -0.6, colors: { backgroundColor: '#E539353D', color: '#FF4141' } },
  { min: null, max: -0.8, colors: { backgroundColor: '#FFA7A7', color: '#761818' } },
  { min: null, max: null, colors: { backgroundColor: '#EDF2F7', color: '#718096' } },
]

const getColorForRating = (rating: number | null) => {
  if (!rating) {
    return DIFFERENCE_COLOR_MAPPING.find((mapping) => mapping.min === null && mapping.max === null)
      .colors
  }
  const mapping = DIFFERENCE_COLOR_MAPPING.find(
    (mapping) =>
      (mapping.min === null || rating >= mapping.min) &&
      (mapping.max === null || rating < mapping.max),
  )
  return mapping ? mapping.colors : {}
}

interface IProps {
  listFinancialsComparison: (params?: object) => void
  financialsComparisonData: ILoadingData<IFinancialsComparisonData>
}

const FinancialsComparison = ({ financialsComparisonData, listFinancialsComparison }: IProps) => {
  const [currentMetrics, setCurrentMetrics] = useState<
    {
      value: FinancialKeys
      label: string
    }[]
  >([
    {
      value: 'net_revenue',
      label: 'Net Revenue',
    },
  ])

  const [currentDateRange, setCurrentDateRange] = useState<{ startDate: string; endDate: string }>({
    startDate: moment(lastFinancialMonth).subtract(1, 'year').format(DATE_DATABASE_FORMAT),
    endDate: lastFinancialMonth.format(DATE_DATABASE_FORMAT),
  })

  const {
    filters,
    orderBy,
    handleFiltersChange,
    handleOrderChange,
    activeItems,
    setActiveItems,
    handleSelectRow,
    activeItem,
    setActiveItem,
  } = useTable({
    tableId: 'financialsComparison',
    filtersDefaults,
    sortDefault: {
      field: 'metric1',
      direction: 'DESC',
    },
  })

  const handleMetricChange = useCallback(
    (event, newValue) => {
      if (currentMetrics.length === 2 && newValue?.length === 3) {
        setCurrentMetrics(newValue?.slice(1, 3))
      } else if (currentMetrics.length === 1 && newValue?.length === 0) {
        setCurrentMetrics(currentMetrics)
      } else {
        setCurrentMetrics(newValue)
      }
    },
    [currentMetrics],
  )

  useEffect(() => {
    if (currentMetrics.length === 1 && orderBy.field.startsWith('metric2')) {
      handleOrderChange(orderBy.field.replace('metric2', 'metric1'), orderBy.direction)
    }
  }, [currentMetrics, orderBy.field, orderBy.direction, handleOrderChange])

  const {
    data: financialComparison,
    isLoading,
    totalItems,
    totals,
    industries,
  } = useMemo(() => {
    return {
      data: financialsComparisonData?.data?.data,
      isLoading: financialsComparisonData?.isLoading,
      totalItems: financialsComparisonData?.data?.totals?.totalCount,
      totals: financialsComparisonData?.data?.totals,
      industries: financialsComparisonData?.data?.industries,
    }
  }, [financialsComparisonData])

  const debounceFilterList = useMemo(
    () =>
      debounceEventHandler((data: object) => {
        listFinancialsComparison(data)
      }, 500),
    [listFinancialsComparison],
  )

  useEffect(() => {
    currentMetrics?.length > 0 &&
      currentDateRange.startDate &&
      currentDateRange.endDate &&
      debounceFilterList({
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
        metrics: currentMetrics.map((metric) => metric.value),
        filters,
        startDate: currentDateRange.startDate,
        endDate: currentDateRange.endDate,
      })
  }, [orderBy, filters, debounceFilterList, currentMetrics, currentDateRange])

  const loadMore = useCallback(() => {
    debounceFilterList({
      loadMore: true,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
      metrics: currentMetrics.map((metric) => metric.value),
      startDate: currentDateRange.startDate,
      endDate: currentDateRange.endDate,
      page: Math.ceil(financialComparison?.length / PER_PAGE),
    })
  }, [orderBy, filters, debounceFilterList, currentMetrics, financialComparison, currentDateRange])

  const areBothMetricsSelected = useMemo(() => currentMetrics?.length === 2, [currentMetrics])

  const filterConfig = useMemo(() => {
    const baseFilter = areBothMetricsSelected
      ? FINANCIALS_COMPARISON_LIST_FILTERS_CONFIG
      : FINANCIALS_COMPARISON_LIST_FILTERS_CONFIG.filter(
          (filter) => !METRIC_2_FIELDS.includes(filter.field),
        )

    return baseFilter?.map((filter) => {
      if (filter.field === 'industry') {
        return {
          ...filter,
          options: industries?.map((industry) => ({
            value: industry,
            label: industry,
          })),
        }
      } else if (filter?.field?.startsWith('metric1')) {
        return {
          ...filter,
          title: filter.title.replace(
            'Metric 1',
            CLIENT_FINANCIALS_FIELDS_MAPPING[currentMetrics[0]?.value],
          ),
        }
      } else if (filter?.field?.startsWith('metric2')) {
        return {
          ...filter,
          title: filter.title.replace(
            'Metric 2',
            CLIENT_FINANCIALS_FIELDS_MAPPING[currentMetrics[1]?.value],
          ),
        }
      }
      return filter
    })
  }, [areBothMetricsSelected, currentMetrics, industries])

  const handleDateChange = useCallback(
    (values: { startDate: string; endDate: string }) => {
      setCurrentDateRange(values)
    },
    [setCurrentDateRange],
  )

  const sumFields = useMemo(() => {
    const baseFields = ['metric1', 'metric1Projection', 'metric1Difference']
    return areBothMetricsSelected
      ? [...baseFields, 'metric2', 'metric2Projection', 'metric2Difference']
      : baseFields
  }, [areBothMetricsSelected])

  const totalRow = useSummaryRow(financialComparison, activeItems, {
    sumFields: sumFields as any,
    averageFields: ['metric1DifferencePercentage', 'metric2DifferencePercentage'],
  })

  return (
    <TableContainer
      className={styles.table}
      onActiveRowsChange={setActiveItems}
      onActiveRowChange={setActiveItem}
    >
      <Form
        validate={filtersValidate}
        onSubmit={handleFiltersChange}
        initialValues={filters}
        mutators={{
          setFieldData: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value)
          },
        }}
        render={({ values, handleSubmit, form: { mutators } }) => (
          <FilterContainer
            filters={filterConfig}
            handleSubmit={handleSubmit}
            mutators={mutators}
            values={values}
            appliedFilters={filters}
            actions={
              <Box display="flex" alignItems="center" gap={1}>
                <Autocomplete
                  withTopLabel
                  label="Select Metric"
                  className={styles.selectField}
                  value={currentMetrics as any}
                  name="currentMetrics"
                  options={metricOptions}
                  getOptionLabel={(option: any) => option?.label || option || ''}
                  getOptionValue={(option: any) => option?.value || option || ''}
                  onChange={handleMetricChange}
                  defaultValue=""
                  //@ts-ignore
                  multiple
                  hideTags
                  disableClearable
                />
                <DatePicker
                  label="Date Range"
                  currentDateRange={currentDateRange}
                  onChange={handleDateChange}
                  datesBoundary={{
                    minDate: '',
                    maxDate: moment().format(DATE_DATABASE_FORMAT),
                  }}
                />
              </Box>
            }
          />
        )}
      />
      <Table>
        <TableHead>
          <TableFiltersRow
            filters={filterConfig}
            orderBy={orderBy}
            handleOrderChange={handleOrderChange}
            isChildrenAtStart
          ></TableFiltersRow>
        </TableHead>
        <TableBody id="scrollableTableDetails">
          {isLoading ? (
            <TableLoader columnsCount={filterConfig.length} />
          ) : (
            <InfiniteScroll
              dataLength={financialComparison?.length || 0}
              next={loadMore}
              hasMore={financialComparison?.length < totalItems}
              loader={<TableLoader columnsCount={filterConfig.length} rowsCount={1} />}
              scrollableTarget={`scrollableTableDetails`}
            >
              {financialComparison?.map((item, index) => {
                const metric1ActualAndProjection = item.metric1 && item.metric1Projection
                const metric2ActualAndProjection = item.metric2 && item.metric2Projection
                return (
                  <TableRow
                    key={`${item.clientId}-${index}`}
                    isActiveRow={activeItems.includes(index)}
                    isCurrentActiveRow={activeItem === index}
                    index={index}
                    onSelectRow={handleSelectRow}
                  >
                    <TableCell className={genericSs.tableTextLeft}>
                      <LinkButton
                        component={Link}
                        to={generatePath(ROUTES.CLIENT_PAGE_FINANCIALS_TAB, {
                          id: item?.clientId,
                        })}
                      >
                        {item?.clientName}
                      </LinkButton>
                    </TableCell>

                    <TableCell className={genericSs.tableTextLeft}>{item.industry}</TableCell>

                    <TableCell className={genericSs.tableTextRight}>
                      {item.riskRating ? item.riskRating?.toFixed(2) : '-'}
                    </TableCell>

                    <TableCell className={genericSs.tableTextRight}>
                      {formatMetric(currentMetrics[0]?.value, item.metric1)}
                    </TableCell>

                    <TableCell className={genericSs.tableTextRight}>
                      {formatMetric(currentMetrics[0]?.value, item.metric1Projection)}
                    </TableCell>

                    <TableCell className={genericSs.tableTextRight}>
                      {metric1ActualAndProjection
                        ? formatMetric(currentMetrics[0]?.value, item.metric1Difference)
                        : '-'}
                    </TableCell>

                    <TableCell className={genericSs.tableTextRight}>
                      {metric1ActualAndProjection ? (
                        <div
                          style={getColorForRating(item.metric1DifferencePercentage)}
                          className={cn(styles.ratingTag)}
                        >
                          {formatPercent(item.metric1DifferencePercentage)}
                        </div>
                      ) : (
                        '-'
                      )}
                    </TableCell>
                    {areBothMetricsSelected && (
                      <>
                        <TableCell className={genericSs.tableTextRight}>
                          {formatMetric(currentMetrics[1]?.value, item.metric2)}
                        </TableCell>

                        <TableCell className={genericSs.tableTextRight}>
                          {formatMetric(currentMetrics[1]?.value, item.metric2Projection)}
                        </TableCell>

                        <TableCell className={genericSs.tableTextRight}>
                          {metric2ActualAndProjection
                            ? formatMetric(currentMetrics[1]?.value, item.metric2Difference)
                            : '-'}
                        </TableCell>

                        <TableCell className={genericSs.tableTextRight}>
                          {metric2ActualAndProjection ? (
                            <div
                              style={getColorForRating(item.metric2DifferencePercentage)}
                              className={cn(styles.ratingTag)}
                            >
                              {formatPercent(item.metric2DifferencePercentage)}
                            </div>
                          ) : (
                            '-'
                          )}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                )
              })}
            </InfiniteScroll>
          )}
          <MultiselectRow activeItems={activeItems}>
            <MultipleTableCells count={2} />
            <TableCell className={genericSs.tableTextRight}>
              {formatMetric(currentMetrics[0]?.value, totalRow?.metric1)}
            </TableCell>
            <TableCell className={genericSs.tableTextRight}>
              {formatMetric(currentMetrics[0]?.value, totalRow?.metric1Projection)}
            </TableCell>
            <TableCell className={genericSs.tableTextRight}>
              {formatMetric(currentMetrics[0]?.value, totalRow?.metric1Difference)}
            </TableCell>
            <TableCell className={genericSs.tableTextRight}>
              {formatPercent(totalRow?.metric1DifferencePercentage)}
            </TableCell>
            {areBothMetricsSelected && (
              <>
                <TableCell className={genericSs.tableTextRight}>
                  {formatMetric(currentMetrics[1]?.value, totalRow?.metric2)}
                </TableCell>
                <TableCell className={genericSs.tableTextRight}>
                  {formatMetric(currentMetrics[1]?.value, totalRow?.metric2Projection)}
                </TableCell>
                <TableCell className={genericSs.tableTextRight}>
                  {formatMetric(currentMetrics[1]?.value, totalRow?.metric2Difference)}
                </TableCell>
                <TableCell className={genericSs.tableTextRight}>
                  {formatPercent(totalRow?.metric2DifferencePercentage)}
                </TableCell>
              </>
            )}
          </MultiselectRow>
        </TableBody>
        <TableFooter>
          {totals && (
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell className={genericSs.tableTextRight}>
                {formatMetric(currentMetrics[0]?.value, totals.metric1Total)}
              </TableCell>
              <TableCell className={genericSs.tableTextRight}>
                {formatMetric(currentMetrics[0]?.value, totals.metric1ProjectionTotal)}
              </TableCell>
              <TableCell className={genericSs.tableTextRight}>
                {formatMetric(currentMetrics[0]?.value, totals.metric1DifferenceTotal)}
              </TableCell>
              <TableCell className={genericSs.tableTextRight}>
                {formatPercent(totals.metric1DifferencePercentageTotal)}
              </TableCell>

              {areBothMetricsSelected && (
                <>
                  <TableCell className={genericSs.tableTextRight}>
                    {formatMetric(currentMetrics[1]?.value, totals.metric2Total)}
                  </TableCell>
                  <TableCell className={genericSs.tableTextRight}>
                    {formatMetric(currentMetrics[1]?.value, totals.metric2ProjectionTotal)}
                  </TableCell>
                  <TableCell className={genericSs.tableTextRight}>
                    {formatMetric(currentMetrics[1]?.value, totals.metric2DifferenceTotal)}
                  </TableCell>
                  <TableCell className={genericSs.tableTextRight}>
                    {formatPercent(totals.metric2DifferencePercentageTotal)}
                  </TableCell>
                </>
              )}
            </TableRow>
          )}

          <div className={styles.footer}>
            <div className={styles.keyTitle}>Key:</div>

            <div className={cn(styles.keyItem)} style={getColorForRating(1)}>
              {' '}
              {'>'}0%
            </div>
            <div className={cn(styles.keyItem)} style={getColorForRating(-0.2)}>
              {'<'}0%
            </div>
            <div className={cn(styles.keyItem)} style={getColorForRating(-0.4)}>
              {'<'}-20%
            </div>
            <div className={cn(styles.keyItem)} style={getColorForRating(-0.6)}>
              {'<'}-40%
            </div>
            <div className={cn(styles.keyItem)} style={getColorForRating(-0.8)}>
              {'<'}-60%
            </div>
            <div className={cn(styles.keyItem)} style={getColorForRating(-1)}>
              {'<'}-80%
            </div>
          </div>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}

export default FinancialsComparison
