import CheckAliases from './CheckAliases'
import { checkAliases } from '../../../redux/clientInfo/selectors'
import { listCheckAliases, updateCheckAliases } from '../../../redux/clientInfo/actions'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
const selector = createStructuredSelector({
  checkAliases,
})

const actions = {
  listCheckAliases,
  updateCheckAliases,
}

export default connect(selector, actions)(CheckAliases)
