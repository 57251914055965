import React, { useEffect, useCallback, useMemo } from 'react'
import { useParams, Link, generatePath } from 'react-router-dom'
import { Form } from 'react-final-form'
import LinkButton from '@mui/material/Link'
import cn from 'classnames'
import InfiniteScroll from 'react-infinite-scroll-component'

import styles from './EntityActivityTable.module.scss'
import genericSs from '@styles/generic.module.scss'

import MultiselectRow from '../MultiselectRow'
import Card from '../Common/Card'
import {
  debounceEventHandler,
  formatPrice,
  dateToString,
  formatDate,
  formatter,
} from '../../helpers/helpers'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TableFiltersRow from '../Common/TableFiltersRow'
import { ENTITY_ACTIVITY_LIST_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import { IEntityActivityData } from '@common/interfaces/entityInfo'
import { ROUTES } from '../../constants/routes'
import { ReactComponent as LinkIcon } from '../../assets/images/link-out-icon.svg'
import FilterContainer from '../Filters/FilterContainer'
import { ILoadingData } from '../../redux/types'
import TableLoader from '../Common/TableLoader'
import useSummaryRow from '../../hooks/useSummaryRow'
import useTable from '../../hooks/useTable'

const filtersValidate = buildFiltersValidateSchema(ENTITY_ACTIVITY_LIST_FILTERS_CONFIG)
const filtersDefaults = buildFiltersDefaults(ENTITY_ACTIVITY_LIST_FILTERS_CONFIG)

interface IProps {
  getEntityActivity: (id: string, filters: any) => Promise<any>
  entityActivityData: ILoadingData<IEntityActivityData>
}

const EntityActivityTable = ({ getEntityActivity, entityActivityData }: IProps) => {
  const { isLoading, entityActivity } = useMemo(
    () => ({
      isLoading: entityActivityData.isLoading,
      entityActivity: entityActivityData.data,
    }),
    [entityActivityData],
  )
  const { id } = useParams<{ id: string }>()

  const {
    orderBy,
    filters,
    activeItem,
    activeItems,
    setActiveItem,
    setActiveItems,
    handleSelectRow,
    handleFiltersChange,
    handleOrderChange,
  } = useTable({
    tableId: 'entityActivityTable',
    filtersDefaults,
    sortDefault: {
      field: 'recordDate',
      direction: 'DESC',
    },
  })

  const fetchEntityActivityList = useCallback(
    (data: any) => {
      const params = {
        ...data,
        filters: {
          ...data.filters,
        },
      }
      if (params?.filters.recordDateFrom && typeof params.filters.recordDateFrom !== 'string') {
        params.filters.recordDateFrom = dateToString(params.filters.recordDateFrom)
      }
      if (params?.filters.recordDateTo && typeof params.filters.recordDateTo !== 'string') {
        params.filters.recordDateTo = dateToString(params.filters.recordDateTo)
      }

      id && getEntityActivity(id, params)
    },
    [getEntityActivity, id],
  )
  const handleItemClick = useCallback((item: any) => {
    if (item.boxLink) {
      window.open(item.boxLink, '_blank')
    }
  }, [])

  const debounceListEntityActivity = useMemo(
    () => debounceEventHandler(fetchEntityActivityList, 500),
    [fetchEntityActivityList],
  )

  useEffect(() => {
    debounceListEntityActivity({
      page: 0,
      perPage: PER_PAGE,
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [filters, orderBy, debounceListEntityActivity])

  const totalRow = useSummaryRow(entityActivity?.data, activeItems, {
    sumFields: ['amount'],
  })

  const loadMore = useCallback(() => {
    fetchEntityActivityList({
      loadMore: true,
      page: Math.ceil(entityActivity?.data.length / PER_PAGE),
      perPage: PER_PAGE,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
  }, [entityActivity, orderBy, filters, fetchEntityActivityList])

  const filtersConfig = useMemo(
    () =>
      ENTITY_ACTIVITY_LIST_FILTERS_CONFIG.map((item) => ({
        ...item,
        options:
          item.field === 'clientName'
            ? entityActivity?.clients?.map((clientName) => ({
                value: clientName,
                label: clientName,
              }))
            : item.options,
      })),
    [entityActivity],
  )

  return (
    <Card noHeaderMargin>
      <TableContainer
        className={styles.table}
        onActiveRowsChange={setActiveItems}
        onActiveRowChange={setActiveItem}
      >
        <Form
          validate={filtersValidate}
          onSubmit={handleFiltersChange}
          initialValues={filters}
          mutators={{
            setFieldData: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value)
            },
          }}
          render={({ values, handleSubmit, form: { mutators } }) => (
            <FilterContainer
              filters={filtersConfig}
              handleSubmit={handleSubmit}
              mutators={mutators}
              values={values}
              appliedFilters={filters}
              title="Account Activity"
            />
          )}
        />
        <Table>
          <TableHead>
            <TableFiltersRow
              filters={filtersConfig}
              orderBy={orderBy}
              handleOrderChange={handleOrderChange}
            />
          </TableHead>
          <TableBody id="scrollableTableNegative">
            {isLoading ? (
              <TableLoader columnsCount={5} />
            ) : (
              entityActivity?.data &&
              entityActivity.data.length > 0 && (
                <InfiniteScroll
                  dataLength={entityActivity?.data.length}
                  next={loadMore}
                  hasMore={entityActivity?.data.length < entityActivity?.totalCount}
                  loader={<TableLoader columnsCount={5} rowsCount={1} />}
                  scrollableTarget="scrollableTableNegative"
                >
                  {entityActivity.data.map((item, index) => (
                    <TableRow
                      key={index}
                      data-index={index}
                      className={cn('activableRow', {
                        activeRow: activeItems.includes(index),
                        currentActiveRow: activeItem === index,
                      })}
                      onClick={(event) => handleSelectRow(event, index)}
                    >
                      <TableCell className={genericSs.tableTextRight}>
                        {formatDate(item.recordDate)}
                      </TableCell>
                      <TableCell className={genericSs.tableTextLeft}>
                        <LinkButton
                          component={Link}
                          to={generatePath(ROUTES.CLIENT_PAGE, {
                            id: item.clientId,
                          })}
                        >
                          {item.clientName}
                        </LinkButton>
                      </TableCell>
                      <TableCell className={genericSs.tableTextLeft}>
                        {item?.boxLink ? (
                          <LinkButton
                            className={styles.aggregationTableType}
                            onClick={() => handleItemClick(item)}
                          >
                            {item.type}
                            <LinkIcon className={styles.linkIcon} />
                          </LinkButton>
                        ) : (
                          item.type
                        )}
                      </TableCell>
                      <TableCell className={genericSs.tableTextLeft}>{item.invoice}</TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        <span className={genericSs.pricePrefix}>$</span>
                        {formatPrice(item.amount)}
                      </TableCell>
                    </TableRow>
                  ))}
                </InfiniteScroll>
              )
            )}
            <MultiselectRow activeItems={activeItems} className={styles.activeToolbar}>
              <TableCell className={genericSs.tableTextRight}>
                {formatter.format(totalRow?.amount)}
              </TableCell>
            </MultiselectRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  )
}

export default EntityActivityTable
