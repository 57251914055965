import { IRootState } from '../reducer'
import { prefix } from './actions'
import { IChatHistory, ICurrentResponse } from '@common/interfaces/atlasai'

// Get the state slice
const getAtlasAIState = (state: IRootState) => state[prefix]

export const currentResponse = (state: IRootState): ICurrentResponse =>
  getAtlasAIState(state).currentResponse

export const chatHistory = (state: IRootState): IChatHistory | null =>
  getAtlasAIState(state).chatHistory

export const sessionId = (state: IRootState): string | null =>
  getAtlasAIState(state).selectedSession.data?.session?.id || null

export const isAtlasAILoading = (state: IRootState): boolean => getAtlasAIState(state).isLoading

// UI state
export const isChatVisible = (state: IRootState) => getAtlasAIState(state).isChatVisible

// Current chat state
export const isLoading = (state: IRootState) => getAtlasAIState(state).isLoading
export const isLoadingMore = (state: IRootState) => getAtlasAIState(state).isLoadingMore

// Session state
export const sessions = (state: IRootState) => getAtlasAIState(state).sessions.data
export const isSessionsLoading = (state: IRootState) => getAtlasAIState(state).sessions.isLoading
export const isSessionsLoadingMore = (state: IRootState) =>
  getAtlasAIState(state).sessions.isLoadingMore
export const hasMoreSessions = (state: IRootState) => getAtlasAIState(state).sessions.hasMore
export const sessionsTotal = (state: IRootState) => getAtlasAIState(state).sessions.total

// Selected session
export const selectedSession = (state: IRootState) => getAtlasAIState(state).selectedSession.data
export const isSelectedSessionLoading = (state: IRootState) =>
  getAtlasAIState(state).selectedSession.isLoading

export const modelProvider = (state: IRootState) => state.atlasai.modelProvider
