import { IBankAccount } from './bankAccount'
import { IEntityInfo } from './entityInfo'

export interface IBankTransactionData {
  data?: IBankTransaction[]
  totalCount?: number
  bankAccounts?: IBankAccount[]
}
export interface IBankTransaction {
  id: string
  cleanedDescription: string
  description?: string
  categoryPredictions?: ICategoryPrediction
  category?: BankTransactionCategory | null
  entityPrediction?: string
  entityPredictionId?: string
  entityName?: string | null
  intermediaryName?: string | null
  transactionType?: 'Credit' | 'Debit'
  transactionAmount?: number
  totalItems?: number
  rows: IBankTransactionDetail[]
}

export interface IBankTransactionDetail {
  id: string
  descriptionOriginal: string
  recordDate: string
  amount: number
  totalCount?: number
}

interface ICategoryPrediction {
  mcaFdebtFlaglag: string
  debtorPaymentrFlag: string
  vendorPaymentFlag: string
  investmentFlag: string
  intraCompanyTransferFlag: string
  diligenceDepositFlag: string
}

export enum BankTransactionCategory {
  Deposit = 'diligence_deposit_flag',
  VendorPayment = 'vendor_payment_flag',
  Intracompany = 'intra_company_transfer_flag',
  Debt = 'debt_flag',
  Other = 'other',
  CustomerCollection = 'debtor_payment_flag',
  Investment = 'investment_flag',
}

export const TYPE_OPTIONS_DEBIT: { value: string | string[]; label: string }[] = [
  {
    value: [
      'debt_flag',
      'intra_company_transfer_flag',
      'diligence_deposit_flag',
      'vendor_payment_flag',
    ],
    label: 'All',
  },
  {
    value: BankTransactionCategory.VendorPayment,
    label: 'Vendor Payment',
  },
  {
    value: BankTransactionCategory.Intracompany,
    label: 'Intracompany Outflow',
  },
  {
    value: BankTransactionCategory.Debt,
    label: 'Debt Outflow',
  },
  {
    value: BankTransactionCategory.Deposit,
    label: 'Diligence Deposit',
  },
  {
    value: BankTransactionCategory.Other,
    label: 'Other Outflow',
  },
]

export const TYPE_OPTIONS_CREDIT: { value: string | string[]; label: string }[] = [
  {
    value: [
      BankTransactionCategory.Debt,
      BankTransactionCategory.CustomerCollection,
      BankTransactionCategory.Investment,
      BankTransactionCategory.Intracompany,
    ],
    label: 'All',
  },
  {
    value: BankTransactionCategory.CustomerCollection,
    label: 'Customer Collection',
  },

  {
    value: BankTransactionCategory.Investment,
    label: 'Investment Inflow',
  },
  {
    value: BankTransactionCategory.Debt,
    label: 'Debt Inflow',
  },
  {
    value: BankTransactionCategory.Intracompany,
    label: 'Intracompany Inflow',
  },
  {
    value: BankTransactionCategory.Other,
    label: 'Other Inflow',
  },
]

export const CATEGORY_OPTIONS = [
  {
    value: BankTransactionCategory.VendorPayment,
    label: 'Vendor Payment',
  },
  {
    value: BankTransactionCategory.Intracompany,
    label: 'Intracompany',
  },
  {
    value: BankTransactionCategory.Debt,
    label: 'Debt',
  },
  {
    value: BankTransactionCategory.Deposit,
    label: 'Diligence Deposit',
  },
  {
    value: BankTransactionCategory.CustomerCollection,
    label: 'Customer Collection',
  },

  {
    value: BankTransactionCategory.Investment,
    label: 'Investment',
  },
  {
    value: BankTransactionCategory.Other,
    label: 'Other',
  },
]

export const CATEGORIES_VALUES = CATEGORY_OPTIONS.map(({ value }) => value)

export const INFLOW_OUTFLOW_CATEGORIES = [
  BankTransactionCategory.Debt,
  BankTransactionCategory.Investment,
  BankTransactionCategory.Intracompany,
  BankTransactionCategory.Other,
]

export const CATEGORIES_LABELS: Record<BankTransactionCategory, string> = CATEGORY_OPTIONS.reduce(
  (result, item) => ({
    ...result,
    [item.value.toString()]: item.label,
  }),
  {} as Record<BankTransactionCategory, string>,
)

export enum BANK_TRANSACTIONS_TABS {
  ACCOUNTS = 'Accounts',
  DEBITS = 'Debits',
  CREDITS = 'Credits',
}

export interface IBankTransactionRaw {
  id: string
  recordDate: string
  description: string
  category: BankTransactionCategory | null
  entityName: string | null
  entityInfo?: IEntityInfo | null
  intermediaryName: string | null
  intermediaryEntityInfo?: IEntityInfo | null
  amount: number
}

export interface IBankTransactionRawData {
  data: IBankTransactionRaw[]
  totals: {
    totalItems: number
    boxLink?: string
    minDate?: string
    maxDate?: string
  }
}

export enum BANK_TRANSACTIONS_GRAPH_OPTION {
  Category = 'category',
  Entity = 'entityName',
}

export type BankTransactionsGraphOption = 'category' | 'entityName'

export interface IBankTransactionGraph {
  category?: string
  entityName?: string
  amounts: number[]
}

export interface IBankTransactionGraphData {
  headers: string[]
  data: IBankTransactionGraph[]
  option: BANK_TRANSACTIONS_GRAPH_OPTION
}

export const BANK_TRANSACTIONS_GRAPH_GROUP_BY_OPTIONS = [
  {
    value: BANK_TRANSACTIONS_GRAPH_OPTION.Category,
    label: 'Category',
  },
  {
    value: BANK_TRANSACTIONS_GRAPH_OPTION.Entity,
    label: 'Entity',
  },
]

export interface IPendingCashDominion {
  entity_id: string
  entity_name: string
  record_date: string
  amount: number
}

export interface IPendingCashDominionData {
  data: IPendingCashDominion[]
  totals: {
    totalItems: number
  }
}
