import ApiClient from './client'
import { Feedback } from '@common/interfaces/prospects'

export default class AtlasAI {
  client: ApiClient

  constructor(client: ApiClient) {
    this.client = client
  }

  askQuestion(data = {}) {
    return this.client.post('/atlasai/ask', data)
  }

  listSessions(params = {}) {
    return this.client.get('/atlasai/sessions', params)
  }

  getSession(sessionId: string, params: { limit?: number; offset?: number } = {}) {
    return this.client.get(`/atlasai/sessions/${sessionId}`, { ...params, includeSession: true })
  }

  createSession() {
    return this.client.post('/atlasai/sessions')
  }

  deleteSession(sessionId: string) {
    return this.client.delete(`/atlasai/sessions/${sessionId}`)
  }

  submitFeedback(data: { messageId: string; feedbackType: Feedback; comment?: string }) {
    return this.client.post('/atlasai/feedback', data)
  }
}
