import React from 'react'
import { Link } from '@mui/material'
import styles from '../AtlasAIChat.module.scss'
// Helper function to format cell values based on data type and pattern
export const formatCellValue = (value: any): string => {
  if (value === null || value === undefined) return ''
  if (typeof value === 'object') return JSON.stringify(value)

  const stringValue = String(value)

  // ISO Date pattern (including with milliseconds)
  const isoDatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{3})?Z?$/
  if (isoDatePattern.test(stringValue)) {
    const date = new Date(stringValue)
    // Check if time is midnight (00:00:00) to determine if we should show time
    const hasTime = date.getHours() !== 0 || date.getMinutes() !== 0 || date.getSeconds() !== 0
    return date.toLocaleString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      ...(hasTime && {
        hour: 'numeric',
        minute: 'numeric',
      }),
    })
  }

  // Date pattern YYYY-MM-DD
  const datePattern = /^\d{4}-\d{2}-\d{2}$/
  if (datePattern.test(stringValue)) {
    return new Date(stringValue).toLocaleString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    })
  }

  // Number with decimal places
  const numberPattern = /^-?\d*\.?\d+$/
  if (numberPattern.test(stringValue)) {
    const num = parseFloat(stringValue)
    // Format with commas and up to 2 decimal places if needed
    return num.toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })
  }

  // Currency pattern (starts with $ or number followed by decimal)
  const currencyPattern = /^\$?\d+(\.\d{2})?$/
  if (currencyPattern.test(stringValue)) {
    const num = parseFloat(stringValue.replace('$', ''))
    return num.toLocaleString(undefined, {
      style: 'currency',
      currency: 'USD',
    })
  }

  return stringValue
}

// Helper function to format bold text within a string
const formatBoldText = (text: string, lineIndex: number, partIndex: number) => {
  const boldPattern = /\*\*([^*]+)\*\*/g
  const parts = text.split(boldPattern)

  return parts.map((part, index) => {
    if (index % 2 === 0) {
      return part
    }
    return <strong key={`bold-${lineIndex}-${partIndex}-${index}`}>{part}</strong>
  })
}

// Helper function to create link element
const createLinkElement = (title: string, url: string, lineIndex: number, partIndex: number) => {
  return (
    <Link
      key={`link-${lineIndex}-${partIndex}`}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className={styles.messageLink}
    >
      {title}
    </Link>
  )
}

// Main function to format message with markdown and links
export const formatMessage = (content: string) => {
  if (!content) {
    return null
  }

  const lines = content.split('\n')

  return lines.map((line, lineIndex) => {
    const markdownLinkPattern = /\[([^\]]+)\]\(([^)]+)\)/g
    const parts = line.split(markdownLinkPattern)
    const formattedParts = []

    for (let i = 0; i < parts.length; i++) {
      if (i % 3 === 0) {
        // Regular text with bold formatting
        formattedParts.push(...formatBoldText(parts[i], lineIndex, i))
      } else if (i % 3 === 1) {
        // Link element
        const title = parts[i]
        const url = parts[i + 1]
        formattedParts.push(createLinkElement(title, url, lineIndex, i))
      }
    }

    return (
      <React.Fragment key={`line-${lineIndex}`}>
        {formattedParts}
        {lineIndex < lines.length - 1 && <br />}
      </React.Fragment>
    )
  })
}
