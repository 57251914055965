import React, { useMemo, useEffect, useState, useCallback, useRef } from 'react'
import { useParams } from 'react-router'
import cn from 'classnames'
import styles from './BBCIneligibleInventoryTableReason.module.scss'
import genericSs from '@styles/generic.module.scss'
import MultiselectRow from '../MultiselectRow'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import { IInventoryIneligibleStatsReason } from '@common/interfaces/bbc'
import { debounceEventHandler, formatPrice } from '../../helpers/helpers'
import InfiniteScroll from 'react-infinite-scroll-component'
import Tooltip from '@mui/material/Tooltip'
import TableFiltersRow from '../Common/TableFiltersRow'
import { BBC_INVENTORY_INELIGIBLE_REASONS_LIST_FILTERS_CONFIG } from '@common/constants/filters'
import TableLoader from '../Common/TableLoader'
import { ExpandDetailIcon } from '../Common/Icons/Icons'
import BBCIneligibleInventoryTableDetail from '../BBCIneligibleInventoryTableDetail'
import useTable from '../../hooks/useTable'

interface IProps {
  inventoryIneligibleStatsReason: IInventoryIneligibleStatsReason[]
  listInventoryIneligibleStatsReason: (id: string, data: object) => void
  filters: any
  isModalShown: boolean
  category: string
  dates: string[]
}

const BBCIneligibleInventoryTableReason = ({
  inventoryIneligibleStatsReason,
  listInventoryIneligibleStatsReason,
  filters,
  isModalShown,
  category,
  dates,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const wrapperRef = useRef(null)

  const [isLoading, setIsLoading] = useState(true)
  const [expanded, setExpanded] = useState([])

  const {
    activeItems,
    activeItem,
    setActiveItems,
    setActiveItem,
    handleSelectRow,
    orderBy,
    handleOrderChange,
  } = useTable({
    tableId: 'inventory-ineligible-table-reason',
    sortDefault: {
      field: 'value_0',
      direction: 'ASC',
    },
  })

  const debounceFilterList = useMemo(
    () =>
      debounceEventHandler(async (data: any) => {
        !data.loadMore && setIsLoading(true)
        await listInventoryIneligibleStatsReason(id, {
          ...data,
          category,
          nestedRows: {
            keys: ['category'],
          },
        })
        setIsLoading(false)
      }, 500),
    [listInventoryIneligibleStatsReason, id, category],
  )

  useEffect(() => {
    debounceFilterList({
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
  }, [orderBy, filters, debounceFilterList])

  const totalRow = useMemo(
    () =>
      inventoryIneligibleStatsReason
        ?.filter((_, index) => activeItems.includes(index))
        .map((row) => row.values)
        .reduce(
          (total, row) => {
            dates.forEach((value: any, index: number) => {
              if (!total.values[index]) {
                total.values[index] = 0
              }
              total.values[index] += row[value] || 0
            })
            return total
          },
          { values: dates.map(() => 0) },
        ),
    [inventoryIneligibleStatsReason, activeItems, dates],
  )

  const totalCount = useMemo(
    () => inventoryIneligibleStatsReason?.[0]?.totalCount || 0,
    [inventoryIneligibleStatsReason],
  )

  const loadMore = useCallback(() => {
    debounceFilterList({
      loadMore: true,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
      page: Math.ceil(inventoryIneligibleStatsReason?.length / 20),
    })
  }, [orderBy, filters, debounceFilterList, inventoryIneligibleStatsReason])

  const filtersConfig = useMemo(
    () =>
      BBC_INVENTORY_INELIGIBLE_REASONS_LIST_FILTERS_CONFIG.map((item) => {
        const newItem = item
        if (item?.field?.startsWith('value_')) {
          const index = Number(item.field.split('_')[1])
          const date = dates[index]
          if (!date) {
            return null
          }
          newItem.title = index === 0 ? 'Current BBC' : date
        }
        return newItem
      }).filter(Boolean),
    [dates],
  )

  const handleExpand = useCallback((label: string) => {
    setExpanded((values) =>
      values.includes(label) ? values.filter((item) => item !== label) : [...values, label],
    )
  }, [])

  return (
    <TableContainer
      className={styles.table}
      onActiveRowsChange={setActiveItems}
      onActiveRowChange={setActiveItem}
      hasFooter
      size="small"
    >
      <Table ref={wrapperRef}>
        <TableHead>
          <TableFiltersRow
            filters={filtersConfig}
            orderBy={orderBy}
            handleOrderChange={handleOrderChange}
            isChildrenAtStart
          >
            <TableCell />
          </TableFiltersRow>
        </TableHead>
        <TableBody id={category} className={styles.scrollableDiv}>
          {isLoading ? (
            <TableLoader columnsCount={(dates?.length || 1) + 2} />
          ) : (
            <InfiniteScroll
              dataLength={inventoryIneligibleStatsReason?.length || 0}
              next={loadMore}
              hasMore={inventoryIneligibleStatsReason?.length < totalCount}
              loader={<TableLoader columnsCount={(dates?.length || 1) + 2} rowsCount={1} />}
              scrollableTarget={category}
            >
              {inventoryIneligibleStatsReason?.map((item, index) => {
                const isExpanded = expanded.includes(item.reason)

                return (
                  <React.Fragment key={item.reason}>
                    <TableRow
                      key={item.reason}
                      data-index={index}
                      className={cn('activableRow', styles.parentRow, {
                        activeRow: activeItems.includes(index),
                        currentActiveRow: activeItem === index,
                      })}
                      onClick={(event) => handleSelectRow(event, index)}
                    >
                      <TableCell className={genericSs.tableTextCenter}>
                        {Object.keys(item.values).length > 0 && (
                          <ExpandDetailIcon
                            onClick={() => handleExpand(item?.reason)}
                            isExpanded={isExpanded}
                          />
                        )}
                      </TableCell>
                      <TableCell className={genericSs.tableTextLeft}>
                        <Tooltip title={item.reason || ''} placement="top">
                          <span>{item.reason}</span>
                        </Tooltip>
                      </TableCell>
                      {dates?.map((date) => (
                        <TableCell key={date} className={genericSs.tableTextRight}>
                          $ {formatPrice(item.values[date] || 0)}
                        </TableCell>
                      ))}
                    </TableRow>
                    {isExpanded && (
                      <TableRow>
                        <TableCell className={genericSs.nestedRowColumn} colSpan={3}>
                          <BBCIneligibleInventoryTableDetail
                            inventoryIneligibleStatsDetail={item.rows}
                            filters={filters}
                            category={category}
                            reason={item.reason}
                            dates={dates}
                          />
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                )
              })}
            </InfiniteScroll>
          )}
          <MultiselectRow activeItems={activeItems} addColumn>
            {totalRow?.values?.map((value: number, index: number) => {
              return (
                <TableCell key={index} className={genericSs.tableTextRight}>
                  $ {formatPrice(value)}
                </TableCell>
              )
            })}
          </MultiselectRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default BBCIneligibleInventoryTableReason
