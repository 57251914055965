import React, { useCallback, useMemo } from 'react'
import { generatePath, Link } from 'react-router-dom'
import LinkButton from '@mui/material/Link'
import cn from 'classnames'
import styles from './Treasury.module.scss'
import genericSs from '@styles/generic.module.scss'
import { formatPrice, formatTime } from '../../helpers/helpers'
import TableCell from '../Common/TableCell'
import { ROUTES } from '../../constants/routes'
import { ITreasuryDetails, TreasuryAmountTypes } from '@common/interfaces/treasury'
import Checkbox from '../Common/Checkbox'
import { FundingRequestStatus } from '@common/interfaces/bbc'
import CurrencyField from '../Common/CurrencyField'
import { DeleteIcon } from '../Common/Icons'
import Tooltip from '@mui/material/Tooltip'
import moment from 'moment'

interface IProps {
  wireType: TreasuryAmountTypes
  wire: ITreasuryDetails
  handleUpdateTreasuryData: (data: object) => void
  handleDeleteWire: (id: string) => void
}

const TreasuryTableRow = ({
  wireType,
  wire,
  handleUpdateTreasuryData,
  handleDeleteWire,
}: IProps) => {
  const onDelete = useCallback(() => handleDeleteWire(wire.id), [wire, handleDeleteWire])

  const handleCurrencyFieldChange = useCallback(
    ({ target }) => {
      handleUpdateTreasuryData({
        id: target.name,
        amount: target.value || 0,
      })
    },
    [handleUpdateTreasuryData],
  )

  const handleUpdatedChecked = useCallback(
    (event, checked) => handleUpdateTreasuryData({ id: wire.id, isChecked: checked }),
    [handleUpdateTreasuryData, wire],
  )

  const tooltipText = useMemo(() => {
    if (wire.borrowingBase?.createdAt) {
      return `Requested at: ${formatTime(wire.borrowingBase?.createdAt)}`
    } else if (wire.predictedDrawTime) {
      return `Average draw time: ${moment(wire.predictedDrawTime, 'HH:mm:ss').format('h:mm a')}`
    }
    return ''
  }, [wire.borrowingBase?.createdAt, wire.predictedDrawTime])

  if (!wire) {
    return null
  }

  return (
    <>
      <TableCell className={genericSs.tableTextLeft}>
        <Checkbox
          className={styles.checkBox}
          checked={wire.isChecked}
          onChange={handleUpdatedChecked}
        />
      </TableCell>

      <TableCell className={genericSs.tableTextLeft}>
        {wireType === TreasuryAmountTypes.Funding ? (
          wire.borrowingBase?.id ? (
            <LinkButton
              component={Link}
              to={generatePath(ROUTES.BBC, {
                id: wire.borrowingBase?.id,
              })}
            >
              {wire.clientInfo?.clientName}
            </LinkButton>
          ) : (
            wire.clientInfo?.clientName
          )
        ) : wireType === TreasuryAmountTypes.Other ? (
          wire.description
        ) : wireType === TreasuryAmountTypes.Participation ? (
          `${wire.clientInfo?.clientName} ${wire.participant} ${wire.participationWireType}`
        ) : wire.clientInfo?.id ? (
          <LinkButton
            component={Link}
            to={generatePath(ROUTES.CLIENT_PAGE, {
              id: wire.clientInfo?.id,
            })}
          >
            {wire.clientInfo?.clientName}
          </LinkButton>
        ) : (
          wire.clientInfo?.clientName
        )}
      </TableCell>

      {wireType === TreasuryAmountTypes.Funding && (
        <TableCell className={cn(genericSs.tableTextLeft)}>
          <Tooltip title={tooltipText}>
            <div
              className={cn(genericSs.tableTextLeft, styles.status, {
                [genericSs.complete]: wire.borrowingBase?.status === FundingRequestStatus.Sent,
                [genericSs.inProgress]:
                  wire.borrowingBase && wire.borrowingBase?.status !== FundingRequestStatus.Sent,
                [genericSs.notStarted]: !wire.borrowingBase,
              })}
            >
              {wire.status}
            </div>
          </Tooltip>
        </TableCell>
      )}

      <TableCell className={genericSs.tableTextRight}>
        {wire.borrowingBase?.status === FundingRequestStatus.Sent ||
        wire?.passThrough ||
        wire?.participationWires ? (
          `$${formatPrice(wire.amount)}`
        ) : (
          <CurrencyField
            className={styles.amountField}
            name={wire.id}
            value={formatPrice(wire.amount)}
            onChange={handleCurrencyFieldChange}
            useFinalForm={false}
          />
        )}
      </TableCell>

      <TableCell className={genericSs.tableTextLeft}>
        {((wireType === TreasuryAmountTypes.Funding && !wire.borrowingBase?.status) ||
          wireType === TreasuryAmountTypes.Other) && (
          <DeleteIcon className={styles.iconButton} onClick={onDelete} size="small" />
        )}
      </TableCell>
    </>
  )
}

export default TreasuryTableRow
