import React, { ReactNode } from 'react'
import MuiModal from '@mui/material/Modal'
import { ReactComponent as CloseIcon } from '@assets/images/modal-close-icon.svg'
import cn from 'classnames'
import styles from './Modal.module.scss'

interface IProps {
  title?: React.ReactNode
  open?: boolean
  children?: ReactNode
  onCancel?: () => void
  footer?: ReactNode
  classes?: any
  size?: string
  disableEnforceFocus?: boolean
  toolbar?: boolean
  hideCloseIcon?: boolean
  isTable?: boolean
}

const Modal = ({
  classes = {},
  title = '',
  open,
  onCancel,
  children,
  footer,
  size,
  disableEnforceFocus,
  toolbar = false,
  hideCloseIcon = false,
  isTable = false,
}: IProps) => {
  return (
    <MuiModal open={open} onClose={onCancel} disableEnforceFocus={disableEnforceFocus}>
      <div
        className={cn(styles.modal, classes.root, {
          [styles.fullscreenModal]: size === 'fullscreen',
          [styles.smallModal]: size === 'small',
        })}
      >
        <div
          className={cn(
            {
              [styles.modalHeaderTitle]: title,
              [styles.editTableTitle]: isTable,
            },
            classes.header,
          )}
        >
          {!hideCloseIcon && (
            <CloseIcon className={cn(styles.modalClose, classes.close)} onClick={onCancel} />
          )}
          {title && (
            <div
              className={cn(styles.modalTitle, classes.title, {
                [styles.smallModalTitle]: size === 'small',
              })}
            >
              {title}
            </div>
          )}
        </div>

        <div className={cn(styles.modalBody, classes.body)}>{children}</div>
        {footer && <div className={cn(styles.modalFooter, classes.footer)}>{footer}</div>}
      </div>
    </MuiModal>
  )
}

export default Modal
