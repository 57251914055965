import { connect } from 'react-redux'
import CollectionAggregationRow from './CollectionAggregationRow'
import {
  deleteAggregationRow,
  listAggregationRow,
  updateAggregationRow,
} from '../../redux/collections/actions'
import { addEntityInfo } from '../../redux/entityInfo/actions'
import { createStructuredSelector } from 'reselect'
import { list as banksAccountData } from '../../redux/bankAccount/selectors'
import { loanBalanceStartDate } from '../../redux/loanBalance/selectors'
import { listBankAccounts } from '../../redux/bankAccount/actions'
const selector = createStructuredSelector({
  banksAccountData,
  loanBalanceStartDate,
})

const actions = {
  listAggregationRow,
  updateAggregationRow,
  deleteAggregationRow,
  addEntityInfo,
  listBankAccounts,
}

export default connect(selector, actions)(CollectionAggregationRow)
