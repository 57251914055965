import React, { MutableRefObject, useMemo } from 'react'
import cn from 'classnames'
import styles from './DilutionOverTime.module.scss'
import genericSs from '@styles/generic.module.scss'
import { formatPrice, formatPercent } from '../../helpers/helpers'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TableLoader from '../Common/TableLoader'
import { IClientGeneralLedgerDilutionOverTime } from '@common/interfaces/clientGeneralLedger'
import MultiselectRow from '../MultiselectRow'

interface IProps {
  isLoading: boolean
  option: string
  dilutionOverTimeHeaders: string[]
  dilutionOverTimeData: IClientGeneralLedgerDilutionOverTime[]
  activeItem: number
  activeItems: number[]
  setActiveItem: (indexes: number) => void
  setActiveItems: (index: number[]) => void
  handleSelectRow: (event: any, index: number) => void
  wrapperRef: MutableRefObject<any>
}

const DilutionOverTimeTableCellValue = ({ option, value }: { option: string; value: number }) => {
  if (option === 'dilution') {
    return <>{formatPercent(value)}</>
  }

  return (
    <>
      <span className={genericSs.pricePrefix}>$</span>
      {formatPrice(value)}
    </>
  )
}

const DilutionOverTimeTable = ({
  isLoading,
  option,
  dilutionOverTimeHeaders,
  dilutionOverTimeData,
  activeItem,
  activeItems,
  setActiveItem,
  setActiveItems,
  handleSelectRow,
  wrapperRef,
}: IProps) => {
  const missedColumns = useMemo(
    () => Array.from(Array(Math.max(6 - (dilutionOverTimeHeaders.length || 0), 0)).keys()),
    [dilutionOverTimeHeaders],
  )

  const totalRow = useMemo(() => {
    const filteredData = dilutionOverTimeData.filter((_, index) => activeItems.includes(index))
    return dilutionOverTimeHeaders
      .map((_, index) =>
        filteredData.reduce(
          (result, row) => {
            result.invoices += row.invoices[index]
            result.payments += row.payments[index]
            result.dilutiveCredits += row.dilutiveCredits[index]
            result.other += row.other[index]

            return result
          },
          {
            invoices: 0,
            payments: 0,
            dilutiveCredits: 0,
            other: 0,
          },
        ),
      )
      .map((item) => ({
        ...item,
        dilution:
          item.invoices && item.dilutiveCredits
            ? Math.abs(item.dilutiveCredits / item.invoices)
            : 0,
      }))
  }, [dilutionOverTimeData, activeItems, dilutionOverTimeHeaders])

  const columnsCount = useMemo(
    () => (dilutionOverTimeHeaders?.length < 6 ? 6 : dilutionOverTimeHeaders.length) + 1,
    [dilutionOverTimeHeaders],
  )

  return (
    <TableContainer
      id="dilutionOverTimeTable"
      className={styles.table}
      onActiveRowsChange={setActiveItems}
      onActiveRowChange={setActiveItem}
    >
      <Table ref={wrapperRef}>
        <TableHead>
          <TableRow>
            <TableCell className={genericSs.tableTextLeft}>
              <div className={styles.header}>Customer</div>
            </TableCell>
            {dilutionOverTimeHeaders.map((header) => (
              <TableCell key={header} className={genericSs.tableTextRight}>
                {header}
              </TableCell>
            ))}
            {missedColumns.map((index) => (
              <TableCell key={index} />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableLoader columnsCount={columnsCount} />
          ) : (
            dilutionOverTimeData.map((item, index) => (
              <TableRow
                key={item.debtor}
                data-index={index}
                className={cn('activableRow', {
                  activeRow: activeItems.includes(index),
                  currentActiveRow: activeItem === index,
                })}
                onClick={(event) => handleSelectRow(event, index)}
              >
                <TableCell className={genericSs.tableTextLeft}>{item.debtor}</TableCell>
                {dilutionOverTimeHeaders.map((header, index) => (
                  <TableCell key={header} className={genericSs.tableTextRight}>
                    <DilutionOverTimeTableCellValue option={option} value={item[option][index]} />
                  </TableCell>
                ))}

                {missedColumns.map((index) => (
                  <TableCell key={index} />
                ))}
              </TableRow>
            ))
          )}
          <MultiselectRow activeItems={activeItems}>
            {dilutionOverTimeHeaders.map((header, index) => (
              <TableCell key={header} className={genericSs.tableTextRight}>
                <DilutionOverTimeTableCellValue
                  option={option}
                  value={totalRow?.[index]?.[option]}
                />
              </TableCell>
            ))}

            {missedColumns.map((index) => (
              <TableCell key={index} />
            ))}
          </MultiselectRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default DilutionOverTimeTable
