import { createRequestTypes } from '../../helpers/helpers'
import { IApi } from '../../api'
import { CommonPromiseAction } from '../types'

export const prefix = 'loanBalance'

export const EXPORT_TRANSACTIONS_REQUEST = `${prefix}/EXPORT_TRANSACTIONS_REQUEST`
export const EXPORT_TRANSACTIONS_SUCCESS = `${prefix}/EXPORT_TRANSACTIONS_SUCCESS`
export const EXPORT_TRANSACTIONS_FAILURE = `${prefix}/EXPORT_TRANSACTIONS_FAILURE`

export const exportTransactions: CommonPromiseAction = (params?: { filters?: object }) => ({
  type: [EXPORT_TRANSACTIONS_REQUEST, EXPORT_TRANSACTIONS_SUCCESS, EXPORT_TRANSACTIONS_FAILURE],
  promise: (api: IApi) => api.loanBalance.exportTransactions(params),
})

export const RECALCULATE_LOAN_BALANCE_REQUEST = `${prefix}/RECALCULATE_LOAN_BALANCE_REQUEST`
export const RECALCULATE_LOAN_BALANCE_SUCCESS = `${prefix}/RECALCULATE_LOAN_BALANCE_SUCCESS`
export const RECALCULATE_LOAN_BALANCE_FAILURE = `${prefix}/RECALCULATE_LOAN_BALANCE_FAILURE`

export const recalculateLoanBalance: CommonPromiseAction = (params: object) => ({
  type: [
    RECALCULATE_LOAN_BALANCE_REQUEST,
    RECALCULATE_LOAN_BALANCE_SUCCESS,
    RECALCULATE_LOAN_BALANCE_FAILURE,
  ],
  promise: (api: IApi) => api.loanBalance.recalculateLoanBalance(params),
})

export const GET_LOAN_BALANCE_START_DATE_REQUEST = `${prefix}/GET_LOAN_BALANCE_START_DATE_REQUEST`
export const GET_LOAN_BALANCE_START_DATE_SUCCESS = `${prefix}/GET_LOAN_BALANCE_START_DATE_SUCCESS`
export const GET_LOAN_BALANCE_START_DATE_FAILURE = `${prefix}/GET_LOAN_BALANCE_START_DATE_FAILURE`

export const getLoanBalanceStartDate: CommonPromiseAction = () => ({
  type: [
    GET_LOAN_BALANCE_START_DATE_REQUEST,
    GET_LOAN_BALANCE_START_DATE_SUCCESS,
    GET_LOAN_BALANCE_START_DATE_FAILURE,
  ],
  promise: (api: IApi) => api.loanBalance.getLoanBalanceStartDate(),
})

export const GET_END_OF_MONTH_FLOW_REQUEST = `${prefix}/GET_END_OF_MONTH_FLOW_REQUEST`
export const GET_END_OF_MONTH_FLOW_SUCCESS = `${prefix}/GET_END_OF_MONTH_FLOW_SUCCESS`
export const GET_END_OF_MONTH_FLOW_FAILURE = `${prefix}/GET_END_OF_MONTH_FLOW_FAILURE`

export const getLatestEndOfMonthFlow: CommonPromiseAction = () => ({
  type: [
    GET_END_OF_MONTH_FLOW_REQUEST,
    GET_END_OF_MONTH_FLOW_SUCCESS,
    GET_END_OF_MONTH_FLOW_FAILURE,
  ],
  promise: (api: IApi) => api.loanBalance.getLatestEndOfMonthFlow(),
})

export const EXPORT_END_OF_MONTH_FLOW_REQUEST = `${prefix}/EXPORT_END_OF_MONTH_FLOW_REQUEST`
export const EXPORT_END_OF_MONTH_FLOW_SUCCESS = `${prefix}/EXPORT_END_OF_MONTH_FLOW_SUCCESS`
export const EXPORT_END_OF_MONTH_FLOW_FAILURE = `${prefix}/EXPORT_END_OF_MONTH_FLOW_FAILURE`

export const exportEndOfMonthFlow: CommonPromiseAction = () => ({
  type: [
    EXPORT_END_OF_MONTH_FLOW_REQUEST,
    EXPORT_END_OF_MONTH_FLOW_SUCCESS,
    EXPORT_END_OF_MONTH_FLOW_FAILURE,
  ],
  promise: (api: IApi) => api.loanBalance.exportEndOfMonthFlow(),
})

export const APPLY_END_OF_MONTH_FLOW_REQUEST = `${prefix}/APPLY_END_OF_MONTH_FLOW_REQUEST`
export const APPLY_END_OF_MONTH_FLOW_SUCCESS = `${prefix}/APPLY_END_OF_MONTH_FLOW_SUCCESS`
export const APPLY_END_OF_MONTH_FLOW_FAILURE = `${prefix}/APPLY_END_OF_MONTH_FLOW_FAILURE`

export const applyEndOfMonthFlow: CommonPromiseAction = (params: object) => ({
  type: [
    APPLY_END_OF_MONTH_FLOW_REQUEST,
    APPLY_END_OF_MONTH_FLOW_SUCCESS,
    APPLY_END_OF_MONTH_FLOW_FAILURE,
  ],
  promise: (api: IApi) => api.loanBalance.applyEndOfMonthFlow(params),
})

export const GET_AVERAGE_LOAN_BALANCE_REQUEST = `${prefix}/GET_AVERAGE_LOAN_BALANCE_REQUEST`
export const GET_AVERAGE_LOAN_BALANCE_SUCCESS = `${prefix}/GET_AVERAGE_LOAN_BALANCE_SUCCESS`
export const GET_AVERAGE_LOAN_BALANCE_FAILURE = `${prefix}/GET_AVERAGE_LOAN_BALANCE_FAILURE`

export const getAverageLoanBalance: CommonPromiseAction = (params: object) => ({
  type: [
    GET_AVERAGE_LOAN_BALANCE_REQUEST,
    GET_AVERAGE_LOAN_BALANCE_SUCCESS,
    GET_AVERAGE_LOAN_BALANCE_FAILURE,
  ],
  promise: (api: IApi) => api.loanBalance.getAverageLoanBalance(params),
})

const EXPORT_INELIGIBLES = createRequestTypes(prefix, 'EXPORT_INELIGIBLES')
export const [EXPORT_INELIGIBLES_REQUEST, EXPORT_INELIGIBLES_SUCCESS, EXPORT_INELIGIBLES_FAILURE] =
  EXPORT_INELIGIBLES

export const exportIneligibles: CommonPromiseAction = (params: object) => ({
  type: EXPORT_INELIGIBLES,
  promise: (api: IApi) => api.loanBalance.exportIneligibles(params),
  params,
})
