import React, { useCallback, useState, useMemo, useEffect } from 'react'
import Tooltip from '@mui/material/Tooltip'
import cn from 'classnames'
import InfiniteScroll from 'react-infinite-scroll-component'
import styles from './CollectionsWiresMapping.module.scss'
import genericSs from '@styles/generic.module.scss'
import { FieldArray } from 'react-final-form-arrays'
import {
  IDB_LOAN_ACCOUNT_NUMBER,
  IWireData,
  IWireDataAggregation,
  RefreshType,
  WireDataStatus,
} from '@common/interfaces/collection'
import { IEntityInfo } from '@common/interfaces/entityInfo'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TableBody from '../Common/TableBody'
import TableContainer from '../Common/TableContainer'
import Autocomplete from '../Common/Autocomplete'
import CreatableSelectField from '../Common/CreatableSelectField'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import Skeleton from '@mui/material/Skeleton'
import { IClientInfo } from '@common/interfaces/client'
import { formatter, formatDate, debounceEventHandler, formatPrice } from '../../helpers/helpers'
import Button from '../Common/Button'
import { WIRE_MAPPING_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import setFieldData from 'final-form-set-field-data'
import TableFiltersRow from '../Common/TableFiltersRow'
import { useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { ROUTES } from '../../constants/routes'
import FilterContainer from '../Filters/FilterContainer'
import MappingProgress from '../MappingProgress'
import Box from '@mui/material/Box'
import WarningModal from '../WarningModal'
import TableLoader from '../Common/TableLoader'
import SaveState from '../Common/SaveState'
import { ILoadingData } from '../../redux/types'
import { MenuIcon } from '../Common/Icons'
import { ReactComponent as WarningIcon } from '@assets/images/warning-icon-outline.svg'
import MultiSelectToolbar from '../MultiSelectToolbar'
import useTable from '../../hooks/useTable'

const filtersDefaults = buildFiltersDefaults(WIRE_MAPPING_FILTERS_CONFIG)
const filtersValidate = buildFiltersValidateSchema(WIRE_MAPPING_FILTERS_CONFIG)

const mutators = {
  ...arrayMutators,
  setFieldData,
}

interface IProps {
  isCheckboxShown?: boolean
  type: WireDataStatus
  wiresData: IWireDataAggregation
  isTableLoading: boolean
  isTableSaving: boolean
  isTableSaved: boolean
  updateWire: (id: string, data: object) => void
  listWires: (params: object, type?: WireDataStatus) => void
  updateWires: (data: object) => void
  archiveWire: (wire: IWireData) => void
  deleteWire: (wire: IWireData) => void
  restoreWire: (wire: IWireData) => void
  markNegativeWire: (wire: IWireData) => void
  addEntityInfo?: (debtor: string, clientName: string) => Promise<any>
  addDebtor?: (wireId: string, debtor: string) => void
  clients: ILoadingData<{ data: IClientInfo[] }>
  listDebtors: (data: object) => Promise<{ data: IEntityInfo[] }>
  mappingProgress?: number
  refreshCountAndType: {
    count: number
    type: RefreshType
  }
  handleRefresh: (type: RefreshType) => void
}

const CollectionsWiresTable = ({
  isCheckboxShown = false,
  type,
  wiresData,
  isTableLoading,
  isTableSaving,
  isTableSaved,
  updateWires,
  archiveWire,
  deleteWire,
  restoreWire,
  markNegativeWire,
  addEntityInfo,
  clients,
  listDebtors,
  listWires,
  updateWire,
  mappingProgress,
  refreshCountAndType,
  handleRefresh,
}: IProps) => {
  const isDeleted = type === WireDataStatus.Deleted

  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false)
  const [isPermanent, setIsPermanent] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [actionsMenuOpen, setActionsMenuOpen] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [isSavingButton, setIsSavingButton] = useState({ type: null, value: false })

  const wires = useMemo(() => wiresData?.data, [wiresData])

  const { clientsData } = useMemo(
    () => ({
      clientsData: clients?.data?.data,
    }),
    [clients],
  )

  const {
    filters,
    handleFiltersChange,
    orderBy,
    handleOrderChange,
    activeItems,
    resetActiveItems,
    activeItem,
    setActiveItem,
    setActiveItems,
    handleSelectRow,
  } = useTable({
    tableId: `mapping-table-${type}`,
    filtersDefaults,
    sortDefault: {
      field: null,
      direction: null,
    },
  })

  const history = useHistory()
  const { search }: { search: string } = useLocation()
  const qs = useMemo(() => queryString.parse(search), [search])

  const handleClickMenu = useCallback((wire, event: React.MouseEvent<HTMLElement>) => {
    setSelectedRow(wire)
    setAnchorEl(event.currentTarget)
    setActionsMenuOpen(true)
  }, [])
  const handleCloseMenu = useCallback(() => {
    setSelectedRow(null)
    setAnchorEl(null)
    setActionsMenuOpen(false)
  }, [])

  useEffect(() => {
    if (!qs.activityId) {
      history.push(ROUTES.HOMEPAGE)
    }
  }, [qs, history])

  const debounceListWires = useMemo(
    () =>
      debounceEventHandler((values: any) => {
        listWires(values, type)
      }, 500),
    [listWires, type],
  )

  const refetchWiresList = useCallback(
    (type: RefreshType) => {
      if (qs.activityId) {
        debounceListWires({
          activityId: qs.activityId,
          filters,
          orderBy: orderBy.field,
          orderDirection: orderBy.direction,
          skipLoader: type === RefreshType.SkipLoader,
        })
      }
    },
    [qs.activityId, filters, orderBy, debounceListWires],
  )

  useEffect(() => {
    refetchWiresList(RefreshType.Refresh)
  }, [refetchWiresList])

  useEffect(() => {
    if (refreshCountAndType.count) {
      refetchWiresList(refreshCountAndType.type)
    }
  }, [refreshCountAndType, refetchWiresList, type])

  const loadMore = useCallback(() => {
    listWires(
      {
        loadMore: true,
        activityId: qs.activityId,
        filters,
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
        page: Math.ceil(wires?.length / PER_PAGE),
      },
      type,
    )
  }, [listWires, qs, wires, filters, orderBy, type])

  const handleSelectPrediction = useCallback(
    (id: string, value: string, skipLoader: boolean) => {
      updateWires({
        ids: [id],
        debtor: value,
        skipLoader: skipLoader,
      })
      handleRefresh(RefreshType.SkipLoader)
    },
    [updateWires, handleRefresh],
  )

  const activeWiresItems = useMemo(
    () => wires?.filter((_, index) => activeItems.includes(index)),
    [activeItems, wires],
  )

  const handleRestoreActive = useCallback(async () => {
    setIsSavingButton({ type: 'restore', value: true })
    await updateWires({
      ids: activeWiresItems.map(({ id }) => id),
      status: WireDataStatus.New,
    })
    resetActiveItems()
    setIsSavingButton({ type: null, value: false })
    handleRefresh(RefreshType.SkipLoader)
  }, [activeWiresItems, updateWires, handleRefresh, resetActiveItems])

  const handleArchiveActive = useCallback(() => {
    setIsPermanent(false)
    setIsDeleteModalShown(true)
  }, [])

  const handleDeleteActive = useCallback(() => {
    setIsPermanent(true)
    setIsDeleteModalShown(true)
  }, [])

  const handleDeleteActiveConfirm = useCallback(async () => {
    setIsSavingButton({ type: null, value: true })
    await updateWires({
      ids: activeWiresItems.map(({ id }) => id),
      status: WireDataStatus.Deleted,
      isPermanent,
    })
    setIsDeleteModalShown(false)
    setIsSavingButton({ type: null, value: false })
    resetActiveItems()
    handleRefresh(RefreshType.SkipLoader)
  }, [activeWiresItems, updateWires, handleRefresh, isPermanent, resetActiveItems])

  const handleDeleteActiveCancel = useCallback(() => {
    setIsDeleteModalShown(false)
    resetActiveItems()
  }, [resetActiveItems])

  const allClientsOptionsForChargeback = useMemo(() => {
    return clientsData?.map(({ clientName }) => ({
      value: clientName,
      label: clientName,
    }))
  }, [clientsData])

  const notMappedClientsOptions = useMemo(
    () =>
      clientsData
        ?.filter(({ bankAccountInfos }) => !bankAccountInfos || bankAccountInfos?.length === 0)
        .map(({ clientName }) => ({
          value: clientName,
          label: clientName,
        })),
    [clientsData],
  )

  const groupedClientsOptions = useMemo(
    () =>
      clientsData?.reduce((result, { clientName, bankAccountInfos }) => {
        bankAccountInfos?.forEach(({ bankAccountNumber }) => {
          if (bankAccountNumber && bankAccountNumber !== '0') {
            if (!result[bankAccountNumber]) {
              result[bankAccountNumber] = []
            }
            if (!result[bankAccountNumber].find(({ value }) => value === clientName)) {
              result[bankAccountNumber].push({
                value: clientName,
                label: clientName,
              })
            }
          }
        })
        return result
      }, {} as { [key: string]: Array<{ value: string; label: string }> }),
    [clientsData],
  )

  const loadDebtors = useCallback(
    async (inputValue: string) => {
      const res = await listDebtors({
        name: inputValue,
      })
      return res.data.map(({ id, name: value }) => ({
        id,
        value,
        label: value,
      }))
    },
    [listDebtors],
  )

  const totalAmount = useMemo(() => {
    const total = wires
      ?.filter((_, index) => activeItems.includes(index))
      .reduce(
        (result, row) => {
          result.paymentAmount += row.paymentAmount || 0

          return result
        },
        {
          paymentAmount: 0,
        },
      )
    return `$${formatPrice(total?.paymentAmount)}`
  }, [wires, activeItems])

  const initialValues = useMemo(
    () => ({
      wires: (wires || []).map((wire) => ({
        id: wire.id,
        isPossibleMultipleClients: wire.isPossibleMultipleClients,
        debtor: wire.debtor,
        client: wire.clientName || null,
        status: wire.status,
      })),
    }),
    [wires],
  )

  const handleSelectPredictions = useCallback(async () => {
    setIsSavingButton({ type: 'prediction', value: true })
    const items = activeWiresItems.filter(({ prediction }) => Boolean(prediction))
    await updateWires({
      ids: items.map(({ id }) => id),
      debtorMapping: items.reduce(
        (result, item) => ({
          ...result,
          [item.id]: item.prediction,
        }),
        {},
      ),
    })
    resetActiveItems()
    setIsSavingButton({ type: null, value: false })
    handleRefresh(RefreshType.SkipLoader)
  }, [activeWiresItems, updateWires, handleRefresh, resetActiveItems])

  const handleUpdateWire = useCallback(
    (data: any) => {
      const activeWiresIds = activeWiresItems.map(({ id }) => id)
      if (data.clientName) {
        updateWire(data.id, {
          clientName: data?.clientName,
        })
      } else if (activeWiresIds.length > 1 && activeWiresIds.includes(data.id)) {
        updateWires({
          ids: activeWiresIds,
          debtor: data?.debtor,
          clientName: data?.clientName,
        })
      } else {
        updateWire(data.id, {
          debtor: data?.debtor,
          clientName: data?.clientName,
        })
      }
      resetActiveItems()
      handleRefresh(RefreshType.SkipLoader)
    },
    [updateWire, updateWires, activeWiresItems, handleRefresh, resetActiveItems],
  )

  const initialFilterOptions = useMemo(
    () =>
      WIRE_MAPPING_FILTERS_CONFIG.map((filter) => ({
        ...filter,
        options:
          filter.field === 'clientName'
            ? clientsData?.map(({ clientName }) => ({
                value: clientName,
                label: clientName,
              }))
            : filter.options,
      })),
    [clientsData],
  )

  return (
    <Form
      onSubmit={() => ({})}
      initialValues={initialValues}
      mutators={mutators}
      render={({ form, values }: { form: any; values: any; handleSubmit: any }) => (
        <TableContainer
          className={styles.wiresDebtorsTable}
          isActivable={type === WireDataStatus.New}
          rowIdPrefix={`mapping-table-row-${type}`}
          onActiveRowsChange={setActiveItems}
          onActiveRowChange={setActiveItem}
        >
          <Form
            mutators={{
              setFieldData: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value)
              },
            }}
            validate={filtersValidate}
            onSubmit={handleFiltersChange}
            initialValues={filters}
            render={({
              values: filterValues,
              handleSubmit: handleFilterSubmit,
              form: { mutators: filterMutators },
            }) => (
              <FilterContainer
                filters={initialFilterOptions}
                handleSubmit={handleFilterSubmit}
                mutators={filterMutators}
                title={
                  type === WireDataStatus.New
                    ? 'Wires mapping'
                    : type === WireDataStatus.Negative
                    ? 'Negative wires'
                    : 'Non-client related cash'
                }
                values={filterValues}
                appliedFilters={filters}
                actions={
                  <Box display="flex" position="relative" alignItems="center">
                    {type === WireDataStatus.New &&
                      (!isTableLoading ? (
                        <MappingProgress value={mappingProgress || 0} />
                      ) : (
                        <Skeleton height={15} width={300} />
                      ))}
                  </Box>
                }
              />
            )}
          />
          <Table>
            <TableHead>
              <TableFiltersRow
                filters={initialFilterOptions}
                orderBy={orderBy}
                handleOrderChange={handleOrderChange}
              />
            </TableHead>
            <TableBody id={`mapping-table-body-${type}`}>
              {isTableLoading ? (
                <TableLoader columnsCount={8} />
              ) : (
                <>
                  <InfiniteScroll
                    dataLength={wires?.length || 0}
                    next={loadMore}
                    hasMore={wires?.length < (wires?.[0]?.totalViewCount || 0)}
                    loader={<TableLoader columnsCount={8} rowsCount={1} />}
                    scrollableTarget={`mapping-table-body-${type}`}
                  >
                    <FieldArray name="wires">
                      {({ fields }) => (
                        <>
                          {fields.map((name, index) => {
                            const wire = wires[index]
                            if (!wire) {
                              return null
                            }

                            const isIDBLoan = wire.accountNumber === IDB_LOAN_ACCOUNT_NUMBER

                            return (
                              <TableRow
                                id={`mapping-table-row-${type}-${index}`}
                                data-index={index}
                                className={cn('activableRow', {
                                  activeRow: activeItems.includes(index),
                                  currentActiveRow: activeItem === index,
                                  [styles.withCheckbox]: isCheckboxShown,
                                  [styles.validRow]:
                                    !!wire.clientName &&
                                    !!wire.debtor &&
                                    type !== WireDataStatus.Negative,
                                  [styles.negativeRow]:
                                    type === WireDataStatus.Negative || wire.isNegativePerClient,
                                })}
                                onClick={(event) => handleSelectRow(event, index)}
                              >
                                <TableCell className={genericSs.tableTextRight}>
                                  <div className={styles.columnWrapper}>
                                    {wire.isPotentialDuplicate && (
                                      <Tooltip
                                        title="Multiple wires with the same account number, payment amount and record date"
                                        placement="top"
                                      >
                                        <WarningIcon className={styles.warningIcon} />
                                      </Tooltip>
                                    )}
                                    {formatDate(wire.recordDate)}
                                  </div>
                                </TableCell>
                                <TableCell className={genericSs.tableTextLeft}>
                                  <div className={styles.wiresDebtorsMemo}>
                                    <Tooltip
                                      title={wire.originalMemo}
                                      placement="top"
                                      disableTouchListener
                                    >
                                      <span>{wire.originalMemo}</span>
                                    </Tooltip>
                                  </div>
                                </TableCell>
                                <TableCell className={genericSs.tableTextLeft}>
                                  {wire.clientName &&
                                  !wire.isPossibleMultipleClients &&
                                  !isIDBLoan ? (
                                    <span>{wire.clientName}</span>
                                  ) : (
                                    <Autocomplete
                                      inTable
                                      key={`client-${values.wires[index].id}-${index}`}
                                      openOnAutoFocus={false}
                                      clearIcon={null}
                                      label=""
                                      className={cn({
                                        focusableInput: type === WireDataStatus.New,
                                      })}
                                      tabIndex={2 * index}
                                      name={`${name}.client`}
                                      value={
                                        wire.clientName
                                          ? {
                                              value: wire.clientName,
                                              label: wire.clientName,
                                            }
                                          : null
                                      }
                                      placeholder="Client"
                                      options={
                                        isIDBLoan
                                          ? allClientsOptionsForChargeback
                                          : wire.isPossibleMultipleClients ||
                                            groupedClientsOptions?.[wire.accountNumber]?.length > 0
                                          ? groupedClientsOptions?.[wire.accountNumber]
                                          : notMappedClientsOptions
                                      }
                                      getOptionValue={(option) => option.value}
                                      disabled={isDeleted}
                                      onChange={(event, value) => {
                                        handleUpdateWire({
                                          id: wire.id,
                                          clientName:
                                            typeof value === 'string' ? value : value?.value,
                                        })
                                      }}
                                    />
                                  )}
                                </TableCell>
                                <TableCell className={genericSs.tableTextLeft}>
                                  <CreatableSelectField
                                    inTable
                                    label=""
                                    name={`${name}.debtor`}
                                    placeholder="Customer"
                                    tabIndex={2 * index + 1}
                                    className={cn({
                                      focusableInput: type === WireDataStatus.New,
                                      [styles.validInput]: !!wire.debtor,
                                    })}
                                    onAddValue={(debtor: string) => {
                                      addEntityInfo(debtor, wire?.clientName)
                                    }}
                                    options={[]}
                                    getOptionValue={(option) => option.value}
                                    disabled={isDeleted}
                                    value={
                                      wire.debtor
                                        ? {
                                            value: wire.debtor,
                                            label: wire.debtor,
                                          }
                                        : null
                                    }
                                    isAsync
                                    loadOptions={loadDebtors}
                                    onChangeCustom={async (event, newValue: any) => {
                                      if (!newValue) {
                                        handleUpdateWire({
                                          id: wire.id,
                                          debtor: '',
                                          skipLoader: true,
                                        })
                                      } else if (newValue.id) {
                                        handleUpdateWire({
                                          id: wire.id,
                                          debtor: newValue?.value,
                                          skipLoader: true,
                                        })
                                      } else if (newValue.value) {
                                        const result = await addEntityInfo(
                                          newValue.value,
                                          wire?.clientName,
                                        )
                                        if (!!result?.error) {
                                          return
                                        }
                                        handleUpdateWire({
                                          id: wire.id,
                                          debtor: newValue?.value,
                                          skipLoader: true,
                                        })
                                      }
                                      form.reset()
                                    }}
                                  />
                                </TableCell>
                                <TableCell className={genericSs.tableTextLeft}>
                                  {wire.prediction && !wire.debtor && (
                                    <div
                                      className={cn(styles.predictionCard, {
                                        [styles.predictionCardNewValue]: !wire.predictionEntityId,
                                      })}
                                      onClick={() =>
                                        handleSelectPrediction(wire.id, wire.prediction, true)
                                      }
                                    >
                                      {wire.predictionEntityId ? (
                                        wire.prediction
                                      ) : (
                                        <Tooltip
                                          title="Create new entity"
                                          placement="top"
                                          disableTouchListener
                                        >
                                          <span>+ {wire.prediction}</span>
                                        </Tooltip>
                                      )}
                                    </div>
                                  )}
                                </TableCell>
                                <TableCell className={genericSs.tableTextRight}>
                                  {wire.accountNumber}
                                </TableCell>
                                <TableCell className={genericSs.tableTextRight}>
                                  {formatter.format(wire.paymentAmount)}
                                </TableCell>
                                <TableCell className={genericSs.tableTextLeft}>
                                  <Box display="inline-box" ml={1}>
                                    <MenuIcon
                                      onClick={(event) => handleClickMenu(wire, event)}
                                      size="small"
                                    />
                                  </Box>
                                </TableCell>
                              </TableRow>
                            )
                          })}
                        </>
                      )}
                    </FieldArray>
                  </InfiniteScroll>
                  <Menu
                    open={actionsMenuOpen}
                    anchorEl={anchorEl}
                    onClose={handleCloseMenu}
                    className={styles.actionsMenu}
                  >
                    {activeItems.length <= 1 && isDeleted && (
                      <MenuItem
                        disabled={!selectedRow?.clientName}
                        onClick={() => {
                          restoreWire(selectedRow)
                          handleCloseMenu()
                        }}
                      >
                        Restore
                      </MenuItem>
                    )}

                    {!isDeleted && type === WireDataStatus.New && (
                      <MenuItem
                        onClick={() => {
                          markNegativeWire(selectedRow)
                          handleCloseMenu()
                        }}
                      >
                        Mark as negative
                      </MenuItem>
                    )}

                    {!isDeleted && type === WireDataStatus.Negative && (
                      <MenuItem
                        disabled={!selectedRow?.clientName}
                        onClick={() => {
                          restoreWire(selectedRow)
                          handleCloseMenu()
                        }}
                      >
                        Mark as positive
                      </MenuItem>
                    )}

                    {activeItems.length <= 1 && !isDeleted && (
                      <MenuItem
                        onClick={() => {
                          archiveWire(selectedRow)
                          handleCloseMenu()
                        }}
                      >
                        Mark as non client related
                      </MenuItem>
                    )}

                    {activeItems.length <= 1 && (
                      <MenuItem
                        onClick={() => {
                          deleteWire(selectedRow)
                          handleCloseMenu()
                        }}
                      >
                        Delete
                      </MenuItem>
                    )}
                  </Menu>
                </>
              )}
            </TableBody>
            <MultiSelectToolbar
              activeItems={activeItems}
              totalSum={totalAmount}
              resetActiveItems={resetActiveItems}
            >
              <Box display="flex" alignItems="center" gap={1} mr={2}>
                <Button
                  className={styles.actionButton}
                  isLoading={isSavingButton?.type === 'prediction' && isSavingButton?.value}
                  color="primary"
                  variant="outlined"
                  onClick={handleSelectPredictions}
                >
                  Prediction
                </Button>
                {(isDeleted || type === WireDataStatus.Negative) && (
                  <Button
                    className={styles.actionButton}
                    isLoading={isSavingButton?.type === 'restore' && isSavingButton?.value}
                    color="primary"
                    variant="outlined"
                    onClick={handleRestoreActive}
                  >
                    {isDeleted ? 'Recover' : 'Mark as positive'}
                  </Button>
                )}

                {!isDeleted && (
                  <Button
                    className={styles.actionButton}
                    color="primary"
                    variant="outlined"
                    onClick={handleArchiveActive}
                  >
                    Non-client Cash
                  </Button>
                )}

                <Button
                  className={styles.actionButton}
                  color="primary"
                  variant="outlined"
                  onClick={handleDeleteActive}
                >
                  Delete
                </Button>
              </Box>
            </MultiSelectToolbar>
          </Table>

          {isDeleteModalShown && (
            <WarningModal
              warningMessage={
                'Wires will be ' +
                (isPermanent ? 'deleted permanently' : 'marked as non client related cash') +
                '. Are you sure?'
              }
              onConfirm={handleDeleteActiveConfirm}
              onCancel={handleDeleteActiveCancel}
              confirmText="Confirm"
              cancelText="Cancel"
              isLoading={isSavingButton.value}
            />
          )}
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <SaveState isSaving={isTableSaving} isSaved={isTableSaved} />
          </Box>
        </TableContainer>
      )}
    />
  )
}

export default CollectionsWiresTable
