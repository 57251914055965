import React, { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router'
import Box from '@mui/material/Box'
import queryString from 'query-string'

import { ReactComponent as HomeIcon } from '@assets/images/home-icon.svg'

import { ROUTES } from '../../constants/routes'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'
import Breadcrumbs from '../../components/Common/Breadcrumbs'
import Card from '../../components/Common/Card'
import Empty from '../../components/Common/Empty'
import Tabs from '../../components/Common/Tabs'
import UWDefaultNotes from '../../components/UWDefaultNotes'

const BREADCRUMBS = [
  {
    link: ROUTES.DUE_DILIGENCE_HOME,
    Icon: HomeIcon,
  },
  {
    link: ROUTES.DUE_DILIGENCE_SETTINGS,
    title: 'Diligence Settings',
  },
]

const TABS = ['Notes', 'Document Requests', 'LCR Commentary']

const UWSettingsPage = () => {
  useSetPageTitle('Diligence Settings')

  const { search }: { search: string } = useLocation()
  const history = useHistory()

  const { tab = TABS[0], ...restSearch } = queryString.parse(search) as { tab: string }

  const handleChangeTab = useCallback(
    (selectedTab: string) => {
      history.replace({
        search: queryString.stringify({
          tab: selectedTab,
          ...restSearch,
        }),
      })
    },
    [restSearch, history],
  )

  return (
    <Box pt={1} pr={2}>
      <Card
        title={
          <Box display="flex" flexDirection="column" gap={4}>
            <Breadcrumbs breadcrumbs={BREADCRUMBS} noMarginLeft />

            <Tabs tabs={TABS} selected={tab} handleChange={handleChangeTab} />
          </Box>
        }
        withBorder={false}
      >
        {tab === TABS[0] ? <UWDefaultNotes /> : <Empty message="Will be available soon" />}
      </Card>
    </Box>
  )
}

export default UWSettingsPage
