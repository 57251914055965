import React, { useEffect, useMemo, useCallback, useState } from 'react'
import { useParams } from 'react-router'
import { generatePath } from 'react-router-dom'
import queryString, { ParsedQuery } from 'query-string'
import Grid from '@mui/material/Grid'
import { ReactComponent as HomeIcon } from '@assets/images/home-icon.svg'
import { ROUTES } from '../../constants/routes'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'
import { IParticipant } from '@common/interfaces/participant'
import { ILoadingData } from '../../redux/types'
import Breadcrumbs from '../../components/Common/Breadcrumbs'
import UserManagement from '../../components/Client/UserManagement'
import ParticipantGeneralSettings from '../../components/ParticipantGeneralSettings'
import { useLocation, useHistory } from 'react-router'
import Tabs from '../../components/Common/Tabs'
import Box from '@mui/material/Box'
import styles from './ParticipantSettingsPage.module.scss'
import { useLoadInfo } from '../../hooks/useLoadInfo'

const TABS = ['General', 'Users']

interface IProps {
  participantInfo: ILoadingData<IParticipant>
  showParticipant: (id: string) => void
}

const ParticipantSettingsPage = ({ participantInfo, showParticipant }: IProps) => {
  const { id } = useParams<{ id: string }>()
  const { search }: { search: string } = useLocation()
  const history = useHistory()

  const { tab = TABS[0] } = queryString.parse(search) as { tab: string }
  const [queryParams, setQueryParams] = useState<ParsedQuery>({ tab })
  const handleChangeTab = useCallback(
    (selectedTab: string) => {
      setQueryParams({ tab: selectedTab })
    },
    [setQueryParams],
  )
  useEffect(() => {
    history.replace({
      search: queryString.stringify(queryParams),
    })
  }, [queryParams, history])

  const { data: participantInfoData } = useMemo(() => participantInfo, [participantInfo])

  useLoadInfo({ id, info: participantInfoData, show: showParticipant })

  useSetPageTitle(participantInfoData?.participant || '')

  const breadcrumbs = useMemo(() => {
    return [
      {
        link: ROUTES.CLIENT_MANAGEMENT,
        Icon: HomeIcon,
      },
      {
        title: 'Participants',
        link: ROUTES.PARTICIPANTS_MANAGEMENT,
      },
      id && {
        link: generatePath(ROUTES.PARTICIPANTS_PAGE, { id }),
        title: participantInfoData?.participant,
      },
      id && {
        title: 'Settings',
        link: generatePath(ROUTES.PARTICIPANT_SETTINGS, { id }),
      },
    ].filter(Boolean)
  }, [participantInfoData?.participant, id])
  return (
    <Grid container py={3} pr={2} className={styles.pageContainer}>
      <Grid item xs={6} className={styles.itemContainer}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </Grid>
      <Grid item xs={6} className={styles.tabItemContainer}>
        <Box className={styles.tabsContainer}>
          <Tabs tabs={TABS} selected={tab} handleChange={handleChangeTab} />
        </Box>
      </Grid>

      <Box className={tab === TABS[0] ? styles.generalBodyContainer : styles.userBodyContainer}>
        {tab === TABS[0] && <ParticipantGeneralSettings />}

        {tab === TABS[1] && (
          <UserManagement participantId={id} participantClients={participantInfoData?.allClients} />
        )}
      </Box>
    </Grid>
  )
}

export default ParticipantSettingsPage
