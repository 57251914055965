import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ClientAccountActivityEditModal from './ClientAccountActivityEditModal'

import { clientInfo } from '../../../redux/clientInfo/selectors'
import { updateFunding, updatePassthroughs } from '../../../redux/clientInfo/actions'
import { updateFee } from '../../../redux/fee/actions'
import { updateAdjustment } from '../../../redux/adjustment/actions'
import { loanBalanceStartDate } from '../../../redux/loanBalance/selectors'
import { updateAggregationRow } from '../../../redux/collections/actions'
import { addEntityInfo, listEntityInfo } from '../../../redux/entityInfo/actions'
import { listBankAccounts } from '../../../redux/bankAccount/actions'
import { list as banksAccountData } from '../../../redux/bankAccount/selectors'
import { list as listClients } from '../../../redux/clientInfo/actions'
import { clients } from '../../../redux/clientInfo/selectors'

const selector = createStructuredSelector({
  loanBalanceStartDate,
  clientInfo,
  banksAccountData,
  clients,
})

const actions = {
  updateFee,
  updateAdjustment,
  updateFunding,
  updatePassthroughs,
  updateAggregationRow,
  addEntityInfo,
  listEntityInfo,
  listBankAccounts,
  listClients,
}

export default connect(selector, actions)(ClientAccountActivityEditModal)
