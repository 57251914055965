import Base from './base'

export default class DueDiligence extends Base {
  getStats() {
    return this.apiClient.get('due-diligence/stats')
  }

  listProspects() {
    return this.apiClient.get('due-diligence/prospects')
  }

  list(data: object) {
    return this.apiClient.get('due-diligence', data)
  }

  show(id: string) {
    return this.apiClient.get(`due-diligence/${id}`)
  }

  update(id: string, data: object) {
    return this.apiClient.patch(`due-diligence/${id}`, data)
  }

  showReporting(id: string, params: object) {
    return this.apiClient.get(`due-diligence/${id}/documents`, params)
  }

  updateReporting(id: string, type: string, params: object) {
    return this.apiClient.patch(`due-diligence/${id}/documents/${type}`, params)
  }

  showDocuments(id: string, type: string, params: object) {
    return this.apiClient.get(`due-diligence/${id}/documents/${type}`, params)
  }

  processDocuments(id: string, type: string, data: object) {
    return this.apiClient.post(`due-diligence/${id}/documents/${type}`, data)
  }

  uploadDocuments(id: string, type: string, data: FormData) {
    return this.apiClient.put(`due-diligence/${id}/documents/${type}`, data)
  }

  updateDocument(id: string, type: string, flowId: string, data: object) {
    return this.apiClient.patch(`due-diligence/${id}/documents/${type}/${flowId}`, data)
  }

  unprocessDocument(id: string, type: string, flowId: string) {
    return this.apiClient.delete(`due-diligence/${id}/documents/${type}/${flowId}`)
  }

  listAliasMapping(id: string, params: object) {
    return this.apiClient.get(`due-diligence/${id}/mapping`, params)
  }

  clientInfo() {
    return this.apiClient.get('due-diligence/me')
  }

  showDocumentRequestsTypes(clientId: string) {
    return this.apiClient.get(`due-diligence/${clientId}/document-requests-types`)
  }

  showDocumentRequests(clientId?: string, params: object = {}) {
    return this.apiClient.get(`due-diligence/${clientId || 'me'}/document-requests`, params)
  }

  createDocumentRequest(clientId: string, data: object) {
    return this.apiClient.post(`due-diligence/${clientId}/document-requests`, data)
  }

  updateDocumentRequest(clientId: string, documentRequestId: string, data: object) {
    return this.apiClient.patch(
      `due-diligence/${clientId}/document-requests/${documentRequestId}`,
      data,
    )
  }

  updateDocumentsRequests(clientId: string, data: object) {
    return this.apiClient.patch(`due-diligence/${clientId}/document-requests`, data)
  }

  deleteDocumentRequest(clientId: string, documentRequestId: string) {
    return this.apiClient.delete(`due-diligence/${clientId}/document-requests/${documentRequestId}`)
  }

  deleteDocument(id: string) {
    return this.apiClient.delete(`due-diligence/${id}/documents-file`)
  }

  deleteDocumentsRequests(clientId: string, documentRequestIds: string[]) {
    return this.apiClient.delete(`due-diligence/${clientId}/document-requests/`, {
      documentRequestIds,
    })
  }

  getDocumentRequestsSharedLink(documentRequestId: string, clientId?: string) {
    return this.apiClient.get(
      `due-diligence/${clientId || 'me'}/document-requests/${documentRequestId}/shared-link`,
    )
  }

  uploadDocumentRequestsFiles(data: FormData, clientId?: string) {
    return this.apiClient.put(`due-diligence/${clientId || 'me'}/document-requests`, data)
  }

  createDocumentRequestComment(documentRequestId: string, data: object, clientId?: string) {
    return this.apiClient.post(
      `due-diligence/${clientId || 'me'}/document-requests/${documentRequestId}/comments`,
      data,
    )
  }

  createDocument(id: string) {
    return this.apiClient.post(`due-diligence/${id}/documents-file`)
  }

  updateDocumentRequestComment(
    documentRequestId: string,
    commentId: string,
    data: object,
    clientId?: string,
  ) {
    return this.apiClient.patch(
      `due-diligence/${
        clientId || 'me'
      }/document-requests/${documentRequestId}/comments/${commentId}`,
      data,
    )
  }

  deleteDocumentRequestComment(documentRequestId: string, commentId: string, clientId?: string) {
    return this.apiClient.delete(
      `due-diligence/${
        clientId || 'me'
      }/document-requests/${documentRequestId}/comments/${commentId}`,
    )
  }

  getCompanyInfo() {
    return this.apiClient.get('due-diligence/me/company-background')
  }

  updateCompanyInfo(data: object) {
    return this.apiClient.put('due-diligence/me/company-background', data)
  }

  listTeamContacts(params: object, clientId: string = 'me') {
    return this.apiClient.get(`due-diligence/${clientId}/team/contacts`, params)
  }

  addTeamContact(data: object) {
    return this.apiClient.post('due-diligence/me/team/contacts', data)
  }

  updateTeamContact(id: string, data: object) {
    return this.apiClient.put(`due-diligence/me/team/contacts/${id}`, data)
  }

  deleteTeamContact(id: string) {
    return this.apiClient.delete(`due-diligence/me/team/contacts/${id}`)
  }

  listTeamExecutives(params: object, clientId: string = 'me') {
    return this.apiClient.get(`due-diligence/${clientId}/team/executives`, params)
  }

  addTeamExecutive(data: object) {
    return this.apiClient.post('due-diligence/me/team/executives', data)
  }

  updateTeamExecutive(id: string, data: object) {
    return this.apiClient.put(`due-diligence/me/team/executives/${id}`, data)
  }

  deleteTeamExecutive(id: string) {
    return this.apiClient.delete(`due-diligence/me/team/executives/${id}`)
  }

  listTeamBoardMembers(params: object, clientId: string = 'me') {
    return this.apiClient.get(`due-diligence/${clientId}/team/board-members`, params)
  }

  addTeamBoardMember(data: object) {
    return this.apiClient.post('due-diligence/me/team/board-members', data)
  }

  updateTeamBoardMember(id: string, data: object) {
    return this.apiClient.put(`due-diligence/me/team/board-members/${id}`, data)
  }

  deleteTeamBoardMember(id: string) {
    return this.apiClient.delete(`due-diligence/me/team/board-members/${id}`)
  }

  listTeamReferences(params: object, clientId: string = 'me') {
    return this.apiClient.get(`due-diligence/${clientId}/team/references`, params)
  }

  addTeamReference(data: object) {
    return this.apiClient.post('due-diligence/me/team/references', data)
  }

  updateTeamReference(id: string, data: object) {
    return this.apiClient.put(`due-diligence/me/team/references/${id}`, data)
  }

  deleteTeamReference(id: string) {
    return this.apiClient.delete(`due-diligence/me/team/references/${id}`)
  }

  loadVendors(data: object) {
    return this.apiClient.get('due-diligence/vendors', data)
  }

  updateTeamAdvisorsInfo(data: object) {
    return this.apiClient.post('due-diligence/me/team/advisors', data)
  }

  updateFinancialsVendorsInfo(data: object) {
    return this.apiClient.post('due-diligence/me/financials/vendors', data)
  }

  getFinancialsCodatLink() {
    return this.apiClient.get('due-diligence/me/financials/codat-link')
  }

  listFinancialsInventoryLocations(params: object, clientId: string = 'me') {
    return this.apiClient.get(`due-diligence/${clientId}/financials/inventory-locations`, params)
  }

  addFinancialsInventoryLocation(data: object) {
    return this.apiClient.post('due-diligence/me/financials/inventory-locations', data)
  }

  updateFinancialsInventoryLocation(id: string, data: object) {
    return this.apiClient.put(`due-diligence/me/financials/inventory-locations/${id}`, data)
  }

  deleteFinancialsInventoryLocation(id: string) {
    return this.apiClient.delete(`due-diligence/me/financials/inventory-locations/${id}`)
  }

  listFinancialsBankAccounts(params: object, clientId: string = 'me') {
    return this.apiClient.get(`due-diligence/${clientId}/financials/bank-accounts`, params)
  }

  addFinancialsBankAccount(data: object) {
    return this.apiClient.post('due-diligence/me/financials/bank-accounts', data)
  }

  updateFinancialsBankAccount(id: string, data: object) {
    return this.apiClient.put(`due-diligence/me/financials/bank-accounts/${id}`, data)
  }

  deleteFinancialsBankAccount(id: string) {
    return this.apiClient.delete(`due-diligence/me/financials/bank-accounts/${id}`)
  }

  runBBC(id: string) {
    return this.apiClient.post(`due-diligence/${id}/run-bbc`)
  }

  refreshAutoReporting(id: string, data: object) {
    return this.apiClient.post(`due-diligence/${id}/refresh-auto-reporting`, data)
  }

  listLCRCommentary(id: string, data: object) {
    return this.apiClient.get(`due-diligence/${id}/lcr-commentary`, data)
  }

  addLCRCommentary(id: string, data: object) {
    return this.apiClient.post(`due-diligence/${id}/lcr-commentary`, data)
  }

  updateLCRCommentary(id: string, lcrCommentaryId: string, data: object) {
    return this.apiClient.put(`due-diligence/${id}/lcr-commentary/${lcrCommentaryId}`, data)
  }

  deleteLCRCommentary(id: string, lcrCommentaryId: string) {
    return this.apiClient.delete(`due-diligence/${id}/lcr-commentary/${lcrCommentaryId}`)
  }

  getHistoricalLineTurn(id: string, data: object) {
    return this.apiClient.get(`due-diligence/${id}/historical-line-turn`, data)
  }

  getHistoricalLineTurnOptions(id: string) {
    return this.apiClient.get(`due-diligence/${id}/historical-line-turn-options`)
  }

  getDefaultNotesOptions() {
    return this.apiClient.get('due-diligence/default-notes/option')
  }

  listDefaultNotes(params: object) {
    return this.apiClient.get('due-diligence/default-notes', params)
  }

  createDefaultNote(data: object) {
    return this.apiClient.post('due-diligence/default-notes', data)
  }

  updateDefaultNote(id: string, data: object) {
    return this.apiClient.put(`due-diligence/default-notes/${id}`, data)
  }

  deleteDefaultNote(id: string) {
    return this.apiClient.delete(`due-diligence/default-notes/${id}`)
  }
}
