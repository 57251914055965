import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import { useParams } from 'react-router'
import cn from 'classnames'
import Tooltip from '@mui/material/Tooltip'
import InfiniteScroll from 'react-infinite-scroll-component'

import styles from './BBCAPDetailRow.module.scss'
import genericSs from '@styles/generic.module.scss'

import MultiselectRow from '../MultiselectRow'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TableFiltersRow from '../Common/TableFiltersRow'
import { IApSummaryStats, ReportingFlow } from '@common/interfaces/bbc'
import { debounceEventHandler, formatPrice } from '../../helpers/helpers'
import ChangedValue from '../ChangedValue'
import { BBC_AP_SUMMARY_DETAILS_LIST_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import TableLoader from '../Common/TableLoader'
import useSummaryRow from '../../hooks/useSummaryRow'
import useTable from '../../hooks/useTable'

interface IProps {
  apDetailStats: IApSummaryStats[]
  getBBCAPDetailStats: (id: string, data: object) => void
  filters: any
  recordDate: string
  isModalShown: boolean
  creditor: string
  reportingFlow: ReportingFlow
  clientName?: string
  compareDate?: string
}

const BBCAPDetailRow = ({
  apDetailStats,
  getBBCAPDetailStats,
  filters,
  isModalShown,
  recordDate,
  creditor,
  reportingFlow,
  clientName,
  compareDate,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const wrapperRef = useRef(null)

  const [isLoading, setIsLoading] = useState(true)

  const {
    handleSelectRow,
    setActiveItems,
    setActiveItem,
    handleOrderChange,
    orderBy,
    activeItem,
    activeItems,
  } = useTable({
    tableId: 'ap-detail-row',
    sortDefault: {
      field: 'invoice',
      direction: 'ASC',
    },
  })
  const isClientUserPage = useMemo(
    () => reportingFlow === ReportingFlow.ClientUserPage,
    [reportingFlow],
  )

  const filtersConfig = useMemo(
    () =>
      BBC_AP_SUMMARY_DETAILS_LIST_FILTERS_CONFIG.filter(
        (config) =>
          !isClientUserPage || (isClientUserPage && !['daysPastInvoice'].includes(config.field)),
      ),
    [isClientUserPage],
  )

  const debounceListApSummary = useMemo(
    () =>
      debounceEventHandler(async (data: any) => {
        !data.loadMore && setIsLoading(true)
        await getBBCAPDetailStats(id, {
          ...data,
          recordDate,
          compareDate,
          creditor,
          reportingFlow,
          clientName,
          nestedRows: {
            keys: ['creditor'],
          },
        })
        setIsLoading(false)
      }, 500),
    [id, getBBCAPDetailStats, recordDate, creditor, reportingFlow, compareDate, clientName],
  )

  const loadMore = useCallback(() => {
    debounceListApSummary({
      loadMore: true,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
      recordDate,
      page: Math.ceil(apDetailStats?.length / PER_PAGE),
    })
  }, [orderBy, filters, recordDate, debounceListApSummary, apDetailStats])

  const totalCount = useMemo(() => apDetailStats?.[0]?.totalCount, [apDetailStats])

  useEffect(() => {
    !apDetailStats &&
      debounceListApSummary({
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
        filters,
        page: 0,
      })
  }, [orderBy, filters, debounceListApSummary, apDetailStats])

  useEffect(() => {
    debounceListApSummary({
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
      page: 0,
    })
  }, [orderBy, filters, debounceListApSummary])

  const totalRow = useSummaryRow(apDetailStats, activeItems, {
    sumFields: [
      'daysPastInvoice',
      'ap_1To_30Days',
      'ap_31To_60Days',
      'ap_61To_90Days',
      'ap_91PlusDays',
      'total',
    ],
    averageFields: ['daysPastInvoice'],
    percentageFields: [{ field: 'percent', totalField: 'total', decimals: 2 }],
  })

  return (
    <TableContainer
      className={styles.table}
      onActiveRowsChange={setActiveItems}
      onActiveRowChange={setActiveItem}
      hasFooter
      size="small"
    >
      <Table ref={wrapperRef}>
        <TableHead>
          <TableFiltersRow
            filters={filtersConfig}
            orderBy={orderBy}
            handleOrderChange={handleOrderChange}
          />
        </TableHead>
        <TableBody id={creditor} className={styles.scrollableDiv}>
          {isLoading ? (
            <TableLoader columnsCount={filtersConfig?.length} />
          ) : (
            <InfiniteScroll
              dataLength={apDetailStats?.length || 0}
              next={loadMore}
              hasMore={apDetailStats?.length < totalCount}
              loader={<TableLoader columnsCount={filtersConfig?.length} rowsCount={1} />}
              scrollableTarget={creditor}
            >
              {apDetailStats?.map((item, index) => {
                return (
                  <TableRow
                    data-index={index}
                    className={cn('activableRow', {
                      activeRow: activeItems.includes(index),
                      currentActiveRow: activeItem === index,
                    })}
                    onClick={(event) => handleSelectRow(event, index)}
                  >
                    <TableCell className={genericSs.tableTextLeft}>
                      <Tooltip
                        title={item.memo || ''}
                        placement="top"
                        disableHoverListener={item.memo?.length < 10 || !item.memo}
                        disableTouchListener
                      >
                        <span>{item.memo}</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell className={genericSs.tableTextLeft}>
                      <Tooltip
                        title={item.invoice || ''}
                        placement="top"
                        disableHoverListener={item.invoice?.length < 10 || !item.invoice}
                        disableTouchListener
                      >
                        <span>{item.invoice}</span>
                      </Tooltip>
                    </TableCell>
                    {!isClientUserPage && (
                      <TableCell className={genericSs.tableTextRight}>
                        <ChangedValue
                          className={styles.changedValue}
                          changedValue={item.daysPastInvoiceChanged}
                          isChip
                        />
                        {Math.round(+item?.daysPastInvoice)}
                      </TableCell>
                    )}
                    <TableCell className={genericSs.tableTextRight}>
                      {!isClientUserPage && (
                        <ChangedValue
                          className={styles.changedValue}
                          changedValue={item.ap_1To_30DaysChanged}
                          isChip
                        />
                      )}
                      <span className={genericSs.pricePrefix}>$</span>
                      {formatPrice(item.ap_1To_30Days)}
                    </TableCell>
                    <TableCell className={genericSs.tableTextRight}>
                      {!isClientUserPage && (
                        <ChangedValue
                          className={styles.changedValue}
                          changedValue={item.ap_31To_60DaysChanged}
                          isChip
                        />
                      )}
                      <span className={genericSs.pricePrefix}>$</span>
                      {formatPrice(item.ap_31To_60Days)}
                    </TableCell>
                    <TableCell className={genericSs.tableTextRight}>
                      {!isClientUserPage && (
                        <ChangedValue
                          className={styles.changedValue}
                          changedValue={item.ap_61To_90DaysChanged}
                          isChip
                        />
                      )}
                      <span className={genericSs.pricePrefix}>$</span>
                      {formatPrice(item.ap_61To_90Days)}
                    </TableCell>
                    <TableCell className={genericSs.tableTextRight}>
                      {!isClientUserPage && (
                        <ChangedValue
                          className={styles.changedValue}
                          changedValue={item.ap_91PlusDaysChanged}
                          isChip
                        />
                      )}
                      <span className={genericSs.pricePrefix}>$</span>
                      {formatPrice(item.ap_91PlusDays)}
                    </TableCell>
                    <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                      {!isClientUserPage && (
                        <ChangedValue
                          className={styles.changedValue}
                          changedValue={item.totalChanged}
                          isChip
                        />
                      )}
                      <span className={genericSs.pricePrefix}>$</span>
                      {formatPrice(item.total)}
                    </TableCell>
                    <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                      {!isClientUserPage && (
                        <ChangedValue
                          className={styles.changedValue}
                          isChip
                          type="percent"
                          changedValue={item.percentChanged}
                        />
                      )}
                      {(item.percent || 0).toFixed(2)}%
                    </TableCell>
                  </TableRow>
                )
              })}{' '}
            </InfiniteScroll>
          )}
          <MultiselectRow activeItems={activeItems}>
            {!isClientUserPage && (
              <>
                <TableCell />
                <TableCell className={genericSs.tableTextRight}>
                  {Math.round(totalRow?.daysPastInvoice)}
                </TableCell>
              </>
            )}
            <TableCell className={genericSs.tableTextRight}>
              <span className={genericSs.pricePrefix}>$</span>
              {formatPrice(totalRow?.ap_1To_30Days)}
            </TableCell>
            <TableCell className={genericSs.tableTextRight}>
              <span className={genericSs.pricePrefix}>$</span>
              {formatPrice(totalRow?.ap_31To_60Days)}
            </TableCell>
            <TableCell className={genericSs.tableTextRight}>
              <span className={genericSs.pricePrefix}>$</span>
              {formatPrice(totalRow?.ap_61To_90Days)}
            </TableCell>
            <TableCell className={genericSs.tableTextRight}>
              <span className={genericSs.pricePrefix}>$</span>
              {formatPrice(totalRow?.ap_91PlusDays)}
            </TableCell>
            <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
              <span className={genericSs.pricePrefix}>$</span>
              {formatPrice(totalRow?.total)}
            </TableCell>
            <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
              {(totalRow?.percent || 0).toFixed(2)}%
            </TableCell>
          </MultiselectRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default BBCAPDetailRow
