import { Action } from 'redux'
import { takeEvery, put } from 'redux-saga/effects'
import {
  UPDATE_ONGOING_REPORTING_SUCCESS,
  EXPORT_SALES_BY_SKU_VISUALIZATION_SUCCESS,
  EXPORT_SALES_BY_SKU_VISUALIZATION_FAILURE,
} from './actions'

import { showOngoingReporting, listInventoryMapping, listSalesBySkuVisualization } from './actions'
import { IResponse } from '@common/interfaces/request'
import { OngoingReportingType } from '@common/interfaces/bbc'
import { download } from '../../helpers/helpers'
import { calculateOngoingReportingFlags } from '../flag/actions'

export function* updateOngoingReportingSuccess() {
  yield takeEvery(UPDATE_ONGOING_REPORTING_SUCCESS, function* (data: IResponse & Action) {
    yield put(showOngoingReporting(data.data.id))
    if (data.data.type === OngoingReportingType.SalesBySKU) {
      yield put(listInventoryMapping(data.data.id))
      yield put(
        listSalesBySkuVisualization(data.data.id, {
          distribution: 'sku',
        }),
      )
    }
    yield put(calculateOngoingReportingFlags({ id: data.data.id }))
  })
}

export function* exportSalesBySkuVisualization() {
  yield takeEvery(
    EXPORT_SALES_BY_SKU_VISUALIZATION_SUCCESS,
    function* (action: IResponse & Action) {
      try {
        const fileName = action.data.name
        yield download(action.data, fileName)
      } catch (error) {
        yield put({ type: EXPORT_SALES_BY_SKU_VISUALIZATION_FAILURE, error })
      }
    },
  )
}
