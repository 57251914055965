import React, { useMemo } from 'react'
import Skeleton from '@mui/material/Skeleton'
import cn from 'classnames'

import styles from './ProspectReportingSummaryTermStructure.module.scss'

import { IProspectTerms } from '@common/interfaces/prospects'
import { LOAN_TYPES } from '@common/constants/client'
import {
  AR_SPECIFIC_TERMS_FIELDS,
  INVENTORY_SPECIFIC_TERMS_FIELDS,
  TERM_STRUCTURE_FIELDS_CONFIG,
} from '@common/constants/prospects'
import RenderedValue from '../RenderedValue'

interface IProps {
  field: string
  data: IProspectTerms
  previousData?: IProspectTerms | null
  percentWithScale?: boolean
  isFullRow?: boolean
}

const ProspectReportingSummaryTermStructureDataRow = ({
  field,
  data,
  previousData,
  percentWithScale,
  isFullRow,
}: IProps) => {
  const fieldWithConfig = useMemo(
    () => TERM_STRUCTURE_FIELDS_CONFIG.find(({ value }) => value === field),
    [field],
  )

  if (
    !fieldWithConfig ||
    (AR_SPECIFIC_TERMS_FIELDS.includes(field) &&
      (!data?.loanType || data.loanType === LOAN_TYPES.inventory)) ||
    (INVENTORY_SPECIFIC_TERMS_FIELDS.includes(field) &&
      (!data?.loanType || data.loanType === LOAN_TYPES.ar)) ||
    ('qualifiedEquityRound' === field && !data?.covenantType)
  ) {
    return null
  }

  return (
    <div
      className={cn(styles.dataRow, {
        [styles.dataRowFull]: isFullRow,
      })}
    >
      <div className={styles.label}>{fieldWithConfig.label}</div>
      {previousData && previousData[field] && (
        <div className={styles.previousValue}>
          <RenderedValue
            value={previousData[field]}
            type={fieldWithConfig.type}
            percentWithScale={percentWithScale}
          />
        </div>
      )}
      <div className={styles.value}>
        {data ? (
          <RenderedValue
            value={data[field]}
            previousValue={previousData?.[field]}
            type={fieldWithConfig.type}
            percentWithScale={percentWithScale}
          />
        ) : (
          <Skeleton width={100} height={15} />
        )}
      </div>
    </div>
  )
}
export default ProspectReportingSummaryTermStructureDataRow
