import { combineReducers } from 'redux'
import * as actions from './actions'
import {
  IChatHistory,
  IChatMessage,
  IChatSession,
  ICurrentResponse,
  Models,
} from '@common/interfaces/atlasai'
import moment from 'moment'
import { ATLAS_AI_THINKING } from '@common/constants/webSockets'

export interface IAtlasAIState {
  isLoading: boolean
  isLoadingMore: boolean
  currentResponse: ICurrentResponse | null
  chatHistory: IChatHistory | null
  isChatVisible: boolean
  sessions: {
    isLoading: boolean
    isLoadingMore: boolean
    data: IChatSession[]
    total: number
    hasMore: boolean
  }
  selectedSession: {
    isLoading: boolean
    data: {
      history: IChatHistory | null
      session: IChatSession
    } | null
  }
  modelProvider: Models
}

const initialChatHistory: IChatHistory = {
  hasMore: true,
  messages: [],
  total: 0,
  limit: 0,
  offset: 0,
}

export default combineReducers<IAtlasAIState>({
  isLoading: (state: boolean = false, action: any): boolean => {
    switch (action.type) {
      case actions.ASK_ATLAS_AI_REQUEST:
        return true
      case actions.ASK_ATLAS_AI_SUCCESS:
      case actions.ASK_ATLAS_AI_FAILURE:
      case actions.CREATE_SESSION_SUCCESS:
      case actions.DELETE_SESSION_SUCCESS:
        return false
      default:
        return state
    }
  },

  isLoadingMore: (state: boolean = false, action: any): boolean => {
    switch (action.type) {
      case actions.LOAD_MORE_HISTORY_REQUEST:
        return true
      case actions.LOAD_MORE_HISTORY_SUCCESS:
      case actions.LOAD_MORE_HISTORY_FAILURE:
        return false
      default:
        return state
    }
  },

  currentResponse: (
    state: ICurrentResponse | null = null,
    action: any,
  ): ICurrentResponse | null => {
    switch (action.type) {
      case actions.ASK_ATLAS_AI_SUCCESS:
        return action.data
      default:
        return state
    }
  },

  chatHistory: (
    state: IChatHistory | null = initialChatHistory,
    action: any,
  ): IChatHistory | null => {
    switch (action.type) {
      case actions.ASK_ATLAS_AI_REQUEST:
        return {
          hasMore: state?.hasMore || true,
          messages: [
            ...(state?.messages || []),
            {
              id: new Date().getTime().toString(),
              role: 'user',
              content: action.params.question,
              createdAt: moment().toISOString(),
              sessionId: action.params.sessionId,
            },
          ],
          total: state?.total || 0,
          limit: state?.limit || 0,
          offset: state?.offset || 0,
        }
      case actions.ASK_ATLAS_AI_SUCCESS:
        return {
          hasMore: state?.hasMore || true,
          messages: [...(state?.messages || []), action.data],
          total: (state?.total || 0) + 1,
          limit: state?.limit || 0,
          offset: (state?.offset || 0) + 1,
        }
      case actions.SELECT_SESSION_SUCCESS:
        return action.data?.history || null
      case actions.LOAD_MORE_HISTORY_SUCCESS:
        if (!state) return action.data.history
        // Prepend older messages to the existing ones
        return {
          ...action.data.history,
          messages: [...action.data.history.messages, ...state.messages],
        }
      case actions.CREATE_SESSION_SUCCESS:
        return initialChatHistory
      case actions.DELETE_SESSION_SUCCESS:
        if (state?.messages?.[state?.messages?.length - 1]?.sessionId === action.data.id) {
          return initialChatHistory
        }
        return state
      case actions.SUBMIT_FEEDBACK_REQUEST:
        return {
          ...state,
          messages: state.messages.map((message: IChatMessage) =>
            message.id === action.params.messageId
              ? {
                  ...message,
                  feedbackType: action.params.feedbackType,
                  feedbackComment: action.params.comment,
                }
              : message,
          ),
        }
      case actions.SUBMIT_FEEDBACK_SUCCESS:
        if (!state) return state
        return {
          ...state,
          messages: state.messages.map((message: IChatMessage) =>
            message.id === action.data.id
              ? {
                  ...message,
                  feedbackType: action.data.feedbackType,
                  feedbackComment: action.data.feedbackComment,
                }
              : message,
          ),
        }
      case ATLAS_AI_THINKING:
        if (action.data.sessionId === state?.messages?.[state?.messages?.length - 1]?.sessionId) {
          return {
            ...state,
            thinking: action.data.thinking,
            result: action.data.result,
          }
        }
        return state
      default:
        return state
    }
  },

  isChatVisible: (state: boolean = false, action: any): boolean => {
    switch (action.type) {
      case actions.TOGGLE_CHAT_VISIBILITY:
        return !state
      default:
        return state
    }
  },

  sessions: (
    state = { isLoading: false, isLoadingMore: false, data: [], total: 0, hasMore: true },
    action: any,
  ): {
    isLoading: boolean
    isLoadingMore: boolean
    data: IChatSession[]
    total: number
    hasMore: boolean
  } => {
    switch (action.type) {
      case actions.LIST_SESSIONS_REQUEST:
        return {
          ...state,
          isLoading: !action.meta?.loadMore,
          isLoadingMore: !!action.meta?.loadMore,
        }
      case actions.LIST_SESSIONS_SUCCESS:
        const sessions = action.data?.sessions || []
        const total = action.data?.total || 0
        const limit = action.data?.limit || 10
        const offset = action.data?.offset || 0

        return {
          isLoading: false,
          isLoadingMore: false,
          // If this is a loadMore request, append the new sessions to the existing ones
          data: action.meta?.loadMore ? [...state.data, ...sessions] : sessions,
          total,
          hasMore: total > offset + limit,
        }
      case actions.LIST_SESSIONS_FAILURE:
        return {
          ...state,
          isLoading: false,
          isLoadingMore: false,
        }
      case actions.CREATE_SESSION_SUCCESS:
        return {
          ...state,
          isLoading: false,
          data: [action.data.session, ...state.data],
          total: state.total + 1,
        }
      case actions.DELETE_SESSION_SUCCESS:
        return {
          ...state,
          data: state.data.filter((session) => session.id !== action.data.id),
          total: state.total - 1,
        }
      case actions.ASK_ATLAS_AI_SUCCESS:
        const currentSession = state.data.find((session) => session.id === action.data.sessionId)
        if (currentSession && !currentSession.name) {
          return {
            ...state,
            data: state.data.map((session) =>
              session.id === action.data.sessionId
                ? { ...session, name: action.data.sessionName }
                : session,
            ),
          }
        }
        return state
      default:
        return state
    }
  },

  selectedSession: (
    state = { isLoading: false, data: null },
    action: any,
  ): {
    isLoading: boolean
    data: {
      history: IChatHistory | null
      session: IChatSession
    }
  } => {
    switch (action.type) {
      case actions.SELECT_SESSION_REQUEST:
        return {
          ...state,
          isLoading: true,
        }
      case actions.SELECT_SESSION_SUCCESS:
        return {
          isLoading: false,
          data: action.data || null,
        }
      case actions.SELECT_SESSION_FAILURE:
        return {
          ...state,
          isLoading: false,
        }
      case actions.CREATE_SESSION_SUCCESS:
        return {
          ...state,
          isLoading: false,
          data: action.data || null,
        }
      case actions.DELETE_SESSION_SUCCESS:
        if (state?.data?.session?.id === action.data.id) {
          return {
            ...state,
            isLoading: false,
            data: null,
          }
        }
        return state
      default:
        return state
    }
  },

  modelProvider: (state: Models = Models.CLAUDE_3_7_SONNET_LATEST, action: any): Models => {
    switch (action.type) {
      case actions.SET_MODEL_PROVIDER:
        return action.modelProvider
      default:
        return state
    }
  },
})
