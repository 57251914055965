import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { useParams } from 'react-router'
import { Form } from 'react-final-form'
import cn from 'classnames'
import Box from '@mui/material/Box'
import InfiniteScroll from 'react-infinite-scroll-component'
import MultiselectRow from '../MultiselectRow'
import styles from './SalesBySKUVisualizationTable.module.scss'
import genericSs from '@styles/generic.module.scss'
import TableRow from '../Common/TableRow'
import SelectField from '../Common/SelectField'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableContainer from '../Common/TableContainer'
import TableBody from '../Common/TableBody'
import TableFooter from '../Common/TableFooter'
import {
  debounceEventHandler,
  formatPrice,
  formatPercent,
  formatNumber,
  formatDate,
  formatDateMonthYear,
} from '../../helpers/helpers'
import {
  ISalesBySKUVisualizationData,
  ISalesBySKUVisualization,
  INVENTORY_DISTRIBUTION_LABELS,
  INVENTORY_DB_FIELDS_TO_LABELS,
  INVENTORY_DISTRIBUTION_DB_FIELDS,
  ISalesBySKUVisualizationFilters,
  SkuTypes,
  WorkflowPage,
  SALES_BY_SKU_VISUALIZATION_SALES_VIEW_OPTIONS,
  SalesBySKUVisualizationSalesView,
} from '@common/interfaces/bbc'
import TableFiltersRow from '../Common/TableFiltersRow'
import {
  SALES_BY_SKU_VISUALIZATION_FILTERS_CONFIG,
  PER_PAGE,
  IFilter,
  getApplicableFilters,
  FieldType,
} from '@common/constants/filters'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import FilterContainer from '../Filters/FilterContainer'
import TableLoader from '../Common/TableLoader'
import SaveState from '../Common/SaveState'
import { ILoadingData } from '../../redux/types'
import SalesBySKUVisualizationFooter from './SalesBySKUVisualizationFooter'
import FormattedTableCell from '../Common/FormattedTableCell'
import useTable from '../../hooks/useTable'
import { DATE_DATABASE_FORMAT } from '../../constants/common'
import moment from 'moment'
import Card from '../Common/Card'
import DatePicker from '../Common/DatePicker'
import SalesByDistributionGraph from '../SalesByDistributionGraph'
import Empty from '../Common/Empty'
import useTrackVisualizationsTable from '../../hooks/useTrackVisualizationsTable'
import { CATEGORIES } from '@common/constants/tracking'
import useTrackVisualizationsTableChartSelection from '../../hooks/useTrackVisualizationsTableChartSelection'
import useGraphToggle from '../../hooks/useGraphTogggle'
import { Tooltip } from '@mui/material'
import { ReactComponent as ActionPendingIcon } from '@assets/images/action-pending.svg'
import { usePermissions } from '../../helpers/permissionContext'
import ExportButton from '../Common/ExportButton'
import LinkButton from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import TableCell from '../Common/TableCell'
import sortBy from 'lodash/orderBy'
import useCoupledTablesScroll from '../../hooks/useCoupledTablesScroll'

const scaleOptions = [
  { value: 'value', label: '$ Value' },
  { value: 'percent', label: '% of Total' },
]

const metricOptions: {
  value: 'units' | 'sales'
  label: '# Units' | '$ Sales'
}[] = [
  { value: 'sales', label: '$ Sales' },
  { value: 'units', label: '# Units' },
]

const monthlyColumnOptions = [
  { value: 'totalSalesPerMonth', label: 'Amount Sold' },
  { value: 'unitSalesPerMonth', label: 'Unit Sold' },
]

const filtersValidate = buildFiltersValidateSchema(SALES_BY_SKU_VISUALIZATION_FILTERS_CONFIG)
const filtersDefaults = buildFiltersDefaults(SALES_BY_SKU_VISUALIZATION_FILTERS_CONFIG, {
  type: [SkuTypes.Finished],
})

const TooltipContent = ({ item, field }: { item: ISalesBySKUVisualization; field: string }) => {
  if (field === 'weeklyCOGS') {
    return (
      <div>
        <div className={styles.tooltip}>Months Count: {formatNumber(item.monthsOfSalesCount)}</div>
        <div className={styles.tooltip}>Effective Months: {formatNumber(item.monthsOfSales)}</div>
      </div>
    )
  } else if (field === 'distribution') {
    return <div>{item.distribution}</div>
  }
  return null
}

interface IProps {
  listSalesBySkuVisualization: (id: string, params?: object) => Promise<void>
  skuVisualization: ILoadingData<ISalesBySKUVisualizationData>
  listSalesBySkuFilters: (id: string, data: object) => Promise<void>
  skuVisualizationsFilters: ILoadingData<ISalesBySKUVisualizationFilters>
  currentId: string
  type: WorkflowPage
  exportSalesBySkuVisualization: (id: string, params?: object) => Promise<void>
  refreshCount?: number
  hideSalesData: () => void
  isClientUserPage?: boolean
  editLink?: string
  isSidebarOpen: boolean
  isNotesShown: boolean
}

const SalesBySKUVisualizationTableRow = ({
  index,
  item,
  isActiveRow,
  isCurrentActiveRow,
  onSelectRow,
  filtersConfig,
  editLink,
  isMonthlySalesBySKU,
  missedColumns,
}: {
  index: number
  item: ISalesBySKUVisualization
  isActiveRow: boolean
  isCurrentActiveRow: boolean
  onSelectRow: (event: any, index: number) => void
  filtersConfig: IFilter[]
  editLink?: string
  isMonthlySalesBySKU: boolean
  missedColumns: number[]
}) => {
  const handleSelectRow = useCallback((event) => onSelectRow(event, index), [index, onSelectRow])

  if (!item) {
    return null
  }

  return (
    <TableRow
      id={`visualization-table-row-${index}`}
      key={`visualization-table-row-${index}`}
      data-index={index}
      className={cn('activableRow', {
        activeRow: isActiveRow,
        currentActiveRow: isCurrentActiveRow,
      })}
      onClick={handleSelectRow}
    >
      {filtersConfig.map(({ field, type }) => {
        const showTooltip = ['distribution', 'weeklyCOGS'].includes(field)
        const isChip = ['previousOnHandDifference', 'previousTotalValueDifference'].includes(field)
        const value =
          isMonthlySalesBySKU &&
          (field.startsWith('totalSalesPerMonth') || field.startsWith('unitSalesPerMonth'))
            ? item[field.split('.')[0]]?.[field.split('.')[1]]
            : item[field]

        return (
          <FormattedTableCell
            key={field}
            value={value}
            fieldType={type}
            nullCondition={value === null || !value}
            tooltip={showTooltip ? <TooltipContent item={item} field={field} /> : null}
            isChip={isChip}
          />
        )
      })}

      {isMonthlySalesBySKU && missedColumns.map((index) => <TableCell key={index} />)}
    </TableRow>
  )
}

const coupledTables = [
  'salesBySKUVisualizationTableHead',
  'salesBySKUVisualizationTableFooter',
  'salesBySKUVisualizationActiveToolbar',
]

const SalesBySKUVisualizationTable = ({
  listSalesBySkuVisualization,
  listSalesBySkuFilters,
  exportSalesBySkuVisualization,
  hideSalesData,
  skuVisualizationsFilters,
  skuVisualization,
  currentId,
  type,
  refreshCount = 1,
  isClientUserPage,
  editLink,
  isNotesShown,
  isSidebarOpen,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const wrapperRef = useRef(null)
  const [currentDistribution, setCurrentDistribution] = useState('')
  const [inventoryDate, setInventoryDate] = useState(null)
  const [previousInventoryDate, setPreviousInventoryDate] = useState('')
  const [currentDateRange, setCurrentDateRange] = useState<{ startDate: string; endDate: string }>({
    startDate: '',
    endDate: '',
  })
  const [datesBoundary, setDatesBoundary] = useState<{ minDate: string; maxDate: string }>({
    minDate: '',
    maxDate: '',
  })
  const [currentScale, setCurrentScale] = useState(scaleOptions[0].value)
  const [currentMetric, setCurrentMetric] = useState<'units' | 'sales'>(metricOptions[0].value)
  const { TabsComponent, isGraphShown } = useGraphToggle({
    defaultTab: isClientUserPage ? 'Chart' : 'Table',
  })
  const [salesView, setSalesView] = useState(SalesBySKUVisualizationSalesView.Aggregate)
  const [monthlyColumn, setMonthlyColumn] = useState(monthlyColumnOptions[0].value)
  const isMonthlySalesBySKU = useMemo(
    () => salesView === SalesBySKUVisualizationSalesView.Monthly,
    [salesView],
  )

  const { isParticipant } = usePermissions()

  const {
    isLoading,
    isSaving,
    isSaved,
    data: resData,
    itemsCount,
    totals,
    totalDuplicateSkus,
    totalUniqueDuplicateSkus,
    dates,
  } = useMemo(() => {
    return {
      isLoading: skuVisualization.isLoading,
      isSaving: skuVisualization.isSaving,
      isSaved: skuVisualization.isSaved,
      data: skuVisualization?.data?.data,
      itemsCount: skuVisualization?.data?.totalItems,
      totals: skuVisualization?.data?.totals,
      totalDuplicateSkus: skuVisualization?.data?.totalDuplicateSkus,
      totalUniqueDuplicateSkus: skuVisualization?.data?.totalUniqueDuplicateSkus,
      dates: skuVisualization?.data?.dates,
    }
  }, [skuVisualization])

  const {
    filters,
    handleFiltersChange,
    handleOrderChange,
    orderBy,
    activeItem,
    activeItems,
    setActiveItem,
    setActiveItems,
    handleSelectRow,
    quickFilter,
    handleQuickFilterChange,
  } = useTable({
    tableId: 'skuVisualization',
    filtersDefaults,
    sortDefault: {
      field: 'totalValue',
      direction: 'DESC',
    },
    quickFilterDefault: 'Finished Goods',
  })

  const { handleBodyScroll, handleHeaderScroll, handleFooterScroll } = useCoupledTablesScroll({
    tableRef: wrapperRef,
    bodyId: 'scrollableTableSKUVisualization',
    coupledTables,
    headerId: 'salesBySKUVisualizationTableHead',
    footerId: 'salesBySKUVisualizationTableFooter',
  })

  const handleDistributionChange = useCallback(
    (event) => {
      setCurrentDistribution(event.target.value)
    },
    [setCurrentDistribution],
  )

  useEffect(() => {
    hideSalesData()
  }, [hideSalesData])

  useEffect(() => {
    if (id && id === currentId) {
      listSalesBySkuFilters(id, {
        type,
      })
    }
  }, [id, listSalesBySkuFilters, type, currentId])

  useEffect(() => {
    if (isClientUserPage && currentId) {
      listSalesBySkuFilters(currentId, {
        type,
        isClientUserPage,
      })
    }
  }, [isClientUserPage, listSalesBySkuFilters, type, currentId])

  useEffect(() => {
    //in case file changes reload filters
    if ([WorkflowPage.dueDilligence, WorkflowPage.ongoingReporting].includes(type)) {
      refreshCount && listSalesBySkuFilters(id, { type })
    }
  }, [id, listSalesBySkuFilters, type, refreshCount])

  const {
    distributionPicklist,
    inventoryDates,
    isDistributionListLoading,
    latestSalesDate,
    earliestSalesDate,
    filterDropdownValues,
  } = useMemo(
    () => ({
      distributionPicklist: skuVisualizationsFilters?.data?.distributionPicklist,
      inventoryDates: skuVisualizationsFilters?.data?.inventoryDates,
      isDistributionListLoading: skuVisualizationsFilters?.isLoading,
      latestSalesDate: skuVisualizationsFilters?.data?.latestSalesDate,
      earliestSalesDate: skuVisualizationsFilters?.data?.earliestSalesDate,
      filterDropdownValues: skuVisualizationsFilters?.data?.filterDropdownValues,
    }),
    [skuVisualizationsFilters],
  )

  useEffect(() => {
    if (inventoryDates?.length) {
      setInventoryDate(inventoryDates[0])
    }
  }, [inventoryDates])

  const fieldOptions = useMemo(
    () =>
      distributionPicklist
        ?.filter((field) => {
          if (isGraphShown) {
            return field !== 'sku'
          }
          return true
        })
        .map((fieldValue) => ({
          value: INVENTORY_DISTRIBUTION_DB_FIELDS[fieldValue],
          label: INVENTORY_DISTRIBUTION_LABELS[fieldValue],
        })),
    [distributionPicklist, isGraphShown],
  )

  useEffect(() => {
    if (isGraphShown && currentDistribution === 'sku') {
      setCurrentDistribution(fieldOptions?.[0]?.value ?? null)
    } else if (!currentDistribution && distributionPicklist?.length) {
      if (isGraphShown && distributionPicklist.length === 1 && distributionPicklist[0] === 'sku') {
        setCurrentDistribution(null)
      } else {
        setCurrentDistribution(distributionPicklist[0])
      }
    }
  }, [isGraphShown, fieldOptions, currentDistribution, distributionPicklist])

  const isInitialized = useMemo(() => !!skuVisualization?.data?.data, [skuVisualization])
  const visualizationsCategory = useMemo(
    () =>
      isGraphShown
        ? CATEGORIES.salesBySKUVisualizationChart
        : CATEGORIES.salesBySKUVisualizationTable,
    [isGraphShown],
  )
  const visualizationsParams = useMemo(
    () => ({
      [type === WorkflowPage.borrowingbase ? 'borrowingBaseId' : 'clientId']: id,
    }),
    [type, id],
  )
  const visualizationsFilters = useMemo(
    () => ({
      ...filters,
      distribution: currentDistribution,
      latestSalesDate: currentDateRange.endDate,
      earliestSalesDate: currentDateRange.startDate,
      inventoryDate: inventoryDate,
      previousInventoryDate: previousInventoryDate,
    }),
    [filters, currentDistribution, currentDateRange, inventoryDate, previousInventoryDate],
  )

  useTrackVisualizationsTable({
    isInitialized,
    category: visualizationsCategory,
    params: visualizationsParams,
    filtersConfig: SALES_BY_SKU_VISUALIZATION_FILTERS_CONFIG,
    filters: visualizationsFilters,
    orderBy,
  })

  useTrackVisualizationsTableChartSelection({
    isInitialized,
    category: visualizationsCategory,
    params: visualizationsParams,
    isChart: !!isGraphShown,
  })

  const dateOptions = useMemo(
    () =>
      inventoryDates?.map((date) => ({
        value: date,
        label: date,
      })),
    [inventoryDates],
  )

  const previousDateOptions = useMemo(
    () =>
      inventoryDates
        ?.filter((date) => date !== inventoryDate)
        .map((date) => ({
          value: date,
          label: date,
        })),
    [inventoryDates, inventoryDate],
  )

  const currentParams = useMemo(
    () => ({
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      distribution: currentDistribution,
      latestSalesDate: currentDateRange.endDate,
      earliestSalesDate: currentDateRange.startDate,
      inventoryDate: inventoryDate,
      previousInventoryDate: previousInventoryDate,
      salesView,
      monthlyColumn,
    }),
    [
      filters,
      orderBy,
      currentDistribution,
      currentDateRange,
      inventoryDate,
      previousInventoryDate,
      salesView,
      monthlyColumn,
    ],
  )

  const fetchSalesBySkuDetailVisualization = useCallback(
    async (data) => {
      if (data.isExport) {
        exportSalesBySkuVisualization(id, data)
        setActiveItems([])
        return
      }
      listSalesBySkuVisualization(id || currentId, {
        ...data,
        isClientUserPage,
      })
      setActiveItems([])
    },
    [
      id,
      currentId,
      listSalesBySkuVisualization,
      exportSalesBySkuVisualization,
      isClientUserPage,
      setActiveItems,
    ],
  )

  const debounceSalesBySkuDetailListVisualization = useMemo(
    () => debounceEventHandler(fetchSalesBySkuDetailVisualization, 500),
    [fetchSalesBySkuDetailVisualization],
  )

  useEffect(() => {
    if (
      ((id && id === currentId && !isClientUserPage) || (isClientUserPage && currentId)) &&
      refreshCount &&
      !isGraphShown &&
      currentDistribution
    ) {
      debounceSalesBySkuDetailListVisualization({
        page: 0,
        ...currentParams,
      })
    }
  }, [
    debounceSalesBySkuDetailListVisualization,
    id,
    currentId,
    refreshCount,
    currentParams,
    isGraphShown,
    currentDistribution,
    isClientUserPage,
  ])

  const handleExportSalesBySku = useCallback(async () => {
    fetchSalesBySkuDetailVisualization({
      ...currentParams,
      isExport: true,
    })
  }, [fetchSalesBySkuDetailVisualization, currentParams])

  const totalRow = useMemo(
    () =>
      resData
        ?.filter((_, index) => activeItems.includes(index))
        .reduce(
          (totalRowResult, row) => {
            totalRowResult.onHand += row.onHand
            totalRowResult.totalValue += row.totalValue
            totalRowResult.totalInventoryPercent += row.totalInventoryPercent
            totalRowResult.unitSales += row.unitSales
            totalRowResult.totalSales += row.totalSales
            totalRowResult.totalSalesPercent += row.totalSalesPercent
            totalRowResult.totalCOGS += row.totalCOGS
            totalRowResult.weeklyCOGS += row.weeklyCOGS
            totalRowResult.totalIneligible += row.totalIneligible
            totalRowResult.previousOnHandDifference += row.previousOnHandDifference
            totalRowResult.previousTotalValueDifference += row.previousTotalValueDifference
            if (isMonthlySalesBySKU) {
              totalRowResult.unitSalesPerMonth = totalRowResult.unitSalesPerMonth?.map(
                (value, index) => (value || 0) + (row.unitSalesPerMonth?.[index] || 0),
              )
              totalRowResult.totalSalesPerMonth = totalRowResult.totalSalesPerMonth?.map(
                (value, index) => (value || 0) + (row.totalSalesPerMonth?.[index] || 0),
              )
            }
            return totalRowResult
          },
          {
            onHand: 0,
            totalValue: 0,
            totalInventoryPercent: 0,
            unitSales: 0,
            totalSales: 0,
            totalSalesPercent: 0,
            totalCOGS: 0,
            weeklyCOGS: 0,
            totalIneligible: 0,
            previousOnHandDifference: 0,
            previousTotalValueDifference: 0,
            unitSalesPerMonth: Array(dates?.length).fill(null),
            totalSalesPerMonth: Array(dates?.length).fill(null),
          },
        ),
    [activeItems, resData, dates, isMonthlySalesBySKU],
  )

  const loadMore = useCallback(() => {
    fetchSalesBySkuDetailVisualization({
      loadMore: true,
      page: Math.ceil(resData?.length / PER_PAGE),
      ...currentParams,
    })
  }, [resData, fetchSalesBySkuDetailVisualization, currentParams])

  useEffect(() => {
    if (earliestSalesDate && latestSalesDate) {
      setDatesBoundary({
        minDate: moment(earliestSalesDate).startOf('month').format(DATE_DATABASE_FORMAT),
        maxDate: moment(latestSalesDate).add(1, 'day').endOf('month').format(DATE_DATABASE_FORMAT),
      })
      const elevenMonthsAgo = moment(latestSalesDate).subtract(11, 'months')
      const earlierDateMoreRecentThan11Months = moment(earliestSalesDate).isAfter(elevenMonthsAgo)
      const startDate = earlierDateMoreRecentThan11Months
        ? moment(earliestSalesDate).format(DATE_DATABASE_FORMAT)
        : elevenMonthsAgo.format(DATE_DATABASE_FORMAT)
      setCurrentDateRange({
        startDate,
        endDate: moment(latestSalesDate).format(DATE_DATABASE_FORMAT),
      })
    } else {
      setCurrentDateRange({
        startDate: '',
        endDate: '',
      })
    }
  }, [earliestSalesDate, latestSalesDate])

  const handleSalesDateChange = useCallback(
    (values: { startDate: string; endDate: string }) => {
      setCurrentDateRange(values)
    },
    [setCurrentDateRange],
  )

  const handleInventoryDateChange = useCallback(
    (event) => {
      setInventoryDate(event.target.value)
    },
    [setInventoryDate],
  )

  const handlePreviousInventoryDateChange = useCallback(
    (event) => {
      setPreviousInventoryDate(event.target.value)
    },
    [setPreviousInventoryDate],
  )

  const hideChangeColumns = useMemo(
    () => inventoryDate === previousInventoryDate || !previousInventoryDate,
    [inventoryDate, previousInventoryDate],
  )

  const handleSalesViewChange = useCallback((event) => {
    setSalesView(event.target.value)
  }, [])

  const handleMonthlyColumnChange = useCallback((event) => {
    setMonthlyColumn(event.target.value)
  }, [])

  const filtersConfig = useMemo(
    () =>
      SALES_BY_SKU_VISUALIZATION_FILTERS_CONFIG.filter(
        (config) =>
          !isClientUserPage ||
          (isClientUserPage &&
            !['totalIneligible', 'totalCOGS', 'weeklyCOGS', 'weeksOfSupply'].includes(
              config.field,
            )),
      ).map((filter) => {
        if (filter.field === 'distribution') {
          return {
            ...filter,
            title: INVENTORY_DB_FIELDS_TO_LABELS[currentDistribution],
          }
        } else if (
          ['previousOnHandDifference', 'previousTotalValueDifference'].includes(filter.field)
        ) {
          if (hideChangeColumns) {
            return {
              ...filter,
              excludeColumn: true,
            }
          }
        } else if (filter.excludeColumn && !filter.excludeFilter) {
          const title = INVENTORY_DISTRIBUTION_LABELS[filter.field]
          const options = filterDropdownValues?.[filter.field]?.map((value) => ({
            value,
            label: value,
          }))
          if (options?.length) {
            return {
              ...filter,
              title,
              options,
            }
          }
          return {
            ...filter,
            excludeFilter: true,
          }
        }
        return filter
      }),
    [filterDropdownValues, currentDistribution, hideChangeColumns, isClientUserPage],
  )
  const isBBC = useMemo(() => type === WorkflowPage.borrowingbase, [type])

  const handleScaleChange = useCallback(({ target: { value } }) => {
    setCurrentScale(value)
  }, [])

  const handleMetricChange = useCallback(({ target: { value } }) => {
    setCurrentMetric(value)
  }, [])

  const showLoading = useMemo(
    () => isLoading || isDistributionListLoading,
    [isLoading, isDistributionListLoading],
  )

  const effectiveColsConfig = useMemo(
    () =>
      isMonthlySalesBySKU
        ? getApplicableFilters([
            ...sortBy(
              filtersConfig?.map((item) => ({
                ...item,
                title: item.field === 'totalValue' ? 'Inventory' : item.title,
                excludeColumn: ![
                  'distribution',
                  'totalValue',
                  'monthsOfSales',
                  'weeksOfSupply',
                ].includes(item.field),
                isHighlighted: false,
              })) || [],
              'field',
              'asc',
            ),
            ...(dates?.map((date, index) => ({
              field: `${monthlyColumn}.${index}`,
              type: (monthlyColumn === 'unitSalesPerMonth' ? 'number' : 'amount') as FieldType,
              isSortable: false,
              title: formatDateMonthYear(date),
              align: 'right',
              excludeFilter: true,
              isHighlighted: true,
            })) || []),
          ])
        : getApplicableFilters(filtersConfig),
    [filtersConfig, isMonthlySalesBySKU, dates, monthlyColumn],
  )

  const noDataAvailable = useMemo(
    () => !isDistributionListLoading && !inventoryDates?.length && !latestSalesDate,
    [isDistributionListLoading, inventoryDates, latestSalesDate],
  )

  const handleClearPreviousInventoryDate = useCallback(() => {
    setPreviousInventoryDate('')
  }, [setPreviousInventoryDate])

  useEffect(() => {
    if (inventoryDate === previousInventoryDate) {
      handleClearPreviousInventoryDate()
    }
  }, [inventoryDate, previousInventoryDate, handleClearPreviousInventoryDate])

  const missedColumns = useMemo(
    () => Array.from(Array(Math.max(6 - (dates?.length || 0), 0)).keys()),
    [dates],
  )

  return (
    <Card
      withBorder={[
        WorkflowPage.borrowingbase,
        WorkflowPage.client,
        WorkflowPage.dueDilligence,
      ].includes(type)}
      noHeaderMargin
    >
      <TableContainer
        className={cn(styles.table, {
          [styles.monthlySalesBySKUTable]: isMonthlySalesBySKU,
          [styles.monthlySalesBySKUTableWithSidebar]: isMonthlySalesBySKU && isSidebarOpen,
          [styles.monthlySalesBySKUTableWithNotes]: isMonthlySalesBySKU && isNotesShown,
          [styles.hiddenColsTable]: !isMonthlySalesBySKU && hideChangeColumns,
          [styles.fullTable]: !isMonthlySalesBySKU && !hideChangeColumns,
        })}
        isActivable
        onActiveRowsChange={setActiveItems}
        onActiveRowChange={setActiveItem}
      >
        <Form
          onSubmit={handleFiltersChange}
          initialValues={filters}
          validate={filtersValidate}
          mutators={{
            setFieldData: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value)
            },
          }}
          render={({ values, handleSubmit, form: { mutators } }) => (
            <FilterContainer
              filters={filtersConfig}
              handleSubmit={handleSubmit}
              mutators={mutators}
              values={values}
              appliedFilters={filters}
              appliedQuickFilter={quickFilter}
              handleAppliedQuickFilterChange={handleQuickFilterChange}
              title={
                <Box className={styles.titleContainer} mr={2}>
                  <div className={cn({ [styles.clientUserTitle]: isClientUserPage })}>
                    <h2>
                      Sales by {INVENTORY_DB_FIELDS_TO_LABELS[currentDistribution]}
                      {editLink && (
                        <LinkButton
                          component={RouterLink}
                          to={editLink}
                          className={styles.editLink}
                        >
                          Edit
                        </LinkButton>
                      )}
                    </h2>
                    {isClientUserPage && inventoryDate && (
                      <span className={styles.asOfLabel}>as of {formatDate(inventoryDate)}</span>
                    )}
                  </div>
                  {+totalDuplicateSkus > 0 && (
                    <Tooltip
                      title={`${totalUniqueDuplicateSkus} unique duplicate SKU - Date(s) appeared ${totalDuplicateSkus} times`}
                    >
                      <ActionPendingIcon className={styles.warningIcon} />
                    </Tooltip>
                  )}
                </Box>
              }
              actionsSize={isClientUserPage ? 6 : 8}
              actions={
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  gap={1}
                  flexWrap="wrap-reverse"
                >
                  {!isGraphShown && (
                    <div>
                      <SelectField
                        className={styles.distributionOptions}
                        label="Sales View"
                        variant="outlined"
                        useFinalForm={false}
                        name="salesView"
                        options={SALES_BY_SKU_VISUALIZATION_SALES_VIEW_OPTIONS}
                        defaultValue=""
                        onChange={handleSalesViewChange}
                        value={salesView}
                        withTopLabel
                        disabled={noDataAvailable}
                      />
                    </div>
                  )}
                  {!isGraphShown && isMonthlySalesBySKU && (
                    <div>
                      <SelectField
                        className={styles.distributionOptions}
                        label="Amount / Units Sold"
                        variant="outlined"
                        useFinalForm={false}
                        name="monthlyColumn"
                        options={monthlyColumnOptions}
                        defaultValue=""
                        onChange={handleMonthlyColumnChange}
                        value={monthlyColumn}
                        withTopLabel
                        disabled={noDataAvailable}
                      />
                    </div>
                  )}
                  {!isBBC && !isGraphShown && (
                    <div>
                      <SelectField
                        className={styles.distributionOptionsSmall}
                        label="Inventory Date"
                        variant="outlined"
                        useFinalForm={false}
                        name="inventoryDate"
                        options={dateOptions || []}
                        defaultValue=""
                        onChange={handleInventoryDateChange}
                        value={inventoryDate}
                        isLoading={isDistributionListLoading}
                        withTopLabel
                        disabled={noDataAvailable}
                      />
                    </div>
                  )}
                  {!isGraphShown && !isClientUserPage && !isMonthlySalesBySKU && (
                    <div>
                      <SelectField
                        className={styles.distributionOptions}
                        label="Previous Inventory"
                        placeholder="Date"
                        variant="outlined"
                        useFinalForm={false}
                        name="previousInventoryDate"
                        options={previousDateOptions || []}
                        defaultValue=""
                        onChange={handlePreviousInventoryDateChange}
                        value={previousInventoryDate}
                        isLoading={isDistributionListLoading}
                        withTopLabel
                        disabled={noDataAvailable}
                        handleClear={handleClearPreviousInventoryDate}
                      />
                    </div>
                  )}
                  {isGraphShown && (
                    <>
                      <SelectField
                        key={currentScale}
                        label="Scale"
                        variant="outlined"
                        className={styles.distributionOptionsSmall}
                        useFinalForm={false}
                        name="scale"
                        options={scaleOptions}
                        onChange={handleScaleChange}
                        value={currentScale}
                        defaultValue=""
                        withTopLabel
                        disabled={noDataAvailable}
                      />
                      <SelectField
                        key={currentMetric}
                        label="Metric"
                        variant="outlined"
                        className={styles.distributionOptionsSmall}
                        useFinalForm={false}
                        name="metric"
                        options={metricOptions}
                        onChange={handleMetricChange}
                        value={currentMetric}
                        defaultValue=""
                        withTopLabel
                        disabled={noDataAvailable}
                      />
                    </>
                  )}
                  <div>
                    <DatePicker
                      label="Sales Date Range"
                      currentDateRange={currentDateRange}
                      datesBoundary={datesBoundary}
                      onChange={handleSalesDateChange}
                      disabled={noDataAvailable}
                    />
                  </div>
                  <div>
                    <SelectField
                      className={styles.distributionOptions}
                      label="Distribution"
                      variant="outlined"
                      useFinalForm={false}
                      name="distribution"
                      options={fieldOptions || []}
                      onChange={handleDistributionChange}
                      value={currentDistribution}
                      defaultValue=""
                      isLoading={isDistributionListLoading}
                      withTopLabel
                      disabled={noDataAvailable}
                    />
                  </div>
                  {!isGraphShown && !isParticipant && !isClientUserPage && (
                    <div>
                      <ExportButton
                        isLoading={isSaving}
                        disabled={noDataAvailable}
                        handleExport={handleExportSalesBySku}
                      />
                    </div>
                  )}
                  <div className={styles.recordDateWrapper}>{TabsComponent}</div>
                </Box>
              }
            />
          )}
        />
        {noDataAvailable ? (
          <Empty
            message={isClientUserPage ? 'No data is available' : 'No sales data available'}
            height="30vh"
          />
        ) : (
          !isGraphShown && (
            <>
              <Table ref={wrapperRef}>
                <TableHead id="salesBySKUVisualizationTableHead" onScroll={handleHeaderScroll}>
                  {isMonthlySalesBySKU && (
                    <TableRow>
                      <TableCell className={genericSs.tableTextLeft}>Inventory</TableCell>
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      {dates?.map((date, index) => (
                        <TableCell
                          key={date}
                          className={cn(genericSs.tableTextLeft, styles.highlighted)}
                        >
                          {index === 0 ? 'Sales' : ''}
                        </TableCell>
                      ))}
                      {missedColumns.map((index) => (
                        <TableCell
                          key={index}
                          className={cn(genericSs.tableTextLeft, styles.highlighted)}
                        />
                      ))}
                    </TableRow>
                  )}
                  <TableFiltersRow
                    filters={effectiveColsConfig}
                    orderBy={orderBy}
                    handleOrderChange={handleOrderChange}
                  >
                    {isMonthlySalesBySKU &&
                      missedColumns.map((index) => (
                        <TableCell key={index} className={styles.highlightedCell} />
                      ))}
                  </TableFiltersRow>
                </TableHead>
                <TableBody id="scrollableTableSKUVisualization" onScroll={handleBodyScroll}>
                  {showLoading ? (
                    <TableLoader columnsCount={effectiveColsConfig.length} height={24} />
                  ) : (
                    resData && (
                      <InfiniteScroll
                        dataLength={resData?.length}
                        next={loadMore}
                        hasMore={resData?.length < itemsCount}
                        loader={
                          <TableLoader
                            columnsCount={effectiveColsConfig.length}
                            height={24}
                            rowsCount={1}
                          />
                        }
                        scrollableTarget="scrollableTableSKUVisualization"
                      >
                        {resData.map((item, index) => (
                          <SalesBySKUVisualizationTableRow
                            key={item.distribution}
                            index={index}
                            item={item}
                            isActiveRow={activeItems.includes(index)}
                            isCurrentActiveRow={activeItem === index}
                            onSelectRow={handleSelectRow}
                            filtersConfig={effectiveColsConfig}
                            isMonthlySalesBySKU={isMonthlySalesBySKU}
                            missedColumns={missedColumns}
                          />
                        ))}
                      </InfiniteScroll>
                    )
                  )}
                  <MultiselectRow activeItems={activeItems}>
                    {isMonthlySalesBySKU ? (
                      <>
                        <TableCell className={genericSs.tableTextRight}>-</TableCell>
                        <TableCell className={genericSs.tableTextRight}>
                          <span className={genericSs.pricePrefix}>$</span>
                          {formatPrice(totalRow?.totalValue)}
                        </TableCell>
                        <TableCell className={genericSs.tableTextRight}>-</TableCell>
                        {dates?.map((date, index) =>
                          monthlyColumn === 'unitSalesPerMonth' ? (
                            <TableCell key={date} className={genericSs.tableTextRight}>
                              {formatNumber(totalRow?.[monthlyColumn]?.[index])}
                            </TableCell>
                          ) : (
                            <TableCell key={date} className={genericSs.tableTextRight}>
                              <span className={genericSs.pricePrefix}>$</span>
                              {formatPrice(totalRow?.[monthlyColumn]?.[index])}
                            </TableCell>
                          ),
                        )}
                        {missedColumns.map((index) => (
                          <TableCell key={index} />
                        ))}
                      </>
                    ) : (
                      <>
                        <TableCell className={genericSs.tableTextRight}>
                          {formatNumber(totalRow?.onHand)}
                        </TableCell>
                        {!hideChangeColumns && (
                          <TableCell className={genericSs.tableTextRight}>
                            {formatNumber(totalRow?.previousOnHandDifference)}
                          </TableCell>
                        )}
                        <TableCell className={genericSs.tableTextRight}>
                          <span className={genericSs.pricePrefix}>$</span>
                          {formatPrice(totalRow?.totalValue)}
                        </TableCell>
                        {!hideChangeColumns && (
                          <TableCell className={genericSs.tableTextRight}>
                            <span className={genericSs.pricePrefix}>$</span>
                            {formatPrice(totalRow?.previousTotalValueDifference)}
                          </TableCell>
                        )}
                        <TableCell className={genericSs.tableTextRight}>
                          {formatPercent(totalRow?.totalInventoryPercent)}
                        </TableCell>
                        <TableCell className={genericSs.tableTextRight}>
                          {formatNumber(totalRow?.unitSales)}
                        </TableCell>
                        <TableCell className={genericSs.tableTextRight}>
                          <span className={genericSs.pricePrefix}>$</span>
                          {formatPrice(totalRow?.totalSales)}
                        </TableCell>
                        <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                          {formatPercent(totalRow?.totalSalesPercent)}
                        </TableCell>
                        <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                          {formatPrice(totalRow?.totalCOGS)}
                        </TableCell>
                        <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                          <span className={genericSs.pricePrefix}>$</span>

                          {formatPrice(totalRow?.weeklyCOGS)}
                        </TableCell>

                        <TableCell className={genericSs.tableTextRight}>
                          {totalRow?.weeklyCOGS
                            ? formatNumber(totalRow?.totalValue / (totalRow?.weeklyCOGS || 1), 2)
                            : '-'}
                        </TableCell>
                        <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                          {formatPrice(totalRow?.totalIneligible)}
                        </TableCell>
                      </>
                    )}
                  </MultiselectRow>
                </TableBody>
                {showLoading ? (
                  <TableFooter>
                    <TableLoader columnsCount={11} rowsCount={3} />
                  </TableFooter>
                ) : (
                  <TableFooter
                    id="salesBySKUVisualizationTableFooter"
                    onScroll={handleFooterScroll}
                  >
                    <SalesBySKUVisualizationFooter
                      title={'Top 5'}
                      totals={totals}
                      filtersConfig={effectiveColsConfig}
                      isMonthlySalesBySKU={isMonthlySalesBySKU}
                      missedColumns={missedColumns}
                    />
                    <SalesBySKUVisualizationFooter
                      title={'Top 10'}
                      totals={totals}
                      filtersConfig={effectiveColsConfig}
                      isMonthlySalesBySKU={isMonthlySalesBySKU}
                      missedColumns={missedColumns}
                    />
                    <SalesBySKUVisualizationFooter
                      title={'All'}
                      totals={totals}
                      filtersConfig={effectiveColsConfig}
                      isMonthlySalesBySKU={isMonthlySalesBySKU}
                      missedColumns={missedColumns}
                    />
                  </TableFooter>
                )}
              </Table>

              <Box display="flex" alignItems="center" justifyContent="space-between">
                {itemsCount > 0 && (
                  <div className={genericSs.itemsCount}>
                    {resData?.length} / {itemsCount}
                  </div>
                )}
                <SaveState isSaving={isSaving} isSaved={isSaved} />
              </Box>
            </>
          )
        )}
        {isGraphShown ? (
          currentDistribution ? (
            <SalesByDistributionGraph
              params={currentParams}
              currentId={currentId}
              isPercent={currentScale === 'percent'}
              metric={currentMetric}
              refreshCount={refreshCount}
              isClientUserPage={isClientUserPage}
            />
          ) : (
            <Empty message="No data is available" height="30vh" />
          )
        ) : null}
      </TableContainer>
    </Card>
  )
}

export default SalesBySKUVisualizationTable
