import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import FinancialsComparison from './FinancialsComparison'
import { financialsComparison } from '../../redux/clientInfo/selectors'

import { listFinancialsComparison } from '../../redux/clientInfo/actions'

const selector = createStructuredSelector({
  financialsComparisonData: financialsComparison,
})
const actions = {
  listFinancialsComparison,
}

export default connect(selector, actions)(FinancialsComparison)
