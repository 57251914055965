import React, { useMemo, useState, useCallback, useRef, useEffect } from 'react'
import { generatePath, matchPath } from 'react-router'
import { useLocation } from 'react-router-dom'
import Avatar from '@mui/material/Avatar'
import Link from '@mui/material/Link'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'
import cn from 'classnames'
import { useDrag, useDrop, DragSourceMonitor, DropTargetMonitor } from 'react-dnd'
import moment from 'moment'

import styles from './../NotesContainer.module.scss'
import { isWithinBounds } from '../../../hooks/useAutoScroll'

import { ReactComponent as NextIcon } from '@assets/images/next-icon.svg'
import { ReactComponent as ReplyIcon } from '@assets/images/reply-icon.svg'
import { ReactComponent as LogIcon } from '@assets/images/indent-increase.svg'
import { ReactComponent as DraggableIcon } from '@assets/images/apps-icon.svg'

import { ReactComponent as CheckIcon } from '@assets/images/check-circle-icon-alt.svg'
import { ReactComponent as CheckIconDefault } from '@assets/images/alert-success-snackbar.svg'
import { ReactComponent as PinIcon } from '@assets/images/star-icon.svg'
import { ReactComponent as PhoneIcon } from '@assets/images/phone.svg'
import { ReactComponent as FlagIcon } from '@assets/images/flag-flat-icon.svg'
import { ReactComponent as EmptyIcon } from '@assets/images/no-notes-icon.svg'
import { ReactComponent as PlusCircleIcon } from '@assets/images/plus-circle-icon.svg'
import { ReactComponent as AlertIcon } from '@assets/images/warning-circle-icon.svg'
import { ReactComponent as WarningOutlined } from '@assets/images/small-warning-icon.svg'
import { INote, NotesFilterOptions, NotesTags, TAG_COLOR_MAPPING } from '@common/interfaces/notes'
import Card from '../../Common/Card'
import { stringAvatar } from '../helpers/helpers'
import AddComment from './../AddComment'
import NoteEditor from './../NoteEditor'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import {
  formatDate,
  formatDateCalendarNoTime,
  formatDateTime,
  handleStopPropagation,
  voidHandler,
  formatSeconds,
} from '../../../helpers/helpers'
import { ROUTES } from '../../../constants/routes'
import { REPORTING_DOCUMENT_TYPES } from '@common/constants/client'
import { IUser } from '@common/interfaces/user'
import WarningModal from '../../WarningModal'
import { FlagColors } from '@common/interfaces/bbc'
import { MenuIcon } from '../../Common/Icons'
import { getEmptyImage } from 'react-dnd-html5-backend'

const NO_CLIENT_NAME_ROUTES = [ROUTES.CLIENT_PAGE, ROUTES.PROSPECT_PAGE, ROUTES.DUE_DILIGENCE]

interface DragItem {
  index: number
  id: string
  type: string
  isChild: boolean
  note: INote
}

interface IProps {
  index?: number
  updateNote: (id: string, params: object) => void
  deleteNote: (id: string) => void
  note?: INote
  currentUser?: IUser
  isChild?: boolean
  onClick?: (note: INote) => void
  isExpandModal?: boolean
  handleCloseExpandModal?: () => void
  isSelected?: boolean
  setIsAutoRefresh?: (isAutoRefresh: boolean) => void
  isFlag?: boolean
  hasTable?: boolean
  listNotes?: (data: any) => void
  isFullScreen?: boolean
  addNoteTag?: (params: object) => void
  noteTags?: string[]
  setQuickFilters?: (filters: object) => void
  commentsContainerListClassName?: string
  addCommentClassName?: string
  moveNote?: (dragIndex: number, hoverIndex: number) => void
  onNoteDrop?: (draggedNote: INote, hoverIndex: number) => void
  isDraggable?: boolean
  orderBy?: { direction: string }
  filters?: { dueDiligenceFilters: string[] }
  isNoteBeingDragged?: boolean
  isDragPreview?: boolean
  onNoteUpdate?: (id: string, params: object) => void
  onNoteDelete?: (id: string) => void
  isDefaultNote?: boolean
}

const Breadcrumbs = ({
  index,
  note,
  isFullScreen,
  allTags,
  addNoteTag,
  setQuickFilters,
  isExpandModal,
}: {
  index: number
  note: INote
  isFullScreen: boolean
  allTags?: string[]
  addNoteTag?: (params: object) => void
  setQuickFilters?: (filters: object) => void
  isExpandModal?: boolean
}) => {
  const location = useLocation()
  const { borrowingBase, clientInfo, opsReporting, ongoingReporting, ddInfo } = note
  const [isAddTagShown, setIsAddTagShown] = useState(false)
  const [isShowAllTagsShown, setIsShowAllTagsShown] = useState(false)

  const addTagRef = useRef(null)
  const [inputValue, setInputValue] = useState('')

  const handleAddTagSelection = useCallback(() => {
    setIsAddTagShown(!isAddTagShown)
    isAddTagShown && addTagRef.current.focus()
    setInputValue('')
  }, [isAddTagShown])

  const handleAddTag = useCallback(
    async (e, value) => {
      if (value) {
        await addNoteTag({ id: note.id, tag: value })
      }
      setInputValue('')
    },
    [addNoteTag, note.id],
  )

  const toggleShowAllTags = useCallback((e) => {
    e.stopPropagation()
    setIsShowAllTagsShown((isShowAllTagsShown) => !isShowAllTagsShown)
  }, [])

  const sectionTags = useMemo(() => (note?.tags || []).map((item) => item.tag), [note?.tags])

  const ongoingReportingLink = useMemo(() => {
    if (ongoingReporting?.id) {
      const { id } = ongoingReporting
      switch (ongoingReporting?.type) {
        case REPORTING_DOCUMENT_TYPES.financials:
          return generatePath(ROUTES.ONGOING_REPORTING_SUMMARY, { id })
        case REPORTING_DOCUMENT_TYPES.financialProjections:
          return generatePath(ROUTES.ONGOING_REPORTING_SUMMARY, { id })
        case REPORTING_DOCUMENT_TYPES.bankTransactions:
          return generatePath(ROUTES.ONGOING_REPORTING_BANK_TRANSACTIONS_UPLOAD, { id })
        case REPORTING_DOCUMENT_TYPES.salesBySKU:
          return generatePath(ROUTES.ONGOING_REPORTING_SALES_BY_SKU, { id })
        case REPORTING_DOCUMENT_TYPES.arGeneralLedger:
          return generatePath(ROUTES.ONGOING_REPORTING_AR_GENERAL_LEDGER, { id })
        default:
          break
      }
    }
  }, [ongoingReporting])

  const isDiligence = useMemo(
    () =>
      matchPath(location.pathname, { path: ROUTES.DUE_DILIGENCE }) ||
      matchPath(location.pathname, { path: ROUTES.DUE_DILIGENCE_SETTINGS }),
    [location.pathname],
  )

  const clientPageLink = useMemo(() => {
    if (NO_CLIENT_NAME_ROUTES.some((route) => matchPath(location.pathname, { path: route }))) {
      return null
    }

    if (opsReporting) {
      return generatePath(ROUTES.PROSPECT_PAGE, { id: opsReporting?.id })
    }
    if (ddInfo && clientInfo) {
      return generatePath(ROUTES.DUE_DILIGENCE, { id: clientInfo?.id })
    }
    if (clientInfo) {
      return generatePath(ROUTES.CLIENT_PAGE, { id: clientInfo?.id })
    }
  }, [opsReporting, clientInfo, location, ddInfo])

  const breadcrumbs = useMemo(() => {
    const result: { name: string; link: string; isIndex?: boolean; isFireflies?: boolean }[] = []

    if (clientPageLink) {
      result.push({
        name: clientInfo.clientName,
        link: clientPageLink,
      })
    }

    if (borrowingBase) {
      result.push({
        name: `${borrowingBase.isTest ? 'Test ' : ''}BBC ${formatDate(borrowingBase.recordDate)}`,
        link: generatePath(ROUTES.BBC_SUMMARY, { id: borrowingBase.id }),
      })
    }

    if (
      opsReporting &&
      clientInfo &&
      !matchPath(location.pathname, { path: ROUTES.PROSPECT_PAGE })
    ) {
      result.push({
        name: 'OPS',
        link: generatePath(ROUTES.PROSPECT_SUMMARY_PAGE, { id: clientInfo.id }),
      })
    }

    if (ddInfo && clientInfo && !isDiligence) {
      result.push({
        name: 'Due Diligence',
        link: generatePath(ROUTES.DUE_DILIGENCE, { id: clientInfo.id }),
      })
    }

    if (isDiligence) {
      if (isFullScreen) {
        result.push({
          name: String(index + 1),
          link: null,
          isIndex: true,
        })
      }
      note.tags?.forEach((tag) => {
        if (tag.firefliesSentence) {
          const transcriptDate = moment(tag.firefliesSentence.transcript.date).format('MM/DD')
          const sentenceTimestamp = formatSeconds(tag.firefliesSentence.startTime)

          result.push({
            name: `${transcriptDate} Call - ${sentenceTimestamp}`,
            link: `${tag.firefliesSentence.transcript.transcriptUrl}?t=${tag.firefliesSentence.startTime}`,
            isFireflies: true,
          })
        } else {
          result.push({
            name: tag.tag,
            link: null,
          })
        }
      })
    }

    if (ongoingReporting) {
      result.push({
        name: `${ongoingReporting.type} ${formatDate(ongoingReporting.recordDate)}`,
        link: ongoingReportingLink,
      })
    }

    return result
  }, [
    clientPageLink,
    borrowingBase,
    clientInfo,
    ongoingReporting,
    ongoingReportingLink,
    opsReporting,
    ddInfo,
    location.pathname,
    note?.tags,
    isDiligence,
    index,
    isFullScreen,
  ])

  const handleInputValueChange = (event: React.SyntheticEvent<Element, Event>, value: string) => {
    setInputValue(value)
  }

  const tagOptions = useMemo(() => {
    return allTags?.filter((tag) => {
      return (
        !sectionTags?.includes(tag) &&
        (inputValue ? tag.toLowerCase().includes(inputValue.toLowerCase()) : true)
      )
    })
  }, [allTags, sectionTags, inputValue])

  return (
    <div className={styles.noteBreadcrumbs}>
      {breadcrumbs.map(({ name, link, isIndex, isFireflies }, index) =>
        isDiligence &&
        !isFullScreen &&
        !isExpandModal &&
        !isShowAllTagsShown &&
        index > 0 ? null : (
          <React.Fragment key={index}>
            <a
              className={cn(styles.noteBreadcrumbItem, {
                [styles.diligenceBreadCrumb]: isDiligence,
                [styles.indexBreadCrumb]: isIndex,
              })}
              href={link}
              target={isFireflies ? '_blank' : undefined}
              rel={isFireflies ? 'noopener noreferrer' : undefined}
              style={isDiligence ? TAG_COLOR_MAPPING[name as NotesTags] : {}}
              onClick={
                isDiligence && !isIndex && !isFireflies
                  ? () =>
                      setQuickFilters({
                        dueDiligenceFilters: [NotesFilterOptions.Open],
                        tags: [name],
                      })
                  : voidHandler
              }
            >
              {name}
            </a>

            {index !== breadcrumbs.length - 1 && !isDiligence && (
              <NextIcon className={styles.noteBreadcrumbSeparator} />
            )}
          </React.Fragment>
        ),
      )}
      {isDiligence && isFullScreen && (
        <div className={styles.addTags}>
          {isAddTagShown && (
            <Autocomplete
              className={cn(styles.select, {
                [styles.fieldOpen]: isAddTagShown,
              })}
              inputValue={inputValue}
              onInputChange={handleInputValueChange}
              options={tagOptions}
              onChange={handleAddTag}
              size="small"
              onClose={handleAddTagSelection}
              renderInput={(params) => <TextField {...params} inputRef={addTagRef} />}
            />
          )}
          {!note?.tags?.length && (
            <div className={styles.addTags} onClick={handleAddTagSelection}>
              <PlusCircleIcon className={styles.circleIcon} />
              <div className={styles.addTagsText}>Add tag</div>
            </div>
          )}
        </div>
      )}
      {isDiligence &&
        !isFullScreen &&
        !isExpandModal &&
        breadcrumbs?.length > 1 &&
        !isShowAllTagsShown && (
          <div onClick={toggleShowAllTags} className={styles.noteBreadcrumbItem}>
            <Tooltip title="Show all tags">
              <span>...</span>
            </Tooltip>
          </div>
        )}
    </div>
  )
}

const Note = ({
  index,
  note,
  isChild,
  updateNote,
  deleteNote,
  currentUser,
  onClick,
  isExpandModal = false,
  handleCloseExpandModal,
  isSelected = false,
  setIsAutoRefresh,
  isFlag = false,
  hasTable = false,
  listNotes,
  isFullScreen = false,
  addNoteTag,
  noteTags,
  setQuickFilters,
  commentsContainerListClassName,
  addCommentClassName,
  moveNote,
  onNoteDrop,
  isDraggable,
  isDragPreview,
  orderBy,
  isNoteBeingDragged,
  filters,
  onNoteUpdate,
  onNoteDelete,
  isDefaultNote,
}: IProps) => {
  const fullName = useMemo(() => {
    if (note?.user?.firstName && note?.user?.lastName) {
      return `${note?.user?.firstName} ${note?.user?.lastName}`
    }
    return ''
  }, [note])
  const isOwnOrSystemNote = useMemo(
    () => note?.user?.id === currentUser?.id || !note?.user?.id,
    [note, currentUser],
  )

  const isDiligence = useMemo(() => note?.ddInfo && note?.ddInfo?.id, [note])

  const [isReplyShown, setIsReplyShown] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false)
  const scrollRef = useRef(null)

  useEffect(() => {
    setTimeout(() => {
      const element = scrollRef.current
      if (element) {
        element.scrollTop = element.scrollHeight
      }
    }, 100)
  }, [note?.children, isFlag, hasTable])

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
    setMenuOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
    setMenuOpen(false)
  }, [])

  const handleDelete = useCallback(() => {
    setIsDeleteModalShown(true)
    handleClose()
  }, [handleClose])

  const handleDeleteConfirm = useCallback(() => {
    setIsDeleteModalShown(false)
    handleCloseExpandModal && handleCloseExpandModal()
    if (onNoteDelete) {
      onNoteDelete(note?.id)
    } else {
      deleteNote(note?.id)
    }
  }, [deleteNote, onNoteDelete, note, handleCloseExpandModal])

  const handleDeleteCancel = useCallback(() => {
    setIsDeleteModalShown(false)
  }, [])

  const isActionDisabled = useMemo(
    () => isDefaultNote || note?.resolved || note?.isArchived,
    [isDefaultNote, note?.resolved, note?.isArchived],
  )

  const isResolveDisabled = useMemo(
    () => isDefaultNote || note?.isArchived,
    [isDefaultNote, note?.isArchived],
  )

  const isArchiveDisabled = useMemo(
    () => isDefaultNote || note?.resolved || note?.isCallAgenda,
    [isDefaultNote, note?.resolved, note?.isCallAgenda],
  )

  const handlePin = useCallback(
    async (event: React.MouseEvent) => {
      event.stopPropagation()
      if (isActionDisabled) {
        return
      }
      await updateNote(note?.id, {
        pinned: !note.pinned,
      })
      handleClose()
    },
    [updateNote, note, handleClose, isActionDisabled],
  )

  const handleCallAgenda = useCallback(
    async (event: React.MouseEvent) => {
      event.stopPropagation()
      if (isActionDisabled) {
        return
      }
      await updateNote(note?.id, {
        isCallAgenda: !note.isCallAgenda,
        skipLoader: isDiligence,
      })
      isDiligence && listNotes && (await listNotes({ isDueDiligence: true }))
      handleClose()
    },
    [updateNote, note, handleClose, isDiligence, listNotes, isActionDisabled],
  )

  const handleFlagged = useCallback(
    async (event: React.MouseEvent) => {
      event.stopPropagation()
      if (isActionDisabled) {
        return
      }
      await updateNote(note?.id, {
        isFlagged: !note.isFlagged,
        skipLoader: isDiligence,
      })
      isDiligence && listNotes && (await listNotes({ isDueDiligence: true }))
      handleClose()
    },
    [updateNote, note, handleClose, isDiligence, listNotes, isActionDisabled],
  )

  const handleToggleArchived = useCallback(
    async (event: React.MouseEvent) => {
      event.stopPropagation()
      if (isArchiveDisabled) {
        return
      }
      await updateNote(note?.id, {
        isArchived: !note.isArchived,
        skipLoader: isDiligence,
      })
      handleCloseExpandModal && (await handleCloseExpandModal())

      isDiligence && listNotes && (await listNotes({ isDueDiligence: true }))
      handleClose()
    },
    [
      updateNote,
      note,
      handleClose,
      isDiligence,
      listNotes,
      handleCloseExpandModal,
      isArchiveDisabled,
    ],
  )

  const handleToggleResolve = useCallback(
    async (event: React.MouseEvent) => {
      event.stopPropagation()
      if (isResolveDisabled) {
        return
      }
      await updateNote(note?.id, {
        resolved: !note.resolved,
        pinned: !note.resolved ? false : note.pinned,
        dueDiligenceFilters: filters?.dueDiligenceFilters,
        skipLoader: isDiligence,
      })
      isDiligence && listNotes && (await listNotes({ isDueDiligence: true }))
      handleClose()
    },
    [
      updateNote,
      note,
      handleClose,
      isDiligence,
      listNotes,
      filters?.dueDiligenceFilters,
      isResolveDisabled,
    ],
  )

  const handleEdit = useCallback(() => {
    setIsEditing(true)
    handleClose()
  }, [handleClose])

  const handleSubmitEdit = useCallback(
    async (newNote: object) => {
      if (onNoteUpdate) {
        await onNoteUpdate(note?.id, {
          note: newNote,
        })
      } else {
        await updateNote(note?.id, {
          note: newNote,
          sortDirection: orderBy?.direction,
          dueDiligenceFilters: filters?.dueDiligenceFilters,
          skipLoader: isDiligence,
        })
      }
      setIsEditing(false)
      handleClose()
    },
    [
      updateNote,
      note,
      handleClose,
      orderBy?.direction,
      isDiligence,
      filters?.dueDiligenceFilters,
      onNoteUpdate,
    ],
  )

  const toggleReplyVisibility = useCallback(
    (event?: React.MouseEvent) => {
      event?.stopPropagation()
      if (note.isArchived) {
        return
      }
      setIsReplyShown((isShown) => !isShown)
    },
    [note],
  )

  const toggleExpand = useCallback((event: React.MouseEvent) => {
    event.stopPropagation()
    setIsExpanded((isExpanded) => !isExpanded)
  }, [])

  const isRedFlag = useMemo(() => note?.flags?.flagColor === FlagColors.Red, [note])

  const expandText = useMemo(() => (isExpanded ? 'Hide all' : 'See all replies'), [isExpanded])

  const handleNoteClick = useCallback(() => {
    if (onClick && note) {
      onClick(note)
    }
  }, [note, onClick])

  const replyText = useMemo(() => (isFlag ? 'Comments' : 'Replies'), [isFlag])

  const ref = useRef<HTMLDivElement>(null)

  const handleEnd = useCallback(
    async (item: DragItem) => {
      const newDragOrder = item.index + 1

      if (item.note?.dragOrder !== newDragOrder) {
        await onNoteDrop(item.note, newDragOrder)
        isDiligence && listNotes && (await listNotes({ isDueDiligence: true }))
      }
    },
    [onNoteDrop, isDiligence, listNotes],
  )

  const noteTag = useMemo(() => note?.tags?.find(({ tag }) => !!tag)?.tag || '', [note])

  const [{ isDragging }, drag, preview] = useDrag<DragItem, unknown, { isDragging: boolean }>({
    type: `NOTE-${noteTag}`,
    item: () => {
      return { type: `NOTE-${noteTag}`, id: note.id, index, isChild, note }
    },
    canDrag: () => isDraggable,
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor: DragSourceMonitor) => {
      if (!monitor.didDrop()) {
        handleEnd(item)
      }
    },
  })

  const [{ isDropping }, drop] = useDrop<DragItem, void, { isDropping: boolean }>({
    accept: `NOTE-${noteTag}`,
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return
      }

      const dragIndex = item.index
      const hoverIndex = index
      if (item.isChild !== isChild) {
        return
      }

      if (dragIndex === hoverIndex) {
        return
      }

      const clientOffset = monitor.getClientOffset()
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      if (!isWithinBounds(clientOffset, hoverBoundingRect)) {
        return
      }

      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const hoverClientY = clientOffset.y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      moveNote && moveNote(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
    drop(item: DragItem) {
      handleEnd(item)
    },
    collect: (monitor) => ({
      isDropping: monitor.canDrop() && monitor.isOver(),
    }),
  })

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [preview])

  useEffect(() => {
    if (isDraggable) {
      drag(drop(ref))
    } else {
      drop(ref)
    }
  }, [isDraggable, drag, drop])

  if (!note) {
    return null
  }

  return (
    <Card
      noHeaderMargin
      withBorder={!isChild}
      className={cn(styles.note, {
        [styles.child]: isChild,
        [styles.isEditing]: isEditing || (isExpandModal && isOwnOrSystemNote),
        [styles.isExpandModal]: isExpandModal,
        [styles.selected]: isSelected,
        [styles.isFullScreenCard]: isFullScreen,
        [styles.isDragging]: isDragging,
        [styles.isDragPreview]: isDragPreview,
        [styles.isReplyShown]: isReplyShown,
        [styles.isDraggable]: isDraggable,
      })}
      onClick={handleNoteClick}
      classes={{
        content: styles.noteCardContent,
      }}
      ref={ref}
    >
      {!isFlag && (
        <>
          {!isChild && (
            <div className={cn(styles.noteTopRow, { [styles.grabbableTopRow]: isDraggable })}>
              {isDraggable && <DraggableIcon className={styles.draggableIcon} />}
              <Breadcrumbs
                note={note}
                isFullScreen={isFullScreen}
                allTags={noteTags}
                addNoteTag={addNoteTag}
                setQuickFilters={setQuickFilters}
                isExpandModal={isExpandModal}
                index={index}
              />
              <div className={styles.noteTopRowActions}>
                {!isDefaultNote && !isExpandModal && (
                  <Tooltip title={replyText}>
                    <div
                      className={cn(styles.noteTopRowAction, {
                        [styles.noteTopRowActionDisabled]: note?.isArchived,
                      })}
                      onClick={toggleReplyVisibility}
                    >
                      <ReplyIcon />
                    </div>
                  </Tooltip>
                )}

                {!isDefaultNote && (
                  <Tooltip title={note?.resolved ? 'Unresolve' : 'Resolve'}>
                    <div
                      className={cn(styles.noteTopRowAction, {
                        [styles.noteTopRowActionResolved]: note?.resolved,
                        [styles.noteTopRowActionDisabled]: isResolveDisabled,
                      })}
                      onClick={handleToggleResolve}
                    >
                      {note?.resolved ? <CheckIconDefault /> : <CheckIcon />}
                    </div>
                  </Tooltip>
                )}

                {!isDefaultNote && !isDiligence && (
                  <Tooltip title={note?.pinned ? 'Unpin' : 'Pin'}>
                    <div
                      className={cn(styles.noteTopRowAction, {
                        [styles.noteTopRowActionPinned]: note?.pinned,
                        [styles.noteTopRowActionDisabled]: isActionDisabled,
                      })}
                      onClick={handlePin}
                    >
                      <PinIcon />
                    </div>
                  </Tooltip>
                )}

                {!isDefaultNote && isDiligence && (
                  <Tooltip
                    title={note?.isCallAgenda ? 'Remove from call agenda' : 'Add to call agenda'}
                  >
                    <div
                      className={cn(styles.noteTopRowAction, styles.callAgendaIcon, {
                        [styles.noteTopRowActionOnCallAgenda]: note?.isCallAgenda,
                        [styles.noteTopRowActionDisabled]: isActionDisabled,
                      })}
                      onClick={handleCallAgenda}
                    >
                      <PhoneIcon />
                    </div>
                  </Tooltip>
                )}

                {!isDefaultNote && isDiligence && isFullScreen && (
                  <Tooltip
                    title={
                      note?.isFlagged ? 'Remove from action required' : 'Add to action required'
                    }
                  >
                    <div
                      className={cn(styles.noteTopRowAction, styles.flaggedIcon, {
                        [styles.noteTopRowActionOnActionRequired]: note?.isFlagged,
                        [styles.noteTopRowActionDisabled]: isActionDisabled,
                      })}
                      onClick={handleFlagged}
                    >
                      <FlagIcon />
                    </div>
                  </Tooltip>
                )}

                {(note?.user?.id === currentUser?.id || !note?.user?.id) && (
                  <MenuIcon
                    isActive={menuOpen}
                    onClick={handleClick}
                    className={cn(styles.noteTopRowAction, styles.noteTopRowActionMenu)}
                    size="small"
                  />
                )}

                <Menu
                  classes={{ paper: styles.noteTopRowMenu }}
                  open={menuOpen}
                  onClose={handleClose}
                  anchorEl={anchorEl}
                  disableScrollLock={false}
                  onClick={handleStopPropagation}
                >
                  {!isDefaultNote &&
                    !(isExpandModal || isFullScreen) &&
                    (note?.user?.id === currentUser?.id || !note?.user?.id) && (
                      <MenuItem onClick={handleEdit}>Edit</MenuItem>
                    )}
                  {(note?.user?.id === currentUser?.id || !note?.user?.id) &&
                    (!isDefaultNote && isDiligence ? (
                      <MenuItem
                        classes={{ root: styles.noteTopRowMenuItemDelete }}
                        onClick={handleToggleArchived}
                        disabled={isArchiveDisabled}
                      >
                        {note.isArchived ? 'Unwaive' : 'Waive'}
                      </MenuItem>
                    ) : (
                      <MenuItem
                        classes={{ root: styles.noteTopRowMenuItemDelete }}
                        onClick={handleDelete}
                      >
                        Delete
                      </MenuItem>
                    ))}
                </Menu>
              </div>
            </div>
          )}

          <div
            className={cn(styles.noteWrapper, {
              [styles.noteWrapperParent]: !isChild,
              [styles.withChildren]:
                (!isFullScreen && note?.children?.length > 1) ||
                (isFullScreen && note?.children?.length > 3),
            })}
          >
            {note.user && !isEditing ? (
              <div className={styles.noteAvatarWrapper}>
                {note?.user?.avatar ? (
                  <Avatar className={styles.noteAvatar} alt={fullName} src={note?.user?.avatar} />
                ) : (
                  <Avatar className={styles.noteAvatar} {...stringAvatar(fullName)} />
                )}
              </div>
            ) : note?.flags && !isExpandModal ? (
              <div className={styles.noteAvatarWrapper}>
                <Avatar
                  className={cn(styles.noteAvatarFlags, {
                    [styles.notesAvatarYellowFlag]: !isRedFlag,
                  })}
                  alt={'flag'}
                >
                  {isRedFlag ? <AlertIcon /> : <WarningOutlined />}
                </Avatar>
              </div>
            ) : note?.bbcLog && !isExpandModal ? (
              <div className={styles.noteAvatarWrapper}>
                <Avatar className={styles.noteAvatarLog}>
                  <LogIcon />
                </Avatar>
              </div>
            ) : null}
            <div
              className={cn(styles.noteContainer, {
                [styles.noteContainerNoAvatar]: !note.user,
              })}
            >
              <div className={styles.noteHeading}>
                <div
                  className={cn(styles.noteHeadingUserAndDate, {
                    [styles.dateNoAvatar]: !note.user && isFullScreen,
                    [styles.dateNoAvatarRight]: !note.user && !isFullScreen,
                  })}
                >
                  {note.user && (
                    <div className={styles.noteHeadingUser}>{note?.user?.firstName}</div>
                  )}
                  <div className={styles.noteHeadingDate}>
                    {note?.createdAt && (
                      <Tooltip title={formatDateTime(note?.createdAt?.toString())}>
                        <span>{formatDateCalendarNoTime(note?.createdAt?.toString(), true)}</span>
                      </Tooltip>
                    )}
                    {note?.isNoteTextEdited && (
                      <Tooltip title={formatDateTime(note?.noteTextUpdatedAt)}>
                        <span>&nbsp;(edited)</span>
                      </Tooltip>
                    )}
                  </div>
                </div>

                {isChild && (
                  <div className={styles.noteTopRowActions}>
                    <Tooltip title={note?.resolved ? 'Unresolve' : 'Resolve'}>
                      <div
                        className={cn(styles.noteTopRowAction, {
                          [styles.noteTopRowActionResolved]: note?.resolved,
                          [styles.noteTopRowActionDisabled]: isResolveDisabled,
                        })}
                        onClick={handleToggleResolve}
                      >
                        {note?.resolved ? <CheckIconDefault /> : <CheckIcon />}
                      </div>
                    </Tooltip>

                    {(note?.user?.id === currentUser?.id || !note?.user?.id) && (
                      <MenuIcon
                        isActive={menuOpen}
                        onClick={handleClick}
                        className={cn(styles.noteTopRowAction, styles.noteTopRowActionMenu)}
                        size="small"
                      />
                    )}

                    <Menu
                      classes={{ paper: styles.noteTopRowMenu }}
                      open={menuOpen}
                      onClose={handleClose}
                      anchorEl={anchorEl}
                      disableScrollLock={false}
                      onClick={handleStopPropagation}
                    >
                      {!(isExpandModal || isFullScreen) &&
                        (note?.user?.id === currentUser?.id || !note?.user?.id) && (
                          <MenuItem onClick={handleEdit}>Edit</MenuItem>
                        )}
                      {(note?.user?.id === currentUser?.id || !note?.user?.id) && (
                        <MenuItem
                          classes={{ root: styles.noteTopRowMenuItemDelete }}
                          onClick={handleDelete}
                        >
                          Delete
                        </MenuItem>
                      )}
                    </Menu>
                  </div>
                )}
              </div>
              <div
                className={cn(styles.noteBody, {
                  [styles.fullScreenTextCursor]: isFullScreen && isOwnOrSystemNote,
                })}
                onClick={
                  isEditing || ((isExpandModal || isFullScreen) && isOwnOrSystemNote)
                    ? handleStopPropagation
                    : undefined
                }
              >
                <NoteEditor
                  key={note?.id}
                  value={note?.note}
                  handleUpdate={
                    (isEditing || ((isExpandModal || isFullScreen) && isOwnOrSystemNote)) &&
                    handleSubmitEdit
                  }
                  readOnly={
                    isDragging ||
                    isDropping ||
                    (!isEditing && !((isExpandModal || isFullScreen) && isOwnOrSystemNote))
                  }
                  isFullScreen={isFullScreen}
                  isExpanded={isExpandModal}
                  noteText={note?.noteText}
                  attachments={note?.attachments}
                  withToolbar
                  isToolbarStatic={!isExpandModal && !isFullScreen}
                  setIsAutoRefresh={setIsAutoRefresh}
                  isNoteBeingDragged={isNoteBeingDragged}
                />
              </div>
            </div>
          </div>
        </>
      )}
      <Card
        noHeaderMargin
        withBorder={false}
        className={styles.childrenContainer}
        classes={{
          content: styles.childrenContainerContent,
        }}
      >
        {!isExpandModal &&
          ((!isFullScreen && note?.children?.length > 1) ||
            (isFullScreen && note?.children?.length > 3)) && (
            <div className={styles.expandTextContainer}>
              <Link className={styles.expandText} onClick={toggleExpand}>
                {expandText}
              </Link>
            </div>
          )}

        {isExpandModal && <div className={styles.noteRepliesLabel}>{replyText}</div>}

        <div
          className={cn(
            styles.childrenContainerList,
            {
              [styles.childrenContainerListFlag]: isFlag,
              [styles.childrenContainerListFlagWithTable]: isFlag && hasTable,
              [styles.childrenContainerListEmpty]: isExpandModal && !note?.children?.length,
            },
            commentsContainerListClassName,
          )}
          ref={scrollRef}
        >
          {isExpandModal && !note?.children?.length && (
            <div className={styles.emptyContainer}>
              {!isFlag && <EmptyIcon />}
              <div className={styles.emptyContainerText}>
                There are no {replyText.toLowerCase()} at this time.
              </div>
            </div>
          )}
          <div
            className={cn(styles.childrenCommentItems, {
              [styles.childrenCommentItemsFlag]: isFlag,
            })}
          >
            {note?.children?.length > 0 &&
              note?.children?.map((child, index) => {
                const isNotesChildrenShown =
                  (isFullScreen && index > note?.children?.length - 4) ||
                  index === note?.children?.length - 1 ||
                  isExpanded ||
                  isExpandModal
                if (isNotesChildrenShown) {
                  return (
                    <Note
                      key={child.id}
                      note={child}
                      isChild
                      updateNote={updateNote}
                      deleteNote={deleteNote}
                      currentUser={currentUser}
                      setIsAutoRefresh={isFullScreen && setIsAutoRefresh}
                      isFullScreen={isFullScreen}
                    />
                  )
                }
                return null
              })}
          </div>
          {(isExpandModal || isReplyShown) && !isChild && (
            <AddComment
              className={addCommentClassName}
              parent={note}
              hideReply={isExpandModal ? undefined : toggleReplyVisibility}
              isDiligence={!!isDiligence}
              listNotes={listNotes}
            />
          )}
        </div>
      </Card>
      {isDeleteModalShown && (
        <WarningModal
          warningMessage="Are you sure you want to delete this comment?"
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
          confirmText="Delete"
          cancelText="Cancel"
        />
      )}
    </Card>
  )
}

export default Note
