import React, { useMemo } from 'react'
import ArrowIcon from '@mui/icons-material/PlayArrow'
import cn from 'classnames'

import styles from './RenderedValue.module.scss'

import { FieldType } from '@common/interfaces/client'
import { formatDate, formatPercent, formatPriceNoDecimal } from '../../helpers/helpers'

const RenderedValue = ({
  value,
  type,
  previousValue,
  percentWithScale,
  numberDecimals = 2,
}: {
  value: string | number
  previousValue?: string | number
  type: FieldType
  percentWithScale?: boolean
  numberDecimals?: number
}) => {
  const changedValue = useMemo(() => {
    if (![FieldType.Number, FieldType.Currency, FieldType.Percent].includes(type)) {
      return null
    }
    if (
      (!value && value !== 0) ||
      typeof value !== 'number' ||
      (!previousValue && previousValue !== 0) ||
      typeof previousValue !== 'number'
    ) {
      return null
    }

    if (value === previousValue) {
      return null
    }

    return value - previousValue
  }, [value, previousValue, type])

  const renderedValue = useMemo(() => {
    if (value === null) {
      return '-'
    }

    if (type === FieldType.Currency) {
      return <>${formatPriceNoDecimal(value)}</>
    }

    if (type === FieldType.Percent) {
      return formatPercent(value as number, 2, percentWithScale ? 1 : 100)
    }

    if (type === FieldType.Date) {
      return value ? formatDate(value as string) : '-'
    }

    if (type === FieldType.Number) {
      if (value === null || value === undefined) {
        return '-'
      }

      return (+value as number)?.toFixed(numberDecimals)
    }

    if (type === FieldType.Boolean || typeof value === 'boolean') {
      return value ? 'Yes' : 'No'
    }

    return value || '-'
  }, [value, type, percentWithScale, numberDecimals])

  return (
    <>
      {changedValue && changedValue !== 0 && (
        <ArrowIcon
          className={cn(styles.arrow, {
            [styles.up]: changedValue > 0,
            [styles.down]: changedValue < 0,
          })}
        />
      )}

      {renderedValue}
    </>
  )
}

export default RenderedValue
