import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import CollectionsDashboardPage from './CollectionsDashboardPage'
import { isLoading as isLoadingCollections, aggregation } from '../../redux/collections/selectors'
import { clients } from '../../redux/clientInfo/selectors'
import { listAggregation, exportAggregation } from '../../redux/collections/actions'
import { list as listClients } from '../../redux/clientInfo/actions'
import { listEntityInfo } from '../../redux/entityInfo/actions'

const selector = createStructuredSelector({
  isLoadingCollections,
  aggregationData: aggregation,
  clients,
})

const actions = {
  listAggregation,
  exportAggregation,
  listClients,
  listEntityInfo,
}

export default connect(selector, actions)(CollectionsDashboardPage)
