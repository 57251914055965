import { useMemo } from 'react'

/**
 * Configuration for fields that should be calculated as percentages
 */

interface PercentageFieldConfig<T> {
  field: keyof T & string // Field to calculate percentage for
  totalField: keyof T & string // Field containing the total value
  decimals?: number // Number of decimal places (default: 2)
  totalSource?: 'data' | 'result' // Where to get the total from (default: 'data')
  total?: number
}


interface WeightAverageFieldConfig<T> {
  field: keyof T & string
  sumField: keyof T & string
  divisor: keyof T & string
}
/**
 * Configuration for custom calculations
 */
interface CustomCalculationConfig<T> {
  field: keyof T & string
  calculate: (rows: T[], activeRows: number[]) => any
}

/**
 * Configuration for the summary row calculation
 */
type SummaryConfig<T> = {
  // Fields that should be summed directly
  sumFields: Array<keyof T & string>
  // Fields that should be averaged
  averageFields?: Array<keyof T & string>
  // Fields that should be calculated as percentages of a total
  percentageFields?: PercentageFieldConfig<T>[]
  //Fields that should be weight averaged
  weightAverageFields?: WeightAverageFieldConfig<T>[]
  // Optional custom calculations
  customCalculations?: CustomCalculationConfig<T>[]
}

/**
 * Hook for calculating summary rows in tables
 *
 * @param data Array of data objects
 * @param activeRows Array of active row indices
 * @param config Configuration for summary calculations
 * @returns Summary row object with calculated values
 */
function useSummaryRow<T extends Record<string, any>>(
  data: T[] | undefined,
  activeRows: number[],
  config: SummaryConfig<T>,
): T | null {
  return useMemo(() => {
    if (!data || !activeRows.length) {
      return null
    }

    const activeRowsData = data.filter((_, index) => activeRows.includes(index))

    // Initialize result object with zeros for all fields
    const result = {} as Record<string, any>

    // Initialize all fields with 0
    config.sumFields.forEach((field) => {
      result[field] = 0
    })

    config.averageFields?.forEach((field) => {
      result[field] = 0
    })

    config.percentageFields?.forEach(({ field }) => {
      result[field] = 0
    })

    config.weightAverageFields?.forEach(({ field }) => {
      result[`${field}_sum`] = 0
      result[`${field}_divisor`] = 0
    })
    // Calculate sums and averages
    activeRowsData.forEach((row) => {
      // Sum fields
      config.sumFields.forEach((field) => {
        result[field] += +(row[field] || 0)
      })

      // Average fields
      config.averageFields?.forEach((field) => {
        result[field] += +(row[field] || 0) / activeRows.length
      })
      // Weight average fields
      config.weightAverageFields?.forEach(({ field, sumField, divisor }) => {
        result[`${field}_sum`] += +(row[sumField] || 0)
        result[`${field}_divisor`] += +(row[divisor] || 0)
      })
    })

    // Calculate percentages
    config.percentageFields?.forEach(
      ({ field, totalField, decimals = 2, totalSource = 'data', total }) => {
        if (total) {
          result[field] = +((result[totalField] / total) * 100).toFixed(decimals)
        } else if (totalSource === 'data') {
          // Calculate percentage based on the sum of this field across all data
          const totalValue = data.reduce((sum, row) => sum + (row[totalField] || 0), 0)
          if (totalValue) {
            result[field] = +(((result[totalField] || 0) / totalValue) * 100).toFixed(decimals)
          } else {
            result[field] = 0
          }
        } else {
          // Calculate percentage based on a field in the result (like for AR aging buckets)
          const totalValue = result[totalField] || 0
          if (totalValue) {
            result[field] = +((result[field] / totalValue) * 100).toFixed(decimals)
          } else {
            result[field] = 0
          }
        }
      },
    )


    config.weightAverageFields?.forEach(({ field }) => {
      result[field] = +(result[`${field}_sum`] / result[`${field}_divisor`])
    })
    // Apply custom calculations
    config.customCalculations?.forEach(({ field, calculate }) => {
      result[field] = calculate(activeRowsData, activeRows)
    })

    return result as T
  }, [data, activeRows, config])
}

export default useSummaryRow
