import config from '../config'
import ApiClient from './client'
import Auth from './auth'
import Box from './box'
import ClientInfo from './clientInfo'
import Collection from './collection'
import Salesforce from './salesforce'
import User from './user'
import Bank from './bank'
import BankAccount from './bankAccount'
import ReportingDocument from './reportingDocument'
import RequiredReport from './requiredReport'
import Frequency from './frequency'
import Dashboard from './dashboard'
import Fee from './fee'
import Adjustment from './adjustment'
import LoanBalance from './loanBalance'
import PassThroughs from './passThroughs'
import OutgoingWire from './outgoingWire'
import Activity from './activity'
import Bbc from './bbc'
import AuditLog from './auditLog'
import File from './file'
import OngoingReporting from './ongoingReporting'
import BankTransactions from './bankTransactions'
import CapTable from './capTable'
import Prospect from './prospect'
import GeneralLedger from './generalLedger'
import Search from './search'
import Bdo from './bdo'
import EntityInfo from './entityInfo'
import Notes from './notes'
import Treasury from './treasury'
import DueDiligence from './dueDiligence'
import ClientIntake from './clientIntake'
import Notification from './notification'
import Newsfeed from './newsfeed'
import Participant from './participant'
import BBCLog from './bbcLog'
import VisualizationsTracking from './visualizationsTracking'
import Flag from './flags'
import Logger from './logger'
import Option from './option'
import AtlasAI from './atlasai'

const apiClient: ApiClient = new ApiClient(config.apiPrefix)

export interface IApi {
  auth: Auth
  box: Box
  clientInfo: ClientInfo
  collection: Collection
  salesforce: Salesforce
  user: User
  bank: Bank
  bankAccount: BankAccount
  reportingDocument: ReportingDocument
  requiredReport: RequiredReport
  frequency: Frequency
  dashboard: Dashboard
  fee: Fee
  adjustment: Adjustment
  loanBalance: LoanBalance
  passThroughs: PassThroughs
  outgoingWire: OutgoingWire
  activity: Activity
  bbc: Bbc
  auditLog: AuditLog
  file: File
  ongoingReporting: OngoingReporting
  client: ApiClient
  bankTransactions: BankTransactions
  capTable: CapTable
  prospect: Prospect
  generalLedger: GeneralLedger
  search: Search
  bdo: Bdo
  entityInfo: EntityInfo
  notes: Notes
  dueDiligence: DueDiligence
  clientIntake: ClientIntake
  notification: Notification
  newsfeed: Newsfeed
  participant: Participant
  bbcLog: BBCLog
  visualizationsTracking: VisualizationsTracking
  flag: Flag
  logger: Logger
  option: Option
  treasury: Treasury
  atlasAI: AtlasAI
}

const apis = {
  auth: new Auth(apiClient),
  box: new Box(apiClient),
  collection: new Collection(apiClient),
  clientInfo: new ClientInfo(apiClient),
  salesforce: new Salesforce(apiClient),
  user: new User(apiClient),
  bank: new Bank(apiClient),
  bankAccount: new BankAccount(apiClient),
  reportingDocument: new ReportingDocument(apiClient),
  requiredReport: new RequiredReport(apiClient),
  frequency: new Frequency(apiClient),
  dashboard: new Dashboard(apiClient),
  fee: new Fee(apiClient),
  adjustment: new Adjustment(apiClient),
  loanBalance: new LoanBalance(apiClient),
  passThroughs: new PassThroughs(apiClient),
  outgoingWire: new OutgoingWire(apiClient),
  activity: new Activity(apiClient),
  bbc: new Bbc(apiClient),
  auditLog: new AuditLog(apiClient),
  file: new File(apiClient),
  ongoingReporting: new OngoingReporting(apiClient),
  client: apiClient,
  bankTransactions: new BankTransactions(apiClient),
  capTable: new CapTable(apiClient),
  prospect: new Prospect(apiClient),
  generalLedger: new GeneralLedger(apiClient),
  search: new Search(apiClient),
  bdo: new Bdo(apiClient),
  entityInfo: new EntityInfo(apiClient),
  notes: new Notes(apiClient),
  dueDiligence: new DueDiligence(apiClient),
  clientIntake: new ClientIntake(apiClient),
  notification: new Notification(apiClient),
  newsfeed: new Newsfeed(apiClient),
  participant: new Participant(apiClient),
  bbcLog: new BBCLog(apiClient),
  visualizationsTracking: new VisualizationsTracking(apiClient),
  flag: new Flag(apiClient),
  logger: new Logger(apiClient),
  option: new Option(apiClient),
  treasury: new Treasury(apiClient),
  atlasAI: new AtlasAI(apiClient),
}

export default apis
