import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import DueDiligenceCapTableMappingPage from './DueDiligenceCapTableMappingPage'
import { dueDiligenceInfo } from '../../redux/dueDiligence/selectors'
import { show } from '../../redux/dueDiligence/actions'
import { capTableFilters } from '../../redux/capTable/selectors'
import { listCapTableFilters } from '../../redux/capTable/actions'

const selector = createStructuredSelector({
  dueDiligenceInfo,
  capTableFilters,
})
const actions = {
  show,
  listCapTableFilters,
}

export default connect(selector, actions)(DueDiligenceCapTableMappingPage)
