import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link as RouterLink, useParams, generatePath } from 'react-router-dom'
import { Form } from 'react-final-form'
import Box from '@mui/material/Box'
import LinkButton from '@mui/material/Link'
import InfiniteScroll from 'react-infinite-scroll-component'
import cn from 'classnames'

import styles from './PendingCashDominion.module.scss'
import genericSs from '@styles/generic.module.scss'

import {
  CLIENT_PENDING_CASH_DOMINION_LIST_FILTERS_CONFIG,
  PER_PAGE,
} from '@common/constants/filters'
import { debounceEventHandler, formatDate, formatPrice } from '../../helpers/helpers'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import FilterContainer from '../Filters/FilterContainer'
import { ILoadingData } from '../../redux/types'
import { ExpandAndMinimize } from '../Common/Icons'
import FullscreenModal from '../Common/FullscreenModal'
import useTable from '../../hooks/useTable'
import { IPendingCashDominionData } from '@common/interfaces/bankTransactions'
import { CATEGORIES } from '@common/constants/tracking'
import useTrackVisualizationsTable from '../../hooks/useTrackVisualizationsTable'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableFiltersRow from '../Common/TableFiltersRow'
import TableBody from '../Common/TableBody'
import TableLoader from '../Common/TableLoader'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import { ROUTES } from '../../constants/routes'
import MultiselectRow from '../MultiselectRow'
import TableContainer from '../Common/TableContainer'
import useSummaryRow from '../../hooks/useSummaryRow'

const filtersValidate = buildFiltersValidateSchema(CLIENT_PENDING_CASH_DOMINION_LIST_FILTERS_CONFIG)
const filtersDefaults = buildFiltersDefaults(CLIENT_PENDING_CASH_DOMINION_LIST_FILTERS_CONFIG)

const sortDefault = {
  field: 'entity_name',
  direction: 'ASC' as const,
}

interface IProps {
  pendingCashDominion: ILoadingData<IPendingCashDominionData>
  listPendingCashDominion: (id: string, params: object) => void
}

const PendingCashDominion = ({ pendingCashDominion, listPendingCashDominion }: IProps) => {
  const { id } = useParams<{ id: string }>()
  const wrapperRef = useRef(null)
  const [isModalShown, setIsModalShown] = useState(false)

  const {
    filters,
    orderBy,
    handleFiltersChange,
    handleOrderChange,
    activeItem,
    activeItems,
    setActiveItem,
    setActiveItems,
    handleSelectRow,
    quickFilter,
    handleQuickFilterChange,
  } = useTable({
    tableId: 'pendingCashDominion',
    filtersDefaults,
    sortDefault,
  })

  const { isLoading, pendingCashDominionData, itemsCount, totalItems } = useMemo(
    () => ({
      isLoading: pendingCashDominion.isLoading,
      pendingCashDominionData: pendingCashDominion.data?.data || [],
      itemsCount: pendingCashDominion.data?.data?.length || 0,
      totalItems: pendingCashDominion.data?.totals?.totalItems || 0,
    }),
    [pendingCashDominion],
  )

  const fetchPendingCashDominion = useCallback(
    (data: any) => {
      listPendingCashDominion(id, data)
    },
    [listPendingCashDominion, id],
  )

  const loadMore = useCallback(
    () =>
      fetchPendingCashDominion({
        loadMore: true,
        page: Math.ceil(itemsCount / PER_PAGE),
        perPage: Number(PER_PAGE),
        filters,
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
      }),
    [filters, orderBy, fetchPendingCashDominion, itemsCount],
  )

  const debounceFetchPendingCashDominion = useMemo(
    () => debounceEventHandler(fetchPendingCashDominion, 500),
    [fetchPendingCashDominion],
  )

  useEffect(() => {
    debounceFetchPendingCashDominion({
      filters,
      page: 0,
      perPage: PER_PAGE,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [filters, orderBy, debounceFetchPendingCashDominion])

  const handleModalToggle = useCallback(() => {
    setIsModalShown((prev) => !prev)
  }, [])

  const visualizationsParams = useMemo(
    () => ({
      clientId: id,
    }),
    [id],
  )

  useTrackVisualizationsTable({
    category: CATEGORIES.pendingCashDominion,
    params: visualizationsParams,
    filtersConfig: CLIENT_PENDING_CASH_DOMINION_LIST_FILTERS_CONFIG,
    filters: filters,
  })

  const totalRow = useSummaryRow(pendingCashDominionData, activeItems, {
    sumFields: ['amount'],
  })

  return (
    <FullscreenModal
      isOpen={isModalShown}
      setIsOpen={setIsModalShown}
      classes={{ body: styles.fullScreenModal }}
    >
      <div>
        <Form
          validate={filtersValidate}
          onSubmit={handleFiltersChange}
          initialValues={filters}
          mutators={{
            setFieldData: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value)
            },
          }}
          render={({ values, handleSubmit, form: { mutators } }) => (
            <FilterContainer
              filters={CLIENT_PENDING_CASH_DOMINION_LIST_FILTERS_CONFIG}
              handleSubmit={handleSubmit}
              mutators={mutators}
              values={values}
              appliedFilters={filters}
              appliedQuickFilter={quickFilter}
              handleAppliedQuickFilterChange={handleQuickFilterChange}
              title="Pending Cash Dominion"
              actions={
                <Box display="flex" alignItems="center" gap={1}>
                  <ExpandAndMinimize action={handleModalToggle} isExpanded={isModalShown} />
                </Box>
              }
            />
          )}
        />

        <TableContainer
          id="pendingCashDominionTable"
          className={styles.table}
          onActiveRowsChange={setActiveItems}
          onActiveRowChange={setActiveItem}
        >
          <Table ref={wrapperRef}>
            <TableHead>
              <TableFiltersRow
                filters={CLIENT_PENDING_CASH_DOMINION_LIST_FILTERS_CONFIG}
                orderBy={orderBy}
                handleOrderChange={handleOrderChange}
              />
            </TableHead>
            <TableBody id="pendingCashDominionScrollableTable">
              {isLoading ? (
                <TableLoader
                  columnsCount={CLIENT_PENDING_CASH_DOMINION_LIST_FILTERS_CONFIG.length}
                />
              ) : (
                <InfiniteScroll
                  dataLength={itemsCount}
                  next={loadMore}
                  hasMore={itemsCount < totalItems}
                  loader={
                    <TableLoader
                      columnsCount={CLIENT_PENDING_CASH_DOMINION_LIST_FILTERS_CONFIG.length}
                    />
                  }
                  scrollableTarget="pendingCashDominionScrollableTable"
                >
                  {pendingCashDominionData.map((item, index) => (
                    <TableRow
                      key={item.entity_id}
                      data-index={index}
                      className={cn('activableRow', {
                        activeRow: activeItems.includes(index),
                        currentActiveRow: activeItem === index,
                      })}
                      onClick={(event) => handleSelectRow(event, index)}
                    >
                      <TableCell className={genericSs.tableTextLeft}>
                        <LinkButton
                          component={RouterLink}
                          to={generatePath(ROUTES.ENTITY_PAGE, {
                            id: item.entity_id,
                          })}
                        >
                          {item.entity_name}
                        </LinkButton>
                      </TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        {formatDate(item.record_date)}
                      </TableCell>
                      <TableCell className={genericSs.tableTextLeft}>Customer Collection</TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        ${formatPrice(item.amount)}
                      </TableCell>
                    </TableRow>
                  ))}
                </InfiniteScroll>
              )}
              <MultiselectRow activeItems={activeItems}>
                <TableCell className={genericSs.tableTextRight}>
                  ${formatPrice(totalRow?.amount)}
                </TableCell>
              </MultiselectRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </FullscreenModal>
  )
}

export default PendingCashDominion
