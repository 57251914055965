import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router'
import Tooltip from '@mui/material/Tooltip'

import styles from './ClientBanners.module.scss'

import { ClientBannerCustomAction, IClientBanner, IClientInfo } from '@common/interfaces/client'
import { CloseIcon } from '../Common/Icons'
import Modal from '../Common/Modal'
import ClientBannerModalContent from './ClientBannerModalContent'

const images = require['context']('../../assets/images', true)

interface IProps {
  clientInfo: IClientInfo
  banner: IClientBanner
  closeBanner: (id: string) => Promise<void>
}

const ClientBannerItem = ({ clientInfo, banner, closeBanner }: IProps) => {
  const history = useHistory()
  const [isModalShown, setIsModalShown] = useState(false)

  const onLinkClick = useCallback(() => {
    if (!banner?.link) {
      return null
    }

    closeBanner(banner.id)
    if (banner.link.startsWith('/')) {
      history.push(banner.link)
    } else {
      window.open(banner.link)
    }
  }, [banner, history, closeBanner])

  const onCustomActionClick = useCallback(() => {
    if (!banner?.data?.action) {
      return null
    }

    switch (banner.data.action) {
      case ClientBannerCustomAction.ShowModal:
        setIsModalShown(true)
        break
    }
  }, [banner])

  const onCloseModal = useCallback(() => {
    setIsModalShown(false)
  }, [])

  const onClose = useCallback(() => {
    if (!banner) {
      return null
    }

    closeBanner(banner.id)
  }, [closeBanner, banner])

  const loadImage = useCallback(() => {
    if (banner.icon) {
      return images(`./${banner.icon}`)
    }

    return null
  }, [banner])

  if (!banner) {
    return null
  }

  return (
    <div className={styles.clientBanner} style={{ background: banner.color }}>
      {banner.icon && <img src={loadImage()} alt="Icon" />}
      {banner.holidayId ? (
        <span className={styles.clientBannerContent}>
          <span className={styles.title}>{banner.title}</span>&nbsp;
          <span
            dangerouslySetInnerHTML={{
              __html: banner.description,
            }}
          />
        </span>
      ) : (
        <Tooltip title={`${banner.title} ${banner.description}`} placement="bottom">
          <span className={styles.clientBannerContent}>
            <span className={styles.title}>{banner.title}</span>&nbsp;
            {banner.description}
          </span>
        </Tooltip>
      )}
      {banner.link && (
        <div onClick={onLinkClick} className={styles.link}>
          Take me there
        </div>
      )}
      {banner.data.action && (
        <div onClick={onCustomActionClick} className={styles.link}>
          {banner.data.label || 'View details'}
        </div>
      )}
      <CloseIcon action={onClose} />

      {isModalShown && (
        <Modal
          open
          onCancel={onCloseModal}
          title={banner.data?.data?.title}
          classes={{
            title: styles.modalTitle,
          }}
        >
          <ClientBannerModalContent clientInfo={clientInfo} banner={banner} />
        </Modal>
      )}
    </div>
  )
}

export default ClientBannerItem
