import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { Link, generatePath } from 'react-router-dom'
import LinkButton from '@mui/material/Link'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import cn from 'classnames'
import InfiniteScroll from 'react-infinite-scroll-component'
import moment from 'moment'
import TextField from '../Common/TextField'
import styles from './FundingWiresTable.module.scss'
import genericSs from '@styles/generic.module.scss'
import WarningModal from '../WarningModal'
import { IClientInfo } from '@common/interfaces/client'
import {
  dateToString,
  databaseDateFormat,
  debounceEventHandler,
  formatDate,
  formatPrice,
  formatter,
  formatDateTime,
} from '../../helpers/helpers'
import Card from '../Common/Card'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TableBody from '../Common/TableBody'
import { IFundingWire, IFundingWiresData } from '@common/interfaces/fundingWire'
import Button from '../Common/Button'
import { IFilter } from '@common/constants/filters'
import TableFiltersRow from '../Common/TableFiltersRow'
import { ROUTES } from '../../constants/routes'
import CurrencyField from '../Common/CurrencyField'
import { DATE_DATABASE_FORMAT, DATE_FORMAT } from '../../constants/common'
import KeyboardDatePicker from '../Common/KeyboardDatePicker'
import {
  FUNDING_WIRES_FILTERS_CONFIG,
  FUNDING_WIRES_PARTICIPANTS_FILTERS_CONFIG,
  PER_PAGE,
} from '@common/constants/filters'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import FilterContainer from '../Filters/FilterContainer'

import MultiselectRow from '../MultiselectRow'
import { ILoadingData } from '../../redux/types'
import useTable from '../../hooks/useTable'
import TableLoader from '../Common/TableLoader'
import { IFunding } from '@common/interfaces/bbc'
import { AddIcon, BoxLink, DeleteIcon, EditIcon, SendIcon } from '../Common/Icons'
import Modal from '../Common/Modal'
import { BoxSignStatus } from '@common/interfaces/box'
import { usePermissions } from '../../helpers/permissionContext'
import { ActivityStatus } from '@common/interfaces/activity'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { MenuIcon } from '../Common/Icons'
import MultiSelectToolbar from '../MultiSelectToolbar'
import useSummaryRow from '../../hooks/useSummaryRow'

const mutators = {
  ...arrayMutators,
}

const today = moment()
const monthAgo = moment().subtract(1, 'month').format(DATE_DATABASE_FORMAT)

const DeleteMenu = ({ handleDeleteWire }: { handleDeleteWire: () => void }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const handleClickMenu = useCallback((event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget)
    setIsMenuOpen(true)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
    setIsMenuOpen(false)
  }, [])

  const handleDelete = useCallback(async () => {
    handleCloseMenu()
    await handleDeleteWire()
  }, [handleCloseMenu, handleDeleteWire])

  return (
    <>
      <MenuIcon isActive={isMenuOpen} onClick={handleClickMenu} size="small" />
      <Menu
        classes={{ paper: styles.iconMenu }}
        open={isMenuOpen}
        onClose={handleCloseMenu}
        anchorEl={anchorEl}
      >
        <MenuItem classes={{ root: styles.iconMenuItem }} onClick={handleDelete}>
          Delete
        </MenuItem>
      </Menu>
    </>
  )
}

interface IProps {
  isAdminRightsRole: boolean
  fundingWiresData: ILoadingData<IFundingWiresData>
  clients: ILoadingData<{ data: IClientInfo[] }>
  listClients: () => void
  listOutgoingWire: (params?: {
    filters?: object
    orderBy?: string
    orderDirection?: string
  }) => void
  sentOutgoingWires: (data: object) => Promise<void>
  loanBalanceStartDate: string
  putNotification: (notification: object) => void
  updateBBC: (id: string, data: object) => void
  updateActivity: (id: string, data: object) => void
}

const FundingWiresTable = ({
  isAdminRightsRole,
  fundingWiresData,
  clients,
  listClients,
  listOutgoingWire,
  sentOutgoingWires,
  loanBalanceStartDate,
  putNotification,
  updateBBC,
  updateActivity,
}: IProps) => {
  const wrapperRef = useRef(null)
  const { isParticipant } = usePermissions()
  const [selectedWires, selectWires] = useState([])
  const [isConfirmModalShown, setIsConfirmModalShown] = useState(false)
  const [wiresSending, setWiresSending] = useState([])
  const [bbcLinkBeingEdited, setBBCLinkBeingEdited] = useState({
    id: '',
    link: '',
  })
  const [isBBCLinkUpdating, setIsBBCLinkUpdating] = useState(false)
  const [deleteWireActivityId, setDeleteWireActivityId] = useState('')
  const [wireDeleteIsLoading, setWireDeleteIsLoading] = useState(false)

  const initialFilter = useMemo(
    () =>
      isParticipant ? FUNDING_WIRES_PARTICIPANTS_FILTERS_CONFIG : FUNDING_WIRES_FILTERS_CONFIG,

    [isParticipant],
  )

  const filtersValidate = buildFiltersValidateSchema(initialFilter)

  const { itemsCount, totalItems, fundingWires, isLoading } = useMemo(
    () => ({
      itemsCount: fundingWiresData?.data?.data?.length,
      totalItems: fundingWiresData?.data?.totals.totalItems,
      fundingWires: fundingWiresData?.data?.data,
      isLoading: fundingWiresData?.isLoading,
    }),
    [fundingWiresData],
  )

  const filtersDefaults = useMemo(() => {
    return buildFiltersDefaults(initialFilter, {
      sentDateFrom: isParticipant ? monthAgo : today.format(DATE_DATABASE_FORMAT),
      sentDateTo: today.format(DATE_DATABASE_FORMAT),
    })
  }, [isParticipant, initialFilter])

  const {
    filters,
    handleFiltersChange,
    handleOrderChange,
    orderBy,
    activeItem,
    activeItems,
    setActiveItem,
    setActiveItems,
    handleSelectRow,
    resetActiveItems,
    quickFilter,
    handleQuickFilterChange,
  } = useTable({
    tableId: 'fundingWires',
    filtersDefaults,
    sortDefault: {
      field: 'sentDate',
      direction: 'DESC',
    },
    quickFilterDefault: isParticipant ? 'Last 30 Days' : 'Today',
  })

  useEffect(() => {
    listClients()
  }, [listClients])

  const { clientsData } = useMemo(
    () => ({
      clientsData: clients?.data?.data,
    }),
    [clients],
  )

  const fetchOutgoingWire = useCallback(
    (values) => {
      const params = {
        ...values,
        filters: {
          ...values.filters,
        },
      }
      if (params?.filters.sentDateFrom && typeof params.filters.sentDateFrom !== 'string') {
        params.filters.sentDateFrom = dateToString(params.filters.sentDateFrom)
      }
      if (params?.filters.sentDateTo && typeof params.filters.sentDateTo !== 'string') {
        params.filters.sentDateTo = dateToString(params.filters.sentDateTo)
      }
      listOutgoingWire(params)
    },
    [listOutgoingWire],
  )

  const debounceListOutgoingWire = useMemo(
    () => debounceEventHandler(fetchOutgoingWire, 500),
    [fetchOutgoingWire],
  )

  useEffect(() => {
    debounceListOutgoingWire({
      page: 0,
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [orderBy, filters, debounceListOutgoingWire])

  const loadMore = useCallback(() => {
    fetchOutgoingWire({
      loadMore: true,
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      page: Math.ceil(itemsCount / PER_PAGE),
    })
  }, [orderBy, filters, fetchOutgoingWire, itemsCount])

  const refetchListOutgoingWire = useCallback(() => {
    fetchOutgoingWire({
      page: 0,
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [filters, orderBy, fetchOutgoingWire])

  const handleSubmitWire = useCallback((wire: IFundingWire) => {
    selectWires([wire])
  }, [])

  const handleSubmitWires = useCallback(
    (values: { wires: IFundingWire[] }) => {
      const wires = values.wires
        .filter((_, index) => activeItems.includes(index))
        .filter(({ isSent }) => !isSent)

      selectWires(wires)
    },
    [activeItems],
  )

  const handleSentCancel = useCallback(() => {
    setIsConfirmModalShown(false)
  }, [])

  const handleSentConfirm = useCallback(async () => {
    setWiresSending(selectedWires.map((item) => item.id))
    await sentOutgoingWires({
      data: selectedWires.map((item) => ({
        id: item.id,
        sentDate: databaseDateFormat(item.sentDate),
        fundings: item.fundings,
      })),
    })
    setWiresSending([])
    setIsConfirmModalShown(false)
    selectWires([])
    setActiveItems([])
    refetchListOutgoingWire()
  }, [sentOutgoingWires, selectedWires, refetchListOutgoingWire, setActiveItems])

  const filtersConfig = useMemo(
    () =>
      initialFilter.map((item) => {
        return {
          ...item,
          options:
            item.field === 'clientName'
              ? clientsData?.map(({ clientName }) => ({
                  value: clientName,
                  label: clientName,
                }))
              : item.options,
        }
      }),
    [clientsData, initialFilter],
  )

  const initialValues = useMemo(
    () => ({
      wires: fundingWires?.map((item) => ({
        ...item,
        amount: item.amount,
        sentDate: moment(item.sentDate),
        fundings:
          item.fundings?.length > 0
            ? item.fundings
            : Array.from(Array(item.wireCount).keys()).map((_, index) => ({
                description: index === 0 ? 'Funding' : '',
                amount: index === 0 ? item.amount : 0,
              })),
      })),
    }),
    [fundingWires],
  )

  useEffect(() => {
    if (selectedWires.length > 0) {
      let isAmountsValid = true
      selectedWires.forEach(({ fundings }: { fundings: IFundingWire[] }) =>
        fundings.forEach((funding, index) => {
          if (index !== 0 && !+funding.amount) {
            isAmountsValid = false
          }
        }),
      )
      if (!isAmountsValid) {
        putNotification({
          code: 'FORMAT_ERROR',
          message: 'Amount is not allowed to be $0 for an additional wire',
          type: 'error',
        })
        return
      }
      if (selectedWires.some((wire) => !moment(wire.sentDate).isSame(today, 'day'))) {
        setIsConfirmModalShown(true)
      } else {
        handleSentConfirm()
      }
    }
  }, [selectedWires, handleSentConfirm, putNotification])

  const unsentWires = useMemo(
    () =>
      initialValues.wires
        ? initialValues?.wires
            .filter((_, index) => activeItems.includes(index))
            .filter(({ isSent }) => !isSent).length
        : 0,
    [activeItems, initialValues],
  )

  const totalRow = useSummaryRow(initialValues?.wires, activeItems, {
    sumFields: ['amount', 'currentLoanAmount', 'newLoanAmount', 'excessAvailability'],
  })
  const totalAmount = useMemo(() => {
    return `$${formatPrice(totalRow?.amount)}`
  }, [totalRow])

  const handleCancelEditBBC = useCallback(() => {
    setBBCLinkBeingEdited({
      id: '',
      link: '',
    })
  }, [])

  const handleSaveBBCLink = useCallback(
    async (data) => {
      setIsBBCLinkUpdating(true)
      await updateBBC(bbcLinkBeingEdited.id, data)
      setIsBBCLinkUpdating(false)
      setBBCLinkBeingEdited({
        id: '',
        link: '',
      })
      refetchListOutgoingWire()
    },
    [bbcLinkBeingEdited, updateBBC, refetchListOutgoingWire],
  )

  const handleEditBBC = useCallback((id, link) => {
    setBBCLinkBeingEdited({
      id,
      link,
    })
  }, [])

  const handleDeleteFundingWire = useCallback((id) => {
    setDeleteWireActivityId(id)
  }, [])

  const deleteFundingWire = useCallback(async () => {
    setWireDeleteIsLoading(true)
    await updateActivity(deleteWireActivityId, {
      status: ActivityStatus.Review,
    })
    setDeleteWireActivityId('')
    setWireDeleteIsLoading(false)
    refetchListOutgoingWire()
  }, [updateActivity, refetchListOutgoingWire, deleteWireActivityId])

  return (
    <Box py={1}>
      <Grid container spacing={3} ref={wrapperRef}>
        <Grid item xs={12}>
          <Card noHeaderMargin noPadding withBorder={false}>
            <TableContainer
              className={cn({
                [styles.outgoingWireTable]: !isParticipant,
                [styles.outgoingWireTableParticipants]: isParticipant,
              })}
              onActiveRowsChange={setActiveItems}
              onActiveRowChange={setActiveItem}
            >
              <Form
                onSubmit={handleSubmitWires}
                initialValues={initialValues}
                mutators={mutators}
                render={({
                  form,
                  handleSubmit: handleSubmitWires,
                  values,
                }: {
                  form: any
                  handleSubmit: any
                  values: any
                }) => (
                  <>
                    <Form
                      validate={filtersValidate}
                      onSubmit={handleFiltersChange}
                      initialValues={filters}
                      mutators={{
                        setFieldData: ([field, value], state, { changeValue }) => {
                          changeValue(state, field, () => value)
                        },
                      }}
                      render={({
                        values,
                        handleSubmit: handleSubmitFilters,
                        form: { mutators: filterMutators },
                      }) => (
                        <FilterContainer
                          filters={filtersConfig as IFilter[]}
                          handleSubmit={handleSubmitFilters}
                          mutators={filterMutators}
                          values={values}
                          appliedFilters={filters}
                          appliedQuickFilter={quickFilter}
                          handleAppliedQuickFilterChange={handleQuickFilterChange}
                        />
                      )}
                    />
                    <Table>
                      <TableHead>
                        <TableFiltersRow
                          filters={filtersConfig}
                          orderBy={orderBy}
                          handleOrderChange={handleOrderChange}
                        />
                      </TableHead>

                      <TableBody id="scrollableTable">
                        {!fundingWires || isLoading ? (
                          <TableLoader columnsCount={filtersConfig?.length} />
                        ) : (
                          <InfiniteScroll
                            dataLength={itemsCount}
                            next={loadMore}
                            hasMore={itemsCount < totalItems}
                            loader={
                              <TableLoader columnsCount={filtersConfig?.length} rowsCount={1} />
                            }
                            scrollableTarget="scrollableTable"
                          >
                            <FieldArray name="wires">
                              {({ fields }) => (
                                <>
                                  {fields.map((name, index) => {
                                    const item = fundingWires[index]
                                    if (!item) {
                                      return null
                                    }

                                    const isDeleteable =
                                      moment(item.sentDate).isSame(moment(), 'day') &&
                                      item.activityId

                                    const totalAmount =
                                      values.wires[index].fundings?.length > 0
                                        ? (values.wires[index].fundings as IFunding[]).reduce(
                                            (sum, { amount }) => sum + (+amount || 0),
                                            0,
                                          )
                                        : +values.wires[index].amount
                                    const fundings = values.wires[index].fundings || []
                                    const isSelected = !!selectedWires.find(
                                      ({ id }) => item.id === id,
                                    )

                                    const isSending = wiresSending.includes(item.id)

                                    const signBBCNeverSent =
                                      item.boxSignStatus === BoxSignStatus.created ||
                                      item.boxSignStatus === BoxSignStatus.converting ||
                                      !item.boxSignStatus

                                    const isUnsigned =
                                      item.boxSignStatus !== BoxSignStatus.signed &&
                                      !signBBCNeverSent

                                    const isRed =
                                      (isUnsigned || signBBCNeverSent) &&
                                      !item.clientBoxLink &&
                                      !isParticipant

                                    const tootltipMessage = !isRed
                                      ? null
                                      : isUnsigned
                                      ? `${item.clientName} has not signed the BBC`
                                      : signBBCNeverSent
                                      ? 'BBC was manually sent, please upload signed PDF'
                                      : null

                                    return (
                                      <React.Fragment key={item.id}>
                                        <TableRow
                                          data-index={index}
                                          className={cn('activableRow', {
                                            activeRow: activeItems.includes(index),
                                            currentActiveRow: activeItem === index,
                                          })}
                                          onClick={(event) => handleSelectRow(event, index)}
                                        >
                                          <TableCell className={genericSs.tableTextLeft}>
                                            {!isParticipant && (
                                              <LinkButton
                                                component={Link}
                                                to={generatePath(ROUTES.CLIENT_PAGE, {
                                                  id: item.clientId,
                                                })}
                                              >
                                                {item.clientName}
                                              </LinkButton>
                                            )}
                                            {isParticipant && (
                                              <LinkButton
                                                component={Link}
                                                to={generatePath(ROUTES.BBC, {
                                                  id: item.id,
                                                })}
                                              >
                                                {`${item.clientName} ${formatDate(
                                                  item.recordDate,
                                                )}`}
                                              </LinkButton>
                                            )}
                                          </TableCell>
                                          {isParticipant && (
                                            <TableCell className={genericSs.tableTextRight}>
                                              {formatter.format(item.currentLoanAmount)}
                                            </TableCell>
                                          )}
                                          <TableCell
                                            className={cn(
                                              genericSs.tableTextRight,
                                              styles.wireAmountCell,
                                            )}
                                          >
                                            {item.isSent || fundings.length > 1 || isParticipant ? (
                                              <>
                                                <span
                                                  className={cn(
                                                    genericSs.pricePrefix,
                                                    styles.priceAdornment,
                                                  )}
                                                >
                                                  $
                                                </span>
                                                {formatPrice(
                                                  item.isSent ? item.amount : totalAmount,
                                                )}
                                              </>
                                            ) : (
                                              <CurrencyField
                                                name={`${name}.fundings[0].amount`}
                                                fullWidth={false}
                                              />
                                            )}
                                          </TableCell>
                                          {isParticipant && (
                                            <TableCell className={genericSs.tableTextRight}>
                                              {formatter.format(item.newLoanAmount)}
                                            </TableCell>
                                          )}
                                          {isParticipant && (
                                            <TableCell className={genericSs.tableTextRight}>
                                              {formatter.format(item.excessAvailability)}
                                            </TableCell>
                                          )}
                                          <TableCell className={genericSs.tableTextRight}>
                                            {item.isSent ? (
                                              formatDate(item.sentDate)
                                            ) : isAdminRightsRole ? (
                                              <KeyboardDatePicker
                                                name={`${name}.sentDate`}
                                                inputFormat={DATE_FORMAT}
                                                maxDate={today}
                                                minDate={moment(
                                                  loanBalanceStartDate || undefined,
                                                ).toDate()}
                                              />
                                            ) : (
                                              'n/a'
                                            )}
                                          </TableCell>
                                          {!isParticipant && (
                                            <TableCell className={genericSs.tableTextRight}>
                                              <div className={styles.certificateContainer}>
                                                {item.boxLink && (
                                                  <BoxLink
                                                    className={cn(styles.boxLink, {
                                                      [styles.red]: isRed,
                                                    })}
                                                    link={item.boxLink}
                                                    title={tootltipMessage}
                                                    size="small"
                                                  />
                                                )}

                                                {(moment(item.sentDate).isSame(moment(), 'day') ||
                                                  !item.boxLink) && (
                                                  <EditIcon
                                                    className={styles.editIcon}
                                                    onClick={() =>
                                                      handleEditBBC(item.id, item.boxLink)
                                                    }
                                                  />
                                                )}
                                              </div>
                                            </TableCell>
                                          )}
                                          {!isParticipant && (
                                            <TableCell className={genericSs.tableTextRight}>
                                              {!item.isSent && (
                                                <AddIcon
                                                  disabled={isSending}
                                                  onClick={() =>
                                                    !isSending &&
                                                    form.mutators.push(`${name}.fundings`, {
                                                      amount: 0,
                                                    })
                                                  }
                                                  title="Add Wire"
                                                  size="small"
                                                />
                                              )}
                                              {item.isSent ? (
                                                <>
                                                  <div
                                                    className={styles.outgoingWireTableSentButton}
                                                  >
                                                    Sent
                                                  </div>
                                                  {isDeleteable && (
                                                    <DeleteMenu
                                                      handleDeleteWire={() =>
                                                        handleDeleteFundingWire(item.activityId)
                                                      }
                                                    />
                                                  )}
                                                </>
                                              ) : isAdminRightsRole ? (
                                                <>
                                                  {unsentWires <= 1 && (
                                                    <SendIcon
                                                      disabled={isSending}
                                                      isLoading={isSending}
                                                      onClick={() =>
                                                        handleSubmitWire(values.wires[index])
                                                      }
                                                      size="small"
                                                    />
                                                  )}
                                                </>
                                              ) : (
                                                <span
                                                  className={styles.outgoingWireTableSentButton}
                                                >
                                                  Not Sent
                                                </span>
                                              )}
                                            </TableCell>
                                          )}
                                        </TableRow>
                                        {fundings.length > 1 && (
                                          <FieldArray name={`${name}.fundings`}>
                                            {({ fields }) => (
                                              <>
                                                {fields.map((fundingName, fundingIndex) => {
                                                  const funding =
                                                    values.wires?.[index]?.fundings?.[fundingIndex]
                                                  if (!funding) {
                                                    return null
                                                  }

                                                  return (
                                                    <TableRow
                                                      key={funding.id || fundingIndex}
                                                      className={cn({
                                                        [styles.childRow]: !isParticipant,
                                                        [styles.childRowParticipant]: isParticipant,
                                                        [styles.lastChildRow]:
                                                          values.wires?.[index]?.fundings?.length -
                                                            1 ===
                                                          fundingIndex,
                                                      })}
                                                    >
                                                      <TableCell>
                                                        <div className={styles.childBorders} />
                                                      </TableCell>
                                                      <TableCell
                                                        className={genericSs.tableTextLeft}
                                                      >
                                                        {item.isSent || fundingIndex === 0 ? (
                                                          funding.description
                                                        ) : (
                                                          <TextField
                                                            name={`${fundingName}.description`}
                                                            fullWidth={false}
                                                          />
                                                        )}
                                                      </TableCell>

                                                      <TableCell
                                                        className={genericSs.tableTextRight}
                                                      >
                                                        {item.isSent ? (
                                                          <span>
                                                            <span
                                                              className={cn(
                                                                genericSs.pricePrefix,
                                                                styles.priceAdornment,
                                                              )}
                                                            >
                                                              $
                                                            </span>
                                                            {formatPrice(funding.amount)}
                                                          </span>
                                                        ) : (
                                                          <CurrencyField
                                                            name={`${fundingName}.amount`}
                                                            fullWidth={false}
                                                            error={
                                                              isSelected &&
                                                              fundingIndex !== 0 &&
                                                              !+funding.amount
                                                            }
                                                          />
                                                        )}
                                                      </TableCell>
                                                      {isParticipant && (
                                                        <>
                                                          <TableCell />
                                                          <TableCell />
                                                          <TableCell />
                                                        </>
                                                      )}
                                                      <TableCell
                                                        className={genericSs.tableTextRight}
                                                      >
                                                        {item.isSent && (
                                                          <Tooltip
                                                            title={formatDateTime(
                                                              funding.createdAt,
                                                            )}
                                                          >
                                                            <span>
                                                              {formatDate(funding.sentDate)}
                                                            </span>
                                                          </Tooltip>
                                                        )}
                                                      </TableCell>
                                                      {!isParticipant && (
                                                        <>
                                                          <TableCell />

                                                          <TableCell
                                                            className={genericSs.tableTextRight}
                                                          >
                                                            {!item.isSent && fundingIndex !== 0 && (
                                                              <DeleteIcon
                                                                onClick={() =>
                                                                  !isSending &&
                                                                  fields.remove(fundingIndex)
                                                                }
                                                                size="small"
                                                                disabled={isSending}
                                                              />
                                                            )}
                                                          </TableCell>
                                                        </>
                                                      )}
                                                    </TableRow>
                                                  )
                                                })}
                                              </>
                                            )}
                                          </FieldArray>
                                        )}
                                      </React.Fragment>
                                    )
                                  })}
                                </>
                              )}
                            </FieldArray>
                          </InfiniteScroll>
                        )}
                        {isParticipant && (
                          <MultiselectRow activeItems={activeItems}>
                            <TableCell className={genericSs.tableTextRight}>
                              {formatter.format(totalRow?.currentLoanAmount)}
                            </TableCell>
                            <TableCell
                              className={cn(genericSs.tableTextRight, styles.wireAmountCell)}
                            >
                              {formatter.format(totalRow?.amount)}
                            </TableCell>
                            <TableCell
                              className={cn(genericSs.tableTextRight, styles.wireAmountCell)}
                            >
                              {formatter.format(totalRow?.newLoanAmount)}
                            </TableCell>
                            <TableCell
                              className={cn(genericSs.tableTextRight, styles.wireAmountCell)}
                            >
                              {formatter.format(totalRow?.excessAvailability)}
                            </TableCell>
                            <TableCell />
                          </MultiselectRow>
                        )}
                      </TableBody>
                      {!isParticipant && (
                        <MultiSelectToolbar
                          activeItems={activeItems}
                          totalSum={totalAmount}
                          resetActiveItems={resetActiveItems}
                        >
                          {unsentWires > 1 && (
                            <SendIcon
                              disabled={wiresSending.length > 0}
                              isLoading={wiresSending.length > 0}
                              onClick={handleSubmitWires}
                            />
                          )}
                        </MultiSelectToolbar>
                      )}
                    </Table>
                    {totalItems > 0 && (
                      <div className={styles.itemsCount}>
                        {itemsCount} / {totalItems}
                      </div>
                    )}
                  </>
                )}
              />
            </TableContainer>
          </Card>
        </Grid>
      </Grid>

      {isConfirmModalShown && (
        <WarningModal
          warningMessage="Are you sure you want to send theses wires? Some are not dated for today."
          onConfirm={handleSentConfirm}
          onCancel={handleSentCancel}
          confirmText="Send"
          cancelText="Cancel"
          disabled={isLoading || wiresSending.length > 0}
          isLoading={wiresSending.length > 0}
        />
      )}
      {bbcLinkBeingEdited.id && (
        <Modal title="Add BBC Link" open={!!bbcLinkBeingEdited} onCancel={handleCancelEditBBC}>
          <Form
            onSubmit={handleSaveBBCLink}
            initialValues={{ boxLink: bbcLinkBeingEdited.link }}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  name="boxLink"
                  label="Link"
                  fullWidth
                  placeholder="https://www.box.com/..."
                />
                <Button
                  style={{ marginTop: 20 }}
                  fullWidth
                  small={false}
                  type="submit"
                  isLoading={isBBCLinkUpdating}
                >
                  Update
                </Button>
              </form>
            )}
          />
        </Modal>
      )}
      {deleteWireActivityId && (
        <WarningModal
          warningMessage="Deleting this wire will move the borrowing base back to in review"
          onConfirm={deleteFundingWire}
          onCancel={() => setDeleteWireActivityId('')}
          confirmText="Delete"
          cancelText="Cancel"
          disabled={wireDeleteIsLoading}
          isLoading={wireDeleteIsLoading}
        />
      )}
    </Box>
  )
}

export default FundingWiresTable
