import React, { useMemo, useCallback, useEffect } from 'react'
import cn from 'classnames'
import { useDropzone, DropzoneProps } from 'react-dropzone'
import { Grid } from '@mui/material'

import styles from './UploadFile.module.scss'

import { IFile } from '@common/interfaces/box'
import Modal from '../Modal'
import UploadFileManagement from '../UploadFileManagement/'
import { ACCEPTED_FILES_MAPPING, DEFAULT_MAX_FILE_SIZE } from '../../../constants/common'
import LinearProgressBar from '../../../components/Common/LinearProgressBar'
import genericSs from '@styles/generic.module.scss'

type UploadFileProps = {
  size?: 'sm' | 'lg' | 'xl'
  isLoading?: boolean
  loaderPos?: number
  dropzoneText?: string
  title?: string
  frequency?: string
  files?: File[] | IFile[]
  onDelete?: (index: number[]) => void
  acceptedFileTypes?: string[]
  isInModal?: boolean
  isModalOpen?: boolean
  handleToggleModal?: (name: string) => void
} & DropzoneProps

const UploadFile: React.FC<UploadFileProps> = React.memo(
  ({
    size,
    isLoading = false,
    loaderPos,
    dropzoneText = 'Drop files here or ',
    title,
    frequency,
    files,
    acceptedFileTypes,
    onDelete,
    isInModal = true,
    isModalOpen,
    handleToggleModal,
    disabled,
    ...props
  }) => {
    const handleModal = useCallback(() => {
      handleToggleModal && handleToggleModal(title)
    }, [title, handleToggleModal])

    const accept = useMemo(() => {
      let data: any
      if (acceptedFileTypes) {
        acceptedFileTypes.forEach((file) => {
          data = { ...data, ...ACCEPTED_FILES_MAPPING[file] }
        })
      }
      return data
    }, [acceptedFileTypes])

    const { getRootProps, getInputProps, open, isDragAccept, isDragReject } = useDropzone({
      noClick: true,
      maxSize: DEFAULT_MAX_FILE_SIZE,
      maxFiles: props?.maxFiles || 99,
      accept,
      multiple: true,
      noDragEventsBubbling: true,
      ...props,
    })
    useEffect(() => {
      if (isModalOpen) {
        if (files?.length === 0) {
          handleToggleModal(title)
        }
      }
    }, [isModalOpen, files, title, handleToggleModal])

    const handleDelete = useCallback(
      (indices: number[]) => {
        onDelete && onDelete(indices)

        return null
      },
      [onDelete],
    )

    return (
      <>
        {isInModal && (
          <div
            className={cn(styles.root, {
              [styles.rootLarge]: size === 'lg',
              [styles.rootExtraLarge]: size === 'xl',
              [styles.accepted]: isDragAccept && !isModalOpen && !disabled,
              [styles.rejected]: isDragReject && !isModalOpen,
            })}
            {...getRootProps()}
          >
            <input {...getInputProps()} />

            <Grid
              className={cn(styles.container, {
                [styles.containerLarge]: size === 'lg',
              })}
              container
              item
              xs={12}
              alignItems={'center'}
              justifyContent={title ? 'space-between' : 'center'}
            >
              {title || frequency ? (
                <Grid container item xs={6} className={styles.titleContainer}>
                  <Grid item xs={12}>
                    <span className={styles.title}>{title}</span>
                  </Grid>
                  <Grid item xs={12}>
                    <span className={styles.frequency}>{frequency}</span>
                  </Grid>
                </Grid>
              ) : null}
              <Grid container item xs={title ? 6 : 12}>
                <Grid
                  item
                  xs={12}
                  className={cn(styles.dropzoneTextContainer, {
                    [styles.dropzoneTextContainerExtraLarge]: size === 'xl',
                  })}
                >
                  {!isLoading ? (
                    files?.length > 0 ? (
                      <div>
                        <span className={styles.filesAdded}>
                          {' '}
                          {files.length} file{files.length > 1 ? 's' : ''} added
                        </span>
                        <span
                          className={cn(styles.manageLink, {
                            [styles.disabled]: disabled,
                          })}
                          onClick={handleModal}
                        >
                          Manage
                        </span>
                      </div>
                    ) : (
                      <span>
                        {dropzoneText}{' '}
                        <span
                          className={cn(styles.fileSelectButton, {
                            [genericSs.disabled]: disabled,
                          })}
                          onClick={open}
                        >
                          browse files
                        </span>{' '}
                      </span>
                    )
                  ) : (
                    <LinearProgressBar />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}

        {isInModal ? (
          isModalOpen && (
            <Modal open title={'Manage uploaded files'} onCancel={handleModal}>
              <UploadFileManagement
                files={files}
                onDelete={handleDelete}
                setIsModalOpen={handleModal}
                isDragAccept={isDragAccept}
                isDragReject={isDragReject}
                getRootProps={getRootProps}
                getInputProps={getInputProps}
                open={open}
                handleDelete={handleDelete}
                dropzoneText={dropzoneText}
                isLoading={isLoading}
                disabled={disabled}
              />
            </Modal>
          )
        ) : (
          <UploadFileManagement
            isLoading={isLoading}
            files={files}
            onDelete={handleDelete}
            setIsModalOpen={handleModal}
            isDragAccept={isDragAccept}
            isDragReject={isDragReject}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            open={open}
            handleDelete={handleDelete}
            dropzoneText={dropzoneText}
            disabled={disabled}
            size={size}
          />
        )}
      </>
    )
  },
)

UploadFile.defaultProps = {
  size: 'sm',
}

UploadFile.displayName = 'UploadFile'

export { UploadFile }
