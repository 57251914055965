import { connect } from 'react-redux'
import LoanCommitteeReportCapTablePage from './LoanCommitteeReportCapTablePage'
import { dueDiligenceInfo } from '../../redux/dueDiligence/selectors'
import { createStructuredSelector } from 'reselect'
import { listCapTableFilters } from '../../redux/capTable/actions'
const selectors = createStructuredSelector({
  dueDiligenceInfo,
})
const actions = {
  listCapTableFilters,
}

export default connect(selectors, actions)(LoanCommitteeReportCapTablePage)
