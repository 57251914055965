import { useMemo } from 'react'
import { ILoadingData } from 'src/redux/types'

export const useLoadingData = <T>(data: ILoadingData<T>) => {
  return useMemo(
    () => ({
      data: data?.data,
      isLoading: data?.isLoading,
      isSaving: data?.isSaving,
      isSaved: data?.isSaved,
    }),
    [data],
  )
}
