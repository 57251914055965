import { ITreasuryData, ITreasuryDetails } from '../../../../common/interfaces/treasury'
import { combineReducers } from 'redux'
import * as actions from './actions'
import { ILoadingData } from '../types'
import { createLoadingDataReducer } from '../../helpers/redux'

export interface ITreasuryState {
  treasuryData: ILoadingData<ITreasuryData>
  fundingWires: ILoadingData<{ data: ITreasuryDetails[] }>
  otherWires: ILoadingData<{ data: ITreasuryDetails[] }>
  participationWires: ILoadingData<{ data: ITreasuryDetails[] }>
  passthroughWires: ILoadingData<{ data: ITreasuryDetails[] }>
}

export default combineReducers<ITreasuryState>({
  treasuryData: createLoadingDataReducer<ITreasuryData>(
    [actions.LIST_TREASURY_REQUEST],
    [actions.UPDATE_TREASURY_REQUEST],
  ),
  fundingWires: createLoadingDataReducer<{ data: ITreasuryDetails[] }>(
    [actions.LIST_TREASURY_FUNDING_WIRES_REQUEST],
    [actions.UPDATE_TREASURY_REQUEST],
  ),
  otherWires: createLoadingDataReducer<{ data: ITreasuryDetails[] }>(
    [actions.LIST_TREASURY_OTHER_WIRES_REQUEST],
    [actions.UPDATE_TREASURY_REQUEST],
  ),
  participationWires: createLoadingDataReducer<{ data: ITreasuryDetails[] }>(
    [actions.LIST_TREASURY_PARTICIPATION_WIRES_REQUEST],
    [actions.UPDATE_TREASURY_REQUEST],
  ),
  passthroughWires: createLoadingDataReducer<{ data: ITreasuryDetails[] }>(
    [actions.LIST_TREASURY_PASSTHROUGH_WIRES_REQUEST],
    [actions.UPDATE_TREASURY_REQUEST],
  ),
})
