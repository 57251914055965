import React, { useCallback, useMemo, useEffect } from 'react'
import { useParams } from 'react-router'
import { Form } from 'react-final-form'
import cn from 'classnames'
import InfiniteScroll from 'react-infinite-scroll-component'
import styles from './CapTableMappingTable.module.scss'
import genericSs from '@styles/generic.module.scss'
import { createFilterOptions } from '@mui/base'
import Box from '@mui/material/Box'
import Button from '../Common/Button'
import TaggingField from '../Common/TaggingField'
import Chip from '@mui/material/Chip'
import Tooltip from '@mui/material/Tooltip'
import CreatableSelectField from '../Common/CreatableSelectField'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableContainer from '../Common/TableContainer'
import TableBody from '../Common/TableBody'
import { debounceEventHandler, formatPercent, voidHandler } from '../../helpers/helpers'
import useTable from '../../hooks/useTable'
import arrayMutators from 'final-form-arrays'
import setFieldData from 'final-form-set-field-data'
import TableFiltersRow from '../Common/TableFiltersRow'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import {
  CAP_TABLE_MAPPING_FILTERS_CONFIG,
  FULLY_DILUTED_OWNERSHIP_QUERY,
  PER_PAGE,
} from '@common/constants/filters'
import FilterContainer from '../Filters/FilterContainer'
import TableLoader from '../Common/TableLoader'
import { ILoadingData } from '../../redux/types'
import { ICapTableMapping, ICapTableMappingData } from '@common/interfaces/capTable'
import { EntityType, IOptionType } from '@common/interfaces/entityInfo'
import SaveState from '../Common/SaveState'
import { ReportingFlow } from '@common/interfaces/bbc'
import { FieldArray } from 'react-final-form-arrays'
import MultiSelectToolbar from '../MultiSelectToolbar'
const filtersValidate = buildFiltersValidateSchema(CAP_TABLE_MAPPING_FILTERS_CONFIG)
const filtersDefaults = buildFiltersDefaults(CAP_TABLE_MAPPING_FILTERS_CONFIG)
const mutators = {
  ...arrayMutators,
  setFieldData,
}
interface IProps {
  listMapping: (params: object) => void
  capTableMapping: ILoadingData<ICapTableMappingData>
  clientName: string
  listEntityInfo: (data: object) => Promise<{ data: any }>
  addEntityInfo: (data: object) => Promise<any>
  updateCapTableDetails: (data: object) => Promise<any>
  listBoardSeats: (id: string, params: object) => Promise<{ data: any }>
  selectedDate?: string
  reportingFlow: ReportingFlow
  refreshCapTable: () => void
}

interface ICapTableMappingRowProps {
  item: ICapTableMapping
  index: number
  activeItems: number[]
  activeItem: number | null
  handleSelectRow: (event: React.MouseEvent, index: number) => void
  addEntityInfo: (data: object) => Promise<any>
  clientName: string
  loadMappings: (inputValue: string) => Promise<any>
  handlePredictionSelect: (index: number) => Promise<void>
  listBoardSeats: (id: string, params: object) => Promise<{ data: any }>
  updateCapTableDetails: (data: object) => Promise<any>
}

const CapTableMappingRow = React.memo(
  ({
    item,
    index,
    activeItems,
    activeItem,
    handleSelectRow,
    addEntityInfo,
    clientName,
    loadMappings,
    handlePredictionSelect,
    listBoardSeats,
    updateCapTableDetails,
  }: ICapTableMappingRowProps) => {
    const filter = createFilterOptions<IOptionType>()
    const { id } = useParams<{ id: string }>()

    const loadBoardSeats = useCallback(
      async (inputValue: string) => {
        const res = await listBoardSeats(id, {
          name: inputValue,
          entityName: item?.investor,
          clientName,
        })

        return res.data.map((seat: any) => ({
          value: seat.boardMember,
          label: seat.boardMember,
        }))
      },
      [listBoardSeats, id, item?.investor, clientName],
    )

    const updateInvestor = useCallback(
      async (event, newValue: any) => {
        if (newValue) {
          await addEntityInfo({ name: newValue.value, type: EntityType.Investor, clientName })
        }

        await updateCapTableDetails({
          clientName,
          descriptionData: [
            {
              description: item.description,
              id: item.id,
              investor: newValue?.value || null,
            },
          ],
          isInvestor: true,
        })
      },
      [addEntityInfo, updateCapTableDetails, item, clientName],
    )

    const updateBoardMember = useCallback(
      async (event, newValue: any, isDeleteBoardMember: boolean = false) => {
        const boardMemberValue = newValue?.[newValue?.length - 1]?.value || newValue || null

        await updateCapTableDetails({
          clientName,
          descriptionData: [
            {
              description: item.description,
              id: item.id,
              investor: item.investor,
              boardMember: boardMemberValue,
              isDeleteBoardMember,
            },
          ],
          isBoardMember: true,
        })
      },
      [updateCapTableDetails, item, clientName],
    )

    const handleDeleteBoardSeat = useCallback(
      async (event, boardMember) => {
        event.stopPropagation()
        await updateBoardMember(event, boardMember, true)
      },
      [updateBoardMember],
    )

    return (
      <TableRow
        data-index={index}
        className={cn('activableRow', {
          activeRow: activeItems.includes(index),
          currentActiveRow: activeItem === index,
        })}
        onClick={(event) => handleSelectRow(event, index)}
      >
        <TableCell className={genericSs.tableTextLeft}>{item.description}</TableCell>
        <TableCell className={genericSs.tableTextRight}>
          {formatPercent(item.fullyDilutedOwnership)}
        </TableCell>
        <TableCell className={genericSs.tableTextLeft}>
          <CreatableSelectField
            label=""
            className="focusableInput"
            tabIndex={index}
            name={`capTableMapping[${index}].investor`}
            placeholder={`Select investor`}
            onAddValue={(mapping: string) =>
              addEntityInfo({
                name: mapping,
                type: EntityType.Investor,
                clientName,
              })
            }
            options={[]}
            isAsync
            loadOptions={loadMappings}
            onChangeCustom={(event, newValue) => updateInvestor(event, newValue)}
            getOptionValue={(option) => option.value}
            value={
              item.investor
                ? {
                    value: item.investor,
                    label: item.investor,
                  }
                : null
            }
            clearOnBlur
            inTable
          />
        </TableCell>
        <TableCell className={genericSs.tableTextLeft}>
          {item.investorPrediction && (
            <div
              className={cn(styles.percentMatchWithPrediction, styles.percentMatchHigh, {
                [styles.newValue]: !item.predictionExists,
                [styles.disablePrediction]: item?.investor,
                [styles.percentMatchHighActive]: activeItems.includes(index),
              })}
              onClick={() => handlePredictionSelect(index)}
            >
              {!item?.investor ? (
                <Tooltip
                  title="Create new entity"
                  placement="top"
                  disableHoverListener={item.predictionExists}
                  disableTouchListener
                >
                  <span
                    className={cn({
                      [styles.newValue]: !item.predictionExists,
                    })}
                  >
                    {!item.predictionExists && '+'} {item.investorPrediction}
                  </span>
                </Tooltip>
              ) : (
                item.investorPrediction
              )}
            </div>
          )}
        </TableCell>
        <TableCell className={cn(genericSs.tableTextLeft)}>
          <TaggingField
            name={`capTableMapping[${index}].boardMembers`}
            className={styles.taggingField}
            limtDrowdown
            placeholder={
              item?.investor
                ? item?.boardMembers?.length
                  ? ''
                  : 'Select board members'
                : 'Select investor first'
            }
            disabled={item.investor ? false : true}
            inTable
            renderTags={(tagValue, getTagProps) => {
              return tagValue.map((option, tagIndex) => (
                <Tooltip key={option.value} title={option.value} placement={'top'}>
                  <Chip
                    {...getTagProps({ tagIndex })}
                    className={cn(styles.chip)}
                    label={option.value}
                    size="small"
                    onDelete={(event) => handleDeleteBoardSeat(event, option.value)}
                  />
                </Tooltip>
              ))
            }}
            limitTags={2}
            onChange={(event, newValue) => {
              updateBoardMember(event, newValue)
            }}
            isFreeSolo={true}
            // @ts-ignore
            value={
              item.boardMembers?.map((boardMember) => ({
                value: boardMember,
                label: boardMember,
              })) || []
            }
            filterOptions={(currentOptions, params) => {
              const filtered = filter(currentOptions, params)

              if (
                params.inputValue !== '' &&
                filtered.findIndex((option) => option.value === params.inputValue) === -1
              ) {
                filtered.push({
                  value: params.inputValue,
                  label: `Add "${params.inputValue}"`,
                  isNew: true,
                })
              }
              return filtered
            }}
            isAsync
            loadOptions={loadBoardSeats}
            minPredictionLength={0}
          />
        </TableCell>
      </TableRow>
    )
  },
)

const CapTableMappingTable = ({
  capTableMapping,
  listMapping,
  clientName,
  addEntityInfo,
  listEntityInfo,
  updateCapTableDetails,
  listBoardSeats,
  selectedDate,
  reportingFlow,
  refreshCapTable,
}: IProps) => {
  const { id } = useParams<{ id: string }>()

  const {
    isLoading,
    isSaving,
    isSaved,
    data: mappingData,
    totals,
  } = useMemo(() => {
    return {
      isLoading: capTableMapping?.isLoading,
      isSaving: capTableMapping?.isSaving,
      isSaved: capTableMapping?.isSaved,
      data: capTableMapping?.data?.data,
      totals: capTableMapping?.data?.totals,
    }
  }, [capTableMapping])

  const {
    filters,
    handleFiltersChange,
    handleOrderChange,
    orderBy,
    activeItem,
    activeItems,
    setActiveItem,
    setActiveItems,
    handleSelectRow,
    resetActiveItems,
  } = useTable({
    tableId: 'capTable',
    filtersDefaults: filtersDefaults,
    sortDefault: {
      field: FULLY_DILUTED_OWNERSHIP_QUERY,
      direction: 'DESC',
    },
  })

  useEffect(() => {
    const isMappingComplete = mappingData?.every(
      (item) =>
        item.fullyDilutedOwnership <= 0.05 ||
        (item.fullyDilutedOwnership > 0.05 && item.investor !== null),
    )

    if (isMappingComplete) {
      refreshCapTable()
    }
  }, [mappingData, refreshCapTable])

  const debounceListCapTableMapping = useMemo(
    () =>
      debounceEventHandler(
        (data: any) =>
          listMapping({
            ...data,
            recordDate: selectedDate,
            reportingFlowId: id,
          }),
        500,
      ),
    [id, listMapping, selectedDate],
  )

  const loadMore = useCallback(() => {
    debounceListCapTableMapping({
      loadMore: true,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
      page: Math.ceil(mappingData?.length / PER_PAGE),
    })
  }, [orderBy, filters, debounceListCapTableMapping, mappingData])

  useEffect(() => {
    selectedDate &&
      listMapping({
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
        filters,
        recordDate: selectedDate,
        reportingFlow,
        reportingFlowId: id,
      })
  }, [id, listMapping, orderBy, filters, selectedDate, reportingFlow])

  const handleAddMapping = useCallback(
    async (mapping: string | string[]) => {
      await addEntityInfo({ name: mapping, type: EntityType.Investor, clientName })
    },
    [addEntityInfo, clientName],
  )

  const handlePredictionSelect = useCallback(
    async (itemIndex?: number) => {
      const itemsToUpdate = []
      const mappingsToAdd = []
      if (itemIndex && mappingData?.[itemIndex].investor) {
        return
      }
      if (activeItems.length > 1) {
        activeItems.forEach((index) => {
          mappingsToAdd.push(mappingData[index].investorPrediction)
          itemsToUpdate.push({
            description: mappingData[index].description,
            id: mappingData[index].id,
            investor: mappingData[index].investorPrediction,
          })
        })
      } else {
        mappingsToAdd.push(mappingData[itemIndex].investorPrediction)
        itemsToUpdate.push({
          description: mappingData[itemIndex].description,
          id: mappingData[itemIndex].id,
          investor: mappingData[itemIndex].investorPrediction,
        })
      }
      await handleAddMapping(mappingsToAdd)
      await updateCapTableDetails({
        clientName,
        descriptionData: itemsToUpdate,
        isInvestor: true,
      })
    },
    [handleAddMapping, updateCapTableDetails, mappingData, clientName, activeItems],
  )

  const handlePredictionSelectButton = useCallback(
    async (event) => {
      await handlePredictionSelect()
      setActiveItems([])
    },
    [handlePredictionSelect, setActiveItems],
  )

  const loadMappings = useCallback(
    async (inputValue: string) => {
      const res = await listEntityInfo({
        name: inputValue,
      })
      return res.data.map((mapping: any) => ({
        id: mapping.id,
        value: mapping.name,
        label: mapping.name,
      }))
    },
    [listEntityInfo],
  )

  const initialValues = useMemo(
    () => ({
      capTableMapping: mappingData?.map((item) => ({
        id: item.id,
        description: item.description,
        investor: item.investor,
        boardMembers: item.boardMembers,
      })),
    }),
    [mappingData],
  )

  const totalAmount = useMemo(() => {
    const total = activeItems?.reduce(
      (acc, item) => acc + mappingData?.[item]?.fullyDilutedOwnership,
      0,
    )
    return formatPercent(total)
  }, [activeItems, mappingData])

  return (
    <Form
      onSubmit={voidHandler}
      initialValues={initialValues}
      mutators={mutators}
      render={({ form }) => (
        <TableContainer
          className={styles.table}
          onActiveRowsChange={setActiveItems}
          onActiveRowChange={setActiveItem}
          hasFooter
        >
          <Form
            validate={filtersValidate}
            onSubmit={handleFiltersChange}
            initialValues={filters}
            mutators={{
              setFieldData: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value)
              },
            }}
            render={({ values, handleSubmit, form: { mutators } }) => (
              <FilterContainer
                filters={CAP_TABLE_MAPPING_FILTERS_CONFIG}
                handleSubmit={handleSubmit}
                mutators={mutators}
                values={values}
                appliedFilters={filters}
              />
            )}
          />

          <>
            <Table>
              <TableHead>
                <TableFiltersRow
                  filters={CAP_TABLE_MAPPING_FILTERS_CONFIG}
                  orderBy={orderBy}
                  handleOrderChange={handleOrderChange}
                />
              </TableHead>
              <TableBody id="scrollableARSummaryTable">
                {isLoading ? (
                  <TableLoader columnsCount={CAP_TABLE_MAPPING_FILTERS_CONFIG.length} />
                ) : (
                  mappingData?.length > 0 && (
                    <InfiniteScroll
                      dataLength={mappingData.length}
                      next={loadMore}
                      hasMore={mappingData.length < totals?.totalCount}
                      loader={<TableLoader columnsCount={10} rowsCount={1} />}
                      scrollableTarget="scrollableARSummaryTable"
                    >
                      <FieldArray name="capTableMapping">
                        {({ fields }) => (
                          <>
                            {fields.map((name, index) => {
                              const item = mappingData[index]
                              if (!item) {
                                return null
                              }

                              return (
                                <CapTableMappingRow
                                  key={item.id}
                                  item={item}
                                  index={index}
                                  activeItems={activeItems}
                                  activeItem={activeItem}
                                  handleSelectRow={handleSelectRow}
                                  addEntityInfo={addEntityInfo}
                                  clientName={clientName}
                                  loadMappings={loadMappings}
                                  handlePredictionSelect={handlePredictionSelect}
                                  listBoardSeats={listBoardSeats}
                                  updateCapTableDetails={updateCapTableDetails}
                                />
                              )
                            })}
                          </>
                        )}
                      </FieldArray>
                    </InfiniteScroll>
                  )
                )}
              </TableBody>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <SaveState isSaving={isSaving} isSaved={isSaved} />
              </Box>
            </Table>
            <MultiSelectToolbar
              activeItems={activeItems}
              totalSum={totalAmount}
              resetActiveItems={resetActiveItems}
              inModal={reportingFlow !== ReportingFlow.DueDiligence}
            >
              {activeItems.length > 1 && (
                <Box display="flex" alignItems="center" gap={1}>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={handlePredictionSelectButton}
                    isLoading={isSaving}
                  >
                    Prediction
                  </Button>
                </Box>
              )}
            </MultiSelectToolbar>
          </>
        </TableContainer>
      )}
    />
  )
}

export default CapTableMappingTable
