import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { generatePath } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import cn from 'classnames'

import styles from './DueDiligenceProcessDocumentsFinancialsPage.module.scss'
import genericSs from '@styles/generic.module.scss'

import { ReactComponent as HomeIcon } from '@assets/images/home-icon.svg'
import {
  DUE_DILIGENCE_DOCUMENTS_LIST_BREADCRUMBS_MAP,
  DUE_DILIGENCE_DOCUMENTS_LIST_TITLE_MAP,
  DUE_DILIGENCE_ROUTES_MAP,
  IDueDiligence,
  IDueDiligenceReportingFlowData,
} from '@common/interfaces/dueDiligence'
import { ROUTES } from '../../constants/routes'
import Breadcrumbs from '../../components/Common/Breadcrumbs'
import { useLoadInfo } from '../../hooks/useLoadInfo'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'
import Button from '../../components/Common/Button'
import Pagination from '../../components/Common/Pagination'
import {
  IOngoingReportingSummaryData,
  IOngoingReportingSummaryHeaders,
  OngoingReportingType,
  ReportingFlow,
  ReportingPeriods,
} from '@common/interfaces/bbc'
import { getReconciliation } from '../../helpers/financials'
import OngoingReportingMapping from '../../components/OngoingReportingMapping'
import OngoingReportingSummary from '../../components/OngoingReportingSummary'
import OngoingReportingReconciliation from '../../components/OngoingReportingReconciliation'
import { formatDateMonthYear } from '../../helpers/helpers'
import { ILoadingData } from '../../redux/types'
import DueDiligenceProcessDocumentsMappingLoader from '../../components/DueDiligenceProcessDocumentsMappingLoader'
import FinancialEditDatesModal from '../../components/FinancialEditDatesModal'
import { ClientInfoStatus } from '@common/interfaces/client'

interface IProps {
  isSidebarOpen: boolean
  isNotesShown: boolean
  dueDiligenceInfo: IDueDiligence
  dueDiligenceReportingFlowsData: ILoadingData<IDueDiligenceReportingFlowData>
  dataSummary: Partial<Record<OngoingReportingType, IOngoingReportingSummaryData>>
  dataSummaryHeaders: IOngoingReportingSummaryHeaders
  show: (id: string) => void
  showDocuments: (id: string, type: string, params: object) => void
  processDocuments: (id: string, type: string, data: object) => void
  updateDocument: (id: string, type: string, flowId: string, data: object) => void
}

const DueDiligenceProcessDocumentsFinancialsPage = ({
  isSidebarOpen,
  isNotesShown,
  dueDiligenceInfo,
  dueDiligenceReportingFlowsData,
  dataSummary,
  dataSummaryHeaders,
  show,
  showDocuments,
  processDocuments,
  updateDocument,
}: IProps) => {
  const {
    clientId: id,
    type: routeType,
    id: reportingId,
  } = useParams<{ clientId: string; type: string; id: string }>()
  const { pathname } = useLocation()
  const history = useHistory()
  const [isProcessing, setIsProcessing] = useState(false)
  const [refreshCounter, setRefreshCounter] = useState(1)
  const [currentReportingPeriod, setCurrentReportingPeriod] = useState<ReportingPeriods>(
    ReportingPeriods.Monthly,
  )

  useLoadInfo({ id, info: dueDiligenceInfo, show })
  useSetPageTitle(dueDiligenceInfo?.clientName || '')

  const readOnly = useMemo(
    () => dueDiligenceInfo?.clientStatus !== ClientInfoStatus.DueDiligence,
    [dueDiligenceInfo],
  )
  const type = useMemo(
    () =>
      (Object.keys(DUE_DILIGENCE_ROUTES_MAP).find(
        (key) => DUE_DILIGENCE_ROUTES_MAP[key] === routeType,
      ) as OngoingReportingType) || null,
    [routeType],
  )

  const reportingFlows = useMemo(
    () =>
      dueDiligenceReportingFlowsData?.data?.data
        .filter(({ isCompleted }) => isCompleted)
        .map((reportingFlow) => ({
          ...reportingFlow,
          isCurrent: reportingFlow.id === reportingId,
        })) || [],
    [dueDiligenceReportingFlowsData, reportingId],
  )
  const currentReportingFlow = useMemo(
    () => reportingFlows.find(({ id }) => id === reportingId),
    [reportingId, reportingFlows],
  )
  const currentReportingFlowIndex = useMemo(
    () => reportingFlows.findIndex(({ id }) => id === reportingId),
    [reportingId, reportingFlows],
  )
  const currentReportFlowTitle = useMemo(
    () =>
      currentReportingFlow
        ? `From ${formatDateMonthYear(
            currentReportingFlow.data.startDate,
          )} to ${formatDateMonthYear(currentReportingFlow.data.endDate)}`
        : '',
    [currentReportingFlow],
  )

  const handleChangeReportingFlow = useCallback(
    (event: React.ChangeEvent<unknown>, page: number) => {
      const nextReportingFlow = reportingFlows[page - 1]
      if (nextReportingFlow) {
        history.push(
          generatePath(ROUTES.DUE_DILIGENCE_PROCESS_DOCUMENTS_FINANCIALS, {
            clientId: id,
            type: routeType,
            id: nextReportingFlow.id,
          }),
        )
      }
    },
    [reportingFlows, id, routeType, history],
  )

  useEffect(() => {
    showDocuments(id, type, {})
  }, [id, type, showDocuments])

  const breadcrumbs = useMemo(() => {
    return [
      {
        link: ROUTES.DUE_DILIGENCE_HOME,
        Icon: HomeIcon,
      },
      {
        link: generatePath(ROUTES.DUE_DILIGENCE, { id }),
        title: dueDiligenceInfo?.clientName,
      },
      {
        link: generatePath(ROUTES.DUE_DILIGENCE_ANALYSIS_QUEUE, { id }),
        title: 'Process Documents',
      },
      {
        link: generatePath(ROUTES.DUE_DILIGENCE_PROCESS_DOCUMENTS_LIST, { id, type: routeType }),
        title: DUE_DILIGENCE_DOCUMENTS_LIST_BREADCRUMBS_MAP[type],
      },
      {
        link: pathname,
        title: currentReportFlowTitle,
      },
    ]
  }, [dueDiligenceInfo, id, pathname, type, routeType, currentReportFlowTitle])

  const dataReconciliation = useMemo(() => {
    return getReconciliation(type, dataSummary, dataSummaryHeaders)
  }, [type, dataSummary, dataSummaryHeaders])

  const handleGoNext = useCallback(async () => {
    if (currentReportingFlowIndex === reportingFlows.length - 1) {
      if (readOnly) {
        history.push(
          generatePath(ROUTES.DUE_DILIGENCE_ANALYSIS_QUEUE, {
            id,
          }),
        )
        return
      }
      setIsProcessing(true)
      await processDocuments(id, type, {
        flowIds: reportingFlows.map(({ id }) => id),
        isSubmit: true,
      })
      setIsProcessing(false)
    } else {
      const nextReportingFlow = reportingFlows[currentReportingFlowIndex + 1]
      history.push(
        generatePath(ROUTES.DUE_DILIGENCE_PROCESS_DOCUMENTS_FINANCIALS, {
          clientId: id,
          type: routeType,
          id: nextReportingFlow.id,
        }),
      )
    }
  }, [
    processDocuments,
    id,
    type,
    reportingFlows,
    currentReportingFlowIndex,
    history,
    routeType,
    readOnly,
  ])

  const handleChangeDates = useCallback(
    async (dates: any) => {
      await updateDocument(id, type, reportingId, dates)
      setRefreshCounter((counter) => counter + 1)
    },
    [id, type, reportingId, updateDocument],
  )
  if (!dueDiligenceInfo || !currentReportingFlow) {
    return <DueDiligenceProcessDocumentsMappingLoader />
  }

  return (
    <Grid
      container
      pt={3}
      pb={reportingFlows.length > 1 ? 10 : 3}
      pr={2}
      alignItems="flex-start"
      justifyContent="center"
      rowSpacing={2}
    >
      <Grid item container xs={12} justifyContent="space-between">
        <Grid item container xs={6} justifyContent="flex-start" alignItems="center">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </Grid>
      </Grid>

      <Grid item xs={12} alignItems="flex-start" justifyContent="flex-start" ml={3}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <h2 className={genericSs.dueDiligenceHeader}>
            <Box display="flex" alignItems="center" gap={1}>
              Map {DUE_DILIGENCE_DOCUMENTS_LIST_TITLE_MAP[type]} {currentReportFlowTitle}
              {!readOnly && (
                <FinancialEditDatesModal
                  startDate={currentReportingFlow.data.startDate}
                  endDate={currentReportingFlow.data.endDate}
                  handleSave={handleChangeDates}
                />
              )}
            </Box>
          </h2>
          <Box display="flex" alignItems="center" gap={2}>
            <Button
              isLoading={isProcessing}
              className={genericSs.dueDiligenceSubmitButton}
              variant="contained"
              onClick={handleGoNext}
            >
              {currentReportingFlowIndex === reportingFlows.length - 1 && !readOnly
                ? 'Submit'
                : 'Next'}
            </Button>
          </Box>
        </Box>
      </Grid>

      {type && (
        <Grid item xs={12}>
          <OngoingReportingMapping
            statementType={type}
            refreshCounter={refreshCounter}
            reportingFlow={ReportingFlow.DueDiligence}
            isDisabled={readOnly}
            currentReportingPeriod={currentReportingPeriod}
            setCurrentReportingPeriod={setCurrentReportingPeriod}
          />
        </Grid>
      )}
      {type && (
        <Grid item xs={12}>
          <OngoingReportingSummary
            statementType={type}
            refreshCounter={refreshCounter}
            reportingFlow={ReportingFlow.DueDiligence}
            currentReportingPeriod={currentReportingPeriod}
            setCurrentReportingPeriod={setCurrentReportingPeriod}
            defaultExpandState={false}
          />
        </Grid>
      )}
      {type && (
        <Grid item xs={12}>
          <OngoingReportingReconciliation
            headers={dataSummaryHeaders?.[type]}
            data={dataReconciliation}
          />
        </Grid>
      )}

      {reportingFlows.length > 1 && (
        <Box
          className={cn(styles.navigationBar, {
            [styles.navigationBarWithSidebar]: isSidebarOpen,

            [styles.navigationBarWithNotes]: isNotesShown,
          })}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <span className={styles.navigationBarLabel}>
            {DUE_DILIGENCE_DOCUMENTS_LIST_TITLE_MAP[type]} {currentReportFlowTitle}
          </span>
          <Box>
            <Pagination
              count={reportingFlows.length}
              page={currentReportingFlowIndex + 1}
              onChange={handleChangeReportingFlow}
              variant="outlined"
              shape="rounded"
              color="primary"
            />
          </Box>
        </Box>
      )}
    </Grid>
  )
}

export default DueDiligenceProcessDocumentsFinancialsPage
