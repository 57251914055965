import React, { useState, useCallback, useMemo, SetStateAction, Dispatch } from 'react'
import { useParams } from 'react-router'
import Grid from '@mui/material/Grid'
import styles from './OngoingReportingFiles.module.scss'
import { IFile } from '@common/interfaces/box'
import Button from '../../components/Common/Button'
import Modal from '../../components/Common/Modal'
import UploadFile from '../Common/UploadFile'
import { IOngoingReporting, FILE_TYPE_TEMPLATES } from '@common/interfaces/bbc'
import SaveState from '../Common/SaveState'
import SelectedFiles from '../SelectedFiles'
import FilesTemplates from '../FilesTemplates'

interface IProps {
  isLoading: boolean
  ongoingReporting: IOngoingReporting
  files: IFile[]
  fileSheets: { id?: string; fileId: string; sheetName: string }[]
  handleSelectFiles: (selectedDocuments: { fileId: string; sheetName: string }[]) => Promise<any>
  isDisabled?: boolean
  showOngoingReporting: (id: string) => Promise<void>
  uploadFile: (data: FormData) => Promise<void>
  deleteFile: (ids: string[]) => Promise<void>
  handleOpenModal?: () => void
  handleCloseModal?: () => void
  isOpen: boolean
  downloadTemplate: (fileType?: string, fileId?: string) => Promise<void>
  setRefreshCounter?: Dispatch<SetStateAction<number>>
  setIsFilesSaving?: Dispatch<SetStateAction<boolean>>
}
const OngoingReportingFiles = ({
  isLoading,
  ongoingReporting,
  files,
  fileSheets,
  handleSelectFiles,
  isDisabled = false,
  showOngoingReporting,
  uploadFile,
  deleteFile,
  handleOpenModal,
  handleCloseModal,
  isOpen,
  downloadTemplate,
  setRefreshCounter,
  setIsFilesSaving,
}: IProps) => {
  const { id } = useParams<{ id: string }>()

  const [isFilesLoading, setIsFilesLoading] = useState(false)
  const [isFilesUploading, setIsFilesUploading] = useState(false)
  const [isFilesSaved, setIsFilesSaved] = useState(false)
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false)
  const [filesForDelete, setFilesForDelete] = useState<IFile[]>([])

  const reportType = useMemo(() => ongoingReporting?.type?.replaceAll(' ', ''), [ongoingReporting])

  const onSelectFiles = useCallback(
    async (fileSheets: { fileId: string; sheetName: string }[]) => {
      setIsFilesSaving && setIsFilesSaving(true)
      setIsFilesLoading(true)
      setIsFilesSaved(false)

      if (isDisabled) {
        return
      }
      const result = await handleSelectFiles(fileSheets)
      !result?.error && setIsFilesSaved(true)
      setIsFilesLoading(false)
      if (result?.data?.success) {
        fileSheets.length > 0 && handleCloseModal && handleCloseModal()
      }
      setRefreshCounter && setRefreshCounter((counter) => counter + 1)
      setIsFilesSaving && setIsFilesSaving(false)
    },
    [
      isDisabled,
      handleSelectFiles,
      setIsFilesLoading,
      setIsFilesSaved,
      handleCloseModal,
      setRefreshCounter,
      setIsFilesSaving,
    ],
  )

  const handleUploadFile = useCallback(
    async (loadedFiles: File[]) => {
      if (loadedFiles?.length) {
        const formData = new FormData()

        const [file] = loadedFiles
        formData.append('files[]', file, file.name)
        formData.append('type', `${ongoingReporting.type}`)
        formData.append('ongoingReportingId', ongoingReporting.id)

        setIsFilesUploading(true)
        await uploadFile(formData)
        setIsFilesUploading(false)
        await showOngoingReporting(id)
      }
    },
    [uploadFile, showOngoingReporting, id, ongoingReporting],
  )

  const handleDeleteFile = useCallback(
    (fileId: string) => {
      setIsDeleteModalShown(true)
      setFilesForDelete(files.filter(({ id }) => id === fileId))
    },
    [files],
  )

  const handleDeleteCancel = useCallback(() => {
    setIsDeleteModalShown(false)
    setFilesForDelete([])
  }, [])

  const handleDeleteConfirm = useCallback(async () => {
    await deleteFile(filesForDelete.map((file) => file.id))
    await showOngoingReporting(id)
    setIsDeleteModalShown(false)
    setFilesForDelete([])
  }, [deleteFile, filesForDelete, showOngoingReporting, id])

  const handleClose = useCallback(() => {
    if (!isFilesLoading) {
      handleCloseModal()
    }
  }, [handleCloseModal, isFilesLoading])

  return (
    <Modal
      title={
        <Grid container justifyContent={'flex-start'} alignItems={'center'}>
          <Grid item>
            <h2 className={styles.modalTitle}>Select Files</h2>
          </Grid>
        </Grid>
      }
      open={isOpen}
      classes={{
        root: styles.modalPaper,
        header: styles.modalHeader,
      }}
      onCancel={handleClose}
    >
      <Grid container>
        <Grid container item xs={12}>
          <SelectedFiles
            files={files}
            fileSheets={fileSheets}
            handleSelectFiles={onSelectFiles}
            handleDeleteFile={handleDeleteFile}
            disabled={isDisabled}
          />

          <Grid container mt={1} item xs={12}>
            <Grid container item xs={8} justifyContent={'flex-start'}>
              <span className={styles.uploadDocumentHelper}>Want to use another document?</span>
            </Grid>
          </Grid>

          <Grid container mt={1} xs={12} lg={12} justifyContent={'center'}>
            <UploadFile
              title=""
              key={+isLoading}
              size="sm"
              onDropAccepted={handleUploadFile}
              acceptedFileTypes={['excel']}
              isInModal={false}
              isLoading={isFilesUploading}
            />
          </Grid>

          <Grid container item xs={6}>
            <FilesTemplates
              templates={(FILE_TYPE_TEMPLATES[reportType] || []).map(
                ({ value, label }: { value: string; label: string }) => ({
                  name: label,
                  type: value,
                }),
              )}
              handleDownloadTemplate={downloadTemplate}
            />
          </Grid>

          <Grid container item xs={6} justifyContent="flex-end">
            <SaveState isSaving={!isDeleteModalShown && isFilesLoading} isSaved={isFilesSaved} />
          </Grid>
        </Grid>
        {isDeleteModalShown && filesForDelete.length > 0 && (
          <Modal
            open={isDeleteModalShown}
            onCancel={handleDeleteCancel}
            title="Delete file?"
            footer={[
              <Button
                key="cancel"
                color="primary"
                variant="outlined"
                onClick={handleDeleteCancel}
                disabled={isLoading}
              >
                Cancel
              </Button>,
              <Button
                key="submit"
                color="primary"
                variant="contained"
                onClick={handleDeleteConfirm}
                isLoading={isLoading}
              >
                Delete
              </Button>,
            ]}
          >
            All related records will be deleted
          </Modal>
        )}
      </Grid>
    </Modal>
  )
}

export default OngoingReportingFiles
