import React, { MutableRefObject } from 'react'
import { generatePath, Link } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import LinkButton from '@mui/material/Link'
import Tooltip from '@mui/material/Tooltip'
import cn from 'classnames'

import styles from './BankTransactions.module.scss'
import genericSs from '@styles/generic.module.scss'

import { IFilter } from '@common/constants/filters'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TableLoader from '../Common/TableLoader'
import {
  CATEGORIES_LABELS,
  INFLOW_OUTFLOW_CATEGORIES,
  IBankTransactionRaw,
} from '@common/interfaces/bankTransactions'
import TableFiltersRow from '../Common/TableFiltersRow'
import { formatDate, formatPrice } from '../../helpers/helpers'
import { ROUTES } from '../../constants/routes'
import MultiselectRow from '../MultiselectRow'
import { usePermissions } from '../../helpers/permissionContext'
import useSummaryRow from '../../hooks/useSummaryRow'

interface IProps {
  isLoading: boolean
  filtersConfig: IFilter[]
  bankTransactionsData: IBankTransactionRaw[]
  itemsCount: number
  totalItems: number
  loadMore: () => void
  orderBy: any
  onOrderChange: (field: string) => void
  activeItem: number
  activeItems: number[]
  setActiveItem: (indexes: number) => void
  setActiveItems: (index: number[]) => void
  handleSelectRow: (event: any, index: number) => void
  wrapperRef: MutableRefObject<any>
}

const BankTransactionsTable = ({
  isLoading,
  filtersConfig,
  bankTransactionsData,
  itemsCount,
  totalItems,
  loadMore,
  orderBy,
  onOrderChange,
  activeItem,
  activeItems,
  setActiveItem,
  setActiveItems,
  handleSelectRow,
  wrapperRef,
}: IProps) => {
  const { isParticipant } = usePermissions()

  const totalRow = useSummaryRow(bankTransactionsData, activeItems, {
    sumFields: ['amount'],
  })

  return (
    <TableContainer
      id="bankTransactionsTable"
      className={styles.table}
      onActiveRowsChange={setActiveItems}
      onActiveRowChange={setActiveItem}
    >
      <Table ref={wrapperRef}>
        <TableHead>
          <TableFiltersRow
            filters={filtersConfig}
            orderBy={orderBy}
            handleOrderChange={onOrderChange}
          />
        </TableHead>
        <TableBody id="scrollableTable">
          {isLoading ? (
            <TableLoader columnsCount={filtersConfig.length} />
          ) : (
            <InfiniteScroll
              dataLength={itemsCount}
              next={loadMore}
              hasMore={itemsCount < totalItems}
              loader={<TableLoader columnsCount={filtersConfig.length} />}
              scrollableTarget="scrollableTable"
            >
              {bankTransactionsData.map((item, index) => (
                <TableRow
                  key={item.id}
                  data-index={index}
                  className={cn('activableRow', {
                    activeRow: activeItems.includes(index),
                    currentActiveRow: activeItem === index,
                  })}
                  onClick={(event) => handleSelectRow(event, index)}
                >
                  <TableCell className={genericSs.tableTextRight}>
                    {formatDate(item.recordDate)}
                  </TableCell>
                  <TableCell className={genericSs.tableTextLeft}>
                    <Tooltip title={item.description} placement="top" disableTouchListener>
                      <span>{item.description}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell className={genericSs.tableTextLeft}>
                    {item.category
                      ? `${CATEGORIES_LABELS[item.category]}${
                          INFLOW_OUTFLOW_CATEGORIES.includes(item.category)
                            ? item.amount > 0
                              ? ' Inflow'
                              : ' Outflow'
                            : ''
                        }`
                      : 'N/A'}
                  </TableCell>
                  <TableCell className={genericSs.tableTextLeft}>
                    {item.entityInfo?.id && !isParticipant ? (
                      <LinkButton
                        component={Link}
                        to={generatePath(ROUTES.ENTITY_PAGE, {
                          id: item.entityInfo.id,
                        })}
                      >
                        {item.entityName}
                      </LinkButton>
                    ) : (
                      <span>{item.entityName}</span>
                    )}
                  </TableCell>
                  <TableCell className={genericSs.tableTextLeft}>
                    {item.intermediaryEntityInfo?.id && !isParticipant ? (
                      <LinkButton
                        component={Link}
                        to={generatePath(ROUTES.ENTITY_PAGE, {
                          id: item.intermediaryEntityInfo.id,
                        })}
                      >
                        {item.intermediaryName}
                      </LinkButton>
                    ) : (
                      <span>{item.intermediaryName}</span>
                    )}
                  </TableCell>
                  <TableCell className={genericSs.tableTextRight}>
                    ${formatPrice(item.amount)}
                  </TableCell>
                </TableRow>
              ))}
            </InfiniteScroll>
          )}
          <MultiselectRow activeItems={activeItems}>
            <TableCell className={genericSs.tableTextRight}>
              ${formatPrice(totalRow?.amount)}
            </TableCell>
          </MultiselectRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default BankTransactionsTable
