import { generatePath } from 'react-router-dom'
import queryString from 'query-string'
import { ISearchResult, ISearchResultItem, SearchType } from '@common/interfaces/search'
import { ClientInfoStatus, IClientInfo } from '@common/interfaces/client'
import { ROUTES } from '../../constants/routes'
import { databaseDateFormat } from '../../helpers/helpers'
import { REPORTING_DOCUMENT_TYPES } from '@common/constants/client'
import { IUser, UserRole } from '@common/interfaces/user'
import { IMasterCollection } from '@common/interfaces/collection'
import { IFee } from '@common/interfaces/loanServicing'
import { INoteMapping } from '@common/interfaces/notes'
import { IOngoingReporting } from '@common/interfaces/bbc'

const CLIENT_STATUS_TO_SECTION_MAP = {
  [ClientInfoStatus.DueDiligence]: SearchType.DueDiligence,
  [ClientInfoStatus.Prospect]: SearchType.Prospect,
  [ClientInfoStatus.Archived]: SearchType.Prospect,
  [ClientInfoStatus.Current]: SearchType.Client,
  [ClientInfoStatus.Past]: SearchType.Client,
}

const SECTION_KEYWORDS = {
  [SearchType.Collection]: ['collection', 'wire', 'check'],
  [SearchType.Fee]: ['fee', 'interest', 'minimum'],
  [SearchType.OngoingReporting]: ['financials', 'sales', 'bank', 'financial', 'general', 'ledger'],
  [SearchType.User]: ['user', 'email', 'contact'],
  [SearchType.Entity]: ['entity', 'debtor', 'customer', 'vendor'],
  [SearchType.Funding]: ['bbc', 'funding', 'borrowing'],
  [SearchType.Note]: ['note'],
}

const reorderResultsBasedOnSection = (results: ISearchResult[], section: string) => {
  const sectionResults = results.filter((result) => result.type === section)
  const otherResults = results.filter((result) => result.type !== section)
  return [...sectionResults, ...otherResults]
}

const pushSectionsToBottom = (results: ISearchResult[], sections: string[]) => {
  const sectionResults = results.filter((result) => sections.includes(result.type))
  const otherResults = results.filter((result) => !sections.includes(result.type))
  return [...otherResults, ...sectionResults]
}

const getSectionFromSearchTerm = (searchTerm: string) => {
  const searchTermArray = searchTerm.toLowerCase().split(' ')
  const section = Object.keys(SECTION_KEYWORDS).find((section) =>
    SECTION_KEYWORDS[section].some((keyword: string) => searchTermArray.includes(keyword)),
  )
  return section
}

const seperateClientStatusResults = (results: ISearchResult[]) => {
  const diligenceMaxScore = results.find(
    (result) => result.type === SearchType.DueDiligence,
  )?.maxScore
  const clientInfoMaxScore = results.find((result) => result.type === SearchType.Client)?.maxScore
  const prospectMaxScore = results.find((result) => result.type === SearchType.Prospect)?.maxScore

  const firstSection = results.find(
    (result) =>
      result.maxScore === Math.max(diligenceMaxScore, clientInfoMaxScore, prospectMaxScore),
  )

  const firstResultStatus = firstSection?.items?.[0]?.status

  const section = CLIENT_STATUS_TO_SECTION_MAP[firstResultStatus]
  const otherSections = Object.values(CLIENT_STATUS_TO_SECTION_MAP).filter(
    (otherSection) => otherSection !== section,
  )

  if (!section) {
    return results
  }

  let reorderedResults = reorderResultsBasedOnSection(results, section)
  reorderedResults = pushSectionsToBottom(reorderedResults, otherSections)
  return reorderedResults
}

const organizeResultsBySectionBasedOnSearchTerm = (
  results: ISearchResult[],
  searchTerm: string,
) => {
  const section = getSectionFromSearchTerm(searchTerm)
  if (!section) {
    return results
  }
  return reorderResultsBasedOnSection(results, section)
}

const generateSearchResultItemLink = (data: ISearchResultItem) => {
  let link: string
  switch (data.type) {
    case SearchType.Client:
      link = generatePath(ROUTES.CLIENT_PAGE, { id: data.id })
      break
    case SearchType.DueDiligence:
      link = generatePath(ROUTES.DUE_DILIGENCE, { id: data.id })
      break
    case SearchType.Prospect:
      const clientInfo = data.item as IClientInfo
      link = clientInfo.firstOPSReportingId
        ? generatePath(ROUTES.PROSPECT_PAGE, { id: clientInfo.firstOPSReportingId })
        : null
      break
    case SearchType.Funding:
      link = generatePath(ROUTES.BBC_SUMMARY, { id: data.id })
      break
    case SearchType.Collection:
      const collection = data.item as IMasterCollection

      link = `${generatePath(ROUTES.CLIENT_PAGE, {
        id: collection.clientId,
      })}?${queryString.stringify({
        accountActivityFilters: queryString.stringify(
          {
            amountMax: collection.paymentAmount,
            amountMin: collection.paymentAmount,
            recordDateFrom: databaseDateFormat(collection.recordDate),
            recordDateTo: databaseDateFormat(collection.recordDate),
            type: [`Collection - ${collection.paymentType}`],
          },
          { arrayFormat: 'index' },
        ),
      })}`
      break
    case SearchType.Fee:
      const fee = data.item as IFee

      link = `${generatePath(ROUTES.CLIENT_PAGE, {
        id: fee.clientId,
      })}?${queryString.stringify({
        accountActivityFilters: queryString.stringify({
          amountMax: fee.amount,
          amountMin: fee.amount,
          recordDateFrom: databaseDateFormat(fee.recordDate),
          recordDateTo: databaseDateFormat(fee.recordDate),
        }),
      })}`
      break
    case SearchType.Note:
      const note = data.item as INoteMapping
      const noteId = note.noteParentId || note.id

      link = `${
        note.borrowingBaseId
          ? generatePath(ROUTES.BBC_SUMMARY, { id: note.borrowingBaseId })
          : note.ongoingReportingId
          ? generatePath(ROUTES.ONGOING_REPORTING, {
              id: note.ongoingReportingId,
            })
          : note.opsReportingId
          ? generatePath(ROUTES.PROSPECT_PAGE, { id: note.opsReportingId })
          : note.clientId
          ? generatePath(ROUTES.CLIENT_PAGE, { id: note.clientId })
          : ''
      }?${queryString.stringify({
        noteId,
        notesContainerFilters: queryString.stringify(
          {
            id: [noteId],
          },
          { arrayFormat: 'index' },
        ),
      })}`
      break
    case SearchType.OngoingReporting:
      const reporting = data.item as IOngoingReporting

      if (
        [
          REPORTING_DOCUMENT_TYPES.financials,
          REPORTING_DOCUMENT_TYPES.financialProjections,
        ].includes(reporting.type)
      ) {
        link = generatePath(ROUTES.ONGOING_REPORTING, { id: reporting.id })
      } else if (REPORTING_DOCUMENT_TYPES.salesBySKU === reporting.type) {
        link = generatePath(ROUTES.ONGOING_REPORTING_SALES_BY_SKU, {
          id: reporting.id,
        })
      } else if (REPORTING_DOCUMENT_TYPES.arGeneralLedger === reporting.type) {
        link = generatePath(ROUTES.ONGOING_REPORTING_AR_GENERAL_LEDGER, {
          id: reporting.id,
        })
      } else if (REPORTING_DOCUMENT_TYPES.bankTransactions === reporting.type) {
        link = generatePath(ROUTES.ONGOING_REPORTING_BANK_TRANSACTIONS_UPLOAD, {
          id: reporting.id,
        })
      } else if (REPORTING_DOCUMENT_TYPES.capTable === reporting.type) {
        link = generatePath(ROUTES.ONGOING_REPORTING_CAP_TABLE_UPLOAD, {
          id: reporting.id,
        })
      }
      break
    case SearchType.User:
      const user = data.item as IUser

      link =
        [UserRole.CLIENT_USER].includes(user.role) && user.clientId
          ? generatePath(ROUTES.CLIENT_SETUP_USERS, { id: user.clientId })
          : ROUTES.USER_MANAGEMENT
      break
    case SearchType.Entity:
      link = generatePath(ROUTES.ENTITY_PAGE, { id: data.id })
      break
    case SearchType.Participant:
      link = generatePath(ROUTES.PARTICIPANTS_PAGE, { id: data.id })
      break
    default:
      link = ''
  }

  return link
}

export {
  seperateClientStatusResults,
  organizeResultsBySectionBasedOnSearchTerm,
  generateSearchResultItemLink,
}
