import React, { KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { generatePath } from 'react-router-dom'
import * as XLSX from 'xlsx'
import {
  CompositeDecorator,
  ContentState,
  convertFromRaw,
  convertToRaw,
  EditorState,
  RawDraftContentState,
  RichUtils,
} from 'draft-js'
import Editor from '@draft-js-plugins/editor'
import createLinkifyPlugin from '@draft-js-plugins/linkify'
import createMentionPlugin, { MentionPluginConfig } from '@draft-js-plugins/mention'
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar'
import createStaticToolbarPlugin from '@draft-js-plugins/static-toolbar'
import {
  BoldButton,
  ItalicButton,
  OrderedListButton,
  UnderlineButton,
  UnorderedListButton,
} from '@draft-js-plugins/buttons'
import Link from '@mui/material/Link'
import Tooltip from '@mui/material/Tooltip'
import { ErrorCode, FileRejection, useDropzone } from 'react-dropzone'
import cn from 'classnames'
import { diff as deepDiff } from 'deep-object-diff'

import '@draft-js-plugins/mention/lib/plugin.css'
import 'draft-js/dist/Draft.css'
import '@draft-js-plugins/inline-toolbar/lib/plugin.css'

import styles from './../NotesContainer.module.scss'

import { ReactComponent as AttachmentIcon } from '@assets/images/attachment-icon.svg'

import { ACCEPTED_FILES_MAPPING, DEFAULT_MAX_FILE_SIZE } from '../../../constants/common'
import Attachments from '../../Attachments/Attachments'
import Card from '../../../components/Common/Card'
import FullscreenModal from '../../../components/Common/FullscreenModal'
import { ROUTES } from '../../../constants/routes'
import { UserRole } from '@common/interfaces/user'
import { IAttachment, INoteSuggestion, NoteSuggestionType } from '@common/interfaces/notes'
import { debounceEventHandler, handleStopPropagation, voidHandler } from '../../../helpers/helpers'
import Button from '../../../components/Common/Button'
import SaveState from '../../Common/SaveState'
import { humanReadableFileSize } from '@common/helpers/helpers'
import { ClientInfoStatus } from '@common/interfaces/client'

const buildNoteSuggestionData = ({
  id,
  type,
  clientStatus,
  opsReportingId,
}: Partial<INoteSuggestion>): { link: string | null; avatar: string; className: string | null } => {
  if ([NoteSuggestionType.InternalUser, NoteSuggestionType.ClientUser].includes(type)) {
    return {
      link: null,
      avatar: 'user',
      className: null,
    }
  }

  if (type === NoteSuggestionType.Entity) {
    return {
      link: generatePath(ROUTES.ENTITY_PAGE, { id }),
      avatar: 'entity',
      className: null,
    }
  }

  if (type !== NoteSuggestionType.Client) {
    return {
      link: null,
      avatar: null,
      className: null,
    }
  }

  if (clientStatus === ClientInfoStatus.DueDiligence) {
    return {
      link: generatePath(ROUTES.DUE_DILIGENCE, { id }),
      avatar: 'client-underwriting',
      className: null,
    }
  }

  if (
    [ClientInfoStatus.Prospect, ClientInfoStatus.Archived].includes(clientStatus) &&
    opsReportingId
  ) {
    return {
      link: generatePath(ROUTES.PROSPECT_PAGE, { id: opsReportingId }),
      avatar: 'client-prospect',
      className: null,
    }
  }

  return {
    link: generatePath(ROUTES.CLIENT_PAGE, { id }),
    avatar: 'client',
    className: null,
  }
}

const images = require['context']('@assets/images', true)

const getNoteMentionAvatarLink = (avatar: string) => images(`./note-mention-${avatar}-icon.svg`)

export const linkifyPlugin = createLinkifyPlugin({
  target: '_blank',
  component(props) {
    return <a {...props}>Link</a>
  },
})

export const onEditorTab = (
  event: KeyboardEvent,
  editorState: EditorState,
  onEditorChange: (editorState: EditorState) => void,
) => {
  event.preventDefault()

  const selection = editorState.getSelection()
  const contentState = editorState.getCurrentContent()
  const currentBlockKey = selection.getStartKey()
  const currentBlock = contentState.getBlockForKey(currentBlockKey)
  const currentBlockType = currentBlock.getType()

  if (currentBlockType === 'ordered-list-item' || currentBlockType === 'unordered-list-item') {
    onEditorChange(RichUtils.onTab(event, editorState, 4))
  }
}

interface IProps extends Partial<React.ComponentProps<typeof Editor>> {
  className?: string
  value?: RawDraftContentState
  handleChange?: (value: string) => void
  customToolbar?: object
  handleSubmit?: (values: object) => Promise<void>
  handleUpdate?: (values: object) => Promise<void>
  role: UserRole
  listSuggestions: (data: object) => Promise<{ data: INoteSuggestion[] }>
  editorClassName?: any
  editorFlex?: boolean
  noteText?: string
  setAttachments?: React.Dispatch<React.SetStateAction<IAttachment[]>> | undefined
  attachments?: IAttachment[]
  key: string
  withToolbar?: boolean
  isToolbarStatic?: boolean
  hideReply?: () => void
  editorState?: any
  onEditorStateChange?: (editorState: any) => void
  disabled?: boolean
  hideExpandModal?: () => void
  isExpanded?: boolean
  setIsAutoRefresh?: (isAutoRefresh: boolean) => void
  putNotification: (notification: object) => void
  isFullScreen?: boolean
  isComment?: boolean
  isAddNote?: boolean
  isNoteBeingDragged?: boolean
}

const NotesEditor = ({
  className,
  value,
  role,
  listSuggestions,
  readOnly = false,
  handleChange = null,
  handleSubmit = null,
  handleUpdate = null,
  editorClassName = '',
  editorFlex = false,
  noteText,
  setAttachments,
  attachments,
  key,
  withToolbar = true,
  isToolbarStatic = false,
  hideReply,
  editorState: externalEditorState,
  onEditorStateChange,
  disabled = false,
  hideExpandModal,
  isExpanded = false,
  setIsAutoRefresh,
  putNotification,
  isFullScreen = false,
  isComment = false,
  isAddNote = false,
  isNoteBeingDragged,
  ...props
}: IProps) => {
  const valueRef = useRef(value)
  const [isModalShown, setIsModalShown] = useState(false)
  const [modalImage, setModalImage] = useState(null)
  const [isLongText, setIsLongText] = useState(false)
  const [isTextExpanded, setIsTextExpanded] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const [editorState, setEditorState] = useState(() => {
    return EditorState.createEmpty()
  })

  useEffect(() => {
    if (value && Object.keys(value).length > 0) {
      setEditorState((editorState) =>
        EditorState.push(editorState, convertFromRaw(value), 'insert-fragment'),
      )
    }
  }, [value])

  const isEditing = useMemo(() => {
    return handleUpdate && (!readOnly || isFullScreen)
  }, [readOnly, handleUpdate, isFullScreen])

  const onUpdate = useCallback(
    async (noteContentJson: any) => {
      // Ignore empty changes
      if (valueRef?.current && !Object.keys(deepDiff(valueRef.current, noteContentJson)).length) {
        return
      }

      setIsSaving(true)
      handleUpdate && (await handleUpdate(noteContentJson))
      valueRef.current = noteContentJson
      setIsSaving(false)
    },
    [handleUpdate],
  )

  const debounceHandleUpdateNotes = useMemo(
    () =>
      debounceEventHandler(async (noteContentJson: any) => {
        setIsAutoRefresh && setIsAutoRefresh(false)
        return onUpdate(noteContentJson)
      }, 1000),
    [onUpdate, setIsAutoRefresh],
  )

  const onEditorChange = useCallback(
    (editorState: EditorState) => {
      onEditorStateChange && onEditorStateChange(editorState)
      setEditorState(editorState)
      isEditing &&
        editorState.getLastChangeType() &&
        (isExpanded || isFullScreen) &&
        debounceHandleUpdateNotes(convertToRaw(editorState.getCurrentContent()))
      if (ref.current?.getEditorRef()?.editor) {
        setIsTextExpanded(true)
      }
    },
    [onEditorStateChange, isFullScreen, isExpanded, isEditing, debounceHandleUpdateNotes],
  )

  const noteContentJson = useMemo(
    () => convertToRaw(editorState.getCurrentContent()),
    [editorState],
  )

  const isDisabled = useMemo(() => {
    return !editorState.getCurrentContent().hasText()
  }, [editorState])

  const removeAttachment = useCallback(
    (index: number) => {
      setAttachments((prevAttachments) => prevAttachments.filter((_, i) => i !== index))
    },
    [setAttachments],
  )

  const handleSubmitNotes = useCallback(
    async (e) => {
      e.preventDefault()
      handleSubmit && (!isDisabled || attachments.length) && (await handleSubmit(noteContentJson))
      onEditorChange(EditorState.createEmpty())
      isComment && hideReply && hideReply()
      hideExpandModal && hideExpandModal()
      setAttachments([])
    },
    [
      handleSubmit,
      isDisabled,
      noteContentJson,
      setAttachments,
      attachments,
      isComment,
      hideReply,
      onEditorChange,
      hideExpandModal,
    ],
  )
  const handleUpdateNotes = useCallback(
    (e) => {
      e.preventDefault()
      handleUpdate && !isDisabled && handleUpdate(noteContentJson)
    },
    [handleUpdate, isDisabled, noteContentJson],
  )

  const handleCancel = useCallback(
    (e) => {
      e.preventDefault()
      onEditorChange(EditorState.createWithContent(convertFromRaw(value)))
      handleUpdate && !isDisabled && handleUpdate(value)
    },
    [handleUpdate, isDisabled, value, onEditorChange],
  )

  const { MentionSuggestions, InlineToolbar, StaticToolbar, plugins, decorator } = useMemo(() => {
    const config: MentionPluginConfig = {
      popperOptions: {
        // popper options so the popper does not get hidden by the scrollbar of the editor container when the editor is too long
        placement: 'bottom-start',
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              rootBoundary: 'viewport',
              enabled: false,
            },
          },
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ],
      },
      entityMutability: 'IMMUTABLE',
      mentionTrigger: '@',
      supportWhitespace: true,
      mentionRegExp: '.',
      theme: {
        mention: styles.mentionsContainerMention,
        mentionSuggestionsPopup: styles.mentionsContainerSuggestionsPopup,
        mentionSuggestionsEntry: styles.mentionsContainerSuggestionsEntry,
        mentionSuggestionsEntryFocused: styles.mentionsContainerSuggestionsEntryFocused,
        mentionSuggestionsEntryAvatar: styles.mentionsContainerSuggestionsEntryAvatar,
        mentionSuggestionsEntryText: styles.mentionsContainerSuggestionsEntryText,
      },
      mentionComponent(mentionProps) {
        const { avatar, className } = buildNoteSuggestionData({
          ...mentionProps.mention,
          id: mentionProps.mention.id.toString(),
        })

        return (
          <a
            href={mentionProps.mention.link}
            className={cn(styles.mentionsContainerMention, className)}
            onClick={handleStopPropagation}
          >
            {avatar && (
              <img
                className={styles.mentionsContainerSuggestionsEntryAvatar}
                src={getNoteMentionAvatarLink(avatar)}
                alt={mentionProps.mention.name}
              />
            )}
            {mentionProps.children}
          </a>
        )
      },
    }
    // Disabled mention
    if (role === UserRole.BDO) {
      config.theme = {
        ...config.theme,
        mention: styles.mentionsContainerMentionDisabled,
      }
    }
    const mentionPlugin = createMentionPlugin(config)
    const inlineToolbarPlugin = createInlineToolbarPlugin({
      theme: {
        buttonStyles: {
          buttonWrapper: styles.toolbarButtonWrapper,
          button: styles.toolbarButton,
          active: styles.toolbarButtonActive,
        },
        toolbarStyles: { toolbar: styles.toolbarStyles },
      },
    })
    const staticToolbarPlugin = createStaticToolbarPlugin({
      theme: {
        buttonStyles: {
          buttonWrapper: styles.toolbarButtonWrapper,
          button: styles.toolbarButton,
          active: styles.toolbarButtonActive,
        },
        toolbarStyles: { toolbar: styles.toolbarStaticStyles },
      },
    })
    const { InlineToolbar } = inlineToolbarPlugin
    const { Toolbar } = staticToolbarPlugin

    const plugins = [mentionPlugin, linkifyPlugin]
    if (withToolbar) {
      if (isToolbarStatic) {
        plugins.push(staticToolbarPlugin)
      } else {
        plugins.push(inlineToolbarPlugin)
      }
    }

    return {
      plugins,
      MentionSuggestions: mentionPlugin.MentionSuggestions,
      InlineToolbar,
      StaticToolbar: Toolbar,
      decorator: new CompositeDecorator(
        [mentionPlugin, linkifyPlugin, inlineToolbarPlugin, staticToolbarPlugin]
          .reduce((acc, plugin) => (plugin.decorators ? [...acc, ...plugin.decorators] : acc), [])
          .filter((_, index) => index !== 1),
      ),
    }
  }, [role, withToolbar, isToolbarStatic])

  useEffect(() => {
    if ((handleUpdate || (readOnly && value)) && editorState === EditorState.createEmpty()) {
      if (Object.keys(value).length > 0) {
        setEditorState(EditorState.createWithContent(convertFromRaw(value), decorator))
      }
      if (noteText) {
        setEditorState(EditorState.createWithContent(ContentState.createFromText(noteText)))
      }
    }
  }, [noteText, decorator, readOnly, value, setEditorState, handleUpdate, editorState])

  const [open, setOpen] = useState(false)

  const [suggestions, setSuggestions] = useState([])

  const loadSuggestions = useCallback(
    async (state) => {
      const res = await listSuggestions({
        name: state.value,
      })
      setSuggestions(
        res.data.map((suggestion) => {
          const { id, type, name, clientStatus, opsReportingId } = suggestion
          const { link, avatar } = buildNoteSuggestionData(suggestion)

          return {
            name,
            avatar: getNoteMentionAvatarLink(avatar),
            title: name,
            id,
            link,
            type,
            clientStatus,
            opsReportingId,
          }
        }),
      )
    },
    [setSuggestions, listSuggestions],
  )

  const handleOpenImageModal = useCallback((imageAttachment) => {
    setModalImage(imageAttachment)
    setIsModalShown(true)
  }, [])

  const handleCloseModal = useCallback(() => {
    setModalImage(null)
    setIsModalShown(false)
  }, [])

  const debounceLoadSuggestions = useMemo(
    () => debounceEventHandler(loadSuggestions, 500),
    [loadSuggestions],
  )

  const onOpenChange = useCallback((_open: boolean) => {
    setOpen(_open)
  }, [])

  const ref = useRef<Editor>(null)

  const focusEditor = useCallback(() => {
    ref.current!.focus()
  }, [])

  const handleFileRead = useCallback(
    (e: ProgressEvent<FileReader>, index: number, contentType: string, fileName: string) => {
      const fileType =
        contentType.split('/')[0] === 'image'
          ? 'image'
          : contentType.split('/')[1] === 'pdf'
          ? 'pdf'
          : contentType.split('.')[contentType.split('.').length - 1] === 'document'
          ? 'document'
          : 'excel'
      if (fileType === 'excel') {
        const wb = XLSX.read(e.target.result, { type: 'binary' })
        const wsname = wb.SheetNames[0]
        const ws = wb.Sheets[wsname]
        const data = XLSX.utils.sheet_to_csv(ws)

        setAttachments((prevState) => [
          ...prevState,
          { id: index, attachmentSrc: data, fileType, contentType, fileName },
        ])
      } else if (e.target && e.target.result) {
        setAttachments((prevState) => [
          ...prevState,
          { id: index, attachmentSrc: e.target.result as string, fileType, contentType, fileName },
        ])
      }
    },
    [setAttachments],
  )

  const handleTab = useCallback(
    (e) => {
      onEditorTab(e, editorState, onEditorChange)
    },
    [editorState, onEditorChange],
  )

  const handleKeyCommand = useCallback(
    (command, editorState) => {
      const newState = RichUtils.handleKeyCommand(editorState, command)
      if (newState) {
        onEditorChange(newState)
        return 'handled'
      }
      return 'not-handled'
    },
    [onEditorChange],
  )

  const handleUploadFile = useCallback(
    (e) => {
      e.map((file: File, index: number) => {
        const reader = new FileReader()
        reader.onload = (e) => handleFileRead(e, index, file.type, file.name)
        reader.readAsDataURL(file)
        return file
      })
    },
    [handleFileRead],
  )

  const handleFileRejection = useCallback(
    (fileRejection: FileRejection[]) => {
      const firstError = fileRejection?.[0]?.errors?.[0]
      if (firstError) {
        putNotification({
          code: firstError.code,
          message:
            firstError.code === ErrorCode.FileTooLarge
              ? `File is too large, the maximum file size is ${humanReadableFileSize(
                  DEFAULT_MAX_FILE_SIZE,
                ).join(' ')}`
              : firstError.message,
          type: 'error',
        })
      }
    },
    [putNotification],
  )

  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    open: openFileSelect,
  } = useDropzone({
    noClick: true,
    maxSize: DEFAULT_MAX_FILE_SIZE,
    maxFiles: 3,
    multiple: true,
    noDragEventsBubbling: true,
    onDrop: handleUploadFile,
    onDropRejected: handleFileRejection,
    accept: {
      ...ACCEPTED_FILES_MAPPING.image,
    },
    disabled: isNoteBeingDragged,
    ...props,
  })

  const toggleTextExpanded = useCallback((e) => {
    e.stopPropagation()
    setIsTextExpanded((isExpanded) => !isExpanded)
  }, [])

  useEffect(() => {
    if ((readOnly || isFullScreen) && ref.current?.getEditorRef()?.editor && !isTextExpanded) {
      setTimeout(() => {
        if (ref.current?.getEditorRef()?.editor) {
          setIsLongText(
            ref.current.getEditorRef()?.editor.scrollHeight >
              ref.current.getEditorRef()?.editor.offsetHeight,
          )
        }
      }, 100)
    }
  }, [readOnly, isFullScreen, value, isEditing, isTextExpanded])

  const onEditorBlur = useCallback(() => {
    setIsAutoRefresh && setIsAutoRefresh(true)
  }, [setIsAutoRefresh])

  return (
    <div
      className={cn({
        [styles.expandModalReply]: isComment && !hideReply,
        [styles.expandModalReplyForce]: editorFlex,
      })}
    >
      <div
        className={cn(styles.editorContainer, className, {
          [styles.accepted]: isDragAccept && !readOnly && !disabled,
        })}
        {...(!readOnly && getRootProps())}
      >
        {<input {...getInputProps()} />}
        <div
          className={cn(editorClassName, {
            [styles.editor]: !readOnly,
            [styles.readOnly]: readOnly || isFullScreen,
            [styles.isExpanded]: isExpanded || isTextExpanded,
            [styles.isFullScreenEditor]: isFullScreen,
          })}
          onClick={focusEditor}
        >
          <Editor
            editorKey={key}
            editorState={externalEditorState || editorState}
            onTab={handleTab}
            onChange={onEditorChange}
            plugins={plugins}
            spellCheck
            ref={ref}
            readOnly={readOnly || disabled}
            blockStyleFn={() => styles['public-DraftStyleDefault-block']}
            handleKeyCommand={handleKeyCommand}
            onBlur={onEditorBlur}
            {...props}
          />
          {((!isEditing && !isExpanded) || isFullScreen) && isLongText && (
            <div className={styles.readMoreButton} onClick={toggleTextExpanded}>
              {isTextExpanded ? 'Show less' : 'Read more'}
            </div>
          )}
          <div className={styles.editorFooter}>
            {!isExpanded && handleSubmit && (
              <div className={styles.actionsBar}>
                <Tooltip title="Add attachment" placement="top">
                  <div className={styles.addAttachmentButton} onClick={openFileSelect}>
                    <AttachmentIcon />
                  </div>
                </Tooltip>

                {!isComment && (
                  <div className={styles.postButton}>
                    <Button
                      disabled={isDisabled && !attachments.length && !readOnly}
                      onClick={handleSubmitNotes}
                    >
                      Post
                    </Button>
                  </div>
                )}
              </div>
            )}

            <Attachments
              attachments={attachments}
              readOnly={readOnly}
              removeAttachment={removeAttachment}
              handleOpenImageModal={handleOpenImageModal}
            />
          </div>
        </div>
      </div>
      {isEditing && !(isExpanded || isFullScreen) && (
        <div className={styles.toolbarContainerStaticWrapper}>
          {withToolbar && isToolbarStatic && StaticToolbar && (
            <div
              className={cn(styles.toolbarContainer, {
                [styles.toolbarContainerStaticVisible]: isEditing || isAddNote,
              })}
            >
              <StaticToolbar>
                {(externalProps) => (
                  <div className={styles.toolbarButtonWrapper}>
                    <div className={styles.toolbarButtonContainer}>
                      <BoldButton {...externalProps} />
                      <ItalicButton {...externalProps} />
                      <UnderlineButton {...externalProps} />
                      <UnorderedListButton {...externalProps} />
                      <OrderedListButton {...externalProps} />
                    </div>
                  </div>
                )}
              </StaticToolbar>
            </div>
          )}

          <div className={cn(styles.actionsBar, styles.commentActionsBar)}>
            <div className={styles.cancelButton}>
              <Button onClick={handleCancel} variant="outlined">
                Cancel
              </Button>
            </div>
            <div className={styles.postButton}>
              <Button disabled={isDisabled} onClick={handleUpdateNotes}>
                Save
              </Button>
            </div>
          </div>
        </div>
      )}

      {isComment && (
        <div className={styles.toolbarContainerStaticWrapper}>
          {withToolbar && isToolbarStatic && StaticToolbar && (
            <div
              className={cn(styles.toolbarContainer, {
                [styles.toolbarContainerStaticVisible]: isEditing || isAddNote,
              })}
            >
              <StaticToolbar>
                {(externalProps) => (
                  <div className={styles.toolbarButtonWrapper}>
                    <div className={styles.toolbarButtonContainer}>
                      <BoldButton {...externalProps} />
                      <ItalicButton {...externalProps} />
                      <UnderlineButton {...externalProps} />
                      <UnorderedListButton {...externalProps} />
                      <OrderedListButton {...externalProps} />
                    </div>
                  </div>
                )}
              </StaticToolbar>
            </div>
          )}
          <div className={cn(styles.actionsBar, styles.commentActionsBar)}>
            {hideReply && (
              <div className={styles.cancelButton}>
                <Button onClick={hideReply} variant="outlined">
                  Cancel
                </Button>
              </div>
            )}
            <div className={styles.postButton}>
              <Button
                disabled={isDisabled && !attachments.length && !readOnly}
                onClick={handleSubmitNotes}
              >
                Post
              </Button>
            </div>
          </div>
        </div>
      )}
      <div className={styles.mentionsContainer}>
        <MentionSuggestions
          open={open}
          onOpenChange={onOpenChange}
          suggestions={suggestions}
          onSearchChange={debounceLoadSuggestions}
        />
      </div>
      <div
        className={cn(styles.toolbarContainer, {
          [styles.toolbarContainerReply]: isAddNote,
        })}
      >
        {withToolbar && !isToolbarStatic && InlineToolbar && (
          <InlineToolbar>
            {(externalProps) => (
              <div className={styles.toolbarButtonWrapper}>
                <div className={styles.toolbarButtonContainer}>
                  {(isExpanded || isFullScreen) && handleSubmit && (
                    <Tooltip title="Add attachment" placement="top">
                      <div className={styles.toolbarButton} onClick={openFileSelect}>
                        <AttachmentIcon className={styles.toolbarButtonAttachmentIcon} />
                      </div>
                    </Tooltip>
                  )}

                  <BoldButton {...externalProps} />
                  <ItalicButton {...externalProps} />
                  <UnderlineButton {...externalProps} />
                  <UnorderedListButton {...externalProps} />
                  <OrderedListButton {...externalProps} />
                </div>
                {(isExpanded || isFullScreen) && (
                  <div className={styles.postButton}>
                    {isEditing ? (
                      <SaveState isSaving={isSaving} isSaved />
                    ) : (
                      <Button
                        disabled={isDisabled && !attachments?.length && !readOnly}
                        onClick={handleSubmitNotes}
                      >
                        Post
                      </Button>
                    )}
                  </div>
                )}
              </div>
            )}
          </InlineToolbar>
        )}
      </div>

      {modalImage && (
        <FullscreenModal
          isOpen={isModalShown}
          setIsOpen={setIsModalShown}
          classes={{ body: styles.fullScreenModal }}
          onCloseCustom={handleCloseModal}
          showCloseIcon
        >
          <div className={styles.imageModalHeader}>
            <div>{modalImage.fileName}</div>
            <Link
              href={modalImage.attachmentSrc}
              target="_blank"
              rel="noopener noreferrer"
              download={modalImage.fileName}
            >
              <Button variant="outlined" onClick={voidHandler}>
                Download
              </Button>
            </Link>
          </div>
          <Card id="image-modal" noHeaderMargin className={styles.modalCard}>
            <img
              className={styles.fullScreenModalImage}
              src={modalImage.attachmentSrc}
              alt={modalImage.fileName}
            />
          </Card>
        </FullscreenModal>
      )}
    </div>
  )
}

export default NotesEditor
