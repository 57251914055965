import { connect } from 'react-redux'
import {
  askAtlasAI,
  toggleChatVisibility,
  listSessions,
  selectSession,
  createSession,
  loadMoreHistory,
} from '../../redux/atlasai/actions'
import {
  isLoading,
  isChatVisible,
  sessions,
  chatHistory,
  sessionId,
  isLoadingMore,
  modelProvider,
} from '../../redux/atlasai/selectors'
import AtlasAIChat from './AtlasAIChat'
import { createStructuredSelector } from 'reselect'

const selector = createStructuredSelector({
  chatHistory,
  isLoading,
  isLoadingMore,
  isChatVisible,
  sessionId,
  sessions,
  modelProvider,
})

const actions = {
  askAtlasAI,
  toggleChatVisibility,
  listSessions,
  selectSession,
  createSession,
  loadMoreHistory,
}

// Export the connected component as default
export default connect(selector, actions)(AtlasAIChat)
