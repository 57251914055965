import React, { useMemo } from 'react'
import { useParams } from 'react-router'
import Box from '@mui/material/Box'

import InputLabel from '../Common/InputLabel'
import TextField from '../Common/TextField'

import styles from './ParticipantGeneralSettings.module.scss'

import { IParticipant } from '@common/interfaces/participant'
import { ILoadingData } from '../../redux/types'
import { Form } from 'react-final-form'
import Button from '../Common/Button'

interface IProps {
  participantInfo: ILoadingData<IParticipant>
  updateParticipant: (id: string, data: object) => void
}

const ParticipantGeneralSettings = ({ participantInfo, updateParticipant }: IProps) => {
  const { id } = useParams<{ id: string }>()

  const { data: participantInfoData } = useMemo(() => participantInfo, [participantInfo])

  const onSubmit = useMemo(
    () => (values: any) => {
      if (participantInfoData?.website && !values?.website) {
        values.website = null
      }
      updateParticipant(id, values)
    },
    [id, updateParticipant, participantInfoData?.website],
  )

  return (
    <Form
      initialValues={{
        participant: participantInfoData?.participant,
        website: participantInfoData?.website,
      }}
      onSubmit={onSubmit}
      render={({ dirty, handleSubmit }) => (
        <form>
          <Box className={styles.infoItems}>
            <InputLabel htmlFor="participant">Name</InputLabel>
            <TextField size="large" name="participant" fullWidth className={styles.textArea} />
          </Box>
          <Box className={styles.infoItems}>
            <InputLabel htmlFor="website">Website</InputLabel>
            <TextField size="large" name="website" className={styles.textArea} />
          </Box>
          <Box className={styles.infoItems}>
            <Button key="Submit" color="primary" onClick={handleSubmit} disabled={!dirty}>
              Save
            </Button>
          </Box>
        </form>
      )}
    />
  )
}

export default ParticipantGeneralSettings
