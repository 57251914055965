import React, { useCallback, useMemo } from 'react'
import moment from 'moment'
import { IDefaultNote } from '@common/interfaces/dueDiligence'
import Note from '../Notes/Note'
import { INote } from '@common/interfaces/notes'

interface IProps {
  note: IDefaultNote
  index: number
  handleNoteUpdate: (id: string, data: object) => void
  handleNoteMove: (dragIndex: number, hoverIndex: number) => void
  handleNoteDrop: (IDefaultNote: IDefaultNote, dragOrder: number) => void
  handleNoteDelete: (id: string) => void
  isNoteBeingDragged: boolean
}

const UWDefaultNotesSection = ({
  note,
  index,
  handleNoteUpdate,
  handleNoteMove,
  handleNoteDrop,
  handleNoteDelete,
  isNoteBeingDragged,
}: IProps) => {
  const preparedNote = useMemo(() => {
    return {
      id: note.id,
      note: note.note,
      noteText: note.noteText,
      tags: note.tags,
      dragOrder: note.order,
      createdAt: note.createdAt,
      noteTextUpdatedAt: note.noteTextUpdatedAt,
      isNoteTextEdited: moment(note.noteTextUpdatedAt).isAfter(note.createdAt),
      ddInfo: {
        id: note.id,
      },
    } as INote
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [note.id, note.order])

  const onNoteMove = useCallback(
    (_, dragOrder) => handleNoteDrop(note, dragOrder),
    [handleNoteDrop, note],
  )

  return (
    <Note
      key={note.id}
      index={index}
      note={preparedNote}
      isFullScreen
      moveNote={handleNoteMove}
      onNoteDrop={onNoteMove}
      onNoteUpdate={handleNoteUpdate}
      onNoteDelete={handleNoteDelete}
      isDraggable
      isNoteBeingDragged={isNoteBeingDragged}
      isDefaultNote
    />
  )
}

export default UWDefaultNotesSection
