import { createRequestTypes } from '../../helpers/helpers'
import { IApi } from '../../api'
import { CommonPromiseAction } from '../types'

export const prefix = 'treasury'

const LIST_TREASURY = createRequestTypes(prefix, 'LIST_TREASURY')

export const [LIST_TREASURY_REQUEST, LIST_TREASURY_SUCCESS, LIST_TREASURY_FAILURE] = LIST_TREASURY

export const listTreasury: CommonPromiseAction = (params?: object) => ({
  type: LIST_TREASURY,
  promise: (api: IApi) => api.treasury.listTreasury(params),
  params,
})

const LIST_TREASURY_FUNDING_WIRES = createRequestTypes(prefix, 'LIST_TREASURY_FUNDING_WIRES')

export const [
  LIST_TREASURY_FUNDING_WIRES_REQUEST,
  LIST_TREASURY_FUNDING_WIRES_SUCCESS,
  LIST_TREASURY_FUNDING_WIRES_FAILURE,
] = LIST_TREASURY_FUNDING_WIRES

export const listTreasuryFundingWires: CommonPromiseAction = (params?: object) => ({
  type: LIST_TREASURY_FUNDING_WIRES,
  promise: (api: IApi) => api.treasury.listTreasuryFundingWires(params),
  params,
})

const LIST_TREASURY_PASSTHROUGH_WIRES = createRequestTypes(
  prefix,
  'LIST_TREASURY_PASSTHROUGH_WIRES',
)

export const [
  LIST_TREASURY_PASSTHROUGH_WIRES_REQUEST,
  LIST_TREASURY_PASSTHROUGH_WIRES_SUCCESS,
  LIST_TREASURY_PASSTHROUGH_WIRES_FAILURE,
] = LIST_TREASURY_PASSTHROUGH_WIRES

export const listTreasuryPassthroughWires: CommonPromiseAction = (params?: object) => ({
  type: LIST_TREASURY_PASSTHROUGH_WIRES,
  promise: (api: IApi) => api.treasury.listTreasuryPassthroughWires(params),
  params,
})

const LIST_TREASURY_PARTICIPATION_WIRES = createRequestTypes(
  prefix,
  'LIST_TREASURY_PARTICIPATION_WIRES',
)

export const [
  LIST_TREASURY_PARTICIPATION_WIRES_REQUEST,
  LIST_TREASURY_PARTICIPATION_WIRES_SUCCESS,
  LIST_TREASURY_PARTICIPATION_WIRES_FAILURE,
] = LIST_TREASURY_PARTICIPATION_WIRES

export const listTreasuryParticipationWires: CommonPromiseAction = (params?: object) => ({
  type: LIST_TREASURY_PARTICIPATION_WIRES,
  promise: (api: IApi) => api.treasury.listTreasuryParticipationWires(params),
  params,
})

const LIST_TREASURY_OTHER_WIRES = createRequestTypes(prefix, 'LIST_TREASURY_OTHER_WIRES')

export const [
  LIST_TREASURY_OTHER_WIRES_REQUEST,
  LIST_TREASURY_OTHER_WIRES_SUCCESS,
  LIST_TREASURY_OTHER_WIRES_FAILURE,
] = LIST_TREASURY_OTHER_WIRES

export const listTreasuryOtherWires: CommonPromiseAction = (params?: object) => ({
  type: LIST_TREASURY_OTHER_WIRES,
  promise: (api: IApi) => api.treasury.listTreasuryOtherWires(params),
  params,
})

const UPDATE_TREASURY = createRequestTypes(prefix, 'UPDATE_TREASURY')

export const [UPDATE_TREASURY_REQUEST, UPDATE_TREASURY_SUCCESS, UPDATE_TREASURY_FAILURE] =
  UPDATE_TREASURY

export const updateTreasury: CommonPromiseAction = (params: object) => ({
  type: UPDATE_TREASURY,
  promise: (api: IApi) => api.treasury.updateTreasury(params),
  params,
})

const CREATE_TREASURY = createRequestTypes(prefix, 'CREATE_TREASURY')

export const [CREATE_TREASURY_REQUEST, CREATE_TREASURY_SUCCESS, CREATE_TREASURY_FAILURE] =
  CREATE_TREASURY

export const createTreasury: CommonPromiseAction = (params: object) => ({
  type: CREATE_TREASURY,
  promise: (api: IApi) => api.treasury.createTreasury(params),
  params,
})
