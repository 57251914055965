import React, { useState, useCallback, useMemo, useEffect, SetStateAction, Dispatch } from 'react'
import { useParams } from 'react-router'
import Grid from '@mui/material/Grid'
import cn from 'classnames'

import styles from '../OngoingReportingFiles/OngoingReportingFiles.module.scss'

import Modal from '../../components/Common/Modal'
import { IFile } from '@common/interfaces/box'
import Button from '../../components/Common/Button'
import UploadFile from '../Common/UploadFile'
import {
  IOPSReporting,
  IOPSReportingFlow,
  OPSReportingFlowStatus,
} from '@common/interfaces/prospects'
import { OngoingReportingType, FILE_TYPE_TEMPLATES, WorkflowPage } from '@common/interfaces/bbc'
import SaveState from '../Common/SaveState'
import FileHeaderMapping from '../FileHeaderMapping'
import SelectedFiles from '../SelectedFiles'
import FilesTemplates from '../FilesTemplates'
import { ReactComponent as BackIcon } from '@assets/images/back-icon.svg'

interface IProps {
  isOpen: boolean
  isLoading: boolean
  updateReporting: (id: string, data: object) => Promise<any>
  deleteFile: (ids: string[]) => Promise<void>
  downloadTemplate: (fileType?: string, fileId?: string) => Promise<void>
  reporting: IOPSReporting
  reportType: OngoingReportingType
  startReporting: (id: string, data?: object) => Promise<any>
  opsReportingFlow: IOPSReportingFlow
  setRefreshCounter?: Dispatch<SetStateAction<number>>
  setIsFilesSaving?: Dispatch<SetStateAction<boolean>>
  handleOpenModal?: () => void
  handleCloseModal?: () => void
}

const ProspectFileSelect = ({
  isOpen,
  isLoading,
  updateReporting,
  deleteFile,
  downloadTemplate,
  reporting,
  reportType,
  startReporting,
  opsReportingFlow,
  setRefreshCounter,
  setIsFilesSaving,
  handleOpenModal,
  handleCloseModal,
}: IProps) => {
  const { id } = useParams<{ id: string }>()

  const [isSaving, setIsSaving] = useState(false)
  const [isSaved, setIsSaved] = useState(false)
  const [isFilesUploading, setIsFilesUploading] = useState(false)
  const [filesForDelete, setFilesForDelete] = useState<IFile[]>([])
  const [attempt, setAttempt] = useState(0)
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false)
  const [selectedHeaderMapping, setSelectedHeaderMapping] = useState('')

  const files = useMemo(() => {
    if (!reporting?.files?.length) {
      return []
    }
    const opsReportingFlowFile = opsReportingFlow?.files[0]
    return reporting.files
      .filter(({ type }) => type === reportType)
      .map((file) => ({
        ...file,
        sheetName:
          file.id === opsReportingFlowFile?.fileId ? opsReportingFlowFile?.sheetName : null,
      }))
  }, [reporting, reportType, opsReportingFlow])

  const handleOpenHeaderMapping = useCallback(() => {
    setSelectedHeaderMapping(reportType)
  }, [reportType])

  const handleCloseHeaderMapping = useCallback(() => {
    setSelectedHeaderMapping('')
  }, [])

  const handleSelectFile = useCallback(
    async (fileId: string, sheetName: string, isReselect?: boolean) => {
      setIsFilesSaving && setIsFilesSaving(true)
      setIsSaving(true)
      setIsSaved(false)
      const opsReportingFlowFile = opsReportingFlow?.files[0]
      const result = await updateReporting(id, {
        filesMapping: {
          [reportType]: {
            oldFileId: opsReportingFlowFile?.fileId,
            fileId,
            sheetName,
          },
        },
      })
      if (result?.data?.success) {
        const startReportingResult = await startReporting(id, { type: reportType, isReselect })
        if (startReportingResult?.data?.success) {
          setIsSaved(true)
          handleCloseModal && handleCloseModal()
          handleCloseHeaderMapping()
        } else if (startReportingResult?.data?.error?.code === 'MISSING_COLUMNS') {
          handleCloseHeaderMapping()
          handleOpenHeaderMapping()
        }
      } else if (result?.error?.code === 'FORMAT_ERROR') {
        await startReporting(id)
      }
      if (result?.data?.error?.code === 'MISSING_COLUMNS') {
        handleCloseHeaderMapping()
        handleOpenHeaderMapping()
      }
      setRefreshCounter && setRefreshCounter((counter) => counter + 1)
      setIsSaving(false)
      setIsFilesSaving && setIsFilesSaving(false)
      !sheetName && handleCloseHeaderMapping()
    },
    [
      id,
      updateReporting,
      reportType,
      startReporting,
      opsReportingFlow,
      setRefreshCounter,
      setIsFilesSaving,
      handleCloseModal,
      handleOpenHeaderMapping,
      handleCloseHeaderMapping,
    ],
  )

  const handleSelectMultipleFiles = useCallback(
    async (flowFiles: any) => {
      setIsFilesSaving && setIsFilesSaving(true)
      setIsSaving(true)
      setIsSaved(false)
      const result = await updateReporting(id, {
        flowFiles,
        type: reportType,
      })
      if (result?.data?.success) {
        const startReportingResult = await startReporting(id, { type: reportType })
        if (startReportingResult?.data?.success) {
          setIsSaved(true)
        } else if (startReportingResult?.data?.error?.code === 'MISSING_COLUMNS') {
          handleCloseHeaderMapping()
          handleOpenHeaderMapping()
        }
      } else if (result?.data?.error?.code === 'FORMAT_ERROR') {
        await startReporting(id)
      }

      setRefreshCounter && setRefreshCounter((counter) => counter + 1)
      setIsSaving(false)
      setIsFilesSaving && setIsFilesSaving(false)
    },
    [
      id,
      updateReporting,
      reportType,
      startReporting,
      setRefreshCounter,
      setIsFilesSaving,
      handleCloseHeaderMapping,
      handleOpenHeaderMapping,
    ],
  )

  const handleUploadFile = useCallback(
    async (loadedFiles: File[]) => {
      if (loadedFiles?.length) {
        const file = loadedFiles[0]
        const formData = new FormData()

        formData.append('files[]', file, file.name)
        formData.append(`type`, reportType)

        setIsFilesUploading(true)
        const result = await updateReporting(id, formData)
        setIsFilesUploading(false)
        if (result?.data?.success) {
          setRefreshCounter && setRefreshCounter((counter) => counter + 1)
        }
      }
    },
    [updateReporting, id, setRefreshCounter, reportType],
  )

  const handleDeleteFile = useCallback(
    (fileId: string) => {
      setIsDeleteModalShown(true)
      setFilesForDelete(files.filter(({ id }) => id === fileId))
    },
    [files],
  )

  const handleDeleteCancel = useCallback(() => {
    setIsDeleteModalShown(false)
    setFilesForDelete([])
  }, [])

  const handleDeleteConfirm = useCallback(async () => {
    await deleteFile(filesForDelete.map((file) => file.id))
    startReporting(id)
    setIsDeleteModalShown(false)
    setFilesForDelete([])
  }, [deleteFile, filesForDelete, startReporting, id])

  useEffect(() => {
    if (
      reporting?.files &&
      !filesForDelete.length &&
      attempt === 0 &&
      !opsReportingFlow?.files?.length
    ) {
      if (opsReportingFlow?.status !== OPSReportingFlowStatus.Waived) {
        handleOpenModal && handleOpenModal()
      }
      setAttempt((attempt) => attempt + 1)
    }
  }, [
    reporting,
    files,
    handleSelectFile,
    attempt,
    filesForDelete,
    opsReportingFlow,
    handleOpenModal,
  ])

  const handleClose = useCallback(() => {
    if (!isSaving) {
      handleCloseModal()
    }
  }, [handleCloseModal, isSaving])

  const selectedDocuments = useMemo(() => {
    const selectedFiles = []
    for (const file of opsReportingFlow.files) {
      const fileAndSheet = files?.find(({ id }) => id === file.fileId)
      if (fileAndSheet) {
        selectedFiles.push({
          ...fileAndSheet,
          sheetName: file.sheetName,
          fileSheetId: file.id,
        })
      }
    }
    return selectedFiles
  }, [files, opsReportingFlow])

  const handleReselectFile = useCallback(async () => {
    const currentFile = selectedDocuments[0]
    await handleSelectFile(currentFile.id, currentFile.sheetName, true)
  }, [handleSelectFile, selectedDocuments])

  const modalTitle = useMemo(
    () => (selectedHeaderMapping ? 'Column Mapping' : 'Select Files'),
    [selectedHeaderMapping],
  )

  return (
    <Modal
      title={
        <Grid container justifyContent={'flex-start'} alignItems={'center'}>
          <Grid item display={'flex'}>
            {selectedHeaderMapping && (
              <BackIcon className={styles.backButton} onClick={handleCloseHeaderMapping} />
            )}
          </Grid>
          <Grid item>
            <h2 className={styles.modalTitle}>{modalTitle}</h2>
          </Grid>
        </Grid>
      }
      open={isOpen}
      classes={{
        root: styles.modalPaper,
        header: styles.modalHeader,
      }}
      onCancel={handleClose}
    >
      <Grid container>
        <Grid container item xs={12}>
          <Grid container item xs={12}>
            <SelectedFiles
              className={cn({ [styles.selectedFilesWrapperShort]: !!selectedHeaderMapping })}
              files={files}
              fileSheets={opsReportingFlow?.files.map((fileSheet) => ({
                ...fileSheet,
                data: {
                  ...(fileSheet.data || {}),
                  error:
                    files.find((file) => file.id === fileSheet?.fileId)?.error ||
                    fileSheet?.data?.error,
                },
              }))}
              handleSelectFiles={handleSelectMultipleFiles}
              handleDeleteFile={handleDeleteFile}
              isMultiple={reportType !== OngoingReportingType.Inventory}
            >
              {!selectedHeaderMapping &&
                reportType === OngoingReportingType.Inventory &&
                opsReportingFlow?.files.length > 0 && (
                  <Grid item xs={12} container justifyContent="flex-end" mt={3}>
                    <Button
                      variant="contained"
                      color="secondary"
                      secondary
                      onClick={handleOpenHeaderMapping}
                      small={false}
                    >
                      Column Mapping
                    </Button>
                  </Grid>
                )}
            </SelectedFiles>

            {reportType === OngoingReportingType.Inventory &&
              selectedHeaderMapping === reportType && (
                <Grid item xs={12}>
                  <FileHeaderMapping
                    id={id}
                    workflow={WorkflowPage.opsReporting}
                    fileType={selectedHeaderMapping}
                    onClose={handleCloseHeaderMapping}
                    onSave={handleReselectFile}
                    inModal={false}
                  />
                </Grid>
              )}
          </Grid>

          {!selectedHeaderMapping && (
            <>
              <Grid container mt={1} item xs={12}>
                <Grid container item xs={8} justifyContent={'flex-start'}>
                  <span className={styles.uploadDocumentHelper}>Want to use another document?</span>
                </Grid>
              </Grid>

              <Grid container mt={1} xs={12} lg={12} justifyContent={'center'}>
                <UploadFile
                  title=""
                  key={+isLoading}
                  size="sm"
                  onDropAccepted={handleUploadFile}
                  acceptedFileTypes={['excel']}
                  isInModal={false}
                  isLoading={!isDeleteModalShown && isFilesUploading}
                />
              </Grid>

              <Grid container item xs={6}>
                <FilesTemplates
                  templates={(FILE_TYPE_TEMPLATES[reportType] || []).map(
                    ({ value, label }: { value: string; label: string }) => ({
                      name: label,
                      type: value,
                    }),
                  )}
                  handleDownloadTemplate={downloadTemplate}
                />
              </Grid>
            </>
          )}

          <Grid container item xs={selectedHeaderMapping ? 12 : 6} justifyContent="flex-end">
            <SaveState isSaving={!isDeleteModalShown && isSaving} isSaved={isSaved} />
          </Grid>
        </Grid>
        {isDeleteModalShown && filesForDelete.length > 0 && (
          <Modal
            open={isDeleteModalShown}
            onCancel={handleDeleteCancel}
            title="Delete file?"
            footer={[
              <Button
                key="cancel"
                color="primary"
                variant="outlined"
                onClick={handleDeleteCancel}
                disabled={isLoading}
              >
                Cancel
              </Button>,
              <Button
                key="submit"
                color="primary"
                variant="contained"
                onClick={handleDeleteConfirm}
                isLoading={isLoading}
              >
                Delete
              </Button>,
            ]}
          >
            All related records will be deleted
          </Modal>
        )}
      </Grid>
    </Modal>
  )
}

export default ProspectFileSelect
