import React, { useMemo } from 'react'

import styles from './ClientBanners.module.scss'

import { ClientBannerType, IClientBanner, IClientInfo } from '@common/interfaces/client'
import { INTERNAL_BANK_TO_ROUTING, InternalBanks } from '@common/interfaces/bankAccount'

interface IProps {
  clientInfo: IClientInfo
  banner: IClientBanner
}

const ClientBannerModalContentPendingCashDominion = ({ clientInfo, banner }: IProps) => {
  const zipCode = useMemo(
    () => ['60675', clientInfo?.lockbox?.toString()?.slice(-4) || ''].join('-'),
    [clientInfo?.lockbox],
  )

  return (
    <div className={styles.modalBody}>
      <span className={styles.helperText}>
        Please redirect all customer payments to Dwight's account held with IDB Bank of New York.
        You can reference{' '}
        {clientInfo.bankAccountInfos?.length > 0
          ? 'the bank account information below'
          : 'our bank account information'}{' '}
        to redirect payments.
      </span>
      <div className={styles.cashDominionCustomerContainer}>
        <div className={styles.cashDominionCustomerColumn}>
          <div className={styles.cashDominionCustomerHeader}>Customers</div>

          <div className={styles.cashDominionCustomerContent}>
            {banner.data?.data?.cash_dominion_customers?.map(
              ({ customer }: { customer: string; amount: string }) => (
                <div className={styles.cashDominionCustomerRow} key={customer}>
                  {customer}
                </div>
              ),
            )}
          </div>
        </div>
        {clientInfo.bankAccountInfos?.length > 0 && (
          <div className={styles.cashDominionCustomerColumn}>
            <div className={styles.cashDominionCustomerHeader}>Payment Change Information</div>

            <div className={styles.cashDominionCustomerContent}>
              <div className={styles.cashDominionCustomerRow}>
                <span className={styles.cashDominionCustomerRowLabel}>Bank Name</span>
                <span className={styles.cashDominionCustomerRowValue}>IDB Bank of New York</span>
              </div>
              <div className={styles.cashDominionCustomerRow}>
                <span className={styles.cashDominionCustomerRowLabel}>Account Name</span>
                <span className={styles.cashDominionCustomerRowValue}>Dwight Funding LLC</span>
              </div>
              <div className={styles.cashDominionCustomerRow}>
                <span className={styles.cashDominionCustomerRowLabel}>Account Number</span>
                <span className={styles.cashDominionCustomerRowValue}>
                  {clientInfo.bankAccountInfos
                    .map(({ bankAccountNumber }) => bankAccountNumber)
                    .join(', ')}
                </span>
              </div>
              <div className={styles.cashDominionCustomerRow}>
                <span className={styles.cashDominionCustomerRowLabel}>Routing Number</span>
                <span className={styles.cashDominionCustomerRowValue}>
                  {INTERNAL_BANK_TO_ROUTING[InternalBanks.IDB]}
                </span>
              </div>
              <div className={styles.cashDominionCustomerRow}>
                <span className={styles.cashDominionCustomerRowLabel}>Payment by Check</span>
                <span className={styles.cashDominionCustomerRowValue}>
                  {clientInfo.clientName}
                  <br />
                  PO Box {clientInfo.lockbox}
                  <br />
                  Chicago, IL, {zipCode}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const COMPONENT_MAP = {
  [ClientBannerType.PendingCashDominion]: ClientBannerModalContentPendingCashDominion,
}

const ClientBannerModalContent = ({ clientInfo, banner }: IProps) => {
  const Component = COMPONENT_MAP[banner.type]

  if (!Component) {
    return null
  }

  return <Component clientInfo={clientInfo} banner={banner} />
}

export default ClientBannerModalContent
