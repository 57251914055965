import React, { useEffect, useCallback, useState, useMemo } from 'react'
import { useParams } from 'react-router'
import { useHistory } from 'react-router'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { ROUTES } from '../../constants/routes'
import Breadcrumbs from '../../components/Common/Breadcrumbs'
import { ReactComponent as HomeIcon } from '@assets/images/home-icon.svg'
import { generatePath } from 'react-router-dom'
import { IOngoingReporting, OngoingReportingStatus, ReportingFlow } from '@common/interfaces/bbc'
import { ILoadingData } from '../../redux/types'
import { ICapTableFilters } from '@common/interfaces/capTable'
import OngoingReportingFiles from '../../components/OngoingReportingFiles'
import CapTableSummaryTable from '../../components/CapTableSummaryTable'
import { REPORTING_DOCUMENT_TYPES } from '@common/constants/client'
import FullscreenModal from '../../components/Common/FullscreenModal'
import CapTableMappingTable from '../../components/CapTableMappingTable'
import genericSs from '@styles/generic.module.scss'
import styles from './CapTablePage.module.scss'
import Button from '../../components/Common/Button'
import useOngoingReportingFileSheet from '../../hooks/useOngoingReportingFileSheet'

interface IProps {
  ongoingReporting: IOngoingReporting
  showOngoingReporting: (id: string) => Promise<void>
  updateOngoingReportingStatus: (data: object) => Promise<void>
  processCapTable: (id: string, data: object) => Promise<any>
  showClient: (id: string) => void
  isLoading: boolean
  capTableFilters: ILoadingData<ICapTableFilters>
  listCapTableFilters: (params: object) => Promise<any>
}

const CapTablePage = ({
  showOngoingReporting,
  updateOngoingReportingStatus,
  ongoingReporting,
  processCapTable,
  showClient,
  isLoading,
  capTableFilters,
  listCapTableFilters,
}: IProps) => {
  const { id } = useParams<{ id?: string }>()

  const [isMappingModalOpen, setIsMappingModalOpen] = useState(false)
  const [refreshCount, setRefreshCount] = useState(1)
  const [mappingDate, setMappingDate] = useState(null)
  const history = useHistory()

  const refreshCapTable = useCallback(() => {
    setRefreshCount((prev) => prev + 1)
  }, [setRefreshCount])

  useEffect(() => {
    if (ongoingReporting?.clientInfo?.id) {
      showClient(ongoingReporting?.clientInfo?.id)
    }
  }, [ongoingReporting?.clientInfo?.id, showClient])

  useEffect(() => {
    refreshCount &&
      listCapTableFilters({ reportingFlowId: id, reportingFlow: ReportingFlow.OngoingReporting })
  }, [listCapTableFilters, id, refreshCount])

  const { isMappingComplete } = useMemo(() => {
    return {
      isMappingComplete: capTableFilters?.data?.isMappingComplete,
    }
  }, [capTableFilters])

  const handleOpenMappingModal = useCallback(() => {
    setIsMappingModalOpen(true)
  }, [])

  const handleClick = useCallback(() => {
    if (ongoingReporting?.status === OngoingReportingStatus.Process) {
      updateOngoingReportingStatus({ id, status: OngoingReportingStatus.InReview })
    } else if (ongoingReporting?.status === OngoingReportingStatus.InReview) {
      updateOngoingReportingStatus({ id, status: OngoingReportingStatus.Verified })
    }
    history.push(ROUTES.ANALYSIS_QUEUE)
  }, [id, updateOngoingReportingStatus, history, ongoingReporting?.status])

  const handleCloseMappingModal = useCallback(async () => {
    refreshCapTable()
    setIsMappingModalOpen(false)
  }, [refreshCapTable])

  const breadcrumbs = useMemo(() => {
    return [
      {
        link: ROUTES.HOMEPAGE,
        Icon: HomeIcon,
      },
      {
        link: ROUTES.ANALYSIS_QUEUE,
        title: 'Analysis queue',
      },
      {
        title: ongoingReporting?.clientName,
        link: ongoingReporting?.clientInfo?.id
          ? generatePath(ROUTES.CLIENT_PAGE, { id: ongoingReporting?.clientInfo?.id })
          : '',
      },
      {
        link: generatePath(ROUTES.ONGOING_REPORTING_CAP_TABLE_UPLOAD, { id }),
        title: `Cap Table reporting`,
      },
    ]
  }, [id, ongoingReporting])

  const isDisabled = useMemo(
    () =>
      [OngoingReportingStatus.Verified, OngoingReportingStatus.Archived].includes(
        ongoingReporting?.status,
      ),
    [ongoingReporting],
  )

  const handleSelectFiles = useCallback(
    async (fileSheets: { fileId: string; sheetName: string }[]) => {
      const result = await processCapTable(id, {
        fileSheets: fileSheets.map((item) => ({
          ...item,
          type: REPORTING_DOCUMENT_TYPES.capTable,
        })),
      })
      if (!result.error) {
        await showOngoingReporting(id)
        refreshCapTable()
      }
    },
    [id, processCapTable, showOngoingReporting, refreshCapTable],
  )
  const { fileSheets, currentSelectedFile } = useOngoingReportingFileSheet({
    reporting: ongoingReporting,
    type: REPORTING_DOCUMENT_TYPES.capTable,
  })

  useEffect(() => {
    showOngoingReporting(id)
  }, [showOngoingReporting, id])

  const [isFileSelectShown, setIsFileSelectShown] = useState(false)
  const handleOpenSelectFile = useCallback(() => {
    setIsFileSelectShown(true)
  }, [setIsFileSelectShown])

  const handleCloseSelectFile = useCallback(async () => {
    setIsFileSelectShown(false)
  }, [setIsFileSelectShown])

  useEffect(() => {
    if (ongoingReporting && !isMappingComplete) {
      handleOpenMappingModal()
      handleCloseSelectFile()
    }
  }, [ongoingReporting, isMappingComplete, handleCloseSelectFile, handleOpenMappingModal])

  return (
    <Box py={1} pr={2}>
      <div className={styles.topContainer}>
        <Breadcrumbs breadcrumbs={breadcrumbs} isLoading={isLoading} />
        {isDisabled ? (
          <Button
            type="submit"
            color="primary"
            variant="contained"
            size="small"
            href={ROUTES.ANALYSIS_QUEUE}
          >
            Return to Analysis queue
          </Button>
        ) : (
          <Button
            type="submit"
            color="primary"
            variant="contained"
            size="small"
            onClick={handleClick}
          >
            Submit
          </Button>
        )}
      </div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <OngoingReportingFiles
            handleSelectFiles={handleSelectFiles}
            fileSheets={fileSheets}
            files={ongoingReporting?.files}
            isDisabled={isDisabled}
            handleCloseModal={handleCloseSelectFile}
            handleOpenModal={handleOpenSelectFile}
            isOpen={isFileSelectShown}
          />
        </Grid>
      </Grid>

      <CapTableSummaryTable
        reportingFlow={ReportingFlow.OngoingReporting}
        handleOpenMappingModal={handleOpenMappingModal}
        boxLink={currentSelectedFile?.link}
        handleOpenSourceData={handleOpenSelectFile}
        isFileSelectShown={isFileSelectShown}
        selectedFile={currentSelectedFile}
        refreshCount={refreshCount}
        refreshCapTable={refreshCapTable}
        isDisabled={isDisabled}
        setMappingDate={setMappingDate}
      />
      {isMappingModalOpen && (
        <FullscreenModal
          isOpen={isMappingModalOpen}
          setIsOpen={handleCloseMappingModal}
          showCloseIcon
          classes={{ body: styles.fullScreenModal }}
        >
          <div className={styles.headerContainer}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <h2 className={genericSs.dueDiligenceHeader}>Mapping</h2>
            </Box>
          </div>
          <div className={styles.bodyContainer}>
            <CapTableMappingTable
              reportingFlow={ReportingFlow.OngoingReporting}
              selectedDate={mappingDate}
              clientName={ongoingReporting?.clientName}
              refreshCapTable={refreshCapTable}
            />
          </div>
        </FullscreenModal>
      )}
    </Box>
  )
}

export default CapTablePage
