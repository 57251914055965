import React, { useCallback, useState } from 'react'
import {
  Box,
  TextField,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material'
import { Send as SendIcon } from '@mui/icons-material'
import styles from './../AtlasAIChat.module.scss'
import { Models, MODEL_PROVIDERS } from '@common/interfaces/atlasai'

interface IProps {
  isLoading: boolean
  isFullscreen?: boolean
  onSendMessage: (message: string) => void
  modelProvider: Models
  setModelProvider: (modelProvider: Models) => void
}

const ChatInput: React.FC<IProps> = ({
  isLoading,
  isFullscreen,
  onSendMessage,
  modelProvider,
  setModelProvider,
}) => {
  const [message, setMessage] = useState('')

  const handleSend = useCallback(() => {
    if (message.trim() && !isLoading) {
      onSendMessage(message)
      setMessage('')
    }
  }, [message, isLoading, onSendMessage])

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault()
        handleSend()
      }
    },
    [handleSend],
  )

  const handleModelChange = useCallback(
    (event: SelectChangeEvent<Models>) => {
      setModelProvider(event.target.value as Models)
    },
    [setModelProvider],
  )

  return (
    <Box className={styles.chatInput}>
      <Box className={styles.inputWithModelSelector}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Ask Atlas a question..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          disabled={isLoading}
          size={isFullscreen ? 'medium' : 'small'}
          multiline
          maxRows={isFullscreen ? 4 : 3}
        />
        <FormControl className={styles.modelSelectorForm}>
          <Select<Models>
            value={modelProvider}
            onChange={handleModelChange}
            size="small"
            className={styles.modelSelector}
            disabled={isLoading}
          >
            {MODEL_PROVIDERS.map((provider) => (
              <MenuItem key={provider.value} value={provider.value}>
                {provider.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <IconButton color="primary" onClick={handleSend} disabled={!message.trim() || isLoading}>
        <SendIcon />
      </IconButton>
    </Box>
  )
}

export default ChatInput
