import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import UWDefaultNotes from './UWDefaultNotes'

import {
  dueDiligenceDefaultNotesOptions,
  dueDiligenceDefaultNotes,
} from '../../redux/dueDiligence/selectors'
import {
  getDefaultNotesOptions,
  listDefaultNotes,
  createDefaultNote,
  updateDefaultNote,
  deleteDefaultNote,
} from '../../redux/dueDiligence/actions'

const selector = createStructuredSelector({
  dueDiligenceDefaultNotesOptions,
  dueDiligenceDefaultNotes,
})

const actions = {
  getDefaultNotesOptions,
  listDefaultNotes,
  createDefaultNote,
  updateDefaultNote,
  deleteDefaultNote,
}

export default connect(selector, actions)(UWDefaultNotes)
