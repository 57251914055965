import React, { useCallback, useMemo } from 'react'
import cn from 'classnames'

import styles from './ClientCashFlow.module.scss'
import genericSs from '@styles/generic.module.scss'

import { FinancialsType } from '@common/interfaces/bbc'
import { IClientCashFlow } from '@common/interfaces/client'
import {
  CLIENT_CASH_FLOW_FIELDS,
  CLIENT_CASH_FLOW_FIELDS_LABELS,
  CLIENT_CASH_FLOW_HEADER_FIELDS,
  CLIENT_CASH_FLOW_HIGHLIGHTED_FIELDS,
  CLIENT_CASH_FLOW_INDENT_FIELDS,
  CLIENT_CASH_FLOW_NUMBER_FIELDS,
} from '@common/constants/client'
import TableContainer from '../../Common/TableContainer'
import Table from '../../Common/Table'
import TableHead from '../../Common/TableHead'
import TableRow from '../../Common/TableRow'
import TableCell from '../../Common/TableCell'
import TableBody from '../../Common/TableBody'
import MultiselectRow from '../../MultiselectRow'
import { formatPrice } from '../../../helpers/helpers'
import Skeleton from '@mui/material/Skeleton'

const coupledTables = ['clientCashFlowTable', 'clientCashFlowActiveToolbar']

const ClientCashFlowCell = ({
  isLoading,
  field,
  data,
}: {
  isLoading: boolean
  field: string
  data: IClientCashFlow
}) => {
  const isNumberField = useMemo(() => CLIENT_CASH_FLOW_NUMBER_FIELDS.includes(field), [field])

  return (
    <TableCell className={genericSs.tableTextRight}>
      {isLoading ? (
        <Skeleton width={100} height={15} />
      ) : isNumberField ? (
        <span className={styles.amountHolder}>{data?.[field] || 0}</span>
      ) : (
        <span
          className={cn(styles.amountHolder, { [styles.amountHolderNegative]: data?.[field] < 0 })}
        >
          ${formatPrice(data?.[field])}
        </span>
      )}
    </TableCell>
  )
}

const ClientCashFlowRow = ({
  isLoading,
  index,
  field,
  headers,
  summary,
  data,
  activeItem,
  activeItems,
  onSelectRow,
}: {
  isLoading: boolean
  index: number
  field: string
  headers: string[]
  summary: { [key in FinancialsType]: IClientCashFlow } | {}
  data: IClientCashFlow[]
  activeItem: number
  activeItems: number[]
  onSelectRow: (event: any, index: number) => void
}) => {
  const handleSelectRow = useCallback((event) => onSelectRow(event, index), [index, onSelectRow])

  if (CLIENT_CASH_FLOW_HEADER_FIELDS.includes(field)) {
    return (
      <TableRow className={styles.headerRow}>
        <TableCell />
        <TableCell className={genericSs.tableTextLeft}>
          {CLIENT_CASH_FLOW_FIELDS_LABELS[field]}
        </TableCell>

        {Object.keys(summary).map((option) => (
          <TableCell key={option} className={genericSs.tableTextRight}>
            {isLoading && <Skeleton width={100} height={15} />}
          </TableCell>
        ))}
        {headers.map((header) => (
          <TableCell key={header}>{isLoading && <Skeleton width={100} height={15} />}</TableCell>
        ))}
        {headers.length === 0 && (
          <TableCell className={genericSs.tableTextRight}>
            {isLoading && <Skeleton width={100} height={15} />}
          </TableCell>
        )}
      </TableRow>
    )
  }

  return (
    <TableRow
      className={cn('activableRow', {
        activeRow: activeItems.includes(index),
        currentActiveRow: activeItem === index,
        [styles.highlightedRow]: CLIENT_CASH_FLOW_HIGHLIGHTED_FIELDS.includes(field),
        [styles.indentedRow]: CLIENT_CASH_FLOW_INDENT_FIELDS.includes(field),
      })}
      data-index={index}
      onClick={handleSelectRow}
    >
      <TableCell
        style={{
          zIndex: 2,
        }}
      />
      <TableCell className={genericSs.tableTextLeft}>
        {CLIENT_CASH_FLOW_FIELDS_LABELS[field]}
      </TableCell>

      {Object.keys(summary).map((option) => (
        <ClientCashFlowCell
          key={option}
          isLoading={isLoading}
          field={field}
          data={summary[option]}
        />
      ))}
      {headers.map((header, index) => (
        <ClientCashFlowCell key={header} isLoading={isLoading} field={field} data={data?.[index]} />
      ))}
      {headers.length === 0 && (
        <TableCell className={genericSs.tableTextRight}>
          {isLoading && <Skeleton width={100} height={15} />}
        </TableCell>
      )}
    </TableRow>
  )
}

interface IProps {
  isLoading: boolean
  headers: string[]
  summary: { [key in FinancialsType]: IClientCashFlow } | {}
  data: IClientCashFlow[]
  numberOfMonths: number
  activeItem: number
  activeItems: number[]
  setActiveItem: (indexes: number) => void
  setActiveItems: (index: number[]) => void
  handleSelectRow: (event: any, index: number) => void
}

const ClientCashFlowTable = ({
  isLoading,
  headers,
  summary,
  data,
  numberOfMonths,
  activeItem,
  activeItems,
  setActiveItem,
  setActiveItems,
  handleSelectRow,
}: IProps) => {
  const totalRow = useMemo(
    () =>
      CLIENT_CASH_FLOW_FIELDS.filter((_: string, index: number) => activeItems.includes(index))
        .filter((field) => !CLIENT_CASH_FLOW_NUMBER_FIELDS.includes(field))
        .reduce((result: number[], field: string) => {
          if (!result.length) {
            result[0] = 0
            headers.forEach((header, columnIndex) => {
              result[columnIndex + 1] = 0
            })
          }

          result[0] += summary[field] || 0
          headers.forEach((header, columnIndex) => {
            result[columnIndex + 1] += data[columnIndex][field] || 0
          })

          return result
        }, []),
    [headers, activeItems, summary, data],
  )

  return (
    <TableContainer
      className={cn(styles.table, {
        [styles.tableWithMultipleSummary]: Object.keys(summary).length > 1,
        [styles.tableCellAutoWidth]: headers.length < 6,
      })}
      onActiveRowsChange={setActiveItems}
      onActiveRowChange={setActiveItem}
      id="clientCashFlowTable"
      coupledTables={coupledTables}
      scrollLeft
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell />
            {Object.keys(summary).map((option) => (
              <TableCell key={option} className={genericSs.tableTextRight}>
                {option === FinancialsType.Actuals ? 'L' : 'N'}
                {numberOfMonths}M Summary
              </TableCell>
            ))}

            {headers.map((header) => (
              <TableCell
                key={header}
                className={cn(genericSs.tableTextRight, {
                  [styles.tableCellProjection]: header.includes('Proj'),
                })}
              >
                {header}
              </TableCell>
            ))}
            {headers.length === 0 && <TableCell />}
          </TableRow>
        </TableHead>
        <TableBody>
          {CLIENT_CASH_FLOW_FIELDS.map((field, index) => (
            <ClientCashFlowRow
              key={field}
              isLoading={isLoading}
              index={index}
              field={field}
              headers={headers}
              summary={summary}
              data={data}
              activeItem={activeItem}
              activeItems={activeItems}
              onSelectRow={handleSelectRow}
            />
          ))}
          <MultiselectRow activeItems={activeItems}>
            {totalRow.map((value, index) => (
              <TableCell key={index} className={genericSs.tableTextRight}>
                <span
                  className={cn(styles.amountHolder, {
                    [styles.amountHolderNegative]: value < 0,
                  })}
                >
                  ${formatPrice(value)}
                </span>
              </TableCell>
            ))}
          </MultiselectRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ClientCashFlowTable
