import Base from './base'

export default class LoanBalance extends Base {
  exportTransactions(params: object) {
    return this.apiClient.post('loan-balances/transactions', params, { isFile: true })
  }

  getAverageLoanBalance(params: object) {
    return this.apiClient.post('loan-balances/average-loan-balance', params, { isFile: true })
  }

  recalculateLoanBalance(params: object) {
    return this.apiClient.post('loan-balances/recalculate', params)
  }

  getLoanBalanceStartDate() {
    return this.apiClient.get('loan-balances/start-date')
  }

  exportClientFeeStatement(params: object) {
    return this.apiClient.post('loan-balances/client-fee-statement', params, { isFile: true })
  }

  exportIneligibles(params: object) {
    return this.apiClient.post('loan-balances/ineligibles-export', params, { isFile: true })
  }

  getLatestEndOfMonthFlow() {
    return this.apiClient.get('loan-balances/end-of-month-flow')
  }

  exportEndOfMonthFlow() {
    return this.apiClient.post('loan-balances/end-of-month-flow', {}, { isFile: true })
  }

  applyEndOfMonthFlow(params: object) {
    return this.apiClient.put('loan-balances/end-of-month-flow', params)
  }
}
