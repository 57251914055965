import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { useParams } from 'react-router'
import { Link, generatePath } from 'react-router-dom'
import { Form } from 'react-final-form'
import InfiniteScroll from 'react-infinite-scroll-component'
import Tooltip from '@mui/material/Tooltip'
import LinkButton from '@mui/material/Link'
import cn from 'classnames'
import styles from './EntityReportSummaryTable.module.scss'
import genericSs from '@styles/generic.module.scss'
import MultiselectRow from '../MultiselectRow'
import Card from '../Common/Card'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableContainer from '../Common/TableContainer'
import TableBody from '../Common/TableBody'
import TableFooter from '../Common/TableFooter'
import {
  IEntityInfo,
  IEntityReportStatsData,
  RelatedEntityType,
} from '@common/interfaces/entityInfo'
import { debounceEventHandler, formatPrice, formatPercent } from '../../helpers/helpers'
import TableFiltersRow from '../Common/TableFiltersRow'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import { ENTITY_REPORT_SUMMARY_LIST_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import { ROUTES } from '../../constants/routes'
import FilterContainer from '../Filters/FilterContainer'
import { ILoadingData } from '../../redux/types'
import TableLoader from '../Common/TableLoader'
import EntitySummaryDetailTable from '../EntityReportSummaryDetailsTable'
import { ExpandDetailIcon } from '../Common/Icons'
import SelectField from '../Common/SelectField'
import { IDebtorCategories } from '@common/interfaces/bbc'
import useSummaryRow from '../../hooks/useSummaryRow'
import useTable from '../../hooks/useTable'

interface IProps {
  entityArSummaryData: ILoadingData<IEntityReportStatsData>
  entityApSummaryData: ILoadingData<IEntityReportStatsData>
  getEntityArSummary: (id: string, params: object) => void
  getEntityApSummary: (id: string, params: object) => void
  reportType: 'AR' | 'AP'
  entityInfo: IEntityInfo
  debtorCategories: ILoadingData<IDebtorCategories>
}

const filtersValidate = buildFiltersValidateSchema(ENTITY_REPORT_SUMMARY_LIST_FILTERS_CONFIG)
const filtersDefaults = buildFiltersDefaults(ENTITY_REPORT_SUMMARY_LIST_FILTERS_CONFIG)

const EntitySummaryTable = ({
  entityArSummaryData,
  entityApSummaryData,
  getEntityArSummary,
  getEntityApSummary,
  reportType,
  entityInfo,
  debtorCategories,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const [expanded, setExpanded] = useState([])
  const [relatedEntities, setRelatedEntities] = useState('')
  const isAR = useMemo(() => reportType === 'AR', [reportType])

  const { isLoading, entityReportSummary } = useMemo(
    () => ({
      isLoading: isAR ? entityArSummaryData.isLoading : entityApSummaryData.isLoading,
      entityReportSummary: isAR ? entityArSummaryData.data : entityApSummaryData.data,
    }),
    [entityArSummaryData, entityApSummaryData, isAR],
  )

  const {
    filters,
    handleFiltersChange,
    handleOrderChange,
    orderBy,
    activeItem,
    activeItems,
    handleSelectRow,
    setActiveItem,
    setActiveItems,
  } = useTable({
    tableId: 'entityReportSummary',
    filtersDefaults,
    sortDefault: {
      field: 'total',
      direction: 'DESC',
    },
  })

  const debtorCategoriesOptions = useMemo(
    () =>
      (debtorCategories.data?.categories || []).map((category) => ({
        label: category,
        value: category,
      })),
    [debtorCategories],
  )

  const filtersConfig = useMemo(() => {
    let finalConfig = ENTITY_REPORT_SUMMARY_LIST_FILTERS_CONFIG
    if (!isAR) {
      finalConfig = finalConfig.filter(
        ({ field }) => !['ineligible', 'dso', 'totalExposure', 'category'].includes(field),
      )
    }
    return finalConfig.map((item) => ({
      ...item,
      title: item.field === 'total' ? `Total ${reportType}` : item.title,
      options:
        item.field === 'clientName'
          ? entityReportSummary?.data.map(({ clientName }) => ({
              value: clientName,
              label: clientName,
            }))
          : item.field === 'invoice'
          ? [
              ...new Set(
                entityReportSummary?.data
                  .map(({ rows }) => (rows || []).map(({ invoice }) => invoice))
                  .flat()
                  .filter(Boolean),
              ),
            ].map((invoice) => ({
              value: invoice,
              label: invoice,
            }))
          : item.field === 'category'
          ? debtorCategoriesOptions
          : item.options,
    }))
  }, [entityReportSummary, reportType, isAR, debtorCategoriesOptions])

  const debounceListEntityReportSummary = useMemo(
    () =>
      debounceEventHandler(
        (data: any) =>
          (isAR ? getEntityArSummary : getEntityApSummary)(id, {
            ...data,
            relatedEntities,
          }),
        500,
      ),
    [id, isAR, getEntityArSummary, getEntityApSummary, relatedEntities],
  )

  useEffect(() => {
    debounceListEntityReportSummary({
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
      page: 0,
      perPage: PER_PAGE,
    })
  }, [orderBy, filters, debounceListEntityReportSummary])

  const loadMore = useCallback(() => {
    debounceListEntityReportSummary({
      loadMore: true,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
      page: Math.ceil(entityReportSummary?.data.length / PER_PAGE),
      perPage: PER_PAGE,
    })
  }, [orderBy, filters, debounceListEntityReportSummary, entityReportSummary])

  const totalRow = useSummaryRow(entityReportSummary?.data, activeItems, {
    sumFields: [
      '_1To_30Days',
      '_31To_60Days',
      '_61To_90Days',
      '_91PlusDays',
      'ineligible',
      'total',
      'totalExposure',
    ],
    percentageFields: [
      {
        field: 'total',
        totalField: 'total',
        decimals: 2,
        total: entityReportSummary?.totals.total,
      },
    ],
  })

  const handleExpand = useCallback((label: string) => {
    setExpanded((values) =>
      values.includes(label) ? values.filter((item) => item !== label) : [...values, label],
    )
  }, [])

  const relatedEntitiesOptions = useMemo(() => {
    if (reportType !== 'AR' || !entityInfo) {
      return []
    }

    const options = []
    if (entityInfo.subsidiaries?.length > 0) {
      options.push({
        value: RelatedEntityType.Subsidiaries,
        label: 'Subsidiaries',
      })
    }

    if (entityInfo.parent) {
      options.push({
        value: RelatedEntityType.All,
        label: 'All related entities',
      })
    }

    if (options.length) {
      options.unshift({
        value: '',
        label: 'None',
      })
    }

    return options
  }, [entityInfo, reportType])

  const handleRelatedEntitiesChange = useCallback(({ target: { value } }) => {
    setRelatedEntities(value)
  }, [])

  return (
    <Card noHeaderMargin>
      <TableContainer
        className={cn(styles.table, isAR ? styles.arTable : styles.apTable)}
        onActiveRowsChange={setActiveItems}
        onActiveRowChange={setActiveItem}
        hasFooter
      >
        <Form
          validate={filtersValidate}
          onSubmit={handleFiltersChange}
          initialValues={filters}
          mutators={{
            setFieldData: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value)
            },
          }}
          render={({ values, handleSubmit, form: { mutators } }) => (
            <FilterContainer
              filters={filtersConfig}
              handleSubmit={handleSubmit}
              mutators={mutators}
              values={values}
              appliedFilters={filters}
              title={`${reportType} Summary`}
              actions={
                isAR &&
                relatedEntitiesOptions.length > 0 && (
                  <SelectField
                    label="Related entities"
                    variant="outlined"
                    className={styles.selectField}
                    useFinalForm={false}
                    name="groupBy"
                    options={relatedEntitiesOptions}
                    onChange={handleRelatedEntitiesChange}
                    value={relatedEntities}
                    defaultValue=""
                    withTopLabel
                  />
                )
              }
            />
          )}
        />
        <Table>
          <TableHead>
            <TableFiltersRow
              filters={filtersConfig}
              orderBy={orderBy}
              handleOrderChange={handleOrderChange}
              isChildrenAtStart
            >
              <TableCell />
            </TableFiltersRow>
          </TableHead>
          <TableBody id="scrollableEntitySummaryTable">
            {isLoading ? (
              <TableLoader columnsCount={filtersConfig.length + 1} height={24} />
            ) : (
              <InfiniteScroll
                dataLength={entityReportSummary?.data?.length || 0}
                next={loadMore}
                hasMore={
                  entityReportSummary?.data.length < entityReportSummary?.data?.[0]?.totalCount
                }
                loader={
                  <TableLoader columnsCount={filtersConfig.length + 1} rowsCount={1} height={24} />
                }
                scrollableTarget="scrollableEntitySummaryTable"
              >
                {entityReportSummary?.data.map((item, index) => {
                  const isExpanded = expanded.includes(item.clientName)

                  return (
                    <React.Fragment key={item.clientName && '-' && reportType}>
                      <TableRow
                        data-index={index}
                        key={index}
                        className={cn('activableRow', {
                          activeRow: activeItems.includes(index),
                          currentActiveRow: activeItem === index,
                        })}
                        onClick={(event) => handleSelectRow(event, index)}
                      >
                        <TableCell className={genericSs.tableTextCenter}>
                          {item.invoiceCount > 0 && (
                            <ExpandDetailIcon
                              onClick={() => handleExpand(item.clientName)}
                              isExpanded={isExpanded}
                            />
                          )}
                        </TableCell>
                        <TableCell className={genericSs.tableTextLeft}>
                          <Tooltip
                            title={item.clientName || ''}
                            placement="top"
                            disableHoverListener={item.clientName?.length < 20 || !item.clientName}
                            disableTouchListener
                          >
                            <LinkButton
                              component={Link}
                              to={generatePath(ROUTES.CLIENT_PAGE, {
                                id: item.clientId,
                              })}
                            >
                              {item.clientName}
                            </LinkButton>
                          </Tooltip>
                        </TableCell>

                        <TableCell className={genericSs.tableTextRight}>
                          <span className={genericSs.pricePrefix}>$</span>
                          {formatPrice(item._1To_30Days)}
                        </TableCell>
                        <TableCell className={genericSs.tableTextRight}>
                          <span className={genericSs.pricePrefix}>$</span>
                          {formatPrice(item._31To_60Days)}
                        </TableCell>
                        <TableCell className={genericSs.tableTextRight}>
                          <span className={genericSs.pricePrefix}>$</span>
                          {formatPrice(item._61To_90Days)}
                        </TableCell>
                        <TableCell className={genericSs.tableTextRight}>
                          <span className={genericSs.pricePrefix}>$</span>
                          {formatPrice(item._91PlusDays)}
                        </TableCell>
                        <TableCell className={genericSs.tableTextRight}>
                          {formatPercent(item._91PlusDaysPercent)}
                        </TableCell>
                        {isAR && (
                          <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                            <span className={genericSs.pricePrefix}>$</span>
                            {formatPrice(item.ineligible)}
                          </TableCell>
                        )}
                        <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                          {item.averageTerms?.toFixed(2) || 0}
                        </TableCell>
                        {isAR && (
                          <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                            {item.dso?.toFixed(2) || 0}
                          </TableCell>
                        )}
                        <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                          <span className={genericSs.pricePrefix}>$</span>
                          {formatPrice(item.total)}
                        </TableCell>
                        <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                          {(item.percent * 100 || 0).toFixed(2)}%
                        </TableCell>
                        {isAR && (
                          <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                            <span className={genericSs.pricePrefix}>$</span>
                            {formatPrice(item.totalExposure)}
                          </TableCell>
                        )}
                      </TableRow>
                      {isExpanded && (
                        <TableRow>
                          <TableCell
                            className={genericSs.nestedRowColumn}
                            colSpan={filtersConfig.length + 1}
                          >
                            <EntitySummaryDetailTable
                              rows={item.rows}
                              reportType={reportType}
                              clientName={item.clientName}
                              filters={filters}
                              relatedEntities={relatedEntities}
                            />
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  )
                })}
              </InfiniteScroll>
            )}
            <MultiselectRow activeItems={activeItems}>
              <TableCell className={genericSs.tableTextRight}>
                <span className={genericSs.pricePrefix}>$</span>
                {formatPrice(totalRow?._1To_30Days)}
              </TableCell>
              <TableCell className={genericSs.tableTextRight}>
                <span className={genericSs.pricePrefix}>$</span>
                {formatPrice(totalRow?._31To_60Days)}
              </TableCell>
              <TableCell className={genericSs.tableTextRight}>
                <span className={genericSs.pricePrefix}>$</span>
                {formatPrice(totalRow?._61To_90Days)}
              </TableCell>
              <TableCell className={genericSs.tableTextRight}>
                <span className={genericSs.pricePrefix}>$</span>
                {formatPrice(totalRow?._91PlusDays)}
              </TableCell>
              {isAR && (
                <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                  <span className={genericSs.pricePrefix}>$</span>
                  {formatPrice(totalRow?.ineligible)}
                </TableCell>
              )}
              <TableCell />
              <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                <span className={genericSs.pricePrefix}>$</span>
                {formatPrice(totalRow?.total)}
              </TableCell>
              <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                {totalRow?.percent}%
              </TableCell>
              {isAR && (
                <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                  <span className={genericSs.pricePrefix}>$</span>
                  {formatPrice(totalRow?.totalExposure)}
                </TableCell>
              )}
            </MultiselectRow>
          </TableBody>
          <TableFooter>
            {isLoading ? (
              <TableLoader columnsCount={filtersConfig.length + 1} rowsCount={2} height={24} />
            ) : (
              <>
                <TableRow>
                  <TableCell className={genericSs.tableTextLeft}>Total</TableCell>
                  <TableCell className={genericSs.tableTextRight}>
                    <span className={genericSs.pricePrefix}>$</span>
                    {formatPrice(entityReportSummary?.totals._1To_30Days)}
                  </TableCell>
                  <TableCell className={genericSs.tableTextRight}>
                    <span className={genericSs.pricePrefix}>$</span>
                    {formatPrice(entityReportSummary?.totals._31To_60Days)}
                  </TableCell>
                  <TableCell className={genericSs.tableTextRight}>
                    <span className={genericSs.pricePrefix}>$</span>
                    {formatPrice(entityReportSummary?.totals._61To_90Days)}
                  </TableCell>
                  <TableCell className={genericSs.tableTextRight}>
                    <span className={genericSs.pricePrefix}>$</span>
                    {formatPrice(entityReportSummary?.totals._91PlusDays)}
                  </TableCell>

                  {isAR && (
                    <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                      <span className={genericSs.pricePrefix}>$</span>
                      {formatPrice(entityReportSummary?.totals.ineligible)}
                    </TableCell>
                  )}
                  <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                    {(+entityReportSummary?.totals.averageTerms || 0).toFixed(2)}
                  </TableCell>
                  {isAR && (
                    <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                      {entityReportSummary?.totals.dso?.toFixed(2)}
                    </TableCell>
                  )}
                  <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                    <span className={genericSs.pricePrefix}>$</span>
                    {formatPrice(entityReportSummary?.totals.total)}
                  </TableCell>
                  <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                    100%
                  </TableCell>
                  {isAR && (
                    <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                      <span className={genericSs.pricePrefix}>$</span>
                      {formatPrice(entityReportSummary?.totals.totalExposure)}
                    </TableCell>
                  )}
                </TableRow>
                <TableRow>
                  <TableCell className={genericSs.tableTextLeft}>% of Total</TableCell>
                  <TableCell className={genericSs.tableTextRight}>
                    {(entityReportSummary?.totals._1To_30DaysPercent || 0)?.toFixed(2)}%
                  </TableCell>
                  <TableCell className={genericSs.tableTextRight}>
                    {(entityReportSummary?.totals._31To_60DaysPercent || 0)?.toFixed(2)}%
                  </TableCell>
                  <TableCell className={genericSs.tableTextRight}>
                    {(entityReportSummary?.totals._61To_90DaysPercent || 0)?.toFixed(2)}%
                  </TableCell>
                  <TableCell className={genericSs.tableTextRight}>
                    {(entityReportSummary?.totals._91PlusDaysPercent || 0)?.toFixed(2)}%
                  </TableCell>
                  {isAR && (
                    <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                      {(entityReportSummary?.totals.ineligiblePercent || 0)?.toFixed(2) || 0}%
                    </TableCell>
                  )}
                  <TableCell className={cn(genericSs.tableTextRight, styles.totals)} />
                  {isAR && <TableCell className={cn(genericSs.tableTextRight, styles.totals)} />}
                  <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                    100%
                  </TableCell>
                  <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                    100%
                  </TableCell>
                  {isAR && (
                    <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                      100%
                    </TableCell>
                  )}
                </TableRow>
              </>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
    </Card>
  )
}

export default EntitySummaryTable
