import Base from './base'

export default class Treasury extends Base {
  listTreasury(params: object) {
    return this.apiClient.get('treasury', params)
  }

  listTreasuryFundingWires(params: object) {
    return this.apiClient.get('treasury/funding-wires', params)
  }

  listTreasuryPassthroughWires(params: object) {
    return this.apiClient.get('treasury/passthrough-wires', params)
  }

  listTreasuryParticipationWires(params: object) {
    return this.apiClient.get('treasury/participation-wires', params)
  }

  listTreasuryOtherWires(params: object) {
    return this.apiClient.get('treasury/other-wires', params)
  }

  updateTreasury(params: object) {
    return this.apiClient.put(`treasury`, params)
  }

  createTreasury(params: object) {
    return this.apiClient.post(`treasury`, params)
  }
}
