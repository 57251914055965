import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { FormApi } from 'final-form'
import Button from '@mui/material/Button'
import queryString from 'query-string'

import styles from './ClientIntakePage.module.scss'

import { ReactComponent as SuccessLogo } from '@assets/images/success-logo.svg'
import { ClientERPSystem } from '@common/interfaces/client'
import { CLIENT_INTAKE_HEADINGS, CLIENT_INTAKE_STEPS } from '@common/constants/client'
import Card from '../../components/Common/Card'
import ClientIntakeStepper from '../../components/ClientIntake/Stepper'
import ClientIntakeHeader from '../../components/ClientIntake/Header'
import ClientIntakeGetStartedForm from '../../components/ClientIntake/GetStartedForm'
import ClientIntakeCompanyInfoForm from '../../components/ClientIntake/CompanyInfoForm'
import ClientIntakePeopleAndToolsForm from '../../components/ClientIntake/PeopleAndToolsForm'
import ClientIntakeAppIntegrations from '../../components/ClientIntake/AppIntegrations'
import ClientIntakeDocuments from '../../components/ClientIntake/Documents'
import { useBeforeUnload } from '../../hooks/useBeforeUnload'
import ClientIntakePageLoader from './ClientIntakePageLoader'
import { usePrevious } from '../../hooks/usePrevious'
import { IOPSReporting, OPSReportingStatus } from '@common/interfaces/prospects'

interface IProps {
  isSaving: boolean
  opsReporting: IOPSReporting
  start: (id: string) => void
  loadVendors: (id: string, data: { type: string; search: string }) => Promise<any>
  getCodatLink: (id: string) => void
  saveContact: (id: string, data: object) => Promise<any>
  saveCompanyInfo: (id: string, data: object) => Promise<any>
  savePeopleAndTools: (id: string, data: object) => Promise<any>
  uploadDocument: (id: string, data: FormData) => void
  deleteDocument: (id: string, fileId: string) => void
  submit: (id: string) => void
}

const ClientIntakePage = ({
  isSaving,
  opsReporting,
  start,
  loadVendors,
  getCodatLink,
  saveContact,
  saveCompanyInfo,
  savePeopleAndTools,
  uploadDocument,
  deleteDocument,
  submit,
}: IProps) => {
  const history = useHistory()
  const location = useLocation()
  const { search } = location

  const { page, integrations, name, email } = queryString.parse(search, {
    parseNumbers: true,
    parseBooleans: true,
  }) as { page: number; integrations: boolean; name?: string; email?: string }
  const { salesforceId } = useParams<{
    salesforceId?: string
  }>()
  const [step, setStep] = useState(CLIENT_INTAKE_STEPS.GET_STARTED)
  const [isDirty, setIsDirty] = useState(false)
  const [isAppIntegrations, setIsAppIntegrations] = useState(false)
  const [isAppIntegrationsSkipped, setIsAppIntegrationsSkipped] = useState(false)
  const [editAgain, setEditAgain] = useState(false)
  const previousPage = usePrevious<number>(page)
  const previousIntegrations = usePrevious<boolean>(integrations)

  const formRef: React.MutableRefObject<FormApi<any, Partial<any>>> = useRef(null)
  const codatWindow = useRef(null)
  const nextStep = useRef(null)

  const [currentName, setCurrentName] = useState(name)
  const [currentEmail, setCurrentEmail] = useState(email)

  const emailLink = useMemo(() => {
    if (!currentEmail || !currentName) {
      return null
    }
    const intakeLink = `https://app.dwightfunding.com/client-intake/${salesforceId}`

    const subject = ' Dwight Funding - Information Request'
    const firstName = currentName?.split(' ')[0]
    const body = `Hi ${firstName},\n\nWe're excited for the opportunity to work with you on this.\n\nAs promised, please find a link to your data room here: ${intakeLink}. Please upload all docs in Monthly format (rather than Quarterly or Annual summaries) in Excel. Thank you!\n\nFeel free to reach out if you have any questions about any of the requests.\n\nWe look forward to reviewing!`
    const url = `https://mail.google.com/mail/u/0/?fs=1&to=${encodeURIComponent(
      currentEmail,
    )}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}&tf=cm`
    return url
  }, [currentEmail, currentName, salesforceId])

  useEffect(() => {
    if (
      salesforceId &&
      (!opsReporting?.clientInfo?.id ||
        (opsReporting.clientInfo.opportunityId !== salesforceId &&
          opsReporting.clientInfo.accountId !== salesforceId &&
          opsReporting.clientInfo.leadId !== salesforceId))
    ) {
      start(salesforceId)
    }
  }, [start, opsReporting, salesforceId])

  useEffect(() => {
    if (emailLink) {
      const newWindow = window.open(emailLink, 'Send prospect email', 'height=600,width=600')
      newWindow && newWindow.focus()
      setCurrentEmail(null)
      setCurrentName(null)
    }
  }, [emailLink, history, step, isAppIntegrations])

  useEffect(() => {
    if (!previousPage?.toString() && page?.toString() && page !== step) {
      setStep(page)
    }
  }, [previousPage, page, step])

  useEffect(() => {
    if (
      !previousIntegrations?.toString() &&
      integrations?.toString() &&
      integrations !== isAppIntegrations
    ) {
      setIsAppIntegrations(integrations)
    }
  }, [previousIntegrations, integrations, isAppIntegrations])

  useEffect(() => {
    let data: {
      page: number
      integrations: boolean
      name?: string
      email?: string
    } = {
      page: step,
      integrations: isAppIntegrations,
    }
    if (currentName) {
      data = { ...data, name: currentName }
    }
    if (currentEmail) {
      data = { ...data, email: currentEmail }
    }
    history.replace({
      search: queryString.stringify(data),
    })
  }, [step, isAppIntegrations, history, currentName, currentEmail])

  const handleGetCodatLink = useCallback(() => {
    if (opsReporting?.clientInfo?.isErpConnected) {
      setIsAppIntegrations(false)
      codatWindow.current = null
    } else if (
      opsReporting?.clientInfo?.codatLink === undefined &&
      (opsReporting?.clientInfo?.erpSystem.includes(ClientERPSystem.Netsuite) ||
        opsReporting?.clientInfo?.erpSystem.includes(ClientERPSystem.QuickbooksOnline))
    ) {
      getCodatLink(salesforceId)
    }
  }, [opsReporting, getCodatLink, salesforceId])

  useEffect(() => {
    if (step === CLIENT_INTAKE_STEPS.DOCUMENTATION) {
      handleGetCodatLink()
    }
  }, [step, handleGetCodatLink])

  useBeforeUnload(isDirty)

  const handleGoBack = useCallback(
    async (data: any) => {
      if (![CLIENT_INTAKE_STEPS.PEOPLE, CLIENT_INTAKE_STEPS.DOCUMENTATION].includes(step)) {
        return
      }
      if (step === CLIENT_INTAKE_STEPS.PEOPLE) {
        await savePeopleAndTools(salesforceId, data)
        setIsAppIntegrations(false)
      }
      setStep((currentStep) => currentStep - 1)
      setIsDirty(false)
      codatWindow.current = null
    },
    [step, salesforceId, savePeopleAndTools],
  )

  const handleSubmit = useCallback(
    async (data: any) => {
      let result
      if (step === CLIENT_INTAKE_STEPS.GET_STARTED) {
        result = await saveContact(salesforceId, data)
      }
      if (step === CLIENT_INTAKE_STEPS.COMPANY_INFO) {
        result = await saveCompanyInfo(salesforceId, data)
      }
      if (step === CLIENT_INTAKE_STEPS.PEOPLE) {
        result = await savePeopleAndTools(salesforceId, data)
        if (
          !result.data?.clientInfo?.isErpConnected &&
          (result.data?.clientInfo?.erpSystem?.includes(ClientERPSystem.Netsuite) ||
            result.data?.clientInfo?.erpSystem?.includes(ClientERPSystem.QuickbooksOnline))
        ) {
          setIsAppIntegrations(true)
        } else {
          setIsAppIntegrations(false)
        }
      }
      if (step === CLIENT_INTAKE_STEPS.DOCUMENTATION) {
        await submit(salesforceId)
        setEditAgain(false)
      }
      if (!!result?.data) {
        setStep((currentStep) => (nextStep.current !== null ? nextStep.current : currentStep + 1))
        nextStep.current = null
        setIsDirty(false)
      }
    },
    [step, salesforceId, saveContact, saveCompanyInfo, savePeopleAndTools, submit],
  )

  const handleStepChange = useCallback(
    (selectedStep: number) => {
      if (!isDirty || !formRef.current) {
        setStep(selectedStep)
      } else {
        nextStep.current = selectedStep
        formRef.current.submit()
      }
    },
    [isDirty],
  )

  const handleSkipAppIntegrations = useCallback(() => {
    setIsAppIntegrations(false)
    setIsAppIntegrationsSkipped(true)
    codatWindow.current = null
  }, [])

  const handleAppIntegrations = useCallback(async () => {
    if (opsReporting?.clientInfo?.codatLink) {
      const url = new URL(window.location.href)
      url.searchParams.set('integrations', 'false')

      await sessionStorage.setItem('previousPage', url.toString())

      codatWindow.current = window.open(opsReporting?.clientInfo.codatLink, '_self')
    }
  }, [opsReporting?.clientInfo?.codatLink])

  useEffect(() => {
    const onFocus = () => {
      if (step === CLIENT_INTAKE_STEPS.DOCUMENTATION && isAppIntegrations && codatWindow.current) {
        start(salesforceId)
      }
    }

    window.addEventListener('focus', onFocus)

    return () => {
      window.removeEventListener('focus', onFocus)
    }
  }, [step, isAppIntegrations, start, salesforceId])

  const handleLoadVendors = useCallback(
    async (type: string, search: string) =>
      loadVendors(salesforceId, {
        type,
        search,
      }),
    [salesforceId, loadVendors],
  )

  const handleUploadDocument = useCallback(
    (type: string, files: File[]) => {
      const formData = new FormData()

      formData.append('type', type)
      for (const file of files) {
        formData.append('files[]', file, file.name)
      }

      return uploadDocument(salesforceId, formData)
    },
    [salesforceId, uploadDocument],
  )

  const handleDeleteDocument = useCallback(
    (fileId: string) => deleteDocument(salesforceId, fileId),
    [salesforceId, deleteDocument],
  )

  const handleEditResponses = useCallback(() => {
    setEditAgain(true)
  }, [])

  const showConnectToAutoReporting = useMemo(
    () =>
      [ClientERPSystem.Netsuite, ClientERPSystem.QuickbooksOnline].some((erp) =>
        opsReporting?.clientInfo?.erpSystem?.includes(erp),
      ) &&
      !opsReporting?.clientInfo?.isErpConnected &&
      !!opsReporting?.clientInfo?.codatLink,

    [opsReporting],
  )

  if (!opsReporting?.clientInfo) {
    return <ClientIntakePageLoader />
  }

  if (opsReporting.status === OPSReportingStatus.Archived) {
    return (
      <div className={styles.clientIntakeWrapper}>
        <div className={styles.clientIntakeSuccessCard}>
          <SuccessLogo />
          <div className={styles.clientIntakeSuccessTitle}>Submitted</div>
          <div className={styles.clientIntakeSuccessDescription}>
            Thank you! We'll take a look and loop back with any questions.
          </div>
        </div>
      </div>
    )
  }

  if (opsReporting.clientIntakeSubmittedAt && !editAgain) {
    return (
      <div className={styles.clientIntakeWrapper}>
        <div className={styles.clientIntakeSuccessCard}>
          <SuccessLogo />
          <div className={styles.clientIntakeSuccessTitle}>Submitted</div>
          <div className={styles.clientIntakeSuccessDescription}>
            Thank you! We'll take a look and loop back with any questions. Come back at any time
            using the original link.
          </div>
          <Button
            className={styles.clientIntakeSuccessButton}
            onClick={handleEditResponses}
            variant="outlined"
          >
            Edit responses
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.clientIntakeWrapper}>
      <Card
        noHeaderMargin
        className={styles.clientIntakeCard}
        classes={{ content: styles.clientIntakeCardContent }}
      >
        {step !== CLIENT_INTAKE_STEPS.GET_STARTED && (
          <ClientIntakeStepper activeStep={step - 1} handleStepChange={handleStepChange} />
        )}
        {step === CLIENT_INTAKE_STEPS.DOCUMENTATION &&
        isAppIntegrations &&
        !isAppIntegrationsSkipped ? (
          <ClientIntakeHeader
            title="App integrations"
            description="Shorten your request list by authorizing Dwight to pull reports on your behalf"
          />
        ) : (
          <ClientIntakeHeader
            title={CLIENT_INTAKE_HEADINGS[step].title}
            description={CLIENT_INTAKE_HEADINGS[step].description}
          />
        )}
        {step === CLIENT_INTAKE_STEPS.GET_STARTED && (
          <ClientIntakeGetStartedForm
            isSaving={isSaving}
            opsReporting={opsReporting}
            handleSubmit={handleSubmit}
            setIsDirty={setIsDirty}
          />
        )}
        {step === CLIENT_INTAKE_STEPS.COMPANY_INFO && (
          <ClientIntakeCompanyInfoForm
            isSaving={isSaving}
            opsReporting={opsReporting}
            handleSubmit={handleSubmit}
            setIsDirty={setIsDirty}
          />
        )}
        {step === CLIENT_INTAKE_STEPS.PEOPLE && (
          <ClientIntakePeopleAndToolsForm
            isSaving={isSaving}
            opsReporting={opsReporting}
            handleSubmit={handleSubmit}
            handleGoBack={handleGoBack}
            handleLoadVendors={handleLoadVendors}
            setIsDirty={setIsDirty}
            formRef={formRef}
          />
        )}
        {step === CLIENT_INTAKE_STEPS.DOCUMENTATION &&
          (isAppIntegrations && !isAppIntegrationsSkipped ? (
            <ClientIntakeAppIntegrations
              handleGoBack={handleGoBack}
              handleAppIntegrations={handleAppIntegrations}
              handleSkipAppIntegrations={handleSkipAppIntegrations}
            />
          ) : (
            <ClientIntakeDocuments
              isSaving={isSaving}
              opsReporting={opsReporting}
              handleSubmit={handleSubmit}
              handleGoBack={handleGoBack}
              handleUploadDocument={handleUploadDocument}
              handleDeleteDocument={handleDeleteDocument}
              handleAppIntegrations={handleAppIntegrations}
              showConnectToAutoReporting={showConnectToAutoReporting}
            />
          ))}
      </Card>
    </div>
  )
}

export default ClientIntakePage
