import { createRequestTypes } from '../../helpers/helpers'
import { IApi } from '../../api'
import { CommonPromiseAction } from '../types'
import { Feedback } from '@common/interfaces/prospects'
import { Models } from '@common/interfaces/atlasai'

export const prefix = 'atlasai'

// Request types
const ASK_ATLAS_AI = createRequestTypes(prefix, 'ASK_ATLAS_AI')
const LIST_SESSIONS = createRequestTypes(prefix, 'LIST_SESSIONS')
const SELECT_SESSION = createRequestTypes(prefix, 'SELECT_SESSION')
const CREATE_SESSION = createRequestTypes(prefix, 'CREATE_SESSION')
const DELETE_SESSION = createRequestTypes(prefix, 'DELETE_SESSION')
const SUBMIT_FEEDBACK = createRequestTypes(prefix, 'SUBMIT_FEEDBACK')
const LOAD_MORE_HISTORY = createRequestTypes(prefix, 'LOAD_MORE_HISTORY')

export const [ASK_ATLAS_AI_REQUEST, ASK_ATLAS_AI_SUCCESS, ASK_ATLAS_AI_FAILURE] = ASK_ATLAS_AI
export const [LIST_SESSIONS_REQUEST, LIST_SESSIONS_SUCCESS, LIST_SESSIONS_FAILURE] = LIST_SESSIONS
export const [SELECT_SESSION_REQUEST, SELECT_SESSION_SUCCESS, SELECT_SESSION_FAILURE] =
  SELECT_SESSION
export const [CREATE_SESSION_REQUEST, CREATE_SESSION_SUCCESS, CREATE_SESSION_FAILURE] =
  CREATE_SESSION
export const [DELETE_SESSION_REQUEST, DELETE_SESSION_SUCCESS, DELETE_SESSION_FAILURE] =
  DELETE_SESSION
export const [SUBMIT_FEEDBACK_REQUEST, SUBMIT_FEEDBACK_SUCCESS, SUBMIT_FEEDBACK_FAILURE] =
  SUBMIT_FEEDBACK
export const [LOAD_MORE_HISTORY_REQUEST, LOAD_MORE_HISTORY_SUCCESS, LOAD_MORE_HISTORY_FAILURE] =
  LOAD_MORE_HISTORY

// UI action types
export const TOGGLE_CHAT_VISIBILITY = `${prefix}/TOGGLE_CHAT_VISIBILITY`

// Action creators for API calls
export const askAtlasAI: CommonPromiseAction = (params: {
  question: string
  sessionId: string
  modelProvider: Models
}) => ({
  type: ASK_ATLAS_AI,
  promise: (api: IApi) => api.atlasAI.askQuestion(params),
  params,
})

export const listSessions: CommonPromiseAction = (params?: {
  limit?: number
  offset?: number
}) => ({
  type: LIST_SESSIONS,
  promise: (api: IApi) => api.atlasAI.listSessions(params),
  params,
})

export const selectSession: CommonPromiseAction = (sessionId: string) => ({
  type: SELECT_SESSION,
  promise: (api: IApi) => api.atlasAI.getSession(sessionId),
  params: { sessionId },
})

export const createSession: CommonPromiseAction = () => ({
  type: CREATE_SESSION,
  promise: (api: IApi) => api.atlasAI.createSession(),
})

export const deleteSession: CommonPromiseAction = (sessionId: string) => ({
  type: DELETE_SESSION,
  promise: (api: IApi) => api.atlasAI.deleteSession(sessionId),
  params: { sessionId },
})

export const submitFeedback: CommonPromiseAction = (params: {
  messageId: string
  feedbackType: Feedback
  comment?: string
}) => ({
  type: SUBMIT_FEEDBACK,
  promise: (api: IApi) => api.atlasAI.submitFeedback(params),
  params,
})

export const loadMoreHistory: CommonPromiseAction = (
  sessionId: string,
  params: { limit: number; offset: number },
) => ({
  type: LOAD_MORE_HISTORY,
  promise: (api: IApi) => api.atlasAI.getSession(sessionId, params),
  params: { sessionId, ...params },
})

// Add a new action for loading more sessions
export const loadMoreSessions: CommonPromiseAction = (params: {
  limit: number
  offset: number
}) => ({
  type: LIST_SESSIONS,
  promise: (api: IApi) => api.atlasAI.listSessions(params),
  params,
  meta: { loadMore: true },
})

// Action creators for UI state
export const toggleChatVisibility = () => ({
  type: TOGGLE_CHAT_VISIBILITY,
})

export const SET_MODEL_PROVIDER = 'SET_MODEL_PROVIDER'

export const setModelProvider = (modelProvider: Models) => ({
  type: SET_MODEL_PROVIDER,
  modelProvider,
})
