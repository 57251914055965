import React, { useCallback, useEffect, useState } from 'react'
import cn from 'classnames'

import styles from './SelectedFiles.module.scss'

import { IFile, IFileSheet } from '@common/interfaces/box'
import SelectedFilesRow from './SelectedFilesRow'

interface IProps {
  className?: string
  files: IFile[]
  fileSheets: IFileSheet[]
  handleSelectFiles: (
    selectedDocuments: { id?: string; fileId: string; sheetName: string }[],
    isReselect?: boolean,
  ) => void
  handleDeleteFile: (id: string) => void
  disabled?: boolean
  children?: React.ReactNode
  isMultiple?: boolean
}

const SelectedFiles = ({
  className,
  files,
  fileSheets,
  handleSelectFiles,
  handleDeleteFile,
  disabled = false,
  children,
  isMultiple = true,
}: IProps) => {
  const [rows, setRows] = useState<Array<{ file: IFile; fileSheets: IFileSheet[] }>>([])
  const [fileLoading, setFileLoading] = useState({
    id: '',
    sheetName: '',
  })

  const onAddFileSheet = useCallback(
    async (id: string) => {
      if (disabled || !isMultiple) {
        return
      }

      setRows((rows) =>
        rows.map((row) =>
          row.file.id === id
            ? {
                ...row,
                fileSheets: [...row.fileSheets, { fileId: id }],
              }
            : row,
        ),
      )
    },
    [disabled, isMultiple],
  )

  const onSelectFileSheet = useCallback(
    async (id: string, sheetName: string, fileSheetId: string) => {
      setFileLoading({ id, sheetName })
      if (fileSheetId) {
        await handleSelectFiles(
          fileSheets.map((fileSheets) => ({
            id: fileSheets.id,
            fileId: fileSheets.fileId,
            sheetName: fileSheetId === fileSheets.id ? sheetName : fileSheets.sheetName,
            isReselect: fileSheetId === fileSheets.id,
          })),
          true,
        )
      } else {
        await handleSelectFiles([
          ...(isMultiple
            ? fileSheets.map((fileSheets) => ({
                id: fileSheets.id,
                fileId: fileSheets.fileId,
                sheetName: fileSheetId === fileSheets.id ? sheetName : fileSheets.sheetName,
                isReselect: fileSheetId === fileSheets.id,
              }))
            : []),
          {
            fileId: id,
            sheetName: sheetName,
          },
        ])
      }
      setFileLoading({ id: '', sheetName: '' })
    },
    [handleSelectFiles, fileSheets, isMultiple],
  )

  const onReselectFileSheet = useCallback(
    async (id: string, sheetName: string) => {
      if (disabled) {
        return
      }
      setFileLoading({ id, sheetName })
      await handleSelectFiles(
        fileSheets.map(({ id: fileSheetId, fileId, sheetName: fileSheetName }) => ({
          id: fileSheetId,
          fileId,
          sheetName: fileSheetName,
          isReselect: fileId === id && fileSheetName === sheetName,
        })),
        true,
      )
      setFileLoading({ id: '', sheetName: '' })
    },
    [fileSheets, handleSelectFiles, disabled],
  )

  const onClearFileSheet = useCallback(
    async (id: string, sheetName: string, fileSheetId: string) => {
      if (disabled) {
        return
      }

      if (!fileSheetId) {
        setRows((rows) =>
          rows.map((row) =>
            row.file.id === id
              ? {
                  ...row,
                  fileSheets: row.fileSheets.filter(({ sheetName }) => !!sheetName),
                }
              : row,
          ),
        )
        return
      }

      setFileLoading({ id, sheetName })
      await handleSelectFiles(
        fileSheets
          .filter(({ id }) => id !== fileSheetId)
          .map(({ id: fileSheetId, fileId, sheetName: fileSheetName }) => ({
            id: fileSheetId,
            fileId,
            sheetName: fileSheetName,
            isReselect: fileId === id && fileSheetName === sheetName,
          })),
      )
      setFileLoading({ id: '', sheetName: '' })
    },
    [fileSheets, handleSelectFiles, disabled],
  )

  const onDeleteFile = useCallback(
    async (id: string) => {
      if (disabled) {
        return
      }

      handleDeleteFile(id)
    },
    [disabled, handleDeleteFile],
  )

  useEffect(() => {
    setRows(
      (files || [])
        .map((file) => ({
          file,
          fileSheets: (fileSheets || [])
            .filter(({ fileId }) => fileId === file.id)
            .sort((a, b) => +new Date(a.createdAt) - +new Date(b.createdAt)),
        }))
        .sort((a, b) => +new Date(a.file.createdAt) - +new Date(b.file.createdAt)),
    )
  }, [files, fileSheets])

  return (
    <div className={cn(styles.wrapper, className)}>
      <div className={styles.container}>
        {rows.map(({ file, fileSheets }) => (
          <React.Fragment key={file.id}>
            {fileSheets.length > 0 ? (
              fileSheets.map((fileSheet, index) => (
                <SelectedFilesRow
                  key={`${file.id}-${fileSheet.id || index}`}
                  file={{
                    ...file,
                    sheetNames: (file.sheetNames || []).filter(
                      (sheetName) =>
                        !fileSheets
                          .filter(({ id }) => id !== fileSheet.id)
                          .map(({ sheetName }) => sheetName)
                          .includes(sheetName),
                    ),
                  }}
                  fileSheet={fileSheet}
                  isPrimary={!index}
                  handleAddFileSheet={onAddFileSheet}
                  handleSelectFileSheet={onSelectFileSheet}
                  handleReselectFileSheet={onReselectFileSheet}
                  handleClearFileSheet={onClearFileSheet}
                  handleDeleteFile={onDeleteFile}
                  disabled={disabled}
                  isMultiple={isMultiple}
                  fileLoading={fileLoading}
                />
              ))
            ) : (
              <SelectedFilesRow
                key={`${file.id}`}
                file={file}
                fileSheet={{
                  fileId: file.id,
                }}
                isPrimary
                handleAddFileSheet={onAddFileSheet}
                handleSelectFileSheet={onSelectFileSheet}
                handleReselectFileSheet={onReselectFileSheet}
                handleClearFileSheet={onClearFileSheet}
                handleDeleteFile={onDeleteFile}
                disabled={disabled}
                isMultiple={isMultiple}
                fileLoading={fileLoading}
              />
            )}
          </React.Fragment>
        ))}
      </div>
      {children}
    </div>
  )
}

export default SelectedFiles
