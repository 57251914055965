import React, { useEffect, useCallback, useState, useMemo } from 'react'
import { useLocation, useHistory, generatePath } from 'react-router-dom'
import queryString from 'query-string'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import cn from 'classnames'
import styles from './CollectionsMidDayWiresPage.module.scss'
import genericSs from '@styles/generic.module.scss'
import WiresUploads from '../../components/WiresUploads'
import { ReactComponent as HomeIcon } from '@assets/images/home-icon.svg'
import Card from '../../components/Common/Card'
import { IWireDataAggregation, WireDataStatus, IWireTotals } from '@common/interfaces/collection'
import Button from '../../components/Common/Button'
import { formatter, unFormatPrice, formatDate } from '../../helpers/helpers'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'
import { ActivityStatus } from '@common/interfaces/activity'
import TableContainer from '../../components/Common/TableContainer'
import Table from '../../components/Common/Table'
import TableHead from '../../components/Common/TableHead'
import TableBody from '../../components/Common/TableBody'
import TableRow from '../../components/Common/TableRow'
import TableCell from '../../components/Common/TableCell'
import CurrencyField from '../../components/Common/CurrencyField'
import CollectionsWiresMapping from '../../components/CollectionsWiresMapping'
import moment from 'moment'
import { ROUTES } from '../../constants/routes'
import { ActivityType } from '@common/interfaces/activity'
import { historyChangeEventHandler, visibilityChangeEventHandler } from '../../helpers/helpers'
import Breadcrumbs from '../../components/Common/Breadcrumbs'
import { ILoadingData } from '../../redux/types'
import Skeleton from '@mui/material/Skeleton'
import TableLoader from '../../components/Common/TableLoader'

interface IProps {
  isLoading: boolean
  wiresData: ILoadingData<IWireDataAggregation>
  wiresDataNegative: ILoadingData<IWireDataAggregation>
  wiresDataDeleted: ILoadingData<IWireDataAggregation>
  wiresDataTotals: ILoadingData<{ data: IWireTotals }>
  collectionStatus?: string
  uploadWires: (data: FormData) => void
  listNewWires: (params: object) => void
  listDeletedWires: (params: object) => void
  listNegativeWires: (params: object) => void
  saveWires: (data: object) => void
  listWireFilesAndStatus: (id: string) => void
  updateWire: (id: string, data: object) => void
  updateWires: (data: object) => void
  restoreWire: (id: string, data?: object) => void
  deleteWire: (id: string, data: object) => void
  trackActivity: (data: object, beacon?: boolean) => void
}

const CollectionsMidDayWiresPage = ({
  wiresData,
  wiresDataNegative,
  wiresDataDeleted,
  wiresDataTotals,
  collectionStatus,
  uploadWires,
  saveWires,
  listNewWires,
  listDeletedWires,
  listNegativeWires,
  listWireFilesAndStatus,
  updateWire,
  updateWires,
  restoreWire,
  deleteWire,
  trackActivity,
}: IProps) => {
  const { wiresDataLoaded, wiresDataNegativeLoaded, wiresDataDeletedLoaded, isWiresLoading } =
    useMemo(
      () => ({
        wiresDataLoaded: wiresData?.data,
        wiresDataNegativeLoaded: wiresDataNegative?.data,
        wiresDataDeletedLoaded: wiresDataDeleted?.data,
        isWiresLoading: wiresData.isLoading,
      }),
      [wiresData, wiresDataNegative, wiresDataDeleted],
    )

  const { isTotalsLoading, wiresTotalsLoaded } = useMemo(
    () => ({
      isTotalsLoading: wiresDataTotals.isLoading,
      wiresTotalsLoaded: wiresDataTotals?.data?.data,
    }),
    [wiresDataTotals],
  )

  const [totalCollections, setTotalCollections] = useState<number | string>(
    wiresTotalsLoaded?.reconciliationAmount || 0,
  )
  const [isSavingButton, setIsSavingButton] = useState(false)

  const { search, pathname }: { search: string; pathname: string } = useLocation()
  const qs = useMemo(() => queryString.parse(search), [search])
  const history = useHistory()

  const refetchWireFilesAndStatus = useCallback(() => {
    if (qs?.activityId && typeof qs?.activityId === 'string') {
      listWireFilesAndStatus(qs?.activityId)
    }
  }, [qs, listWireFilesAndStatus])

  useEffect(() => {
    refetchWireFilesAndStatus()
  }, [refetchWireFilesAndStatus])

  useEffect(() => {
    setTotalCollections(wiresTotalsLoaded?.reconciliationAmount || 0)
  }, [wiresTotalsLoaded?.reconciliationAmount])

  const handleChangeTotalCollections = useCallback(
    (event) => {
      setTotalCollections(event.target.value)
    },
    [setTotalCollections],
  )
  const wireDate = useMemo(
    () =>
      wiresDataLoaded?.data?.length
        ? formatDate(wiresDataLoaded.data[0].recordDate)
        : wiresDataNegativeLoaded?.data?.length
        ? formatDate(wiresDataNegativeLoaded.data[0].recordDate)
        : wiresDataDeletedLoaded?.data?.length
        ? formatDate(wiresDataDeletedLoaded.data[0].recordDate)
        : '',
    [wiresDataLoaded, wiresDataDeletedLoaded, wiresDataNegativeLoaded],
  )

  useSetPageTitle('Midday wires ' + wireDate)

  const breadcrumbs = useMemo(() => {
    return [
      {
        link: ROUTES.HOMEPAGE,
        Icon: HomeIcon,
      },
      {
        link: ROUTES.ACTIVITY_QUEUE,
        title: 'Operations',
      },
      {
        link: generatePath(ROUTES.COLLECTIONS_WIRES + search),
        title: `Wires ${wireDate}`,
      },
    ]
  }, [wireDate, search])

  const negativeWiresFromMidDay = useMemo(
    () => (wiresDataNegativeLoaded?.data || []).reduce((acc, wire) => acc + wire.paymentAmount, 0),
    [wiresDataNegativeLoaded],
  )

  const wiresPostingTodayMinusNegative = useMemo(
    () => (wiresTotalsLoaded?.wiresPostingToday || 0) - negativeWiresFromMidDay,
    [wiresTotalsLoaded, negativeWiresFromMidDay],
  )

  const totalWireReconciliation =
    Math.round(
      ((wiresTotalsLoaded?.totalWires || 0) -
        ((wiresTotalsLoaded?.wiresPostedYesterday || 0) +
          (wiresTotalsLoaded?.negativeWiresUploadedYesterday || 0) +
          (wiresTotalsLoaded?.wiresPostingToday || 0))) *
        100,
    ) / 100
  const totalCollectionsReconciliation =
    Math.round(
      (unFormatPrice(totalCollections) -
        ((wiresTotalsLoaded?.wiresPostedYesterday || 0) +
          (wiresTotalsLoaded?.negativeWiresPostedYesterday || 0) +
          (wiresPostingTodayMinusNegative || 0) +
          (wiresTotalsLoaded?.checksPostedYesterday || 0))) *
        100,
    ) / 100

  const handleListWires = useCallback(
    async (params: any, type?: WireDataStatus) => {
      switch (type) {
        case WireDataStatus.Negative:
          await listNegativeWires(params)
          break
        case WireDataStatus.New:
          await listNewWires(params)
          break
        case WireDataStatus.Deleted:
          await listDeletedWires(params)
          break
        default:
          break
      }
    },
    [listNegativeWires, listNewWires, listDeletedWires],
  )

  const [startTime, setStartTime] = useState(moment().toISOString())

  const logActivity = useCallback(
    (beacon: boolean = false) => {
      if (qs?.activityId) {
        const endTime = moment().toISOString()
        const data = {
          activityId: qs?.activityId,
          startedAt: startTime,
          finishedAt: endTime,
          workflow: ActivityType.MidDayWire,
          step: 'Mapping',
        }
        trackActivity(data, beacon)
        setStartTime(endTime)
      }
    },
    [qs, startTime, trackActivity],
  )
  useEffect(() => {
    const unlisten = historyChangeEventHandler(logActivity, history, pathname)
    return unlisten
  }, [history, pathname, logActivity])

  useEffect(() => {
    const unlisten = visibilityChangeEventHandler(logActivity)
    return unlisten
  }, [logActivity])

  const handleSubmit = useCallback(async () => {
    setIsSavingButton(true)
    logActivity()
    await saveWires({ activityId: qs.activityId })
    setIsSavingButton(false)
  }, [saveWires, qs, logActivity, setIsSavingButton])

  const mappingProgress = useMemo(
    () =>
      ((wiresDataLoaded?.mappedCount + wiresDataNegativeLoaded?.mappedCount) * 100) /
      (wiresDataLoaded?.totalCount + wiresDataNegativeLoaded?.totalCount),
    [wiresDataLoaded, wiresDataNegativeLoaded],
  )

  return (
    <Box py={1} pr={2}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box mb={2}>
            {!isWiresLoading ? (
              <Breadcrumbs breadcrumbs={breadcrumbs} noMarginLeft />
            ) : (
              <Skeleton height={15} width={300} />
            )}
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            pt={2}
            paddingTop={'15'}
            alignItems="center"
            mb={3}
          >
            <WiresUploads uploadWires={uploadWires} />
            <div className={styles.submitButtonContainer}>
              <Button
                type="button"
                disabled={mappingProgress !== 100}
                color="primary"
                variant="contained"
                size="small"
                onClick={handleSubmit}
                isLoading={isSavingButton}
              >
                {collectionStatus === ActivityStatus.Review ? 'Submit' : 'Submit for review'}
              </Button>
            </div>
          </Box>
        </Grid>

        <CollectionsWiresMapping
          handleListWires={handleListWires}
          updateWire={updateWire}
          updateWires={updateWires}
          restoreWire={restoreWire}
          deleteWire={deleteWire}
        />

        <Grid
          container
          xs={12}
          marginBottom={'24px'}
          justifyContent={'space-between'}
          paddingLeft={'24px'}
        >
          <Grid item xs={5.5}>
            <Card noHeaderMargin title={'Total wire reconciliation'}>
              <TableContainer className={styles.wiresReconciliationTable}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={genericSs.tableTextLeft}>Account</TableCell>
                      <TableCell className={genericSs.tableTextRight}>Total deposit</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isTotalsLoading ? (
                      <TableLoader rowsCount={4} columnsCount={2} />
                    ) : (
                      <>
                        <TableRow>
                          <TableCell className={genericSs.tableTextLeft}>
                            Total Wires from File
                          </TableCell>
                          <TableCell className={genericSs.tableTextRight}>
                            {formatter.format(wiresTotalsLoaded?.totalWires || 0)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={genericSs.tableTextLeft}>Posted Wires</TableCell>
                          <TableCell className={genericSs.tableTextRight}>
                            {formatter.format(wiresTotalsLoaded?.wiresPostedYesterday || 0)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={genericSs.tableTextLeft}>Negative Wires</TableCell>
                          <TableCell className={genericSs.tableTextRight}>
                            {formatter.format(
                              (wiresTotalsLoaded?.negativeWiresUploadedYesterday || 0) +
                                negativeWiresFromMidDay,
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={genericSs.tableTextLeft}>
                            Wires Posting Today
                          </TableCell>
                          <TableCell className={genericSs.tableTextRight}>
                            {formatter.format(wiresPostingTodayMinusNegative || 0)}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          className={cn({
                            [styles.validRow]: totalWireReconciliation === 0,
                            [styles.inValidRow]: totalWireReconciliation !== 0,
                          })}
                        >
                          <TableCell className={genericSs.tableTextLeft}>
                            <b>Discrepancy</b>
                          </TableCell>
                          <TableCell className={genericSs.tableTextRight}>
                            {formatter.format(totalWireReconciliation)}
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Grid>
          <Grid item xs={5.5}>
            <Card noHeaderMargin title={'Total collections reconciliation'}>
              <TableContainer className={styles.wiresReconciliationTable}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={genericSs.tableTextLeft}>Account</TableCell>
                      <TableCell className={genericSs.tableTextRight}>Total deposit</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isTotalsLoading ? (
                      <TableLoader columnsCount={2} />
                    ) : (
                      <>
                        <TableRow>
                          <TableCell className={genericSs.tableTextLeft}>
                            Total Collections
                          </TableCell>
                          <TableCell className={genericSs.tableTextRight}>
                            <CurrencyField
                              name="concentration"
                              useFinalForm={false}
                              value={totalCollections}
                              onChange={handleChangeTotalCollections}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={genericSs.tableTextLeft}>Posted Wires</TableCell>
                          <TableCell className={genericSs.tableTextRight}>
                            {formatter.format(
                              (wiresTotalsLoaded?.wiresPostedYesterday || 0) +
                                (wiresTotalsLoaded?.negativeWiresPostedYesterday || 0),
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={genericSs.tableTextLeft}>
                            Wires Posting Today
                          </TableCell>
                          <TableCell className={genericSs.tableTextRight}>
                            {formatter.format(wiresPostingTodayMinusNegative || 0)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={genericSs.tableTextLeft}>Posted Checks</TableCell>
                          <TableCell className={genericSs.tableTextRight}>
                            {formatter.format(wiresTotalsLoaded?.checksPostedYesterday || 0)}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          className={cn({
                            [styles.validRow]: totalCollectionsReconciliation === 0,
                            [styles.inValidRow]: totalCollectionsReconciliation !== 0,
                          })}
                        >
                          <TableCell className={genericSs.tableTextLeft}>
                            <b>Discrepancy</b>
                          </TableCell>
                          <TableCell className={genericSs.tableTextRight}>
                            {formatter.format(totalCollectionsReconciliation)}
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Grid>
        </Grid>
        <Grid
          container
          xs={12}
          marginBottom={'24px'}
          justifyContent={'flex-end'}
          paddingLeft={'24px'}
        >
          <div className={styles.submitButtonContainer}>
            <Button
              type="button"
              disabled={mappingProgress !== 100}
              color="primary"
              variant="contained"
              size="small"
              onClick={handleSubmit}
              isLoading={isSavingButton}
            >
              {collectionStatus === ActivityStatus.Review ? 'Submit' : 'Submit for review'}
            </Button>
          </div>
        </Grid>
      </Grid>
    </Box>
  )
}

export default CollectionsMidDayWiresPage
