import { IRootState } from '../reducer'
import { prefix } from './actions'

export const treasuryData = (state: IRootState) => state[prefix].treasuryData

export const fundingWires = (state: IRootState) => state[prefix].fundingWires

export const otherWires = (state: IRootState) => state[prefix].otherWires

export const participationWires = (state: IRootState) => state[prefix].participationWires

export const passthroughWires = (state: IRootState) => state[prefix].passthroughWires
