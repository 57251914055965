import { IClientInfo } from './client'
import { IActivity } from './activity'
import { IBorrowingBase, IOngoingReporting } from './bbc'
import { IAdjustment, IFee } from './loanServicing'
import { INote } from './notes'
import { IUser, NotificationSettingType } from './user'
import { ICheck, IWireData } from './collection'
import { IPassThrough } from './passThrough'
import { IDueDiligenceDocumentRequest, IDueDiligenceDocumentRequestComment } from './dueDiligence'
import { ITermLoan } from './termLoan'
import { INewsfeed } from './newsfeed'

export enum NotificationType {
  RequestFunding = 'RequestFunding',
  RequestFundingClient = 'RequestFundingClient',
  CancelFunding = 'CancelFunding',
  DeleteFunding = 'DeleteFunding',
  SubmitReporting = 'SubmitReporting',
  FeeCreated = 'FeeCreated',
  FeeUpdated = 'FeeUpdated',
  FeeDeleted = 'FeeDeleted',
  FeeCharged = 'FeeCharged',
  FeeChargedMultiple = 'FeeChargedMultiple',
  AdjustmentCreated = 'AdjustmentCreated',
  AdjustmentUpdated = 'AdjustmentUpdated',
  AdjustmentDeleted = 'AdjustmentDeleted',
  PassThroughUpdated = 'PassThroughUpdated',
  PassThroughDeleted = 'PassThroughDeleted',
  CheckDataCreated = 'CheckDataCreated',
  CheckDataUpdated = 'CheckDataUpdated',
  CheckDataDeleted = 'CheckDataDeleted',
  WiresDataCreated = 'WiresDataCreated',
  WiresDataUpdated = 'WiresDataUpdated',
  WiresDataDeleted = 'WiresDataDeleted',
  ActivityAssign = 'ActivityAssign',
  ActivityDelete = 'ActivityDelete',
  ChecksPostingIssue = 'ChecksPostingIssue',
  NoteTagging = 'NoteTagging',
  NoteTaggingOPS = 'NoteTaggingOPS',
  ClientIntakeSubmit = 'ClientIntakeSubmit',
  AutoReportingConnected = 'AutoReportingConnected',
  AutoReportingDisconnected = 'AutoReportingDisconnected',
  PasswordReset = 'PasswordReset',
  PasswordResetSuccess = 'PasswordResetSuccess',
  UserInvite = 'UserInvite',
  ProspectUserInvite = 'ProspectUserInvite',
  ParticipantUserInvite = 'ParticipantUserInvite',
  UserProfileUpdate = 'UserProfileUpdate',
  ManagerNotification = 'ManagerNotification',
  LoanBalanceReport = 'LoanBalanceReport',
  ClientApplicationFileUpload = 'ClientApplicationFileUpload',
  ClientApplicationFilesUploadSummarizing = 'ClientApplicationFilesUploadSummarizing',
  ClientApplicationStepFinished = 'ClientApplicationStepFinished',
  ClientApplicationDocumentComment = 'ClientApplicationDocumentComment',
  ClientApplicationDocumentCommentsSummarizing = 'ClientApplicationDocumentCommentsSummarizing',
  MaturityDateRenewal = 'MaturityDateRenewal',
  OveradvanceCreated = 'OveradvanceCreated',
  OveradvanceUpdated = 'OveradvanceUpdated',
  OveradvanceUpdatedPriority = 'OveradvanceUpdatedPriority',
  OveradvanceDeleted = 'OveradvanceDeleted',
  AmendmentCreated = 'AmendmentCreated',
  AmendmentUpdated = 'AmendmentUpdated',
  AmendmentDeleted = 'AmendmentDeleted',
  OPSSync = 'OPSSync',
  CodatPortfolioSync = 'CodatPortfolioSync',
  CodatUWSync = 'CodatUWSync',
  LoanBalanceDiscrepancy = 'LoanBalanceDiscrepancy',
  LoanBalanceDiscrepancyResolved = 'LoanBalanceDiscrepancyResolved',
  BBCSignReminder = 'BBCSignReminder',
  BBCSignReminderManual = 'BBCSignReminderManual',
  SubmitAllReportingReminder = 'SubmitAllReportingReminder',
  BBCMonthlyReminder = 'BBCMonthlyReminder',
  BBCProcessingSkipped = 'BBCProcessingSkipped',
  TermLoanCreated = 'TermLoanCreated',
  TermLoanUpdated = 'TermLoanUpdated',
  TermLoanDeleted = 'TermLoanDeleted',
  TermLoanActivityCreated = 'TermLoanActivityCreated',
  TermLoanActivityUpdated = 'TermLoanActivityUpdated',
  TermLoanActivityDeleted = 'TermLoanActivityDeleted',
  NoteReply = 'NoteReply',
  DefaultDiligenceNoteReply = 'DefaultDiligenceNoteReply',
  LCRNoteReply = 'LCRNoteReply',
  DDDocumentStatusReminder = 'DDDocumentStatusReminder',
  CodatSyncSummarizingPortfolio = 'CodatSyncSummarizingPortfolio',
  CodatSyncSummarizingUW = 'CodatSyncSummarizingUW',
  CodatSyncSummarizingBD = 'CodatSyncSummarizingBD',
  SubmitOPS = 'SubmitOPS',
  ApproveOPS = 'ApproveOPS',
  CheckUploadReminder = 'CheckUploadReminder',
  BBCSigned = 'BBCSigned',
  MarketNews = 'MarketNews',
  MarketNewsDigest = 'MarketNewsDigest',
}

export const NOTIFICATION_TYPE_SETTING: Record<NotificationType, NotificationSettingType> = {
  [NotificationType.RequestFunding]: NotificationSettingType.Funding,
  [NotificationType.CancelFunding]: NotificationSettingType.Funding,
  [NotificationType.DeleteFunding]: NotificationSettingType.Funding,
  [NotificationType.SubmitReporting]: NotificationSettingType.SubmitReporting,
  [NotificationType.FeeCreated]: NotificationSettingType.FeesAndAdjustments,
  [NotificationType.FeeUpdated]: NotificationSettingType.FeesAndAdjustments,
  [NotificationType.FeeDeleted]: NotificationSettingType.FeesAndAdjustments,
  [NotificationType.FeeCharged]: NotificationSettingType.FeesAndAdjustments,
  [NotificationType.FeeChargedMultiple]: NotificationSettingType.FeesAndAdjustments,
  [NotificationType.AdjustmentCreated]: NotificationSettingType.FeesAndAdjustments,
  [NotificationType.AdjustmentUpdated]: NotificationSettingType.FeesAndAdjustments,
  [NotificationType.AdjustmentDeleted]: NotificationSettingType.FeesAndAdjustments,
  [NotificationType.LoanBalanceDiscrepancy]: NotificationSettingType.FeesAndAdjustments,
  [NotificationType.LoanBalanceDiscrepancyResolved]: NotificationSettingType.FeesAndAdjustments,
  [NotificationType.TermLoanActivityCreated]: NotificationSettingType.FeesAndAdjustments,
  [NotificationType.TermLoanActivityUpdated]: NotificationSettingType.FeesAndAdjustments,
  [NotificationType.TermLoanActivityDeleted]: NotificationSettingType.FeesAndAdjustments,
  [NotificationType.PassThroughUpdated]: NotificationSettingType.PassThrough,
  [NotificationType.PassThroughDeleted]: NotificationSettingType.PassThrough,
  [NotificationType.CheckDataCreated]: NotificationSettingType.Collections,
  [NotificationType.CheckDataUpdated]: NotificationSettingType.Collections,
  [NotificationType.CheckDataDeleted]: NotificationSettingType.Collections,
  [NotificationType.WiresDataCreated]: NotificationSettingType.Collections,
  [NotificationType.WiresDataUpdated]: NotificationSettingType.Collections,
  [NotificationType.WiresDataDeleted]: NotificationSettingType.Collections,
  [NotificationType.ActivityAssign]: NotificationSettingType.Activities,
  [NotificationType.ActivityDelete]: NotificationSettingType.Activities,
  [NotificationType.ChecksPostingIssue]: NotificationSettingType.Activities,
  [NotificationType.NoteTagging]: NotificationSettingType.Notes,
  [NotificationType.NoteTaggingOPS]: NotificationSettingType.Notes,
  [NotificationType.ClientIntakeSubmit]: NotificationSettingType.ClientIntake,
  [NotificationType.AutoReportingConnected]: NotificationSettingType.AutoReportingConnected,
  [NotificationType.AutoReportingDisconnected]: NotificationSettingType.AutoReportingConnected,
  [NotificationType.ClientApplicationFileUpload]: NotificationSettingType.ClientApplication,
  [NotificationType.ClientApplicationFilesUploadSummarizing]:
    NotificationSettingType.ClientApplication,
  [NotificationType.ClientApplicationStepFinished]: NotificationSettingType.ClientApplication,
  [NotificationType.ClientApplicationDocumentComment]: NotificationSettingType.ClientApplication,
  [NotificationType.ClientApplicationDocumentCommentsSummarizing]:
    NotificationSettingType.ClientApplication,
  [NotificationType.DDDocumentStatusReminder]: NotificationSettingType.ClientApplication,
  [NotificationType.MaturityDateRenewal]: NotificationSettingType.ClientSettings,
  [NotificationType.OveradvanceCreated]: NotificationSettingType.ClientSettings,
  [NotificationType.OveradvanceUpdated]: NotificationSettingType.ClientSettings,
  [NotificationType.OveradvanceUpdatedPriority]: NotificationSettingType.ClientSettings,
  [NotificationType.OveradvanceDeleted]: NotificationSettingType.ClientSettings,
  [NotificationType.AmendmentCreated]: NotificationSettingType.ClientSettings,
  [NotificationType.AmendmentUpdated]: NotificationSettingType.ClientSettings,
  [NotificationType.AmendmentDeleted]: NotificationSettingType.ClientSettings,
  [NotificationType.TermLoanCreated]: NotificationSettingType.ClientSettings,
  [NotificationType.TermLoanUpdated]: NotificationSettingType.ClientSettings,
  [NotificationType.TermLoanDeleted]: NotificationSettingType.ClientSettings,
  [NotificationType.OPSSync]: NotificationSettingType.ClientIntake,
  [NotificationType.CodatPortfolioSync]: NotificationSettingType.SubmitReporting,
  [NotificationType.CodatUWSync]: NotificationSettingType.ClientApplication,
  [NotificationType.BBCProcessingSkipped]: NotificationSettingType.Funding,
  [NotificationType.NoteReply]: NotificationSettingType.Notes,
  [NotificationType.DefaultDiligenceNoteReply]: NotificationSettingType.Notes,
  [NotificationType.LCRNoteReply]: NotificationSettingType.Notes,
  [NotificationType.CodatSyncSummarizingPortfolio]: NotificationSettingType.SubmitReporting,
  [NotificationType.CodatSyncSummarizingUW]: NotificationSettingType.ClientApplication,
  [NotificationType.CodatSyncSummarizingBD]: NotificationSettingType.ClientIntake,
  [NotificationType.SubmitOPS]: NotificationSettingType.ClientIntake,
  [NotificationType.ApproveOPS]: NotificationSettingType.ClientIntake,
  [NotificationType.CheckUploadReminder]: NotificationSettingType.Activities,
  [NotificationType.BBCSigned]: NotificationSettingType.Funding,
  [NotificationType.MarketNews]: NotificationSettingType.MarketNews,
  [NotificationType.MarketNewsDigest]: NotificationSettingType.MarketNews,

  //system notifications
  [NotificationType.RequestFundingClient]: NotificationSettingType.System,
  [NotificationType.PasswordReset]: NotificationSettingType.System,
  [NotificationType.PasswordResetSuccess]: NotificationSettingType.System,
  [NotificationType.UserInvite]: NotificationSettingType.System,
  [NotificationType.ProspectUserInvite]: NotificationSettingType.System,
  [NotificationType.ParticipantUserInvite]: NotificationSettingType.System,
  [NotificationType.UserProfileUpdate]: NotificationSettingType.System,
  [NotificationType.ManagerNotification]: NotificationSettingType.System,
  [NotificationType.LoanBalanceReport]: NotificationSettingType.System,
  [NotificationType.BBCSignReminder]: NotificationSettingType.System,
  [NotificationType.BBCSignReminderManual]: NotificationSettingType.System,
  [NotificationType.SubmitAllReportingReminder]: NotificationSettingType.System,
  [NotificationType.BBCMonthlyReminder]: NotificationSettingType.System,
}

export const EMAIL_NOTIFICATIONS = [
  NotificationType.RequestFundingClient,
  NotificationType.RequestFunding,
  NotificationType.CancelFunding,
  NotificationType.DeleteFunding,
  NotificationType.PasswordReset,
  NotificationType.PasswordResetSuccess,
  NotificationType.UserInvite,
  NotificationType.ProspectUserInvite,
  NotificationType.ParticipantUserInvite,
  NotificationType.UserProfileUpdate,
  NotificationType.ManagerNotification,
  NotificationType.FeeCreated,
  NotificationType.FeeUpdated,
  NotificationType.FeeDeleted,
  NotificationType.FeeChargedMultiple,
  NotificationType.AdjustmentCreated,
  NotificationType.AdjustmentUpdated,
  NotificationType.AdjustmentDeleted,
  NotificationType.PassThroughUpdated,
  NotificationType.PassThroughDeleted,
  NotificationType.CheckDataCreated,
  NotificationType.CheckDataUpdated,
  NotificationType.CheckDataDeleted,
  NotificationType.WiresDataCreated,
  NotificationType.WiresDataUpdated,
  NotificationType.WiresDataDeleted,
  NotificationType.ActivityAssign,
  NotificationType.ActivityDelete,
  NotificationType.LoanBalanceReport,
  NotificationType.NoteTagging,
  NotificationType.NoteTaggingOPS,
  NotificationType.ClientIntakeSubmit,
  NotificationType.AutoReportingConnected,
  NotificationType.AutoReportingDisconnected,
  NotificationType.ClientApplicationFilesUploadSummarizing,
  NotificationType.ClientApplicationStepFinished,
  NotificationType.ClientApplicationDocumentCommentsSummarizing,
  NotificationType.MaturityDateRenewal,
  NotificationType.OveradvanceCreated,
  NotificationType.OveradvanceUpdated,
  NotificationType.OveradvanceUpdatedPriority,
  NotificationType.OveradvanceDeleted,
  NotificationType.AmendmentCreated,
  NotificationType.AmendmentUpdated,
  NotificationType.AmendmentDeleted,
  NotificationType.TermLoanCreated,
  NotificationType.TermLoanUpdated,
  NotificationType.TermLoanDeleted,
  NotificationType.OPSSync,
  NotificationType.LoanBalanceDiscrepancy,
  NotificationType.LoanBalanceDiscrepancyResolved,
  NotificationType.BBCSignReminder,
  NotificationType.BBCSignReminderManual,
  NotificationType.SubmitAllReportingReminder,
  NotificationType.BBCMonthlyReminder,
  NotificationType.TermLoanActivityCreated,
  NotificationType.TermLoanActivityUpdated,
  NotificationType.TermLoanActivityDeleted,
  NotificationType.NoteReply,
  NotificationType.DefaultDiligenceNoteReply,
  NotificationType.LCRNoteReply,
  NotificationType.DDDocumentStatusReminder,
  NotificationType.CodatSyncSummarizingPortfolio,
  NotificationType.CodatSyncSummarizingUW,
  NotificationType.CodatSyncSummarizingBD,
  NotificationType.SubmitOPS,
  NotificationType.ApproveOPS,
  NotificationType.CheckUploadReminder,
  NotificationType.BBCSigned,
  NotificationType.MarketNewsDigest,
]

export const PUSH_NOTIFICATIONS = [
  NotificationType.RequestFunding,
  NotificationType.CancelFunding,
  NotificationType.DeleteFunding,
  NotificationType.SubmitReporting,
  NotificationType.FeeCreated,
  NotificationType.FeeUpdated,
  NotificationType.FeeDeleted,
  NotificationType.FeeCharged,
  NotificationType.AdjustmentCreated,
  NotificationType.AdjustmentUpdated,
  NotificationType.AdjustmentDeleted,
  NotificationType.PassThroughUpdated,
  NotificationType.PassThroughDeleted,
  NotificationType.CheckDataCreated,
  NotificationType.CheckDataUpdated,
  NotificationType.CheckDataDeleted,
  NotificationType.WiresDataCreated,
  NotificationType.WiresDataUpdated,
  NotificationType.WiresDataDeleted,
  NotificationType.ActivityAssign,
  NotificationType.ActivityDelete,
  NotificationType.NoteTagging,
  NotificationType.ClientIntakeSubmit,
  NotificationType.AutoReportingConnected,
  NotificationType.AutoReportingDisconnected,
  NotificationType.ClientApplicationFileUpload,
  NotificationType.ClientApplicationStepFinished,
  NotificationType.ClientApplicationDocumentComment,
  NotificationType.MaturityDateRenewal,
  NotificationType.OveradvanceCreated,
  NotificationType.OveradvanceUpdated,
  NotificationType.OveradvanceUpdatedPriority,
  NotificationType.OveradvanceDeleted,
  NotificationType.AmendmentCreated,
  NotificationType.AmendmentUpdated,
  NotificationType.AmendmentDeleted,
  NotificationType.TermLoanCreated,
  NotificationType.TermLoanUpdated,
  NotificationType.TermLoanDeleted,
  NotificationType.OPSSync,
  NotificationType.CodatPortfolioSync,
  NotificationType.CodatUWSync,
  NotificationType.LoanBalanceDiscrepancy,
  NotificationType.LoanBalanceDiscrepancyResolved,
  NotificationType.BBCProcessingSkipped,
  NotificationType.TermLoanActivityCreated,
  NotificationType.TermLoanActivityUpdated,
  NotificationType.TermLoanActivityDeleted,
  NotificationType.NoteReply,
  NotificationType.DefaultDiligenceNoteReply,
  NotificationType.LCRNoteReply,
  NotificationType.DDDocumentStatusReminder,
  NotificationType.SubmitOPS,
  NotificationType.ApproveOPS,
  NotificationType.CheckUploadReminder,
  NotificationType.BBCSigned,
  NotificationType.MarketNews,
]

export const NOTIFICATION_TYPE_ACTION_MAP = {
  [NotificationType.FeeCreated]: 'added',
  [NotificationType.FeeUpdated]: 'updated',
  [NotificationType.FeeDeleted]: 'deleted',
  [NotificationType.AdjustmentCreated]: 'added',
  [NotificationType.AdjustmentUpdated]: 'updated',
  [NotificationType.AdjustmentDeleted]: 'deleted',
  [NotificationType.PassThroughUpdated]: 'updated',
  [NotificationType.PassThroughDeleted]: 'deleted',
  [NotificationType.CheckDataCreated]: 'added',
  [NotificationType.CheckDataUpdated]: 'updated',
  [NotificationType.CheckDataDeleted]: 'deleted',
  [NotificationType.WiresDataCreated]: 'added',
  [NotificationType.WiresDataUpdated]: 'updated',
  [NotificationType.WiresDataDeleted]: 'deleted',
  [NotificationType.OveradvanceCreated]: 'added',
  [NotificationType.OveradvanceUpdated]: 'updated',
  [NotificationType.OveradvanceDeleted]: 'deleted',
  [NotificationType.AmendmentCreated]: 'added',
  [NotificationType.AmendmentUpdated]: 'updated',
  [NotificationType.AmendmentDeleted]: 'deleted',
  [NotificationType.TermLoanCreated]: 'added',
  [NotificationType.TermLoanUpdated]: 'updated',
  [NotificationType.TermLoanDeleted]: 'deleted',
  [NotificationType.TermLoanActivityCreated]: 'added',
  [NotificationType.TermLoanActivityUpdated]: 'updated',
  [NotificationType.TermLoanActivityDeleted]: 'deleted',
}

export const ACCOUNT_ACTIVITY_NOTIFICATION_TYPES_MAP = {
  [NotificationType.FeeCreated]: 'fee',
  [NotificationType.FeeUpdated]: 'fee',
  [NotificationType.FeeDeleted]: 'fee',
  [NotificationType.AdjustmentCreated]: 'adjustment',
  [NotificationType.AdjustmentUpdated]: 'adjustment',
  [NotificationType.AdjustmentDeleted]: 'adjustment',
  [NotificationType.PassThroughUpdated]: 'pass-through',
  [NotificationType.PassThroughDeleted]: 'pass-through',
  [NotificationType.CheckDataCreated]: 'check',
  [NotificationType.CheckDataUpdated]: 'check',
  [NotificationType.CheckDataDeleted]: 'check',
  [NotificationType.WiresDataCreated]: 'wire',
  [NotificationType.WiresDataUpdated]: 'wire',
  [NotificationType.WiresDataDeleted]: 'wire',
  [NotificationType.TermLoanActivityCreated]: 'on a Term Loan',
  [NotificationType.TermLoanActivityUpdated]: 'on a Term Loan',
  [NotificationType.TermLoanActivityDeleted]: 'on a Term Loan',
}

export interface INotification {
  id: string
  type: NotificationType
  clientName: string | null
  clientInfo: IClientInfo | null
  notificationText: string | null
  activityId: string | null
  activity: IActivity | null
  borrowingBaseId: string | null
  borrowingBase: IBorrowingBase | null
  ongoingReportingId: string | null
  ongoingReporting: IOngoingReporting | null
  feeId: string | null
  fee: IFee | null
  adjustmentId: string | null
  adjustment: IAdjustment | null
  termLoanId: string | null
  termLoan: ITermLoan | null
  wiresDataId: string | null
  wiresData: IWireData | null
  checkDataId: string | null
  checkData: ICheck | null
  passThroughsId: string | null
  passThroughs: IPassThrough | null
  ddDocumentRequestId: string | null
  ddDocumentRequest: IDueDiligenceDocumentRequest | null
  ddDocumentRequestCommentId: string | null
  ddDocumentRequestComment: IDueDiligenceDocumentRequestComment | null
  newsfeedHeadlineId: string | null
  newsfeedHeadline: INewsfeed | null
  noteId: string | null
  note: INote | null
  userId: string | null
  user: IUser | null
  data: any
  isRead?: boolean
  createdAt: string
}

export interface INotificationData {
  data: INotification[]
  total: number
  totalUnread: number
}

export enum EmailNotificationStatus {
  Pending = 0,
  Sent = 1,
  Failed = 2,
}
