import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { useParams } from 'react-router'
import { Form } from 'react-final-form'
import cn from 'classnames'
import Box from '@mui/material/Box'
import styles from './BBCInventoryDistributionTable.module.scss'
import genericSs from '@styles/generic.module.scss'
import MultiselectRow from '../MultiselectRow'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableContainer from '../Common/TableContainer'
import TableBody from '../Common/TableBody'
import TableFooter from '../Common/TableFooter'
import TableFiltersRow from '../Common/TableFiltersRow'
import {
  IInventoryDistributionAnalysisData,
  IInventoryDistributionAnalysis,
  INVENTORY_DISTRIBUTION_LABELS,
  InventoryFieldKeys,
  IInventoryDistributionAnalysisCategories,
  WorkflowPage,
  NOT_MAPPED,
} from '@common/interfaces/bbc'
import { formatDate, formatPrice, debounceEventHandler } from '../../helpers/helpers'
import Card from '../Common/Card'
import { IInventoryIneligibilityFieldAliases } from '@common/interfaces/client'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import { BBC_INVENTORY_DISTRIBUTION_LIST_FILTERS_CONFIG } from '@common/constants/filters'
import FilterContainer from '../Filters/FilterContainer'
import TableLoader from '../Common/TableLoader'
import SelectField from '../Common/SelectField'
import { ILoadingData } from '../../redux/types'
import useTable from '../../hooks/useTable'
import InventoryDistributionGraph from '../InventoryDistributionGraph'
import Empty from '../Common/Empty'
import useTrackVisualizationsTable from '../../hooks/useTrackVisualizationsTable'
import { CATEGORIES } from '@common/constants/tracking'
import useTrackVisualizationsTableChartSelection from '../../hooks/useTrackVisualizationsTableChartSelection'
import useGraphToggle from '../../hooks/useGraphTogggle'
import LinkButton from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'

const filtersValidate = buildFiltersValidateSchema(BBC_INVENTORY_DISTRIBUTION_LIST_FILTERS_CONFIG)
const filtersDefaults = buildFiltersDefaults(BBC_INVENTORY_DISTRIBUTION_LIST_FILTERS_CONFIG)

export const DAYS_LABELS = {
  '0': '0-90 Days',
  '91': '91-180 Days',
  '181': '181-270 Days',
  '271': '271-360 Days',
  '361': '361+ Days',
}

const metricOptions = [
  { value: 'value', label: '$ Value' },
  { value: 'percent', label: '% of Total' },
]

const xAxisOptions = [
  { value: 'date', label: 'Date' },
  { value: 'distribution', label: 'Distribution' },
]

const calculateTotals = (
  data: IInventoryDistributionAnalysis[],
  borrowingBases: { id: string; recordDate: string }[],
) =>
  data
    ?.map((row) => row.values)
    .reduce(
      (total, row) => {
        borrowingBases?.forEach((value, index) => {
          if (!total.values[index]) {
            total.values[index] = 0
          }
          if (!total.percents[index]) {
            total.percents[index] = 0
          }
          total.values[index] += row[value.id]?.value || 0
          total.percents[index] += row[value.id]?.percent || 0
        })
        return total
      },
      { values: borrowingBases.map(() => 0), percents: borrowingBases.map(() => 0) },
    )

interface IProps {
  inventoryDistributionCategories: ILoadingData<IInventoryDistributionAnalysisCategories>
  listInventoryDistributionAnalysis: (id: string, data: object) => void
  listInventoryDistributionAnalysisCategories: (id: string, data: object) => Promise<void>
  inventoryDistributionData: ILoadingData<IInventoryDistributionAnalysisData>
  getBBCSummaryStats: (id: string, params?: object) => void
  aliases: IInventoryIneligibilityFieldAliases
  hideFilters?: boolean
  type: WorkflowPage
  onInitialize?: () => void
  editLink?: string
}

const BBCInventoryDistributionTable = ({
  inventoryDistributionCategories,
  getBBCSummaryStats,
  listInventoryDistributionAnalysis,
  listInventoryDistributionAnalysisCategories,
  inventoryDistributionData,
  aliases,
  type,
  onInitialize,
  editLink,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const wrapperRef = useRef(null)

  const [field, setField] = useState<InventoryFieldKeys>()
  const [currentMetric, setCurrentMetric] = useState<string>('value')
  const [xAxis, setXAxis] = useState<'date' | 'distribution'>('date')
  const [date, setDate] = useState<string>('')
  const {
    filters,
    handleFiltersChange,
    handleOrderChange,
    orderBy,
    activeItem,
    activeItems,
    setActiveItem,
    setActiveItems,
    handleSelectRow,
  } = useTable({
    tableId: 'inventoryDistribution',
    filtersDefaults,
    sortDefault: {
      field: 'value_0',
      direction: 'DESC',
    },
  })

  const { isLoading, data, inventoryDates } = useMemo(
    () => ({
      isLoading: inventoryDistributionData?.isLoading,
      data: inventoryDistributionData?.data?.data || [],
      inventoryDates: inventoryDistributionData?.data?.inventoryDates || [],
    }),
    [inventoryDistributionData],
  )

  const { filterDropdownValues, distributionPicklist, isDistributionListLoading } = useMemo(
    () => ({
      filterDropdownValues: inventoryDistributionCategories?.data?.filterDropdownValues,
      distributionPicklist: inventoryDistributionCategories?.data?.distributionPicklist,
      isDistributionListLoading: inventoryDistributionCategories?.isLoading,
    }),
    [inventoryDistributionCategories],
  )

  useEffect(() => {
    const fetchDistributionList = async () => {
      if (id) {
        await listInventoryDistributionAnalysisCategories(id, {
          type,
        })
      }
    }

    fetchDistributionList()
  }, [id, type, listInventoryDistributionAnalysisCategories])

  useEffect(() => {
    if (id && type === WorkflowPage.borrowingbase) {
      getBBCSummaryStats(id, {
        type,
      })
    }
  }, [id, type, getBBCSummaryStats])

  useEffect(() => {
    if (id && type === WorkflowPage.dueDilligence) {
      setXAxis('distribution')
    }
  }, [id, type])

  const debounceFilterList = useMemo(
    () =>
      debounceEventHandler(async (data: any) => {
        await listInventoryDistributionAnalysis(id, data)
        onInitialize && onInitialize()
      }, 500),
    [id, listInventoryDistributionAnalysis, onInitialize],
  )

  useEffect(() => {
    !isDistributionListLoading &&
      field &&
      debounceFilterList({
        field,
        type,
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
        filters,
      })
  }, [orderBy, filters, debounceFilterList, type, field, isDistributionListLoading])

  const distributionLookup = useCallback(
    (field: InventoryFieldKeys) => aliases?.[field] || INVENTORY_DISTRIBUTION_LABELS[field],
    [aliases],
  )

  const filtersConfig = useMemo(
    () =>
      BBC_INVENTORY_DISTRIBUTION_LIST_FILTERS_CONFIG.map((filter) => {
        const indexOfField = parseInt(filter.field.split('_')?.[1])
        const isIndexANumber = !isNaN(indexOfField)
        if (filter.field === 'distribution') {
          return {
            ...filter,
            title: INVENTORY_DISTRIBUTION_LABELS[field],
          }
        } else if (filter.excludeColumn && !filter.excludeFilter) {
          const title = distributionLookup(filter.field as InventoryFieldKeys)
          const options = filterDropdownValues?.[filter.field]?.map((value) => ({
            value,
            label: value,
          }))
          if (options?.length) {
            return {
              ...filter,
              title,
              options,
            }
          }
          return {
            ...filter,
            excludeFilter: true,
          }
        } else if (isIndexANumber && !inventoryDates?.[indexOfField]) {
          return null
        }

        return filter
      }).filter(Boolean),
    [filterDropdownValues, field, inventoryDates, distributionLookup],
  )

  const totalAggregationRow = useMemo(
    () =>
      calculateTotals(
        data?.filter((_, index) => activeItems.includes(index)),
        inventoryDates,
      ),

    [data, activeItems, inventoryDates],
  )

  const totals = useMemo(() => calculateTotals(data, inventoryDates), [data, inventoryDates])

  const handleChangeField = useCallback((event) => {
    setField(event.target.value)
  }, [])

  const fieldOptions = useMemo(
    () =>
      distributionPicklist?.map((fieldValue) => ({
        value: fieldValue,
        label: distributionLookup(fieldValue as InventoryFieldKeys) || fieldValue,
      })),
    [distributionPicklist, distributionLookup],
  )

  const isInitialized = useMemo(
    () => !!inventoryDistributionData?.data?.data,
    [inventoryDistributionData],
  )

  const { TabsComponent, isGraphShown } = useGraphToggle({})

  const visualizationsCategory = useMemo(
    () =>
      isGraphShown ? CATEGORIES.inventoryDistributionChart : CATEGORIES.inventoryDistributionTable,
    [isGraphShown],
  )
  const visualizationsParams = useMemo(
    () => ({
      [type === WorkflowPage.borrowingbase ? 'borrowingBaseId' : 'clientId']: id,
    }),
    [type, id],
  )
  const visualizationsFilters = useMemo(
    () => ({
      ...filters,
      field,
    }),
    [filters, field],
  )

  useTrackVisualizationsTable({
    isInitialized,
    category: visualizationsCategory,
    params: visualizationsParams,
    filtersConfig: BBC_INVENTORY_DISTRIBUTION_LIST_FILTERS_CONFIG,
    filters: visualizationsFilters,
    orderBy,
  })

  useTrackVisualizationsTableChartSelection({
    isInitialized,
    category: visualizationsCategory,
    params: visualizationsParams,
    isChart: isGraphShown,
  })

  const columnCount = useMemo(
    () => (inventoryDates?.length ? inventoryDates?.length * 2 + 1 : 11),
    [inventoryDates],
  )

  const handleMetricChange = useCallback(({ target: { value } }) => {
    setCurrentMetric(value)
  }, [])

  const handleXAxisChange = useCallback(({ target: { value } }) => {
    setXAxis(value)
  }, [])

  useEffect(() => {
    if (fieldOptions && fieldOptions.length > 0 && !field) {
      setField(fieldOptions[0].value as InventoryFieldKeys)
    }
  }, [fieldOptions, field])

  const dateOptions = useMemo(() => {
    return inventoryDates?.map(({ recordDate }) => ({
      value: recordDate,
      label: formatDate(recordDate),
    }))
  }, [inventoryDates])

  useEffect(() => {
    if (
      dateOptions &&
      dateOptions.length > 0 &&
      (!date || (date && !dateOptions.find(({ value }) => value === date)))
    ) {
      const latestDate = dateOptions.sort(
        (a, b) => new Date(b.value).getTime() - new Date(a.value).getTime(),
      )[0]
      setDate(latestDate.value)
    }
  }, [dateOptions, date])

  const handleDateChange = useCallback(({ target: { value } }) => {
    setDate(value)
  }, [])

  const noDataAvailable = useMemo(
    () => !isDistributionListLoading && !isLoading && !inventoryDates?.length,
    [isDistributionListLoading, isLoading, inventoryDates],
  )

  return (
    <Card noHeaderMargin ref={wrapperRef}>
      <TableContainer
        className={styles.table}
        onActiveRowsChange={setActiveItems}
        onActiveRowChange={setActiveItem}
        hasFooter
      >
        <Form
          validate={filtersValidate}
          onSubmit={handleFiltersChange}
          initialValues={filters}
          mutators={{
            setFieldData: ([filterField, value], state, { changeValue }) => {
              changeValue(state, filterField, () => value)
            },
          }}
          render={({ values, handleSubmit, form: { mutators } }) => (
            <FilterContainer
              filters={filtersConfig}
              handleSubmit={handleSubmit}
              mutators={mutators}
              values={values}
              appliedFilters={filters}
              title={
                <Box mr={2}>
                  <h2>
                    {`Inventory Distribution by ${distributionLookup(field)}`}
                    {editLink && (
                      <LinkButton component={RouterLink} to={editLink} className={styles.editLink}>
                        Edit
                      </LinkButton>
                    )}
                  </h2>
                </Box>
              }
              actions={
                <Box display="flex" justifyContent="space-between" alignItems="center" gap={1}>
                  {xAxis === 'distribution' && isGraphShown && (
                    <SelectField
                      className={styles.selectField}
                      name="date"
                      useFinalForm={false}
                      label="Date"
                      options={dateOptions || []}
                      onChange={handleDateChange}
                      value={date || ''}
                      withTopLabel
                      variant="outlined"
                    />
                  )}
                  {isGraphShown ? (
                    <SelectField
                      key={xAxis}
                      label="X Axis"
                      variant="outlined"
                      className={styles.selectField}
                      useFinalForm={false}
                      name="xAxis"
                      options={xAxisOptions}
                      onChange={handleXAxisChange}
                      value={xAxis}
                      withTopLabel
                    />
                  ) : (
                    <SelectField
                      key={currentMetric}
                      label="Select mertic"
                      variant="outlined"
                      className={styles.selectField}
                      useFinalForm={false}
                      name="recordDate"
                      options={metricOptions}
                      onChange={handleMetricChange}
                      value={currentMetric}
                      defaultValue=""
                      withTopLabel
                      disabled={isLoading || isDistributionListLoading || noDataAvailable}
                    />
                  )}

                  <SelectField
                    className={styles.selectField}
                    name="field"
                    useFinalForm={false}
                    label="Distribution"
                    options={fieldOptions || []}
                    onChange={handleChangeField}
                    value={field || ''}
                    withTopLabel
                    variant="outlined"
                    isLoading={isDistributionListLoading}
                    disabled={isDistributionListLoading || noDataAvailable}
                  />
                  <div className={styles.recordDateWrapper}>{TabsComponent}</div>
                </Box>
              }
            />
          )}
        />
        {noDataAvailable ? (
          <Empty message="No inventory distribution data available" height="30vh" />
        ) : !isGraphShown ? (
          <>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  {inventoryDates.map((bbc, index) => (
                    <TableCell
                      key={bbc.id}
                      colSpan={2}
                      className={cn(styles.highlighted, {
                        [styles.highlightedCurrent]: index === 0,
                      })}
                    >
                      {type === WorkflowPage.borrowingbase && index === 0
                        ? 'Current BBC'
                        : formatDate(bbc?.recordDate)}
                    </TableCell>
                  ))}
                </TableRow>
                <TableFiltersRow
                  filters={filtersConfig}
                  orderBy={orderBy}
                  handleOrderChange={handleOrderChange}
                />
              </TableHead>
              <TableBody>
                {isLoading || isDistributionListLoading ? (
                  <TableLoader
                    columnsCount={inventoryDates?.length ? inventoryDates.length * 2 + 1 : 11}
                  />
                ) : (
                  data &&
                  Object.values(data).map((item, index) => {
                    const ineligible = item.eligible === false
                    const values = item.values
                    return (
                      <TableRow
                        key={item.distribution || NOT_MAPPED}
                        data-index={index}
                        className={cn('activableRow', {
                          activeRow: activeItems.includes(index),
                          currentActiveRow: activeItem === index,
                        })}
                        onClick={(event) => handleSelectRow(event, index)}
                      >
                        <TableCell className={genericSs.tableTextLeft}>
                          <div className={styles.locationName}>
                            <span>
                              {item?.mappedLocation ||
                                DAYS_LABELS[item?.distribution as keyof typeof DAYS_LABELS] ||
                                item?.distribution ||
                                NOT_MAPPED}
                              {ineligible && <div className={genericSs.grayCard}>Ineligible</div>}
                            </span>
                            {item?.mappedLocation && item?.mappedLocation !== item.distribution && (
                              <span className={styles.originalLocationName}>
                                {item.distribution}
                              </span>
                            )}
                          </div>
                        </TableCell>
                        {inventoryDates.map((borrowingBase) => {
                          const { value, percent } = values[borrowingBase.id] || {
                            value: 0,
                            percent: 0,
                          }

                          return (
                            <React.Fragment key={borrowingBase.id}>
                              <TableCell className={genericSs.tableTextRight}>
                                <span className={genericSs.pricePrefix}>$</span>
                                {formatPrice(value)}
                              </TableCell>
                              <TableCell className={genericSs.tableTextRight}>
                                {percent?.toFixed(2)}%
                              </TableCell>
                            </React.Fragment>
                          )
                        })}
                      </TableRow>
                    )
                  })
                )}
                <MultiselectRow activeItems={activeItems}>
                  {inventoryDates?.map(({ id }, index) => {
                    const totalValue = totalAggregationRow.values[index]
                    const percent = totalAggregationRow.percents[index]
                    return (
                      <>
                        <TableCell key={id} className={genericSs.tableTextRight}>
                          <span className={genericSs.pricePrefix}>$</span>
                          {formatPrice(totalValue)}
                        </TableCell>
                        <TableCell className={genericSs.tableTextRight}>
                          {percent?.toFixed(2)}%
                        </TableCell>
                      </>
                    )
                  })}
                </MultiselectRow>
              </TableBody>
              <TableFooter>
                {isLoading || isDistributionListLoading ? (
                  <TableLoader columnsCount={columnCount} rowsCount={1} />
                ) : (
                  <TableRow>
                    <TableCell className={genericSs.tableTextLeft}>Total Value</TableCell>
                    {inventoryDates?.map(({ id }, index) => {
                      const total = totals.values[index]
                      return (
                        <React.Fragment key={id}>
                          <TableCell className={genericSs.tableTextRight}>
                            <span className={genericSs.pricePrefix}>$</span>
                            {formatPrice(total)}
                          </TableCell>
                          <TableCell className={genericSs.tableTextRight}>100%</TableCell>
                        </React.Fragment>
                      )
                    })}
                  </TableRow>
                )}
              </TableFooter>
            </Table>
          </>
        ) : (
          <InventoryDistributionGraph
            borrowingBases={inventoryDates}
            inventoryDistributionAnaysis={data}
            isPercent={currentMetric === 'percent'}
            field={field}
            isLoading={isLoading}
            xAxis={xAxis}
            date={date}
          />
        )}
      </TableContainer>
    </Card>
  )
}

export default BBCInventoryDistributionTable
