import React, { useEffect, useMemo, useCallback, useState, useRef } from 'react'
import ReactDOM from 'react-dom'
import { useHistory, useLocation, useParams } from 'react-router'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import cn from 'classnames'
import queryString from 'query-string'
import pick from 'lodash/pick'

import styles from './ProspectReportingFinancialsPage.module.scss'
import genericSs from '@styles/generic.module.scss'
import { usePermissions } from '../../helpers/permissionContext'

import {
  IOngoingReportingSummaryData,
  IOngoingReportingSummaryHeaders,
  OngoingReportingType,
  ReportingFlow,
  ReportingPeriods,
} from '@common/interfaces/bbc'
import {
  IOPSReporting,
  OPSReportingFlowStatus,
  OPSReportingStatus,
} from '@common/interfaces/prospects'
import OngoingReportingMapping from '../../components/OngoingReportingMapping'
import OngoingReportingSummary, {
  OngoingReportingSummaryLoader,
} from '../../components/OngoingReportingSummary'
import OngoingReportingReconciliation from '../../components/OngoingReportingReconciliation'
import { getReconciliation } from '../../helpers/financials'
import ProspectDashboardHeader from '../../components/ProspectDashboardHeader'
import ProspectFileSelect from '../../components/ProspectFileSelect'
import Pagination from '../../components/Common/Pagination'
import { usePrevious } from '../../hooks/usePrevious'
import { ClientInfoStatus } from '@common/interfaces/client'
import OPSWaivedDocument from '../../components/OPSWaivedDocument'

interface IProps {
  reporting: IOPSReporting
  statementType: OngoingReportingType | null
  dataSummary: Partial<Record<OngoingReportingType, IOngoingReportingSummaryData>>
  dataSummaryHeaders: IOngoingReportingSummaryHeaders
  isSidebarOpen: boolean
  isNotesShown: boolean
  show: (id: string) => void
  startReporting: (id: string, data?: object) => void
  submitOngoingReporting: (id: string, data: object) => Promise<any>
}

const ProspectReportingFinancialsPage = ({
  reporting,
  statementType,
  dataSummary,
  dataSummaryHeaders,
  isSidebarOpen,
  isNotesShown,
  show,
  startReporting,
  submitOngoingReporting,
}: IProps) => {
  const { id } = useParams<{ id?: string }>()
  const { search, pathname }: { search: string; pathname: string } = useLocation()
  const history = useHistory()
  const [currentReportingPeriod, setCurrentReportingPeriod] = useState<ReportingPeriods>(
    ReportingPeriods.Monthly,
  )
  const { isUW } = usePermissions()

  const readOnly = useMemo(
    () =>
      ![ClientInfoStatus.Prospect, ClientInfoStatus.Archived].includes(
        reporting?.clientInfo?.clientStatus,
      ) ||
      reporting?.status === OPSReportingStatus.Archived ||
      isUW,
    [reporting, isUW],
  )

  const [refreshCounter, setRefreshCounter] = useState(1)
  const [isFilesSaving, setIsFilesSaving] = useState(false)
  const [isModalMultiSelected, setIsModalMultiSelected] = useState(false)
  const [isMappingModalShown, setIsMappingModalShown] = useState(false)
  const [isModalShown, setIsModalShown] = useState(false)
  const wasMappingModalShown = usePrevious<boolean>(isMappingModalShown)
  const isSubmitting = useRef(false)

  const qs = useMemo(() => queryString.parse(search), [search])

  const handleSubmitOngoingReporting = useCallback(async () => {
    if (isSubmitting.current) {
      return
    }
    isSubmitting.current = true
    statementType &&
      (await submitOngoingReporting(reporting?.id, {
        statementTypes: [statementType],
        reportingFlow: ReportingFlow.OPS,
        status: wasMappingModalShown ? OPSReportingFlowStatus.Complete : undefined,
      }))
    isSubmitting.current = false
  }, [reporting?.id, submitOngoingReporting, statementType, wasMappingModalShown])

  const opsReportingFlow = useMemo(
    () => reporting?.opsReportingFlows?.filter(({ type }) => type === statementType)[0],
    [reporting, statementType],
  )
  const isActionRequired = useMemo(() => {
    if (readOnly) {
      return false
    }
    const opsReportingFlow = reporting?.opsReportingFlows?.filter(
      ({ type }) => type === statementType,
    )[0]
    return ![OPSReportingFlowStatus.Complete, OPSReportingFlowStatus.Waived].includes(
      opsReportingFlow?.status,
    )
  }, [reporting, statementType, readOnly])
  const isFileRequired = useMemo(() => {
    const opsReportingFlow = reporting?.opsReportingFlows?.filter(
      ({ type }) => type === statementType,
    )[0]
    return !opsReportingFlow?.files?.[0]?.fileId
  }, [reporting, statementType])

  const currentFile = useMemo(() => {
    const current =
      opsReportingFlow?.files?.find(
        ({ fileId, sheetName }) => fileId === qs.fileId && sheetName === qs.sheetName,
      ) || opsReportingFlow?.files[0]

    return current
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opsReportingFlow, qs, reporting?.id])

  const currentFileIndex = useMemo(() => {
    const index = opsReportingFlow?.files?.findIndex(
      ({ fileId, sheetName }) => fileId === qs.fileId && sheetName === qs.sheetName,
    )

    if (index === -1) {
      return 0
    }

    return index
  }, [opsReportingFlow, qs])

  const handleChangeCurrentFile = useCallback(
    (event: React.ChangeEvent<unknown>, page: number) => {
      const nextFile = opsReportingFlow?.files[page - 1]
      if (nextFile) {
        history.push({
          pathname,
          search: queryString.stringify({
            fileId: nextFile.fileId,
            sheetName: nextFile.sheetName,
          }),
        })
      }
    },
    [history, opsReportingFlow, pathname],
  )

  const ongoingReportingData = useMemo(
    () => ({
      ...pick(currentFile || {}, ['fileId', 'sheetName']),
      opsReportingId: reporting?.id,
      opsReportingFlowsId: opsReportingFlow?.id,
    }),
    [currentFile, reporting?.id, opsReportingFlow?.id],
  )

  const dataReconciliation = useMemo(() => {
    return getReconciliation(statementType, dataSummary, dataSummaryHeaders)
  }, [statementType, dataSummary, dataSummaryHeaders])

  useEffect(() => {
    if (reporting?.id && !readOnly) {
      startReporting(reporting.id)
    }
  }, [reporting?.id, readOnly, startReporting])

  useEffect(() => {
    if (wasMappingModalShown && !isMappingModalShown) {
      show(id)
    }
  }, [isMappingModalShown, wasMappingModalShown, show, id])

  const [wrapper, setWrapper] = useState<HTMLElement | null>(null)

  useEffect(() => {
    setTimeout(() => {
      isMappingModalShown && setWrapper(document.getElementById('secondModalOpen'))
    }, 1000)
  }, [isMappingModalShown])

  const [isFileSelectShown, setIsFileSelectShown] = useState(false)
  const handleOpenSelectFile = useCallback(() => {
    setIsFileSelectShown(true)
  }, [setIsFileSelectShown])

  const handleCloseSelectFile = useCallback(() => {
    setIsFileSelectShown(false)
    show(id)
  }, [setIsFileSelectShown, show, id])
  useEffect(() => {
    if (
      !readOnly &&
      !isMappingModalShown &&
      reporting?.id &&
      opsReportingFlow?.status !== OPSReportingFlowStatus.FileSelect
    ) {
      handleSubmitOngoingReporting()
    }
  }, [
    isMappingModalShown,
    reporting?.id,
    handleSubmitOngoingReporting,
    opsReportingFlow?.status,
    readOnly,
  ])

  const isWaived = useMemo(
    () => opsReportingFlow?.status === OPSReportingFlowStatus.Waived,
    [opsReportingFlow],
  )

  const selectedFile = useMemo(() => {
    const currentFile = opsReportingFlow?.files?.[currentFileIndex]
    return reporting?.files
      ?.filter(({ type }) => type === statementType)
      ?.find(({ id }) => id === currentFile?.fileId)
  }, [reporting, statementType, opsReportingFlow, currentFileIndex])

  if (!statementType) {
    return null
  }

  return (
    <Box py={1} pr={2} className={styles.container} display="flex" flexDirection="column">
      <Box flex={1} className={genericSs.overflowXAuto}>
        <ProspectDashboardHeader
          setRefreshCounter={setRefreshCounter}
          currentReportType={statementType}
        />
        {!readOnly && opsReportingFlow && (
          <ProspectFileSelect
            key={statementType}
            isOpen={isFileSelectShown}
            reportType={statementType}
            opsReportingFlow={opsReportingFlow}
            setRefreshCounter={setRefreshCounter}
            setIsFilesSaving={setIsFilesSaving}
            handleOpenModal={handleOpenSelectFile}
            handleCloseModal={handleCloseSelectFile}
          />
        )}
        {isWaived ? (
          <OPSWaivedDocument
            reportType={statementType}
            openFileSelectModal={handleOpenSelectFile}
          />
        ) : (
          <Grid item xs={12} className={styles.reportingSummaryItem}>
            {reporting?.id ? (
              <OngoingReportingSummary
                dates={currentFile?.data}
                statementType={statementType}
                refreshCounter={refreshCounter}
                setIsMappingModalShown={setIsMappingModalShown}
                handleOpenSelectFile={readOnly ? null : handleOpenSelectFile}
                showEditFinancialsDate={!readOnly}
                isModalShown={isModalShown}
                setIsModalShown={setIsModalShown}
                selectedFile={selectedFile}
                additionalParams={ongoingReportingData}
                reportingFlow={ReportingFlow.OPS}
                isMappingRequired={isActionRequired}
                isMappingDisabled={isFileRequired}
                isLoading={isFilesSaving || !reporting}
                currentReportingPeriod={currentReportingPeriod}
                setCurrentReportingPeriod={setCurrentReportingPeriod}
              />
            ) : (
              <OngoingReportingSummaryLoader statementType={statementType} />
            )}
          </Grid>
        )}
        {reporting && opsReportingFlow?.status !== OPSReportingFlowStatus.FileSelect && (
          <Grid className={styles.reportingTables} container spacing={3}>
            <Grid item xs={12}>
              <OngoingReportingMapping
                statementType={statementType}
                refreshCounter={refreshCounter}
                isMappingModalShown={isMappingModalShown}
                setIsMappingModalShown={setIsMappingModalShown}
                currentReportingPeriod={currentReportingPeriod}
                setCurrentReportingPeriod={setCurrentReportingPeriod}
                additionalParams={ongoingReportingData}
                reportingFlow={ReportingFlow.OPS}
                setIsModalMultiSelected={setIsModalMultiSelected}
                isDisabled={readOnly}
              />
            </Grid>

            {isMappingModalShown && wrapper ? (
              ReactDOM.createPortal(
                <Grid item xs={12}>
                  <OngoingReportingReconciliation
                    headers={dataSummaryHeaders?.[statementType]}
                    data={dataReconciliation}
                  />
                </Grid>,
                wrapper,
              )
            ) : isWaived ? null : (
              <Grid item xs={12}>
                <OngoingReportingReconciliation
                  headers={dataSummaryHeaders?.[statementType]}
                  data={dataReconciliation}
                />
              </Grid>
            )}
          </Grid>
        )}
      </Box>

      {opsReportingFlow?.files?.length > 1 && !isModalMultiSelected && (
        <Box
          className={cn(styles.navigationBar, {
            [styles.navigationBarWithSidebar]: isSidebarOpen && !isModalMultiSelected,
            [styles.navigationBarWithNotes]: isNotesShown,
            [styles.navigationBarModal]: isMappingModalShown,
          })}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <span className={styles.navigationBarLabel}>
            {currentFile.file?.fileName} - {currentFile.sheetName}
          </span>
          <Box>
            <Pagination
              count={opsReportingFlow.files.length}
              page={currentFileIndex + 1}
              onChange={handleChangeCurrentFile}
              variant="outlined"
              shape="rounded"
              color="primary"
            />
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default ProspectReportingFinancialsPage
