import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { generatePath, Link } from 'react-router-dom'
import { useHistory, useLocation } from 'react-router'
import queryString from 'query-string'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import LinkButton from '@mui/material/Link'
import { Form } from 'react-final-form'
import cn from 'classnames'
import InfiniteScroll from 'react-infinite-scroll-component'
import styles from './PortfolioManagerDashboard.module.scss'
import termStructureStyles from '../ProspectReportingSummaryTermStructure/ProspectReportingSummaryTermStructure.module.scss'
import genericSs from '@styles/generic.module.scss'
import TableFooter from '../Common/TableFooter'
import Card from '../Common/Card'
import MultiselectRow from '../MultiselectRow'
import {
  debounceEventHandler,
  formatMaxNumber,
  formatPercent,
  formatPrice,
  formatter,
} from '../../helpers/helpers'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TableFiltersRow from '../Common/TableFiltersRow'
import {
  ClientInfoStatus,
  FieldType,
  IClientInfo,
  IClientsAggregation,
} from '@common/interfaces/client'
import { ROUTES } from '../../constants/routes'
import Tabs from '../Common/Tabs'
import ComplianceByClientTable from '../ComplianceByClientTable'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import {
  PARTICIPANT_PORTFOLIO_REVIEW_LIST_FILTERS_CONFIG,
  PER_PAGE,
  PORTFOLIO_REVIEW_LIST_FILTERS_CONFIG,
  PORTFOLIO_REVIEW_METRICS_LIST_FILTERS_CONFIG,
} from '@common/constants/filters'
import FilterContainer from '../Filters/FilterContainer'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'
import { ILoadingData } from '../../redux/types'
import TableLoader from '../Common/TableLoader/TableLoader'
import { ExpandDetailIcon } from '../Common/Icons'
import FullPageFlags from '../Flags/FullPageFlags'
import { usePermissions } from '../../helpers/permissionContext'
import RiskRatingTable from '../RiskRatingTable'
import { UserRole } from '@common/interfaces/user'
import useTable from '../../hooks/useTable'
import { ParticipationStatus } from '@common/interfaces/participant'
import RenderedValue from '../RenderedValue'
import FinancialsComparison from '../FinancialsComparison'
import useSummaryRow from '../../hooks/useSummaryRow'
import MultipleTableCells from '../Common/MultipleTableCells'
import Tooltip from '@mui/material/Tooltip'
import CopyToClipboard from '../Common/CopyToClipboard'

interface IProps {
  userId: string
  role: UserRole
  clients: ILoadingData<{ data: IClientInfo[] }>
  listClients: () => void
  fullClientsAggregation: ILoadingData<IClientsAggregation>
  listClientsAggregation: (params?: {
    page?: number
    perPage?: number
    filters?: object
    orderBy?: string
    orderDirection?: string
  }) => void
  exportPortfolioReview: () => void
}

const PortfolioManagerDashboard = ({
  userId,
  role,
  clients,
  listClients,
  fullClientsAggregation,
  listClientsAggregation,
  exportPortfolioReview,
}: IProps) => {
  const { isParticipant } = usePermissions()

  const TABS = useMemo(
    () =>
      isParticipant
        ? ['Portfolio', 'Reporting']
        : ['Portfolio', 'Reporting', 'Watchlist', 'Flags', 'Financials'],
    [isParticipant],
  )

  const filterArray = useMemo(
    () =>
      [
        ...(isParticipant
          ? PARTICIPANT_PORTFOLIO_REVIEW_LIST_FILTERS_CONFIG
          : PORTFOLIO_REVIEW_LIST_FILTERS_CONFIG),
        ...PORTFOLIO_REVIEW_METRICS_LIST_FILTERS_CONFIG,
      ].map((filter) =>
        filter.type === 'quickFilter'
          ? {
              ...filter,
              quickFilters: [UserRole.ADMIN, UserRole.PORTFOLIO_ADMIN].includes(role)
                ? [
                    {
                      title: 'My Clients',
                      isHidden: true,
                      filters: {
                        userId,
                      },
                    },
                    {
                      title: 'Active Clients',
                      filters: {
                        clientStatus: [ClientInfoStatus.Active],
                      },
                    },
                  ]
                : UserRole.UW_USER === role
                ? [
                    {
                      title: 'Active Clients',
                      filters: {
                        clientStatus: [ClientInfoStatus.Active],
                      },
                    },

                    {
                      title: 'Due Diligence',
                      filters: {
                        clientStatus: [ClientInfoStatus.DueDiligence],
                      },
                    },
                  ]
                : [],
            }
          : filter,
      ),
    [isParticipant, role, userId],
  )

  const filtersValidate = useMemo(() => buildFiltersValidateSchema(filterArray), [filterArray])
  const filtersDefaults = useMemo(
    () =>
      buildFiltersDefaults(
        filterArray,
        isParticipant
          ? { clientParticipantStatus: [ParticipationStatus.Active] }
          : {
              clientStatus: [ClientInfoStatus.Active],
            },
      ),
    [filterArray, isParticipant],
  )

  const {
    filters,
    orderBy,
    handleFiltersChange,
    handleOrderChange,
    quickFilter,
    handleQuickFilterChange,
    activeItems,
    setActiveItems,
    activeItem,
    setActiveItem,
    handleSelectRow,
  } = useTable({
    tableId: 'portfolio',
    filtersDefaults,
    sortDefault: {
      field: 'client_name',
      direction: 'ASC',
    },
    quickFilterDefault: isParticipant ? null : 'Active Clients',
  })

  const { clientsData } = useMemo(
    () => ({
      clientsData: clients?.data?.data,
    }),
    [clients],
  )

  const { search }: { search: string } = useLocation()
  const history = useHistory()

  const { tab = TABS[0] } = queryString.parse(search) as { tab: string }
  const handleChangeTab = useCallback(
    (selectedTab: string) => {
      history.replace({
        search: queryString.stringify({ tab: selectedTab }),
      })
    },
    [history],
  )

  useEffect(() => {
    const currentSearch = queryString.parse(search, {
      parseNumbers: true,
      parseBooleans: true,
      arrayFormat: 'index',
    })

    if (!currentSearch.tab) {
      history.replace({
        search: queryString.stringify({ ...currentSearch, tab: TABS[0] }),
      })
    }
  }, [history, search, TABS])

  const fetchClientsAggregationList = useCallback(
    (data: any) => {
      const params = {
        ...data,
        filters: {
          ...data.filters,
        },
      }
      listClientsAggregation(params)
    },
    [listClientsAggregation],
  )

  const debounceListClientsAggregation = useMemo(
    () => debounceEventHandler(fetchClientsAggregationList, 500),
    [fetchClientsAggregationList],
  )

  useEffect(() => {
    debounceListClientsAggregation({
      page: 0,
      perPage: PER_PAGE,
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [filters, orderBy, debounceListClientsAggregation])

  useEffect(() => {
    listClients()
  }, [listClients])

  const filtersConfig = useMemo(
    () =>
      filterArray.map((item) => ({
        ...item,
        type: isParticipant && item.field === 'clientStatus' ? 'empty' : item.type,
        options:
          item.field === 'clients'
            ? clientsData?.map(({ clientName }) => ({
                value: clientName,
                label: clientName,
              }))
            : item.options,
      })),
    [clientsData, filterArray, isParticipant],
  )

  const { data: clientsAggregation, isLoading } = useMemo(
    () => fullClientsAggregation,
    [fullClientsAggregation],
  )

  const totalRow = useSummaryRow(clientsAggregation?.data, activeItems, {
    sumFields: [
      'totalLoanBalance',
      'totalParticipantExposureAmount',
      'minimumLineAmount',
      'maxLineAmount',
    ],
    weightAverageFields: [
      { field: 'utilization', sumField: 'totalLoanBalance', divisor: 'netAvailability' },
    ],
  })

  const loadMore = useCallback(() => {
    fetchClientsAggregationList({
      loadMore: true,
      page: Math.ceil(clientsAggregation?.data.length / PER_PAGE),
      perPage: PER_PAGE,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
  }, [clientsAggregation, orderBy, filters, fetchClientsAggregationList])

  const handleExportAggregation = useCallback(async () => {
    await exportPortfolioReview()
  }, [exportPortfolioReview])

  const [expanded, setExpanded] = useState([])

  const handleExpand = useCallback((label: string) => {
    setExpanded((values) =>
      values.includes(label) ? values.filter((item) => item !== label) : [...values, label],
    )
  }, [])
  useSetPageTitle(tab)

  return (
    <Box pt={1} pr={2}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card
            title={
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Tabs tabs={TABS} selected={tab} handleChange={handleChangeTab} />
              </Box>
            }
            withBorder={false}
          >
            {tab === TABS[0] && (
              <TableContainer
                className={cn({
                  [styles.clientsTable]: !isParticipant,
                  [styles.clientsTableParticipant]: isParticipant,
                })}
                onActiveRowsChange={setActiveItems}
                onActiveRowChange={setActiveItem}
              >
                <Form
                  validate={filtersValidate}
                  onSubmit={handleFiltersChange}
                  initialValues={filters}
                  mutators={{
                    setFieldData: ([field, value], state, { changeValue }) => {
                      changeValue(state, field, () => value)
                    },
                  }}
                  render={({ values, handleSubmit, form: { mutators } }) => (
                    <FilterContainer
                      filters={filtersConfig}
                      handleSubmit={handleSubmit}
                      mutators={mutators}
                      values={values}
                      appliedFilters={filters}
                      handleExportAggregation={handleExportAggregation}
                      appliedQuickFilter={quickFilter}
                      handleAppliedQuickFilterChange={handleQuickFilterChange}
                    />
                  )}
                />
                <Table>
                  <TableHead>
                    <TableFiltersRow
                      filters={filtersConfig}
                      orderBy={orderBy}
                      handleOrderChange={handleOrderChange}
                      isChildrenAtStart={true}
                    >
                      <TableCell className={genericSs.tableTextCenter} />
                    </TableFiltersRow>
                  </TableHead>
                  <TableBody id="scrollableTable">
                    {isLoading ? (
                      <TableLoader columnsCount={10} height={24} />
                    ) : (
                      clientsAggregation?.data &&
                      clientsAggregation.data.length > 0 && (
                        <InfiniteScroll
                          dataLength={clientsAggregation?.data.length}
                          next={loadMore}
                          hasMore={
                            clientsAggregation?.data.length < clientsAggregation?.totals.totalItems
                          }
                          loader={<TableLoader columnsCount={10} rowsCount={1} height={24} />}
                          scrollableTarget="scrollableTable"
                        >
                          {clientsAggregation.data.map((item, index) => {
                            const isExpanded = expanded.includes(item.id)

                            if (item.isPast) {
                              return (
                                <TableRow
                                  key={index}
                                  data-index={index}
                                  className={cn('activableRow', styles.pastClient)}
                                  isActiveRow={activeItems.includes(index)}
                                  isCurrentActiveRow={activeItem === index}
                                  onClick={(event) => handleSelectRow(event, index)}
                                >
                                  <TableCell className={genericSs.tableTextCenter}></TableCell>
                                  <TableCell className={genericSs.tableTextLeft}>
                                    <LinkButton
                                      component={Link}
                                      to={generatePath(ROUTES.CLIENT_PAGE, { id: item.id })}
                                    >
                                      {item.clientName}
                                    </LinkButton>
                                    <span className={genericSs.grayCard}>Past</span>
                                  </TableCell>

                                  {isParticipant && (
                                    <TableCell className={genericSs.tableTextRight}>
                                      <span className={genericSs.pricePrefix}>$</span>
                                      {formatPrice(item.totalParticipantExposureAmount)}
                                    </TableCell>
                                  )}
                                  <TableCell className={genericSs.tableTextRight}>
                                    <CopyToClipboard textToCopy={item.totalLoanBalance}>
                                      <span className={genericSs.pricePrefix}>$</span>
                                      {formatPrice(item.totalLoanBalance)}
                                    </CopyToClipboard>
                                  </TableCell>

                                  <TableCell colSpan={7} />
                                </TableRow>
                              )
                            }

                            return (
                              <React.Fragment key={index}>
                                <TableRow
                                  data-index={index}
                                  className={cn('activableRow')}
                                  isActiveRow={activeItems.includes(index)}
                                  isCurrentActiveRow={activeItem === index}
                                  index={index}
                                  onSelectRow={handleSelectRow}
                                >
                                  <TableCell className={genericSs.tableTextCenter}>
                                    <ExpandDetailIcon
                                      onClick={() => handleExpand(item.id)}
                                      isExpanded={isExpanded}
                                    />
                                  </TableCell>
                                  <TableCell className={genericSs.tableTextLeft}>
                                    <LinkButton
                                      component={Link}
                                      to={generatePath(ROUTES.CLIENT_PAGE, { id: item.id })}
                                    >
                                      {item.clientName}
                                    </LinkButton>
                                  </TableCell>

                                  {isParticipant && (
                                    <TableCell className={genericSs.tableTextRight}>
                                      <span className={genericSs.pricePrefix}>$</span>
                                      {formatPrice(item.totalParticipantExposureAmount)}
                                    </TableCell>
                                  )}

                                  <TableCell className={genericSs.tableTextRight}>
                                    <CopyToClipboard textToCopy={item.totalLoanBalance}>
                                      <span className={genericSs.pricePrefix}>$</span>
                                      {formatPrice(item.totalLoanBalance)}
                                    </CopyToClipboard>
                                  </TableCell>
                                  <TableCell className={genericSs.tableTextRight}>
                                    <Tooltip
                                      title={`Availability: $${formatPrice(item.netAvailability)}`}
                                    >
                                      {item.netAvailability ? (
                                        <span> {formatPercent(item.utilization, 0)}</span>
                                      ) : (
                                        <span> - </span>
                                      )}
                                    </Tooltip>
                                  </TableCell>
                                  {!isParticipant && (
                                    <TableCell className={genericSs.tableTextRight}>
                                      {item.risk ? item.risk.toFixed(2) : '-'}
                                    </TableCell>
                                  )}
                                  <TableCell className={genericSs.tableTextRight}>
                                    {formatMaxNumber(item.lineTurn, 50)}
                                  </TableCell>
                                  <TableCell className={genericSs.tableTextRight}>
                                    {formatPercent(item.arAdvance)}
                                  </TableCell>
                                  <TableCell className={genericSs.tableTextRight}>
                                    {formatPercent(item.inventoryAdvanceRateCost)}
                                  </TableCell>
                                  <TableCell className={genericSs.tableTextRight}>
                                    {formatPercent(item.nolv)}
                                  </TableCell>
                                  <TableCell className={genericSs.tableTextRight}>
                                    <span className={genericSs.pricePrefix}>$</span>
                                    {formatPrice(item.minimumLineAmount)}{' '}
                                  </TableCell>
                                  <TableCell className={genericSs.tableTextRight}>
                                    <span className={genericSs.pricePrefix}>$</span>
                                    {formatPrice(item.maxLineAmount)}
                                  </TableCell>
                                </TableRow>

                                {isExpanded && (
                                  <TableRow>
                                    <TableCell colSpan={10} className={styles.nestedRowColumn}>
                                      <div className={termStructureStyles.data}>
                                        <div className={termStructureStyles.column}>
                                          <div className={termStructureStyles.section}>
                                            <div
                                              className={cn(
                                                termStructureStyles.sectionTitle,
                                                styles.sectionTitle,
                                              )}
                                            >
                                              Deal Structure
                                            </div>

                                            <div className={termStructureStyles.sectionData}>
                                              {!isParticipant && (
                                                <div className={termStructureStyles.dataRow}>
                                                  <div className={termStructureStyles.label}>
                                                    Contract date
                                                  </div>
                                                  <div className={termStructureStyles.value}>
                                                    <RenderedValue
                                                      value={item.contractDate}
                                                      type={FieldType.Date}
                                                    />
                                                  </div>
                                                </div>
                                              )}

                                              <div className={termStructureStyles.dataRow}>
                                                <div className={termStructureStyles.label}>
                                                  Deal Type
                                                </div>
                                                <div className={termStructureStyles.value}>
                                                  <RenderedValue
                                                    value={item.loanType}
                                                    type={FieldType.Text}
                                                  />
                                                </div>
                                              </div>

                                              <div className={termStructureStyles.dataRow}>
                                                <div className={termStructureStyles.label}>
                                                  NOLV Advance Rate
                                                </div>
                                                <div className={termStructureStyles.value}>
                                                  <RenderedValue
                                                    value={item.inventoryAdvanceRateNolv}
                                                    type={FieldType.Percent}
                                                  />
                                                </div>
                                              </div>

                                              <div className={termStructureStyles.dataRow}>
                                                <div className={termStructureStyles.label}>
                                                  Interest Rate
                                                </div>
                                                <div className={termStructureStyles.value}>
                                                  <RenderedValue
                                                    value={item.interestRate}
                                                    type={FieldType.Percent}
                                                  />
                                                </div>
                                              </div>

                                              {!isParticipant &&
                                              item.totalParticipantExposureAmount ? (
                                                <>
                                                  <div className={termStructureStyles.dataRow}>
                                                    <div className={termStructureStyles.label}>
                                                      Total Participants Exposure
                                                    </div>
                                                    <div className={termStructureStyles.value}>
                                                      <RenderedValue
                                                        value={item.totalParticipantExposureAmount}
                                                        type={FieldType.Currency}
                                                      />
                                                      &nbsp;(
                                                      <RenderedValue
                                                        value={
                                                          item.totalParticipantExposureAmount /
                                                          item.totalLoanBalance
                                                        }
                                                        type={FieldType.Percent}
                                                      />
                                                      )
                                                    </div>
                                                  </div>

                                                  <div className={termStructureStyles.dataRow}>
                                                    <div className={termStructureStyles.label}>
                                                      NFE
                                                    </div>
                                                    <div className={termStructureStyles.value}>
                                                      <RenderedValue
                                                        value={item.totalNFE}
                                                        type={FieldType.Currency}
                                                      />
                                                      &nbsp;(
                                                      <RenderedValue
                                                        value={
                                                          item.totalNFE / item.totalLoanBalance
                                                        }
                                                        type={FieldType.Percent}
                                                      />
                                                      )
                                                    </div>
                                                  </div>
                                                </>
                                              ) : null}
                                            </div>
                                          </div>
                                        </div>

                                        <div className={termStructureStyles.column}>
                                          <div className={termStructureStyles.section}>
                                            <div
                                              className={cn(
                                                termStructureStyles.sectionTitle,
                                                styles.sectionTitle,
                                              )}
                                            >
                                              Company
                                            </div>

                                            <div className={termStructureStyles.sectionData}>
                                              <div className={termStructureStyles.dataRow}>
                                                <div className={termStructureStyles.label}>
                                                  Location
                                                </div>
                                                <div className={termStructureStyles.value}>
                                                  <RenderedValue
                                                    value={`${item.city} ${
                                                      item.city && item.state ? ', ' : ''
                                                    }${item.state}`}
                                                    type={FieldType.Text}
                                                  />
                                                </div>
                                              </div>

                                              <div className={termStructureStyles.dataRow}>
                                                <div className={termStructureStyles.label}>
                                                  Industry
                                                </div>
                                                <div className={termStructureStyles.value}>
                                                  <RenderedValue
                                                    value={item.industry}
                                                    type={FieldType.Text}
                                                  />
                                                </div>
                                              </div>

                                              <div className={termStructureStyles.dataRow}>
                                                <div className={termStructureStyles.label}>
                                                  Sub-industry
                                                </div>
                                                <div className={termStructureStyles.value}>
                                                  <RenderedValue
                                                    value={item.subIndustry}
                                                    type={FieldType.Text}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                )}
                              </React.Fragment>
                            )
                          })}
                        </InfiniteScroll>
                      )
                    )}
                    {clientsAggregation?.totals?.nonClientCashBalance ? (
                      <TableRow
                        key={clientsAggregation?.totals.totalItems + 1}
                        data-index={clientsAggregation?.totals.totalItems + 1}
                      >
                        <TableCell />
                        <TableCell className={genericSs.tableTextLeft}>
                          Non Client Balance
                        </TableCell>
                        <TableCell className={genericSs.tableTextRight}>
                          <span className={genericSs.pricePrefix}>$</span>
                          {formatPrice(clientsAggregation?.totals?.nonClientCashBalance)}
                        </TableCell>
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                      </TableRow>
                    ) : (
                      ''
                    )}
                    <MultiselectRow activeItems={activeItems} addColumn>
                      {isParticipant && (
                        <TableCell className={genericSs.tableTextRight}>
                          {formatter.format(totalRow?.totalParticipantExposureAmount)}
                        </TableCell>
                      )}
                      <TableCell className={genericSs.tableTextRight}>
                        {formatter.format(totalRow?.totalLoanBalance)}
                      </TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        {formatPercent(totalRow?.utilization)}
                      </TableCell>
                      <MultipleTableCells count={5} />
                      <TableCell className={genericSs.tableTextRight}>
                        {formatter.format(totalRow?.minimumLineAmount)}
                      </TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        {formatter.format(totalRow?.maxLineAmount)}
                      </TableCell>
                    </MultiselectRow>
                  </TableBody>
                  <TableFooter>
                    {!isParticipant && (
                      <TableRow>
                        <TableCell
                          colSpan={2}
                          className={cn(genericSs.tableTextLeft, styles.footerLabel)}
                        >
                          <div>Total Loan Balance</div>
                        </TableCell>
                        <TableCell className={genericSs.tableTextRight}>
                          <span className={genericSs.pricePrefix}>$</span>
                          {formatPrice(clientsAggregation?.totals?.totalLoanBalance)}
                        </TableCell>
                        <TableCell className={genericSs.tableTextRight}>
                          <Tooltip
                            title={`Availability: $${formatPrice(
                              clientsAggregation?.totals?.totalNetAvailability,
                            )}`}
                          >
                            <span>
                              {formatPercent(clientsAggregation?.totals?.totalUtilization)}
                            </span>
                          </Tooltip>
                        </TableCell>
                        <TableCell colSpan={5} />

                        <TableCell className={genericSs.tableTextRight}>
                          <span className={genericSs.pricePrefix}>$</span>
                          {formatPrice(clientsAggregation?.totals?.totalMinimumLineAmount)}
                        </TableCell>
                        <TableCell className={genericSs.tableTextRight}>
                          <span className={genericSs.pricePrefix}>$</span>
                          {formatPrice(clientsAggregation?.totals?.totalMaxLineAmount)}
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell
                        colSpan={2}
                        className={cn(genericSs.tableTextLeft, styles.footerLabel)}
                      >
                        {!isParticipant && <div>Dwight Loan Balance</div>}
                      </TableCell>

                      <TableCell className={genericSs.tableTextRight}>
                        <span className={genericSs.pricePrefix}>$</span>
                        {formatPrice(clientsAggregation?.totals?.nfe)}
                      </TableCell>
                      {isParticipant && (
                        <TableCell className={genericSs.tableTextRight}>
                          <span className={genericSs.pricePrefix}>$</span>
                          {formatPrice(clientsAggregation?.totals?.totalLoanBalance)}
                        </TableCell>
                      )}
                      <TableCell colSpan={isParticipant ? 6 : 7} />
                      <TableCell className={genericSs.tableTextRight}>
                        <div>
                          {clientsAggregation?.data?.length} /{' '}
                          {clientsAggregation?.totals?.totalItems}
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            )}

            {tab === TABS[1] && <ComplianceByClientTable />}
            {tab === TABS[2] && (
              <>
                <RiskRatingTable />
              </>
            )}
            {tab === TABS[3] && <FullPageFlags />}
            {tab === TABS[4] && <FinancialsComparison />}
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

export default PortfolioManagerDashboard
