import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import Treasury from './Treasury'

import {
  treasuryData,
  fundingWires,
  otherWires,
  participationWires,
  passthroughWires,
} from '../../redux/treasury/selectors'
import {
  listTreasury,
  listTreasuryFundingWires,
  listTreasuryOtherWires,
  listTreasuryParticipationWires,
  listTreasuryPassthroughWires,
  updateTreasury,
  createTreasury,
} from '../../redux/treasury/actions'

const selector = createStructuredSelector({
  treasuryData,
  fundingWires,
  otherWires,
  participationWires,
  passthroughWires,
})

const actions = {
  listTreasury,
  listTreasuryFundingWires,
  listTreasuryOtherWires,
  listTreasuryParticipationWires,
  listTreasuryPassthroughWires,
  updateTreasury,
  createTreasury,
}

export default connect(selector, actions)(Treasury)
