import React, { useEffect, useCallback, useState, useMemo } from 'react'
import { Form, FormRenderProps } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import setFieldData from 'final-form-set-field-data'
import InfiniteScroll from 'react-infinite-scroll-component'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import cn from 'classnames'
import genericSs from '@styles/generic.module.scss'
import styles from './AliasMapping.module.scss'
import { IAlias } from '@common/interfaces/bbc'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TableContainer from '../Common/TableContainer'
import TableBody from '../Common/TableBody'
import TableFiltersRow from '../Common/TableFiltersRow'
import CreatableSelectField from '../Common/CreatableSelectField'
import Tabs from '../Common/Tabs'
import { debounceEventHandler, formatPrice, voidHandler } from '../../helpers/helpers'
import { ALIAS_LIST_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import { tableFieldMapping } from '@common/constants/mappings'
import { textToTitleCase } from '@common/helpers/helpers'
import { formatDate, formatPercent } from '../../helpers/helpers'
import FilterContainer from '../Filters/FilterContainer'
import Button from '../Common/Button'
import TableLoader from '../Common/TableLoader'
import { ILoadingData } from '../../redux/types'
import SaveState from '../Common/SaveState'
import { WorkflowTypes } from '@common/interfaces/notes'
import EntityPreview from '../EntityPreview'
import useTable from '../../hooks/useTable'
import MultiSelectToolbar from '../MultiSelectToolbar/MultiSelectToolbar'

const mutators = {
  ...arrayMutators,
  setFieldData,
}

interface IProps {
  aliasesData: ILoadingData<{ data: IAlias[] }>
  listEntityInfo: (data: object) => Promise<{ data: any }>
  addEntityInfo: (data: object) => Promise<any>
  updateAlias?: (data: object) => Promise<any>
  deleteAlias?: (data: object) => Promise<any>
  listAliasMapping?: (id: string, data: object) => void
  hideAliasMapping?: () => void
  id?: string
  table?: keyof typeof tableFieldMapping
  clientName?: string | null
  tabOptions?: string[]
  handleTabChange?: (tab: string) => void
  currentTab?: string
  tableClassName?: string
  isProspect?: boolean
  readOnly?: boolean
  bbcId?: string
  ddReportingId?: string
}

const filtersValidate = buildFiltersValidateSchema(ALIAS_LIST_FILTERS_CONFIG)
const filtersDefaults = buildFiltersDefaults(ALIAS_LIST_FILTERS_CONFIG)

const AliasMappingRow = ({
  index,
  alias,
  isActiveRow,
  isCurrentActiveRow,
  onSelectRow,
  table,
  entityType,
  getRowStyle,
  loadMappings,
  handleAddMapping,
  onLinkedNameChange,
  onPredictionSelect,
  readOnly = false,
  isUW,
}: {
  index: number
  alias: IAlias
  isActiveRow: boolean
  isCurrentActiveRow: boolean
  onSelectRow: (event: any, index: number) => void
  table: string
  entityType: string
  getRowStyle: (index: number, confidence: number) => string
  loadMappings: (inputValue: string) => Promise<any>
  handleAddMapping: (mapping: string | string[]) => Promise<void>
  onLinkedNameChange: (index: number, value: any) => void
  onPredictionSelect: (index: number, value: any) => void
  readOnly?: boolean
  isUW?: boolean
}) => {
  const handleSelectRow = useCallback((event) => onSelectRow(event, index), [index, onSelectRow])

  const handleLinkedNameChange = useCallback(
    (event, newValue: any) => {
      if (readOnly) {
        return
      }

      //clear the input after the value is set

      onLinkedNameChange(index, newValue?.value || null)
    },
    [index, onLinkedNameChange, readOnly],
  )

  const handlePredictionSelect = useCallback(() => {
    if (readOnly) {
      return
    }
    onPredictionSelect(index, alias.prediction)
  }, [alias, index, onPredictionSelect, readOnly])

  if (!alias) {
    return null
  }

  return (
    <TableRow
      id={`mapping-table-row-${index}`}
      data-index={index}
      className={cn('activableRow', {
        activeRow: isActiveRow,
        currentActiveRow: isCurrentActiveRow,
      })}
      onClick={handleSelectRow}
    >
      <TableCell className={genericSs.tableTextLeft}>
        <Tooltip
          title={alias.originalName || textToTitleCase(alias.aka)}
          placement="top"
          disableTouchListener
        >
          <span>{alias.originalName || textToTitleCase(alias.aka)}</span>
        </Tooltip>
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>
        <CreatableSelectField
          label=""
          key={`${index}-${alias.aka}`}
          name={`aliases[${index}].linkedName`}
          placeholder={entityType}
          onAddValue={handleAddMapping}
          options={[]}
          isAsync
          loadOptions={loadMappings}
          onChangeCustom={handleLinkedNameChange}
          getOptionValue={(option) => option.value}
          disabled={readOnly}
          inTable
          value={
            alias.linkedName
              ? {
                  value: alias.linkedName,
                  label: alias.linkedName,
                }
              : null
          }
        />
      </TableCell>

      <TableCell className={genericSs.tableTextLeft}>
        {alias.prediction ? (
          <div
            className={cn(
              'prediction-cell',
              styles.percentMatchWithPrediction,
              getRowStyle(index, alias.predictionConfidence),
              {
                [styles.newValue]: alias.isNewEntity,
                [styles.shortLabel]: isUW && !!alias.entityId,
              },
            )}
            onClick={handlePredictionSelect}
          >
            {alias.isNewEntity ? (
              <Tooltip title="Create new entity" placement="top" disableTouchListener>
                <span className={styles.newValue}>+ {alias.prediction}</span>
              </Tooltip>
            ) : (
              alias.prediction
            )}
          </div>
        ) : (
          <div className={cn(styles.percentMatch, getRowStyle(index, alias.confidence))}>
            {formatPercent(alias.confidence, 0)}
          </div>
        )}
        {isUW && !!alias.entityId && (
          <EntityPreview
            id={alias.entityId}
            workflow={WorkflowTypes.dueDiligencePage}
            className={styles.entityPreviewIcon}
          />
        )}
      </TableCell>

      {table === 'aliases' ? (
        <>
          <TableCell className={genericSs.tableTextLeft}>
            <Tooltip title={alias?.clientName || ''} placement="top" disableTouchListener>
              <span>{alias?.clientName || ''}</span>
            </Tooltip>
          </TableCell>
          <TableCell className={genericSs.tableTextRight}>
            <Tooltip title={alias.lastMappedDate} placement="top" disableTouchListener>
              <span>{formatDate(alias.lastMappedDate)}</span>
            </Tooltip>
          </TableCell>
        </>
      ) : (
        <TableCell className={genericSs.tableTextRight}>
          ${formatPrice(alias.totalAmount, 0)}
        </TableCell>
      )}
    </TableRow>
  )
}

interface IAliasFormProps extends FormRenderProps<any> {
  isLoading?: boolean
  isSaving?: boolean
  isSaved?: boolean
  table?: keyof typeof tableFieldMapping
  tabOptions?: string[]
  handleTabChange?: (tab: string) => void
  currentTab?: string
  tableClassName?: string
  filters: any
  onFiltersChange: (data: any) => void
  orderBy: any
  onOrderChange: (field: string) => void
  itemsCount: number
  totalCount: number
  loadMore: () => void
  loadMappings: (inputValue: string) => Promise<any>
  handleAddMapping: (mapping: string | string[]) => Promise<void>
  readOnly?: boolean
  isUW?: boolean
  activeItem: number
  activeItems: number[]
  setActiveItem: (indexes: number) => void
  setActiveItems: (index: number[]) => void
  handleSelectRow: (event: any, index: number) => void
  handleUpdateAlias: (index: number, linkedName: string | null) => void
  handleMapToPrediction: () => Promise<void>
}

const AliasMappingForm = ({
  isLoading,
  isSaving,
  isSaved,
  table,
  tabOptions,
  handleTabChange,
  currentTab,
  tableClassName,
  filters,
  onFiltersChange,
  orderBy,
  onOrderChange,
  itemsCount,
  totalCount,
  loadMore,
  loadMappings,
  handleAddMapping,
  values,
  handleUpdateAlias,
  isUW,
  readOnly = false,
  activeItem,
  activeItems,
  setActiveItem,
  setActiveItems,
  handleSelectRow,
  handleMapToPrediction,
}: IAliasFormProps) => {
  const handleLinkedNameChange = useCallback(
    async (index, value) => {
      await handleUpdateAlias(index, value)
    },
    [handleUpdateAlias],
  )

  const handlePredictionSelect = useCallback(
    async (index, value) => {
      await handleUpdateAlias(index, value)
    },
    [handleUpdateAlias],
  )

  const handleSelectPrediction = useCallback(async () => {
    await handleMapToPrediction()
  }, [handleMapToPrediction])

  const handleLabelIndividual = useCallback(async () => {
    await handleUpdateAlias(activeItem, 'Individual')
  }, [handleUpdateAlias, activeItem])

  const getRowStyle = useCallback(
    (index: number, confidence: number) => {
      if (confidence < 0.55) {
        return activeItems.includes(index) ? styles.percentMatchLowActive : styles.percentMatchLow
      } else if (confidence < 0.75) {
        return activeItems.includes(index)
          ? styles.percentMatchMediumActive
          : styles.percentMatchMedium
      } else {
        return activeItems.includes(index) ? styles.percentMatchHighActive : styles.percentMatchHigh
      }
    },
    [activeItems],
  )
  const filterConfig = useMemo(() => {
    if (table === 'aliases') {
      const alias = { title: 'Alias', placeholder: 'Alias' }

      const masterEntityRecord = {
        title: 'Primary Entity Record',
        placeholder: 'Primary Entity Record',
      }
      const percentMatch = { title: 'Percent Match', placeholder: 'Percent Match' }
      return ALIAS_LIST_FILTERS_CONFIG.filter(
        ({ field }) => !['totalAmount', 'prediction'].includes(field),
      ).map((filter) => {
        if (filter.field === 'aka') {
          return {
            ...filter,
            ...alias,
          }
        }
        if (filter.field === 'linkedName') {
          return {
            ...filter,
            ...masterEntityRecord,
          }
        }
        if (filter.field === 'confidence') {
          return {
            ...filter,
            ...percentMatch,
          }
        }
        return filter
      })
    }

    return ALIAS_LIST_FILTERS_CONFIG.filter(({ field }) =>
      ['aka', 'linkedName', 'prediction', 'totalAmount'].includes(field),
    ).map((filter) =>
      filter.field === 'linkedName' &&
      ['client_ap_raw', 'client_ap_summary', 'prospect_ap_raw', 'prospect_ap'].includes(table)
        ? {
            ...filter,
            title: 'Vendor',
          }
        : filter,
    )
  }, [table])

  // if table is client_ar_raw or prospect_ar, then set entityType to "customer"
  const entityType = useMemo(() => {
    if (table === 'client_ar_raw' || table === 'prospect_ar') {
      return 'Customer'
    } else if (table === 'client_ap_raw' || table === 'prospect_ap') {
      return 'Vendor'
    } else {
      return 'Entity'
    }
  }, [table])

  const isARAPMapping = useMemo(
    () =>
      [
        'client_ar_raw',
        'client_ap_raw',
        'client_ap',
        'client_ar_summary',
        'client_ap_summary',
        'prospect_ap_raw',
        'prospect_ar_raw',
        'prospect_ap',
        'prospect_ar',
      ].includes(table),
    [table],
  )

  const totalSum = useMemo(() => {
    const sum = activeItems.reduce((sum: number, index: number) => {
      return sum + values?.aliases[index]?.totalAmount
    }, 0)
    return `$${formatPrice(sum)}`
  }, [values?.aliases, activeItems])

  const handleResetActiveItems = useCallback(() => {
    setActiveItems([])
  }, [setActiveItems])

  return (
    <TableContainer
      className={cn(
        {
          [styles.aliasTable]: table === 'aliases',
          [styles.table]: table !== 'aliases',
        },
        tableClassName,
      )}
      isActivable
      onActiveRowsChange={setActiveItems}
      onActiveRowChange={setActiveItem}
    >
      {tabOptions && (
        <div className={styles.tabContainer}>
          <Tabs tabs={tabOptions} selected={currentTab} handleChange={handleTabChange} />
        </div>
      )}

      <div className={styles.tableHeaderContainer}>
        <Form
          validate={filtersValidate}
          onSubmit={onFiltersChange}
          initialValues={filters}
          mutators={{
            setFieldData: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value)
            },
          }}
          render={({ values: filterValues, handleSubmit, form: { mutators: filterMutators } }) => (
            <FilterContainer
              filters={filterConfig}
              handleSubmit={handleSubmit}
              mutators={filterMutators}
              values={filterValues}
              appliedFilters={filters}
            />
          )}
        />
      </div>
      <Table>
        <TableHead>
          <TableFiltersRow
            filters={filterConfig}
            orderBy={orderBy}
            handleOrderChange={onOrderChange}
          />
        </TableHead>
        <TableBody id="scrollableTable">
          {isLoading ? (
            <TableLoader columnsCount={filterConfig.length} height={26} />
          ) : (
            values.aliases && (
              <InfiniteScroll
                dataLength={itemsCount}
                next={loadMore}
                hasMore={itemsCount < totalCount}
                loader={
                  <TableLoader columnsCount={filterConfig.length} rowsCount={1} height={26} />
                }
                scrollableTarget="scrollableTable"
              >
                <FieldArray name="aliases">
                  {({ fields }) =>
                    fields.map((name, index) => (
                      <AliasMappingRow
                        key={name}
                        index={index}
                        alias={values.aliases[index]}
                        isActiveRow={activeItems.includes(index)}
                        isCurrentActiveRow={activeItem === index}
                        onSelectRow={handleSelectRow}
                        table={table}
                        entityType={entityType}
                        getRowStyle={getRowStyle}
                        loadMappings={loadMappings}
                        handleAddMapping={handleAddMapping}
                        onLinkedNameChange={handleLinkedNameChange}
                        onPredictionSelect={handlePredictionSelect}
                        readOnly={readOnly}
                        isUW={isUW}
                      />
                    ))
                  }
                </FieldArray>
              </InfiniteScroll>
            )
          )}
        </TableBody>
      </Table>
      <MultiSelectToolbar
        inModal={!isUW}
        activeItems={activeItems}
        totalSum={totalSum}
        resetActiveItems={handleResetActiveItems}
      >
        {!readOnly && activeItems.length > 1 && table !== 'aliases' && (
          <Box display="flex" alignItems="center" gap={1}>
            {isARAPMapping && (
              <Button
                color="primary"
                variant="outlined"
                onClick={handleLabelIndividual}
                disabled={isSaving}
              >
                Individual
              </Button>
            )}
            <Button
              color="primary"
              variant="outlined"
              onClick={handleSelectPrediction}
              disabled={isSaving}
            >
              Prediction
            </Button>
          </Box>
        )}
      </MultiSelectToolbar>

      <Box display="flex" alignItems="center" justifyContent="space-between">
        <div className={styles.legend}>
          <span className={genericSs.bold}>Key:</span>
          <span className={genericSs.greenTag}>Existing Entity</span>
          <span className={genericSs.blueTag}>+ New Entity</span>
        </div>
        <SaveState isSaving={isSaving} isSaved={isSaved} />
      </Box>
    </TableContainer>
  )
}

const AliasMapping = ({
  aliasesData,
  listEntityInfo,
  addEntityInfo,
  updateAlias,
  deleteAlias,
  listAliasMapping,
  hideAliasMapping,
  id,
  table,
  clientName = null,
  tabOptions,
  handleTabChange,
  currentTab,
  tableClassName,
  isProspect = false,
  readOnly = false,
  bbcId = null,
  ddReportingId = null,
}: IProps) => {
  const { isLoading, aliases } = useMemo(
    () => ({
      isLoading: aliasesData.isLoading,
      isSaving: aliasesData.isSaving,
      aliases: aliasesData?.data?.data || [],
    }),
    [aliasesData],
  )
  const [isSaving, setIsSaving] = useState(false)
  const [isSaved, setIsSaved] = useState(false)
  const [refreshCounter, setRefreshCounter] = useState(1)

  const isAliasTableShowing = useMemo(() => table === 'aliases', [table])

  const {
    filters,
    handleFiltersChange,
    handleOrderChange,
    orderBy,
    activeItem,
    activeItems,
    setActiveItem,
    setActiveItems,
    handleSelectRow,
  } = useTable({
    tableId: 'aliasMapping',
    filtersDefaults,
    sortDefault: {
      field: isAliasTableShowing ? 'confidence' : 'totalAmount',
      direction: isAliasTableShowing ? 'ASC' : 'DESC',
    },
  })

  const itemsCount = useMemo(() => aliases?.length, [aliases])
  const mappingKey = useMemo(() => tableFieldMapping?.[table]?.field, [table])
  const totalCount = useMemo(() => (aliases ? aliases[0]?.totalItems : 0), [aliases])
  const isUW = useMemo(() => !!ddReportingId, [ddReportingId])

  useEffect(() => {
    return () => {
      hideAliasMapping()
    }
  }, [id, hideAliasMapping])

  const handleListAliases = useCallback(
    async (data: object) => {
      if (id) {
        await listAliasMapping(id, { ...data, table, isProspect })
      }
      if (refreshCounter > 1) {
        setActiveItems([])
        setIsSaved(true)
        setIsSaving(false)
      }
    },
    [id, listAliasMapping, table, isProspect, refreshCounter, setActiveItems],
  )
  const debounceHandleListAliases = useMemo(
    () => debounceEventHandler(handleListAliases, 500),
    [handleListAliases],
  )

  useEffect(() => {
    refreshCounter &&
      debounceHandleListAliases({
        page: 0,
        perPage: PER_PAGE,
        filters,
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
        useCache: refreshCounter > 1,
        skipLoader: refreshCounter > 1,
      })
  }, [filters, orderBy, debounceHandleListAliases, refreshCounter])

  const loadMappings = useCallback(
    async (inputValue: string) => {
      const res = await listEntityInfo({
        name: inputValue,
        isProspect,
      })
      return res.data.map((mapping: any) => ({
        id: mapping.id,
        value: mapping.name,
        label: mapping.name,
      }))
    },
    [listEntityInfo, isProspect],
  )

  const loadMore = useCallback(
    () =>
      handleListAliases({
        loadMore: true,
        page: Math.ceil(itemsCount / PER_PAGE),
        perPage: Number(PER_PAGE),
        filters,
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
      }),
    [filters, orderBy, handleListAliases, itemsCount],
  )

  const initialValues = useMemo(
    () => ({
      aliases: aliases?.map((alias) => ({
        id: alias.id,
        aka: alias.aka,
        linkedName: alias.linkedName ? alias.linkedName : '',
        prediction: alias.prediction ? alias.prediction : '',
        confidence: alias.confidence,
        clientName: alias?.clientName,
        lastMappedDate: alias?.lastMappedDate,
        isNewEntity: alias?.isNewEntity,
        entityId: alias?.entityId,
        totalAmount: alias?.totalAmount,
      })),
    }),
    [aliases],
  )

  const handleAddMapping = useCallback(
    async (mapping: string | string[]) =>
      addEntityInfo({ name: mapping, type: mappingKey, clientName, isProspect }),
    [addEntityInfo, mappingKey, clientName, isProspect],
  )

  const multiSelectedAkas = useMemo(
    () => (activeItems.length > 1 ? activeItems.map((index) => aliases[index].aka) : []),
    [activeItems, aliases],
  )

  const handleUpdateAlias = useCallback(
    async (index: number, linkedName: string | null) => {
      setIsSaving(true)
      setIsSaved(false)
      const currentAka = aliases?.[index]?.aka
      const finalMultiSelectedAkas = [...new Set([...multiSelectedAkas, currentAka])]
      if (linkedName) {
        await handleAddMapping(linkedName)
        await updateAlias({
          aka: finalMultiSelectedAkas,
          linkedName,
          isSilent: true,
          isProspect,
          table,
          clientName,
          bbcId,
          ddReportingId,
        })
      } else {
        await deleteAlias({
          aka: finalMultiSelectedAkas,
          isSilent: true,
          isProspect,
          table,
          clientName,
        })
      }
      setRefreshCounter((prev) => prev + 1)
    },
    [
      updateAlias,
      deleteAlias,
      multiSelectedAkas,
      aliases,
      clientName,
      isProspect,
      table,
      handleAddMapping,
      bbcId,
      ddReportingId,
    ],
  )
  const predictedLinkedNames = useMemo(
    () => activeItems.map((index) => aliases[index].prediction),
    [activeItems, aliases],
  )

  const handleTabChangeAndReset = useCallback(
    (tab: string) => {
      setRefreshCounter(1)
      handleTabChange(tab)
    },
    [handleTabChange, setRefreshCounter],
  )

  const handleMapToPrediction = useCallback(async () => {
    setIsSaving(true)
    setIsSaved(false)
    await handleAddMapping(predictedLinkedNames)
    await updateAlias({
      aka: multiSelectedAkas,
      linkedName: predictedLinkedNames,
      isSilent: true,
      isProspect,
      table,
      clientName,
    })

    setRefreshCounter((prev) => prev + 1)
  }, [
    updateAlias,
    multiSelectedAkas,
    predictedLinkedNames,
    clientName,
    isProspect,
    table,
    handleAddMapping,
  ])

  return (
    <Form
      onSubmit={voidHandler}
      initialValues={initialValues}
      mutators={mutators}
      render={(formProps) => (
        <>
          <AliasMappingForm
            {...formProps}
            isLoading={isLoading}
            isSaving={isSaving}
            isSaved={isSaved}
            table={table}
            tabOptions={tabOptions}
            handleTabChange={handleTabChangeAndReset}
            currentTab={currentTab}
            tableClassName={tableClassName}
            orderBy={orderBy}
            onOrderChange={handleOrderChange}
            filters={filters}
            onFiltersChange={handleFiltersChange}
            itemsCount={itemsCount}
            totalCount={totalCount}
            loadMore={loadMore}
            loadMappings={loadMappings}
            handleAddMapping={handleAddMapping}
            readOnly={readOnly}
            isUW={isUW}
            activeItem={activeItem}
            activeItems={activeItems}
            setActiveItem={setActiveItem}
            setActiveItems={setActiveItems}
            handleSelectRow={handleSelectRow}
            handleUpdateAlias={handleUpdateAlias}
            handleMapToPrediction={handleMapToPrediction}
          />
        </>
      )}
    />
  )
}

export default AliasMapping
