import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import cn from 'classnames'

import genericSs from '@styles/generic.module.scss'
import styles from './ClientTermLoans.module.scss'

import { formatPercent, formatPrice } from '../../../helpers/helpers'
import { IClientInfo } from '@common/interfaces/client'
import { ITermLoan } from '@common/interfaces/termLoan'
import Card from '../../Common/Card'
import Table from '../../Common/Table'
import TableHead from '../../Common/TableHead'
import TableRow from '../../Common/TableRow'
import TableCell from '../../Common/TableCell'
import TableContainer from '../../Common/TableContainer'
import TableBody from '../../Common/TableBody'
import { ReactComponent as TermLoanIcon } from '@assets/images/term-loan-icon.svg'
import { ROUTES } from '../../../constants/routes'
import { termLoanFieldConfig } from '../../ClientTermLoans/ClientTermLoans'
import { getFieldValue } from '../ClientHelpers'
import { BoxLink, ExpandDetailIcon } from '../../Common/Icons'
import ClientTermLoanAmortization from '../../../components/ClientTermLoans/ClientTermLoanAmortization'
import Modal from '../../../components/Common/Modal'
import { Skeleton } from '@mui/material'
import AddButton from '../AddButton'

interface IProps {
  clientInfo: IClientInfo
  listTermLoans: (id: string) => void
  listAmortizationSchedule: (clientId: string, termLoanId: string) => void
  termLoans: ITermLoan[]
}

const ClientTermLoans = ({
  clientInfo,
  termLoans,
  listTermLoans,
  listAmortizationSchedule,
}: IProps) => {
  const [refreshCounter, setRefreshCounter] = useState(1)
  const [openAmortScheduleId, setOpenAmortScheduleId] = useState<string | null>(null)
  const [amortizationIdLoading, setAmortizationIdLoading] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const clientId = useMemo(() => clientInfo?.id, [clientInfo])

  const handleOpenAmortSchedule = useCallback((id: string) => {
    setOpenAmortScheduleId(id)
  }, [])

  const handleLoadAmortization = useCallback(
    async (termLoanId) => {
      setAmortizationIdLoading(termLoanId)
      await listAmortizationSchedule(clientId, termLoanId)
      setAmortizationIdLoading(null)
    },
    [clientId, listAmortizationSchedule],
  )

  useEffect(() => {
    if (openAmortScheduleId && refreshCounter) {
      handleLoadAmortization(openAmortScheduleId)
    }
  }, [openAmortScheduleId, handleLoadAmortization, refreshCounter])

  useEffect(() => {
    if (clientInfo) {
      setIsLoading(true)
      listTermLoans(clientInfo?.id)
      setIsLoading(false)
    }
  }, [listTermLoans, clientInfo])

  const [expanded, setExpanded] = useState([])

  const handleExpand = useCallback((label: string) => {
    setExpanded((values) =>
      values.includes(label) ? values.filter((item) => item !== label) : [...values, label],
    )
  }, [])

  if (isLoading) {
    return <Skeleton variant="rectangular" height={300} />
  }

  if (termLoans?.length === 0) {
    return null
  }

  return (
    <Card
      id="term-loans"
      title={
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid container item xs={6}>
            <TermLoanIcon className={styles.icon} />
            <span>Term loans</span>
          </Grid>
          <Grid item xs={6} container justifyContent={'flex-end'}>
            {clientId && (
              <Link
                component={RouterLink}
                to={generatePath(ROUTES.CLIENT_SETUP_LOAN_STRUCTURE_TERM, { id: clientId })}
              >
                <AddButton />
              </Link>
            )}
          </Grid>
        </Grid>
      }
    >
      <TableContainer className={styles.table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={genericSs.tableTextCenter}> </TableCell>
              <TableCell className={genericSs.tableTextLeft}>Collateral Name</TableCell>
              <TableCell className={genericSs.tableTextRight}>Loan amount</TableCell>
              <TableCell className={genericSs.tableTextRight}>Balance</TableCell>
              <TableCell className={genericSs.tableTextLeft}>Rate</TableCell>
              <TableCell className={genericSs.tableTextCenter}>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody id="scrollableTable">
            {termLoans.map((item) => {
              const isExpanded = expanded.includes(item.id)
              const isLoading = amortizationIdLoading === item.id
              return (
                <React.Fragment key={item.id}>
                  <TableRow
                    key={item.id}
                    className={cn('activableRow', {
                      activeRow: isExpanded,
                      currentActiveRow: isExpanded,
                    })}
                  >
                    <TableCell className={genericSs.tableTextCenter}>
                      <ExpandDetailIcon
                        onClick={() => handleExpand(item.id)}
                        isExpanded={isExpanded}
                      />
                    </TableCell>
                    <TableCell className={genericSs.tableTextLeft}>{item.collateralName}</TableCell>
                    <TableCell className={genericSs.tableTextRight}>
                      {formatPrice(item.loanAmount)}
                    </TableCell>
                    <TableCell className={genericSs.tableTextRight}>
                      {formatPrice(item.loanBalance)}
                    </TableCell>
                    <TableCell className={genericSs.tableTextLeft}>
                      {formatPercent(item.rate, 2, 1)}
                    </TableCell>
                    <TableCell className={genericSs.tableTextLeft}>{item.status}</TableCell>
                  </TableRow>
                  {isExpanded && (
                    <>
                      <TableRow>
                        <TableCell className={genericSs.nestedRowColumn} colSpan={6}>
                          <Grid container rowSpacing={3} justifyContent={'start'}>
                            {termLoanFieldConfig.map((field) => {
                              const value = getFieldValue(item, field.name, field.type, 1)
                              if (field.name === 'boxLink' && value !== '-') {
                                return (
                                  <Grid item md={3} lg={2}>
                                    <h3>{field.label}</h3>
                                    <BoxLink key={field.name} link={value} />
                                  </Grid>
                                )
                              }
                              return (
                                <Grid item md={3} lg={2}>
                                  <h3>{field.label}</h3>
                                  <span className={styles.value}>{value}</span>
                                </Grid>
                              )
                            })}
                            <Grid item md={3} lg={2}>
                              <h3>Amortization Schedule</h3>
                              <span className={styles.value}>
                                <Link
                                  className={styles.link}
                                  onClick={() => handleOpenAmortSchedule(item.id)}
                                >
                                  View
                                </Link>
                              </span>
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                      <Modal
                        open={openAmortScheduleId === item.id}
                        onCancel={() => setOpenAmortScheduleId(null)}
                        title="Amortization Schedule"
                      >
                        <ClientTermLoanAmortization
                          isLoading={isLoading}
                          termLoan={item}
                          setRefreshCounter={setRefreshCounter}
                          inModal
                        />
                      </Modal>
                    </>
                  )}
                </React.Fragment>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  )
}

export default ClientTermLoans
