import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'

import styles from './Treasury.module.scss'
import genericSs from '@styles/generic.module.scss'

import { ReactComponent as DeleteIcon } from '@assets/images/delete-icon.svg'
import { debounceEventHandler, formatPrice } from '../../helpers/helpers'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TableContainer from '../Common/TableContainer'
import TableBody from '../Common/TableBody'
import { ILoadingData } from '../../redux/types'
import { IFilter } from '@common/constants/filters'
import TableFiltersRow from '../Common/TableFiltersRow'
import TableLoader from '../Common/TableLoader'
import { ITreasuryDetails, TreasuryAmountTypes } from '@common/interfaces/treasury'
import Checkbox from '../Common/Checkbox'
import WarningModal from '../WarningModal'
import Card from '../Common/Card'
import FilterContainer from '../Filters/FilterContainer'
import useTable from '../../hooks/useTable'
import MultiSelectToolbar from '../MultiSelectToolbar'
import TreasuryTableRow from './TreasuryTableRow'
import Button from '../Common/Button'

interface IProps {
  title: string
  wireType: TreasuryAmountTypes
  config: IFilter[]
  wires: ILoadingData<{ data: ITreasuryDetails[] }>
  listWires: (params?: object) => void
  updateTreasury: (params: object) => void
  refreshCounter?: number
}

const TreasuryTable = ({
  title,
  wireType,
  config,
  wires,
  listWires,
  updateTreasury,
  refreshCounter,
}: IProps) => {
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false)
  const [selectedWire, setSelectedWire] = useState(null)

  const sortDefault = useMemo(() => {
    if (wireType === TreasuryAmountTypes.Funding) {
      return {
        field: config.find(({ field }) => field === 'status')?.dbField || '',
        direction: 'ASC' as const,
      }
    } else {
      return {
        field: config.find(({ field }) => field === 'amount')?.dbField || '',
        direction: 'DESC' as const,
      }
    }
  }, [wireType, config])

  const tableId = useMemo(() => `treasury${wireType}`, [wireType])

  const {
    filters,
    orderBy,
    handleFiltersChange,
    handleOrderChange,
    activeItems,
    setActiveItems,
    handleSelectRow,
    activeItem,
    setActiveItem,
    resetActiveItems,
  } = useTable({
    tableId,
    sortDefault,
  })

  const debounceListWires = useMemo(() => debounceEventHandler(listWires, 500), [listWires])

  useEffect(() => {
    debounceListWires({
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, orderBy, debounceListWires, refreshCounter])

  const { data, isLoading } = useMemo(() => {
    return {
      data: wires?.data?.data,
      isLoading: wires?.isLoading,
    }
  }, [wires])

  const handleUpdateTreasuryData = useCallback(
    async (data: object) => {
      await updateTreasury(data)
      await listWires({
        filters,
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
        skipLoader: true,
      })
    },
    [updateTreasury, listWires, filters, orderBy],
  )

  const handleUpdateDebounce = useMemo(
    () =>
      debounceEventHandler(async (data: object) => {
        await handleUpdateTreasuryData(data)
      }, 1000),
    [handleUpdateTreasuryData],
  )

  const isAllChecked = useMemo(() => data?.every((item) => item.isChecked), [data])

  const handleDeleteWire = useCallback(async (id) => {
    setSelectedWire(id)
    setIsDeleteModalShown(true)
  }, [])

  const handleDeleteWires = useCallback(async (id) => {
    setIsDeleteModalShown(true)
  }, [])

  const handleDeleteTreasuryConfirm = useCallback(async () => {
    if (selectedWire) {
      await handleUpdateTreasuryData({ id: selectedWire, isDisplayed: false })
    } else {
      if (wireType === TreasuryAmountTypes.Other) {
        await handleUpdateTreasuryData({
          ids: activeItems.map((index) => data?.[index]?.id),
          isDisplayed: false,
        })
      } else if (wireType === TreasuryAmountTypes.Funding) {
        await handleUpdateTreasuryData({
          ids: activeItems
            .map((index) => data?.[index])
            .filter((wire) => !wire.borrowingBase?.status)
            .map((wire) => wire.id),
          isDisplayed: false,
        })
      }
    }
    setIsDeleteModalShown(false)
    setSelectedWire(null)
  }, [wireType, selectedWire, activeItems, data, handleUpdateTreasuryData])

  const handleDeleteTreasuryCancel = useCallback(() => {
    setIsDeleteModalShown(false)
    setSelectedWire(null)
  }, [])

  const handleUpdatedAllChecked = useCallback(
    (event, checked) => {
      handleUpdateTreasuryData({ wireType, isChecked: checked })
    },
    [wireType, handleUpdateTreasuryData],
  )

  const totalMessage = useMemo(
    () =>
      `Amount (SUM): $${formatPrice(
        activeItems.reduce((acc, item) => acc + +data?.[item]?.amount, 0),
      )}`,
    [activeItems, data],
  )

  return (
    <Card noHeaderMargin>
      <TableContainer
        className={styles.table}
        onActiveRowsChange={setActiveItems}
        onActiveRowChange={setActiveItem}
      >
        <Form
          onSubmit={handleFiltersChange}
          initialValues={filters}
          mutators={{
            setFieldData: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value)
            },
          }}
          render={({ values, handleSubmit, form: { mutators } }) => (
            <FilterContainer
              filters={[]}
              handleSubmit={handleSubmit}
              mutators={mutators}
              values={values}
              title={title}
              actionsSize={0}
            />
          )}
        />

        <Table>
          <TableHead>
            <TableFiltersRow
              filters={config}
              hideFilters
              isChildrenAtStart
              orderBy={orderBy}
              handleOrderChange={handleOrderChange}
            >
              <TableCell className={genericSs.tableTextLeft}>
                <Checkbox
                  className={styles.checkBox}
                  checked={isAllChecked}
                  key={+isAllChecked}
                  onChange={handleUpdatedAllChecked}
                />
              </TableCell>
            </TableFiltersRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableLoader columnsCount={config.length + 1} />
            ) : (
              data?.map((item, index) => (
                <TableRow
                  key={item.id}
                  isActiveRow={activeItems.includes(index)}
                  isCurrentActiveRow={activeItem === index}
                  index={index}
                  onSelectRow={handleSelectRow}
                >
                  <TreasuryTableRow
                    wireType={wireType}
                    wire={item}
                    handleUpdateTreasuryData={handleUpdateDebounce}
                    handleDeleteWire={handleDeleteWire}
                  />
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {isDeleteModalShown && (
        <WarningModal
          warningMessage=""
          onConfirm={handleDeleteTreasuryConfirm}
          onCancel={handleDeleteTreasuryCancel}
          confirmText="Yes, proceed"
          cancelText="Cancel"
          disabled={isLoading}
        />
      )}

      <MultiSelectToolbar
        activeItems={activeItems}
        totalSum={totalMessage}
        resetActiveItems={resetActiveItems}
      >
        {(wireType === TreasuryAmountTypes.Other ||
          (wireType === TreasuryAmountTypes.Funding &&
            activeItems.map((index) => data?.[index]).filter((wire) => !wire.borrowingBase?.status)
              .length > 0)) && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleDeleteWires}
            startIcon={<DeleteIcon className={genericSs.iconPathStrokeWhite} />}
          >
            Delete
          </Button>
        )}
      </MultiSelectToolbar>
    </Card>
  )
}

export default TreasuryTable
