import { combineReducers } from 'redux'

import * as actions from './actions'
import { loadingArrayReducer } from '../../helpers/helpers'
import { IOPSReporting } from '@common/interfaces/prospects'

export interface IClientIntake {
  savingArray: string[]
  opsReporting: IOPSReporting
}

export default combineReducers<IClientIntake>({
  savingArray: loadingArrayReducer([
    `${actions.prefix}/SAVE_CONTACT`,
    `${actions.prefix}/SAVE_COMPANY_INFO`,
    `${actions.prefix}/SAVE_PEOPLE_AND_TOOLS`,
    `${actions.prefix}/SUBMIT`,
  ]),
  opsReporting(state: IOPSReporting = null, action) {
    switch (action.type) {
      case actions.START_SUCCESS:
      case actions.SAVE_CONTACT_SUCCESS:
      case actions.SAVE_COMPANY_INFO_SUCCESS:
      case actions.SAVE_PEOPLE_AND_TOOLS_SUCCESS:
      case actions.GET_CODAT_LINK_SUCCESS:
      case actions.UPLOAD_DOCUMENT_SUCCESS:
      case actions.DELETE_DOCUMENT_SUCCESS:
      case actions.SUBMIT_SUCCESS:
        if (!state) {
          return action.data
        }

        return {
          ...state,
          ...action.data,
          clientInfo: {
            ...(state.clientInfo || {}),
            ...(action.data?.clientInfo || {}),
          },
        }
      default:
        return state
    }
  },
})
