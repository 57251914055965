import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import { useParams } from 'react-router'
import { Form } from 'react-final-form'
import cn from 'classnames'
import Box from '@mui/material/Box'
import styles from './BBCIneligibleARTable.module.scss'
import genericSs from '@styles/generic.module.scss'
import MultiselectRow from '../MultiselectRow'
import Card from '../Common/Card'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableContainer from '../Common/TableContainer'
import TableBody from '../Common/TableBody'
import TableFooter from '../Common/TableFooter'
import { IARIneligibleStats, IClientCollateral } from '@common/interfaces/bbc'
import {
  debounceEventHandler,
  formatDate,
  formatPrice,
  transformNegativeToZero,
} from '../../helpers/helpers'
import TableFiltersRow from '../Common/TableFiltersRow'
import FullscreenModal from '../Common/FullscreenModal'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import {
  BBC_INELIGIBLE_AR_LIST_FILTERS_CONFIG,
  AR_INELIGIBLE_STATS_LABELS,
  AR_INELIGIBLE_STATS_FILTERS_CONFIG,
} from '@common/constants/filters'
import BBCIneligibleARDetails from '../BBCIneligibleARDetails'
import FilterContainer from '../Filters/FilterContainer'
import { ExpandAndMinimize } from '../Common/Icons/Icons'
import { ILoadingData } from '../../redux/types'
import TableLoader from '../Common/TableLoader'
import { ExpandDetailIcon } from '../Common/Icons/Icons'
import useTable from '../../hooks/useTable'
import useTrackVisualizationsTable from '../../hooks/useTrackVisualizationsTable'
import { CATEGORIES } from '@common/constants/tracking'

interface IProps {
  isLoadingBBC: boolean
  clientCollaterals: IClientCollateral[]
  data: ILoadingData<{ data: IARIneligibleStats[] }>
  listARIneligibleStats: (id: string, data: object) => void
  refreshCount: number
}

const filtersValidate = buildFiltersValidateSchema(BBC_INELIGIBLE_AR_LIST_FILTERS_CONFIG)
const filtersDefaults = buildFiltersDefaults(BBC_INELIGIBLE_AR_LIST_FILTERS_CONFIG)

const FIELDS_CONFIG: {
  fields: Array<keyof IClientCollateral>
  total: keyof IClientCollateral
  totalIneligible: keyof IClientCollateral
} = {
  fields: [
    'over_90DaysAndCreditsPastDue',
    'crossAge',
    'ineligibleDebtors',
    'contras',
    'concentration',
    'creditLimits',
    'arOtherMiscReserves',
  ],
  total: 'totalReceivables',
  totalIneligible: 'totalIneligibleAr',
}

const BBCIneligibleARTable = ({
  isLoadingBBC,
  clientCollaterals,
  data,
  listARIneligibleStats,
  refreshCount,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const wrapperRef = useRef(null)

  const { isLoading, arIneligibleStats } = useMemo(
    () => ({
      isLoading: data.isLoading,
      arIneligibleStats: data?.data?.data || [],
    }),
    [data],
  )
  const [expanded, setExpanded] = useState([])
  const [isModalShown, setIsModalShown] = useState(false)

  const {
    filters,
    handleFiltersChange,
    handleOrderChange,
    orderBy,
    activeItem,
    activeItems,
    setActiveItem,
    setActiveItems,
    handleSelectRow,
  } = useTable({
    tableId: 'ineligibleAr',
    filtersDefaults,
    sortDefault: {
      field: 'value_0',
      direction: 'DESC',
    },
  })

  const filtersConfig = useMemo(
    () =>
      AR_INELIGIBLE_STATS_FILTERS_CONFIG.filter(
        (filter) =>
          !filter.field.startsWith('value_') ||
          !!clientCollaterals[parseInt(filter.field.replace('value_', ''))],
      ).map((filter) => {
        if (!filter.field.startsWith('value_') || filter.field === 'value_0') {
          return filter
        }

        const index = +filter.field.replace('value_', '')
        return {
          ...filter,
          title: formatDate(clientCollaterals[index].recordDate),
        }
      }),
    [clientCollaterals],
  )

  const debounceFilterList = useMemo(
    () =>
      debounceEventHandler((data: object) => {
        listARIneligibleStats(id, data)
      }, 500),
    [id, listARIneligibleStats],
  )

  useEffect(() => {
    refreshCount &&
      debounceFilterList({
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
        filters,
      })
  }, [orderBy, filters, debounceFilterList, refreshCount])

  const totalRow = useMemo(
    () =>
      arIneligibleStats
        .filter((_, index) => activeItems.includes(index))
        .reduce(
          (total, item) => {
            item.values.forEach((value, vIndex) => {
              total.values[vIndex] += value
            })

            return total
          },
          { values: clientCollaterals.map(() => 0) },
        ),
    [clientCollaterals, activeItems, arIneligibleStats],
  )

  const handleExpand = useCallback(
    (label: string) => {
      if (expanded.length === 0) {
        setIsModalShown(true)
      }
      setExpanded((values) =>
        values.includes(label) ? values.filter((item) => item !== label) : [...values, label],
      )
    },
    [expanded],
  )

  const toggleModal = useCallback(() => {
    setIsModalShown((prev) => !prev)
  }, [])

  const isInitialized = useMemo(() => !!arIneligibleStats, [arIneligibleStats])

  const visualizationsParams = useMemo(
    () => ({
      borrowingBaseId: id,
    }),
    [id],
  )

  useTrackVisualizationsTable({
    isInitialized,
    category: CATEGORIES.ineligibleARTable,
    params: visualizationsParams,
    filtersConfig,
    filters,
    orderBy,
  })

  return (
    <FullscreenModal
      isOpen={isModalShown}
      setIsOpen={setIsModalShown}
      classes={{ body: styles.fullScreenModal }}
    >
      <Card id="ineligible-ar" noHeaderMargin ref={wrapperRef}>
        <TableContainer
          className={styles.table}
          onActiveRowsChange={setActiveItems}
          onActiveRowChange={setActiveItem}
          hasFooter
        >
          <Form
            validate={filtersValidate}
            onSubmit={handleFiltersChange}
            initialValues={filters}
            mutators={{
              setFieldData: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value)
              },
            }}
            render={({ values, handleSubmit, form: { mutators } }) => (
              <FilterContainer
                filters={filtersConfig}
                handleSubmit={handleSubmit}
                mutators={mutators}
                values={values}
                appliedFilters={filters}
                withFullSearch={false}
                title={
                  <Box mr={2}>
                    <h2>Ineligible AR</h2>
                  </Box>
                }
                actions={
                  <Box display="flex" justifyContent="space-between" alignItems="center" gap={1}>
                    <ExpandAndMinimize action={toggleModal} isExpanded={isModalShown} />
                  </Box>
                }
              />
            )}
          />
          <Table>
            <TableHead>
              <TableFiltersRow
                filters={filtersConfig}
                orderBy={orderBy}
                handleOrderChange={handleOrderChange}
                isChildrenAtStart
              >
                <TableCell />
              </TableFiltersRow>
            </TableHead>
            <TableBody id="scrollableIneligibleARTable">
              {isLoadingBBC || isLoading ? (
                <TableLoader
                  columnsCount={clientCollaterals.length ? clientCollaterals.length + 2 : 7}
                  rowsCount={7}
                  height={24}
                />
              ) : (
                arIneligibleStats.map(({ label, values, rows }, index) => {
                  const isExpanded = expanded.includes(label)
                  const hasChild = values.filter(Boolean).length > 0

                  return (
                    <React.Fragment key={label}>
                      <TableRow
                        data-index={index}
                        className={cn('activableRow', styles.parentRow, {
                          activeRow: activeItems.includes(index),
                          currentActiveRow: activeItem === index,
                        })}
                        onClick={(event) => handleSelectRow(event, index)}
                      >
                        <TableCell className={genericSs.tableTextCenter}>
                          {hasChild && (
                            <ExpandDetailIcon
                              onClick={() => handleExpand(label)}
                              isExpanded={isExpanded}
                            />
                          )}
                        </TableCell>
                        <TableCell className={genericSs.tableTextLeft}>
                          {AR_INELIGIBLE_STATS_LABELS[
                            label as keyof typeof AR_INELIGIBLE_STATS_LABELS
                          ] || label}
                        </TableCell>
                        {values.map((value: any, i: number) => (
                          <TableCell key={i} className={genericSs.tableTextRight}>
                            $ {formatPrice(value)}
                          </TableCell>
                        ))}
                      </TableRow>
                      {isExpanded && (
                        <TableRow>
                          <TableCell className={genericSs.nestedRowColumn} colSpan={7}>
                            <BBCIneligibleARDetails
                              arDetailStats={rows}
                              filters={filters}
                              label={label}
                            />
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  )
                })
              )}
              <MultiselectRow activeItems={activeItems} addColumn>
                {totalRow.values.map((value, index) => (
                  <TableCell key={index} className={genericSs.tableTextRight}>
                    $ {formatPrice(value)}
                  </TableCell>
                ))}
              </MultiselectRow>
            </TableBody>
            {isLoadingBBC ? (
              <TableFooter>
                <TableLoader columnsCount={7} rowsCount={2} />
              </TableFooter>
            ) : (
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={2} className={genericSs.tableTextLeft}>
                    Total Ineligible
                  </TableCell>
                  {clientCollaterals?.map((clientCollateral) => (
                    <TableCell key={clientCollateral.id} className={genericSs.tableTextRight}>
                      $ {formatPrice(clientCollateral[FIELDS_CONFIG.totalIneligible])}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} className={genericSs.tableTextLeft}>
                    Total Eligible
                  </TableCell>
                  {clientCollaterals?.map((clientCollateral) => (
                    <TableCell key={clientCollateral.id} className={genericSs.tableTextRight}>
                      $&nbsp;
                      {formatPrice(
                        transformNegativeToZero(
                          Number(clientCollateral[FIELDS_CONFIG.total]) -
                            Number(clientCollateral[FIELDS_CONFIG.totalIneligible]),
                        ),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </Card>
    </FullscreenModal>
  )
}

export default BBCIneligibleARTable
