import React, { useState, useCallback, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'
import Box from '@mui/material/Box'
import Tabs from '../../components/Common/Tabs'
import BDOHomepageTable from '../../components/BDOHomepageTable'
import ProspectAnalysisQueueTable from '../../components/ProspectAnalysisQueueTable'
import { matchPath, useHistory, useLocation } from 'react-router'
import queryString, { ParsedQuery } from 'query-string'
import { ROUTES } from '../../constants/routes'
import Card from '../../components/Common/Card'
const TABS = ['Prospects', 'Analysis Queue']

const BDOHomepage = ({ isBDO }: { isBDO: boolean }) => {
  const { pathname } = useLocation()
  const history = useHistory()

  const { search }: { search: string } = useLocation()

  const { tab = TABS[0] } = queryString.parse(search) as { tab: string }
  const [queryParams, setQueryParams] = useState<ParsedQuery>({ tab })

  const handleChangeTab = useCallback(
    (selectedTab: string) => {
      setQueryParams({ tab: selectedTab })
    },
    [setQueryParams],
  )
  useEffect(() => {
    history.replace({
      search: queryString.stringify(queryParams),
    })
  }, [queryParams, history])

  useSetPageTitle('Prospects')

  useEffect(() => {
    if (isBDO && matchPath(pathname, ROUTES.PROSPECTS)) {
      history.replace(ROUTES.HOMEPAGE)
    }
  }, [pathname, isBDO, history])

  return (
    <Box py={1} pr={2}>
      <Grid item xs={12}>
        <Card
          title={
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Tabs tabs={TABS} selected={tab} handleChange={handleChangeTab} />
            </Box>
          }
          withBorder={false}
        >
          {tab === TABS[0] && <BDOHomepageTable />}
          {tab === TABS[1] && <ProspectAnalysisQueueTable refreshCounter={1} />}
        </Card>
      </Grid>
    </Box>
  )
}

export default BDOHomepage
