export interface IFile {
  id: string
  name: string
  fileName?: string
  size?: number
  type?: string
  link?: string
  boxLink?: string
  sheetNames?: string[]
  sheetName?: string
  isSkipped?: boolean
  fileId?: string
  boxSignRequestId?: string
  boxSignStatus?: string
  boxSignEmbeddedUrl?: string
  createdAt?: string
  updatedAt?: string
  modifiedAt?: string
  isUploading?: boolean
  error?: string | null
}

export interface IFileSheet {
  id?: string
  fileId: string
  sheetName?: string
  data?: any
  createdAt?: string
}

export interface IFolder {
  id: string
  name: string
  type?: string
  items?: (IFolder | IFile)[]
}

export interface IFileProcessingError {
  id: string
  fileName: string
  sheetName: string
  error: string
  messagesList?: string[]
  code: string
}

export enum BoxSignStatus {
  converting = 'converting',
  created = 'created',
  sent = 'sent',
  viewed = 'viewed',
  signed = 'signed',
  cancelled = 'cancelled',
  declined = 'declined',
  expired = 'expired',
  error_converting = 'error_converting',
  error_sending = 'error_sending',
}
