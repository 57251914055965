import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ProspectAnalysisQueueTable from './ProspectAnalysisQueueTable'

import { prospectReporting } from '../../redux/prospect/selectors'
import { listProspectReporting, updateReporting } from '../../redux/prospect/actions'
import { isBDO, user } from '../../redux/profile/selectors'

const selector = createStructuredSelector({
  prospectReporting,
  isBDO,
  user,
})
const actions = {
  listProspectReporting,
  updateReporting,
}

export default connect(selector, actions)(ProspectAnalysisQueueTable)
