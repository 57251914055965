import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import Skeleton from '@mui/material/Skeleton'
import Link from '@mui/material/Link'
import cn from 'classnames'

import genericSs from '@styles/generic.module.scss'
import styles from './ProspectReportingSummaryTermStructure.module.scss'

import { ILoadingData } from '../../redux/types'
import { IProspectTermsSummaryInfo } from '@common/interfaces/prospects'
import { formatDate, formatTime } from '../../helpers/helpers'
import { ROUTES } from '../../constants/routes'
import ProspectReportingSummaryTermStructureData from './ProspectReportingSummaryTermStructureData'
import { textToTitleCase } from '@common/helpers/helpers'
import SelectField from '../Common/SelectField'

interface IProps {
  termStructureSummaryInfo: ILoadingData<IProspectTermsSummaryInfo>
  showTermStructureSummaryInfo: (id: string) => void
}

const ProspectReportingSummaryTermStructure = ({
  termStructureSummaryInfo,
  showTermStructureSummaryInfo,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const [compareTermDataId, setCompareTermDataId] = useState<string | null>(null)

  const { isLoading, termData, previousTermsData } = useMemo(
    () => ({
      isLoading: termStructureSummaryInfo.isLoading,
      termData: termStructureSummaryInfo.data?.data,
      previousTermsData: termStructureSummaryInfo.data?.totals?.previous || [],
    }),
    [termStructureSummaryInfo],
  )

  const compareOption = useMemo(
    () =>
      previousTermsData.map(({ id, status, recordDate, createdAt }) => ({
        value: id,
        label: [
          textToTitleCase(status || 'Original structure'),
          formatDate(recordDate),
          previousTermsData.find(
            (termData) =>
              termData.id !== id &&
              termData.status === status &&
              termData.recordDate === recordDate,
          )
            ? formatTime(createdAt)
            : '',
        ].join(' '),
      })),
    [previousTermsData],
  )

  useEffect(() => {
    showTermStructureSummaryInfo(id)
  }, [showTermStructureSummaryInfo, id])

  const handleCompareTermDataIdChange = useCallback(({ target: { value } }) => {
    setCompareTermDataId(value)
  }, [])

  const handleCompareTermDataIdClear = useCallback(() => {
    setCompareTermDataId(null)
  }, [])

  const previousData = useMemo(
    () =>
      compareTermDataId
        ? previousTermsData.find(({ id }) => id === compareTermDataId) || null
        : null,
    [previousTermsData, compareTermDataId],
  )

  if (termData && !termData.id) {
    return null
  }

  return (
    <div className={styles.container}>
      <div className={styles.headerWrapper}>
        <div className={styles.header}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>Term Structure</div>

            <Link
              component={RouterLink}
              to={
                termData?.id
                  ? generatePath(ROUTES.PROSPECT_EDIT_TERMS_PAGE, { id, termId: termData.id })
                  : generatePath(ROUTES.PROSPECT_MANAGE_TERMS_PAGE, { id })
              }
              className={styles.editLink}
            >
              Edit
            </Link>
          </div>

          <div className={cn(styles.subTitle, genericSs.textCapitalize)}>
            {isLoading ? (
              <Skeleton className={styles.subTitleLoader} width={85} height={20} />
            ) : termData ? (
              `${termData.status || ''} - ${formatDate(termData.recordDate)}`
            ) : (
              '-'
            )}
          </div>
        </div>
        {compareOption.length > 0 && (
          <div className={styles.actions}>
            <SelectField
              key={compareTermDataId}
              label="Compare"
              variant="outlined"
              className={styles.selectField}
              useFinalForm={false}
              name="compareTermDataId"
              options={compareOption}
              onChange={handleCompareTermDataIdChange}
              value={compareTermDataId}
              defaultValue=""
              withTopLabel
              handleClear={handleCompareTermDataIdClear}
            />
          </div>
        )}
      </div>

      <ProspectReportingSummaryTermStructureData data={termData} previousData={previousData} />
    </div>
  )
}

export default ProspectReportingSummaryTermStructure
