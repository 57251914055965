import React, { useCallback, useMemo, useState } from 'react'
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Button,
} from '@mui/material'
import {
  ExpandMore as ExpandMoreIcon,
  Code as CodeIcon,
  TableChart as TableChartIcon,
} from '@mui/icons-material'
import { IChatMessage } from '@common/interfaces/atlasai'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import styles from './../AtlasAIChat.module.scss'
import Table from '../../Common/Table/Table'
import { textToTitleCase } from '@common/helpers/helpers'
import TableHead from '../../Common/TableHead'
import { formatCellValue } from '../helpers/formatters'
import { formatTodayTimeOrDate } from '../../../helpers/helpers'
import cn from 'classnames'
import ChatFeedback from '../ChatFeedback'
import { formatMessage } from '../helpers/formatters'
const MAX_ROWS = 5

// Component to display query results in a table format
const QueryResultsTable = ({ queryResults }: { queryResults: string }) => {
  const [showAll, setShowAll] = useState(false)
  const results = useMemo(() => JSON.parse(queryResults), [queryResults])
  const headers = useMemo(() => (results?.length > 0 ? Object.keys(results?.[0]) : []), [results])

  const displayedResults = useMemo(() => {
    return showAll ? results : results.slice(0, MAX_ROWS)
  }, [results, showAll])

  const hasMoreRows = useMemo(() => {
    return results.length > MAX_ROWS
  }, [results])

  const handleShowAll = useCallback(() => {
    setShowAll((prev) => !prev)
  }, [])

  if (!results) {
    return null
  }
  try {
    return (
      <Box>
        <TableContainer component={Paper} variant="outlined" sx={{ maxHeight: 300 }}>
          <Table size="small" stickyHeader className={styles.resultsTable}>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableCell key={header}>{textToTitleCase(header?.replace(/_/g, ' '))}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {displayedResults.map((row: any, rowIndex: number) => (
                <TableRow key={rowIndex}>
                  {headers.map((header) => (
                    <TableCell key={`${rowIndex}-${header}`}>
                      {formatCellValue(row[header])}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {hasMoreRows && (
          <Button
            size="small"
            onClick={handleShowAll}
            sx={{ mt: 1 }}
            className={styles.showAllButton}
          >
            {showAll ? 'Show less' : `Show ${results.length - MAX_ROWS} more`}
          </Button>
        )}
      </Box>
    )
  } catch (error) {
    return <Typography variant="body2">Error displaying results: Invalid data format</Typography>
  }
}

interface IProps {
  message: IChatMessage
}

const ChatMessage: React.FC<IProps> = ({ message }) => {
  const isUser = useMemo(() => message.role === 'user', [message.role])

  return (
    <Box className={isUser ? styles.userMessage : styles.assistantMessage}>
      <Typography variant="body1" className={styles.messageContent}>
        {formatMessage(message.content)}
      </Typography>

      {message.sqlQuery && (
        <Accordion className={styles.sqlAccordion} sx={{ '&.Mui-expanded': { mb: 0, mt: 0 } }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={styles.expandIcon} />}
            className={styles.sqlSummary}
          >
            <CodeIcon fontSize="small" className={styles.codeIcon} />
            <span className={styles.sqlSummaryText}>View Query</span>
          </AccordionSummary>
          <AccordionDetails className={styles.sqlDetails}>
            <SyntaxHighlighter
              language="sql"
              customStyle={{
                margin: 0,
                borderRadius: '4px',
                fontSize: '12px',
                backgroundColor: '#f5f5f5',
              }}
            >
              {message.sqlQuery}
            </SyntaxHighlighter>
          </AccordionDetails>
        </Accordion>
      )}

      {message.queryResults && (
        <Accordion className={styles.resultsAccordion} sx={{ '&.Mui-expanded': { mb: 0, mt: 0 } }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={styles.expandIcon} />}
            className={styles.resultsSummary}
          >
            <TableChartIcon fontSize="small" className={styles.tableIcon} />
            <span className={styles.resultsSummaryText}>View Results</span>
          </AccordionSummary>
          <AccordionDetails className={styles.resultsDetails}>
            <QueryResultsTable queryResults={message.queryResults} />
          </AccordionDetails>
        </Accordion>
      )}

      <Box className={styles.messageFooter}>
        <Typography
          variant="caption"
          className={cn(styles.timestamp, {
            [styles.userTimestamp]: isUser,
          })}
        >
          {formatTodayTimeOrDate(message.createdAt)}
          {!isUser && message.timeToRespondInMs
            ? ` • ${(message.timeToRespondInMs / 1000).toFixed(1)}s`
            : null}
        </Typography>

        {!isUser && (
          <ChatFeedback
            messageId={message.id}
            feedbackType={message.feedbackType}
            savedFeedbackComment={message.feedbackComment}
          />
        )}
      </Box>
    </Box>
  )
}

export default ChatMessage
