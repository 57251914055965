import React, { useCallback, useState, useEffect } from 'react'
import {
  Box,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  CircularProgress,
} from '@mui/material'
import { Delete as DeleteIcon } from '@mui/icons-material'
import { IChatSession } from '@common/interfaces/atlasai'
import styles from './../AtlasAIChat.module.scss'
import WarningModal from '../../WarningModal'
import { formatDateCalendar } from '../../../helpers/helpers'
import cn from 'classnames'
import { PER_PAGE } from '@common/constants/filters'

interface IProps {
  open: boolean
  sessions: IChatSession[]
  sessionId: string | null
  isSessionsLoading: boolean
  isSessionsLoadingMore: boolean
  hasMoreSessions: boolean
  selectSession: (sessionId: string) => void
  deleteSession: (sessionId: string) => void
  loadMoreSessions: (params: { limit: number; offset: number }) => void
}

const ChatSidebar: React.FC<IProps> = ({
  open,
  sessions,
  sessionId,
  isSessionsLoading,
  isSessionsLoadingMore,
  hasMoreSessions,
  selectSession,
  deleteSession,
  loadMoreSessions,
}) => {
  const [sessionToDelete, setSessionToDelete] = useState<string | null>(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  useEffect(() => {
    if (open && sessions.length === 0 && !isSessionsLoading) {
      loadMoreSessions({ limit: PER_PAGE, offset: 0 })
    }
  }, [open, sessions.length, isSessionsLoading, loadMoreSessions])

  const handleDeleteClick = useCallback(
    (e: React.MouseEvent, sessionId: string) => {
      e.stopPropagation()
      setSessionToDelete(sessionId)
      setShowDeleteModal(true)
    },
    [setSessionToDelete, setShowDeleteModal],
  )

  const handleConfirmDelete = useCallback(() => {
    if (sessionToDelete) {
      deleteSession(sessionToDelete)
      setShowDeleteModal(false)
      setSessionToDelete(null)
    }
  }, [sessionToDelete, deleteSession])

  const handleCancelDelete = useCallback(() => {
    setShowDeleteModal(false)
    setSessionToDelete(null)
  }, [setShowDeleteModal, setSessionToDelete])

  const handleScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement>) => {
      const target = e.target as HTMLElement

      // Calculate how close to the bottom we are
      const scrollPosition = target.scrollTop + target.clientHeight
      const scrollHeight = target.scrollHeight
      const scrollPercentage = (scrollPosition / scrollHeight) * 100

      // Don't load more if we're already loading or there's no more data
      if (isSessionsLoadingMore || !hasMoreSessions) return

      // Load more when scrolled past 80% of the container
      if (scrollPercentage > 80) {
        loadMoreSessions({
          limit: PER_PAGE,
          offset: sessions.length,
        })
      }
    },
    [loadMoreSessions, isSessionsLoadingMore, hasMoreSessions, sessions.length],
  )

  return (
    <>
      <Box className={cn(styles.sidebar, { [styles.closed]: !open })}>
        <Box className={cn(styles.sidebarHeader, { [styles.closed]: !open })}>
          <Typography variant="h6">Chats</Typography>
        </Box>
        <Box className={styles.sidebarContent}>
          <Box className={styles.sessionList} onScroll={handleScroll}>
            <List disablePadding>
              {isSessionsLoading && sessions.length === 0 ? (
                <Box className={styles.loadingContainer}>
                  <CircularProgress size={24} />
                </Box>
              ) : sessions.length === 0 ? (
                <Box p={2}>
                  <Typography variant="body2" color="textSecondary">
                    No chat sessions found.
                  </Typography>
                </Box>
              ) : (
                <>
                  {sessions.map((session) => (
                    <ListItem key={session.id} disablePadding className={styles.sessionItem}>
                      <ListItemButton
                        selected={session.id === sessionId}
                        onClick={() => {
                          selectSession(session.id)
                        }}
                      >
                        <ListItemText
                          primary={session.name || 'Untitled Chat'}
                          secondary={
                            <>
                              <Typography variant="caption" component="span" color="textSecondary">
                                {formatDateCalendar(session.lastActive)}
                              </Typography>
                              <br />
                              <Typography
                                variant="caption"
                                component="span"
                                color="textSecondary"
                                className={styles.lastMessage}
                              >
                                {session.lastMessage}
                              </Typography>
                            </>
                          }
                        />
                        <IconButton
                          size="small"
                          className={styles.deleteButton}
                          onClick={(e) => handleDeleteClick(e, session.id)}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </ListItemButton>
                    </ListItem>
                  ))}
                  {isSessionsLoadingMore && (
                    <Box className={styles.loadingContainer} py={1}>
                      <CircularProgress size={20} />
                    </Box>
                  )}
                </>
              )}
            </List>
          </Box>
        </Box>
      </Box>

      {showDeleteModal && (
        <WarningModal
          title="Delete Chat Session"
          warningMessage="Are you sure you want to delete this chat session? This action cannot be undone."
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
          confirmText="Delete"
          cancelText="Cancel"
        />
      )}
    </>
  )
}

export default ChatSidebar
