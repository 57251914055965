import { combineReducers } from 'redux'
import * as actions from './actions'
import { INotesData } from '@common/interfaces/notes'
import { orderBy } from 'lodash'
import { loadingArrayReducer } from '../../helpers/helpers'

export interface INotesState {
  loadingArray: string[]
  isNotesShown: boolean
  notes: INotesData
  summary: string
}

export default combineReducers<INotesState>({
  loadingArray: loadingArrayReducer([actions.prefix], [actions.SUMMARIZE_NOTES_REQUEST]),
  summary: (state = null, action) => {
    switch (action.type) {
      case actions.SUMMARIZE_NOTES_SUCCESS:
        return action.data
      default:
        return state
    }
  },

  isNotesShown: (state = true, action) => {
    switch (action.type) {
      case actions.TOGGLE_NOTES:
        return !state
      case actions.TOGGLE_NOTES_OFF:
        return false
      case actions.TOGGLE_NOTES_ON:
        return true
      default:
        return state
    }
  },
  notes: (state = null, action) => {
    switch (action.type) {
      case actions.LIST_NOTES_SUCCESS:
        if (!action.loadMore) {
          return action.data
        } else {
          return {
            ...state,
            data: [...state.data, ...action.data.data],
          }
        }
      case actions.ADD_NOTE_SUCCESS:
        if (!state) return null
        if (action.data?.parentId) {
          return {
            ...state,
            data: state.data.map((item) => {
              if (item.id === action.data.parentId) {
                return {
                  ...item,
                  children: orderBy(
                    item?.children?.length > 0
                      ? [...(item?.children || []), action.data]
                      : [action.data],
                    ['pinned', 'resolved', 'createdAt'],
                    ['asc', 'desc', 'asc'],
                  ),
                }
              } else {
                return item
              }
            }),
          }
        } else if (action.data?.dragOrderChange) {
          return {
            ...state,
            total: state.total + 1,
            data:
              action.data?.sortDirection === 'ASC'
                ? [action.data, ...state.data.slice(0, -1)]
                : state.data,
          }
        }
        const sortedExistingNotes = orderBy(
          state.data,
          ['pinned', 'resolved', 'createdAt'],
          ['desc', 'asc', 'desc'],
        )
        return {
          ...state,
          total: state.total + 1,
          data: [action.data, ...sortedExistingNotes],
        }
      case actions.DELETE_NOTE_SUCCESS:
        if (!state) return null
        if (action.data?.parentId) {
          return {
            ...state,
            data: state.data.map((item) => {
              if (item.id === action.data.parentId) {
                return {
                  ...item,
                  children: item.children.filter((child) => child.id !== action.data.id),
                }
              } else {
                return item
              }
            }),
          }
        }
        return {
          ...state,
          total: state.total - 1,
          data: state.data.filter((item) => item.id !== action.data.id),
        }
      case actions.ADD_NOTE_TAG_SUCCESS:
        if (!state) return null
        return {
          ...state,
          total: state.total + 1,

          data: state.data.map((item) => {
            if (item.id === action.data?.noteId) {
              return {
                ...item,
                tags: [...item.tags, action.data.tag],
              }
            } else {
              return item
            }
          }),
        }
      case actions.DELETE_NOTE_TAG_SUCCESS:
        if (!state) return null
        return {
          ...state,
          total: state.total - 1,
          data: state.data.map((item) => {
            if (item.id === action.data?.noteId) {
              return {
                ...item,
                tags: item.tags.filter((tag) => tag !== action.data.tag),
              }
            } else {
              return item
            }
          }),
        }
      case actions.UPDATE_NOTE_SUCCESS:
        if (!state) return null
        if (action.data?.parentId) {
          return {
            ...state,
            data: state.data.map((item) => {
              if (item.id === action.data.parentId) {
                return {
                  ...item,
                  children: orderBy(
                    item.children.map((child) => {
                      if (child.id === action.data.id) {
                        return action.data
                      } else {
                        return child
                      }
                    }),
                    ['pinned', 'resolved', 'createdAt'],
                    ['asc', 'desc', 'asc'],
                  ),
                }
              } else {
                return item
              }
            }),
          }
        } else if (action.data?.dragOrderChange) {
          return state
        } else if (action.data?.isCallAgendaSort) {
          let itemRemoved = false
          const updatedData = orderBy(
            state.data
              .map((item) => {
                if (item.id === action.data.id) {
                  if (item.resolved !== action.data.resolved) {
                    itemRemoved = true
                    return null
                  }
                  return action.data
                }
                return item
              })
              .filter((item) => item !== null),
            ['dragOrder'],
            [action.data?.sortDirection?.toLowerCase() || 'asc'],
          )

          return {
            ...state,
            data: updatedData,
            total: itemRemoved ? state.total - 1 : state.total,
          }
        }
        const updatedData = orderBy(
          state.data.map((item) => {
            if (item.id === action.data.id) {
              return action.data
            }
            return item
          }),
          ['pinned', 'resolved', 'createdAt'],
          ['desc', 'asc', 'desc'],
        )

        return {
          ...state,
          data: updatedData,
          total: state.total,
        }
      case actions.HIDE_NOTES:
        return null

      default:
        return state
    }
  },
})
