import { IClientCollateral } from '../interfaces/bbc'

export const tableFieldMapping = {
  client_general_ledger: {
    field: 'debtor',
    originalName: 'original_debtor',
    amountField: 'abs(amount)',
  },
  client_ar_raw: {
    field: 'debtor',
    originalName: 'memo',
    amountField: 'balance',
  },
  client_ap_raw: {
    field: 'creditor',
    originalName: 'memo',
    amountField: 'balance',
  },
  client_ap: {
    field: 'creditor',
    originalName: 'memo',
    amountField: 'ap_total',
  },
  client_ar_summary: {
    field: 'debtor',
    originalName: 'memo',
    amountField: 'total',
  },
  client_ap_summary: {
    field: 'creditor',
    originalName: 'memo',
    amountField: 'total',
  },
  prospect_ap_raw: {
    field: 'creditor',
    originalName: 'memo',
    amountField: 'balance',
  },
  prospect_ar_raw: {
    field: 'debtor',
    originalName: 'memo',
    amountField: 'balance',
  },
  prospect_ap: {
    field: 'creditor',
    originalName: 'memo',
    amountField: 'ap_total',
  },
  prospect_ar: {
    field: 'debtor',
    originalName: 'memo',
    amountField: 'ar_total',
  },
  aliases: {
    field: 'debtor',
    originalName: 'aka',
    amountField: '',
  },
  prospect_aliases: {
    field: 'debtor',
    originalName: 'aka',
    amountField: '',
  },
}

export const INELIGIBLE_INVENTORY_FIELDS_CONFIG: {
  fields: Array<keyof IClientCollateral>
  total: keyof IClientCollateral
  totalIneligible: keyof IClientCollateral
} = {
  fields: [
    'ineligibleSkus',
    'ineligibleLocationsAndInTransit',
    'slowAgedOrExpired',
    'invOtherMiscReserves',
  ],
  total: 'totalInventory',
  totalIneligible: 'totalIneligibleInventory',
}
