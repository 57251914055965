import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import { useDragDropManager } from 'react-dnd'
import withScrolling from 'react-dnd-scrolling'

import Box from '@mui/material/Box'

import styles from './UWDefaultNotes.module.scss'

import { ReactComponent as ChevronUp } from '@assets/images/chevron-up.svg'
import { ReactComponent as ChevronDown } from '@assets/images/down-chevron.svg'
import { ILoadingData } from '../../redux/types'
import { IDefaultNote, IDefaultNoteOptionsData } from '@common/interfaces/dueDiligence'
import { DEFAULT_NOTES_LIST_FILTERS_CONFIG } from '@common/constants/filters'
import useTable from '../../hooks/useTable'
import FilterContainer from '../Filters/FilterContainer'
import { buildFiltersDefaults } from '../../helpers/filters'
import { debounceEventHandler } from '../../helpers/helpers'
import { useAutoScroll } from '../../hooks/useAutoScroll'
import UWDefaultNotesLoader from './UWDefaultNotesLoader'
import UWDefaultNotesSection from './UWDefaultNotesSection'
import CustomDragLayer from '../Notes/NotesFullScreenModal/CustomDragLayer'
import Tooltip from '@mui/material/Tooltip'
const filtersDefaults = buildFiltersDefaults(DEFAULT_NOTES_LIST_FILTERS_CONFIG)

const ScrollingComponent = withScrolling('div')

interface IProps {
  dueDiligenceDefaultNotesOptions: ILoadingData<IDefaultNoteOptionsData>
  dueDiligenceDefaultNotes: ILoadingData<{ data: IDefaultNote[] }>
  getDefaultNotesOptions: () => Promise<void>
  listDefaultNotes: (params: object) => Promise<void>
  createDefaultNote: (data: object) => Promise<void>
  updateDefaultNote: (id: string, data: object) => Promise<void>
  deleteDefaultNote: (id: string) => Promise<void>
}

const UWDefaultNotes = ({
  dueDiligenceDefaultNotesOptions,
  dueDiligenceDefaultNotes,
  getDefaultNotesOptions,
  listDefaultNotes,
  createDefaultNote,
  updateDefaultNote,
  deleteDefaultNote,
}: IProps) => {
  const [expandedTags, setExpandedTags] = useState<string[]>([])
  const [notesByTags, setNotesByTag] = useState<Record<string, IDefaultNote[]>>(
    {} as Record<string, IDefaultNote[]>,
  )
  const scrollContainerRef = useRef<HTMLDivElement>(null)

  const { updatePosition } = useAutoScroll(scrollContainerRef)
  const dragDropManager = useDragDropManager()
  const monitor = dragDropManager.getMonitor()

  const isDraggingRef = useRef(monitor.isDragging())

  useEffect(() => {
    getDefaultNotesOptions()
  }, [getDefaultNotesOptions])

  useEffect(() => {
    const unsubscribe = monitor.subscribeToOffsetChange(() => {
      const offset = monitor.getSourceClientOffset()?.y as number
      updatePosition({ position: offset, isScrollAllowed: true })
      isDraggingRef.current = monitor.isDragging()
    })
    return () => {
      unsubscribe()
    }
  }, [monitor, updatePosition])

  const { tags } = useMemo(
    () => ({
      tags: dueDiligenceDefaultNotesOptions.data?.tags || [],
    }),
    [dueDiligenceDefaultNotesOptions],
  )

  const { isLoading, isInitialized, notes } = useMemo(
    () => ({
      isLoading: dueDiligenceDefaultNotes.isLoading,
      isInitialized: !!dueDiligenceDefaultNotes.data,
      notes: dueDiligenceDefaultNotes.data?.data || [],
    }),
    [dueDiligenceDefaultNotes],
  )

  useEffect(() => {
    setNotesByTag(
      tags.reduce(
        (notesByTag, tag) => ({
          ...notesByTag,
          [tag]: notes
            .filter(({ tags }) => tags?.[0]?.tag === tag)
            .sort((a, b) => a.order - b.order),
        }),
        {} as Record<string, IDefaultNote[]>,
      ),
    )
  }, [notes, tags])

  const filtersConfig = useMemo(
    () =>
      DEFAULT_NOTES_LIST_FILTERS_CONFIG.map((item) => ({
        ...item,
        options:
          item.field === 'tag' ? tags.map((tag) => ({ value: tag, label: tag })) : item.options,
      })),
    [tags],
  )

  const { filters, handleFiltersChange } = useTable({
    tableId: 'uwDefaultNotes',
    filtersDefaults,
  })

  const filteredTags = useMemo(() => {
    if (!filters?.tag?.length) {
      return tags
    }

    return tags.filter((tag) => filters.tag.includes(tag))
  }, [filters?.tag, tags])

  const debounceListDefaultNotes = useMemo(
    () => debounceEventHandler(listDefaultNotes, 500),
    [listDefaultNotes],
  )

  useEffect(() => {
    debounceListDefaultNotes({
      filters,
    })
  }, [filters, debounceListDefaultNotes])

  const refetchDefaultNotes = useCallback(
    () =>
      listDefaultNotes({
        filters,
        skipLoader: true,
      }),
    [filters, listDefaultNotes],
  )

  const handleNoteCreate = useCallback(
    async (data: object) => {
      await createDefaultNote(data)

      await refetchDefaultNotes()
    },
    [createDefaultNote, refetchDefaultNotes],
  )

  const handleNoteUpdate = useCallback(
    async (noteId: string, data: Partial<IDefaultNote>) => {
      await updateDefaultNote(noteId, {
        note: data.note,
      })

      await refetchDefaultNotes()
    },
    [updateDefaultNote, refetchDefaultNotes],
  )

  const handleNoteMove = useCallback((tag: string, dragIndex: number, hoverIndex: number) => {
    setNotesByTag((notesByTags) => {
      const updatedNotesByTag = [...notesByTags[tag]]
      const [draggedNote] = updatedNotesByTag.splice(dragIndex, 1)
      updatedNotesByTag.splice(hoverIndex, 0, draggedNote)

      return {
        ...notesByTags,
        [tag]: updatedNotesByTag,
      }
    })
  }, [])

  const handleNoteDrop = useCallback(
    async (note, order) => {
      await updateDefaultNote(note.id, {
        order,
      })

      await refetchDefaultNotes()
    },
    [updateDefaultNote, refetchDefaultNotes],
  )

  const handleNoteDelete = useCallback(
    async (noteId: string) => {
      await deleteDefaultNote(noteId)

      await refetchDefaultNotes()
    },
    [deleteDefaultNote, refetchDefaultNotes],
  )

  const handleExpandTag = useCallback((tag: string) => {
    setExpandedTags((tags) =>
      tags.includes(tag) ? tags.filter((item) => item !== tag) : [...tags, tag],
    )
  }, [])

  const handleExpandAll = useCallback(() => {
    setExpandedTags((expandedTags) => (expandedTags.length > 0 ? [] : [...tags]))
  }, [tags])

  return (
    <Box display="flex" flexDirection="column">
      <Form
        onSubmit={handleFiltersChange}
        initialValues={filters}
        mutators={{
          setFieldData: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value)
          },
        }}
        render={({ values, handleSubmit, form: { mutators } }) => (
          <FilterContainer
            filters={filtersConfig}
            appliedFilters={filters}
            handleSubmit={handleSubmit}
            mutators={mutators}
            values={values}
            title={
              <Box className={styles.tagSectionTitle} onClick={handleExpandAll}>
                <Tooltip title={expandedTags.length > 0 ? 'Collapse all' : 'Expand all'}>
                  {expandedTags.length > 0 ? <ChevronUp /> : <ChevronDown />}
                </Tooltip>
              </Box>
            }
          />
        )}
      />

      <CustomDragLayer />

      <ScrollingComponent className={styles.notesList} ref={scrollContainerRef}>
        {isLoading && !isInitialized ? (
          <UWDefaultNotesLoader />
        ) : (
          filteredTags.map((tag) => (
            <UWDefaultNotesSection
              key={tag}
              tag={tag}
              isExpanded={expandedTags.includes(tag)}
              toggleExpand={handleExpandTag}
              notes={notesByTags[tag]}
              handleNoteCreate={handleNoteCreate}
              handleNoteUpdate={handleNoteUpdate}
              handleNoteMove={handleNoteMove}
              handleNoteDrop={handleNoteDrop}
              handleNoteDelete={handleNoteDelete}
              isNoteBeingDragged={!!isDraggingRef.current}
            />
          ))
        )}
      </ScrollingComponent>
    </Box>
  )
}

export default UWDefaultNotes
