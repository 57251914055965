import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import Note from './Note'

import { updateNote, deleteNote, addNoteTag } from '../../../redux/notes/actions'
import { user } from '../../../redux/profile/selectors'

const selector = createStructuredSelector({
  currentUser: user,
})
const actions = {
  updateNote,
  deleteNote,
  addNoteTag,
}

export default connect(selector, actions)(Note)
