import React, { useCallback, useEffect, useMemo } from 'react'
import Box from '@mui/material/Box'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'
import CollectionsPassThroughsTable from '../../components/CollectionsPassThroughsTable/'
import FundingWiresTable from '../../components/FundingWiresTable'
import Treasury from '../../components/Treasury'

import OutstandingParticipationWires from '../../components/OutstandingParticipationWires'
import { usePermissions } from '../../helpers/permissionContext'
import Card from '../../components/Common/Card'
import Grid from '@mui/material/Grid'
import Tabs from '../../components/Common/Tabs'
import { useHistory, useLocation } from 'react-router'
import queryString from 'query-string'

enum TabNames {
  Treasury = 'Treasury',
  Fundings = 'Fundings',
  PassThroughs = 'Pass-throughs',
  Participations = 'Participations',
  Transfers = 'Transfers',
}

const INTERNAL_TABS = [
  TabNames.Fundings,
  TabNames.PassThroughs,
  TabNames.Participations,
  TabNames.Treasury,
]
const EXTERNAL_TABS = [TabNames.Fundings, TabNames.Transfers]

const OutgoingWirePage = () => {
  useSetPageTitle('Wires')
  const { isParticipant } = usePermissions()

  const history = useHistory()

  const { search }: { search: string } = useLocation()

  const { tab = TabNames.Fundings } = queryString.parse(search) as { tab: string }

  const handleChangeTab = useCallback(
    (selectedTab: string) => {
      history.replace({
        search: queryString.stringify({ tab: selectedTab }),
      })
    },
    [history],
  )

  useEffect(() => {
    const currentSearch = queryString.parse(search, {
      parseNumbers: true,
      parseBooleans: true,
      arrayFormat: 'index',
    })

    if (!currentSearch.tab) {
      history.replace({
        search: queryString.stringify({ ...currentSearch, tab }),
      })
    }
  }, [history, search, tab])

  const effectiveTabs = useMemo(
    () => (isParticipant ? EXTERNAL_TABS : INTERNAL_TABS),
    [isParticipant],
  )

  return (
    <Box py={1}>
      <Grid item xs={12}>
        <Card
          withBorder={false}
          noHeaderMargin
          title={
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Tabs tabs={effectiveTabs} selected={tab} handleChange={handleChangeTab} />
            </Box>
          }
        >
          {tab === TabNames.Fundings && <FundingWiresTable />}
          {tab === TabNames.PassThroughs && <CollectionsPassThroughsTable />}
          {(tab === TabNames.Participations || tab === TabNames.Transfers) && (
            <OutstandingParticipationWires />
          )}
          {tab === TabNames.Treasury && <Treasury />}
        </Card>
      </Grid>
    </Box>
  )
}

export default OutgoingWirePage
