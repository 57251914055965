import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import { Form } from 'react-final-form'
import * as Yup from 'yup'
import moment from 'moment'
import styles from './ClientAccountActivityEditModal.module.scss'
import genericSs from '@styles/generic.module.scss'
import Modal from '../../Common/Modal'
import Button from '../../Common/Button'
import KeyboardDatePicker from '../../Common/KeyboardDatePicker'
import { DATE_FORMAT } from '../../../constants/common'
import CurrencyField from '../../Common/CurrencyField'
import InputLabel from '../../Common/InputLabel'
import TextField from '../../Common/TextField'
import CreatableSelectField from '../../Common/CreatableSelectField'
import { FeeType, miscOptions, passthroughOptions } from '@common/interfaces/loanServicing'
import { IAccountActivity, IClientInfo } from '@common/interfaces/client'
import { AGGREGATION_TYPES } from '@common/constants/client'
import { dateToString, voidHandler } from '../../../helpers/helpers'
import FormField from '../../Common/FormField'
import { OnChange } from 'react-final-form-listeners'
import SelectField, { IOption } from '../../Common/SelectField'
import cn from 'classnames'
import { IEntityInfo } from '@common/interfaces/entityInfo'
import { ILoadingData } from '../../../redux/types'
import { IBankAccount } from '@common/interfaces/bankAccount'

interface IProps {
  loanBalanceStartDate: string
  clientInfo: IClientInfo
  item: IAccountActivity
  updateAdjustment: (id: string, data: object) => void
  updateFunding: (id: string, data: object) => void
  updatePassthroughs: (id: string, data: object) => void
  updateAggregationRow: (id: string, data: object) => void
  updateFee: (id: string, data: object) => void
  handleCancel: () => void
  handleConfirm: () => void
  addEntityInfo: (data: object) => Promise<any>
  listEntityInfo: (data: object) => Promise<{ data: IEntityInfo[] }>
  listBankAccounts: (id: string, data: object) => void
  banksAccountData: ILoadingData<{ data: IBankAccount[] }>
  clients: ILoadingData<{ data: IClientInfo[] }>
  listClients: (params?: object) => void
}

const ClientAccountActivityEditModal = ({
  loanBalanceStartDate,
  clientInfo,
  item,
  banksAccountData,
  updateAdjustment,
  updateFunding,
  updatePassthroughs,
  updateAggregationRow,
  updateFee,
  handleCancel,
  handleConfirm,
  addEntityInfo,
  listEntityInfo,
  listBankAccounts,
  clients,
  listClients,
}: IProps) => {
  const [error, setError] = useState<string | null>(null)
  const validate = useMemo(() => {
    const minDate = moment(loanBalanceStartDate || undefined).toDate()
    const schema = Yup.object()
      .shape({
        recordDate: Yup.date()
          .typeError('Please type date in MM/DD/YY format')
          .min(minDate, 'Cannot set record date for a locked month'),
        amount: Yup.number().typeError('Invalid number').required('Required'),
      })
      .test('sumOfShares', 'Sum of shares must be less than or equal to amount', (values) => {
        let sumOfShares = 0
        Object.keys(values).forEach((key) => {
          if (key.endsWith('Share')) {
            const share = +values[key]
            sumOfShares += share
          }
        })
        if (values.amount < 0) {
          return sumOfShares >= values.amount && sumOfShares <= 0
        }
        return sumOfShares <= values.amount
      })

    return schema
  }, [loanBalanceStartDate])

  useEffect(() => {
    listClients()
  }, [listClients])

  const { clientsData } = useMemo(
    () => ({
      clientsData: clients?.data?.data,
    }),
    [clients],
  )

  const clientNamesOptions: IOption[] = useMemo(
    () =>
      clientsData?.map(({ clientName }) => ({
        value: clientName,
        label: clientName,
      })),
    [clientsData],
  )

  const handleAddDebtor = useCallback(
    async (debtor: string) =>
      addEntityInfo({ name: debtor, type: 'debtor', clientName: clientInfo.clientName }),
    [addEntityInfo, clientInfo],
  )

  const loadDebtors = useCallback(
    async (inputValue: string) => {
      const res = await listEntityInfo({
        name: inputValue,
      })
      return res.data.map(({ name }) => ({
        value: name,
        label: name,
      }))
    },
    [listEntityInfo],
  )

  useEffect(() => {
    if (clientInfo.id) {
      listBankAccounts(clientInfo.id, {
        filters: { isExternal: false },
      })
    }
  }, [clientInfo.id, listBankAccounts])

  const clientBanksOptions: IOption[] = useMemo(
    () =>
      banksAccountData?.data?.data.map(({ id, bankName, bankAccountNumber }) => ({
        value: id,
        label: `${bankName} - ${bankAccountNumber.trim().slice(-4)}`,
      })),
    [banksAccountData],
  )

  const handleSubmit = useCallback(
    async (values: any) => {
      const participationData = item.fee?.participationFeeExposure?.reduce((acc, participation) => {
        acc[participation.id] = {
          override: values[`${participation.id}-Override`],
          share: values[`${participation.id}-Share`],
        }
        return acc
      }, {})

      switch (item.type) {
        case AGGREGATION_TYPES.adjustment:
          await updateAdjustment(item.id, {
            title: values.description,
            recordDate: dateToString(values.recordDate),
            amount: values.amount,
          })
          break
        case AGGREGATION_TYPES.funding:
          await updateFunding(clientInfo.id, {
            id: item.id,
            amount: values.amount,
            description: values.description,
          })
          break
        case AGGREGATION_TYPES.passThroughs:
          await updatePassthroughs(clientInfo.id, {
            id: item.id,
            amount: values.amount,
          })
          break
        case AGGREGATION_TYPES.check:
          await updateAggregationRow(item.id, {
            newCheckInfo: {
              clientName: values.clientName,
              recordDate: dateToString(values.recordDate),
              debtor: values.debtor?.label,
              checkNumber: values.checkNumber,
              accountNumber: values.accountNumber,
              routingNumber: values.routingNumber,
              boxLink: values.boxLink,
              amount: values.amount,
            },
          })
          break
        case AGGREGATION_TYPES.wire:
          await updateAggregationRow(item.id, {
            newWireInfo: {
              recordDate: dateToString(values.recordDate),
              debtor: values.debtor?.label,
              memo: values.memo,
              bankAccountId: values.bankAccountId,
              amount: values.amount,
            },
          })
          break
        default:
          await updateFee(item.id, {
            title: values.feeTitle?.value,
            description: values.feeDescription,
            recordDate: dateToString(values.recordDate),
            amount: values.amount,
            participations: participationData,
          })
          break
      }
      handleConfirm()
    },
    [
      item,
      clientInfo,
      updateAdjustment,
      updateFunding,
      updatePassthroughs,
      updateFee,
      handleConfirm,
      updateAggregationRow,
    ],
  )

  const currentBankAccountId = useMemo(() => {
    return banksAccountData?.data?.data?.find(
      ({ bankAccountNumber }) => bankAccountNumber === item.wireData?.accountNumber,
    )?.id
  }, [banksAccountData, item.wireData])

  const initialValues = useMemo(
    () => ({
      amount: item.amount,
      recordDate: moment(item.recordDate).toDate(),
      description: item.description?.replace('Adjustment ', ''),
      feeTitle: item.fee?.title
        ? {
            value: item.fee.title,
            label: item.fee.title,
          }
        : null,
      feeDescription: item.fee?.description,
      ...item.fee?.participationFeeExposure?.reduce((acc, participation) => {
        acc[`${participation.id}-Override`] = participation.feeOverride * 100
        acc[`${participation.id}-Share`] = participation.participantExposureAmount
        return acc
      }, {}),
      debtor: item?.entityInfo?.id
        ? {
            value: item.entityInfo.id,
            label: item.entityInfo.name,
          }
        : null,
      bankAccountId: currentBankAccountId,
      routingNumber: item.checkData?.routingNumber,
      accountNumber: item.checkData?.accountNumber,
      checkNumber: item.checkData?.checkNumber,
      boxLink: item.checkData?.boxLink,
      clientName: item.checkData?.clientName,
    }),
    [item, currentBankAccountId],
  )

  const updateParticipationFees = useCallback(
    (form: any, newAmount, oldAmount) => {
      if (!form) return

      const participationFees = item.fee?.participationFeeExposure
      let changeFields = true
      const formsToChange = {}
      if (participationFees) {
        participationFees.forEach((participationFee) => {
          const currentShareValue = form.getFieldState(`${participationFee.id}-Share`)?.value
          const previousSharePercent = (currentShareValue / oldAmount).toFixed(2)

          if (
            !previousSharePercent ||
            previousSharePercent === participationFee.participantExposurePercentage.toFixed(2)
          ) {
            const shareForm = `${participationFee.id}-Share`
            const newFee = (newAmount * participationFee.participantExposurePercentage).toFixed(2)
            formsToChange[shareForm] = newFee
          } else {
            changeFields = false
          }
        })
        if (changeFields) {
          for (const [key, value] of Object.entries(formsToChange)) {
            form.change(key, value)
          }
        }
      }
    },
    [item],
  )

  return (
    <Modal open onCancel={handleCancel} title="Edit account activity" size="small">
      <Form
        validate={(values) => {
          try {
            validate.validateSync(values, { abortEarly: false })
            setError(null)
          } catch (errors) {
            return errors.inner.reduce((formErrors: any, error: any) => {
              error.message !== 'Required' && setError(error.message)
              return {
                ...formErrors,
                [error.path]: error.message,
              }
            }, {})
          }
        }}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        render={({ pristine, invalid, submitting, values, handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="column" gap={3}>
              {[AGGREGATION_TYPES.adjustment, AGGREGATION_TYPES.funding].includes(item.type) ? (
                <Box flex={1}>
                  <InputLabel htmlFor="description" className={genericSs.textLeft}>
                    Description
                  </InputLabel>
                  <TextField
                    className={styles.textFormField}
                    id="description"
                    name="description"
                    placeholder="Description (Optional)"
                    InputProps={{
                      type: 'text',
                    }}
                  />
                </Box>
              ) : [AGGREGATION_TYPES.wire, AGGREGATION_TYPES.check].includes(item.type) ? null : ![
                  AGGREGATION_TYPES.passThroughs,
                ].includes(item.type) ? (
                item.fee?.type === FeeType.PassThroughs ? (
                  <Box flex={1}>
                    <InputLabel htmlFor="feeTitle" className={genericSs.textLeft}>
                      Fee subtype
                    </InputLabel>
                    <CreatableSelectField
                      className={styles.creatableSelectFormField}
                      name="feeTitle"
                      placeholder="Fee Type"
                      onAddValue={voidHandler}
                      options={passthroughOptions}
                      height="large"
                      withBorder
                      disabledClearable
                    />
                  </Box>
                ) : item.fee?.type === FeeType.Miscellaneous ? (
                  <Box flex={1}>
                    <InputLabel htmlFor="feeTitle" className={genericSs.textLeft}>
                      Fee subtype
                    </InputLabel>
                    <CreatableSelectField
                      className={styles.creatableSelectFormField}
                      name="feeTitle"
                      placeholder="Fee Type"
                      onAddValue={voidHandler}
                      options={miscOptions}
                      height="large"
                      withBorder
                      disabledClearable
                    />
                  </Box>
                ) : (
                  <Box flex={1}>
                    <InputLabel htmlFor="feeDescription" className={genericSs.textLeft}>
                      Description
                    </InputLabel>
                    <TextField
                      className={styles.textFormField}
                      id="feeDescription"
                      name="feeDescription"
                      placeholder="Description (Optional)"
                      InputProps={{
                        type: 'text',
                      }}
                    />
                  </Box>
                )
              ) : null}
              {item.type === AGGREGATION_TYPES.wire && (
                <>
                  <Box flex={1}>
                    <InputLabel htmlFor="debtor" className={genericSs.textLeft}>
                      Debtor
                    </InputLabel>
                    <CreatableSelectField
                      className={styles.creatableSelectFormField}
                      name="debtor"
                      placeholder="Debtor"
                      onAddValue={handleAddDebtor}
                      options={[]}
                      isAsync
                      loadOptions={loadDebtors}
                      value={values?.debtor}
                      height="large"
                      withBorder
                    />
                  </Box>
                  <Box flex={1}>
                    <InputLabel
                      htmlFor="bankAccountId"
                      className={cn(genericSs.textLeft, styles.label)}
                    >
                      Bank Account
                    </InputLabel>
                    <SelectField
                      className={styles.selectFormField}
                      id="bankAccountId"
                      name="bankAccountId"
                      options={clientBanksOptions || []}
                      placeholder="Bank Account"
                      selectSize="large"
                    />
                  </Box>
                </>
              )}

              {item.type === AGGREGATION_TYPES.check && (
                <>
                  <Box flex={1}>
                    <InputLabel htmlFor="clientName" className={genericSs.textLeft}>
                      Client name
                    </InputLabel>
                    <SelectField
                      className={styles.selectFormField}
                      id="clientName"
                      name="clientName"
                      options={clientNamesOptions || []}
                      placeholder="Client name"
                      selectSize="large"
                    />
                  </Box>
                  <Box display="flex" flexDirection="row" flexWrap="wrap" rowGap={1}>
                    <Box flex={1} marginRight={2}>
                      <InputLabel htmlFor="debtor" className={genericSs.textLeft}>
                        Debtor
                      </InputLabel>
                      <CreatableSelectField
                        className={styles.creatableSelectFormField}
                        name="debtor"
                        placeholder="Debtor"
                        onAddValue={handleAddDebtor}
                        options={[]}
                        isAsync
                        loadOptions={loadDebtors}
                        height="large"
                        withBorder
                      />
                      <InputLabel
                        htmlFor="accountNumber"
                        className={cn(genericSs.textLeft, styles.label)}
                      >
                        Account number
                      </InputLabel>
                      <TextField
                        className={styles.textFormField}
                        id="accountNumber"
                        name="accountNumber"
                        placeholder="Enter account number"
                        InputProps={{
                          type: 'number',
                        }}
                      />
                      <InputLabel
                        htmlFor="routingNumber"
                        className={cn(genericSs.textLeft, styles.label)}
                      >
                        Routing number
                      </InputLabel>
                      <TextField
                        className={styles.textFormField}
                        id="routingNumber"
                        name="routingNumber"
                        placeholder="Enter routing number"
                        InputProps={{
                          type: 'number',
                        }}
                      />
                    </Box>
                    <Box flex={1}>
                      <InputLabel htmlFor="checkNumber" className={genericSs.textLeft}>
                        Check number
                      </InputLabel>
                      <TextField
                        className={styles.textFormField}
                        id="checkNumber"
                        name="checkNumber"
                        placeholder="Enter check number"
                        InputProps={{
                          type: 'number',
                        }}
                      />
                      <InputLabel
                        htmlFor="boxLink"
                        className={cn(genericSs.textLeft, styles.label)}
                      >
                        Box link
                      </InputLabel>
                      <TextField
                        className={styles.textFormField}
                        id="boxLink"
                        name="boxLink"
                        placeholder="Enter box link"
                        InputProps={{
                          type: 'text',
                        }}
                      />
                    </Box>
                  </Box>
                </>
              )}
              {![AGGREGATION_TYPES.funding, AGGREGATION_TYPES.passThroughs].includes(item.type) && (
                <Box flex={1}>
                  <InputLabel htmlFor="recordDate" className={genericSs.textLeft}>
                    Date
                  </InputLabel>
                  <KeyboardDatePicker
                    className={styles.dateFormField}
                    name="recordDate"
                    inputFormat={DATE_FORMAT}
                    minDate={moment(loanBalanceStartDate || undefined).toDate()}
                    placeholder="Select date"
                  />
                </Box>
              )}
              <Box flex={1}>
                <InputLabel htmlFor="amount" className={genericSs.textLeft}>
                  Amount
                </InputLabel>
                <CurrencyField
                  id="amount"
                  name="amount"
                  placeholder="Enter Amount"
                  className={styles.textFormField}
                />
              </Box>
            </Box>
            <OnChange name="amount">
              {(value, previous) => {
                updateParticipationFees(form, value, previous)
              }}
            </OnChange>
            {item.fee?.participationFeeExposure?.length
              ? item?.fee?.participationFeeExposure?.map((participation) => {
                  const overrideForm = `${participation.id}-Override`
                  const shareForm = `${participation.id}-Share`

                  const currentShareValue = values[shareForm]
                  const currentAmount = values.amount

                  const sharePercent = currentAmount
                    ? ((currentShareValue / currentAmount) * 100).toFixed(2)
                    : 0

                  return (
                    <Box flex={1} key={participation.participant} mt={2}>
                      <InputLabel
                        htmlFor={participation.participant}
                        className={genericSs.textLeft}
                      >
                        {participation.participant}
                      </InputLabel>
                      <Box display="flex" flexDirection="row" flexWrap="wrap" rowGap={1}>
                        <Box flex={1} marginRight={2}>
                          <InputLabel htmlFor={overrideForm} className={genericSs.textLeft}>
                            Override (%)
                          </InputLabel>
                          <FormField
                            name={overrideForm}
                            type="percent"
                            size="large"
                            placeholder="Enter override"
                          />
                        </Box>
                        <Box flex={1}>
                          <InputLabel htmlFor={shareForm} className={genericSs.textLeft}>
                            Share ({sharePercent}%)
                          </InputLabel>
                          <CurrencyField
                            className={styles.textFormField}
                            id={shareForm}
                            name={shareForm}
                            placeholder="Enter share"
                          />
                        </Box>
                      </Box>
                    </Box>
                  )
                })
              : null}

            <Box mt={5}>
              {error && <Box color="error.main">{error}</Box>}

              <Button
                type="submit"
                fullWidth
                small={false}
                key="submit"
                color="primary"
                variant="contained"
                onClick={handleSubmit}
                disabled={pristine || invalid || submitting}
                isLoading={submitting}
              >
                Apply
              </Button>
            </Box>
          </form>
        )}
      />
    </Modal>
  )
}

export default ClientAccountActivityEditModal
